define('frontend-cp/components/ko-agent-dropdown/create-case/component', ['exports', 'moment', 'frontend-cp/components/ko-button/styles'], function (exports, _moment, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  var next = Ember.run.next;
  var debounce = Ember.run.debounce;
  var later = Ember.run.later;
  var EmberObject = Ember.Object;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    isDisabled: false,
    store: service(),
    i18n: service(),
    fields: null,
    filteredUsers: null,

    // Lifecycle hooks
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var i18n = this.get('i18n');
      var requesterErrorText = i18n.t('generic.create_case_panel.requester_required');
      this.set('fields', EmberObject.create({
        requester: {
          value: null,
          validator: function validator(value) {
            return value ? null : requesterErrorText;
          }
        }
      }));
      this.set('filteredUsers', null);

      next(function () {
        _this.$('.ember-power-select-trigger input').focus();
      });
    },


    // Actions
    actions: {
      searchUsers: function searchUsers(filterString) {
        var _this2 = this;

        return new EmberPromise(function (resolve) {
          debounce(_this2, _this2._searchUsers, filterString, resolve, 200);
        });
      },
      selectRequester: function selectRequester(user) {
        this.set('fields.requester.value', user);
        var className = _styles.default.primary.split(' ')[0];
        later(this.$('.' + className), 'focus');
      },
      submit: function submit() {
        this.get('onSubmit')('session.agent.cases.new', (0, _moment.default)().format('YYYY-MM-DD-hh-mm-ss'), { queryParams: { requester_id: this.get('fields.requester.value.id') } });
      }
    },

    // Methods
    _searchUsers: function _searchUsers(filterString, resolve) {
      if (filterString.length === 0) {
        return resolve([]);
      }

      return this.get('store').query('user', {
        name: filterString
      }).then(function (results) {
        return resolve(results);
      }, function () {
        return resolve([]);
      });
    }
  });
});