define("frontend-cp/components/ko-admin/automation-actions-builder/action/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "automation-action": "ko-admin_automation-actions-builder_action__automation-action_m905mz",
    "fadeIn": "ko-admin_automation-actions-builder_action__fadeIn_m905mz",
    "small-slot": "ko-admin_automation-actions-builder_action__small-slot_m905mz",
    "big-slot": "ko-admin_automation-actions-builder_action__big-slot_m905mz",
    "remove-action-btn": "ko-admin_automation-actions-builder_action__remove-action-btn_m905mz",
    "actions": "ko-admin_automation-actions-builder_action__actions_m905mz"
  };
});