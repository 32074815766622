define("frontend-cp/components/ko-info-bar/field/select/trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-info-bar_field_select_trigger__trigger_53vxl2",
    "input": "ko-info-bar_field_select_trigger__input_53vxl2",
    "input--hidden": "ko-info-bar_field_select_trigger__input--hidden_53vxl2",
    "placeholder": "ko-info-bar_field_select_trigger__placeholder_53vxl2",
    "icon": "ko-info-bar_field_select_trigger__icon_53vxl2",
    "loading": "ko-info-bar_field_select_trigger__loading_53vxl2 _loading-icon_jvqwfg",
    "header": "ko-info-bar_field_select_trigger__header_53vxl2",
    "middle-ellipsis-span": "ko-info-bar_field_select_trigger__middle-ellipsis-span_53vxl2"
  };
});