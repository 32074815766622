define("frontend-cp/trial-data/insights/cases/csat", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "status": 200,
    "data": {
      "start_at": "2016-05-28T00:00:00+00:00",
      "end_at": "2016-06-27T23:59:59+00:00",
      "previous_start_at": "2016-04-16T00:00:00+00:00",
      "previous_end_at": "2016-05-16T23:59:59+00:00",
      "interval": "DAY",
      "interval_count": 31,
      "series": {
        "name": "average_csat",
        "data": [0, 0, 82.353, 84.615, 85.714, 86.364, 93.023, 93.023, 93.023, 87.5, 86.486, 94.595, 86.842, 80.488, 80.488, 80.488, 88.462, 84, 94.737, 89.583, 85.185, 85.185, 50, 83.333, 89.744, 89.552, 93.333, 91.667, 100, 100, 100],
        "previous": [100, 100, 97.297, 88.889, 83.333, 88.235, 91.667, 91.667, 100, 92, 84.615, 89.655, 100, 90.909, 90.909, 90.909, 100, 100, 83.333, 87.5, 85.714, 85.714, 85.714, 85.714, 94.444, 84.615, 88, 100, 100, 100, 90.909],
        "resource_type": "report_series_comparison"
      },
      "metric": {
        "name": "average_csat",
        "value": 88.51,
        "delta_percent": -2.343,
        "previous": 90.634,
        "resource_type": "report_value_comparison"
      },
      "resource_type": "report_case_csat"
    },
    "resources": [],
    "resource": "report_case_csat"
  };
});