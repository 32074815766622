define('frontend-cp/components/ko-notification-center-new/notification-case-assigned-to-team/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    notification: null,
    close: function close() {},

    activityActions: computed.reads('notification.activity.actions'),
    teamAssigneeActions: computed.filterBy('activityActions', 'field', 'assigneeteamid'),
    user: computed.reads('notification.activity.objectActorUser'),
    teamName: computed('teamAssigneeActions', 'notification', function () {
      if (this.get('teamAssigneeActions.length') > 0) {
        var action = this.get('teamAssigneeActions.firstObject');
        return action.get('newObject.title');
      } else {
        return this.get('notification.activity.case.assignedTeam.title');
      }
    })
  });
});