define("frontend-cp/components/ko-admin/automation-actions-builder/autocomplete-team-or-agent/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "sender-name": "ko-admin_automation-actions-builder_autocomplete-team-or-agent__sender-name_1s2byl",
    "separator": "ko-admin_automation-actions-builder_autocomplete-team-or-agent__separator_1s2byl",
    "agent": "ko-admin_automation-actions-builder_autocomplete-team-or-agent__agent_1s2byl",
    "avatar": "ko-admin_automation-actions-builder_autocomplete-team-or-agent__avatar_1s2byl"
  };
});