define('frontend-cp/serializers/view', ['exports', 'ember-data', 'frontend-cp/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      updatedAt: { serialize: false },
      createdAt: { serialize: false },
      columns: { serialize: 'ids', deserialize: 'records' },
      predicateCollections: { serialize: 'records' }
    },

    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      if (relationship.key === 'visibilityToTeams') {
        if (snapshot.attr('visibilityType') !== 'TEAM') {
          return;
        }
        json.team_ids = snapshot.hasMany('visibilityToTeams').mapBy('id');
      } else {
        this._super.apply(this, arguments);
      }
    }
  });
});