define('frontend-cp/session/admin/channels/email/new/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend((0, _route.default)('mailbox'), {
    model: function model(params) {
      return RSVP.hash({
        mailbox: this.store.createRecord('mailbox'),
        brands: this.store.findAll('brand')
      });
    },
    setupController: function setupController(controller, model) {
      model.mailbox.set('brand', model.brands.findBy('isDefault', true));
      controller.setProperties(model);
      controller.initEdits();
    }
  });
});