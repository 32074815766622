define("frontend-cp/session/agent/knowledgebase/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "main-container": "session_agent_knowledgebase__main-container_19ws7w",
    "sidebar": "session_agent_knowledgebase__sidebar_19ws7w",
    "main-content": "session_agent_knowledgebase__main-content_19ws7w",
    "hidden": "session_agent_knowledgebase__hidden_19ws7w"
  };
});