define("frontend-cp/components/ko-on-boarding/learn/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "zIndexToHideMessenger": "9999999999",
    "box": "ko-on-boarding_learn__box_e4nbus",
    "box__number": "ko-on-boarding_learn__box__number_e4nbus",
    "box__title": "ko-on-boarding_learn__box__title_e4nbus",
    "is-done": "ko-on-boarding_learn__is-done_e4nbus",
    "box__illustration": "ko-on-boarding_learn__box__illustration_e4nbus",
    "box__content": "ko-on-boarding_learn__box__content_e4nbus",
    "box__text": "ko-on-boarding_learn__box__text_e4nbus",
    "box__description": "ko-on-boarding_learn__box__description_e4nbus",
    "box__tick": "ko-on-boarding_learn__box__tick_e4nbus",
    "container": "ko-on-boarding_learn__container_e4nbus",
    "button": "ko-on-boarding_learn__button_e4nbus",
    "is-hidden": "ko-on-boarding_learn__is-hidden_e4nbus",
    "button--skip": "ko-on-boarding_learn__button--skip_e4nbus",
    "button--previous": "ko-on-boarding_learn__button--previous_e4nbus",
    "button--next": "ko-on-boarding_learn__button--next_e4nbus",
    "arrow-previous": "ko-on-boarding_learn__arrow-previous_e4nbus",
    "arrow-next": "ko-on-boarding_learn__arrow-next_e4nbus",
    "header": "ko-on-boarding_learn__header_e4nbus",
    "logo": "ko-on-boarding_learn__logo_e4nbus",
    "main": "ko-on-boarding_learn__main_e4nbus",
    "images-group": "ko-on-boarding_learn__images-group_e4nbus",
    "slide-out-left": "ko-on-boarding_learn__slide-out-left_e4nbus",
    "slide-out-right": "ko-on-boarding_learn__slide-out-right_e4nbus",
    "is-visible": "ko-on-boarding_learn__is-visible_e4nbus",
    "slide": "ko-on-boarding_learn__slide_e4nbus",
    "slide__heading": "ko-on-boarding_learn__slide__heading_e4nbus",
    "slide__description": "ko-on-boarding_learn__slide__description_e4nbus",
    "slide__visual": "ko-on-boarding_learn__slide__visual_e4nbus",
    "intro-text": "ko-on-boarding_learn__intro-text_e4nbus",
    "intro-text-in": "ko-on-boarding_learn__intro-text-in_e4nbus",
    "intro-video": "ko-on-boarding_learn__intro-video_e4nbus",
    "video-in": "ko-on-boarding_learn__video-in_e4nbus",
    "footer": "ko-on-boarding_learn__footer_e4nbus",
    "pagination": "ko-on-boarding_learn__pagination_e4nbus",
    "step-number": "ko-on-boarding_learn__step-number_e4nbus",
    "is-active": "ko-on-boarding_learn__is-active_e4nbus"
  };
});