define("frontend-cp/components/ko-universal-search/result/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-universal-search_result__container_1yuoiw",
    "slide": "ko-universal-search_result__slide_1yuoiw",
    "container-read-only": "ko-universal-search_result__container-read-only_1yuoiw",
    "is-active": "ko-universal-search_result__is-active_1yuoiw",
    "icon": "ko-universal-search_result__icon_1yuoiw",
    "action": "ko-universal-search_result__action_1yuoiw",
    "content": "ko-universal-search_result__content_1yuoiw",
    "link": "ko-universal-search_result__link_1yuoiw",
    "read-only": "ko-universal-search_result__read-only_1yuoiw"
  };
});