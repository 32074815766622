define('frontend-cp/lib/humanize-seconds', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (time) {
    var maxType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'hours';

    var days = Math.floor(time / 86400);
    var hours = parseInt(time / 3600) - days * 24;
    var minutes = parseInt(parseInt(time % 3600) / 60);
    var seconds = parseInt(time) - minutes * 60;

    if (!days && !hours && !minutes) {
      return seconds + 's';
    } else if (!days && !hours) {
      return minutes + 'm ' + (seconds ? seconds + 's' : '');
    } else if (!days) {
      return hours + 'h ' + (minutes ? minutes + 'm' : '');
    } else {
      if (maxType === 'hours') {
        hours += days * 24;
        return hours + 'h ' + minutes + 'm';
      }

      return days + 'd ' + hours + 'h ' + minutes + 'm';
    }
  };
});