define('frontend-cp/utils/locale', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.findLocaleFieldBySettings = findLocaleFieldBySettings;
  function findLocaleFieldBySettings(localeFields, localeService, sessionService) {
    var fieldInUserLocale = localeFields.findBy('locale', sessionService.get('user.locale.locale'));
    var fieldInAccDefaultLocale = localeFields.findBy('locale', localeService.get('accountDefaultLocaleCode'));
    return fieldInUserLocale || fieldInAccDefaultLocale;
  }
});