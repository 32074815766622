define("frontend-cp/components/ko-identities/form/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "form": "ko-identities_form__form_1bq1h6",
    "content": "ko-identities_form__content_1bq1h6",
    "title": "ko-identities_form__title_1bq1h6",
    "buttons": "ko-identities_form__buttons_1bq1h6"
  };
});