define("frontend-cp/components/ko-loader/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-loader__container_2f7byw",
    "item": "ko-loader__item_2f7byw",
    "state1": "ko-loader__state1_2f7byw",
    "state2": "ko-loader__state2_2f7byw",
    "state3": "ko-loader__state3_2f7byw",
    "containerLarge": "ko-loader__containerLarge_2f7byw",
    "containerMedium": "ko-loader__containerMedium_2f7byw",
    "containerSmall": "ko-loader__containerSmall_2f7byw"
  };
});