define('frontend-cp/services/i18n', ['exports', 'moment', 'frontend-cp/utils/bugsnag'], function (exports, _moment, _bugsnag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Service = Ember.Service;
  var capitalize = Ember.String.capitalize;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var assign = Ember.assign;
  var getOwner = Ember.getOwner;


  var _humaniseKey = function _humaniseKey(key) {
    return capitalize(key.split('.').get('lastObject').replace(/_/g, ' '));
  };

  exports.default = Service.extend({
    intl: service(),
    store: service(),

    timeFormatSetting: computed(function () {
      return this.get('store').peekAll('setting').findBy('name', 'time_format');
    }),

    hour12: computed('timeFormatSetting.value', function () {
      return this.get('timeFormatSetting.value') !== '24hour';
    }),

    now: function now() {
      return (0, _moment.default)();
    },
    t: function t(key) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return this._translateAndLogErrors(key, function () {
        var _get;

        return (_get = _this.get('intl')).t.apply(_get, [key].concat(_toConsumableArray(args)));
      });
    },
    findTranslationByKey: function findTranslationByKey(key) {
      var _this2 = this;

      return this._translateAndLogErrors(key, function () {
        return _this2.get('intl').findTranslationByKey(key);
      });
    },
    formatMessage: function formatMessage(key) {
      var _this3 = this;

      for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      return this._translateAndLogErrors(key, function () {
        var _get2;

        return (_get2 = _this3.get('intl')).formatMessage.apply(_get2, [key].concat(_toConsumableArray(args)));
      });
    },
    formatHtmlMessage: function formatHtmlMessage(key) {
      var translation = this.findTranslationByKey(key);
      var formattedHtmlMessage = void 0;
      try {
        var _get3;

        for (var _len3 = arguments.length, args = Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
          args[_key3 - 1] = arguments[_key3];
        }

        formattedHtmlMessage = (_get3 = this.get('intl')).formatHtmlMessage.apply(_get3, [translation].concat(_toConsumableArray(args)));
      } catch (e) {
        this._logError(key, e);
      }
      return formattedHtmlMessage;
    },
    formatNumber: function formatNumber(key) {
      var _get4;

      for (var _len4 = arguments.length, args = Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
        args[_key4 - 1] = arguments[_key4];
      }

      return (_get4 = this.get('intl')).formatNumber.apply(_get4, [key].concat(_toConsumableArray(args)));
    },
    formatDate: function formatDate(key, hash) {
      hash.hour12 = this.get('hour12');
      if (key) {
        var _get5;

        return (_get5 = this.get('intl')).formatDate.apply(_get5, arguments);
      } else {
        return null;
      }
    },
    formatRelative: function formatRelative(key) {
      if (key) {
        var _get6;

        for (var _len5 = arguments.length, args = Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
          args[_key5 - 1] = arguments[_key5];
        }

        return (_get6 = this.get('intl')).formatRelative.apply(_get6, [key].concat(_toConsumableArray(args)));
      } else {
        return null;
      }
    },
    formatTime: function formatTime(time, options) {
      if (!time) {
        return null;
      }

      var intl = this.get('intl');
      var hour12 = this.get('hour12');
      var _options = options,
          timeZone = _options.timeZone;


      options = assign({}, { hour12: hour12 }, options);

      if (timeZone) {
        Reflect.deleteProperty(options, 'timeZone');

        var momentZone = _moment.default.tz.zone(timeZone);
        var zoneOffset = momentZone.offset(time);
        var localOffset = this.now().utcOffset();

        // Because the `timeZone` option on Intl.DateTimeFormat is not 100%
        // supported across target browsers we must translate our time object
        // into the target zone before we try to format it.
        time = (0, _moment.default)(time).subtract(localOffset, 'm') // translate into UTC
        .subtract(zoneOffset, 'm') // translate into target TZ
        .toDate();
      }

      return intl.formatTime(time, options);
    },
    exists: function exists(key) {
      return this.get('intl').exists(key);
    },
    _logError: function _logError(key, e) {
      if (console && console.error) {
        // eslint-disable-line
        console.error('Failed to translate ' + key + ': ' + e); // eslint-disable-line
      }

      var context = (0, _bugsnag.getMetaData)(null, getOwner(this));
      context.intl = {
        key: key
      };
      if (window && window.Bugsnag && window.Bugsnag.apiKey !== undefined) {
        window.Bugsnag.notifyException(e, 'Failed to translate ' + key, context, 'info');
      }
    },
    _translateAndLogErrors: function _translateAndLogErrors(key, fn) {
      try {
        var message = fn();
        if (message.match(/^Missing translation:/)) {
          this._logError(key, message);
          return _humaniseKey(key);
        } else {
          return message;
        }
      } catch (e) {
        this._logError(key, e);
        return _humaniseKey(key);
      }
    }
  });
});