define('frontend-cp/services/time-formatter', ['exports', 'moment', 'frontend-cp/utils/moment-to-intl'], function (exports, _moment, _momentToIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var TEN_SECONDS = 10000;
  var THIRTY_SECONDS = 30000;
  var ONE_MINUTE = 60000;
  var TWO_MINUTES = 120000;
  var FIVE_MINUTES = 300000;
  var NINETY_MINUTES = 5400000;
  var THIRTY_SIX_HOURS = 129600000;
  var ONE_YEAR = 31536000000;

  exports.default = Service.extend({
    date: service(),
    i18n: service(),

    // Methods
    getLocalizedOutput: function getLocalizedOutput(now, momentTime, hideSuffix) {
      if (!momentTime.isValid()) {
        return '-';
      }
      var i18n = this.get('i18n');

      var diffFromNowInMS = momentTime.diff(now) * -1;
      var happenedToday = momentTime.isSame(now, 'day');
      var happenedThisYear = momentTime.isSame(now, 'year');

      if (happenedToday && diffFromNowInMS <= NINETY_MINUTES) {
        return momentTime.from(now, hideSuffix);
      } else if (happenedToday && diffFromNowInMS <= THIRTY_SIX_HOURS && momentTime.isSame(now, 'day')) {
        var dateForIntl = (0, _momentToIntl.default)(momentTime);
        var time = i18n.formatTime(dateForIntl, {
          format: 'time'
        });
        return i18n.t('generic.at_time', { time: time });
      } else if (happenedThisYear && diffFromNowInMS <= ONE_YEAR) {
        var _dateForIntl = (0, _momentToIntl.default)(momentTime);
        var date = i18n.formatDate(_dateForIntl, { format: 'dayMonth' });
        return i18n.t('generic.on_date', { date: date });
      } else {
        var _dateForIntl2 = (0, _momentToIntl.default)(momentTime);
        var _date = i18n.formatDate(_dateForIntl2, { format: 'L' });
        return i18n.t('generic.on_date', { date: _date });
      }
    },
    shortFormatRelative: function shortFormatRelative(now, momentTime) {
      var minute = 6e4;
      var hour = 36e5;
      var day = 864e5;
      var week = 6048e5;

      var i18n = this.get('i18n');

      var formats = {
        minutes: i18n.t('generic.minute_abbreviation'),
        hours: i18n.t('generic.hour_abbreviation'),
        days: i18n.t('generic.day_abbreviation')
      };

      var diff = Math.abs(momentTime.diff(now));
      var unit = null;
      var num = null;

      if (diff < minute) {
        unit = 'minutes';
        num = 1;
      } else if (diff < hour) {
        unit = 'seconds';
      } else if (diff < day) {
        unit = 'hours';
      } else if (diff < week) {
        unit = 'days';
      } else if (momentTime.isSame(now, 'year')) {
        return i18n.formatDate(momentTime, { format: 'dayMonth' });
      } else {
        return i18n.formatDate(momentTime, { format: 'll' });
      }

      if (!num) {
        if (unit === 'seconds') {
          num = Math.round(diff / minute);
          unit = 'minutes';
        } else {
          num = _moment.default.duration(diff)[unit]();
        }
      }

      var unitStr = unit = formats[unit];

      return num + unitStr;
    },
    calculateAppropriateInterval: function calculateAppropriateInterval(momentTime) {
      var diffInMS = (0, _moment.default)(this.get('date').getCurrentDate()).diff(momentTime);
      if (diffInMS < TWO_MINUTES) {
        return TEN_SECONDS;
      } else if (diffInMS < FIVE_MINUTES) {
        return THIRTY_SECONDS;
      } else if (diffInMS < NINETY_MINUTES) {
        return ONE_MINUTE;
      } else if (diffInMS < THIRTY_SIX_HOURS) {
        return TWO_MINUTES;
      } else {
        return null;
      }
    }
  });
});