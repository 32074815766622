define('frontend-cp/models/channel', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    channelType: _emberData.default.attr('string'),
    characterLimit: _emberData.default.attr('number'),
    account: _emberData.default.belongsTo('account', { polymorphic: true, async: false }),

    isChannelTypeMailbox: computed('channelType', function () {
      return ['MAILBOX', 'MAIL'].indexOf(this.get('channelType')) > -1;
    }),

    handle: computed('channelType', function () {
      var channelType = this.get('channelType');
      switch (channelType) {
        case 'MAILBOX':
        case 'MAIL':
          {
            return this.get('account.address');
          }
        case 'FACEBOOK':
          {
            return this.get('account.title') + ' - Message';
          }
        case 'TWITTER':
          {
            return this.get('account.screenName') + ' - Tweet';
          }
        case 'TWITTER_DM':
          {
            return this.get('account.screenName') + ' - DM';
          }
      }
    })
  });
});