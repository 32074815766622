define("frontend-cp/components/ko-notification-center-new/preferences-new/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "description": "ko-notification-center-new_preferences-new__description_b97k2t",
    "heading": "ko-notification-center-new_preferences-new__heading_b97k2t",
    "devices": "ko-notification-center-new_preferences-new__devices_b97k2t",
    "device": "ko-notification-center-new_preferences-new__device_b97k2t",
    "vertical-separator": "ko-notification-center-new_preferences-new__vertical-separator_b97k2t",
    "device-text": "ko-notification-center-new_preferences-new__device-text_b97k2t",
    "device-label": "ko-notification-center-new_preferences-new__device-label_b97k2t",
    "loader": "ko-notification-center-new_preferences-new__loader_b97k2t",
    "list": "ko-notification-center-new_preferences-new__list_b97k2t",
    "table": "ko-notification-center-new_preferences-new__table_b97k2t",
    "list-row": "ko-notification-center-new_preferences-new__list-row_b97k2t",
    "list-row-old": "ko-notification-center-new_preferences-new__list-row-old_b97k2t",
    "list-head": "ko-notification-center-new_preferences-new__list-head_b97k2t",
    "list-data": "ko-notification-center-new_preferences-new__list-data_b97k2t",
    "list-header": "ko-notification-center-new_preferences-new__list-header_b97k2t",
    "buttons": "ko-notification-center-new_preferences-new__buttons_b97k2t",
    "sound-preferences-container": "ko-notification-center-new_preferences-new__sound-preferences-container_b97k2t",
    "sound-preferences": "ko-notification-center-new_preferences-new__sound-preferences_b97k2t",
    "sound-preference": "ko-notification-center-new_preferences-new__sound-preference_b97k2t",
    "sound-icon": "ko-notification-center-new_preferences-new__sound-icon_b97k2t"
  };
});