define('frontend-cp/session/agent/cases/new/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    store: service(),
    casePriorityCache: service('cache/case-priority'),

    model: function model() {
      var caseModel = this.modelFor('session.agent.cases.new');
      var store = this.get('store');
      var casePriorityCache = this.get('casePriorityCache');

      return hash({
        caseFields: store.findAll('case-field'),
        priorities: casePriorityCache.getAll(),
        statuses: store.findAll('case-status'),
        types: store.findAll('case-type'),
        caseForms: store.findAll('case-form'),
        teams: store.query('team', {}),
        case: caseModel
      });
    },
    setupController: function setupController(controller, model) {
      var parentController = this.controllerFor('session.agent.cases.new');
      controller.set('state', parentController.get('state'));
      controller.setProperties(model);
    }
  });
});