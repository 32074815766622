define("frontend-cp/components/ko-reorderable-crud-list/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-reorderable-crud-list__item_1qs79g",
    "actions": "ko-reorderable-crud-list__actions_1qs79g",
    "actions-item": "ko-reorderable-crud-list__actions-item_1qs79g"
  };
});