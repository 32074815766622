define('frontend-cp/components/ko-admin/automation-actions-builder/autocomplete-agent/component', ['exports', 'frontend-cp/components/ko-admin/automation-actions-builder/base/component', 'ember-concurrency', 'frontend-cp/lib/keycodes'], function (exports, _component, _emberConcurrency, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;


  function normalizeUser(u) {
    return { id: get(u, 'id'), text: get(u, 'fullName') };
  }

  exports.default = _component.default.extend({
    store: service(),

    // CPs
    options: computed('definition.valuesSortedAphabetically', function () {
      var originalData = this.get('definition.valuesSortedAphabetically');
      return originalData.map(function (e) {
        return { id: e.id, text: e.value };
      });
    }),

    selected: computed('automationAction.value', 'options', {
      get: function get() {
        var id = this.get('automationAction.value');
        if (!id) {
          return null;
        }
        return this.get('options').findBy('id', id) || this.get('store').find('user', id).then(normalizeUser);
      },
      set: function set(_, selection) {
        this.set('automationAction.value', selection.id);
        return selection;
      }
    }),

    // Tasks
    search: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(name) {
      var users, lowercaseTerm, specialResults, searchResults;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              _context.next = 4;
              return this.get('store').query('user', { in: 'ADMINS,AGENTS', name: name });

            case 4:
              users = _context.sent;
              lowercaseTerm = name.toLowerCase();
              specialResults = this.get('options').filter(function (e) {
                return e.text.toLowerCase().indexOf(lowercaseTerm) > -1;
              });
              searchResults = users.map(normalizeUser);
              return _context.abrupt('return', specialResults.concat(searchResults));

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    // Actions
    actions: {
      preventSubmissionOnEnter: function preventSubmissionOnEnter(_, e) {
        if (e.keyCode === _keycodes.enter) {
          e.preventDefault();
        }
      }
    }
  });
});