define('frontend-cp/components/ko-cases-list/sidebar/item/reload-icon/component', ['exports', 'ember-concurrency', 'frontend-cp/lib/animation'], function (exports, _emberConcurrency, _animation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;


  var SPIN_TIME = 1000;

  exports.default = Component.extend({
    // Attributes:
    isRefreshingCases: null,
    casesView: null,
    currentCachedView: null,

    // State:
    currentDegrees: 0,

    // CPs
    iconStyle: computed('currentDegrees', function () {
      return htmlSafe('transform: rotate(' + this.get('currentDegrees') + 'deg)');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('currentCachedView') === this.get('casesView')) {
        if (this.get('isRefreshingCases') && !this.get('spin.isRunning')) {
          this.get('spin').perform();
        }
      } else {
        this.get('spin').cancelAll();
      }
    },


    spin: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var _this = this;

      var animateTask;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              animateTask = void 0;
              _context.prev = 1;

              animateTask = _animation.default.get('animate').perform(function (time) {
                var newDegrees = Math.round(time % SPIN_TIME / SPIN_TIME * 360);

                if (!_this.get('isRefreshingCases') && newDegrees <= _this.get('currentDegrees')) {
                  return false;
                }

                _this.set('currentDegrees', newDegrees);
              });
              _context.next = 5;
              return animateTask;

            case 5:
              _context.prev = 5;

              animateTask.cancel();
              this.set('currentDegrees', 0);
              return _context.finish(5);

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[1,, 5, 9]]);
    })).drop()
  });
});