define('frontend-cp/adapters/messenger-setting', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'messenger/settings';
    },


    // Makes a put request to save messenger settings. The API creates a messenger_setting resource if it doesn't exist
    saveMessengerSettings: function saveMessengerSettings(brandId, settings) {
      var url = this.namespace + '/messenger/settings?include=locale_field';
      var payload = Object.assign({}, settings, { brand_id: brandId });
      return this.ajax(url, 'PUT', { data: payload });
    }
  });
});