define('frontend-cp/components/ko-table/header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    allRowsSelected: false,
    selectable: false,

    actions: {
      selectAll: function selectAll(value) {
        var allRowsSelected = this.get('allRowsSelected');

        if (allRowsSelected) {
          this.get('onDeselectAll')();
        } else {
          this.get('onSelectAll')();
        }
      }
    }
  });
});