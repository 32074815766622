define('frontend-cp/components/ko-note-destination/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      onChange: function onChange(option) {
        this.sendAction('onChange', option);
      }
    }
  });
});