define('frontend-cp/components/ko-info-bar/field/basic-drill-down/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    title: null,
    options: [],
    value: null,
    isEdited: false,
    isKREEdited: false,
    isErrored: false,
    isDisabled: false,
    onValueChange: null,
    hasEmptyOption: true,
    emptyLabel: '-',
    renderInPlace: false
  });
});