define('frontend-cp/components/ko-admin/monitors/index/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var filterBy = Ember.computed.filterBy;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    i18n: service(),
    notification: service(),
    confirmation: service(),

    // CPs
    enabledMonitors: filterBy('monitors', 'isEnabled', true),
    disabledMonitors: filterBy('monitors', 'isEnabled', false),
    sortedEnabledMonitors: computed('enabledMonitors.@each.executionOrder', function () {
      return this.get('enabledMonitors').toArray().sortBy('executionOrder');
    }),
    sortedDisabledMonitors: computed('disabledMonitors.@each.title', function () {
      return this.get('disabledMonitors').toArray().sortBy('title');
    }),

    // Actions
    actions: {
      toggleStatus: function toggleStatus(monitor, e) {
        e.stopPropagation();
        e.preventDefault();
        var numberOfEnabledMonitors = this.get('enabledMonitors.length');
        var isEnabled = monitor.toggleProperty('isEnabled');
        monitor.set('executionOrder', isEnabled ? numberOfEnabledMonitors : null);
        monitor.save();
      },
      delete: function _delete(monitor, e) {
        var _this = this;

        e.preventDefault();
        e.stopPropagation();
        this.get('confirmation').confirm({ intlConfirmationBody: 'generic.confirm.delete' }).then(function () {
          return monitor.destroyRecord();
        }).then(function () {
          return _this.get('notification').success(_this.get('i18n').t('admin.monitors.delete.success_message'));
        });
      },
      reorder: function reorder(newOrder) {
        var _this2 = this;

        var data = { monitor_ids: newOrder.mapBy('id') };
        var appInstance = getOwner(this);
        var applicationAdapter = appInstance.lookup('adapter:application');
        var url = applicationAdapter.urlPrefix() + '/monitors/reorder';
        newOrder.forEach(function (monitor, index) {
          return monitor.set('executionOrder', index);
        });
        applicationAdapter.ajax(url, 'PUT', { data: data }).then(function (response) {
          _this2.get('store').pushPayload('monitors', response);
        });
      }
    }
  });
});