define('frontend-cp/session/admin/apps/api/oauth-apps/schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _virtualModel.model)('oauth-app', {
    name: (0, _virtualModel.attr)(),
    url: (0, _virtualModel.attr)(),
    description: (0, _virtualModel.attr)(),
    scopes: (0, _virtualModel.many)((0, _virtualModel.attr)()),
    author: (0, _virtualModel.attr)(),
    author_url: (0, _virtualModel.attr)(),
    callback_url: (0, _virtualModel.attr)(),

    logoFileId: (0, _virtualModel.attr)()
  });
});