define('frontend-cp/components/ko-on-boarding/learn/component', ['exports', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend(_component.default, {
    session: service(),
    onBoardingStatus: service(),

    isRedirected: null,
    showGuide: false,
    isComplete: false,
    imageGroups: [[1], [2, 3], [4, 5, 6]],
    activeGroup: 0,
    activeStep: 1,
    videoPlayer: null,
    testingMode: Ember.testing,

    keyboardShortcuts: {
      esc: 'close',
      left: 'previousStep',
      right: 'nextStep'
    },

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('isRedirected') === 'true') {
        this.set('showGuide', true);
      }

      if (this.get('onBoardingStatus.progress.learn_kayako_completed')) {
        this.set('isComplete', true);
      }

      this.setupVideoPlayer();
    },
    setupVideoPlayer: function setupVideoPlayer() {
      var _this = this;

      window._wq = window._wq || [];
      window._wq.push({
        id: '1gr5j956ux',
        options: {
          playerColor: '#4eafcb'
        },
        onHasData: function onHasData(video) {
          _this.set('videoPlayer', video);
        }
      });
    },
    _changeGroup: function _changeGroup(step) {
      var group = this.get('imageGroups').findIndex(function (group) {
        return group.includes(step);
      });

      if (group !== this.get('activeGroup')) {
        this.set('activeGroup', group);
      }
    },


    actions: {
      show: function show() {
        this.set('showGuide', true);
      },
      close: function close() {
        this.setProperties({
          showGuide: false,
          activeGroup: 0,
          activeStep: 1
        });
      },
      changeStep: function changeStep(step) {
        this._changeGroup(step);
        this.set('activeStep', step);
      },
      nextStep: function nextStep() {
        var activeStep = this.get('activeStep');

        if (this.get('videoPlayer') && activeStep === 1) {
          this.get('videoPlayer').pause();
        }

        if (activeStep < 6) {
          this._changeGroup(activeStep + 1);
          this.incrementProperty('activeStep');
        } else {
          this.send('close');
          if (!this.get('isComplete')) {
            this.set('isComplete', true);
            this.onComplete();
          }
        }
      },
      previousStep: function previousStep() {
        var activeStep = this.get('activeStep');

        if (activeStep > 1) {
          this._changeGroup(activeStep - 1);
          this.decrementProperty('activeStep');
        }
      }
    }
  });
});