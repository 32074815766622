define("frontend-cp/components/ko-notification-center/notification/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-notification-center_notification__item_5rgtds",
    "is-seen": "ko-notification-center_notification__is-seen_5rgtds",
    "item-link": "ko-notification-center_notification__item-link_5rgtds",
    "avatar-container": "ko-notification-center_notification__avatar-container_5rgtds",
    "for-breach": "ko-notification-center_notification__for-breach_5rgtds",
    "avatar": "ko-notification-center_notification__avatar_5rgtds",
    "summary": "ko-notification-center_notification__summary_5rgtds",
    "time": "ko-notification-center_notification__time_5rgtds"
  };
});