define('frontend-cp/helpers/ko-admin/forms/link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Helper = Ember.Helper;
  var htmlSafe = Ember.String.htmlSafe;
  var service = Ember.inject.service;
  exports.default = Helper.extend({
    i18n: service(),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          link = _ref2[0],
          linkText = _ref2[1];

      if (link) {
        var localizedLinkText = this.get('i18n').t(linkText);
        return htmlSafe('<a href="' + link + '" target="_blank" rel="noopener noreferrer">' + localizedLinkText + '</a>');
      } else {
        return '';
      }
    }
  });
});