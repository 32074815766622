define('frontend-cp/session/admin/apps/endpoints/edit/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)(), {
    model: function model(params) {
      return this.store.findRecord('endpoint', params.endpoint_id);
    },
    setupController: function setupController(controller, endpoint) {
      controller.setProperties({ endpoint: endpoint });
      controller.initEdits();
    }
  });
});