define("frontend-cp/components/ko-admin/engagements/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "rule-title": "ko-admin_engagements_index__rule-title_b1g9ee",
    "row-wrapper": "ko-admin_engagements_index__row-wrapper_b1g9ee"
  };
});