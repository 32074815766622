define('frontend-cp/adapters/preview', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    handleResponse: function handleResponse(status, headers, payload) {
      if (headers['date-iso']) {
        this.get('serverClock').set('lastKnownServerTime', headers['date-iso']);
      }
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      } // do not show notification for errors on preview request
    }

  });
});