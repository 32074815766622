define('frontend-cp/components/ko-radio/component', ['exports', 'frontend-cp/lib/keycodes'], function (exports, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var guidFor = Ember.guidFor;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    label: '',
    checked: false,
    onChange: function onChange() {},
    qaClass: null,
    disabled: false,

    // State
    uniqueId: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.uniqueId = guidFor(this);
    },


    // Actions
    actions: {
      keyDown: function keyDown(e) {
        if (e.keyCode === _keycodes.space) {
          e.preventDefault();
        }
      },
      keyUp: function keyUp(e) {
        if (e.keyCode === _keycodes.space) {
          this.get('onChange')(true);
          e.preventDefault();
        }
      }
    }
  });
});