define('frontend-cp/components/ko-admin/views/edit/component', ['exports', 'ember-concurrency', 'frontend-cp/lib/keycodes', 'frontend-cp/components/ko-admin/predicate-builder/proposition/styles', 'frontend-cp/components/ko-admin/predicate-builder/styles'], function (exports, _emberConcurrency, _keycodes, _styles, _styles2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var equal = Ember.computed.equal;
  var $ = Ember.$;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var computed = Ember.computed;
  var on = Ember.on;
  exports.default = Component.extend({
    // Attributes
    caseView: null,
    editedCaseView: null,
    title: null,
    schema: null,
    onCancel: function onCancel() {},
    onDelete: function onDelete() {},
    onSuccess: function onSuccess() {},

    // State
    teams: [],
    definitions: null,
    availableColumns: [],

    // Services
    confirmation: service(),
    store: service(),
    i18n: service(),
    virtualModel: service(),

    // CPs
    sharedWithSelf: equal('editedCaseView.visibilityType', 'SELF'),
    sharedWithAll: equal('editedCaseView.visibilityType', 'ALL'),
    sharedWithTeam: equal('editedCaseView.visibilityType', 'TEAM'),

    titleOrSharingEditDisabled: computed('save.isRunning', 'caseView.viewType', function () {
      return this.get('save.isRunning') || this.get('caseView.viewType') === 'INBOX';
    }),

    canBeDeleted: computed('caseView.isNew', 'caseView.viewType', function () {
      var view = this.get('caseView');
      return !view.get('isNew') && !['INBOX', 'TRASH'].includes(view.get('viewType'));
    }),

    sortingDirections: computed(function () {
      return [EmberObject.create({ id: 'ASC', value: this.get('i18n').t('generic.sort.ASC') }), EmberObject.create({ id: 'DESC', value: this.get('i18n').t('generic.sort.DESC') })];
    }),

    initAvailableColumns: on('init', function () {
      var _this = this;

      this.get('store').findAll('column').then(function (columns) {
        _this.set('availableColumns', columns);
      });
    }),

    initDefinitions: on('init', function () {
      this.set('definitions', this.get('store').query('definition', { type: 'view' }));
    }),

    initTeams: on('init', function () {
      var _this2 = this;

      this.get('store').findAll('team').then(function (teams) {
        _this2.set('teams', teams);
      });
    }),

    // CPs
    availableTeams: computed('teams.@each.id', 'selectedTeams.@each.id', function () {
      var editedTeamIds = this.get('selectedTeams').mapBy('id');
      return this.get('teams').filter(function (team) {
        return editedTeamIds.indexOf(team.get('id')) === -1;
      });
    }),

    selectedTeams: computed('editedCaseView.visibilityToTeams.[]', function () {
      return this.get('editedCaseView.visibilityToTeams').toArray();
    }),

    orderedAvailableColumnList: computed('availableColumns.[]', function () {
      return this.get('availableColumns').sortBy('title');
    }),

    sortOrderDirections: computed(function () {
      return [{
        label: this.get('i18n').t('generic.sort.ASC'),
        direction: 'ASC'
      }, {
        label: this.get('i18n').t('generic.sort.DESC'),
        direction: 'DESC'
      }];
    }),

    selectedSortableColumn: computed('editedCaseView.orderByColumn', 'availableColumns.[]', function () {
      var columns = this.get('availableColumns');
      var selectedColumn = this.get('editedCaseView.orderByColumn');

      return columns.findBy('id', selectedColumn);
    }),

    selectedSortingDirection: computed('editedCaseView.orderBy', function () {
      var sortOrderDirections = this.get('sortOrderDirections');
      var sortDirection = this.get('editedCaseView.orderBy');

      return sortOrderDirections.findBy('direction', sortDirection);
    }),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var caseView;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              caseView = this.get('caseView');
              _context.next = 3;
              return this.get('virtualModel').save(caseView, this.get('editedCaseView'), this.get('schema'));

            case 3:

              caseView.get('predicateCollections').forEach(function (predicateCollection) {
                return predicateCollection.get('propositions').filter(function (proposition) {
                  return proposition.get('isNew');
                }).forEach(function (proposition) {
                  return proposition.unloadRecord();
                });
              });

              caseView.get('predicateCollections').filter(function (predicateCollection) {
                return predicateCollection.get('isNew');
              }).forEach(function (predicateCollection) {
                return predicateCollection.unloadRecord();
              });

              this.get('onSuccess')();

            case 6:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    actions: {
      cancel: function cancel() {
        this.get('onCancel')();
      },
      setViewSharingSelf: function setViewSharingSelf() {
        this.set('editedCaseView.visibilityType', 'SELF');
        this.get('editedCaseView.visibilityToTeams').clear();
      },
      setViewSharingAll: function setViewSharingAll() {
        this.set('editedCaseView.visibilityType', 'ALL');
        this.get('editedCaseView.visibilityToTeams').clear();
      },
      setViewSharingTeam: function setViewSharingTeam() {
        this.set('editedCaseView.visibilityType', 'TEAM');
      },
      addCollection: function addCollection() {
        this.get('editedCaseView.predicateCollections').pushObject({
          propositions: [{}]
        });
      },
      removeCollection: function removeCollection(predicateCollection, id) {
        var _this3 = this;

        var element = $('#' + id);
        element.addClass(_styles2.default.containerRemoving);
        element.fadeTo(400, 0, function () {
          _this3.get('editedCaseView.predicateCollections').removeObject(predicateCollection);
        });
      },
      addPropositionToCollection: function addPropositionToCollection(predicateCollection) {
        get(predicateCollection, 'propositions').pushObject({});
      },
      removePropositionFromCollection: function removePropositionFromCollection(predicateCollection, proposition, id) {
        var element = $('#' + id);
        element.addClass(_styles.default.containerRemoving);
        element.fadeTo(400, 0, function () {
          get(predicateCollection, 'propositions').removeObject(proposition);
        });
      },
      selectOrderByColumn: function selectOrderByColumn(column) {
        this.set('editedCaseView.orderByColumn', column.id);
      },
      selectOrderByDirection: function selectOrderByDirection(sortOrder) {
        this.set('editedCaseView.orderBy', sortOrder.direction);
      },
      deleteView: function deleteView() {
        var _this4 = this;

        return this.get('confirmation').confirm({
          intlConfirmLabel: 'generic.confirm.delete_button',
          intlConfirmationBody: 'admin.views.confirm_delete.body',
          intlConfirmationHeader: 'admin.views.confirm_delete.title'
        }).then(function () {
          return _this4.get('caseView').destroyRecord().then(function () {
            _this4.get('onDelete')();
          });
        });
      },
      selectTeam: function selectTeam(team) {
        this.set('editedCaseView.visibilityType', 'TEAM');
        this.get('editedCaseView.visibilityToTeams').setObjects(team);
      },
      addColumn: function addColumn(column) {
        this.get('editedCaseView.columns').pushObject(column);
      },
      removeColumn: function removeColumn(column) {
        this.get('editedCaseView.columns').removeObject(column);
      },
      reorderColumn: function reorderColumn(list) {
        this.get('editedCaseView.columns').setObjects(list);
      },
      preventFormSubmission: function preventFormSubmission(_, e) {
        if (e.keyCode === _keycodes.enter) {
          e.preventDefault();
        }
      }
    }
  });
});