define('frontend-cp/session/agent/users/user/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route'], function (exports, _tabbedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  var RSVP = Ember.RSVP;
  exports.default = _tabbedRoute.default.extend({
    processManager: service(),

    model: function model(params) {
      return this.store.findRecord('user', params.user_id);
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(model, 'user');
      processManager.setForegroundProcess(process);

      var tabStore = this.get('tabStore');

      var _model$getProperties = model.getProperties('tags'),
          tags = _model$getProperties.tags;

      var tab = this.retrieveTab(transition);

      tabStore.update(tab, { process: process });
      tabStore.set('activeTab', tab);
      this.set('tab', tab);

      return RSVP.resolve(tags).then(function () {
        var state = process.get('state');

        if (!state) {
          var UserStateManager = getOwner(_this).factoryFor('state-manager:user');
          state = UserStateManager.create({ user: model, tab: tab });
          process.set('state', state);
        }

        _this.set('state', state);
      });
    },
    retrieveTab: function retrieveTab(transition) {
      var newUserController = this.controllerFor('session.agent.users.new.index');
      var tabStore = this.get('tabStore');

      if (!newUserController.get('openInSameTab')) {
        return tabStore.open(transition);
      }

      var state = tabStore.decomposeTransition(transition);
      var transitionTabPath = newUserController.get('transitionTabPath');
      var tab = tabStore.getTab(transitionTabPath);

      newUserController.set('openInSameTab', false);
      newUserController.set('transitionTabPath', null);

      tabStore.update(tab, state);

      return tab;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('state', this.get('state'));
    },


    actions: {
      willTransition: function willTransition() {
        var processManager = this.get('processManager');
        processManager.setForegroundProcess(null);

        this._super.apply(this, arguments);
      }
    }
  });
});