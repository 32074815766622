define('frontend-cp/components/ko-insights/loader/help-center/search/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectProxy = Ember.ObjectProxy;
  var PromiseProxyMixin = Ember.PromiseProxyMixin;
  var computed = Ember.computed;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend({
    tagName: '',
    insights: service(),
    store: service(),

    metricsQueryParams: null,

    failedSearches: null,
    popularSearches: null,

    request: computed('metricsQueryParams', function () {
      var _this = this;

      var insights = this.get('insights');
      var endpoint = insights.searchEndpoint();
      var adapter = getOwner(this).lookup('adapter:application');

      return ObjectProxy.extend(PromiseProxyMixin).create({
        promise: adapter.ajax(endpoint, 'GET', this.get('metricsQueryParams')).then(function (payload) {
          if (_this.isDestroying || _this.isDestroyed) {
            return null;
          }

          _this.setProperties({
            failedSearches: payload.data.failed_searches ? payload.data.failed_searches.sortBy('attempt_count').reverseObjects() : null,
            popularSearches: payload.data.popular_searches ? payload.data.popular_searches.sortBy('attempt_count').reverseObjects() : null
          });

          return payload;
        })
      });
    })
  });
});