define('frontend-cp/serializers/organization-field-value', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalize: function normalize(modelType, payload) {
      payload.field_id = payload.field.id;
      Reflect.deleteProperty(payload, 'field');
      return this._super.apply(this, arguments);
    }
  });
});