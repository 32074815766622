define('frontend-cp/adapters/case-reply', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    autoIncludeAll: false,

    pathForType: function pathForType() {
      return 'reply';
    },
    urlForCreateRecord: function urlForCreateRecord(modelType, snapshot) {
      var requiredResources = 'case,post,case_message,note,sla_metric,sla_version_target,sla_version,attachment,message_recipient,identity_email,twitter_tweet,twitter_message,facebook_post,facebook_message,facebook_comment';

      var pathForType = this.pathForType();
      var url = this._super.apply(this, arguments);
      return url.replace(pathForType, 'cases/' + snapshot.record.get('case.id') + '/' + pathForType + '?include=' + requiredResources);
    }
  });
});