define('frontend-cp/components/ko-admin/automation-actions-builder/base/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',
    automationAction: null, // mandatory
    definition: null, // mandatory
    smallSlotClass: '',
    bigSlotClass: '',

    requiresValue: computed('automationAction.option', function () {
      var operator = this.get('automationAction.option');
      return ['CHANGE', 'ADD', 'REMOVE', 'REPLACE'].includes(operator);
    }),

    // Lifecycle hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var availableOptions = get(this, 'definition.options');
      var option = get(this, 'automationAction.option');
      if (get(availableOptions, 'length') === 1) {
        set(this, 'automationAction.option', get(this, 'definition.options.firstObject'));
      } else if (availableOptions.indexOf(option) === -1) {
        set(this, 'automationAction.option', null);
      }
    }
  });
});