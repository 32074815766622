define('frontend-cp/lib/comma-separate-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = commaSeparateList;
  function commaSeparateList(list) {
    return list.split(/[ ,\n]/).filter(function (x) {
      return x;
    }).join(',');
  }
});