define('frontend-cp/components/ko-user-content/field/timezone-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var oneWay = Ember.computed.oneWay;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Params
    isEdited: false,
    isErrored: false,
    isKREEdited: false,
    onChangeTimezone: null,
    timezone: null,
    isDisabled: false,

    timezoneService: service('timezones'),

    tagName: '',

    timezones: oneWay('timezoneService.timeZones')

  });
});