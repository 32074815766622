define('frontend-cp/components/ko-insights/loader/actor/team/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectProxy = Ember.ObjectProxy;
  var PromiseProxyMixin = Ember.PromiseProxyMixin;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    store: service(),
    i18n: service(),
    metricsQueryParams: null,

    actorId: null,
    request: null,

    onUpdate: function onUpdate() {},

    init: function init() {
      this._super.apply(this, arguments);

      var promise = ObjectProxy.extend(PromiseProxyMixin).create({
        promise: this.get('store').findAll('team')
      });

      this.set('request', promise);
    },


    data: reads('request.content'),

    actor: computed('data', 'actorId', function () {
      var _this = this;

      var data = this.get('data');

      if (!data) {
        return {};
      }

      return this.get('data').find(function (actor) {
        return actor.get('id') === _this.get('actorId');
      });
    }),

    description: computed('actor.title', function () {
      var i18n = this.get('i18n');

      return i18n.t('insights.teams.subtitle', {
        team: this.get('actor.title')
      });
    })
  });
});