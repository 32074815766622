define('frontend-cp/services/confirmation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    i18n: service(),
    activeConfirmation: null,
    confirmationPromise: null,

    /* Store functions to accept/reject current confirmation promise */
    _acceptConfirmation: null,
    _rejectConfirmation: null,

    confirm: function confirm(_ref) {
      var _this = this;

      var intlConfirmationHeader = _ref.intlConfirmationHeader,
          intlConfirmationBody = _ref.intlConfirmationBody,
          _ref$intlConfirmLabel = _ref.intlConfirmLabel,
          intlConfirmLabel = _ref$intlConfirmLabel === undefined ? 'generic.confirm_button' : _ref$intlConfirmLabel,
          _ref$intlCancelLabel = _ref.intlCancelLabel,
          intlCancelLabel = _ref$intlCancelLabel === undefined ? 'generic.cancel' : _ref$intlCancelLabel,
          _ref$isIntl = _ref.isIntl,
          isIntl = _ref$isIntl === undefined ? false : _ref$isIntl,
          _ref$confirmButtonTyp = _ref.confirmButtonType,
          confirmButtonType = _ref$confirmButtonTyp === undefined ? 'primary' : _ref$confirmButtonTyp;


      if (isIntl) {
        this.set('activeConfirmation', {
          text: intlConfirmationBody,
          header: intlConfirmationHeader ? intlConfirmationHeader : null,
          confirmLabel: intlConfirmLabel,
          cancelLabel: this.get('i18n').t(intlCancelLabel)
        });
      } else {
        this.set('activeConfirmation', {
          text: this.get('i18n').t(intlConfirmationBody),
          header: intlConfirmationHeader ? this.get('i18n').t(intlConfirmationHeader) : null,
          confirmLabel: this.get('i18n').t(intlConfirmLabel),
          cancelLabel: this.get('i18n').t(intlCancelLabel)
        });
      }

      this.set('activeConfirmation.confirmButtonType', confirmButtonType);

      return new EmberPromise(function (accept, cancel) {
        _this.set('_acceptConfirmation', accept);
        _this.set('_rejectConfirmation', cancel);
      }).finally(function () {
        _this.set('activeConfirmation', null);
        _this.set('_acceptConfirmation', null);
        _this.set('_rejectConfirmation', null);
      });
    },
    cancelConfirmation: function cancelConfirmation() {
      this.get('_rejectConfirmation')(false);
    },
    acceptConfirmation: function acceptConfirmation() {
      this.get('_acceptConfirmation')(true);
    }
  });
});