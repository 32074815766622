define('frontend-cp/session/agent/cases/index/suspended-messages/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(_ref) {
      var id = _ref.id;

      return this.modelFor('session.agent.cases.index.suspended-messages').findBy('id', id);
    },


    actions: {
      permanentlyDelete: function permanentlyDelete() {
        var _this = this;

        this.modelFor(this.routeName).destroyRecord().then(function () {
          return _this.transitionTo('session.agent.cases.index.suspended-messages');
        });
      },
      allowThrough: function allowThrough() {
        var _this2 = this;

        var mail = this.modelFor(this.routeName);
        mail.set('isSuspended', false);
        mail.save().then(function () {
          return _this2.transitionTo('session.agent.cases.index.suspended-messages');
        });
      },
      onClose: function onClose() {
        this.transitionTo('session.agent.cases.index.suspended-messages.index');
      }
    }
  });
});