define('frontend-cp/models/plan', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    features: _emberDataModelFragments.default.fragmentArray('feature', { async: false }),
    limits: _emberDataModelFragments.default.fragment('plan-limit', { async: false }),
    expiryAt: _emberData.default.attr('date'),
    product: _emberData.default.belongsTo('product'),
    billing: _emberDataModelFragments.default.fragment('billing'),
    accountId: _emberData.default.attr('string'),
    subscriptionId: _emberData.default.attr('string'),
    opportunityId: _emberData.default.attr('string'),
    leadId: _emberData.default.attr('string'),
    isGrandfathered: _emberData.default.attr('boolean'),
    rateplanId: _emberData.default.attr('string')
  });
});