define("frontend-cp/components/ko-admin/forms/group/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "group": "ko-admin_forms_group__group_9ysg3g",
    "group--with-legend": "ko-admin_forms_group__group--with-legend_9ysg3g",
    "legend": "ko-admin_forms_group__legend_9ysg3g",
    "contents": "ko-admin_forms_group__contents_9ysg3g"
  };
});