define('frontend-cp/services/adapter-request-logger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    _hasRequestedAllRecordsHash: null,

    init: function init() {
      this._hasRequestedAllRecordsHash = {};
    },
    hasRequestedAllRecords: function hasRequestedAllRecords(modelName) {
      return this._hasRequestedAllRecordsHash[modelName];
    },
    setRequestedAllRecords: function setRequestedAllRecords(modelName) {
      this._hasRequestedAllRecordsHash[modelName] = true;
    }
  });
});