define('frontend-cp/components/ko-file-size/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;


  function readableFilesize(bytes, unitSet) {
    // this is correct for kilo, 1024 is for kibi
    var thresh = 1000;

    if (Math.abs(bytes) < thresh) {
      return {
        size: String(bytes),
        unit: unitSet.B
      };
    }
    var units = [unitSet.kB, unitSet.MB, unitSet.GB];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return {
      size: String(bytes.toFixed(2)),
      unit: units[u]
    };
  }

  exports.default = Component.extend({
    tagName: 'span',
    size: null,
    i18n: service(),

    options: computed('size', function () {
      var i18n = this.get('i18n');

      var units = {
        B: i18n.t('generic.units.B'),
        kB: i18n.t('generic.units.kB'),
        MB: i18n.t('generic.units.MB'),
        GB: i18n.t('generic.units.GB')
      };

      return readableFilesize(this.get('size'), units);
    })
  });
});