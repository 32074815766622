define('frontend-cp/session/admin/apps/atlasai/controller', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    store: service(),
    i18n: service(),
    notification: service(),
    virtualModel: service(),

    settings: null,
    settingsObj: null,
    wasEnabled: false,
    isEnabled: false,
    isRunning: false,

    onCancel: function onCancel() {},

    initEdits: function initEdits() {
      var settingsObj = {};
      this.get('settings').forEach(function (setting) {
        settingsObj[setting.get('name')] = setting.get('value');
      });
      this.set('isEnabled', ['ENABLED', 'ACTIVE'].includes(settingsObj.status));
      this.set('wasEnabled', ['ENABLED', 'ACTIVE'].includes(settingsObj.status));
      this.set('settingsObj', settingsObj);
    },
    isEdited: function isEdited() {
      return this.get('isEnabled') !== this.get('wasEnabled');
    },


    label: computed('settingsObj.status', 'isEnabled', 'wasEnabled', function () {
      if (this.isEdited()) {
        var isEnabled = this.get('isEnabled');
        if (isEnabled) {
          return this.get('i18n').t('admin.apps.atlasai.will_be_enabled');
        } else {
          return this.get('i18n').t('admin.apps.atlasai.will_be_disabled');
        }
      }
      var status = this.get('settingsObj').status;
      switch (status) {
        case 'ACTIVE':
          return this.get('i18n').t('admin.apps.atlasai.active');
        case 'ENABLED':
          return this.get('i18n').t('admin.apps.atlasai.enabled');
        case 'DISABLED':
          return this.get('i18n').t('admin.apps.atlasai.disabled');
        default:
          return this.get('i18n').t('admin.apps.atlasai.disabled');
      }
    }),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var _this = this;

      var isEnabled, adapter;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (this.isEdited()) {
                _context.next = 2;
                break;
              }

              return _context.abrupt('return');

            case 2:
              this.set('isRunning', true);
              this.set('errors', {});
              try {
                isEnabled = this.get('isEnabled');
                adapter = this.store.adapterFor('atlasai');


                (isEnabled ? adapter.register() : adapter.deregister()).then(function (response) {
                  _this.get('notification').add({
                    type: 'success',
                    title: _this.get('i18n').t('generic.changes_saved'),
                    autodismiss: true
                  });

                  // Update settingsObj based on the response
                  var updatedSettings = response.data.reduce(function (acc, setting) {
                    acc[setting.name] = setting.value;
                    return acc;
                  }, {});

                  _this.set('settingsObj', updatedSettings);
                  _this.set('wasEnabled', isEnabled);
                  _this.set('isRunning', false);
                }).catch(function (error) {
                  if (error.errors) {
                    var errors = {};
                    error.errors.forEach(function (_ref) {
                      var parameter = _ref.parameter,
                          message = _ref.message;

                      errors[parameter] = [{ message: message }];
                    });
                    _this.set('errors', errors);
                  }
                  _this.set('isRunning', false);
                });
              } catch (e) {
                this.set('isRunning', false);
              }

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    actions: {
      toggle: function toggle(enabled) {
        this.set('isEnabled', enabled);
      }
    }
  });
});