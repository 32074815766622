define('frontend-cp/components/ko-universal-search/component', ['exports', 'ember-service/inject', 'ember-concurrency', 'ember-keyboard-shortcuts/mixins/component', 'frontend-cp/components/ko-universal-search/styles'], function (exports, _inject, _emberConcurrency, _component, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var bool = Ember.computed.bool;
  var jQuery = Ember.$;
  var guidFor = Ember.guidFor;


  var QUERY_DEBOUNCE_INTERVAL = 500;

  exports.default = Component.extend(_component.default, {
    launchDarkly: (0, _inject.default)(),

    tagName: '',

    // Attributes
    groupSelect: false,
    displayInline: false,
    showSearchHints: true,
    showHelpBlock: true,
    showSeeMoreButton: true,
    searchWithin: null,
    resultsToExclude: null,
    onLoadSearchRoute: null,
    highlightInputOnFocus: false,
    hasInlineInputBorder: false,
    isFocused: false,
    onClose: function onClose() {},
    onSeeMore: function onSeeMore() {},
    onSearchStateChange: function onSearchStateChange() {},

    // State
    searchQuery: null,
    highlightedResult: null,

    // Services
    i18n: service(),
    store: service(),
    metrics: service(),
    notification: service(),
    searchHistory: service(),
    universalSearch: service(),
    searchSuggestions: service(),

    keyboardShortcuts: {
      esc: 'closeSearch',
      down: 'highlightNextResult',
      up: 'highlightPreviousResult',
      enter: 'selectHighlightedResult'
    },

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('resultsToExclude')) {
        this.set('resultsToExclude', []);
      }
      this.handleRootMouseDown = this.handleRootMouseDown.bind(this);
    },
    didInsertElement: function didInsertElement() {
      self.document.body.addEventListener('mousedown', this.handleRootMouseDown, true);
      jQuery('#' + this.get('inputId')).focus();
    },
    willDestroyElement: function willDestroyElement() {
      self.document.body.removeEventListener('mousedown', this.handleRootMouseDown, true);
    },


    // CPs
    elementGuid: computed(function () {
      return 'ko-' + guidFor(this);
    }),

    inputId: computed('elementGuid', function () {
      return this.get('elementGuid') + '-input';
    }),

    searchResults: computed('doSearch.lastSuccessful.value', 'resultsToExclude.[]', function () {
      var records = this.get('doSearch.lastSuccessful.value');
      var resultsToExclude = this.get('resultsToExclude');

      if (!records) {
        return null;
      }

      var recordsOfValidResource = records.filter(function (record) {
        return ['organization', 'user', 'case', 'article'].indexOf(record.get('resource')) > -1;
      });

      var validRecordsWithExclusionsApplied = recordsOfValidResource.filter(function (valid) {
        return !resultsToExclude.some(function (excluded) {
          return valid.get('id') && excluded.id === valid.get('id').substr(valid.get('id').lastIndexOf('/') + 1) && excluded.type === valid.get('resource');
        });
      });

      return validRecordsWithExclusionsApplied;
    }),

    searchActive: computed('searchQuery.length', function () {
      var searchQueryLength = this.get('searchQuery.length');
      if (searchQueryLength) {
        this.get('onSearchStateChange')(true);
        return true;
      } else {
        this.get('onSearchStateChange')(false);
        return false;
      }
    }),

    isSearching: bool('searchQuery'),

    searchQueryIsValid: computed.gte('searchQuery.length', 3),

    recentSearches: computed.readOnly('searchHistory.recentSearches'),

    suggestedSearchOptions: computed.readOnly('searchSuggestions.suggestedSearchOptions'),

    allSearchHints: computed('suggestedSearchOptions', 'recentSearches', function () {
      return this.get('suggestedSearchOptions').concat(this.get('recentSearches'));
    }),

    adjustScroll: function adjustScroll(highlightIndex) {
      var down = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var rowHeight = 64;
      var highlight = rowHeight * highlightIndex;
      var element = jQuery('.' + _styles.default.list);

      if (down) {
        var offsetHeight = 5 * rowHeight;
        if (highlight - element.scrollTop() > offsetHeight) {
          element.scrollTop(highlight - offsetHeight);
        }
      } else {
        if (highlight < element.scrollTop()) {
          element.scrollTop(highlight);
        }
      }
    },


    doSearch: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(value) {
      var searchResults, searchWithin;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              this.setProperties({
                searchQuery: value,
                highlightedResult: null
              });

              if (this.get('searchQueryIsValid')) {
                _context.next = 3;
                break;
              }

              return _context.abrupt('return', null);

            case 3:
              _context.next = 5;
              return (0, _emberConcurrency.timeout)(QUERY_DEBOUNCE_INTERVAL);

            case 5:
              searchResults = null;
              searchWithin = this.get('searchWithin');

              if (!searchWithin) {
                _context.next = 13;
                break;
              }

              _context.next = 10;
              return this.get('universalSearch').search(searchWithin + ' ' + value);

            case 10:
              searchResults = _context.sent;
              _context.next = 16;
              break;

            case 13:
              _context.next = 15;
              return this.get('universalSearch').search(value);

            case 15:
              searchResults = _context.sent;

            case 16:
              this.get('searchHistory').logSearch(value);
              return _context.abrupt('return', searchResults);

            case 18:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    // Methods
    highlightNextResult: function highlightNextResult() {
      var isSearching = this.get('isSearching');
      if (isSearching && !this.get('searchQueryIsValid')) {
        return;
      }

      var currentlyHighlightedResult = this.get('highlightedResult');
      var results = isSearching ? this.get('searchResults') : this.get('allSearchHints');

      if (!currentlyHighlightedResult) {
        this.set('highlightedResult', results.get('firstObject'));
        return;
      }

      var currentIndex = results.indexOf(currentlyHighlightedResult);
      if (currentIndex < results.get('length') - 1) {
        this.set('highlightedResult', results.objectAt(currentIndex + 1));
        this.adjustScroll(currentIndex + 1, true);
      }
    },
    highlightPreviousResult: function highlightPreviousResult() {
      var isSearching = this.get('isSearching');
      if (isSearching && !this.get('searchQueryIsValid')) {
        return;
      }

      var currentlyHighlightedResult = this.get('highlightedResult');
      var results = isSearching ? this.get('searchResults') : this.get('allSearchHints');
      var currentIndex = results.indexOf(currentlyHighlightedResult);

      // back to the start if we try to go below the list
      if (currentIndex > 0) {
        this.set('highlightedResult', results.objectAt(currentIndex - 1));
        this.adjustScroll(currentIndex - 1, false);
      }
    },
    selectSearchSuggestion: function selectSearchSuggestion(suggestion) {
      if ((typeof suggestion === 'undefined' ? 'undefined' : _typeof(suggestion)) === 'object') {
        suggestion = suggestion.searchTerm;
      }

      this.set('searchQuery', suggestion);
      this.get('searchHistory').logSearch(suggestion);
      this.get('doSearch').perform(suggestion);
    },
    handleRootMouseDown: function handleRootMouseDown(e) {
      if (!jQuery('#' + this.get('elementGuid'))[0].contains(e.target)) {
        this.closeSearch();
      }
    },
    closeSearch: function closeSearch() {
      this.get('doSearch').perform('');
      this.set('searchQuery', null);
      this.get('onClose')();
    },


    actions: {
      highlightResult: function highlightResult(highlightedResult) {
        this.set('highlightedResult', highlightedResult);
      },
      closeSearch: function closeSearch() {
        this.closeSearch();
      },
      highlightNextResult: function highlightNextResult() {
        this.highlightNextResult();
      },
      highlightPreviousResult: function highlightPreviousResult() {
        this.highlightPreviousResult();
      },
      selectHighlightedSearchSuggestion: function selectHighlightedSearchSuggestion(suggestion) {
        this.selectSearchSuggestion(suggestion);
      },
      updateMergeList: function updateMergeList(operation, result) {
        this.get('onLoadSearchRoute')(operation, result);
      },
      selectHighlightedResult: function selectHighlightedResult(hasModifier) {
        var launchDarkly = this.get('launchDarkly');

        var result = this.get('highlightedResult');
        if (!result) {
          return;
        }

        if (!this.get('isSearching')) {
          this.selectSearchSuggestion(result);
          return;
        }

        this.get('searchHistory').logSearch(this.get('searchQuery'));

        if (!hasModifier) {
          this.closeSearch();
        } else {
          var notificationText = this.get('i18n').t('cases.search.open_in_background', { title: result.get('title') });
          this.get('notification').success(notificationText);
        }

        if (launchDarkly.get('ops-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'Search - Open a result',
            category: 'Agent'
          });
        }

        var routes = {
          user: 'session.agent.users.user',
          case: 'session.agent.cases.case',
          organization: 'session.agent.organizations.organization'
        };

        var resourceType = result.get('resource');

        if (resourceType === 'article') {
          var article = this.get('store').peekRecord('article', result.get('resultData.id'));
          var $articleLink = jQuery('<a href="' + (article.get('helpcenterUrl') || '/article/' + article.get('id')) + '" target="_blank" rel="noopener noreferrer" style="visibility: hidden;"></a>');
          jQuery('body').append($articleLink);
          $articleLink.get(0).click();
          $articleLink.remove();
        } else {
          var route = routes[resourceType];
          this.get('onLoadSearchRoute')(route, result.get('resultData.content'), hasModifier);
        }
      },
      navigateSeeMore: function navigateSeeMore() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('ops-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'Search - Open advanced search',
            category: 'Agent'
          });
        }

        this.get('onSeeMore')(this.get('searchQuery'));
        this.closeSearch();
      }
    }
  });
});