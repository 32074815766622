define('frontend-cp/components/ko-admin/rateplans/summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // attributes
    rateplanLabel: null,
    selectedNumberOfSeats: null,
    subscriptionAmount: 0,
    calculatingSummary: false,
    currency: null,
    selectedTerm: null,
    nextChargeDate: null,
    discountAmount: 0,
    grossTotal: 0,
    isDisabled: false
  });
});