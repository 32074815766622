define('frontend-cp/models/case-message', ['exports', 'ember-data', 'frontend-cp/models/postable'], function (exports, _emberData, _postable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _postable.default.extend({
    uuid: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    bodyText: _emberData.default.attr('string'),
    bodyHtml: _emberData.default.attr('string'),
    fullname: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    creationMode: _emberData.default.attr('string'),
    locale: _emberData.default.attr('string'),
    responseTime: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    // Relations
    case: _emberData.default.belongsTo('case', { async: true }),
    creator: _emberData.default.belongsTo('user', { async: true }),
    identity: _emberData.default.belongsTo('identity', { async: true }),
    mailbox: _emberData.default.belongsTo('mailbox', { async: true }),
    location: _emberData.default.belongsTo('location', { async: true }),

    recipients: _emberData.default.hasMany('message-recipient', { async: true }),
    attachments: _emberData.default.hasMany('attachment', { async: true }),

    postType: 'message',
    isMessage: true
  });
});