define('frontend-cp/components/app-version-notifier/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;


  var LS_NAMESPACE = 'app-version-notifier';
  var LS_KEY = 'v1';

  exports.default = Component.extend({
    tagName: '',

    appVersion: null,
    activeVersion: null,
    notificationFrequency: 0,

    config: service(),
    cookies: service(),
    locale: service(),

    localStorage: service('localStore'),
    showReloadNotification: false,
    translationsLoaded: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('locale').setup().then(function () {
        _this.set('translationsLoaded', true);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var appVersion = this.get('appVersion');
      var activeVersion = this.get('activeVersion');
      var frequency = this.get('notificationFrequency');
      var isLightningPreview = this._isLightningPreview();

      if (appVersion === null || activeVersion === null || isLightningPreview) {
        this.set('showReloadNotification', false);
      } else if (appVersion !== activeVersion) {
        var isAppropriateToNotifyUser = this._isAppropriateToNotifyUser(frequency);

        if (isAppropriateToNotifyUser) {
          this._updateLastNotified(activeVersion);
          this.set('showReloadNotification', true);
        }
      } else {
        this.set('showReloadNotification', false);
      }
    },
    _isAppropriateToNotifyUser: function _isAppropriateToNotifyUser(frequency) {
      var json = this.get('localStorage').getItem(LS_NAMESPACE, LS_KEY, { persist: true });

      if (!json) {
        return true;
      }

      var now = _moment.default.utc();
      var lastNotifiedAt = json.lastNotifiedAt && _moment.default.utc(json.lastNotifiedAt);
      var hoursSinceLastNotification = now.diff(lastNotifiedAt, 'hours');

      return frequency <= hoursSinceLastNotification;
    },
    _isLightningPreview: function _isLightningPreview() {
      return this.get('cookies').read(this.get('config.lightningVersionCookieName'));
    },
    _updateLastNotified: function _updateLastNotified(version) {
      var data = {
        version: version,
        lastNotifiedAt: _moment.default.utc().toISOString()
      };

      this.get('localStorage').setItem(LS_NAMESPACE, LS_KEY, data, { persist: true });
    },


    actions: {
      reloadBrowser: function reloadBrowser(e) {
        e.preventDefault();

        window.location.reload(true);
      }
    }
  });
});