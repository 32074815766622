define('frontend-cp/session/admin/manage/case-forms/edit/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend((0, _route.default)(), {
    store: service(),

    model: function model(params) {
      var store = this.get('store');

      return RSVP.hash({
        brands: store.findAll('brand'),
        caseForm: store.findRecord('case-form', params.case_form_id),
        // we need all fields, even the ones that haven't been added to a form
        caseFields: store.query('case-field', { include: [] })
      });
    },
    afterModel: function afterModel(model) {
      if (model.caseForm.get('isDeleted')) {
        this.transitionTo('session.admin.manage.case-forms.index');
        return;
      }

      var store = this.get('store');
      var locales = store.peekAll('locale').filterBy('isPublic', true);
      var customerTitles = model.caseForm.get('customerTitles');
      var descriptions = model.caseForm.get('descriptions');

      // HOTFIX FT-1897
      // Remove any fields that don’t appear in model.caseFields (the canonical list).
      // They are soft-deleted and showing up by mistake.
      //
      // Note also: the form’s case-fields are side-loaded for now, until the backend
      // is patched such that it does not include soft-deleted fields in the form’s
      // “fields” list.
      //
      // If and when PDM-9524 lands, we can revert this out.
      var filteredFields = model.caseForm.get('fields').filter(function (field) {
        return model.caseFields.includes(field);
      });
      model.caseForm.set('fields', filteredFields);

      locales.forEach(function (element) {
        var locale = element.get('locale');

        if (!customerTitles.findBy('locale', locale)) {
          customerTitles.pushObject(store.createRecord('locale-field', { locale: locale }));
        }

        if (!descriptions.findBy('locale', locale)) {
          descriptions.pushObject(store.createRecord('locale-field', { locale: locale }));
        }
      });
    },
    setupController: function setupController(controller, props) {
      controller.setProperties(props);
      controller.initEdits();
    }
  });
});