define('frontend-cp/components/ko-entity-list/sidebar/definition/operator/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    //Attrs
    definition: null,
    currentPropositions: null,
    positionInPropositions: null,

    //State

    //Serivces

    //Lifecycle Hooks

    //CP's
    selectedOperator: computed('currentPropositions.[]', 'positionInPropositions', function () {
      var currentPropositions = this.get('currentPropositions');
      var positionInPropositions = this.get('positionInPropositions');

      return currentPropositions[positionInPropositions].operator;
    }),

    operatorDefinitionForSelected: computed('selectedOperator', function () {
      var _this = this;

      return this.get('definition.operators').find(function (operator) {
        return operator.get('label') === _this.get('selectedOperator');
      });
    }),

    //Actions
    actions: {
      onSelectedOperatorChange: function onSelectedOperatorChange(selectedOperator) {
        var currentPropositions = this.get('currentPropositions');
        var positionInPropositions = this.get('positionInPropositions');
        var inputType = this.get('definition.operators').find(function (operator) {
          return operator.get('label') === selectedOperator.get('label');
        }).get('input.inputType');
        var value = currentPropositions[positionInPropositions].value;

        if (inputType === 'DATE_ABSOLUTE' && !(0, _moment.default)(value).isValid()) {
          value = null;
        } else if (inputType === 'DATE_RELATIVE' && (0, _moment.default)(value).isValid()) {
          value = null;
        }

        this.sendAction('onPropositionsChange', positionInPropositions, {
          label: this.get('definition.label').toLowerCase(),
          field: selectedOperator.get('originalFieldName'),
          operator: selectedOperator.get('label'),
          value: value
        });
      }
    }
  });
});