define("frontend-cp/components/ko-select/multiple/pill/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "pill": "ko-select_multiple_pill__pill_75re94",
    "pill--error": "ko-select_multiple_pill__pill--error_75re94",
    "pill--new": "ko-select_multiple_pill__pill--new_75re94",
    "pill--kre": "ko-select_multiple_pill__pill--kre_75re94",
    "text": "ko-select_multiple_pill__text_75re94",
    "remove": "ko-select_multiple_pill__remove_75re94",
    "close": "ko-select_multiple_pill__close_75re94"
  };
});