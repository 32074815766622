define("frontend-cp/components/ko-universal-search/result/article/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "avatarContainer": "ko-universal-search_result_article__avatarContainer_1nv5ig",
    "icon": "ko-universal-search_result_article__icon_1nv5ig",
    "creatorAvatarContainer": "ko-universal-search_result_article__creatorAvatarContainer_1nv5ig",
    "creatorAvatar": "ko-universal-search_result_article__creatorAvatar_1nv5ig",
    "title": "ko-universal-search_result_article__title_1nv5ig",
    "separator": "ko-universal-search_result_article__separator_1nv5ig",
    "status": "ko-universal-search_result_article__status_1nv5ig",
    "date": "ko-universal-search_result_article__date_1nv5ig"
  };
});