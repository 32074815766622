define('frontend-cp/components/ko-input-date-range/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    i18n: service(),
    date: service(),
    localClassNames: ['chartDateRange'],
    rangeStart: null,
    rangeEnd: null,
    onRangeApplied: function onRangeApplied() {},
    onPickerHidden: function onPickerHidden() {},

    ranges: computed(function () {
      var i18n = this.get('i18n');

      var options = {};

      var today = i18n.t('generic.input_date_range.today');
      var yesterday = i18n.t('generic.input_date_range.yesterday');
      var last7Days = i18n.t('generic.input_date_range.last7days');
      var last30Days = i18n.t('generic.input_date_range.last30days');
      var thisMonth = i18n.t('generic.input_date_range.this_month');
      var lastMonth = i18n.t('generic.input_date_range.last_month');

      var currentDate = this.get('date').getCurrentDate();

      options[today] = [(0, _moment.default)(currentDate), (0, _moment.default)(currentDate)];
      options[yesterday] = [(0, _moment.default)().subtract(1, 'days'), (0, _moment.default)().subtract(1, 'days')];
      options[last7Days] = [(0, _moment.default)().subtract(7, 'days'), (0, _moment.default)()];
      options[last30Days] = [(0, _moment.default)().subtract(30, 'days'), (0, _moment.default)()];
      options[thisMonth] = [(0, _moment.default)().startOf('month'), (0, _moment.default)().endOf('month')];
      options[lastMonth] = [(0, _moment.default)().subtract(1, 'month').startOf('month'), (0, _moment.default)().subtract(1, 'month').endOf('month')];

      return options;
    }),

    daysOfWeek: _moment.default.weekdaysMin(),
    monthNames: _moment.default.monthsShort(),

    actions: {
      openDatePicker: function openDatePicker() {
        this.$().find('.daterangepicker-input').trigger('click');
      }
    }
  });
});