define("frontend-cp/components/article-filters-panel/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "filters-panel-container": "article-filters-panel__filters-panel-container_7olcgx",
    "filter-headings": "article-filters-panel__filter-headings_7olcgx",
    "heading-column": "article-filters-panel__heading-column_7olcgx",
    "heading-operator": "article-filters-panel__heading-operator_7olcgx",
    "heading-value": "article-filters-panel__heading-value_7olcgx",
    "filter-row": "article-filters-panel__filter-row_7olcgx",
    "filter-column": "article-filters-panel__filter-column_7olcgx",
    "filter-operator": "article-filters-panel__filter-operator_7olcgx",
    "filter-value": "article-filters-panel__filter-value_7olcgx",
    "filter-value-hidden": "article-filters-panel__filter-value-hidden_7olcgx",
    "remove-filter": "article-filters-panel__remove-filter_7olcgx",
    "add-filter-btn": "article-filters-panel__add-filter-btn_7olcgx",
    "apply-filter-btn": "article-filters-panel__apply-filter-btn_7olcgx",
    "reset-filter-btn": "article-filters-panel__reset-filter-btn_7olcgx",
    "filter-actions": "article-filters-panel__filter-actions_7olcgx",
    "margin-left-20": "article-filters-panel__margin-left-20_7olcgx"
  };
});