define('frontend-cp/lib/location-origin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var locationOrigin = window.location.origin;

  if (!window.location.origin) {
    locationOrigin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }

  exports.default = locationOrigin;
});