define('frontend-cp/session/admin/customizations/case-fields/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    casePriorityCache: service('cache/case-priority'),

    activate: function activate() {
      this._super.apply(this, arguments);
      this.get('casePriorityCache').invalidateCache();
    }
  });
});