define('frontend-cp/models/contact-address', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    isPrimary: _emberData.default.attr('boolean', { defaultValue: false }),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'), // TODO maybe integer?
    country: _emberData.default.attr('string'), // TODO should be country code
    type: _emberData.default.attr('string', { defaultValue: 'OTHER' }),

    parent: _emberData.default.belongsTo('has-addresses', { async: true, polymorphic: true, parent: true })
  });
});