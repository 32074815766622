define('frontend-cp/components/ko-tab-strip/tab/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    tab: null,
    qaClass: null,
    'on-close': function onClose() {},

    init: function init() {
      this._super.apply(this, arguments);

      var tab = this.get('tab');

      if (!tab) {
        throw new Error('Must provide a tab');
      }
    }
  });
});