define('frontend-cp/components/ko-change-password/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	var computed = Ember.computed;
	var service = Ember.inject.service;
	exports.default = Component.extend({
		store: service(),
		notification: service('notification'),
		i18n: service(),

		currentPassword: null,
		newPassword: null,
		confirmPassword: null,

		agentPasswordFields: {
			'agent.password.min_characters': function agentPasswordMin_characters(value, expectedLength) {
				return value && value.length >= expectedLength;
			},
			'agent.password.min_numbers': function agentPasswordMin_numbers(value, expectedLength) {
				return new RegExp('\\d{' + expectedLength + '}').test(value);
			},
			'agent.password.min_symbols': function agentPasswordMin_symbols(value, expectedLength) {
				return new RegExp('[$-/:-?{-~!"^_@#`]{' + expectedLength + '}').test(value);
			},
			'agent.password.require_mixed_case': function agentPasswordRequire_mixed_case(value, expectedLength) {
				return new RegExp('[A-Z]{' + expectedLength + '}').test(value);
			},
			'agent.password.max_consecutive': function agentPasswordMax_consecutive(value, expectedLength) {
				return !new RegExp('(.)\\1{' + (expectedLength - 1) + '}\\1').test(value);
			}
		},

		buttonDisabled: computed('currentPassword', 'confirmPassword', 'policies', function () {
			var policiesMet = this.get('policies').isEvery('passes');
			return !(this.get('currentPassword') && this.get('confirmPassword') && policiesMet);
		}),

		policies: computed('newPassword', function () {
			var _this = this;

			var password = this.get('newPassword');

			var settings = this.get('store').peekAll('setting');

			var passwordChecksFields = Object.keys(this.get('agentPasswordFields'));
			return settings.filter(function (setting) {
				return passwordChecksFields.includes(setting.get('name')) && Number(setting.get('value'));
			}).map(function (setting) {
				return {
					value: setting.get('value'),
					name: setting.get('name').replace('agent.password', 'users.change_password.policies'),
					passes: _this.get('agentPasswordFields')[setting.get('name')](password, Number(setting.get('value')))
				};
			});
		}),

		save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(e) {
			var currentPassword, newPassword, confirmPassword;
			return regeneratorRuntime.wrap(function _callee$(_context) {
				while (1) {
					switch (_context.prev = _context.next) {
						case 0:
							e.preventDefault();
							currentPassword = this.get('currentPassword');
							newPassword = this.get('newPassword');
							confirmPassword = this.get('confirmPassword');

							// Do not proceed if new & confirm passwords do not match

							if (!(!newPassword || newPassword !== confirmPassword)) {
								_context.next = 7;
								break;
							}

							this.get('notification').add({
								type: 'error',
								title: this.get('i18n').t('users.change_password.message.dont_match'),
								autodismiss: true
							});
							return _context.abrupt('return');

						case 7:
							_context.next = 9;
							return this.get('store').adapterFor('user').changePassword(currentPassword, newPassword);

						case 9:

							this.get('notification').add({
								type: 'success',
								title: this.get('i18n').t('users.change_password.message.success'),
								autodismiss: true
							});

							// Close the modal
							this.get('onClose')();

						case 11:
						case 'end':
							return _context.stop();
					}
				}
			}, _callee, this);
		})).drop()
	});
});