define("frontend-cp/components/articles-view-sidebar/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "article-list": "articles-view-sidebar__article-list_1vl8pc",
    "article-item": "articles-view-sidebar__article-item_1vl8pc",
    "selected": "articles-view-sidebar__selected_1vl8pc",
    "create-article": "articles-view-sidebar__create-article_1vl8pc",
    "article-count": "articles-view-sidebar__article-count_1vl8pc"
  };
});