define('frontend-cp/mixins/insights-control-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    startAt: '',
    endAt: '',
    interval: '',
    agent: '',
    team: '',
    sla: '',

    actions: {
      intervalChanged: function intervalChanged(interval) {
        this.transitionToRoute({ queryParams: { interval: interval } });
      },
      dateRangeHidden: function dateRangeHidden(start, end) {
        var startAt = this.get('startAt');
        var endAt = this.get('endAt');

        if (startAt !== start || endAt !== end) {
          this.transitionToRoute({ queryParams: { startAt: start, endAt: end } });
        }
      },
      updateAgent: function updateAgent(agent) {
        this.set('agentItem', agent);
        this.set('actorQueryTerm', '');
        this.transitionToRoute({ queryParams: { agent: agent.get('id') } });
      },
      updateTeam: function updateTeam(team) {
        this.set('teamItem', team);
        this.set('actorQueryTerm', '');
        this.transitionToRoute({ queryParams: { team: team.get('id') } });
      },
      updateSla: function updateSla(sla) {
        this.set('slaItem', sla);
        this.transitionToRoute({ queryParams: { sla: sla.get('id') } });
      }
    }
  });
});