define("frontend-cp/components/ko-admin/email/edit/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "heading": "ko-admin_email_edit__heading_1fk1jd",
    "info": "ko-admin_email_edit__info_1fk1jd",
    "table": "ko-admin_email_edit__table_1fk1jd",
    "table-header-row": "ko-admin_email_edit__table-header-row_1fk1jd",
    "table-row": "ko-admin_email_edit__table-row_1fk1jd",
    "table-header-cell": "ko-admin_email_edit__table-header-cell_1fk1jd",
    "table-cell": "ko-admin_email_edit__table-cell_1fk1jd",
    "wrap-text": "ko-admin_email_edit__wrap-text_1fk1jd",
    "valid-cell": "ko-admin_email_edit__valid-cell_1fk1jd",
    "invalid-cell": "ko-admin_email_edit__invalid-cell_1fk1jd",
    "dns-type": "ko-admin_email_edit__dns-type_1fk1jd",
    "dns-status": "ko-admin_email_edit__dns-status_1fk1jd",
    "error": "ko-admin_email_edit__error_1fk1jd",
    "reload": "ko-admin_email_edit__reload_1fk1jd"
  };
});