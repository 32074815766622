define('frontend-cp/utils/compute-selected', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = computeSelected;
  function computeSelected(caseId, checked, alreadySelected, allCaseIds) {
    var toBeSelected = [];
    var allSelected = [];

    // Prepare array of new rows
    var lastSelected = alreadySelected.get('lastObject');
    var topLimit = allCaseIds.indexOf(lastSelected);
    var bottomLimit = allCaseIds.indexOf(caseId);
    var isSelectingUpwards = topLimit > bottomLimit;

    if (isSelectingUpwards) {
      toBeSelected = allCaseIds.filter(function (el, i) {
        return i <= topLimit && i >= bottomLimit;
      }).reverse();
    } else {
      toBeSelected = allCaseIds.filter(function (el, i) {
        return i >= topLimit && i <= bottomLimit;
      });
    }

    // Concatinate new rows to the previously selected rows
    if (checked) {
      allSelected = alreadySelected.concat(toBeSelected).uniq();
    } else {
      allSelected = alreadySelected.reject(function (el) {
        return toBeSelected.includes(el);
      });
    }

    return allSelected;
  }
});