define('frontend-cp/components/ko-admin/macros/edit/action-handlers/assignee', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = {
    serialize: function serialize(action, macro, store) {
      this.reset(macro);

      if (action && action.value) {
        var value = action.value;

        if (value === 'UNASSIGNED' || value === 'CURRENT_AGENT') {
          macro.set('assigneeType', value);
        } else {
          var _value$split = value.split('-'),
              _value$split2 = _slicedToArray(_value$split, 2),
              teamId = _value$split2[0],
              agentId = _value$split2[1];

          var team = store.peekRecord('team', teamId);

          if (agentId) {
            var agent = store.peekRecord('user', agentId);
            macro.setProperties({
              assignedAgent: agent,
              assignedTeam: team,
              assigneeType: 'AGENT'
            });
          } else {
            macro.setProperties({
              assignedTeam: team,
              assigneeType: 'TEAM'
            });
          }
        }
      }

      return macro;
    },
    deserialize: function deserialize(macro, actions) {
      var _macro$getProperties = macro.getProperties('assigneeType', 'assignedAgent', 'assignedTeam'),
          assigneeType = _macro$getProperties.assigneeType,
          assignedAgent = _macro$getProperties.assignedAgent,
          assignedTeam = _macro$getProperties.assignedTeam;

      if (assigneeType) {
        if (['UNASSIGNED', 'CURRENT_AGENT'].indexOf(assigneeType) > -1) {
          actions.push({
            name: 'assignee',
            option: 'CHANGE',
            value: assigneeType
          });
        } else if (assigneeType === 'AGENT') {
          actions.push({
            name: 'assignee',
            option: 'CHANGE',
            value: assignedTeam.get('id').toString() + '-' + assignedAgent.get('id').toString()
          });
        } else {
          actions.push({
            name: 'assignee',
            option: 'CHANGE',
            value: assignedTeam.get('id').toString()
          });
        }
      }

      return actions;
    },
    reset: function reset(macro) {
      macro.set('assigneeType', null);
      macro.set('assignedAgent', null);
      macro.set('assignedTeam', null);
    }
  };
});