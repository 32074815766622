define('frontend-cp/session/admin/account/billing/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return hash({
        cards: this.store.findAll('creditcard', { reload: true }),
        account: this.store.findAll('account', { reload: true }).then(function (account) {
          return account.get('firstObject');
        })
      });
    }
  });
});