define('frontend-cp/components/ko-table/column/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend({
    tagName: '',

    column: null,
    orderBy: null,
    orderByColumn: null,
    sortable: false,
    maxWidth: null,
    minWidth: null,
    width: null,
    dense: false,

    // Services
    renderedTextWidth: service(),

    // CPs
    headingStyle: computed('width', function () {
      var maxWidth = this.get('maxWidth');
      var minWidth = this.get('minWidth');
      var styles = minWidth ? 'min-width: ' + minWidth + 'px;' : '';
      styles += maxWidth ? ' max-width: ' + maxWidth + 'px;' : '';
      return htmlSafe(styles);
    }),

    selected: computed('orderByColumn', 'column.name', function () {
      return this.get('orderByColumn') === this.get('column.name');
    }),

    actions: {
      handleMouseDown: function handleMouseDown(e) {
        if (this.get('sortable')) {
          e.preventDefault();
        }
      },
      handleClick: function handleClick() {
        if (!this.get('sortable')) {
          return null;
        }

        var onSort = this.get('onSort');
        var column = this.get('column');
        var selected = this.get('selected');
        var orderBy = this.get('orderBy');
        var direction = selected && orderBy === 'asc' ? 'desc' : 'asc';

        onSort(column, direction);
      }
    }
  });
});