define('frontend-cp/components/ko-datepicker/component', ['exports', 'npm:lodash', 'moment', 'frontend-cp/lib/keycodes'], function (exports, _npmLodash, _moment, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var observer = Ember.observer;
  var computed = Ember.computed;
  var on = Ember.on;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    onDateChange: null,
    onClose: null,
    date: null,

    // State
    today: null,
    shownDate: null,

    // Services
    dateService: service('date'),

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.set('today', (0, _moment.default)(this.get('dateService').getCurrentDate()));
    },


    onDateParamChange: on('init', observer('momentDate', function () {
      this.set('shownDate', (0, _moment.default)(this.get('momentDate').isValid() ? this.get('momentDate') : this.get('today')).toDate());
    })),

    momentDate: computed('date', function () {
      return this.get('date') ? (0, _moment.default)(this.get('date')) : (0, _moment.default)(this.get('dateService').getCurrentDate());
    }),

    month: computed('shownDate', function () {
      return (0, _moment.default)(this.get('shownDate')).month();
    }),

    year: computed('shownDate', function () {
      return (0, _moment.default)(this.get('shownDate')).year();
    }),

    weekdays: computed(function () {
      var weekdays = _moment.default.weekdaysShort();
      var firstDayOfWeek = _moment.default.localeData().firstDayOfWeek();
      _npmLodash.default.times(firstDayOfWeek, function () {
        return weekdays.push(weekdays.shift());
      });
      return weekdays;
    }),

    days: computed('year', 'month', 'today', 'momentDate', function () {
      var date = (0, _moment.default)({
        year: this.get('year'),
        month: this.get('month'),
        day: 1
      }).startOf('week');

      var end = (0, _moment.default)({
        year: this.get('year'),
        month: this.get('month'),
        day: 1
      }).add(1, 'month').endOf('week');

      var dates = [];
      while (date.isBefore(end, 'day') || date.isSame(end, 'day')) {
        dates.push({
          date: date.date(),
          month: date.month(),
          year: date.year(),
          currentMonth: date.month() === this.get('month'),
          today: this.get('today').isSame(date, 'day'),
          selected: date.isSame(this.get('momentDate'), 'day')
        });
        date.add(1, 'day');
      }
      return dates;
    }),

    jumpDateBy: function jumpDateBy(method, range) {
      if (this.get('momentDate').isValid()) {
        this.setDate((0, _moment.default)(this.get('momentDate'))[method](1, range));
      }
      return false;
    },
    setDate: function setDate(date) {
      var value = dateToUTC(date);
      this.attrs.onDateChange(value);
    },


    actions: {
      handleKeyDown: function handleKeyDown(e) {
        // TODO: fix this bit, as it seems not to work.

        switch (e.keyCode) {
          case _keycodes.up:
            {
              return this.jumpDateBy('subtract', 'week');
            }
          case _keycodes.down:
            {
              return this.jumpDateBy('add', 'week');
            }
          case _keycodes.left:
            {
              return this.jumpDateBy('subtract', 'day');
            }
          case _keycodes.right:
            {
              return this.jumpDateBy('add', 'day');
            }
        }
      },
      previousMonth: function previousMonth() {
        this.set('shownDate', (0, _moment.default)(this.get('shownDate')).subtract(1, 'month').toDate());
      },
      nextMonth: function nextMonth() {
        this.set('shownDate', (0, _moment.default)(this.get('shownDate')).add(1, 'month').toDate());
      },
      selectDate: function selectDate(date) {
        this.setDate((0, _moment.default)({
          year: date.year,
          month: date.month,
          day: date.date
        }));
      },
      today: function today() {
        this.setDate((0, _moment.default)(this.get('today')));
      },
      clear: function clear() {
        this.setDate(null);
      }
    }
  });


  function dateToUTC(date) {
    var m = (0, _moment.default)(date);

    if (!m.isValid()) {
      return null;
    }

    var year = m.get('year');
    var month = m.get('month');
    var day = m.get('date');
    var result = (0, _moment.default)([year, month, day]);

    return result.toDate();
  }
});