define('frontend-cp/session/admin/people/businesshours/new/controller', ['exports', 'ember-service/inject', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/people/businesshours/schema'], function (exports, _inject, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    // Services
    notification: service(),
    i18n: service(),
    virtualModel: service(),
    metrics: service(),

    schema: _schema.default,

    // Methods
    initEdits: function initEdits() {
      this.set('editedBusinessHour', this.get('virtualModel').makeSnapshot(this.get('businessHour'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('businessHour'), this.get('editedBusinessHour'), _schema.default);
    },


    // Actions
    actions: {
      created: function created() {
        var launchDarkly = this.get('launchDarkly');

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'business_hours_created',
            object: this.get('editedBusinessHour.id')
          });
        }
        this.transitionToRoute('session.admin.people.businesshours.index');
      },
      canceled: function canceled() {
        this.transitionToRoute('session.admin.people.businesshours.index');
      }
    }
  });
});