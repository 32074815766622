define('frontend-cp/transforms/always-string', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized === null || serialized === undefined) {
        //eslint-disable-line no-undefined
        return '';
      } else {
        return String(serialized);
      }
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});