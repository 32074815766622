define("frontend-cp/components/ko-cases-list/column/org-composite/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "avatar-loading": "ko-cases-list_column_org-composite__avatar-loading_1w266a",
    "icon": "ko-cases-list_column_org-composite__icon_1w266a",
    "title": "ko-cases-list_column_org-composite__title_1w266a",
    "avatar": "ko-cases-list_column_org-composite__avatar_1w266a",
    "updated": "ko-cases-list_column_org-composite__updated_1w266a",
    "domains": "ko-cases-list_column_org-composite__domains_1w266a",
    "domain": "ko-cases-list_column_org-composite__domain_1w266a",
    "domainFirst": "ko-cases-list_column_org-composite__domainFirst_1w266a",
    "separator": "ko-cases-list_column_org-composite__separator_1w266a",
    "loading-separator": "ko-cases-list_column_org-composite__loading-separator_1w266a"
  };
});