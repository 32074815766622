define("frontend-cp/components/ko-instant-entity/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "instant-entity": "ko-instant-entity_item__instant-entity_1ysouf",
    "focused": "ko-instant-entity_item__focused_1ysouf",
    "avatar": "ko-instant-entity_item__avatar_1ysouf",
    "org-icon": "ko-instant-entity_item__org-icon_1ysouf",
    "info": "ko-instant-entity_item__info_1ysouf",
    "info-row": "ko-instant-entity_item__info-row_1ysouf",
    "name": "ko-instant-entity_item__name_1ysouf",
    "separator": "ko-instant-entity_item__separator_1ysouf",
    "organization": "ko-instant-entity_item__organization_1ysouf",
    "row-1": "ko-instant-entity_item__row-1_1ysouf",
    "row-2": "ko-instant-entity_item__row-2_1ysouf",
    "channel": "ko-instant-entity_item__channel_1ysouf",
    "channel-icon": "ko-instant-entity_item__channel-icon_1ysouf",
    "channel-contact": "ko-instant-entity_item__channel-contact_1ysouf",
    "blue": "ko-instant-entity_item__blue_1ysouf",
    "italic": "ko-instant-entity_item__italic_1ysouf",
    "small": "ko-instant-entity_item__small_1ysouf",
    "domain": "ko-instant-entity_item__domain_1ysouf",
    "domain-first": "ko-instant-entity_item__domain-first_1ysouf",
    "invalid-state": "ko-instant-entity_item__invalid-state_1ysouf"
  };
});