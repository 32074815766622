define('frontend-cp/models/notification', ['exports', 'moment', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _moment, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    activity: (0, _relationships.belongsTo)('activity'),
    readState: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('string'),
    notificationType: (0, _attr.default)('string'),
    notificationDay: computed('createdAt', function () {
      var createdAt = (0, _moment.default)(this.get('createdAt'));
      var daysDiff = (0, _moment.default)().diff(createdAt, 'days');

      if (daysDiff === 0) {
        return 'Today';
      }

      if (daysDiff === 1) {
        return 'Yesterday';
      }

      return createdAt.format('DD/MM/YYYY');
    })
  });
});