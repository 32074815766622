define('frontend-cp/models/sla-metric', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    startedAt: _emberData.default.attr('date'),
    completedAt: _emberData.default.attr('date'),
    dueAt: _emberData.default.attr('date'),
    lastPausedAt: _emberData.default.attr('date'),
    metricType: _emberData.default.attr('string'),
    stage: _emberData.default.attr('string'), //COMPLETED | PAUSED | ACTIVE
    target: _emberData.default.belongsTo('sla-version-target'),

    isCompleted: computed('stage', function () {
      return this.get('stage') === 'COMPLETED';
    }),

    isPaused: computed('stage', function () {
      return this.get('stage') === 'PAUSED';
    })
  });
});