define("frontend-cp/components/ko-universal-search/result/case/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "avatarContainer": "ko-universal-search_result_case__avatarContainer_10ncau",
    "avatar": "ko-universal-search_result_case__avatar_10ncau",
    "avatarUnassigned": "ko-universal-search_result_case__avatarUnassigned_10ncau",
    "agentAvatarContainer": "ko-universal-search_result_case__agentAvatarContainer_10ncau",
    "agentAvatar": "ko-universal-search_result_case__agentAvatar_10ncau",
    "title": "ko-universal-search_result_case__title_10ncau",
    "separator": "ko-universal-search_result_case__separator_10ncau",
    "requesterFullName": "ko-universal-search_result_case__requesterFullName_10ncau",
    "status": "ko-universal-search_result_case__status_10ncau",
    "bottom-text": "ko-universal-search_result_case__bottom-text_10ncau",
    "bullet": "ko-universal-search_result_case__bullet_10ncau",
    "status-icon": "ko-universal-search_result_case__status-icon_10ncau",
    "preview": "ko-universal-search_result_case__preview_10ncau",
    "appended-button": "ko-universal-search_result_case__appended-button_10ncau",
    "append-button": "ko-universal-search_result_case__append-button_10ncau",
    "remove-button": "ko-universal-search_result_case__remove-button_10ncau",
    "showPrimaryBadge": "ko-universal-search_result_case__showPrimaryBadge_10ncau"
  };
});