define("frontend-cp/components/ko-search-sidebar/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-search-sidebar_item__item_x6ovp6",
    "item--active": "ko-search-sidebar_item__item--active_x6ovp6",
    "text": "ko-search-sidebar_item__text_x6ovp6",
    "counter": "ko-search-sidebar_item__counter_x6ovp6"
  };
});