define("frontend-cp/components/ko-select/shared", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "statusIcon": "_statusIcon_jvqwfg",
    "trigger": "_trigger_jvqwfg _statusIcon_jvqwfg",
    "trigger--focused": "_trigger--focused_jvqwfg",
    "dropdown": "_dropdown_jvqwfg",
    "loading-icon": "_loading-icon_jvqwfg",
    "loading": "_loading_jvqwfg",
    "rotating": "_rotating_jvqwfg"
  };
});