define("frontend-cp/components/ko-avatar-update/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "dropdown": "ko-avatar-update__dropdown_1kiom8 _dropdown_jvqwfg",
    "list-item": "ko-avatar-update__list-item_1kiom8",
    "remove-item": "ko-avatar-update__remove-item_1kiom8",
    "hide": "ko-avatar-update__hide_1kiom8",
    "file-field": "ko-avatar-update__file-field_1kiom8",
    "file-upload-label": "ko-avatar-update__file-upload-label_1kiom8"
  };
});