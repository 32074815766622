define('frontend-cp/components/ko-info-bar/field/cascadingselect/component', ['exports', 'frontend-cp/components/ko-select/drill-down/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    title: null,
    options: null,
    value: null,
    isErrored: false,
    isEdited: false,
    isKREEdited: false,
    isDisabled: false,
    onValueChange: null,

    // HTML
    tagName: '',

    tree: computed('options', function () {
      var items = this.get('options').filter(function (option) {
        return option && get(option, 'value');
      });
      return (0, _component.buildTreeFromList)(items, function (item) {
        return {
          id: get(item, 'id'),
          value: get(item, 'value')
        };
      });
    })
  });
});