define('frontend-cp/services/on-boarding-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var jQuery = Ember.$;
  exports.default = Service.extend({
    session: service(),
    store: service(),

    settings: null,
    completedCases: 0,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('settings', []);
    },
    getSettings: function getSettings() {
      var _this = this;

      return this.get('store').findAll('setting').then(function (response) {
        _this.set('settings', response.toArray());
      });
    },
    getCompletedCases: function getCompletedCases() {
      var _this2 = this;

      this.get('store').query('case', { status: 'COMPLETED' }).then(function (response) {
        _this2.set('completedCases', response.get('length'));
      });
    },


    progress: computed('settings.@each.value', function () {
      var steps = {};
      var stepsToPick = ['learn_kayako_completed', 'experience_kayako_completed', 'account_setup', 'account_connected', 'setup', 'team_added', 'agent_added'];

      this.get('settings').forEach(function (setting) {
        if (stepsToPick.includes(setting.get('name'))) {
          steps[setting.get('name')] = setting.get('value') === '1';
        }
      });
      return steps;
    }),

    updateStatus: function updateStatus(property, data) {
      var payload = this.get('settings').find(function (setting) {
        return setting.get('name') === property;
      });

      payload.set('value', '1');

      jQuery.ajax({
        url: '/api/v1/settings',
        headers: {
          'X-CSRF-Token': this.get('session.csrfToken')
        },
        processData: false,
        method: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({ values: data }),
        error: function error() {
          payload.set('value', '0');
        }
      });
    },

    pendingSteps: computed('progress', function () {
      var counter = 0;

      if (!this.get('progress.account_setup')) {
        counter++;
      }

      if (!this.get('progress.account_connected')) {
        counter++;
      }

      if (!this.get('progress.setup')) {
        counter++;
      }

      if (!this.get('progress.team_added')) {
        counter++;
      }

      if (!this.get('progress.agent_added')) {
        counter++;
      }

      if (this.get('completedCases') < 3) {
        counter++;
      }

      return counter;
    })
  });
});