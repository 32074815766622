define('frontend-cp/models/post', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.POST_STATUS_CLIENT_FAILED = exports.POST_STATUS_CLIENT_SENDING = exports.POST_STATUS_CLIENT_WAITING = exports.POST_STATUS_REJECTED = exports.POST_STATUS_SEEN = exports.POST_STATUS_DELIVERED = exports.POST_STATUS_SENT = exports.POST_STATUS_NOT_SENT = undefined;
  var computed = Ember.computed;
  var POST_STATUS_NOT_SENT = exports.POST_STATUS_NOT_SENT = 'NOT_SENT';
  var POST_STATUS_SENT = exports.POST_STATUS_SENT = 'SENT';
  var POST_STATUS_DELIVERED = exports.POST_STATUS_DELIVERED = 'DELIVERED';
  var POST_STATUS_SEEN = exports.POST_STATUS_SEEN = 'SEEN';
  var POST_STATUS_REJECTED = exports.POST_STATUS_REJECTED = 'REJECTED';

  var POST_STATUS_CLIENT_WAITING = exports.POST_STATUS_CLIENT_WAITING = 'CLIENT_WAITING';
  var POST_STATUS_CLIENT_SENDING = exports.POST_STATUS_CLIENT_SENDING = 'CLIENT_SENDING';
  var POST_STATUS_CLIENT_FAILED = exports.POST_STATUS_CLIENT_FAILED = 'CLIENT_FAILED';

  exports.default = _emberData.default.Model.extend({
    uuid: _emberData.default.attr('string'),
    clientId: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    contents: _emberData.default.attr('string'),
    creator: _emberData.default.belongsTo('post-creator', { async: false, polymorphic: true }),
    sourceChannel: _emberData.default.belongsTo('channel', { async: false }),
    identity: _emberData.default.belongsTo('identity', { async: false }),
    attachments: _emberData.default.hasMany('attachment', { async: true }),
    downloadAll: _emberData.default.attr('string'),
    original: _emberData.default.belongsTo('postable', { async: true, polymorphic: true }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    metadata: _emberData.default.attr(),
    destinationMedium: _emberData.default.attr('string'), // MAIL / MESSENGER / null

    // NOT_SENT > SENT > DELIVERED > SEEN > REJECTED
    postStatus: _emberData.default.attr('string'),
    postStatusRejectReason: _emberData.default.attr('string'),
    postStatusUpdatedAt: _emberData.default.attr('date'),

    // Virtual parent field
    parent: _emberData.default.belongsTo('has-posts', { async: true, polymorphic: true }),

    // CP's
    isViaMessenger: computed('sourceChannel', 'destinationMedium', function () {
      return this.get('sourceChannel.channelType') === 'MESSENGER' || this.get('destinationMedium') === 'MESSENGER';
    }),

    isViaEmail: computed('isViaMessenger', 'original', function () {
      return !this.get('isViaMessenger') && this.get('original.postType') === 'message';
    })
  });
});