define('frontend-cp/components/ko-confirm-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var oneWay = Ember.computed.oneWay;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    confirmation: service(),

    activeConfirmation: oneWay('confirmation.activeConfirmation'),

    actions: {
      onCancel: function onCancel() {
        this.get('confirmation').cancelConfirmation();
      },
      onConfirm: function onConfirm() {
        this.get('confirmation').acceptConfirmation();
      }
    }
  });
});