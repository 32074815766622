define('frontend-cp/session/agent/cases/case/user/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route-child'], function (exports, _tabbedRouteChild) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  var getOwner = Ember.getOwner;
  exports.default = _tabbedRouteChild.default.extend({
    store: service(),
    timelineService: service('timeline'),
    queryParams: {
      timestamp: {
        refreshModel: true
      }
    },

    beforeModel: function beforeModel() {
      return this.get('store').findAll('user-field');
    },
    model: function model(_ref) {
      var timestamp = _ref.timestamp;

      var store = this.get('store');
      var caseModel = this.modelFor('session.agent.cases.case');
      var partiallyLoadedRequester = caseModel.get('requester');
      var timelineService = this.get('timelineService');

      return store.findRecord('user', partiallyLoadedRequester.get('id')).then(function (user) {
        return RSVP.hash({
          timeline: timelineService.timelineForCaseUser(caseModel, user),
          statuses: store.findAll('case-status'),
          caseFields: store.findAll('case-field'),
          case: caseModel,
          user: user,
          timestamp: timestamp
        });
      });
    },
    afterModel: function afterModel(_ref2) {
      var user = _ref2.user;

      return user.get('tags');
    },
    setupController: function setupController(controller, models) {
      controller.setProperties(models);
      var parentController = this.controllerFor('session.agent.cases.case');
      var tab = parentController.get('tab');
      var UserStateManager = getOwner(this).factoryFor('state-manager:user');
      var state = UserStateManager.create({ user: models.user, tab: tab });
      controller.set('state', state);
    }
  });
});