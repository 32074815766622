define('frontend-cp/session/admin/channels/facebook/callback/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    notification: service('notification'),
    i18n: service(),
    cookies: service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var params = transition.queryParams;

      if (params.code && params.state) {
        this.store.queryRecord('facebook-account-callback', {
          code: params.code,
          state: params.state,
          callback: '/admin/channels/facebook/callback'
        }).then(function () {
          _this.get('notification').add({
            type: 'success',
            title: _this.get('i18n').t('generic.changes_saved'),
            autodismiss: true
          });
        }).finally(function () {
          var isFacebookPageReconnect = _this.get('cookies').read('is_facebook_page_reconnect');
          _this._transitionToModal(isFacebookPageReconnect !== 'true');
        });
      } else {
        this._transitionToModal();
      }
    },
    _transitionToModal: function _transitionToModal() {
      var showModal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      this.transitionTo('session.admin.channels.facebook.index', { queryParams: { showModal: showModal } });
    }
  });
});