define("frontend-cp/components/ko-grid-picker/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-grid-picker__container_8fr6t6",
    "cell": "ko-grid-picker__cell_8fr6t6",
    "selected": "ko-grid-picker__selected_8fr6t6",
    "row": "ko-grid-picker__row_8fr6t6",
    "legend": "ko-grid-picker__legend_8fr6t6",
    "legend-cell": "ko-grid-picker__legend-cell_8fr6t6"
  };
});