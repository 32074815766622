define('frontend-cp/models/businesshour-holiday', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    date: _emberData.default.attr('string'),
    openHours: _emberData.default.attr({ defaultValue: function defaultValue() {
        return [];
      } })
  });
});