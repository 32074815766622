define('frontend-cp/components/ko-app-slot/app/component', ['exports', 'npm:uuid/v4', 'npm:json-stable-stringify', 'ember-diff-attrs', 'frontend-cp/config/environment', 'frontend-cp/utils/diff-attrs'], function (exports, _v, _npmJsonStableStringify, _emberDiffAttrs, _environment, _diffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var run = Ember.run;
  var get = Ember.get;
  var camelize = Ember.String.camelize;
  var service = Ember.inject.service;
  var assign = Ember.assign;
  exports.default = Component.extend({
    localClassNames: 'app',

    processManager: service(),
    ajax: service(),
    session: service(),

    installedApp: null,
    prompts: null,
    app: null,
    slot: null,
    instance: null,

    case: null,
    user: null,
    organization: null,

    width: 0,
    height: 0,

    didReceiveAttrs: (0, _emberDiffAttrs.default)('case', 'user', 'organization', 'slot', 'app', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      if (!changedAttrs || (0, _diffAttrs.attrChanged)(changedAttrs.case, 'id') || (0, _diffAttrs.attrChanged)(changedAttrs.user, 'id') || (0, _diffAttrs.attrChanged)(changedAttrs.organization, 'id') || changedAttrs.slot || changedAttrs.app) {
        this.removeAllEventListeners();

        this.setProperties({
          width: 0,
          height: 0,
          instance: (0, _v.default)()
        });

        if (this.channel) {
          this.channel.port1.close();
          this.channel.port2.close();
          this.channel = null;
        }
      }
    }),

    src: computed('slot.url', 'instance', function () {
      var url = this.get('slot.url');
      if (!url) {
        return;
      }
      var instance = this.get('instance');
      return url + '?_instance=' + instance; // TODO - use hash? or at least check if we already have params
    }),

    initMessageChannel: function initMessageChannel() {
      var _this = this;

      this.channel = new MessageChannel();
      this.channel.port1.onmessage = function (msg) {
        run(function () {
          return _this.handleMessage(msg);
        });
      };

      var data = this.get('prompts');
      var iframe = this.$('iframe')[0].contentWindow;
      iframe.postMessage({ event: 'setup', ref: (0, _v.default)(), data: data }, '*', [this.channel.port2]);
    },
    handleMessage: function handleMessage(msg) {
      var handlerName = camelize('handle_' + msg.data.event);
      var handlerFunc = this[handlerName];
      if (handlerFunc) {
        handlerFunc.call(this, msg);
      } else {
        this.replyWithError(msg, 'unknown_event', 'Received unknown event "' + msg.data.event + '"');
      }
    },
    handleDimensionsChanged: function handleDimensionsChanged(msg) {
      var _msg$data$payload = msg.data.payload,
          width = _msg$data$payload.width,
          height = _msg$data$payload.height;


      if (width >= 0 && height >= 0) {
        this.setProperties({ width: width, height: height });
        this.replyTo(msg, { status: 'ok' });
      } else {
        this.replyWithError(msg, 'dimensions_invalid', 'Dimensions must be >= 0');
      }
    },
    handleGetData: function handleGetData(msg) {
      this.replyTo(msg, {
        status: 'ok',
        data: this.serialize(msg.data.payload.path)
      });
    },
    handleAddEventListener: function handleAddEventListener(msg) {
      var _this2 = this;

      var type = msg.data.payload.type;

      // only change is supported currently
      if (type !== 'change') {
        return this.replyWithError(msg, 'unknown_event_listener_type', 'Cannot add event listener for unknown type "' + type + '"');
      }

      var path = msg.data.payload.path;

      var resource = path.split('.').shift();
      if (!['case', 'user', 'organization'].includes(resource)) {
        return this.replyWithError(msg, 'unknown_event_listener_path', 'Cannot add event listener for unknown path "' + path + '"');
      }

      var subscription = (0, _v.default)();

      var lastValue = (0, _npmJsonStableStringify.default)(this.serialize(path));

      var listener = function listener() {
        if (!_this2.channel) {
          return;
        }

        var data = _this2.serialize(path);

        // ensure the path being listened to has actually changed
        var stringified = (0, _npmJsonStableStringify.default)(data);
        if (stringified === lastValue) {
          return;
        }
        lastValue = stringified;

        _this2.channel.port1.postMessage({
          ref: (0, _v.default)(),
          event: 'event',
          payload: {
            type: 'change',
            subscription: subscription,
            data: data
          }
        });
      };

      var remove = void 0;
      switch (resource) {
        case 'case':
          {
            var process = this.get('processManager').getOrCreateProcess(this.get('case'), 'case');
            var state = process.get('state');
            state.on('updated', listener);
            remove = function remove() {
              return state.off('updated', listener);
            };
            break;
          }

        case 'user':
          {
            var _process = this.get('processManager').getOrCreateProcess(this.get('user'), 'user');
            var _state = _process.get('state');
            _state.on('updated', listener);
            remove = function remove() {
              return _state.off('updated', listener);
            };
            break;
          }

        case 'organization':
          {
            var _process2 = this.get('processManager').getOrCreateProcess(this.get('organization'), 'organization');
            var _state2 = _process2.get('state');
            _state2.on('updated', listener);
            remove = function remove() {
              return _state2.off('updated', listener);
            };
            break;
          }
      }

      this._eventListeners[subscription] = {
        remove: remove,
        listener: listener
      };

      this.replyTo(msg, {
        status: 'ok',
        subscription: subscription
      });
    },
    handleRemoveEventListener: function handleRemoveEventListener(msg) {
      var listenerData = this._eventListeners[msg.data.payload.subscription];
      if (!listenerData) {
        return this.replyWithError(msg, 'subscription_reference_invalid', 'No event listener found for subscription reference "' + msg.data.payload.subscription + '"');
      }

      listenerData.remove();
      delete this._eventListeners[msg.data.payload.subscription];

      this.replyTo(msg, { status: 'ok' });
    },
    removeAllEventListeners: function removeAllEventListeners() {
      var listeners = this._eventListeners || {};
      Object.keys(listeners).forEach(function (key) {
        listeners[key].remove();
      });
      this._eventListeners = {};
    },
    handleRemoteRequest: function handleRemoteRequest(msg) {
      if (msg.data.payload.url.match(/^\/api\//)) {
        this._apiRequest(msg);
      } else {
        this._proxyRequest(msg);
      }
    },
    _apiRequest: function _apiRequest(msg) {
      var _this3 = this;

      // bypasses adapter because user may want custom headers, non-flat mode etc…
      this.get('ajax').raw(msg.data.payload.url, {
        method: msg.data.payload.method,
        data: msg.data.payload.data,
        headers: assign({}, msg.data.payload.headers || {}, {
          'X-CSRF-Token': this.get('session.csrfToken'),
          'X-Session-ID': this.get('session.sessionId')
        })
      }).then(function (response) {
        _this3.replyTo(msg, {
          status: 'ok',
          response: {
            body: response.jqXHR.responseText,
            status: response.jqXHR.status,
            headers: {} // TODO - extract from response.jqXHR.getAllResponseHeaders()
          }
        });
      }).catch(function (e) {
        _this3.replyWithError(msg, 'unknown', 'Something went wrong'); // TODO
      });
    },
    _proxyRequest: function _proxyRequest(msg) {
      var _this4 = this;

      this.get('ajax').post(_environment.default.appsApiUrl + '/proxy', {
        contentType: 'application/json',
        headers: {
          'X-Session-ID': this.get('session.sessionId'),
          'X-User-Agent': window.navigator.userAgent,
          'X-Instance-Domain': this.get('session.session.instanceName')
        },
        data: {
          id: this.get('installedApp.id'),
          payload: msg.data.payload
        }
      }).then(function (responseBody) {
        _this4.replyTo(msg, { status: 'ok', response: responseBody.data });
      }).catch(function (e) {
        _this4.replyWithError(msg, 'unknown', 'Something went wrong'); // TODO
      });
    },
    serialize: function serialize(path) {
      var data = {};

      if (this.get('case')) {
        assign(data, {
          case: {
            id: this.get('case.id'),
            subject: this.get('case.subject'),
            requestor: {
              id: this.get('user.id'),
              fullName: this.get('user.fullName'),
              email: this.get('user.primaryEmail.email')
            }
          }
        });
      }

      if (this.get('user')) {
        assign(data, {
          user: {
            id: this.get('user.id'),
            fullName: this.get('user.fullName'),
            email: this.get('user.primaryEmail.email')
          }
        });
      }

      if (this.get('organization')) {
        assign(data, {
          organization: {
            id: this.get('organization.id'),
            name: this.get('organization.name')
          }
        });
      }

      if (path) {
        return get(data, path);
      } else {
        return data;
      }
    },
    replyTo: function replyTo(msg, payload) {
      if (!this.channel) {
        return;
      }

      this.channel.port1.postMessage({
        payload: payload,
        event: 'reply',
        ref: msg.data.ref
      });
    },
    replyWithError: function replyWithError(msg, code, message) {
      this.replyTo(msg, { status: 'error', code: code, message: message });
    },


    actions: {
      iframeLoaded: function iframeLoaded() {
        this.initMessageChannel();
      }
    }
  });
});