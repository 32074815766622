define('frontend-cp/services/error-handler/notification-strategy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    notification: service('notification'),

    init: function init() {
      this._super.apply(this, arguments);
      this.records = [];
    },
    processAll: function processAll(records) {
      this.records = records || [];
      this.process();
    },
    accept: function accept(record) {
      this.records.push(record);
    },
    process: function process() {
      var _this = this;

      var recordsCount = this.records.length;

      if (recordsCount) {
        this.records.forEach(function (notification) {
          var title = notification.message || '';

          title = title.replace(/\\n/g, '\n');

          _this.get('notification').add({
            type: notification.type.toLowerCase(),
            title: title,
            href: notification.related_href,
            hrefText: notification.related_label,
            autodismiss: !notification.sticky,
            dismissable: true
          });
        });

        this.records = [];
      }

      return recordsCount;
    }
  });
});