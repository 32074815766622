define('frontend-cp/components/ko-two-factor-auth/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var later = Ember.run.later;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    store: service(),
    notification: service('notification'),
    i18n: service(),

    token: null,
    authCode: null,
    disableButton: false,
    recoveryCodes: [],
    recoveryCodeList: null,

    // Used for animations
    visibleStep: 'getStarted',
    previousStep: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('role') === 'enable') {
        this.getToken();
      }
    },
    getToken: function getToken() {
      var _this = this;

      this.get('store').adapterFor('user').getQrCode().then(function (token) {
        _this.set('token', token.data);
      });
    },


    verifyButtonDisabled: computed('authCode', function () {
      return this.get('authCode.length') !== 6;
    }),

    actions: {
      switchModalContent: function switchModalContent(switchTo) {
        if (switchTo === 'getApp') {
          this.set('previousStep', 'getStarted');
        } else if (switchTo === 'verify') {
          this.set('previousStep', 'getApp');
          later(function () {
            $('#verify-qr-code').focus();
          }, 100);
        }
        this.set('visibleStep', switchTo);
      },
      verifyQrCode: function verifyQrCode(e) {
        var _this2 = this;

        e.preventDefault();
        this.set('disableButton', true);

        var data = {
          token: this.get('token.token'),
          otp: this.get('authCode')
        };

        this.get('store').adapterFor('user').sendTwoFactorCode(data).then(function (response) {
          var recoveryCodes = response.data.recovery_codes;
          _this2.set('recoveryCodes', recoveryCodes);
          _this2.set('recoveryCodeList', recoveryCodes.join(',\n'));
          _this2.set('visibleStep', 'done');
          _this2.set('previousStep', 'verify');
        }).then(function () {
          _this2.get('onUpdate')();
        }).catch(function () {
          _this2.set('disableButton', false);
          _this2.get('notification').add({
            type: 'error',
            title: _this2.get('i18n').t('users.two_factor.enable.error_message'),
            autodismiss: true
          });
        });
      },
      copiedToClipboard: function copiedToClipboard() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.copied_to_clipboard'),
          autodismiss: true
        });
      },
      removeTwoFactorAuth: function removeTwoFactorAuth() {
        var _this3 = this;

        this.set('disableButton', true);

        this.get('store').adapterFor('user').removeSelfTwoFactorAuth().then(function () {
          _this3.get('onClose')();
          _this3.get('notification').add({
            type: 'success',
            title: _this3.get('i18n').t('users.two_factor.disable.notification'),
            autodismiss: true
          });
        }).then(function () {
          _this3.get('onUpdate')();
        });
      }
    }
  });
});