define('frontend-cp/components/ko-org-logo/component', ['exports', 'frontend-cp/utils/platform'], function (exports, _platform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'span',

    // Attributes
    size: 42,
    greyscale: false,
    org: null,
    domain: null,
    src: null,

    // CPs
    // Accepts a string, domain model object, or an org for computing a domain.
    computedDomain: computed.or('domain', 'domain.domain', 'org.domains.firstObject.domain'),

    imageSize: computed('size', function () {
      return _platform.isRetina ? this.get('size') * 2 : this.get('size');
    }),

    computedSrc: computed('computedDomain', 'size', 'imageSize', 'greyscale', function () {
      var computedDomain = this.get('computedDomain');
      var imageSize = this.get('imageSize');
      var size = this.get('size');
      var greyscale = this.get('greyscale');
      return '//logo.clearbit.com/' + computedDomain + '?size=' + (imageSize || size) + (greyscale ? '&greyscale=true' : '');
    }),

    actions: {
      replaceSrc: function replaceSrc() {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        if (this.get('size') <= 20) {
          this.set('src', '/images/inline-icons/icon--org.svg');
        } else {
          this.set('src', '/images/inline-icons/icon--org--32.svg');
        }
      }
    }
  });
});