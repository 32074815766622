define('frontend-cp/components/ko-admin/automation-actions-builder/component', ['exports', 'ember-basic-dropdown/utils/computed-fallback-if-undefined', 'ember-composable-helpers/helpers/without'], function (exports, _computedFallbackIfUndefined, _without) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var not = Ember.computed.not;
  var computed = Ember.computed;
  var setProperties = Ember.setProperties;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['ko-automation-actions-builder'],

    type: '',
    addAction: function addAction() {},
    removeAction: function removeAction() {},
    changeAction: function changeAction() {},


    automationActions: (0, _computedFallbackIfUndefined.default)([]),
    definitions: (0, _computedFallbackIfUndefined.default)([]),
    shouldGroupDefinitions: (0, _computedFallbackIfUndefined.default)(true),
    usedDefinitions: null,

    i18n: service(),

    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);

      var actionNames = this.get('automationActions').map(function (action) {
        return action.name;
      });

      var usedDefinitions = this.get('definitions').filter(function (definition) {
        return actionNames.indexOf(definition.get('name')) > -1;
      });

      this.set('usedDefinitions', usedDefinitions);
    },


    // CPs
    availableDefinitions: computed('definitions.[]', 'usedDefinitions.[]', function () {
      var definitions = this.get('definitions');
      var usedDefinitions = this.get('usedDefinitions');

      return (0, _without.without)(usedDefinitions, definitions);
    }),
    actionsAreExclusive: not('shouldGroupDefinitions'),
    showAddActionButton: computed('actionsAreExclusive', 'automationActions.length', function () {
      var actionsAreExclusive = this.get('actionsAreExclusive');
      var actionCount = this.get('automationActions.length');
      var definitionCount = this.get('definitions.length');

      if (!actionsAreExclusive) {
        return true;
      }

      return actionCount < definitionCount;
    }),

    actions: {
      onRemoveAction: function onRemoveAction(action, definition) {
        var actionsAreExclusive = this.get('actionsAreExclusive');
        var usedDefinitions = this.get('usedDefinitions');

        if (actionsAreExclusive && definition) {
          usedDefinitions.removeObject(definition);
        }

        this.get('removeAction')(action);
      },
      onChangeAction: function onChangeAction(action, previousDefinition, selectedDefinition) {
        var actionsAreExclusive = this.get('actionsAreExclusive');
        var usedDefinitions = this.get('usedDefinitions');

        if (actionsAreExclusive) {
          if (previousDefinition) {
            usedDefinitions.removeObject(previousDefinition);
          }
          usedDefinitions.pushObject(selectedDefinition);
        }

        setProperties(action, {
          name: get(selectedDefinition, 'name'),
          option: null,
          value: null
        });

        this.get('changeAction')(action, previousDefinition);
      }
    }
  });
});