define("frontend-cp/components/ko-insights/section/searches/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "header": "ko-insights_section_searches__header_1y7hxg",
    "entity": "ko-insights_section_searches__entity_1y7hxg",
    "filters": "ko-insights_section_searches__filters_1y7hxg",
    "table": "ko-insights_section_searches__table_1y7hxg",
    "first": "ko-insights_section_searches__first_1y7hxg",
    "title": "ko-insights_section_searches__title_1y7hxg",
    "description": "ko-insights_section_searches__description_1y7hxg",
    "searches": "ko-insights_section_searches__searches_1y7hxg"
  };
});