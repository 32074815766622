define("frontend-cp/components/ko-edit-signature/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "input": "ko-edit-signature__input_1i5dn8",
    "buttons": "ko-edit-signature__buttons_1i5dn8"
  };
});