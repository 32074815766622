define('frontend-cp/components/ko-admin/twitter/edit/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    account: null,
    editedAccount: null,

    // Events
    onCancel: null,
    onSave: null,
    onSuccess: null,

    // Services
    store: service()
  });
});