define("frontend-cp/components/ko-admin/roles/list-item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "action": "ko-admin_roles_list-item__action_1lhh2x",
    "title": "ko-admin_roles_list-item__title_1lhh2x",
    "caption": "ko-admin_roles_list-item__caption_1lhh2x"
  };
});