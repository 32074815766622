define('frontend-cp/serializers/automation-action-definition', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'name',

    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      resourceHash.values = Object.keys(resourceHash.values || {}).map(function (key) {
        return { id: key, value: resourceHash.values[key] };
      });
      return this._super.apply(this, arguments);
    }
  });
});