define('frontend-cp/models/identity-facebook', ['exports', 'ember-data', 'frontend-cp/models/identity'], function (exports, _emberData, _identity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _identity.default.extend({
    facebookId: _emberData.default.attr('string'),
    userName: _emberData.default.attr('string'),
    fullName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    bio: _emberData.default.attr('string'),
    birthDate: _emberData.default.attr('date'),
    website: _emberData.default.attr('string'),
    profileUrl: _emberData.default.attr('string'),
    locale: _emberData.default.attr('string'),

    // Relations
    user: _emberData.default.belongsTo('user', { async: true }),

    // CPs
    name: computed.or('userName', 'fullName'),

    canBeValidated: false
  });
});