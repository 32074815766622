define('frontend-cp/components/ko-instant-entity/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    tagName: '',
    invalidState: null,
    entity: null,
    isProcessRunning: false,
    iconComponent: null,
    helpText: null,
    showAddOption: false,
    inUserMode: false,

    actions: {
      dispatch: function dispatch(method) {
        for (var _len = arguments.length, rest = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          rest[_key - 1] = arguments[_key];
        }

        this.sendAction.apply(this, [method].concat(_toConsumableArray(rest)));
      }
    }
  });
});