define('frontend-cp/models/message-recipient', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    messageRecipientType: _emberData.default.attr('string'),
    emailDeliveryStatus: _emberData.default.attr('string'),
    emailDeliveryStatusUpdatedAt: _emberData.default.attr('date'),
    identity: _emberData.default.belongsTo('identity-email', { async: false }),

    isCC: computed.equal('messageRecipientType', 'CC'),
    isTo: computed.equal('messageRecipientType', 'TO'),
    time: computed('emailDeliveryStatusUpdatedAt', function () {
      return (0, _moment.default)(this.get('emailDeliveryStatusUpdatedAt')).fromNow();
    })
  });
});