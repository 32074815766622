define("frontend-cp/components/ko-input-date-range/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "chartDateRange": "ko-input-date-range__chartDateRange_8891jo",
    "chartDateRangeIcon": "ko-input-date-range__chartDateRangeIcon_8891jo",
    "button-apply": "ko-input-date-range__button-apply_8891jo ko-button__primary_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "button-cancel": "ko-input-date-range__button-cancel_8891jo ko-button__naked_ka3fcv ko-button__shared_ka3fcv"
  };
});