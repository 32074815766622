define('frontend-cp/session/admin/automation/sla/new/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  exports.default = Route.extend((0, _route.default)('sla'), {
    i18n: service(),

    model: function model(params) {
      return RSVP.hash({
        definitions: this.store.query('definition', { type: 'sla' }),
        priorities: this.store.query('case-priority', {})
      });
    },
    setupController: function setupController(controller, model) {
      model.sla = this.store.createRecord('sla');
      model.sla.get('predicateCollections').createRecord({
        propositions: [this.store.createRecord('proposition', {})]
      });
      controller.setProperties(model);
    }
  });
});