define("frontend-cp/components/ko-bulk-invitation/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "fieldLabel": "ko-bulk-invitation__fieldLabel_g264ud",
    "row": "ko-bulk-invitation__row_g264ud",
    "actions": "ko-bulk-invitation__actions_g264ud",
    "layout": "ko-bulk-invitation__layout_g264ud",
    "item": "ko-bulk-invitation__item_g264ud",
    "addNew": "ko-bulk-invitation__addNew_g264ud",
    "icon": "ko-bulk-invitation__icon_g264ud"
  };
});