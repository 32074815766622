define('frontend-cp/services/custom-fields', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    i18n: service(),
    store: service(),
    customFieldsTypes: service('custom-fields/types'),
    sorter: service(),

    getTitleBreadcrumbs: function getTitleBreadcrumbs(model) {
      var title = [];
      var type = this.get('customFieldsTypes').getTypeByName(model.get('fieldType'));
      var stateModifier = model.get('isNew') ? 'new' : 'edit';

      title.push(this._getTranslation(this.getTranslationPrefix(model.constructor.modelName) + '.title'));

      if (model.get('title')) {
        title.push(model.get('title'));
      } else if (type) {
        title.push(this._getTranslation(type.nameIntlKey));
        title.push(this._getTranslation('admin.fields.' + stateModifier + '.heading'));
      }

      return title.join(' / ');
    },
    ensureLocaleFieldsAndOptions: function ensureLocaleFieldsAndOptions(model) {
      var store = this.get('store');
      var locales = store.peekAll('locale').filterBy('isPublic', true);
      var customerTitles = model.get('customerTitles');
      var descriptions = model.get('descriptions');
      var options = model.get('options');

      if (this.isChoiceField(model) && options.get('length') === 0) {
        options.pushObject(store.createRecord('field-option'));
      }

      locales.forEach(function (element) {
        var locale = element.get('locale');

        if (!customerTitles.findBy('locale', locale)) {
          customerTitles.pushObject(store.createRecord('locale-field', { locale: locale }));
        }

        if (!descriptions.findBy('locale', locale)) {
          descriptions.pushObject(store.createRecord('locale-field', { locale: locale }));
        }

        options.forEach(function (option) {
          if (!option.get('values').findBy('locale', locale)) {
            option.get('values').pushObject(store.createRecord('locale-field', { locale: locale }));
          }
        });
      });
    },
    reorder: function reorder(_, models) {
      this.get('sorter').sort(models);
    },
    toggleEnabled: function toggleEnabled(field) {
      field.toggleProperty('isEnabled');
      return field.save();
    },
    isChoiceField: function isChoiceField(field) {
      switch (field.get('fieldType')) {
        case 'RADIO':
        case 'CHECKBOX':
        case 'CASCADINGSELECT':
        case 'SELECT':
          return true;

        default:
          return false;
      }
    },
    baseTypeKeyForFieldType: function baseTypeKeyForFieldType(fieldType) {
      switch (fieldType) {
        case 'SUBJECT':
        case 'TEXT':
          return 'admin.casefields.type.text.name';

        case 'MESSAGE':
        case 'TEXTAREA':
          return 'admin.casefields.type.textarea.name';

        case 'PRIORITY':
        case 'STATUS':
        case 'TYPE':
        case 'ASSIGNEE':
        case 'TEAM':
        case 'SELECT':
        case 'DROPDOWN':
          return 'admin.casefields.type.dropdown.name';

        case 'RADIO':
          return 'admin.casefields.type.radio.name';
        case 'CHECKBOX':
          return 'admin.casefields.type.checkbox.name';
        case 'NUMERIC':
          return 'admin.casefields.type.numeric.name';
        case 'DECIMAL':
          return 'admin.casefields.type.decimal.name';
        case 'FILE':
          return 'admin.casefields.type.file.name';
        case 'YESNO':
          return 'admin.casefields.type.yesno.name';
        case 'CASCADINGSELECT':
          return 'admin.casefields.type.cascadingselect.name';
        case 'DATE':
          return 'admin.casefields.type.date.name';
        case 'REGEX':
          return 'admin.casefields.type.regex.name';
      }
    },
    baseKeyForFieldType: function baseKeyForFieldType(fieldType) {
      switch (fieldType) {
        case 'SUBJECT':
          return 'TEXT';

        case 'MESSAGE':
          return 'TEXTAREA';

        case 'PRIORITY':
        case 'STATUS':
        case 'TYPE':
        case 'ASSIGNEE':
        case 'TEAM':
          return 'SELECT';
        default:
          return fieldType;
      }
    },
    getTranslationPrefix: function getTranslationPrefix(typeKey) {
      if (typeKey === 'case-field') {
        return 'admin.casefields';
      } else if (typeKey === 'user-field') {
        return 'admin.userfields';
      } else if (typeKey === 'organization-field') {
        return 'admin.organizationfields';
      }
    },
    _getTranslation: function _getTranslation(key) {
      return this.get('i18n').t(key);
    }
  });
});