define("frontend-cp/components/ko-notification-center-new/preferences/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "description": "ko-notification-center-new_preferences__description_1ayae5",
    "heading": "ko-notification-center-new_preferences__heading_1ayae5",
    "devices": "ko-notification-center-new_preferences__devices_1ayae5",
    "device": "ko-notification-center-new_preferences__device_1ayae5",
    "vertical-separator": "ko-notification-center-new_preferences__vertical-separator_1ayae5",
    "device-text": "ko-notification-center-new_preferences__device-text_1ayae5",
    "device-label": "ko-notification-center-new_preferences__device-label_1ayae5",
    "loader": "ko-notification-center-new_preferences__loader_1ayae5",
    "list": "ko-notification-center-new_preferences__list_1ayae5",
    "table": "ko-notification-center-new_preferences__table_1ayae5",
    "list-row": "ko-notification-center-new_preferences__list-row_1ayae5",
    "list-head": "ko-notification-center-new_preferences__list-head_1ayae5",
    "list-data": "ko-notification-center-new_preferences__list-data_1ayae5",
    "list-header": "ko-notification-center-new_preferences__list-header_1ayae5",
    "buttons": "ko-notification-center-new_preferences__buttons_1ayae5"
  };
});