define('frontend-cp/session/admin/automation/macros/index/controller', ['exports', 'frontend-cp/session/admin/automation/macros/index/route', 'ember-concurrency'], function (exports, _route, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    queryParams: ['page'],
    page: 1,
    isLoadingMacros: false,
    model: null,

    i18n: service(),
    confirmation: service(),
    store: service(),

    totalPages: computed('model.meta.total', function () {
      var totalMacros = this.get('model.meta.total');
      return Math.ceil(totalMacros / _route.MACROS_LIMIT);
    }),

    deleteAndRefreshMacrosList: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(macro) {
      var page, offset, macros;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return macro.destroyRecord();

            case 2:
              page = this.get('page');
              offset = (parseInt(page) - 1) * _route.MACROS_LIMIT;
              _context.next = 6;
              return this.get('store').query('macro', { show_all: true, offset: offset, limit: _route.MACROS_LIMIT });

            case 6:
              macros = _context.sent;

              this.set('model', macros);

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    actions: {
      transitionToNewMacroRoute: function transitionToNewMacroRoute() {
        this.transitionToRoute('session.admin.automation.macros.new');
      },
      editMacro: function editMacro(macro) {
        this.transitionToRoute('session.admin.automation.macros.edit', macro.get('id'));
      },
      deleteMacro: function deleteMacro(macro, e) {
        var _this = this;

        e.stopPropagation();
        return this.get('confirmation').confirm({ intlConfirmationBody: 'generic.confirm.delete' }).then(function () {
          return _this.get('deleteAndRefreshMacrosList').perform(macro);
        });
      }
    }
  });
});