define('frontend-cp/services/profile-preferences', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;


  var PATH = '/profile/preferences';

  var DEFAULT_PREFERENCES = {
    desktopSoundAlerts: false,
    desktopSoundAlertsRealtimeOnly: false
  };

  exports.default = Service.extend({
    store: service(),

    _preferences: null,

    preferences: computed('_preferences', function () {
      var preferences = this.get('_preferences');

      return Object.keys(preferences).reduce(function (prefs, key) {
        if (prefs[key] === null) {
          prefs[key] = DEFAULT_PREFERENCES[key];
        }

        return prefs;
      }, preferences);
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('_preferences', {});
    },
    loadPreferences: function loadPreferences() {
      return this.get('_loadPreferences').perform();
    },
    savePreferences: function savePreferences(preferences) {
      return this.get('_savePreferences').perform(preferences);
    },


    isSaving: computed.reads('_savePreferences.isRunning'),

    _loadPreferences: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var _this = this;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              return _context.abrupt('return', this._load().then(function (result) {
                var preferences = _this._deserializePreferences(result.data);
                _this.set('_preferences', preferences);

                return result;
              }));

            case 1:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    _savePreferences: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(preferences) {
      var data;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              data = this._serializePreference(preferences);
              _context2.next = 3;
              return this._save(data);

            case 3:
              return _context2.abrupt('return', this.loadPreferences());

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    _deserializePreferences: function _deserializePreferences(prefs) {
      return {
        desktopSoundAlerts: prefs.desktop_sound_alerts,
        desktopSoundAlertsRealtimeOnly: prefs.desktop_sound_alerts_realtime_only
      };
    },
    _serializePreference: function _serializePreference(prefs) {
      return {
        desktop_sound_alerts: prefs.desktopSoundAlerts,
        desktop_sound_alerts_realtime_only: prefs.desktopSoundAlertsRealtimeOnly
      };
    },
    _adapter: function _adapter() {
      return this.get('store').adapterFor('application');
    },
    _url: function _url() {
      var adapter = this._adapter();
      return '' + adapter.namespace + PATH;
    },
    _load: function _load() {
      var adapter = this._adapter();
      var url = this._url();

      return adapter.ajax(url, 'GET');
    },
    _save: function _save(data) {
      var adapter = this._adapter();
      var url = this._url();

      return adapter.ajax(url, 'PUT', { data: data });
    }
  });
});