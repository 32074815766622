define('frontend-cp/helpers/join-classes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.joinClasses = joinClasses;
  var helper = Ember.Helper.helper;
  function joinClasses(params) {
    return params.join(' ');
  }

  exports.default = helper(joinClasses);
});