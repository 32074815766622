define("frontend-cp/components/ko-admin/time-tracking/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "wrapper": "ko-admin_time-tracking__wrapper_kzx4u",
    "text": "ko-admin_time-tracking__text_kzx4u",
    "title": "ko-admin_time-tracking__title_kzx4u",
    "sub-title": "ko-admin_time-tracking__sub-title_kzx4u",
    "features": "ko-admin_time-tracking__features_kzx4u",
    "feature": "ko-admin_time-tracking__feature_kzx4u",
    "feature-title": "ko-admin_time-tracking__feature-title_kzx4u",
    "feature-description": "ko-admin_time-tracking__feature-description_kzx4u",
    "image": "ko-admin_time-tracking__image_kzx4u"
  };
});