define('frontend-cp/services/notification', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var isEqual = _npmLodash.default.isEqual;


  var DUP_THRESHOLD_MS = 2000;

  exports.default = Service.extend({
    notifications: null,
    timestamps: null,

    init: function init() {
      this._super();
      this.set('notifications', this.get('notifications') || []);
      this.set('timestamps', new WeakMap());
    },


    /**
     * Add a notification
     * @param {object} notification Notification object
     * @param {string} notification.type Notification type (allowed values: 'info', 'warning', 'error', 'success')
     * @param {string} notification.title Notification title text
     * @param {string} [notification.body=null] Notification body text
     * @param {boolean} [notification.dismissable=false] Whether to allow the user to close the notification
     * @param {boolean} [notification.autodismiss=false] Whether to automatically dismiss the message after a timeout
     * @param {date} [timestamp] timestamp to associate with the notification
     */
    add: function add(notification) {
      var timestamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();

      var notifications = this.get('notifications');
      var timestamps = this.get('timestamps');

      // Skip notifications that should only appear *once* per session.
      if (notification.unique && notifications.findBy('title', notification.title)) {
        return;
      }

      // Skip indentical notifications received in quick succession.
      if (this.get('notifications.length') > 0) {
        var previousNotification = this.get('notifications.lastObject');
        var previousTimestamp = timestamps.get(previousNotification);
        var timeSincePrevious = timestamp - previousTimestamp;
        var withinDupTreshold = timeSincePrevious < DUP_THRESHOLD_MS;

        if (withinDupTreshold && isEqual(notification, previousNotification)) {
          return;
        }
      }

      notifications.pushObject(notification);
      timestamps.set(notification, timestamp);
    },
    success: function success(message) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var notifications = this.get('notifications');
      notifications.pushObject(Object.assign({
        type: 'success',
        title: message,
        autodismiss: true
      }, params));
    },
    error: function error(message) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var notifications = this.get('notifications');
      notifications.pushObject(Object.assign({
        type: 'error',
        title: message,
        autodismiss: true
      }, params));
    },
    removeAll: function removeAll() {
      this.get('notifications').clear();
    },


    /**
     * Remove a notification
     * @param {object} notification Notification that has previously been added
     */
    remove: function remove(notification) {
      var notifications = this.get('notifications');

      if (notification.onClose) {
        notification.onClose(notification);
      }

      notifications.removeObject(notification);
    }
  });
});