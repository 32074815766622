define('frontend-cp/lib/chart-point-intervals', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function getChartPointInterval(interval) {
    var intervals = {
      DAY: 24 * 3600 * 1000,
      WEEK: 24 * 3600 * 1000 * 7,
      MONTH: 24 * 3600 * 1000 * 31
    };

    return intervals[interval];
  }

  function getTickInterval(interval) {
    if (interval === 'DAY') {
      return getChartPointInterval(interval) * 2;
    }

    return getChartPointInterval(interval);
  }

  exports.getChartPointInterval = getChartPointInterval;
  exports.getTickInterval = getTickInterval;
});