define('frontend-cp/components/ko-timeline-2/streaks', ['exports', 'frontend-cp/components/ko-timeline-2/list/activity/standard/styles'], function (exports, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = streaks;
  var jQuery = Ember.$;
  function streaks(container) {
    var posts = jQuery(container).children().get();
    var streaks = [];
    var streak = void 0;

    posts.forEach(function (post) {
      if (post.querySelector('[data-connectable]') || post.querySelector('[data-connectable-no-icon]')) {
        if (!streak) {
          streak = [];
          streaks.push(streak);
        }
        streak.push(post);
      } else {
        streak = null;
      }
    });

    var activityItemClass = _styles.default.activity;

    streaks.forEach(function (streak) {
      if (streak.length === 1) {
        var targetDiv = jQuery(streak[0]).find('div.' + activityItemClass);

        if (targetDiv.data('connectable') !== undefined) {
          streak[0].setAttribute('data-single', '');
        } else if (targetDiv.data('connectable-no-icon') !== undefined) {
          streak[0].setAttribute('data-single-no-icon', '');
        }
      }

      streak.forEach(function (post, index) {
        var targetDiv = jQuery(post).find('div.' + activityItemClass);

        if (index === streak.length - 1) {
          post.removeAttribute('data-connect');
          post.removeAttribute('data-connect-no-icon');
        } else {
          if (targetDiv.data('connectable') !== undefined) {
            post.setAttribute('data-connect', '');
          } else if (targetDiv.data('connectable-no-icon') !== undefined) {
            post.setAttribute('data-connect-no-icon', '');
          }
        }
      });
    });
  }
});