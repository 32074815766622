define('frontend-cp/components/ko-admin/fields/form/types/component', ['exports', 'ember-service/inject', 'ember-concurrency', 'frontend-cp/services/virtual-model'], function (exports, _inject, _emberConcurrency, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var observer = Ember.observer;
  var computed = Ember.computed;


  var schema = (0, _virtualModel.model)('case-type', {
    label: (0, _virtualModel.attr)()
  });

  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    // HTML
    tagName: '',

    // Attributes
    types: [],
    onEditedChange: function onEditedChange() {},

    // State
    originalType: null,
    editedType: null,

    // Services
    confirmation: service(),
    plan: service(),
    virtualModel: service(),
    metrics: service(),

    // CPs
    systemTypes: computed('types.[]', function () {
      return this.get('types').rejectBy('caseTypeType', 'CUSTOM');
    }),

    customTypes: computed('types.[]', function () {
      return this.get('types').filterBy('caseTypeType', 'CUSTOM');
    }),

    isPlanEnabled: computed('plan', function () {
      return this.get('plan').has('custom_case_types');
    }),

    // Tasks
    addType: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var type;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              type = this.get('types').createRecord();
              _context.prev = 1;
              _context.next = 4;
              return this.get('startEditing').perform(type);

            case 4:
              _context.next = 9;
              break;

            case 6:
              _context.prev = 6;
              _context.t0 = _context['catch'](1);

              type.rollbackAttributes();

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[1, 6]]);
    })).drop(),

    startEditing: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(type) {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('cancelEditing').perform();

            case 2:
              this.set('originalType', type);
              this.set('editedType', this.get('virtualModel').makeSnapshot(type, schema));

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    cancelEditing: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              if (!(this.get('editedType') && (0, _virtualModel.isEdited)(this.get('originalType'), this.get('editedType'), schema))) {
                _context3.next = 3;
                break;
              }

              _context3.next = 3;
              return this.get('confirmation').confirm({
                intlConfirmationHeader: 'generic.confirm.lose_changes_header',
                intlConfirmationBody: 'generic.confirm.lose_changes',
                intlConfirmLabel: 'generic.confirm.lose_changes_button'
              });

            case 3:
              if (this.get('originalType.isNew')) {
                this.get('originalType').rollbackAttributes();
              }
              this.set('originalType', null);
              this.set('editedType', null);

            case 6:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).drop(),

    saveType: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      var launchDarkly;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');
              _context4.next = 3;
              return this.get('virtualModel').save(this.get('originalType'), this.get('editedType'), schema);

            case 3:
              if (launchDarkly.get('release-event-tracking') && this.get('originalType.isNew')) {
                this.get('metrics').trackEvent({
                  event: 'type_created'
                });
              }
              this.set('originalType', null);
              this.set('editedType', null);

            case 6:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })).drop(),

    removeType: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5(type) {
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              _context5.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmLabel: 'generic.confirm.delete_button',
                intlConfirmationBody: 'generic.confirm_delete.body'
              });

            case 2:
              type.destroyRecord();

            case 3:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })).drop(),

    editedTypeObserver: observer('editedType', function () {
      this.get('onEditedChange')(Boolean(this.get('editedType')));
    }),

    willDestroyElement: function willDestroyElement() {
      if (this.get('originalType.isNew')) {
        this.get('originalType').rollbackAttributes();
      }
    },


    actions: {
      removeType: function removeType(type, event) {
        event.stopPropagation();
        this.get('removeType').perform(type);
      }
    }
  });
});