define("frontend-cp/session/admin/automation/macros/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "session_admin_automation_macros_index__title_1y25xm",
    "caption": "session_admin_automation_macros_index__caption_1y25xm",
    "macros-list": "session_admin_automation_macros_index__macros-list_1y25xm",
    "loading-state": "session_admin_automation_macros_index__loading-state_1y25xm"
  };
});