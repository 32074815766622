define('frontend-cp/components/ko-timeline-2/list/activity/summary-text/component', ['exports', 'frontend-cp/utils/parse-summary'], function (exports, _parseSummary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    model: null,
    activity: null,

    store: service(),

    // Methods
    getUserFromToken: function getUserFromToken(token) {
      var id = token.url.match(/[0-9]+$/)[0];
      return this.get('store').peekRecord('user', id);
    },


    // CPs
    activityBelongsToCase: computed.bool('activity.kase.id'),
    tokens: computed('summary', function () {
      var summary = this.get('summary');
      if (!summary) {
        return [];
      }
      return (0, _parseSummary.default)(summary) || [];
    })
  });
});