define('frontend-cp/services/socket', ['exports', 'frontend-cp/config/environment', 'frontend-cp/services/socket/channel'], function (exports, _environment, _channel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.STATE_OPEN = exports.STATE_CONNECTING = exports.STATE_CLOSED = undefined;
  var getOwner = Ember.getOwner;
  var RSVP = Ember.RSVP;
  var Service = Ember.Service;
  var Evented = Ember.Evented;
  var Logger = Ember.Logger;
  var STATE_CLOSED = exports.STATE_CLOSED = 'closed';
  var STATE_CONNECTING = exports.STATE_CONNECTING = 'connecting';
  var STATE_OPEN = exports.STATE_OPEN = 'open';

  exports.default = Service.extend(Evented, {
    state: STATE_CLOSED,
    hasAttemptedConnection: false,
    hasConnectedOnce: false,

    init: function init() {
      this._super.apply(this, arguments);
      this._channels = {};
    },
    connect: function connect() {
      var _this = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (this._socket && this._socket.isConnected()) {
        return RSVP.resolve();
      }

      this._socket = this.createSocket({ params: params });

      return new RSVP.Promise(function (resolve, reject) {
        _this.set('hasAttemptedConnection', true);

        _this._socket.onOpen(function () {
          _this.set('hasConnectedOnce', true);
          _this.set('state', STATE_OPEN);
          _this.trigger('onOpen');
          resolve();
        });

        _this._socket.onError(function (e) {
          _this.set('state', STATE_CLOSED);
          _this.trigger('onError');
          reject(e);
        });

        _this._socket.onClose(function () {
          _this.set('state', STATE_CLOSED);
          _this.trigger('onClose');
        });

        _this.set('state', STATE_CONNECTING);
        _this._socket.connect();
      });
    },
    channel: function channel(name) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (!this._socket) {
        throw new Error('Must connect before joining a channel (' + name + ')');
      }

      var channel = this._channels[name];
      if (!channel) {
        channel = this.createChannel({ _socket: this._socket, name: name, params: params });
        this._channels[name] = channel;
      }

      return channel;
    },
    hasJoinedChannel: function hasJoinedChannel(name) {
      var channel = this._channels[name];
      return channel && channel.get('state') === 'joined';
    },
    push: function push(name, message) {
      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var channel = this._channels[name];
      if (!channel) {
        throw new Error('channel has not been joined (' + name + ')');
      }
      return channel.push(message, data);
    },
    request: function request(name, message) {
      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var channel = this._channels[name];
      if (!channel) {
        return RSVP.reject(new Error('channel has not been joined (' + name + ')'));
      }
      return channel.request(message, data);
    },
    leaveChannel: function leaveChannel(name) {
      var channel = this._channels[name];
      if (!channel) {
        Logger.debug('Warning: channel has not been joined (' + name + ')');
        return RSVP.resolve();
      }
      return channel.leave();
    },
    createSocket: function createSocket(options) {
      var url = _environment.default.kreSocket;
      var Socket = getOwner(this).lookup('constructor:socket');
      return new Socket(url, options);
    },
    createChannel: function createChannel(options) {
      return _channel.default.create(options);
    }
  });
});