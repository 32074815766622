define("frontend-cp/components/ko-recent-cases/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "root": "ko-recent-cases__root_1tzzxl",
    "heading": "ko-recent-cases__heading_1tzzxl",
    "subject": "ko-recent-cases__subject_1tzzxl",
    "case-info": "ko-recent-cases__case-info_1tzzxl",
    "missing": "ko-recent-cases__missing_1tzzxl",
    "case-item": "ko-recent-cases__case-item_1tzzxl",
    "case-item-inner": "ko-recent-cases__case-item-inner_1tzzxl",
    "title": "ko-recent-cases__title_1tzzxl",
    "involved-users": "ko-recent-cases__involved-users_1tzzxl",
    "avatar": "ko-recent-cases__avatar_1tzzxl",
    "last-updated": "ko-recent-cases__last-updated_1tzzxl",
    "no-assignee": "ko-recent-cases__no-assignee_1tzzxl",
    "status": "ko-recent-cases__status_1tzzxl",
    "case-status": "ko-recent-cases__case-status_1tzzxl",
    "case-status-NEW": "ko-recent-cases__case-status-NEW_1tzzxl",
    "case-status-OPEN": "ko-recent-cases__case-status-OPEN_1tzzxl",
    "case-status-PENDING": "ko-recent-cases__case-status-PENDING_1tzzxl",
    "case-status-COMPLETED": "ko-recent-cases__case-status-COMPLETED_1tzzxl",
    "case-status-CLOSED": "ko-recent-cases__case-status-CLOSED_1tzzxl",
    "case-status-CUSTOM": "ko-recent-cases__case-status-CUSTOM_1tzzxl",
    "middot": "ko-recent-cases__middot_1tzzxl",
    "view-all-cases": "ko-recent-cases__view-all-cases_1tzzxl"
  };
});