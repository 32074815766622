define("frontend-cp/components/ko-admin/facebook/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "availablePages": "ko-admin_facebook_index__availablePages_pgnhe8",
    "availablePage": "ko-admin_facebook_index__availablePage_pgnhe8",
    "no-pages": "ko-admin_facebook_index__no-pages_pgnhe8",
    "reconnect": "ko-admin_facebook_index__reconnect_pgnhe8",
    "error": "ko-admin_facebook_index__error_pgnhe8",
    "title": "ko-admin_facebook_index__title_pgnhe8",
    "caption": "ko-admin_facebook_index__caption_pgnhe8",
    "cancel": "ko-admin_facebook_index__cancel_pgnhe8"
  };
});