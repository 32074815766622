define('frontend-cp/session/admin/customizations/case-fields/select-type/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      setCaseFieldType: function setCaseFieldType(caseFieldType) {
        this.transitionToRoute('session.admin.customizations.case-fields.new', caseFieldType);
      },
      canceled: function canceled() {
        this.transitionToRoute('session.admin.customizations.case-fields.index');
      }
    }
  });
});