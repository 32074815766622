define("frontend-cp/components/ko-select/trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-select_trigger__trigger_1rsgoc",
    "input": "ko-select_trigger__input_1rsgoc",
    "loading": "ko-select_trigger__loading_1rsgoc _loading_jvqwfg"
  };
});