define('frontend-cp/components/ko-admin/forms/description/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var htmlSafe = Ember.String.htmlSafe;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    description: '',
    link: null,
    linkText: '',
    small: false,

    // Services
    i18n: service(),

    linkContents: computed('link', 'linkText', function () {
      var link = this.get('link');
      if (link) {
        var linkText = this.get('i18n').t(this.get('linkText'));
        return htmlSafe('<a href="' + link + '" target="_blank" rel="noopener noreferrer">' + linkText + '</a>');
      } else {
        return null;
      }
    })
  });
});