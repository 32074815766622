define('frontend-cp/components/ko-journey-timeline/note/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    notification: service(),
    i18n: service(),
    store: service(),
    session: service(),

    activity: null,
    model: null,
    note: null,

    pinTooltipText: computed('note.isPinned', function () {
      var i18n = this.get('i18n');
      var isPinned = this.get('note.isPinned');
      var modelName = this.get('activity.object.name');
      var pinMode = isPinned ? 'unpin' : 'pin';

      switch (modelName) {
        case 'user':
          return i18n.t('generic.' + pinMode + '.user');
        case 'organization':
          return i18n.t('generic.' + pinMode + '.org');
      }
    }),

    toggleNotePin: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(activity) {
      var modelName, entityName, entityId, note, pinNote, user, errMessage;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              modelName = this.get('activity.object.name');
              entityName = modelName + 's';
              entityId = this.get('activity.object.url').match(/[0-9]+$/)[0];
              note = this.get('note.content');
              pinNote = !this.get('note.isPinned');
              user = void 0, errMessage = void 0;
              _context.prev = 6;

              if (pinNote) {
                user = this.get('session.user');
              }
              note.setProperties({ isPinned: pinNote, pinnedBy: user });
              _context.next = 11;
              return note.save({ adapterOptions: { entityName: entityName, entityId: entityId } });

            case 11:
              _context.next = 18;
              break;

            case 13:
              _context.prev = 13;
              _context.t0 = _context['catch'](6);

              if (pinNote) {
                errMessage = 'generic.pin_failed';
              } else {
                errMessage = 'generic.unpin_failed';
              }

              note.rollbackAttributes();

              this.get('notification').error(this.get('i18n').t(errMessage));

            case 18:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[6, 13]]);
    })).drop()
  });
});