define("frontend-cp/components/smart-banner/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "inner": "smart-banner__inner_wem94w",
    "app-details": "smart-banner__app-details_wem94w",
    "close-button": "smart-banner__close-button_wem94w",
    "icon": "smart-banner__icon_wem94w",
    "info": "smart-banner__info_wem94w",
    "title": "smart-banner__title_wem94w",
    "description": "smart-banner__description_wem94w",
    "view-button": "smart-banner__view-button_wem94w"
  };
});