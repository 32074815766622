define('frontend-cp/components/ko-notification-center/preferences/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    session: service(),
    store: service(),
    notification: service(),
    i18n: service(),

    willDestroyElement: function willDestroyElement() {
      this._rollbackPreferences();
    },


    preferences: computed(function () {
      return this.get('store').findAll('notification-preference', { reload: true });
    }),

    isEdited: computed('preferences.@each.hasDirtyAttributes', function () {
      return this.get('preferences').isAny('hasDirtyAttributes', true);
    }),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var data;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              data = this.get('preferences').filterBy('hasDirtyAttributes').map(function (data) {
                return {
                  notification_type: data.get('notificationType'),
                  channel_desktop: data.get('channelDesktop'),
                  channel_mobile: data.get('channelMobile'),
                  channel_email: data.get('channelEmail')
                };
              });
              _context.prev = 1;
              _context.next = 4;
              return this.get('store').adapterFor('notification-preference').updatePreferences(data);

            case 4:

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('notifications.preferences.success'),
                autodismiss: true
              });

              this.get('onClose')();
              _context.next = 11;
              break;

            case 8:
              _context.prev = 8;
              _context.t0 = _context['catch'](1);

              this.get('notification').add({
                type: 'error',
                title: this.get('i18n').t('generic.generic_error'),
                autodismiss: true
              });

            case 11:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[1, 8]]);
    })),

    _rollbackPreferences: function _rollbackPreferences() {
      this.get('preferences').forEach(function (preference) {
        preference.rollbackAttributes();
      });
    }
  });
});