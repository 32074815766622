define('frontend-cp/session/agent/users/user/index/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route-child'], function (exports, _tabbedRouteChild) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = _tabbedRouteChild.default.extend({
    store: service(),
    timelineService: service('timeline'),
    queryParams: {
      timestamp: {
        refreshModel: true
      }
    },

    model: function model(_ref) {
      var timestamp = _ref.timestamp;

      var store = this.get('store');
      var user = this.modelFor('session.agent.users.user');
      var timelineService = this.get('timelineService');

      return RSVP.hash({
        timeline: timelineService.timelineForUser(user),
        statuses: store.findAll('case-status'),
        caseFields: store.findAll('case-field'),
        user: user,
        timestamp: timestamp
      });
    },
    setupController: function setupController(controller, models) {
      var parentController = this.controllerFor('session.agent.users.user');
      controller.set('state', parentController.get('state'));
      controller.setProperties(models);
    }
  });
});