define("frontend-cp/components/ko-admin/brands/form/domain-input/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-admin_brands_form_domain-input__container_1kwwd3",
    "focused": "ko-admin_brands_form_domain-input__focused_1kwwd3",
    "input": "ko-admin_brands_form_domain-input__input_1kwwd3",
    "domain": "ko-admin_brands_form_domain-input__domain_1kwwd3"
  };
});