define('frontend-cp/components/ko-admin/brands/form/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var filterBy = Ember.computed.filterBy;
  var empty = Ember.computed.empty;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;


  var DOMAIN_AVAILABLE = 'domain-available';
  var DOMAIN_NOT_AVAILABLE = 'domain-not-available';

  exports.default = Component.extend({
    tagName: '',

    // Attributes
    schema: null,
    brand: null,
    editedBrand: null,
    locales: [],
    tabs: null,

    onCancel: null,
    onSuccess: null,
    onError: null,

    // State
    settingSsl: false,

    // Services
    store: service(),
    virtualModel: service(),
    i18n: service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var brand = this.get('brand');

      if (brand.get('isSslEnabled')) {
        this.get('getSslCertificate').perform(brand);
      }
    },


    getSslCertificate: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(brand) {
      var payload, cert;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('store').adapterFor('brand').getSslCertificates(brand);

            case 2:
              payload = _context.sent;
              cert = payload.data.certificate;


              this.get('brand').set('sslCertificate', cert);
              this.get('editedBrand').set('sslCertificate', cert);

            case 6:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    // CPs
    enabledLocales: filterBy('locales', 'isPublic', true),

    isValid: computed('brand.isNew', 'checkDomainAvailability.last.value.status', function () {
      return !this.get('brand.isNew') || this.get('checkDomainAvailability.last.value.status') === DOMAIN_AVAILABLE;
    }),

    isAliasEmpty: empty('editedBrand.alias'),

    checkDomainAvailability: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(e) {
      var i18n, subDomain, adapter, request;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              i18n = this.get('i18n');
              subDomain = e.target.value;

              if (subDomain) {
                _context2.next = 4;
                break;
              }

              return _context2.abrupt('return', null);

            case 4:
              if (!(subDomain.length < 3)) {
                _context2.next = 6;
                break;
              }

              return _context2.abrupt('return', {
                status: DOMAIN_NOT_AVAILABLE,
                message: i18n.t('admin.brands.edit.domain.too_short')
              });

            case 6:
              if (/^\w[\w-]*\w$/.test(subDomain)) {
                _context2.next = 8;
                break;
              }

              return _context2.abrupt('return', {
                status: DOMAIN_NOT_AVAILABLE,
                message: i18n.t('admin.brands.edit.domain.invalid_characters')
              });

            case 8:

              this.set('editedBrand.subDomain', subDomain);

              _context2.next = 11;
              return (0, _emberConcurrency.timeout)(250);

            case 11:
              adapter = getOwner(this).lookup('adapter:domain-availability');
              request = adapter.ajax(adapter.namespace + '/brands/available', 'POST', {
                data: {
                  sub_domain: subDomain
                }
              });
              _context2.prev = 13;
              _context2.next = 16;
              return request;

            case 16:
              return _context2.abrupt('return', {
                status: DOMAIN_AVAILABLE,
                message: i18n.t('admin.brands.edit.domain.available')
              });

            case 19:
              _context2.prev = 19;
              _context2.t0 = _context2['catch'](13);
              return _context2.abrupt('return', {
                status: DOMAIN_NOT_AVAILABLE,
                message: i18n.t('admin.brands.edit.domain.not_available')
              });

            case 22:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this, [[13, 19]]);
    })).restartable(),

    checkAliasValidity: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(e) {
      var adapter, subDomain, alias, request;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              adapter = getOwner(this).lookup('adapter:application');
              subDomain = this.get('editedBrand.subDomain');
              alias = this.get('editedBrand.alias');
              request = adapter.ajax(adapter.namespace + '/brands/validate', 'POST', {
                data: {
                  sub_domain: subDomain,
                  alias: alias
                }
              });
              _context3.prev = 4;
              _context3.next = 7;
              return request;

            case 7:
              return _context3.abrupt('return', {
                alias: alias,
                status: 'valid'
              });

            case 10:
              _context3.prev = 10;
              _context3.t0 = _context3['catch'](4);
              return _context3.abrupt('return', {
                alias: alias,
                status: 'invalid'
              });

            case 13:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this, [[4, 10]]);
    })),

    actions: {
      save: function save() {
        var virtualModel = this.get('virtualModel');
        var schema = this.get('schema');
        var brand = this.get('brand');
        var editedBrand = this.get('editedBrand');
        return virtualModel.save(brand, editedBrand, schema);
      },
      startSettingSsl: function startSettingSsl() {
        this.set('settingSsl', true);
      },
      stopSettingSsl: function stopSettingSsl() {
        this.set('editedBrand.sslCertificate', this.get('brand.sslCertificate'));
        this.set('editedBrand.privateKey', '');
        this.set('settingSsl', false);
      }
    }
  });
});