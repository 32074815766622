define('frontend-cp/session/route', ['exports', 'ember-service/inject', 'ember-keyboard-shortcuts/mixins/route', 'frontend-cp/config/environment', 'frontend-cp/utils/bugsnag'], function (exports, _inject, _route, _environment, _bugsnag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Route$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Route = Ember.Route;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  var jQuery = Ember.$;
  var getOwner = Ember.getOwner;
  exports.default = Route.extend(_route.default, (_Route$extend = {
    launchDarkly: (0, _inject.default)(),

    store: service(),
    session: service(),
    analytics: service(),
    // appcues: service(),
    tabStore: service(),
    locale: service(),
    errorHandler: service(),
    caseListTab: service(),
    socket: service(),
    onBoardingStatus: service(),
    processManager: service(),
    notification: service('notification'),
    realtimePush: service()
  }, _defineProperty(_Route$extend, 'launchDarkly', service()), _defineProperty(_Route$extend, 'plan', service()), _defineProperty(_Route$extend, 'apps', service()), _defineProperty(_Route$extend, 'notificationPreferences', service()), _defineProperty(_Route$extend, 'profilePreferences', service()), _defineProperty(_Route$extend, 'keyboardShortcuts', {
    'g c': {
      action: 'goToCases',
      global: false
    },
    'g i': {
      action: 'goToInsights',
      global: false
    },
    'g a': {
      action: 'goToAdmin',
      global: false
    },
    '?': {
      action: 'toggleKeyboardHelp',
      global: false
    },
    esc: {
      action: 'removeFocus',
      global: true
    },
    '/': {
      action: 'openSearch',
      global: false
    }
  }), _defineProperty(_Route$extend, 'beforeModel', function beforeModel(transition) {
    var _this = this;

    // we ignore errors before locale strings are loaded
    return this.get('errorHandler').disableWhile(function () {
      return _this.get('session').getSession().then(function () {
        return _this._setupApp();
      }, function () {
        return _this._transitionToLogin(transition);
      }).catch(function (error) {
        if (!Ember.testing && window.Bugsnag) {
          window.Bugsnag.notifyException(error, 'Failed setting up app', {}, 'error');
        }

        transition.abort();
        _this.get('notification').add({
          type: 'error',
          title: 'Ooops. Something went wrong',
          body: 'We have been notified of this. Refresh to try again.',
          autodismiss: false
        });
      });
    });
  }), _defineProperty(_Route$extend, '_setupApp', function _setupApp() {
    var _this2 = this;

    return RSVP.all([this.get('analytics').setup(),
    // this.get('appcues').identify(),
    this.get('locale').setup(), this.get('store').findAll('brand'), this.get('onBoardingStatus').getSettings(), this.get('onBoardingStatus').getCompletedCases(), this.get('notificationPreferences').loadPreferences(), this.get('profilePreferences').loadPreferences(), this.setupPush(), this.setupKRE(), this.setupApps(), this._identifyLaunchDarkly()]).then(function () {
      return _this2.get('processManager').restoreProcesses();
    });
  }), _defineProperty(_Route$extend, '_identifyLaunchDarkly', function _identifyLaunchDarkly() {
    var user = this.get('session.user');

    var props = {
      key: user.get('uuid'),
      anonymous: false,
      email: user.get('primaryEmailAddress'),
      avatar: user.get('avatar'),
      custom: {
        fullName: user.get('fullName'),
        role: user.get('role.roleType'),
        createdAt: user.get('createdAt'),
        instance: this.get('session.session.instanceName'),
        host: window.location.hostname,
        isTrial: this.get('plan.isTrial'),
        appVersion: _environment.default.currentRevision
      }
    };

    return this.get('launchDarkly').identify(props);
  }), _defineProperty(_Route$extend, '_transitionToLogin', function _transitionToLogin(transition) {
    this.get('session').set('attemptedTransition', transition);
    this.transitionTo('login-agent');
  }), _defineProperty(_Route$extend, 'model', function model() {
    return RSVP.hash({
      brands: this.get('store').peekAll('brand')
    });
  }), _defineProperty(_Route$extend, 'setupController', function setupController(controller, _ref) {
    var brands = _ref.brands;

    controller.set('brand', brands.findBy('isDefault', true));
  }), _defineProperty(_Route$extend, 'activate', function activate() {
    this._super.apply(this, arguments);
  }), _defineProperty(_Route$extend, 'setupApps', function setupApps() {
    var _this3 = this;

    var launchDarkly = this.get('launchDarkly');

    if (!launchDarkly.get('release-apps')) {
      return;
    }
    var apps = this.get('apps');
    return apps.setup().catch(function (e) {
      if (apps.get('isDevMode')) {
        _this3.get('notification').add({
          type: 'error',
          title: 'Failed to connect to local apps server',
          body: 'Ensure "kit server" is running and try again.',
          autodismiss: false
        });
      } else {
        if (!Ember.testing && window.Bugsnag) {
          var context = (0, _bugsnag.getMetaData)(null, getOwner(_this3));
          window.Bugsnag.notifyException(e, 'Failed to connect to apps server', context, 'error');
        }
      }
    });
  }), _defineProperty(_Route$extend, 'setupPush', function setupPush() {
    var launchDarkly = this.get('launchDarkly');

    if (!launchDarkly.get('feature-push-notifications')) {
      return RSVP.resolve();
    }
    return this.get('realtimePush').registerUserDevice().catch(function (e) {}); // swallow failures
  }), _defineProperty(_Route$extend, 'setupKRE', function setupKRE() {
    var _this4 = this;

    var socket = this.get('socket');

    socket.on('onError', function () {
      _this4.get('store').queryRecord('user', { id: 'me' }).catch(function (_ref2) {
        var errors = _ref2.errors;

        if (errors.findBy('code', 'AUTHORIZATION_REQUIRED')) {
          _this4.transitionTo('login-agent');
        }
      });
    });

    socket.connect({
      instance: this.get('session.session.instanceName'),
      session_id: this.get('session.sessionId'),
      user_agent: window.navigator.userAgent
    }).catch(function () {
      // swallow failures, we display a banner automatically
    });
  }), _defineProperty(_Route$extend, 'actions', {
    willTransition: function willTransition(transition) {
      this.controller.set('searchResults', null);
    },
    openSearchResult: function openSearchResult(routeName, obj, hasModifier) {
      var tabStore = this.get('tabStore');

      if (hasModifier) {
        tabStore.createTab(routeName, obj);
      } else {
        this.transitionTo(routeName, obj.get('id'));
      }
    },
    transitionToRoute: function transitionToRoute() {
      this.transitionTo.apply(this, arguments);
    },
    goToCases: function goToCases() {
      this.transitionTo('session.agent.cases.index');
    },
    goToInsights: function goToInsights() {
      this.transitionTo('session.agent.insights');
    },
    goToAdmin: function goToAdmin() {
      this.transitionTo('session.admin');
    },
    toggleKeyboardHelp: function toggleKeyboardHelp() {
      this.get('controller').toggleProperty('showingKeyboardHelp');
    },
    openSearch: function openSearch() {
      this.get('controller').set('isSearchActive', true);
    },
    closeSearch: function closeSearch() {
      this.get('controller').set('isSearchActive', false);
    },
    openAdvancedSearch: function openAdvancedSearch(term) {
      if (term) {
        this.controllerFor('session.agent.search').set('openInANewTab', true);
        this.transitionTo('session.agent.search', term);
      }
    },
    removeFocus: function removeFocus() {
      // has to be in the next "tick" otherwise it doesn't remove focus from contenteditable
      setTimeout(function () {
        var focused = document.activeElement;
        if (!focused) {
          return;
        }

        if (jQuery(focused).closest(':input, [contenteditable]').length) {
          focused.blur();
          window.getSelection().removeAllRanges();
        }
      }, 0);
    }
  }), _Route$extend));
});