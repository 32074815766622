define("frontend-cp/components/ko-identities/identity/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-identities_identity__trigger_3sg79c",
    "dropdown": "ko-identities_identity__dropdown_3sg79c _dropdown_1ilz5g _dropdown_jvqwfg",
    "link": "ko-identities_identity__link_3sg79c"
  };
});