define('frontend-cp/session/agent/users/user/organization/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route-child'], function (exports, _tabbedRouteChild) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  var getOwner = Ember.getOwner;
  exports.default = _tabbedRouteChild.default.extend({
    store: service(),
    timelineService: service('timeline'),
    queryParams: {
      timestamp: {
        refreshModel: true
      }
    },

    model: function model(_ref) {
      var timestamp = _ref.timestamp;

      var store = this.get('store');
      var user = this.modelFor('session.agent.users.user');
      var organization = user ? user.get('organization') : null;
      var timelineService = this.get('timelineService');

      return RSVP.resolve(organization).then(function (organization) {
        return RSVP.hash({
          timeline: timelineService.timelineForUserOrganization(user, organization),
          statuses: store.findAll('case-status'),
          caseFields: store.findAll('case-field'),
          user: user,
          organization: organization,
          timestamp: timestamp
        });
      });
    },
    afterModel: function afterModel(_ref2) {
      var organization = _ref2.organization;

      if (organization) {
        return RSVP.hash({
          domains: organization.get('domains'),
          tags: organization.get('tags')
        });
      }
    },
    setupController: function setupController(controller, model) {
      var organization = model.organization;

      var tab = this.retrieveTab();
      var tabId = tab.basePath;
      var state = this.createStateFor(organization, tab);

      controller.setProperties(model);
      controller.set('tabId', tabId);
      controller.set('state', state);
    },
    retrieveTab: function retrieveTab() {
      var userController = this.controllerFor('session.agent.users.user');
      var tab = userController.get('tab');

      return tab;
    },
    createStateFor: function createStateFor(organization, tab) {
      var owner = getOwner(this);
      var OrganizationStateManager = owner.factoryFor('state-manager:organization');
      var state = OrganizationStateManager.create({ organization: organization, tab: tab });

      return state;
    }
  });
});