define('frontend-cp/components/ko-loading/text-placeholder/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend({
    // Attrs
    tagName: '',

    width: null,
    height: '10px',
    margin: null,

    // CP's
    inlineStyles: computed('width', 'height', 'margin', function () {
      return htmlSafe('width:' + this.get('width') + '; height:' + this.get('height') + '; margin:' + this.get('margin') + ';');
    })
  });
});