define('frontend-cp/models/team', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    title: (0, _attr.default)('string', { defaultValue: '' }),
    memberCount: (0, _attr.default)('number'),
    businesshour: (0, _relationships.belongsTo)('business-hour'),
    members: (0, _relationships.hasMany)('user')
  });
});