define("frontend-cp/components/ko-insights/metric/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "metric": "ko-insights_metric__metric_1boro7",
    "name": "ko-insights_metric__name_1boro7",
    "first": "ko-insights_metric__first_1boro7",
    "values": "ko-insights_metric__values_1boro7",
    "value": "ko-insights_metric__value_1boro7",
    "delta": "ko-insights_metric__delta_1boro7",
    "deltaNegative": "ko-insights_metric__deltaNegative_1boro7",
    "triangle": "ko-insights_metric__triangle_1boro7",
    "triangleUpPositive": "ko-insights_metric__triangleUpPositive_1boro7",
    "triangleUpNegative": "ko-insights_metric__triangleUpNegative_1boro7",
    "triangleDownPositive": "ko-insights_metric__triangleDownPositive_1boro7",
    "triangleDownNegative": "ko-insights_metric__triangleDownNegative_1boro7"
  };
});