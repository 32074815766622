define("frontend-cp/components/ko-merge-conversation-modal/suggestions/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "suggestions-container": "ko-merge-conversation-modal_suggestions__suggestions-container_cpobwn",
    "suggested-heading": "ko-merge-conversation-modal_suggestions__suggested-heading_cpobwn",
    "centered-loader": "ko-merge-conversation-modal_suggestions__centered-loader_cpobwn",
    "no-cases-placeholder": "ko-merge-conversation-modal_suggestions__no-cases-placeholder_cpobwn",
    "no-cases-text": "ko-merge-conversation-modal_suggestions__no-cases-text_cpobwn"
  };
});