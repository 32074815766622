define("frontend-cp/components/ko-organization-content/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "root": "ko-organization-content__root_1rujr1",
    "buttonLoading": "ko-organization-content__buttonLoading_1rujr1",
    "timeline-header": "ko-organization-content__timeline-header_1rujr1",
    "timeline-header-image": "ko-organization-content__timeline-header-image_1rujr1",
    "timeline-header-body": "ko-organization-content__timeline-header-body_1rujr1",
    "timeline-header-title": "ko-organization-content__timeline-header-title_1rujr1",
    "timeline-header-subtitle": "ko-organization-content__timeline-header-subtitle_1rujr1",
    "timeline-preamble": "ko-organization-content__timeline-preamble_1rujr1",
    "submit-properties": "ko-organization-content__submit-properties_1rujr1",
    "submitting": "ko-organization-content__submitting_1rujr1",
    "show-submit": "ko-organization-content__show-submit_1rujr1",
    "or-text": "ko-organization-content__or-text_1rujr1",
    "cancel-property-changes": "ko-organization-content__cancel-property-changes_1rujr1",
    "redirection-loader": "ko-organization-content__redirection-loader_1rujr1",
    "redirection-message": "ko-organization-content__redirection-message_1rujr1",
    "send-button": "ko-organization-content__send-button_1rujr1"
  };
});