define('frontend-cp/serializers/twitter-account-callback', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      account: { serialize: false }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.data.id = 1;
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});