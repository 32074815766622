define('frontend-cp/services/cookies', ['exports', 'ember-cookies/services/cookies'], function (exports, _cookies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var A = Ember.A;
  var isEmpty = Ember.isEmpty;
  var computed = Ember.computed;
  exports.default = _cookies.default.extend({
    _documentCookies: computed(function () {
      var all = this.get('_document.cookie').split(';');

      return A(all).reduce(function (acc, cookie) {
        if (!isEmpty(cookie)) {
          var _cookie$split = cookie.split('='),
              _cookie$split2 = _slicedToArray(_cookie$split, 2),
              key = _cookie$split2[0],
              value = _cookie$split2[1];

          if (value.trim()) {
            // FT-1143 skip blank cookie values
            acc[key.trim()] = value.trim();
          }
        }
        return acc;
      }, {});
    }).volatile()

  });
});