define('frontend-cp/components/ko-select/trigger/component', ['exports', 'frontend-cp/lib/keycodes'], function (exports, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var run = Ember.run;
  var observer = Ember.observer;
  exports.default = Component.extend({
    // HTML
    tagName: '',

    // Observers
    isOpenObserver: observer('select.isOpen', function () {
      var select = this.get('select');
      var oldIsOpen = this.get('oldIsOpen');

      if (!oldIsOpen && select.isOpen) {
        run.schedule('afterRender', this, function () {
          $('#ko-select-trigger-' + this.get('select.uniqueId') + ' input').focus();
        });
      } else if (oldIsOpen && !select.isOpen) {
        run.schedule('actions', null, select.actions.search, '');
      }

      this.set('oldIsOpen', select.isOpen);
    }),

    // Actions
    actions: {
      onKeydown: function onKeydown(e) {
        var onKeydown = this.get('onKeydown');
        if (onKeydown && onKeydown(e) === false) {
          e.stopPropagation();
          return false;
        }
        if (e.keyCode === _keycodes.space) {
          e.stopPropagation();
        }
      },
      keepOpened: function keepOpened(e) {
        if (this.get('select.isOpen')) {
          e.stopPropagation();
        }
      }
    }
  });
});