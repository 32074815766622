define("frontend-cp/components/ko-text-editor/link-manager/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-text-editor_link-manager__container_ecw6jt",
    "buttonContainer": "ko-text-editor_link-manager__buttonContainer_ecw6jt"
  };
});