define('frontend-cp/helpers/automation-action-builder-component-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.automationActionBuilderComponentName = automationActionBuilderComponentName;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var helper = Ember.Helper.helper;
  var get = Ember.get;
  var dasherize = Ember.String.dasherize;


  var basicInputs = ['integer', 'float', 'string'];
  var basicEndpointTypes = ['endpoint-slack', 'endpoint-email'];
  var postEndpointTypes = ['endpoint-http-xml', 'endpoint-http-json'];
  var booleanTypes = ['boolean'];
  var booleanTrueTypes = ['boolean-true'];
  var booleanFalseTypes = ['boolean-false'];
  var notificationTypes = ['notification-user', 'notification-team'];

  function automationActionBuilderComponentName(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        automationActionDefinition = _ref2[0];

    if (automationActionDefinition) {
      var inputType = dasherize(get(automationActionDefinition, 'inputType'));
      var componentName = inputType;
      if (basicInputs.indexOf(inputType) > -1 && automationActionDefinition.get('name') === 'send_message') {
        componentName = 'autocomplete-team-or-agent';
      } else if (basicInputs.indexOf(inputType) > -1) {
        componentName = 'input';
      }
      if (basicEndpointTypes.indexOf(inputType) > -1) {
        componentName = 'endpoint-basic';
      }
      if (postEndpointTypes.indexOf(inputType) > -1) {
        componentName = 'endpoint-http-post';
      }
      if (booleanTypes.indexOf(inputType) > -1) {
        componentName = 'boolean';
      }
      if (booleanTrueTypes.indexOf(inputType) > -1) {
        componentName = 'boolean-true';
      }
      if (booleanFalseTypes.indexOf(inputType) > -1) {
        componentName = 'boolean-false';
      }
      if (notificationTypes.indexOf(inputType) > -1) {
        componentName = 'notification';
      }
      if (inputType === 'autocomplete' && automationActionDefinition.get('name') === 'assignee') {
        componentName = 'autocomplete-agent';
      }
      return 'ko-admin/automation-actions-builder/' + componentName;
    }
  }

  exports.default = helper(automationActionBuilderComponentName);
});