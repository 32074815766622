define('frontend-cp/session/admin/customizations/brands/new/controller', ['exports', 'ember-service/inject', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/customizations/brands/schema'], function (exports, _inject, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    // Attributes
    brand: null,
    brands: [],
    editedBrand: null,
    schema: _schema.default,
    metrics: service(),

    // Services
    notification: service(),
    i18n: service(),
    confirmation: service(),
    virtualModel: service(),

    // Actions
    actions: {
      cancel: function cancel() {
        this.transitionToRoute('session.admin.customizations.brands.index');
      },
      onSuccess: function onSuccess() {
        var launchDarkly = this.get('launchDarkly');

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'brand_created',
            object: this.get('editedBrand.id'),
            name: this.get('editedBrand.domain'),
            alias: this.get('editedBrand.alias')
          });
        }
        this.transitionToRoute('session.admin.customizations.brands.edit.index', this.get('brand.id'));
      }
    },

    // Actions
    initEdits: function initEdits() {
      this.set('editedBrand', this.get('virtualModel').makeSnapshot(this.get('brand'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('brand'), this.get('editedBrand'), _schema.default);
    }
  });
});