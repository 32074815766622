define("frontend-cp/lib/keycodes", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var backspace = exports.backspace = 8;
  var tab = exports.tab = 9;
  var enter = exports.enter = 13;
  var shift = exports.shift = 16;
  var ctrl = exports.ctrl = 17;
  var alt = exports.alt = 18;
  var pause = exports.pause = 19;
  var capsLock = exports.capsLock = 20;
  var escape = exports.escape = 27;
  var space = exports.space = 32;
  var pageUp = exports.pageUp = 33;
  var pageDown = exports.pageDown = 34;
  var end = exports.end = 35;
  var home = exports.home = 36;
  var left = exports.left = 37;
  var up = exports.up = 38;
  var right = exports.right = 39;
  var down = exports.down = 40;
  var insert = exports.insert = 45;
  var del = exports.del = 46;
  var zero = exports.zero = 48;
  var one = exports.one = 49;
  var two = exports.two = 50;
  var three = exports.three = 51;
  var four = exports.four = 52;
  var five = exports.five = 53;
  var six = exports.six = 54;
  var seven = exports.seven = 55;
  var eight = exports.eight = 56;
  var nine = exports.nine = 57;
  var a = exports.a = 65;
  var b = exports.b = 66;
  var c = exports.c = 67;
  var d = exports.d = 68;
  var e = exports.e = 69;
  var f = exports.f = 70;
  var g = exports.g = 71;
  var h = exports.h = 72;
  var i = exports.i = 73;
  var j = exports.j = 74;
  var k = exports.k = 75;
  var l = exports.l = 76;
  var m = exports.m = 77;
  var n = exports.n = 78;
  var o = exports.o = 79;
  var p = exports.p = 80;
  var q = exports.q = 81;
  var r = exports.r = 82;
  var s = exports.s = 83;
  var t = exports.t = 84;
  var u = exports.u = 85;
  var v = exports.v = 86;
  var w = exports.w = 87;
  var x = exports.x = 88;
  var y = exports.y = 89;
  var z = exports.z = 90;
  var leftWindowKey = exports.leftWindowKey = 91;
  var rightWindowKey = exports.rightWindowKey = 92;
  var selectKey = exports.selectKey = 93;
  var numpad0 = exports.numpad0 = 96;
  var numpad1 = exports.numpad1 = 97;
  var numpad2 = exports.numpad2 = 98;
  var numpad3 = exports.numpad3 = 99;
  var numpad4 = exports.numpad4 = 100;
  var numpad5 = exports.numpad5 = 101;
  var numpad6 = exports.numpad6 = 102;
  var numpad7 = exports.numpad7 = 103;
  var numpad8 = exports.numpad8 = 104;
  var numpad9 = exports.numpad9 = 105;
  var multiply = exports.multiply = 106;
  var add = exports.add = 107;
  var subtract = exports.subtract = 109;
  var decimalPoint = exports.decimalPoint = 110;
  var divide = exports.divide = 111;
  var f1 = exports.f1 = 112;
  var f2 = exports.f2 = 113;
  var f3 = exports.f3 = 114;
  var f4 = exports.f4 = 115;
  var f5 = exports.f5 = 116;
  var f6 = exports.f6 = 117;
  var f7 = exports.f7 = 118;
  var f8 = exports.f8 = 119;
  var f9 = exports.f9 = 120;
  var f10 = exports.f10 = 121;
  var f11 = exports.f11 = 122;
  var f12 = exports.f12 = 123;
  var numLock = exports.numLock = 144;
  var scrollLock = exports.scrollLock = 145;
  var semiColon = exports.semiColon = 186;
  var equalSign = exports.equalSign = 187;
  var comma = exports.comma = 188;
  var dash = exports.dash = 189;
  var period = exports.period = 190;
  var forwardSlash = exports.forwardSlash = 191;
  var graveAccent = exports.graveAccent = 192;
  var openBracket = exports.openBracket = 219;
  var backSlash = exports.backSlash = 220;
  var closeBracket = exports.closeBracket = 221;
  var singleQuote = exports.singleQuote = 222;
});