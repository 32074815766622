define('frontend-cp/components/ko-case-content/field/assignee/component', ['exports', 'ember-keyboard-shortcuts/mixins/component', 'ember-concurrency'], function (exports, _component, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.generateAssigneeValues = generateAssigneeValues;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend(_component.default, {
    // Attributes
    agent: null,
    admin: null,
    field: null,
    isErrored: false,
    isEdited: false,
    isKREEdited: false,
    isDisabled: false,
    onValueChange: null,
    hasEmptyOption: false,
    emptyLabel: null,
    agents: null,
    teams: null,

    keyboardShortcuts: {
      'ctrl+alt+a': {
        action: 'open',
        global: false,
        preventDefault: true
      },
      a: {
        action: 'open',
        global: false
      }
    },

    // State
    assigneeValues: [],

    // HTML
    tagName: '',

    // Services
    store: service(),
    i18n: service(),

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('emptyLabel') === null) {
        this.set('emptyLabel', this.get('i18n').t('cases.unassigned'));
      }
      this.setAssigneeValues();
    },


    // CPs
    currentlySelectedValue: computed('agent.id', 'team.id', function () {
      if (this.get('team') === '0') {
        return '0';
      } else {
        return generateTeamAgentId(this.get('team.id'), this.get('agent.id'));
      }
    }),

    // Tasks
    getAgents: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var store;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              store = this.get('store');
              _context.next = 3;
              return store.query('user', {
                role: 'agent',
                limit: 500,
                include: ['role', 'identity-facebook', 'identity-twitter', 'identity-phone', 'identity_email']
              });

            case 3:
              return _context.abrupt('return', _context.sent);

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    // Actions
    actions: {
      assigneeSelected: function assigneeSelected(item) {
        var agent = null;
        var team = null;
        var id = get(item || {}, 'id');

        if (id && id === '0') {
          team = '0';
          agent = null;
        } else if (id) {
          var _id$split = id.split('-'),
              _id$split2 = _slicedToArray(_id$split, 2),
              teamId = _id$split2[0],
              agentId = _id$split2[1];

          team = this.get('teams').findBy('id', teamId);

          if (agentId) {
            agent = this.get('agents').findBy('id', agentId);
          }
        }

        this.attrs.onValueChange(team, agent);
      },
      open: function open() {
        this.get('drillDownComponent').send('open');
      }
    },

    // Methods
    generateTeamAgentId: function generateTeamAgentId(teamId, agentId) {
      return agentId ? teamId + '-' + agentId : teamId;
    },
    setAssigneeValues: function setAssigneeValues() {
      var _this = this;

      this.get('getAgents').perform().then(function (agents) {
        var teams = _this.get('teams');
        _this.set('agents', agents);
        var assigneeValues = [{
          value: _this.get('i18n').t('cases.unassigned'),
          id: '0',
          object: null,
          children: []
        }].concat(_toConsumableArray(generateAssigneeValues({ agents: agents, teams: teams })));
        _this.set('assigneeValues', assigneeValues);
      }).catch(function (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      });
    }
  });
  function generateAssigneeValues(_ref) {
    var agents = _ref.agents,
        teams = _ref.teams;

    agents = agents.filter(function (agent) {
      return agent.get('isEnabled');
    });
    var result = teams.map(function (team) {
      return {
        value: team.get('title'),
        id: team.get('id'),
        object: { team: team },
        children: []
      };
    });
    agents.forEach(function (agent) {
      agent.get('teams').forEach(function (team) {
        var agentsInTeam = result.find(function (i) {
          return i.value === team.get('title');
        }).children;
        agentsInTeam.pushObject({
          id: generateTeamAgentId(team.id, agent.id),
          object: { team: team, agent: agent },
          value: agent.get('fullName')
        });
      });
    });
    return result;
  }

  function generateTeamAgentId(teamId, agentId) {
    return agentId ? teamId + '-' + agentId : teamId;
  }
});