define('frontend-cp/components/ko-admin/rateplans/item-group/component', ['exports', 'ember-concurrency', 'npm:lodash'], function (exports, _emberConcurrency, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // attributes
    rateplans: [],
    // rateplan: null,
    selectedPlan: null,
    isDisabled: false,
    selectedTerm: null,
    selectedNumberOfSeats: '0', // make sure it is string
    selectedNumberOfCollaborators: '0', // make sure it is string
    defaultNumberOfCollaborators: '0', // bidirectional
    selectedSubscriptionDuration: { id: 3, text: '3 Years' }, // bidirectional,
    seatsLimit: '0', // make sure it is string
    collaboratorsLimit: '0', // make sure it is string
    grossTotal: 0,
    calculatingSummary: false,
    nextChargeDate: null,
    subscriptionAmount: 0,
    discountAmount: 0,
    stickySummary: false,

    // Services
    ratePlansService: service('rateplans'),
    plan: service(),
    store: service(),
    notification: service('notification'),
    i18n: service(),

    rateplan: computed('rateplanGroup', function () {
      return this.get('rateplanGroup')[2];
    }),

    numberOfCollaborators: computed('rateplan', 'selectedTerm', function () {
      var selectedTerm = this.get('selectedTerm');
      // can sometimes be undefined
      return this.get('rateplan.' + selectedTerm + '.collaborators') || 0;
    }),

    isSelected: computed('selectedPlan.{key,productId}', 'rateplan.{key,productId}', function () {
      var _this = this;

      try {
        return this.get('rateplanGroup').findIndex(function (plan) {
          return plan.label === _this.get('selectedPlan').label;
        }) > -1;
      } catch (err) {
        return false;
      }
    }),

    canAddCollaborators: computed('selectedPlan', function () {
      var plan = this.get('selectedPlan');

      return !plan.key.startsWith('enterprise');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('load').perform();
    },


    load: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!this.get('plan.isTrial')) {
                _context.next = 2;
                break;
              }

              return _context.abrupt('return');

            case 2:
              _context.next = 4;
              return this.get('loadSubscription').perform();

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    loadSubscription: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var store, subscription;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!this.get('subscription')) {
                _context2.next = 2;
                break;
              }

              return _context2.abrupt('return');

            case 2:
              store = this.get('store');
              _context2.next = 5;
              return store.queryRecord('subscription', {});

            case 5:
              subscription = _context2.sent;


              this.set('subscription', subscription);

            case 7:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    subscriptionRatePlan: computed('subscription.rateplans', function () {
      if (this.get('subscription.rateplans') !== undefined) {
        return this.get('ratePlansService').getSubscriptionRatePlan(this.get('subscription.rateplans'));
      }
      return;
    }),

    subscribedPlanKey: computed('subscriptionRatePlan.productRateplan.key', function () {
      if (this.get('plan.isTrial')) {
        return;
      }

      return this.get('subscriptionRatePlan.productRateplan.key');
    }),

    actions: {
      clicked: function clicked(plan) {
        if (!this.get('plan.isTrial')) {
          var canChangeSupportPlan = true;
          var subscribedPlanKey = this.get('subscribedPlanKey');

          if (subscribedPlanKey.includes('gold') && plan.key.includes('standard')) {
            canChangeSupportPlan = false;
          }

          if (subscribedPlanKey.includes('platinum')) {
            canChangeSupportPlan = false;
          }

          if (!canChangeSupportPlan) {
            this.get('notification').add({
              type: 'error',
              title: this.get('i18n').t('generic.block_subscription_downgrade'),
              autodismiss: true
            });

            return;
          }
        }

        this.set('rateplan', plan);
        this.set('selectedPlan', plan);
        this.sendAction('switchPlan', plan);
        this.sendAction('agentsChanged', { target: { value: this.get('selectedNumberOfSeats') } }, this.get('selectedSubscriptionDuration'));
      },

      updateSubscriptionDurationValue: function updateSubscriptionDurationValue(duration) {
        this.setSubscriptionDuration(duration);
        this.sendAction('switchSubscriptionDuration', duration);
      },

      switchSubscriptionDuration: function switchSubscriptionDuration(duration) {
        this.setSubscriptionDuration(duration);
      }
    },

    mixedCaseOptions: computed(function () {
      var subscriptionDurationOptions = [{ label: '1 Year', value: 1 }, { label: '3 Years', value: 3 }, { label: '5 Years', value: 5 }];

      var mixedCaseOptions = _npmLodash.default.map(subscriptionDurationOptions, function (item, key) {
        return { id: item.value, text: item.label };
      });
      this.set('selectedSubscriptionDuration', mixedCaseOptions[1]);
      return mixedCaseOptions;
    }),

    setSubscriptionDuration: function setSubscriptionDuration(duration) {
      if (duration === null) {
        return;
      }

      var subscriptionDurationOptions = [{ label: '1 Year', value: 1 }, { label: '3 Years', value: 3 }, { label: '5 Years', value: 5 }];
      var mixedsubscriptionDurationOptions = _npmLodash.default.map(subscriptionDurationOptions, function (item, key) {
        return { id: item.value, text: item.label };
      });

      if (this.get('selectedSubscriptionDuration') === null) {
        this.set('selectedSubscriptionDuration', mixedsubscriptionDurationOptions[1]);
      }

      if (duration !== null) {
        this.set('selectedSubscriptionDuration', duration);
      }

      this.sendAction('agentsChanged', { target: { value: this.get('selectedNumberOfSeats') } }, this.get('selectedSubscriptionDuration'));
    }
  });
});