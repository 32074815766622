define("frontend-cp/trial-data/insights/cases/resolution", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "status": 200,
    "data": {
      "start_at": "2016-05-28T00:00:00+00:00",
      "end_at": "2016-06-27T23:59:59+00:00",
      "previous_start_at": "2016-04-16T00:00:00+00:00",
      "previous_end_at": "2016-05-16T23:59:59+00:00",
      "interval": "DAY",
      "interval_count": 31,
      "series": {
        "name": "average_resolution_time",
        "data": [35414, 14922, 391850, 351190, 240956, 198490, 484105, 82009, 87590, 798632, 406476, 295724, 202794, 250357, 93477, 17113, 372639, 351190, 427973, 240956, 302621, 4841, 98403, 413235, 421243, 497974, 211923, 254827, 48364, 23544, 410192],
        "previous": [92362, 2029, 107354, 72760, 92362, 104913, 114574, 206963, 108724, 757133, 248328, 135925, 188867, 147768, 153571, 295724, 232099, 667834, 102661, 69369, 102444, 114431, 146759, 185006, 366202, 830462, 222754, 278259, 102233, 628674, 180974],
        "resource_type": "report_series_comparison"
      },
      "metric": {
        "name": "average_resolution_time",
        "value": 533355,
        "delta_percent": 102.174,
        "previous": 263810,
        "resource_type": "report_value_comparison"
      },
      "resource_type": "report_average_resolution"
    },
    "resources": [],
    "resource": "report_average_resolution"
  };
});