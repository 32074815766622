define("frontend-cp/components/ko-notification-center/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "wrapper": "ko-notification-center__wrapper_82j11e",
    "bell": "ko-notification-center__bell_82j11e",
    "is-active": "ko-notification-center__is-active_82j11e",
    "unread-mark": "ko-notification-center__unread-mark_82j11e",
    "scale-up-dot": "ko-notification-center__scale-up-dot_82j11e",
    "header": "ko-notification-center__header_82j11e",
    "content": "ko-notification-center__content_82j11e",
    "title": "ko-notification-center__title_82j11e",
    "read-all-action": "ko-notification-center__read-all-action_82j11e",
    "activated": "ko-notification-center__activated_82j11e",
    "pop-icon": "ko-notification-center__pop-icon_82j11e",
    "list": "ko-notification-center__list_82j11e",
    "loader": "ko-notification-center__loader_82j11e",
    "load-more": "ko-notification-center__load-more_82j11e",
    "preferences-icon": "ko-notification-center__preferences-icon_82j11e"
  };
});