define("frontend-cp/components/ko-date-select/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "datepicker": "ko-date-select__datepicker_1ibfcu",
    "content": "ko-date-select__content_1ibfcu",
    "trigger": "ko-date-select__trigger_1ibfcu",
    "trigger--focused": "ko-date-select__trigger--focused_1ibfcu",
    "trigger-text": "ko-date-select__trigger-text_1ibfcu",
    "trigger-icon": "ko-date-select__trigger-icon_1ibfcu"
  };
});