define('frontend-cp/components/ko-admin/engagements/index/toggle/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({

    // Services
    store: service(),
    i18n: service(),
    notification: service(),
    engagement: null,
    tagName: '',

    // Tasks
    toggleStatus: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var engagement, engagementId, data;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              engagement = this.get('engagement');

              engagement.toggleProperty('isEnabled');
              engagementId = get(engagement, 'id');
              data = { 'is_enabled': get(engagement, 'isEnabled') };
              /**
               * CAUTION: Please don't change below logic to use ember-data's
               * save(). Current logic ensures that list reordering works the way
               * it works which is dependent on executionOrder of engagements
               * and is maintained on client.
               */

              _context.prev = 4;
              _context.next = 7;
              return this.get('store').adapterFor('engagement').updateStatus(engagementId, data);

            case 7:
              if (this.get('engagement.isEnabled')) {
                this.get('notification').success(this.get('i18n').t('admin.engagements.notifications.success.engagement_enabled'));
              } else {
                this.get('notification').success(this.get('i18n').t('admin.engagements.notifications.success.engagement_disabled'));
              }
              _context.next = 14;
              break;

            case 10:
              _context.prev = 10;
              _context.t0 = _context['catch'](4);

              engagement.toggleProperty('isEnabled');
              this.get('notification').success(this.get('i18n').t('admin.engagements.notifications.error.toggle_status'));

            case 14:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[4, 10]]);
    }))
  });
});