define('frontend-cp/adapters/article', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  function normalizeArticles(articles) {
    return articles.data.map(function (article) {
      // Extract the titles from the locale_field associated with titles
      var titles = article.titles.map(function (titleField) {
        var titleResource = articles.resources.locale_field[titleField.id];
        return {
          locale: titleResource.locale,
          translation: titleResource.translation
        };
      });

      // Extract the author name
      var creator = articles.resources.user_minimal[article.creator.id].full_name;

      // Extract the author
      var author = articles.resources.user_minimal[article.author.id];

      // Find the section for the article
      var section = articles.resources.section[article.section.id];

      // Find the category for the section
      var category = articles.resources.category[section.category.id];

      // Find the brand for the category
      var brand = articles.resources.brand[category.brand.id].name;

      // Format the lastModified date
      var lastModifiedDate = new Date(article.updated_at);
      var lastModified = lastModifiedDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }).replace(/ /g, '/').replace('Sept', 'Sep');

      return {
        id: article.id,
        titles: titles,
        brand: brand,
        createdBy: creator,
        author: author,
        status: article.status,
        lastModified: lastModified
      };
    });
  }

  function normalizeArticle(articleResponse) {
    var article = articleResponse.data;
    var resources = articleResponse.resources;

    // Extract all titles associated with the article
    var titles = article.titles.map(function (titleField) {
      var titleResource = resources.locale_field[titleField.id];
      return {
        locale: titleResource.locale,
        translation: titleResource.translation
      };
    });

    // Extract all contents associated with the article
    var contents = article.contents.map(function (contentField) {
      var contentResource = resources.locale_field[contentField.id];
      return {
        locale: contentResource.locale,
        translation: contentResource.translation
      };
    });

    // Extract the creator name
    var creator = resources.user_minimal[article.creator.id].full_name;

    // Extract the author
    var author = resources.user_minimal[article.author.id];

    // Find the section for the article
    var section = resources.section[article.section.id];

    // Find the category for the section
    var category = resources.category[section.category.id];

    // Find the brand for the category
    var brand = resources.brand[category.brand.id].name;

    // Format the lastModified date
    var lastModifiedDate = new Date(article.updated_at);
    var lastModified = lastModifiedDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).replace(/ /g, '/');

    // Extract attachment data
    var attachments = article.attachments.map(function (attachment) {
      var attachmentData = resources.attachment[attachment.id];
      return {
        id: attachmentData.id,
        name: attachmentData.name,
        url: attachmentData.url,
        url_download: attachmentData.url_download,
        size: attachmentData.size
      };
    });

    // Extract tags
    var tags = article.tags.map(function (tag) {
      var tagData = resources.tag[tag.id];
      return {
        id: tagData.id,
        name: tagData.name
      };
    });

    return {
      id: article.id,
      titles: titles,
      contents: contents,
      brand: brand,
      createdBy: creator,
      lastModified: lastModified,
      attachments: attachments,
      tags: tags,
      status: article.status,
      author: author,
      searchKeywords: article.keywords,
      pinArticle: article.is_featured,
      allowComments: article.allow_comments
    };
  }

  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'article';
    },
    fetchArticles: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(_ref) {
        var offset = _ref.offset,
            limit = _ref.limit,
            _ref$filters = _ref.filters,
            filters = _ref$filters === undefined ? [] : _ref$filters;
        var articles;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.ajax('/api/v1/articles/list', 'POST', {
                  data: {
                    filters: filters,
                    offset: offset,
                    limit: limit
                  }
                });

              case 2:
                articles = _context.sent;
                return _context.abrupt('return', {
                  'data': normalizeArticles(articles),
                  'totalCount': articles.total_count
                });

              case 4:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function fetchArticles(_x) {
        return _ref2.apply(this, arguments);
      }

      return fetchArticles;
    }(),
    fetchArticleById: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(articleId) {
        var article;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return this.ajax('/api/v1/articles/' + articleId, 'GET');

              case 2:
                article = _context2.sent;
                return _context2.abrupt('return', normalizeArticle(article));

              case 4:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function fetchArticleById(_x2) {
        return _ref3.apply(this, arguments);
      }

      return fetchArticleById;
    }(),
    addArticle: function () {
      var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(articleData) {
        var response;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return this.ajax('/api/v1/articles.json', 'POST', {
                  data: articleData
                });

              case 2:
                response = _context3.sent;
                return _context3.abrupt('return', normalizeArticle(response));

              case 4:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function addArticle(_x3) {
        return _ref4.apply(this, arguments);
      }

      return addArticle;
    }(),
    updateArticle: function () {
      var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(articleId, articleData) {
        var response;
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _context4.next = 2;
                return this.ajax('/api/v1/articles/' + articleId + '.json', 'PUT', {
                  data: articleData
                });

              case 2:
                response = _context4.sent;
                return _context4.abrupt('return', normalizeArticle(response));

              case 4:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function updateArticle(_x4, _x5) {
        return _ref5.apply(this, arguments);
      }

      return updateArticle;
    }(),
    deleteByIds: function () {
      var _ref6 = _asyncToGenerator(regeneratorRuntime.mark(function _callee5(articleIds) {
        return regeneratorRuntime.wrap(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                _context5.next = 2;
                return this.ajax('/api/v1/articles?ids=' + articleIds, 'DELETE', { data: { include: '' } });

              case 2:
              case 'end':
                return _context5.stop();
            }
          }
        }, _callee5, this);
      }));

      function deleteByIds(_x6) {
        return _ref6.apply(this, arguments);
      }

      return deleteByIds;
    }(),
    removeAttachment: function () {
      var _ref7 = _asyncToGenerator(regeneratorRuntime.mark(function _callee6(articleId, attachmentId) {
        var response;
        return regeneratorRuntime.wrap(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                _context6.next = 2;
                return this.ajax('/api/v1/articles/' + articleId + '/attachments/' + attachmentId + '.json', 'DELETE');

              case 2:
                response = _context6.sent;
                return _context6.abrupt('return', response);

              case 4:
              case 'end':
                return _context6.stop();
            }
          }
        }, _callee6, this);
      }));

      function removeAttachment(_x7, _x8) {
        return _ref7.apply(this, arguments);
      }

      return removeAttachment;
    }(),
    fetchArticleCounts: function () {
      var _ref8 = _asyncToGenerator(regeneratorRuntime.mark(function _callee7() {
        var response;
        return regeneratorRuntime.wrap(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                _context7.next = 2;
                return this.ajax('/api/v1/articles/count', 'GET');

              case 2:
                response = _context7.sent;
                return _context7.abrupt('return', {
                  allCount: response.data.all_count,
                  draftCount: response.data.draft_count,
                  myPublishedCount: response.data.my_published_count
                });

              case 4:
              case 'end':
                return _context7.stop();
            }
          }
        }, _callee7, this);
      }));

      function fetchArticleCounts() {
        return _ref8.apply(this, arguments);
      }

      return fetchArticleCounts;
    }()
  });
});