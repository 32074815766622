define('frontend-cp/utils/tweet-length', ['exports', 'npm:twitter-text', 'npm:he'], function (exports, _npmTwitterText, _npmHe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = tweetLength;


  var TWEET_IMAGE_CHARS = 24;

  function tweetLength(content, hasFiles) {
    var tweetContent = content.replace(/<a.+>(.+)<\/a>/g, '$1').replace(/<br>/g, '\n');
    tweetContent = _npmHe.default.unescape(tweetContent);
    var tweetLength = _npmTwitterText.default.getTweetLength(tweetContent.trim());
    if (hasFiles) {
      tweetLength += TWEET_IMAGE_CHARS;
    }
    return tweetLength;
  }
});