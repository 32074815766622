define('frontend-cp/session/agent/search/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route'], function (exports, _tabbedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  exports.default = _tabbedRoute.default.extend({

    // Services
    router: service('-routing'),
    processManager: service(),

    // State
    transitionTabPath: null,

    queryParams: {
      group: { refreshModel: true },
      page: { refreshModel: true }
    },

    model: function model(_ref, transition) {
      var term = _ref.term,
          group = _ref.group,
          page = _ref.page;

      var currentHandlerInfos = transition.router.currentHandlerInfos;
      var currentRouteName = currentHandlerInfos[currentHandlerInfos.length - 1].name;
      var shouldFocus = currentRouteName !== this.get('routeName');

      var tabStore = this.get('tabStore');
      var state = tabStore.decomposeTransition(transition);

      var searchController = this.controllerFor('session.agent.search');

      if (this.get('transitionTabPath') && !tabStore.getTab(state.basePath)) {
        var tab = tabStore.getTab(this.get('transitionTabPath'));
        tabStore.update(tab, state);
        tabStore.set('activeTab', tab);
        this.tab = tab;
      } else {
        if (searchController.get('tabIndex')) {
          tabStore.set('insertTabAtIndex', searchController.get('tabIndex'));
        }
        this.tab = tabStore.open(transition, term);
        tabStore.set('insertTabAtIndex', null);
        searchController.set('tabIndex', null);
      }

      this.set('transitionTabPath', null);

      return { term: term, shouldFocus: shouldFocus };
    },
    afterModel: function afterModel(_ref2, transition) {
      var term = _ref2.term;

      var model = EmberObject.create({ id: term });
      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(model, 'search-results');
      processManager.setForegroundProcess(process);

      var tabStore = this.get('tabStore');

      if (this.tab.process && this.tab.process !== process) {
        processManager.destroyProcess(this.tab.process);
      }

      tabStore.update(this.tab, { process: process });
    },
    setupController: function setupController(controller, _ref3) {
      var term = _ref3.term,
          shouldFocus = _ref3.shouldFocus;

      controller.set('searchTerm', term);
      controller.get('search').perform();
      if (shouldFocus) {
        this.controller.focusInput();
      }
    },


    actions: {
      willTransition: function willTransition(transition) {
        var processManager = this.get('processManager');
        processManager.setForegroundProcess(null);

        var tabStore = this.get('tabStore');
        var activeTab = tabStore.get('activeTab');
        var openInANewTab = this.controller.get('openInANewTab');
        this.controller.get('search').cancelAll();

        // Record the current tab basePath so we can update the correct tab in `afterModel`
        if (activeTab && !openInANewTab && transition.targetName === 'session.agent.search') {
          this.set('transitionTabPath', activeTab.basePath);
        } else {
          this.controller.set('openInANewTab', false);
          this.set('transitionTabPath', null);
        }
      },
      didTransition: function didTransition() {
        var searchController = this.controllerFor('session.agent.search');

        var tabStore = this.get('tabStore');
        var activeTab = tabStore.get('activeTab');
        var transition = searchController.get('transition');
        var tab = void 0;

        if (transition) {
          var handlerInfos = transition.router.oldState.handlerInfos;
          var oldRouteHash = handlerInfos[handlerInfos.length - 1].params.hash;

          tab = tabStore.getTab('/agent/search-new/' + oldRouteHash);
        }

        var isDirty = this.controllerFor('session.agent.search').get('isDirty');
        var routeName = this.get('router').currentRouteName;

        if (tab && activeTab.routeName !== routeName && isDirty) {
          tabStore.close(tab);
          this.controllerFor('session.agent.search').set('isDirty', false);
          searchController.set('transition', null);
        }
        return true;
      }
    }
  });
});