define('frontend-cp/components/ember-truncate/truncation-noop', ['exports', 'ember-truncate/components/ember-truncate/truncation-noop'], function (exports, _truncationNoop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _truncationNoop.default;
    }
  });
});