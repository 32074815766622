define('frontend-cp/utils/presence', ['exports', 'phoenix'], function (exports, _phoenix) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.list = list;
  var assign = Ember.assign;


  // Ember adds __ember_meta__ to objects which interferes with Phoenix.Presence.list
  // so we sanitize it here before calling through

  function list(presence, cb) {
    var sanitized = assign({}, presence);
    Reflect.deleteProperty(sanitized, '__ember_meta__');
    return _phoenix.Presence.list(sanitized, cb);
  }
});