define('frontend-cp/components/ko-select/multiple/pill/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    all: null,
    select: null,
    option: null,
    searchField: null,
    removable: true,

    // HTML
    tagName: '',

    actions: {
      remove: function remove(object, e) {
        e.stopPropagation();
        e.preventDefault();
        var select = this.get('select');
        select.actions.choose(object);
      }
    }
  });
});