define("frontend-cp/components/ko-user-list/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-user-list__container_qaq3pz",
    "title-loader": "ko-user-list__title-loader_qaq3pz",
    "title": "ko-user-list__title_qaq3pz",
    "heading": "ko-user-list__heading_qaq3pz",
    "sidebar": "ko-user-list__sidebar_qaq3pz",
    "results": "ko-user-list__results_qaq3pz",
    "not-found": "ko-user-list__not-found_qaq3pz",
    "not-found-image": "ko-user-list__not-found-image_qaq3pz",
    "not-found-message": "ko-user-list__not-found-message_qaq3pz",
    "sidebar-detail": "ko-user-list__sidebar-detail_qaq3pz"
  };
});