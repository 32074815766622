define('frontend-cp/components/ko-text-editor/secondary-controls/component', ['exports', 'frontend-cp/components/ko-text-editor/secondary-controls/styles'], function (exports, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',
    isInsideDropdown: false,
    isButtonOutdentDisabled: false,
    onTriggerControl: function onTriggerControl() {},

    itemClass: computed('isInsideDropdown', function () {
      if (this.get('isInsideDropdown')) {
        return _styles.default.buttonsDropdownItem;
      }
      return _styles.default.item + ' ' + _styles.default.itemWrap + ' ' + _styles.default.itemSecondary;
    })
  });
});