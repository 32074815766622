define('frontend-cp/components/ko-universal-search/result/user/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    session: service(),

    result: null,

    // Lifecycle Hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('identities', this.buildIdentities());
    },


    role: computed.readOnly('result.resultData.role'),
    avatar: computed.readOnly('result.resultData.avatar'),
    organization: computed.readOnly('result.resultData.organization'),
    email: computed.readOnly('result.resultData.primaryEmailAddress'),
    twitter: computed.readOnly('result.resultData.twitter'),
    phones: computed.readOnly('result.resultData.phones'),
    facebook: computed.readOnly('result.resultData.facebook'),
    isMe: computed('result.resultData.id', 'session.user.id', function () {
      return this.get('result.resultData.id') === this.get('session.user.id');
    }),

    twitterScreenName: computed('twitter', function () {
      var identity = this.get('twitter.length') && this.get('twitter').find(function (identity) {
        return identity.get('isPrimary');
      });

      if (!identity) {
        return null;
      }

      return '@' + identity.get('screenName');
    }),

    facebookFullName: computed('facebook', function () {
      var identity = this.get('facebook.length') && this.get('facebook').find(function (identity) {
        return identity.get('isPrimary');
      });

      if (!identity) {
        return null;
      }

      return identity.get('fullName');
    }),

    phone: computed('phones', function () {
      var identity = this.get('phones.length') && this.get('phones').find(function (identity) {
        return identity.get('isPrimary');
      });
      if (!identity) {
        return null;
      }

      return identity.get('number');
    }),

    buildIdentities: function buildIdentities() {
      var identities = [];

      if (this.get('email')) {
        identities.push({ type: 'email', value: this.get('email') });
      }

      if (this.get('twitterScreenName')) {
        identities.push({ type: 'twitter', value: this.get('twitterScreenName') });
      }

      if (this.get('facebookFullName')) {
        identities.push({ type: 'facebook', value: this.get('facebookFullName') });
      }

      if (this.get('phone')) {
        identities.push({ type: 'phone', value: this.get('phone') });
      }

      return identities;
    }
  });
});