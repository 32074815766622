define('frontend-cp/components/ko-admin/messenger/customize/component', ['exports', 'ember-concurrency', 'ember-diff-attrs', 'npm:lodash', 'frontend-cp/components/ko-admin/messenger/customize/phraseList'], function (exports, _emberConcurrency, _emberDiffAttrs, _npmLodash, _phraseList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    i18n: service(),

    backgroundMode: 'gradient',
    primaryColor: '#F1703F',
    activeSolidColor: '#FF3B30',
    activeGradient: '-192deg, #40364D 37%, #9B4779 100%',
    activePattern: '1',
    existingStyle: '',
    averageReplyTime: null,

    gradients: ['-192deg, #40364D 37%, #9B4779 100%', '-134deg, #CE287E 0%, #FF3B30 100%', '-134deg, #F34E8B 0%, #FFEA01 100%', '-134deg, #FFCC00 0%, #53DB91 100%', '-134deg, #23A975 0%, #BAD33B 100%', '-134deg, #0DDFA9 0%, #5AC8FA 100%', '-134deg, #581F7E 0%, #5195F8 100%', '-180deg, #62A8FD 0%, #9E80F3 100%', '-180deg, #77EFD8 0%, #45BAF2 100%', '-180deg, #F9BF66 0%, #F95C81 100%', '-180deg, #B6B7F8 0%, #F5ACCC 100%', '-180deg, #FFE35A 0%, #FFB064 100%', '-180deg, #F387BC 0%, #A26FED 100%', '-180deg, #E7EE9D 0%, #5BF2B9 100%', '0deg, #5F6BE4 0%, #73BDEB 100%', '-180deg, #FD9786 0%, #F567DC 100%', '0deg, #889CFF 0%, #9BE1D3 100%', '-180deg, #B7F2EB 0%, #8ADDEB 100%', '-180deg, #86CFEC 0%, #789AEC 100%', '-180deg, #E5EBA4 0%, #A7DA7C 100%', '-180deg, #A2ACF1 0%, #B482D3 100%', '0deg, #F25555 0%, #FB72AE 100%'],
    solidColors: ['#FF3B30', '#FF9500', '#FFCC00', '#4CD964', '#5AC8FA', '#007AFF', '#5856D6'],
    patterns: [false, '1', '2', '3', '4', '5', '6', '7', '8', '9'],

    didReceiveAttrs: (0, _emberDiffAttrs.default)('brand', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);
      if (!changedAttrs || changedAttrs && changedAttrs.brand) {
        this.get('fetchAverageReplyTime').perform();
      }
    }),

    fetchAverageReplyTime: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(id) {
      var brand, response;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              brand = this.get('brand');

              if (brand) {
                _context.next = 3;
                break;
              }

              return _context.abrupt('return');

            case 3:
              _context.next = 5;
              return this.get('store').adapterFor('conversation-starter').fetchForBrand(brand.get('id'));

            case 5:
              response = _context.sent;

              this.set('averageReplyTime', response.data.average_reply_time);
              return _context.abrupt('return', response);

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    currentResponseTimeExpectation: computed('averageReplyTime', function () {
      return this.getPhrase(this.get('averageReplyTime'));
    }),

    getPhrase: function getPhrase(replyTime) {
      var phrase = 'admin.messenger.tabs.customize.response_time.options.reply_asap';
      if (replyTime !== null) {
        var matchingPhrase = _npmLodash.default.find(_phraseList.default, function (ph) {
          return ph.times[0] <= replyTime && ph.times[1] >= replyTime;
        });

        if (matchingPhrase) {
          phrase = 'admin.messenger.tabs.customize.response_time.options.' + matchingPhrase.sentence().locale;
        }
      }
      return this.get('i18n').t(phrase);
    },


    actions: {
      switchBackgroundStyle: function switchBackgroundStyle(mode) {
        if (mode === 'color') {
          this.set('backgroundMode', 'color');
        } else {
          this.set('backgroundMode', 'gradient');
        }
      },
      updateColor: function updateColor(color) {
        this.set('activeSolidColor', color);
      },
      updateGradient: function updateGradient(gradient) {
        this.set('activeGradient', gradient);
      },
      updatePattern: function updatePattern(pattern) {
        this.set('activePattern', pattern);
      },
      primaryColorChanged: function primaryColorChanged(color) {
        if (/^#\w{3}$|^#\w{6}$/.test(color)) {
          this.set('primaryColor', color);
        }
      }
    }
  });
});