define('frontend-cp/components/ko-pinned-notes/dropdown/component', ['exports', 'ember-concurrency', 'frontend-cp/components/ko-pinned-notes/dropdown/styles', 'frontend-cp/components/ko-agent-content/layout/styles', 'frontend-cp/components/ko-timeline-2/list/post/styles', 'frontend-cp/components/ko-journey-timeline/styles', 'frontend-cp/lib/scroll-to'], function (exports, _emberConcurrency, _styles, _styles2, _styles3, _styles4, _scrollTo2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var jQuery = Ember.$;
  var RSVP = Ember.RSVP;
  var scheduleOnce = Ember.run.scheduleOnce;


  var JUMP_BLIP_DELAY = 200;
  var JUMP_BLIP_DURATION = 300;

  exports.default = Component.extend({
    tagName: '',

    // Attributes
    notes: [],

    // Service
    routing: service('-routing'),
    store: service(),
    tabStore: service(),
    advancedSearch: service(),
    i18n: service(),

    // Methods
    pinTooltipText: function pinTooltipText(note) {
      var i18n = this.get('i18n');
      var parentType = this.getNoteParentType(note);
      switch (parentType) {
        case 'users':
          return i18n.t('generic.unpin.user');
        case 'organizations':
          return i18n.t('generic.unpin.org');
        case 'cases':
          return i18n.t('generic.unpin.case');
      }
    },
    getNoteParentType: function getNoteParentType(note) {
      return note.get('resourceUrl').match(/([a-z]+)\/[0-9]+\/[a-z]+\/[0-9]+$/)[1];
    },
    getNoteParentId: function getNoteParentId(note) {
      return note.get('resourceUrl').match(/[a-z]+\/([0-9]+)\/[a-z]+\/[0-9]+$/)[1];
    },
    getNoteParentIcon: function getNoteParentIcon(note) {
      var parentType = this.getNoteParentType(note);
      var parentId = this.getNoteParentId(note);
      switch (parentType) {
        case 'users':
          return this.get('store').peekRecord('user', parentId);
        case 'organizations':
          return this.get('store').peekRecord('organization', parentId);
        case 'cases':
          return 'icon--conversation';
      }
    },


    // Tasks
    _blipPost: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(element, styles) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!(element && element.length)) {
                _context.next = 7;
                break;
              }

              _context.next = 3;
              return (0, _emberConcurrency.timeout)(JUMP_BLIP_DELAY);

            case 3:
              element.addClass(styles.located);
              _context.next = 6;
              return (0, _emberConcurrency.timeout)(JUMP_BLIP_DURATION);

            case 6:
              element.removeClass(styles.located);

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    unpinNote: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(note, event) {
      var entityName, modelName, entityId, entity;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              $(event.currentTarget).addClass(_styles.default.processing);

              entityName = this.getNoteParentType(note);
              modelName = entityName.slice(0, -1);
              entityId = this.getNoteParentId(note);
              entity = void 0;
              _context2.prev = 5;

              note.setProperties({ isPinned: false, pinnedBy: null });
              _context2.next = 9;
              return note.save({ adapterOptions: { entityName: entityName, entityId: entityId } });

            case 9:

              entity = this.get('store').peekRecord(modelName, entityId);
              entity.decrementProperty('pinnedNotesCount');
              _context2.next = 16;
              break;

            case 13:
              _context2.prev = 13;
              _context2.t0 = _context2['catch'](5);

              if (!Ember.testing && window.Bugsnag) {
                window.Bugsnag.notifyException(_context2.t0, 'Failed to unpin note', {}, 'info');
              }

            case 16:
              _context2.prev = 16;

              if (event.currentTarget) {
                $(event.currentTarget).removeClass(_styles.default.processing);
              }
              return _context2.finish(16);

            case 19:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this, [[5, 13, 16, 19]]);
    })).drop(),

    scrollTo: function scrollTo() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$position = _ref.position,
          position = _ref$position === undefined ? _scrollTo2.MIDDLE : _ref$position,
          _ref$animated = _ref.animated,
          animated = _ref$animated === undefined ? true : _ref$animated,
          post = _ref.post;

      var scrollParent = jQuery('.' + _styles2.default.timeline);
      return new RSVP.Promise(function (resolve, reject) {
        scheduleOnce('afterRender', function () {
          var timeline = scrollParent;
          if (!timeline.length) {
            return reject(new Error('No timeline'));
          }

          var timelineItem = void 0;
          if (post) {
            timelineItem = post;
          }

          (0, _scrollTo2.scrollTo)({
            parent: timeline,
            element: timelineItem,
            position: position,
            animated: animated
          }).then(resolve).catch(reject);
        });
      });
    },


    actions: {
      openPinnedNote: function openPinnedNote(note, event) {
        var _this = this;

        /**
         * @DOCUMENTATION for `openPinnedNote`
         * written on 8 July, 2017, 4:30AM IST.
         *
         * @TERMINOLOGY
         * 'new tab' refers to app (Kayako) tabs, and not browser tabs.
         * 'journey note' refers to User or Org notes.
         * 'blip' refers to the background highlight surrounding a note that lasts a few hundred ms.
         *
         * @PARAMETERS:
         * @note: The note model object.
         * @dropdown: The ember-basic-dropdown component object.
         * @event: Javascript window event object.
         *
         * @LOGIC:
         * There are two modes of operation.
         * 1. Opening in the current tab.
         * 2. Opening in a new tab (in background).
         *
         * @VARIABLES
         * @hasModifier determines whether a meta key was pressed. If yes, the note is opened in the background (new tab).
         * @entityName is the name of the resource that the note belongs to. (Typically it's the model name in plural)
         * @modelName is the name of the model that the note belongs to. If the note was created on a user view, this value would be set to 'user'.
         * @routeName is the route to which we need to transition, if needed. This is computed based on other variables.
         * @parentRouteName is the route name of the parent of the current route the app is in. If the current route is 'session.agent.cases.case.user', the `parentRouteName` would be 'session.agent.cases.case'.
         * @postElement is the HTML element containing the note. The post element is the activity wrapper in journeys, and posts wrapper in cases.
         * @subRoute is the last segment in the route name that we want to transition to. This is one of the variables used to compute `routeName`. Its value is determined from `modelName`, or is set to 'index' if the value of `modelName` is the same as `currentParentName`.
         * @targetModelName is the name of the model we want to go to. If we are opening a note in the current tab, then `targetModelName` would be the same as the `currentParentName`. If we are opening a note in a new tab, then `targetModelName` would be the same as `modelName`.
         * @styles is the styles hash referring to timelineItemStyles or journeyTimelineItemStyles, depending on whether the current route model is a case model or not.
         * @blipElement is the HTML element that would have the blip around it.
         * @targetModel is the model object for the resource that the note belongs to. Its `resourceType` is the same as `targetModelName`, as that name is the model name of this model.
         * @modelId is the ID of the model of the current route. If we're on any of the case routes, like 'session.agent.users.user.organization', the `modelId` would have the ID for the user.
         * @currentParentName is the model name of the model for the current route that has the same ID as `modelId`.
         * @timestamp is the UNIX timestamp in seconds (moment-format 'X') for the note that we are looking for. Obtained from `note.createdAt`.
         * @currentParentModel is the model object for the model referred to by `modelId` and `currentParentName`.
         * @queryParams stores either the {postId} or {timestamp} depending on whether we're jumping to a post note, or a journey note.
         */
        var hasModifier = event.metaKey || event.ctrlKey || event.shiftKey;

        var entityName = this.getNoteParentType(note);
        var modelName = entityName.slice(0, -1);
        var routeName = void 0,
            parentRouteName = void 0,
            postElement = void 0,
            subRoute = void 0,
            targetModelName = void 0,
            targetModel = void 0,
            styles = void 0,
            blipElement = void 0;
        var modelId = this.get('tabStore.activeTab.dynamicSegments.firstObject');
        var currentParentName = this.get('routing.currentRouteName').match(/([a-z]+)\.[a-z]+$/i)[1];
        var timestamp = new Date(note.get('createdAt')).getTime() / 1000;
        var currentParentModel = this.get('store').peekRecord(currentParentName, modelId);

        var queryParams = {};

        if (modelName === 'case') {
          queryParams['noteId'] = note.get('id');
          postElement = jQuery('.' + _styles3.default.post + '[data-note-id="' + note.get('id') + '"]');
          blipElement = postElement.children('div');
          styles = _styles3.default;
        } else {
          queryParams.timestamp = timestamp;
          postElement = jQuery('.' + _styles4.default.item + '[data-created-at="' + timestamp + '"]');
          blipElement = postElement;
          styles = _styles4.default;
        }

        subRoute = modelName;
        targetModelName = currentParentName;
        if (modelName === currentParentName) {
          subRoute = 'index';
        } else if (hasModifier && modelName !== currentParentName) {
          subRoute = 'index';
          targetModelName = modelName;
        }

        switch (targetModelName) {
          case 'case':
            parentRouteName = 'session.agent.cases.case';break;
          case 'organization':
            parentRouteName = 'session.agent.organizations.organization';break;
          case 'user':
            parentRouteName = 'session.agent.users.user';break;
        }
        routeName = parentRouteName + '.' + subRoute;

        if (hasModifier && modelName !== currentParentName) {
          switch (modelName) {
            case 'organization':
              switch (currentParentName) {
                case 'case':
                  targetModel = currentParentModel.get('requester.organization.content');break;
                case 'user':
                  targetModel = currentParentModel.get('organization.content');break;
              }break;
            case 'user':
              switch (currentParentName) {
                case 'case':
                  targetModel = currentParentModel.get('requester.content');break;
              }break;
          }

          this.get('tabStore').createTabNextToActiveTab(routeName, targetModel, queryParams);
        } else {
          if (this.get('routing.currentRouteName') === routeName && postElement.length) {
            this.scrollTo({ post: postElement }).then(function () {
              _this.get('_blipPost').perform(blipElement, styles);
            });
          } else {
            this.get('routing').transitionTo(routeName, [modelId], queryParams);
          }
        }
      },
      unpinNote: function unpinNote(note, event) {
        event.preventDefault();
        event.stopPropagation();
        this.get('unpinNote').perform(note, event);
      }
    }
  });
});