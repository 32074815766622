define('frontend-cp/components/ko-admin/messenger-persistent/preferences/phraseList', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Phrases and the date time logic to be used
   * for displaying the team reply under xxx
   * minutes.
   */
  var phraseList = [{
    times: [-1, 5],
    sentence: function sentence() {
      return { locale: 'reply_in_few_minutes', data: {} };
    }
  }, {
    times: [6, 15],
    sentence: function sentence() {
      return { locale: 'reply_in_under_minutes', data: { minutes: 10 } };
    }
  }, {
    times: [16, 25],
    sentence: function sentence() {
      return { locale: 'reply_in_under_minutes', data: { minutes: 20 } };
    }
  }, {
    times: [26, 40],
    sentence: function sentence() {
      return { locale: 'reply_in_under_minutes', data: { minutes: 30 } };
    }
  }, {
    times: [41, 80],
    sentence: function sentence() {
      return { locale: 'reply_in_under_hour', data: {} };
    }
  }, {
    times: [81, 240],
    sentence: function sentence() {
      return { locale: 'reply_in_few_hours', data: {} };
    }
  }, {
    times: [241, 1800],
    sentence: function sentence() {
      return { locale: 'reply_in_a_day', data: {} };
    }
  }, {
    times: [1801, Infinity],
    sentence: function sentence() {
      return { locale: 'reply_asap', data: {} };
    }
  }];

  exports.default = phraseList;
});