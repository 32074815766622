define('frontend-cp/lib/column-mappings/map-columns-to-fields-and-includes', ['exports', 'frontend-cp/lib/column-mappings/mappings'], function (exports, _mappings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var columnNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    var _mapColumnNamesToFiel = _mapColumnNamesToFieldsAndIncludes(columnNames),
        fields = _mapColumnNamesToFiel.fields,
        includes = _mapColumnNamesToFiel.includes;

    var fieldsString = _flattenAndStringifyFields(fields);
    var includesString = includes.join(',');

    return { fields: fieldsString, includes: includesString };
  };

  function _stringify(obj) {
    return Object.keys(obj).map(function (key) {
      if (Object.keys(obj[key]).length) {
        return key + '(' + _stringify(obj[key]) + ')';
      }

      return key;
    }).join(',');
  }

  function _mapColumnNamesToFieldsAndIncludes(columnNames) {
    var _columnNames$reduce = columnNames.reduce(function (obj, columnName) {
      if (columnName.startsWith('case_field_')) {
        obj.fields = obj.fields.concat(['custom_fields']);
      } else {
        var mapping = _mappings.default[columnName];

        if (mapping) {
          obj.fields = obj.fields.concat(mapping.fields || []);
          obj.includes = obj.includes.concat(mapping.includes || []);
        }
      }

      return obj;
    }, { fields: [], includes: [] }),
        fields = _columnNames$reduce.fields,
        includes = _columnNames$reduce.includes;

    return {
      fields: fields.uniq(),
      includes: includes.uniq()
    };
  }

  function _flattenAndStringifyFields(fields) {
    var flattenedFields = {};

    fields.forEach(function (field) {
      var parts = field.split('.');

      parts.reduce(function (flattened, current) {
        if (!flattened[current]) {
          flattened[current] = {};
        }

        return flattened[current];
      }, flattenedFields);
    });

    return _stringify(flattenedFields);
  }
});