define('frontend-cp/models/view-count', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var equal = Ember.computed.equal;
  exports.default = _model.default.extend({
    count: (0, _attr.default)('number'),
    countAccuracy: (0, _attr.default)('string'),
    realtimeChannel: (0, _attr.default)('string'),
    view: (0, _relationships.belongsTo)('view'),

    // CPs
    hasRelativeAccuracy: equal('countAccuracy', 'RELATIVE'),
    hasKnownAccuracy: computed('countAccuracy', function () {
      return this.get('countAccuracy') !== 'UNKNOWN';
    })
  });
});