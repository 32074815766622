define('frontend-cp/lib/facade/operation-as-post', ['exports', 'frontend-cp/state-managers/case', 'frontend-cp/models/post'], function (exports, _case, _post) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var readOnly = Ember.computed.readOnly;
  exports.default = EmberObject.extend({
    key: 'root',
    operation: null,

    id: null,
    isNew: true,
    createdAt: readOnly('operation.createdAt'),
    destinationMedium: computed('operation.meta.shouldDeliverViaMessenger', 'operation.attrs.channelType', function () {
      var channelType = this.get('operation.attrs.channelType');
      var shouldDeliverViaMessenger = this.get('operation.meta.shouldDeliverViaMessenger');

      if (channelType !== 'MAIL') {
        return null;
      }

      if (shouldDeliverViaMessenger) {
        return 'MESSENGER';
      }

      return 'MAIL';
    }),
    metadata: null,
    postStatus: computed('operation.state', function () {
      switch (this.get('operation.state')) {
        case _case.OP_STATE_WAITING:
          return _post.POST_STATUS_CLIENT_WAITING;
        case _case.OP_STATE_SENDING:
          return _post.POST_STATUS_CLIENT_SENDING;
        case _case.OP_STATE_FAILED:
          return _post.POST_STATUS_CLIENT_FAILED;
      }
    }),
    postRateLimited: computed('operation.error', function () {
      if (this.get('operation.error.errors.' + 0 + '.code') === 'RATE_LIMIT_REACHED') {
        return true;
      }

      return false;
    }),
    postStatusUpdatedAt: readOnly('operation.createdAt'),
    attachments: computed(function () {
      var attachmentFileIds = this.get('operation.attrs.attachmentFileIds') || [];
      var attachments = this.get('operation.attrs.attachments');
      return attachmentFileIds.map(function (id) {
        return EmberObject.create({
          id: id,
          size: attachments.findBy('attachmentId', id).get('size'),
          name: attachments.findBy('attachmentId', id).get('name'),
          thumbnails: []
        });
      });
    }),

    contents: readOnly('operation.attrs.contents'),

    original: computed(function () {
      return EmberObject.create({
        isActivity: false,
        activity: null,
        actions: null,
        postType: postTypeFor(this.get('operation.attrs.channelType')),
        apiEvent: null,
        isLegacyPostType: false,
        isPinned: null,
        note: null,
        bodyHtml: this.get('operation.attrs.contents'),
        recipients: [],
        email: null
      });
    }),

    sourceChannel: computed(function () {
      return EmberObject.create({
        channelType: this.get('operation.attrs.channelType')
      });
    })
  });


  function postTypeFor(channelType) {
    switch (channelType) {
      case 'MAIL':
        return 'message';
      case 'TWITTER':
        return 'twitterTweet';
      case 'FACEBOOK':
        return 'facebookMessage';
      case 'NOTE':
        return 'note';
    }
  }
});