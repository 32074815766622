define('frontend-cp/helpers/ko-intl-key-for-field-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var helper = Ember.Helper.helper;
  exports.default = helper(function (fieldType) {
    if (fieldType.length) {
      return 'admin.casefields.type.' + fieldType[0].toLowerCase() + '.name';
    }
  });
});