define('frontend-cp/components/ko-admin/automation-actions-builder/cascading-select/component', ['exports', 'frontend-cp/components/ko-admin/automation-actions-builder/base/component', 'frontend-cp/components/ko-select/drill-down/component'], function (exports, _component, _component2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = _component.default.extend({
    i18n: service(),
    definition: null,
    automationAction: null,

    valuePlaceholder: computed('definition.name', function () {
      var name = this.get('definition.name');
      var i18n = this.get('i18n');
      var translationKey = 'admin.automation_actions_builder.placeholders.' + name;
      if (i18n.exists(translationKey)) {
        return i18n.t(translationKey);
      } else {
        return i18n.t('admin.automation_actions_builder.placeholders.default');
      }
    }),

    tree: computed('definition.values', function () {
      var items = this.get('definition.values').filter(function (option) {
        return option && get(option, 'value');
      });

      return (0, _component2.buildTreeFromList)(items, function (item) {
        return {
          id: get(item, 'id'),
          value: get(item, 'value')
        };
      });
    }),

    actions: {
      changeValue: function changeValue(value) {
        this.set('automationAction.value', get(value, 'id'));
      }
    }
  });
});