define("frontend-cp/components/ko-insights/section/sla-overview/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "header": "ko-insights_section_sla-overview__header_1l58i9",
    "title": "ko-insights_section_sla-overview__title_1l58i9",
    "entity": "ko-insights_section_sla-overview__entity_1l58i9",
    "filters": "ko-insights_section_sla-overview__filters_1l58i9",
    "filtersControls": "ko-insights_section_sla-overview__filtersControls_1l58i9",
    "intervalToggle": "ko-insights_section_sla-overview__intervalToggle_1l58i9",
    "charts": "ko-insights_section_sla-overview__charts_1l58i9"
  };
});