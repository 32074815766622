define('frontend-cp/services/case-bulk-update', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  var Service = Ember.Service;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({
    launchDarkly: (0, _inject.default)(),
    trashCases: function trashCases(caseIds) {
      var adapter = getOwner(this).lookup('adapter:application');
      var adapterNamespace = adapter.get('namespace');
      var url = adapterNamespace + '/cases/trash?ids=' + caseIds.toString();

      return adapter.ajax(url, 'PUT', { data: { state: 'TRASH' } });
    },
    updateCases: function updateCases(caseIds, options) {
      var launchDarkly = this.get('launchDarkly');

      var adapter = getOwner(this).lookup('adapter:application');
      var adapterNamespace = adapter.get('namespace');
      var url = adapterNamespace + '/cases?ids=' + caseIds.toString();
      var payload = {};

      if (!Object.keys(options) || !Object.keys(options).length) {
        return new EmberPromise(function (resolve) {
          return resolve();
        });
      } else {
        if (typeof options.assignedTeam !== 'undefined') {
          payload.assigned_team_id = options.assignedTeam.id ? options.assignedTeam.id : null;
          payload.assigned_agent_id = options.assignedAgent ? options.assignedAgent.id : null;
        }

        if (typeof options.caseStatus !== 'undefined') {
          payload.status_id = options.caseStatus.id;
        }

        if (typeof options.casePriority !== 'undefined') {
          if (options.casePriority) {
            payload.priority_id = options.casePriority.id;
          } else {
            payload.priority_id = options.casePriority;
          }
        }
        if (typeof options.caseType !== 'undefined') {
          if (options.caseType) {
            payload.type_id = options.caseType.id;
          } else {
            payload.type_id = options.caseType;
          }
        }
        if (typeof options.tags !== 'undefined' && options.tags.length > 0) {
          payload.tags = options.tags.map(function (tag) {
            return tag.name;
          }).toString();
        }

        if (launchDarkly.get('release-sidebar-custom-fields')) {
          if (options.fieldValues) {
            payload.field_values = options.fieldValues;
          }
        }

        return adapter.ajax(url, 'PUT', { data: payload });
      }
    }
  });
});