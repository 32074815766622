define("frontend-cp/session/admin/apps/zapier/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "header": "session_admin_apps_zapier__header_1dcq2z",
    "contentBlock": "session_admin_apps_zapier__contentBlock_1dcq2z",
    "title": "session_admin_apps_zapier__title_1dcq2z",
    "subtitle": "session_admin_apps_zapier__subtitle_1dcq2z",
    "content": "session_admin_apps_zapier__content_1dcq2z",
    "messagesBlock": "session_admin_apps_zapier__messagesBlock_1dcq2z",
    "imageBlock": "session_admin_apps_zapier__imageBlock_1dcq2z",
    "definitionList": "session_admin_apps_zapier__definitionList_1dcq2z",
    "definitionTitle": "session_admin_apps_zapier__definitionTitle_1dcq2z",
    "definitionText": "session_admin_apps_zapier__definitionText_1dcq2z",
    "findOutCTA": "session_admin_apps_zapier__findOutCTA_1dcq2z"
  };
});