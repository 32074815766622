define("frontend-cp/components/ko-admin/messenger-persistent/preferences/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "label": "ko-admin_messenger-persistent_preferences__label_1r4fzh",
    "localized-field": "ko-admin_messenger-persistent_preferences__localized-field_1r4fzh",
    "reply-time-locale": "ko-admin_messenger-persistent_preferences__reply-time-locale_1r4fzh",
    "radio-container": "ko-admin_messenger-persistent_preferences__radio-container_1r4fzh",
    "radio": "ko-admin_messenger-persistent_preferences__radio_1r4fzh",
    "text": "ko-admin_messenger-persistent_preferences__text_1r4fzh",
    "current-expectation": "ko-admin_messenger-persistent_preferences__current-expectation_1r4fzh",
    "tooltip-container": "ko-admin_messenger-persistent_preferences__tooltip-container_1r4fzh",
    "text--small": "ko-admin_messenger-persistent_preferences__text--small_1r4fzh",
    "business-hours": "ko-admin_messenger-persistent_preferences__business-hours_1r4fzh",
    "select-field": "ko-admin_messenger-persistent_preferences__select-field_1r4fzh",
    "suggestions-settings-container": "ko-admin_messenger-persistent_preferences__suggestions-settings-container_1r4fzh",
    "form-field": "ko-admin_messenger-persistent_preferences__form-field_1r4fzh",
    "disabled": "ko-admin_messenger-persistent_preferences__disabled_1r4fzh",
    "checkbox": "ko-admin_messenger-persistent_preferences__checkbox_1r4fzh"
  };
});