define('frontend-cp/session/admin/customizations/localization/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var filterBy = Ember.computed.filterBy;
  var sort = Ember.computed.sort;
  var computed = Ember.computed;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    // Services
    i18n: service(),
    notification: service(),

    // CPs
    languagesSorting: ['name:asc'],
    enabledLanguages: filterBy('model', 'isPublic', true),
    disabledLanguages: filterBy('model', 'isPublic', false),
    enabledLanguagesSorted: sort('enabledLanguages', 'languagesSorting'),
    disabledLanguagesSorted: sort('disabledLanguages', 'languagesSorting'),

    tabs: computed(function () {
      return [{
        id: 'case',
        label: this.get('i18n').t('admin.localization.tabs.languages'),
        routeName: 'session.admin.customizations.localization.index',
        dynamicSegments: [],
        queryParams: null
      }, {
        id: 'case',
        label: this.get('i18n').t('admin.localization.tabs.settings'),
        routeName: 'session.admin.customizations.localization.settings',
        dynamicSegments: [],
        queryParams: null
      }];
    })
  });
});