define('frontend-cp/components/ko-admin/automation-actions-builder/tags/component', ['exports', 'frontend-cp/components/ko-admin/automation-actions-builder/base/component', 'npm:lodash', 'ember-concurrency'], function (exports, _component, _npmLodash, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var computed = Ember.computed;
  var isBlank = Ember.isBlank;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    store: service(),
    i18n: service(),

    // CPs
    tags: computed('automationAction.value', function () {
      var value = this.get('automationAction.value');
      var tags = isBlank(value) ? [] : value.split(',');
      return tags.map(function (str) {
        return { name: str.trim() };
      });
    }),

    // Tasks
    suggestTags: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(searchTerm) {
      var addNewMessage, data, exactMatch;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              addNewMessage = this.get('i18n').t('generic.addtagname', { tag: searchTerm });
              _context.next = 5;
              return this.get('store').query('tag', { name: searchTerm });

            case 5:
              data = _context.sent;
              exactMatch = !!data.toArray().findBy('name', searchTerm) || !!this.get('tags').findBy('name', searchTerm);
              return _context.abrupt('return', _npmLodash.default.difference(data.mapBy('name'), this.get('tags').mapBy('name')).map(function (name) {
                return { name: name };
              }).concat(exactMatch ? [] : [{ name: addNewMessage, actualName: searchTerm }]));

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    // Actions
    actions: {
      addTag: function addTag(tag) {
        var name = get(tag, 'actualName') || get(tag, 'name');
        var currentTags = this.get('tags');
        if (currentTags.find(function (t) {
          return tag === name;
        })) {
          return;
        }
        this.set('automationAction.value', currentTags.mapBy('name').concat([name]).join(','));
      },
      removeTag: function removeTag(tag) {
        var currentTags = this.get('tags');
        currentTags.removeObject(tag);
        this.set('automationAction.value', currentTags.mapBy('name').join(','));
      }
    }
  });
});