define('frontend-cp/components/spectrum-color-picker', ['exports', 'ember-spectrum-color-picker/components/spectrum-color-picker'], function (exports, _spectrumColorPicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _spectrumColorPicker.default.extend({
    showButtons: false,
    moveFiresChange: true,
    preferredFormat: 'hex'
  });
});