define('frontend-cp/components/ko-admin/privacy/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var filterBy = Ember.computed.filterBy;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Attributes
    privacy: null,
    editedPrivacy: null,
    title: null,
    locales: null,
    onSave: function onSave() {},
    onCancel: function onCancel() {},
    onDelete: function onDelete() {},

    init: function init() {
      this._super.apply(this, arguments);
      // Getting all locales
      this.set('locales', this.get('store').findAll('locale', { reload: true }));
    },


    enabledLocales: filterBy('locales', 'isPublic', true),

    locale: computed('editedPrivacy.locale', 'enabledLocales', function () {
      var localeIdentificator = this.get('editedPrivacy.locale');
      return this.get('enabledLocales').find(function (obj) {
        return obj.get('locale') === localeIdentificator;
      });
    }),

    // Services
    confirmation: service(),
    virtualModel: service(),
    store: service(),

    privacyTypes: computed(function () {
      return [{
        titleKey: 'admin.privacy.privacy_types.register',
        id: 'REGISTRATION'
      }, {
        titleKey: 'admin.privacy.privacy_types.cookie',
        id: 'COOKIE'
      }];
    }),

    privacyType: computed('editedPrivacy.privacyType', 'privacyTypes', function () {
      var privacyTypeId = this.get('editedPrivacy.privacyType');
      return this.get('privacyTypes').find(function (_ref) {
        var id = _ref.id;
        return id === privacyTypeId;
      });
    }),

    isDisabled: or('save.isRunning', 'performDelete.isRunning'),

    confirmDelete: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmationBody: 'admin.privacy.confirm_delete.body',
                intlConfirmationHeader: 'admin.privacy.confirm_delete.title',
                intlConfirmLabel: 'generic.confirm.delete_button'
              });

            case 2:
              _context.next = 4;
              return this.get('performDelete').perform();

            case 4:
              this.get('onDelete')();

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    performDelete: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('privacy').destroyRecord();

            case 2:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var privacy, editedPrivacy, schema;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              privacy = this.get('privacy');
              editedPrivacy = this.get('editedPrivacy');
              schema = this.get('schema');
              _context3.next = 5;
              return this.get('virtualModel').save(privacy, editedPrivacy, schema);

            case 5:
              this.get('onSave')();

            case 6:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    }))
  });
});