define("frontend-cp/components/ko-table/cell/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-table_cell__container_16vitb",
    "compact": "ko-table_cell__compact_16vitb",
    "dasherize": "ko-table_cell__dasherize_16vitb",
    "ellipsis": "ko-table_cell__ellipsis_16vitb"
  };
});