define('frontend-cp/components/ko-info-bar/update-log/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    updateLog: null,

    // CP's
    summary: computed('updateLog.[]', function () {
      return this.get('updateLog').reduce(function (previousValue, currentValue) {
        var fullName = get(currentValue, 'user.fullName');

        if (!previousValue.mapBy('fullName').includes(fullName)) {
          previousValue.push({
            fullName: fullName,
            activityCount: 1,
            lastUpdatedAt: currentValue.updatedAt
          });
        } else {
          var existingEntry = previousValue.find(function (entry) {
            return entry.fullName === fullName;
          });
          existingEntry.activityCount += 1;
          if ((0, _moment.default)(currentValue.updatedAt).isAfter(existingEntry.lastUpdatedAt, 'minute')) {
            existingEntry.lastUpdatedAt = currentValue.updatedAt;
          }
        }
        return previousValue;
      }, []);
    })
  });
});