define("frontend-cp/components/ko-admin/predicate-builder/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-admin_predicate-builder__container_yti7wc",
    "fadeIn": "ko-admin_predicate-builder__fadeIn_yti7wc",
    "containerRemoving": "ko-admin_predicate-builder__containerRemoving_yti7wc",
    "label": "ko-admin_predicate-builder__label_yti7wc",
    "remove": "ko-admin_predicate-builder__remove_yti7wc",
    "add": "ko-admin_predicate-builder__add_yti7wc",
    "new": "ko-admin_predicate-builder__new_yti7wc"
  };
});