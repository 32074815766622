define('frontend-cp/components/ko-text-editor/file-upload/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    onCancel: function onCancel() {},
    uploads: null
  });
});