define("frontend-cp/components/ko-user-cases/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "sandbox-trigger": "ko-user-cases__sandbox-trigger_ksf5v6",
    "active-cases-trigger": "ko-user-cases__active-cases-trigger_ksf5v6",
    "chevron": "ko-user-cases__chevron_ksf5v6",
    "sandbox-dropdown": "ko-user-cases__sandbox-dropdown_ksf5v6",
    "conversations-count": "ko-user-cases__conversations-count_ksf5v6",
    "trigger-text": "ko-user-cases__trigger-text_ksf5v6",
    "closed": "ko-user-cases__closed_ksf5v6",
    "active-cases-dropdown": "ko-user-cases__active-cases-dropdown_ksf5v6"
  };
});