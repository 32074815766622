define('frontend-cp/components/ko-admin/engagement-predicate-builder/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes

    definitions: [],
    collections: [],
    onCollectionAddition: null,
    onCollectionRemoval: null,
    onAdditionOfPropositionToCollection: null,
    onPropositionDeletion: null,
    onPropositionChange: null,

    // Actions

    actions: {
      onPropositionChange: function onPropositionChange(collection, proposition) {
        if (this.get('onPropositionChange')) {
          return this.get('onPropositionChange')(collection, proposition);
        }
      }
    }
  });
});