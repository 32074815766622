define("frontend-cp/components/ko-journey-timeline/note/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "note": "ko-journey-timeline_note__note_c06o40",
    "pinned": "ko-journey-timeline_note__pinned_c06o40",
    "avatar": "ko-journey-timeline_note__avatar_c06o40",
    "body": "ko-journey-timeline_note__body_c06o40",
    "header": "ko-journey-timeline_note__header_c06o40",
    "message": "ko-journey-timeline_note__message_c06o40",
    "activity-time": "ko-journey-timeline_note__activity-time_c06o40",
    "content": "ko-journey-timeline_note__content_c06o40",
    "menu": "ko-journey-timeline_note__menu_c06o40",
    "menu-wrapper": "ko-journey-timeline_note__menu-wrapper_c06o40",
    "hidden-item": "ko-journey-timeline_note__hidden-item_c06o40",
    "menu-item": "ko-journey-timeline_note__menu-item_c06o40",
    "yellow": "ko-journey-timeline_note__yellow_c06o40",
    "processing": "ko-journey-timeline_note__processing_c06o40",
    "pinned-by": "ko-journey-timeline_note__pinned-by_c06o40",
    "separator": "ko-journey-timeline_note__separator_c06o40"
  };
});