define('frontend-cp/components/ko-timeline-2/list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attrs
    parent: null,
    post: null,
    publicReplyChannels: null,
    posts: null,
    caseFields: null,
    timelineContext: null,
    lastReadPost: null,
    canResend: true,
    'scroll-into-view': function scrollIntoView() {},
    onReplyToPost: function onReplyToPost() {},
    onReplyWithQuote: function onReplyWithQuote() {},
    onAddCC: function onAddCC() {},
    onCopyLink: function onCopyLink() {},
    onResend: function onResend() {},
    onItemMenuOpen: function onItemMenuOpen() {}
  });
});