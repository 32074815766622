define('frontend-cp/components/ko-admin/rateplans/currency/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;


  var currencyComponent = Component.extend({
    tagName: ''
  });

  currencyComponent.reopenClass({
    positionalParams: ['amount', 'currency']
  });

  exports.default = currencyComponent;
});