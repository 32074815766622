define("frontend-cp/components/ko-cases-list/column/user/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "wrapper": "ko-cases-list_column_user__wrapper_tqq92",
    "avatar": "ko-cases-list_column_user__avatar_tqq92",
    "name": "ko-cases-list_column_user__name_tqq92"
  };
});