define('frontend-cp/components/ko-simple-list/cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    disabled: false,
    width: null,

    computedStyles: computed('width', function () {
      return htmlSafe(this.get('width') ? 'width: ' + this.get('width') : 'flex-grow: 1; flex-shrink: 1; flex-basis: 0;');
    })
  });
});