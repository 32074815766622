define("frontend-cp/components/ko-lightbox/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "modal": "ko-lightbox__modal_2ubbcy",
    "lightbox-image": "ko-lightbox__lightbox-image_2ubbcy",
    "loading-image": "ko-lightbox__loading-image_2ubbcy",
    "hidden-image": "ko-lightbox__hidden-image_2ubbcy"
  };
});