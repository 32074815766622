define("frontend-cp/components/ko-insights/interval-toggle/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "toggle": "ko-insights_interval-toggle__toggle_12hol7",
    "item": "ko-insights_interval-toggle__item_12hol7",
    "active": "ko-insights_interval-toggle__active_12hol7"
  };
});