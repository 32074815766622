define('frontend-cp/session/admin/automation/sla/edit/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  exports.default = Route.extend((0, _route.default)(), {
    i18n: service(),

    model: function model(params) {
      var store = this.get('store');

      return RSVP.hash({
        sla: store.findRecord('sla', params.sla_id),
        definitions: store.query('definition', { type: 'sla' }),
        priorities: store.query('case-priority', {})
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    }
  });
});