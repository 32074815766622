define('frontend-cp/session/agent/users/new/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route'], function (exports, _tabbedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;


  var CUSTOMER_ROLE_ID = 4;

  exports.default = _tabbedRoute.default.extend({
    i18n: service(),
    processManager: service(),

    model: function model(_ref) {
      var _this = this;

      var timestamp = _ref.timestamp;

      var newUser = this.store.peekAll('user').findBy('creationTimestamp', timestamp);
      if (newUser) {
        return newUser;
      }

      return this.store.findRecord('role', CUSTOMER_ROLE_ID).then(function (roleModel) {
        return _this.store.createRecord('user', {
          role: roleModel,
          creationTimestamp: timestamp
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(model, 'user-new');
      processManager.setForegroundProcess(process);

      var tabStore = this.get('tabStore');
      var tab = tabStore.open(transition);
      tabStore.update(tab, { process: process });

      this.set('tab', tab);

      var UserStateManager = getOwner(this).factoryFor('state-manager:user');
      var state = UserStateManager.create({ user: model, tab: tab });
      this.set('state', state);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('state', this.get('state'));
    },


    actions: {
      willTransition: function willTransition(transition) {
        var tabStore = this.get('tabStore');
        var activeTab = tabStore.get('activeTab');
        var newUserController = this.controllerFor('session.agent.users.new.index');
        var openInSameTab = newUserController.get('openInSameTab');

        // Record the current tab basePath so we can update the correct tab in `afterModel`
        if (activeTab && openInSameTab && (transition.targetName === 'session.agent.users.user.index' || transition.targetName === 'session.agent.users.user.organization')) {
          newUserController.set('transitionTabPath', activeTab.basePath);
        } else {
          newUserController.set('openInSameTab', false);
          newUserController.set('transitionTabPath', null);
        }
      },
      closeTab: function closeTab() {
        this.get('tabStore').close(this.tab);
      }
    }
  });
});