define('frontend-cp/models/product-rateplan-charge-tier', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    price: _emberData.default.attr('string'),
    priceFormat: _emberData.default.attr('string'),
    currency: _emberData.default.attr('string'),
    endingUnit: _emberData.default.attr('number'),
    tier: _emberData.default.attr('number')
  });
});