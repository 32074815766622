define('frontend-cp/services/socket/channel', ['exports', 'phoenix'], function (exports, _phoenix) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var EmberObject = Ember.Object;
  var _join = Ember.run.join;
  var Evented = Ember.Evented;


  var Logger = Ember.Logger;
  var performance = window.performance || Date;

  exports.default = EmberObject.extend(Evented, {
    _socket: null,
    _locks: 0,

    params: null,
    name: null,
    presence: null,
    state: 'closed',

    init: function init() {
      this._super.apply(this, arguments);
      this._queue = [];
      this.set('presence', {});
    },
    lock: function lock() {
      this.incrementProperty('_locks');
      return this;
    },
    unlock: function unlock() {
      (false && !(this.get('_locks') !== 0) && Ember.assert('Channel is not locked (' + this.get('name') + ')', this.get('_locks') !== 0));


      var newValue = this.decrementProperty('_locks');
      if (newValue === 0) {
        this.leave();
      }
      return this;
    },
    join: function join() {
      var _this = this;

      if (this._joinPromise) {
        return this._joinPromise;
      }

      var _getProperties = this.getProperties('name', 'params'),
          name = _getProperties.name,
          params = _getProperties.params;

      this._channel = this.get('_socket').channel(name, params);
      this.set('state', 'joining');

      var joinCB = this.onMemberJoined.bind(this);
      var leaveCB = this.onMemberLeft.bind(this);

      this._channel.on('presence_state', function (state) {
        var presence = _phoenix.Presence.syncState(_this.get('presence'), state, joinCB, leaveCB);
        _this.set('presence', presence);
        _this.trigger('presence-change', presence);
      });

      this._channel.on('presence_diff', function (diff) {
        var presence = _phoenix.Presence.syncDiff(_this.get('presence'), diff, joinCB, leaveCB);
        _this.set('presence', presence);
        _this.trigger('presence-change', presence);
      });

      this._channel.onClose(function () {
        if (!_this.get('isDestroying') && !_this.get('isDestroyed')) {
          _this.set('state', 'closed');
        }
      });

      this._queue.forEach(function (name) {
        return _this._addEventHandler(name);
      });
      this._queue = [];

      // const start = performance.now();
      // Logger.debug('--> [JOIN]', name, deepClone(params));

      this._joinPromise = new RSVP.Promise(function (resolve, reject) {
        _this._channel.join().receive('ok', function (r) {
          return _join(null, resolve, r);
        }).receive('error', function (e) {
          return _join(null, reject, e);
        }).receive('timeout', function () {
          return _join(null, reject, false);
        });
      }).catch(function (e) {
        // const end = performance.now();
        // const ms = Math.ceil(end - start);
        // Logger.debug('!-- [JOIN]', name, `(${ms}ms)`, e);
        _this._joinPromise = null;
        throw e;
      }).then(function (response) {
        _this.set('state', 'joined');
        _this.set('data', response);

        // const end = performance.now();
        // const ms = Math.ceil(end - start);
        // Logger.debug('<-- [JOIN]', name, `(${ms}ms)`, deepClone(response));

        return _this;
      });

      return this._joinPromise;
    },
    leave: function leave() {
      (false && !(this._channel) && Ember.assert('Cannot leave, channel has not been joined (' + this.get('name') + ')', this._channel));


      var channel = this._channel;
      this._channel = null;
      this._joinPromise = null;
      this.set('state', 'closing');

      Logger.debug('--x [LEAVE]', this.get('name'));
      return new RSVP.Promise(function (resolve, reject) {
        channel.leave().receive('ok', function (r) {
          return _join(null, resolve, r);
        }).receive('error', function (e) {
          return _join(null, reject, e);
        }).receive('timeout', function () {
          return _join(null, reject, false);
        });
      });
    },
    onMemberJoined: function onMemberJoined(id /*, prev, metas*/) {
      this.trigger('presence-joined', id, this);
    },
    onMemberLeft: function onMemberLeft(id) {
      this.trigger('presence-left', id, this);
    },
    request: function request(message, data) {
      var _this2 = this;

      (false && !(this._channel) && Ember.assert('Cannot request, channel has not been joined (' + this.get('name') + ')', this._channel));


      var start = performance.now();
      Logger.debug('--> [REQUEST]', message, deepClone(data));

      var p = new RSVP.Promise(function (resolve, reject) {
        _this2._channel.push(message, data).receive('ok', function (r) {
          return _join(null, resolve, r);
        }).receive('error', function (e) {
          return _join(null, reject, e);
        }).receive('timeout', function () {
          return _join(null, reject, false);
        });
      });

      return p.then(function (response) {
        var end = performance.now();
        var ms = Math.ceil(end - start);
        Logger.debug('<-- [REQUEST]', message, '(' + ms + 'ms)', deepClone(response));
        return response;
      }).catch(function (e) {
        var end = performance.now();
        var ms = Math.ceil(end - start);
        Logger.error('!-- [REQUEST]', message, '(' + ms + 'ms)', e);
        throw e;
      });
    },
    push: function push(message, data) {
      (false && !(this._channel) && Ember.assert('Cannot push, channel has not been joined (' + this.get('name') + ')', this._channel));


      Logger.debug('--> [TRIGGER]', message, deepClone(data));
      this._channel.push(message, data).receive('error', function (e) {
        Logger.error('!-- [TRIGGER]', message, e);
        throw e;
      });
    },
    on: function on(name) {
      if (!this.has(name) && !name.match(/^presence-/)) {
        if (this._channel) {
          this._addEventHandler(name);
        } else {
          this._queue.push(name);
        }
      }

      // _super has to be after, otherwise `has` is always false
      this._super.apply(this, arguments);
      return this;
    },
    off: function off(name) {
      this._super.apply(this, arguments);

      if (!this._channel) {
        return this;
      }

      if (!this.has(name) && !name.match(/^presence-/)) {
        this._channel.off(name);
      }

      return this;
    },
    _addEventHandler: function _addEventHandler(name) {
      var _this3 = this;

      this._channel.on(name, function (data) {
        return _this3.trigger(name, data, _this3);
      });
    }
  });


  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
});