define('frontend-cp/models/report', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    label: (0, _attr.default)(),
    object: (0, _attr.default)({ defaultValue: 'CASES' }),
    visibility: (0, _attr.default)({ defaultValue: 'PUBLIC' }),
    visibilityToTeams: (0, _relationships.hasMany)('team', { async: false }),
    predicateCollections: (0, _relationships.hasMany)('predicate-collection', { defaultValue: [], async: false }),
    status: (0, _attr.default)(),
    realtimeChannel: (0, _attr.default)(),

    creator: (0, _relationships.belongsTo)('user', { async: true, inverse: null }),
    lastGeneratedBy: (0, _relationships.belongsTo)('user', { async: true, inverse: null }),

    recordCount: (0, _attr.default)(),
    lastStatusUpdateAt: (0, _attr.default)(),
    lastDownloadedAt: (0, _attr.default)(),
    activities: (0, _attr.default)(), // hasMany [ Activity ]
    createdAt: (0, _attr.default)(),
    updatedAt: (0, _attr.default)()
  });
});