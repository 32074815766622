define('frontend-cp/components/ko-user-content/info-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes:
    user: null,
    processingOrg: false,
    isUserOnline: false,

    tagName: '',

    actions: {
      updateOrgRemovalState: function updateOrgRemovalState(value, org) {
        this.sendAction('updateOrgRemovalState', value, org);
      },
      onPresenceChanged: function onPresenceChanged() {
        var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var id = this.get('user.id');
        var metas = data[id] && data[id].metas;
        var isOnline = !!(metas && metas.length);

        this.set('isUserOnline', isOnline);
      }
    }
  });
});