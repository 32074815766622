define('frontend-cp/components/ko-agent-content/sidebar/user-info-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var reads = Ember.computed.reads;
  exports.default = Component.extend({
    tagName: '',

    user: null,

    primaryEmail: reads('user.primaryEmailAddress'),
    primaryPhone: reads('user.primaryPhoneNumber'),
    primaryTwitterHandle: reads('user.primaryTwitterHandle'),
    primaryFacebookUsername: reads('user.primaryFacebookUsername'),
    hasTwoFactorAuthEnabled: reads('user.isMfaEnabled')
  });
});