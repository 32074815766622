define('frontend-cp/components/ko-info-bar/field/select-multiple/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    allowCreate: false,
    allowDelete: true,
    isDisabled: false,
    isEdited: false,
    isErrored: false,
    isKREEdited: false,
    onChange: null,
    onValueAddition: null,
    onValueRemoval: null,
    onSuggestion: null,
    placeholder: '',
    searchField: 'name',
    title: '',
    value: [],
    qaClass: null,

    // HTML
    tagName: ''
  });
});