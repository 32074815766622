define('frontend-cp/services/browser-tab', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Service = Ember.Service;
  var service = Ember.inject.service;


  var FAVICON_WIDTH_HEIGHT = 32;
  var FAVICON_PATH = '/images/favicon32x32.png';
  var NOTIFICATION_CIRCLE_FILL_COLOUR = '#DB3F24';
  var NOTIFICATION_CIRCLE_BORDER_COLOUR = '#FFFFFF';

  exports.default = Service.extend({
    _faviconKeyframes: null,
    _defaultFavicons: null,
    _windowFocusEventBound: false,

    window: service(),
    headData: service(),
    i18n: service(),

    init: function init() {
      this._super.apply(this, arguments);

      this._faviconKeyframes = [];
      this._defaultFavicons = {};
    },
    initialize: function initialize() {
      this._saveDefaultFavicons();
      this._preGenerateFavicons();
    },
    _saveDefaultFavicons: function _saveDefaultFavicons() {
      var favicons = this._favicons();

      var defaults = favicons.reduce(function (defaults, icon) {
        defaults[icon.getAttribute('sizes')] = icon.getAttribute('href');
        return defaults;
      }, {});

      this._defaultFavicons = defaults;
    },
    registerAppUpdate: function registerAppUpdate() {
      if (!this._isWindowVisible()) {
        this._updateFavicon();
        this._updateTitle();
        this._bindWindowFocusEvent();
      }
    },
    _preGenerateFavicons: function _preGenerateFavicons() {
      var context = this._canvasContext();

      var img = document.createElement('img');
      img.setAttribute('crossOrigin', 'anonymous');

      var _this = this;
      img.onload = function () {
        var img = this;
        _this._generateFaviconKeyframes(img, context);
      };

      img.src = FAVICON_PATH;
    },
    _generateFaviconKeyframes: function _generateFaviconKeyframes(img, context) {
      var _this2 = this;

      var keyframes = this._alphaValues().map(function (alpha) {
        context.globalAlpha = alpha;

        _this2._drawBackgroundImage(img, context);
        _this2._drawNotificationCircle(context);

        return _this2._dataUrl(context);
      });

      this._faviconKeyframes = keyframes;
    },
    _alphaValues: function _alphaValues() {
      function generateAlphaValue(val) {
        return (val + 1) / 10;
      }

      return [].concat(_toConsumableArray(Array(10).keys())).map(generateAlphaValue);
    },
    _drawBackgroundImage: function _drawBackgroundImage(img, context) {
      context.clearRect(0, 0, FAVICON_WIDTH_HEIGHT, FAVICON_WIDTH_HEIGHT);
      context.drawImage(img, 0, 0, FAVICON_WIDTH_HEIGHT, FAVICON_WIDTH_HEIGHT);
    },
    _drawNotificationCircle: function _drawNotificationCircle(context) {
      var _circleProperties = this._circleProperties(),
          _circleProperties2 = _slicedToArray(_circleProperties, 2),
          outer = _circleProperties2[0],
          inner = _circleProperties2[1];

      context.beginPath();
      context.arc(outer.x, outer.y, outer.radius, outer.startAngle, outer.endAngle);
      context.fillStyle = outer.colour;
      context.fill();

      context.beginPath();
      context.arc(inner.x, inner.y, inner.radius, inner.startAngle, inner.endAngle);
      context.fillStyle = inner.colour;
      context.fill();
    },
    _circleProperties: function _circleProperties() {
      var outerCircle = {
        x: FAVICON_WIDTH_HEIGHT * 0.75,
        y: FAVICON_WIDTH_HEIGHT / 2,
        radius: FAVICON_WIDTH_HEIGHT / 4,
        startAngle: 0,
        endAngle: 2 * Math.PI,
        colour: NOTIFICATION_CIRCLE_BORDER_COLOUR
      };

      var innerCircle = Object.assign({}, outerCircle, {
        radius: outerCircle.radius * 0.75,
        colour: NOTIFICATION_CIRCLE_FILL_COLOUR
      });

      return [outerCircle, innerCircle];
    },
    _isWindowVisible: function _isWindowVisible() {
      return this.get('window.visible');
    },
    _updateFavicon: function _updateFavicon() {
      this.get('_applyFaviconKeyframes').perform();
    },
    _restoreFavicon: function _restoreFavicon() {
      var _this3 = this;

      this._favicons().forEach(function (icon) {
        return icon.href = _this3._defaultFavicons[icon.getAttribute('sizes')];
      });
    },


    _applyFaviconKeyframes: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var _this4 = this;

      var favicons, _loop, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, frame;

      return regeneratorRuntime.wrap(function _callee$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              favicons = this._favicons();
              _loop = regeneratorRuntime.mark(function _loop(frame) {
                return regeneratorRuntime.wrap(function _loop$(_context) {
                  while (1) {
                    switch (_context.prev = _context.next) {
                      case 0:
                        favicons.forEach(function (icon) {
                          return icon.href = frame;
                        });
                        _context.next = 3;
                        return (0, _emberConcurrency.timeout)(100);

                      case 3:
                      case 'end':
                        return _context.stop();
                    }
                  }
                }, _loop, _this4);
              });
              _iteratorNormalCompletion = true;
              _didIteratorError = false;
              _iteratorError = undefined;
              _context2.prev = 5;
              _iterator = this._faviconKeyframes[Symbol.iterator]();

            case 7:
              if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
                _context2.next = 13;
                break;
              }

              frame = _step.value;
              return _context2.delegateYield(_loop(frame), 't0', 10);

            case 10:
              _iteratorNormalCompletion = true;
              _context2.next = 7;
              break;

            case 13:
              _context2.next = 19;
              break;

            case 15:
              _context2.prev = 15;
              _context2.t1 = _context2['catch'](5);
              _didIteratorError = true;
              _iteratorError = _context2.t1;

            case 19:
              _context2.prev = 19;
              _context2.prev = 20;

              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }

            case 22:
              _context2.prev = 22;

              if (!_didIteratorError) {
                _context2.next = 25;
                break;
              }

              throw _iteratorError;

            case 25:
              return _context2.finish(22);

            case 26:
              return _context2.finish(19);

            case 27:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee, this, [[5, 15, 19, 27], [20,, 22, 26]]);
    })).drop(),

    _updateTitle: function _updateTitle() {
      this.get('_applyTitle').perform();
    },
    _restoreTitle: function _restoreTitle() {
      this.get('_applyTitle').cancelAll();
    },


    _applyTitle: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var originalTitle, WAIT, ITERATIONS, newTitle, titles, count, _titles, _titles2, head, tail;

      return regeneratorRuntime.wrap(function _callee2$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              originalTitle = this.get('headData.title');
              _context3.prev = 1;
              WAIT = 1000;
              ITERATIONS = 8;
              newTitle = this.get('i18n').t('generic.app_update_received_browser_tab_title');
              titles = [newTitle, originalTitle];
              count = 0;

            case 7:
              if (!(count < ITERATIONS)) {
                _context3.next = 16;
                break;
              }

              _context3.next = 10;
              return (0, _emberConcurrency.timeout)(WAIT);

            case 10:
              _titles = titles, _titles2 = _slicedToArray(_titles, 2), head = _titles2[0], tail = _titles2[1];

              this.set('headData.title', head);
              titles = [tail, head];

              count = count + 1;
              _context3.next = 7;
              break;

            case 16:
              _context3.prev = 16;

              this.set('headData.title', originalTitle);
              return _context3.finish(16);

            case 19:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee2, this, [[1,, 16, 19]]);
    })).drop(),

    _bindWindowFocusEvent: function _bindWindowFocusEvent() {
      var _this5 = this;

      if (this._windowFocusEventBound) {
        return;
      }

      this.get('window').one('focus', this, function () {
        _this5._restoreFavicon();
        _this5._restoreTitle();
        _this5._windowFocusEventBound = false;
      });

      this._windowFocusEventBound = true;
    },
    _favicons: function _favicons() {
      var icons = [];
      var links = document.getElementsByTagName('head')[0].getElementsByTagName('link');

      for (var i = 0; i < links.length; i++) {
        var link = links[i];
        if (link.getAttribute('rel') === 'icon') {
          icons.push(link);
        }
      }

      return icons;
    },
    _canvasContext: function _canvasContext() {
      var canvas = document.createElement('canvas');
      canvas.width = canvas.height = FAVICON_WIDTH_HEIGHT;

      return canvas.getContext('2d');
    },
    _dataUrl: function _dataUrl(context) {
      return context.canvas.toDataURL('image/png');
    }
  });
});