define('frontend-cp/session/admin/customizations/user-fields/edit/controller', ['exports', 'frontend-cp/services/virtual-model', 'frontend-cp/lib/fields-schema'], function (exports, _virtualModel, _fieldsSchema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    notification: service(),
    i18n: service(),
    customFields: service(),
    virtualModel: service(),
    schema: _fieldsSchema.default,

    // CPs
    title: computed('field.title', function () {
      return this.get('customFields').getTitleBreadcrumbs(this.get('field'));
    }),

    // Actions
    actions: {
      updated: function updated() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        this.transitionToRoute('session.admin.customizations.user-fields.index');
      },
      deleted: function deleted() {
        this.initEdits();
        this.transitionToRoute('session.admin.customizations.user-fields.index');
      },
      canceled: function canceled() {
        this.transitionToRoute('session.admin.customizations.user-fields.index');
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedField', this.get('virtualModel').makeSnapshot(this.get('field'), _fieldsSchema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('field'), this.get('editedField'), _fieldsSchema.default);
    }
  });
});