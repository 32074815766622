define('frontend-cp/components/ko-bulk-invitation/recipient/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var empty = Ember.computed.empty;
  exports.default = Component.extend({
    tagName: '',
    recipient: null,
    allRoles: [],
    allTeams: [],

    roleSorting: ['title:asc'],
    sortedRoles: computed.sort('allRoles', 'roleSorting'),

    teamSorting: ['title:asc'],
    sortedTeams: computed.sort('availableTeams', 'teamSorting'),

    availableTeams: computed('allTeams.@each.id', 'selectedTeams.@each.id', function () {
      var editedTeamIds = this.get('selectedTeams').mapBy('id');
      return this.get('allTeams').filter(function (team) {
        return editedTeamIds.indexOf(team.get('id')) === -1;
      });
    }),

    selectedTeams: computed('recipient.teams.[]', function () {
      return this.get('recipient.teams').toArray();
    }),

    disableTeamSelector: empty('allTeams'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('onBoarding')) {
        this.configureForOnBoarding();
      }
    },
    configureForOnBoarding: function configureForOnBoarding() {
      var agentRole = this.get('allRoles').findBy('title', 'Agent');
      var generalTeam = this.get('allTeams').findBy('title', 'General');

      if (agentRole) {
        this.set('recipient.role', agentRole);
      }

      if (generalTeam) {
        this.get('recipient.teams').addObject(generalTeam);
      }
    }
  });
});