define("frontend-cp/components/ko-select/drill-down/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "drill-down": "ko-select_drill-down__drill-down_wwxv1q",
    "back-option": "ko-select_drill-down__back-option_wwxv1q",
    "back-arrow": "ko-select_drill-down__back-arrow_wwxv1q",
    "option-main": "ko-select_drill-down__option-main_wwxv1q",
    "child-arrow": "ko-select_drill-down__child-arrow_wwxv1q",
    "focused": "ko-select_drill-down__focused_wwxv1q"
  };
});