define("frontend-cp/session/admin/customizations/email-templates/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "brand-selector": "session_admin_customizations_email-templates__brand-selector_1t1ei6",
    "loader": "session_admin_customizations_email-templates__loader_1t1ei6"
  };
});