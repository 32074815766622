define('frontend-cp/components/ko-cases-list/column/generic-string/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    column: null,
    model: null,
    propertyName: null
  });
});