define('frontend-cp/components/ko-admin/apps/webhooks/index/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var filterBy = Ember.computed.filterBy;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Attributes
    webhooks: [],
    edit: null,

    // Services
    store: service(),
    i18n: service(),
    notification: service(),
    confirmation: service(),

    // CPs
    enabledWebhooks: filterBy('webhooks', 'isEnabled', true),
    disabledWebhooks: filterBy('webhooks', 'isEnabled', false),
    sortedEnabledWebhooks: computed('enabledWebhooks.@each.label', function () {
      return this.get('enabledWebhooks').toArray().sortBy('label');
    }),
    sortedDisabledWebhooks: computed('disabledWebhooks.@each.label', function () {
      return this.get('disabledWebhooks').toArray().sortBy('label');
    }),

    // Actions
    actions: {
      toggleStatus: function toggleStatus(webhook, e) {
        e.stopPropagation();
        e.preventDefault();

        webhook.toggleProperty('isEnabled');
        webhook.save();
      },
      delete: function _delete(webhook, e) {
        var _this = this;

        e.preventDefault();
        e.stopPropagation();
        return this.get('confirmation').confirm({
          intlConfirmationBody: 'generic.confirm.delete'
        }).then(function () {
          return webhook.destroyRecord();
        }).then(function () {
          var msg = _this.get('i18n').t('admin.apps.webhooks.delete.success_message');
          _this.get('notification').success(msg);
        });
      }
    }
  });
});