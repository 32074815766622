define('frontend-cp/components/ko-admin/inline-locale-edit/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    localeFields: null,
    inputType: 'text',

    // State
    defaultLocale: '',

    // HTML
    tagName: 'div',

    store: service(),

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      var settings = this.get('store').peekAll('setting');
      this.set('defaultLocale', settings.findBy('key', 'account.default_language').get('value'));
    },


    // CPs
    hasMultipleLocales: computed('localeFields', function () {
      return this.get('localeFields').length > 1;
    }),

    translationsForField: computed('localeFields', function () {
      var _this = this;

      var locales = this.get('store').peekAll('locale');
      var localeFields = this.get('localeFields');

      return localeFields.filter(function (localeField) {
        var locale = locales.findBy('locale', localeField.get('locale'));
        return locale.get('isPublic') === true && localeField.get('locale') !== _this.get('defaultLocale');
      });
    }),

    isEdited: computed('localeFields.@each.hasDirtyAttributes', 'localeFields.@each.translation', function () {
      var fieldLocales = this.get('translationsForField');
      var populatedFieldLocales = fieldLocales.find(function (fieldLocale) {
        return fieldLocale.get('translation');
      });

      return populatedFieldLocales && fieldLocales.isAny('hasDirtyAttributes', true);
    })
  });
});