define('frontend-cp/components/ko-admin/engagement-actions-builder/component', ['exports', 'ember-basic-dropdown/utils/computed-fallback-if-undefined'], function (exports, _computedFallbackIfUndefined) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({

    // Attributes

    classNames: ['ko-automation-actions-builder'],
    type: '',
    isNew: true,
    team: null,
    agent: null,
    teams: (0, _computedFallbackIfUndefined.default)([]),
    agents: (0, _computedFallbackIfUndefined.default)([]),
    automationActions: (0, _computedFallbackIfUndefined.default)([]),
    definitions: (0, _computedFallbackIfUndefined.default)([]),

    // Lifecycle hooks

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    }
  });
});