define("frontend-cp/session/agent/search/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "session_agent_search__container_2b3a56",
    "title": "session_agent_search__title_2b3a56",
    "search-input": "session_agent_search__search-input_2b3a56",
    "help-link": "session_agent_search__help-link_2b3a56",
    "main": "session_agent_search__main_2b3a56",
    "right": "session_agent_search__right_2b3a56",
    "loader": "session_agent_search__loader_2b3a56",
    "not-found": "session_agent_search__not-found_2b3a56",
    "bulk-actions": "session_agent_search__bulk-actions_2b3a56",
    "merge-button": "session_agent_search__merge-button_2b3a56",
    "trash-button": "session_agent_search__trash-button_2b3a56",
    "row-styles": "session_agent_search__row-styles_2b3a56",
    "ribbon": "session_agent_search__ribbon_2b3a56"
  };
});