define('frontend-cp/login-agent/route', ['exports', 'ember-service/inject', 'frontend-cp/lib/sso-url-parsing', 'frontend-cp/utils/bugsnag'], function (exports, _inject, _ssoUrlParsing, _bugsnag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Route.extend({
    launchDarkly: (0, _inject.default)(),

    caseListTab: service('case-list-tab'),
    errorHandler: service(),
    locale: service(),
    localStoreService: service('localStore'),
    sessionService: service('session'),
    store: service(),
    windowService: service('window'),
    notification: service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var launchDarkly = this.get('launchDarkly');
      var launchDarkly = this.get('launchDarkly');
      var launchDarkly = this.get('launchDarkly');

      this.get('caseListTab').cancelCasePolling();

      var error = this.paramsFor('login-agent').error;
      if (error) {
        this.get('notification').add({
          type: 'error',
          title: error,
          autodismiss: true
        });
      }

      var store = this.get('store');
      var bypassSso = this.paramsFor('login-agent').bypassSso;
      var windowService = this.get('windowService');

      var loggedIn = function loggedIn() {
        if (launchDarkly.get('ops-remember-me-bugsnag-logging')) {
          var context = (0, _bugsnag.getMetaData)(null, getOwner(_this));
          var isARememberMeLogin = Boolean(_this.get('sessionService.rememberMeToken') || _this.get('sessionService.rememberMe'));
          if (isARememberMeLogin) {
            window.Bugsnag.notify('RememberMeLogin', 'User was logged in in rememberMe mode', context, 'info');
          }
        }

        _this.transitionTo('session.agent');
      };

      var notLoggedIn = function notLoggedIn() {
        if (launchDarkly.get('release-remember-me')) {
          if (launchDarkly.get('ops-remember-me-bugsnag-logging')) {
            var context = (0, _bugsnag.getMetaData)(null, getOwner(_this));
            var isARememberMeLogout = Boolean(_this.get('sessionService.rememberMeToken'));
            if (isARememberMeLogout) {
              window.Bugsnag.notify('RememberMeLogout', 'User was logged out despite a remember me token being present', context, 'info');
            }
          }

          _this.get('sessionService').clear({ rememberMeToken: true });
        }
        return store.findAll('auth-provider').then(function (providers) {
          var ssoProvider = providers.findBy('scheme', 'SSO');
          if (ssoProvider && bypassSso !== 'true') {
            transition.abort();
            var url = ssoProvider.get('loginUrl');
            url = (0, _ssoUrlParsing.replaceActionUrl)(url, windowService.currentPath());
            window.location = url;
          }
        }).catch(function () {
          // if we fail to get the auth providers, carry on to the login screen
        });
      };

      return this.get('errorHandler').disableWhile(function () {
        return _this.get('locale').setup();
      }).then(function () {
        return _this.get('sessionService').getSession().then(loggedIn, notLoggedIn);
      });
    },
    model: function model() {
      var model = EmberObject.create({
        email: '',
        password: ''
      });

      return RSVP.hash({
        model: model,
        authProviders: this.get('store').findAll('auth-provider')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    },


    actions: {
      willTransition: function willTransition() {
        this.get('controller').reset();
      }
    }
  });
});