define('frontend-cp/session/admin/people/businesshours/new/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)('businessHour'), {
    model: function model() {
      return this.get('store').createRecord('business-hour', {
        zones: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: []
        }
      });
    },
    setupController: function setupController(controller, businessHour) {
      controller.setProperties({ businessHour: businessHour });
      controller.initEdits();
    }
  });
});