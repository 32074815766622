define('frontend-cp/components/ko-admin/fields/form/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var EmberObject = Ember.Object;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var observer = Ember.observer;
  exports.default = Component.extend({
    // HTML
    tagName: '',

    // Attributes
    field: null,
    editedField: null,
    title: null,
    onCancel: null,
    onSave: null,
    onDelete: null,
    onSystemOptionsEdit: function onSystemOptionsEdit() {},
    agentCaseSettings: false,
    isCaseField: alias('agentCaseSettings'),
    isStatusKeyAvailable: true,
    isCustomerEditAvailable: true,
    isSystemOptionsEdited: false,
    schema: null,

    // Services
    customFields: service(),
    confirmation: service(),
    locale: service(),
    store: service(),
    virtualModel: service(),

    // CPs
    isApiFieldKeyAvailable: computed('field.id', function () {
      return !this.get('field.id');
    }),

    isStatusOrApiFieldAvailable: computed('isApiFieldKeyAvailable', 'isStatusKeyAvailable', function () {
      return this.get('isApiFieldKeyAvailable') || this.get('isStatusKeyAvailable');
    }),

    canBeDeleted: computed('field.isNew', 'field.isSystem', function () {
      var field = this.get('field');
      return !(field.get('isNew') || field.get('isSystem'));
    }),

    canBeVisibleByCustomers: computed('field', function () {
      var fieldModelName = this.get('field.constructor.modelName');
      return fieldModelName !== 'organization-field';
    }),

    onCustomerEditableChange: observer('field.isCustomerEditable', function () {
      if (!this.get('field.isCustomerEditable')) {
        this.set('field.isRequiredForCustomers', false);
      }
    }),

    isSystemField: computed('field.fieldType', function () {
      switch (this.get('field.fieldType')) {
        case 'SUBJECT':
        case 'PRIORITY':
        case 'STATUS':
        case 'TYPE':
        case 'TEAM':
        case 'ASSIGNEE':
          return true;
        default:
          return false;
      }
    }),

    isRequiredWhenCreatingEditable: computed('field.fieldType', function () {
      switch (this.get('field.fieldType')) {
        case 'SUBJECT':
          return false;
        default:
          return true;
      }
    }),

    isCustomerVisibleEditable: computed('field.fieldType', function () {
      switch (this.get('field.fieldType')) {
        case 'SUBJECT':
        case 'ASSIGNEE':
          return false;
        default:
          return true;
      }
    }),

    isCustomerFieldTitleEditable: computed('field.fieldType', function () {
      switch (this.get('field.fieldType')) {
        case 'SUBJECT':
        case 'MESSAGE':
          return false;
        default:
          return true;
      }
    }),

    isCustomerEditableEditable: computed('field.fieldType', function () {
      switch (this.get('field.fieldType')) {
        case 'SUBJECT':
        case 'TEAM':
        case 'ASSIGNEE':
          return false;
        default:
          return true;
      }
    }),

    isCustomerRequiredEditable: computed('field.fieldType', function () {
      switch (this.get('field.fieldType')) {
        case 'SUBJECT':
        case 'TEAM':
        case 'ASSIGNEE':
        case 'STATUS':
          return false;
        default:
          return true;
      }
    }),

    isCustomerDescriptionsVisible: computed('field.fieldType', function () {
      switch (this.get('field.fieldType')) {
        case 'TEAM':
        case 'ASSIGNEE':
          return false;
        default:
          return true;
      }
    }),

    customerTitleLocale: computed('editedField.customerTitles.[]', 'locale.accountDefaultLocaleCode', function () {
      var customerTitles = this.get('editedField.customerTitles');
      return customerTitles.findBy('locale', this.get('locale.accountDefaultLocaleCode'));
    }),

    descriptionLocale: computed('editedField.descriptions.[]', 'locale.accountDefaultLocaleCode', function () {
      var descriptions = this.get('editedField.descriptions');
      return descriptions.findBy('locale', this.get('locale.accountDefaultLocaleCode'));
    }),

    optionsSortOrder: ['sortOrder'],
    orderedOptionList: computed.sort('editedField.options', 'optionsSortOrder'),

    // Tasks
    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var field;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              field = this.get('field');
              _context.next = 4;
              return this.get('virtualModel').save(field, this.get('editedField'), this.get('schema'));

            case 4:
              field.get('customerTitles').filter(function (customerTitle) {
                return customerTitle.get('isNew');
              }).forEach(function (customerTitle) {
                return customerTitle.unloadRecord();
              });
              field.get('descriptions').filter(function (description) {
                return description.get('isNew');
              }).forEach(function (description) {
                return description.unloadRecord();
              });
              field.get('options').filter(function (option) {
                return option.get('isNew');
              }).forEach(function (option) {
                return option.unloadRecord();
              });
              field.get('options').forEach(function (option) {
                option.get('values').filter(function (value) {
                  return value.get('isNew');
                }).forEach(function (value) {
                  return value.unloadRecord();
                });
              });
              this.get('onSave')();
              _context.next = 13;
              break;

            case 11:
              _context.prev = 11;
              _context.t0 = _context['catch'](0);

            case 13:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[0, 11]]);
    })).drop(),

    // Actions
    actions: {
      cancel: function cancel() {
        this.get('onCancel')();
      },
      addOption: function addOption() {
        var options = this.get('editedField.options');
        var maxSortOrder = Math.max.apply(Math, [0].concat(_toConsumableArray(options.map(function (option) {
          return option.get('sortOrder');
        }))));

        var locales = this.get('store').peekAll('locale').filterBy('isPublic', true);

        options.pushObject(EmberObject.create({
          values: locales.map(function (locale) {
            return EmberObject.create({
              locale: locale.get('locale'),
              translation: ''
            });
          }),
          sortOrder: maxSortOrder + 1
        }));
      },
      removeOption: function removeOption(option) {
        this.get('editedField.options').removeObject(option);
      },
      setCustomerTitle: function setCustomerTitle(translation) {
        var customerTitleLocale = this.get('customerTitleLocale');

        if (customerTitleLocale) {
          customerTitleLocale.set('translation', translation);
        }
      },
      setDescription: function setDescription(translation) {
        var descriptionLocale = this.get('descriptionLocale');

        if (descriptionLocale) {
          descriptionLocale.set('translation', translation);
        }
      },
      reorderList: function reorderList(list) {
        var order = 1;
        list.forEach(function (option) {
          option.set('sortOrder', order);
          order++;
        });
      },
      deleteField: function deleteField() {
        var _this = this;

        var field = this.get('field');
        var translationPrefix = this.get('customFields').getTranslationPrefix(field.constructor.modelName);

        return this.get('confirmation').confirm({
          intlConfirmationBody: translationPrefix + '.labels.delete_confirmation',
          intlConfirmationHeader: translationPrefix + '.labels.confirm_delete',
          intlConfirmLabel: 'generic.confirm.delete_button'
        }).then(function () {
          return field.destroyRecord().then(function () {
            return _this.get('onDelete')();
          });
        });
      }
    }
  });
});