define('frontend-cp/models/case-form', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  var isPresent = Ember.isPresent;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string', { defaultValue: '' }),
    customerTitle: _emberData.default.attr('string', { defaultValue: '' }),
    description: _emberData.default.attr('string', { defaultValue: '' }),
    isVisibleToCustomers: _emberData.default.attr('boolean'),
    isEnabled: _emberData.default.attr('boolean', { default: true }),
    isDeleted: _emberData.default.attr('boolean', { default: false }),
    isDefault: _emberData.default.attr('boolean', { default: false }),
    sortOrder: _emberData.default.attr('number'),
    fields: _emberData.default.hasMany('case-field', { async: false }),
    brand: _emberData.default.belongsTo('brand', { async: false }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    customerTitles: _emberData.default.hasMany('locale-field', { async: false }),
    descriptions: _emberData.default.hasMany('locale-field', { async: false }),

    canBeDisabled: computed('id', 'isDefault', function () {
      return isPresent(this.get('id')) && !this.get('isDefault');
    }),

    canBeMadeDefault: not('isDefault'),
    canBeDeleted: not('isDefault')
  });
});