define('frontend-cp/components/ko-select/multiple/internal/component', ['exports', 'frontend-cp/lib/keycodes', 'npm:string.prototype.trimleft', 'ember-basic-dropdown/utils/computed-fallback-if-undefined'], function (exports, _keycodes, _npmStringPrototype, _computedFallbackIfUndefined) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Component = Ember.Component;
  var computed = Ember.computed;
  var run = Ember.run;
  var isBlank = Ember.isBlank;
  exports.default = Component.extend({
    // Attributes
    allowCreate: false,
    allowDelete: true,
    allowAddDuringSearch: false,
    dropdownClass: null,
    extra: null,
    disabled: false,
    onChange: null,
    onValueAddition: null,
    onValueRemoval: null,
    onSuggestion: null,
    options: null,
    placeholder: '',
    searchField: 'name',
    triggerClass: null,
    renderInPlace: (0, _computedFallbackIfUndefined.default)(true),
    verticalPosition: (0, _computedFallbackIfUndefined.default)('auto'),
    selected: [],

    // HTML
    tagName: '',

    // CPs
    isEmpty: computed('onSuggestion', 'options', function () {
      return !this.get('onSuggestion') && !this.get('options');
    }),

    // Methods
    addValues: function addValues(select, searchText) {
      var _this = this;

      var names = this.get('selected').mapBy(this.get('searchField')).map(function (name) {
        return name.toLowerCase();
      });
      var newValues = searchText.split(' ').map(function (name) {
        return name.trim().toLowerCase();
      }).filter(function (name) {
        return name;
      }).filter(function (name) {
        return !names.includes(name);
      }).map(function (name) {
        return _defineProperty({}, _this.get('searchField'), name);
      });

      newValues.forEach(function (value) {
        return select.actions.choose(value);
      });

      run.scheduleOnce('afterRender', function () {
        select.actions.search('');
      });
    },


    actions: {
      createOnEnterOrTab: function createOnEnterOrTab(select, e) {
        if (select.highlighted) {
          if (e.keyCode === _keycodes.tab) {
            select.actions.choose(select.highlighted, e);
          }
        } else if (!isBlank(select.searchText)) {
          if (e.keyCode === _keycodes.enter) {
            if (select.loading && !this.get('allowAddDuringSearch')) {
              return false;
            }
            this.addValues(select, select.searchText);
            e.preventDefault();
            e.stopPropagation();
          } else if (e.keyCode === _keycodes.tab) {
            this.addValues(select, select.searchText);
          }
        }
      },
      createOnCloseClickingOutside: function createOnCloseClickingOutside(select, e) {
        if (!e || e.type !== 'mousedown') {
          return;
        }
        if (!isBlank(select.searchText) && !select.loading) {
          this.addValues(select, select.searchText);
        }
      },
      closeIfEmpty: function closeIfEmpty(select) {
        // Here component acts as a freeform multi-value input
        if (this.get('isEmpty')) {
          return false;
        }
      },
      onSearch: function onSearch(searchText, select) {
        if (this.get('allowCreate') && (0, _npmStringPrototype.default)(searchText).includes(' ')) {
          this.addValues(select, searchText);
        } else if (this.get('onSuggestion')) {
          return this.get('onSuggestion')(searchText);
        }
      },
      setValue: function setValue(newSelection) {
        var _this2 = this;

        var currentValues = this.get('selected');
        var valuesToAdd = newSelection.filter(function (value) {
          return currentValues.indexOf(value) === -1;
        });
        var valuesToRemove = currentValues.filter(function (value) {
          return newSelection.indexOf(value) === -1;
        });

        valuesToAdd.forEach(function (value) {
          return _this2.attrs.onValueAddition(value);
        });
        valuesToRemove.forEach(function (value) {
          return _this2.attrs.onValueRemoval(value);
        });
      }
    }
  });
});