define('frontend-cp/components/ko-admin/engagements/form/component', ['exports', 'frontend-cp/services/virtual-model', 'ember-basic-dropdown/utils/computed-fallback-if-undefined', 'ember-diff-attrs'], function (exports, _virtualModel, _computedFallbackIfUndefined, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;
  var service = Ember.inject.service;
  var setProperties = Ember.setProperties;


  var schema = (0, _virtualModel.model)('visitor-engagement', {
    id: (0, _virtualModel.attr)(),
    title: (0, _virtualModel.attr)(),
    executionOrder: (0, _virtualModel.attr)(),
    isEnabled: (0, _virtualModel.attr)(),
    predicateCollections: (0, _virtualModel.many)((0, _virtualModel.model)('predicate-collection', {
      id: (0, _virtualModel.attr)(),
      propositions: (0, _virtualModel.many)((0, _virtualModel.model)('proposition', {
        id: (0, _virtualModel.attr)(),
        field: (0, _virtualModel.attr)(),
        operator: (0, _virtualModel.attr)(),
        value: (0, _virtualModel.attr)()
      }))
    })),
    actions: (0, _virtualModel.many)((0, _virtualModel.model)('automation-action', {
      label: (0, _virtualModel.attr)(),
      name: (0, _virtualModel.attr)(),
      option: (0, _virtualModel.attr)(),
      value: (0, _virtualModel.attr)(),
      attributes: (0, _virtualModel.attr)()
    })),
    createdAt: (0, _virtualModel.attr)(),
    updatedAt: (0, _virtualModel.attr)()
  });

  exports.default = Component.extend({
    // Attributes
    theVisitorEngagement: null,
    definitions: null,
    timeBasedCollection: null,
    teams: (0, _computedFallbackIfUndefined.default)([]),
    agents: (0, _computedFallbackIfUndefined.default)([]),
    registerAs: function registerAs() {},
    didSave: function didSave() {},
    cancel: function cancel() {},

    // Services
    virtualModel: service(),
    confirmation: service(),
    store: service(),

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.registerAs(this);
    },


    didReceiveAttrs: (0, _emberDiffAttrs.default)('theVisitorEngagement', function (changedAttrs) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, arguments);
      if (changedAttrs && !changedAttrs.theVisitorEngagement) {
        return;
      }

      if (!changedAttrs) {
        var actions = this.get('theVisitorEngagement.actions').toArray();
        if (actions.length === 1 && !get(actions[0], 'name')) {
          setProperties(actions[0], {
            name: 'send_message',
            option: null,
            value: null,
            attributes: {}
          });
        }
      }

      this.initEdits();

      /**
       * This code populates timeBasedPropositions if there is no timeBasedCollection present.
       * Also it is decided here if 'Add a new condition' button inside collections should be shown or not
       */
      var isTimeBasedPropositionPresent = false;
      this.get('editedVisitorEngagement.predicateCollections').toArray().forEach(function (predicateCollection, index) {
        if (get(predicateCollection, 'propositions').toArray().some(function (proposition) {
          return proposition.field === 'time_on_page';
        }) || get(predicateCollection, 'propositions').toArray().some(function (proposition) {
          return proposition.field === 'time_on_site';
        })) {
          isTimeBasedPropositionPresent = true;
          _this.set('timeBasedCollection', predicateCollection);
          set(_this.get('editedVisitorEngagement.predicateCollections')[index], 'showAddNewCondition', false);
          return;
        }
        set(_this.get('editedVisitorEngagement.predicateCollections')[index], 'showAddNewCondition', true);
      });

      this.get('editedVisitorEngagement.predicateCollections').toArray().forEach(function (predicateCollection, index) {
        var definitions = _this.get('store').peekAll('definition').filterBy('group', 'ENGAGEMENT');

        if (isTimeBasedPropositionPresent && predicateCollection !== _this.get('timeBasedCollection')) {
          definitions.removeObject(_this.getDefinition('time_on_page'));
          definitions.removeObject(_this.getDefinition('time_on_site'));
        }

        if (get(predicateCollection, 'propositions').toArray().length > 1) {
          definitions.removeObject(_this.getDefinition('time_on_page'));
          definitions.removeObject(_this.getDefinition('time_on_site'));
        }

        set(predicateCollection, 'definitions', definitions);
      });
    }),

    // CPs
    canBeDeleted: computed.not('theVisitorEngagement.isNew'),

    // Actions
    actions: {
      save: function save() {
        var _this2 = this;

        var theVisitorEngagement = this.get('theVisitorEngagement');
        var editedVisitorEngagement = this.get('editedVisitorEngagement');

        return this.get('virtualModel').save(theVisitorEngagement, editedVisitorEngagement, schema).then(function () {
          theVisitorEngagement.get('predicateCollections').toArray().forEach(function (predColl) {
            if (predColl.get('isNew')) {
              predColl.get('propositions').invoke('unloadRecord');
              predColl.unloadRecord();
            } else {
              predColl.get('propositions').filterBy('isNew').invoke('unloadRecord');
            }
          });
          theVisitorEngagement.get('actions').filterBy('isNew').invoke('unloadRecord');
          _this2.set('editedVisitorEngagement', _this2.get('virtualModel').makeSnapshot(_this2.get('theVisitorEngagement'), schema));
        }).then(this.get('didSave'));
      },
      deleteVisitorEngagement: function deleteVisitorEngagement() {
        var _this3 = this;

        return this.get('confirmation').confirm({
          intlConfirmationBody: 'admin.engagements.labels.delete_confirmation',
          intlConfirmationHeader: 'admin.engagements.labels.confirm_delete',
          intlConfirmLabel: 'generic.confirm.delete_button'
        }).then(function () {
          return _this3.get('theVisitorEngagement').destroyRecord().then(function () {
            return _this3.attrs.didSave();
          });
        });
      },
      addCollection: function addCollection() {
        var definitions = this.get('store').peekAll('definition').filterBy('group', 'ENGAGEMENT');
        // .filter(definition => { return definition.get('group') === 'ENGAGEMENT'; });
        var timeOnPageDefinition = this.getDefinition('time_on_page');
        var timeOnSiteDefinition = this.getDefinition('time_on_site');

        /**
         * If timeBasedCollection is there then remove both timeBasedPropositions
         */

        if (this.get('timeBasedCollection')) {
          definitions.removeObject(timeOnPageDefinition);
          definitions.removeObject(timeOnSiteDefinition);
        }

        this.get('editedVisitorEngagement.predicateCollections').pushObject({
          propositions: [{}],
          showAddNewCondition: true,
          definitions: definitions
        });
      },
      removeCollection: function removeCollection(predicateCollection) {
        if (predicateCollection === this.get('timeBasedCollection')) {
          this.populateTimeBasedDefinitions(predicateCollection);
        }
        this.get('editedVisitorEngagement.predicateCollections').removeObject(predicateCollection);
      },
      addPropositionToCollection: function addPropositionToCollection(predicateCollection) {
        var collectionDefinitions = get(predicateCollection, 'definitions');
        get(predicateCollection, 'propositions').pushObject({});
        if (get(predicateCollection, 'propositions').length > 1) {
          var timeOnPageDefinition = this.getDefinition('time_on_page');
          var timeOnSiteDefinition = this.getDefinition('time_on_site');
          collectionDefinitions.removeObject(timeOnPageDefinition);
          collectionDefinitions.removeObject(timeOnSiteDefinition);
        }
      },
      removePropositionFromCollection: function removePropositionFromCollection(predicateCollection, proposition) {
        var collectionDefinitions = get(predicateCollection, 'definitions');
        var collectionPropositions = get(predicateCollection, 'propositions');
        get(predicateCollection, 'propositions').removeObject(proposition);

        /**
         * If length of predicateCollection becomes 1 after removing proposition
         * and there is no timeBasedCollection present then populate timeBasedDefinitions
         * in this collection
         */

        if (collectionPropositions.toArray().length === 1 && !this.get('timeBasedCollection')) {
          var timeOnPageDefinition = this.getDefinition('time_on_page');
          var timeOnSiteDefinition = this.getDefinition('time_on_site');
          var isTimeOnPageDefinitionPresent = collectionDefinitions.any(function (definition) {
            return get(definition, 'id') === 'time_on_page';
          });
          var isTimeOnSiteDefinitionPresent = collectionDefinitions.any(function (definition) {
            return get(definition, 'id') === 'time_on_site';
          });
          if (!isTimeOnPageDefinitionPresent) {
            collectionDefinitions.pushObject(timeOnPageDefinition);
          }
          if (!isTimeOnSiteDefinitionPresent) {
            collectionDefinitions.pushObject(timeOnSiteDefinition);
          }
        }
      },
      onPropositionChange: function onPropositionChange(collection, proposition) {
        if (get(proposition, 'field') === 'time_on_page' || get(proposition, 'field') === 'time_on_site') {
          set(collection, 'showAddNewCondition', false);
          this.set('timeBasedCollection', collection);
          this.removeTimeBasedPropositionsFromAllCollections();
        } else {
          set(collection, 'showAddNewCondition', true);
          this.populateTimeBasedDefinitions(collection);
        }
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedVisitorEngagement', this.get('virtualModel').makeSnapshot(this.get('theVisitorEngagement'), schema));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('theVisitorEngagement'), this.get('editedVisitorEngagement'), schema);
    },
    populateTimeBasedDefinitions: function populateTimeBasedDefinitions(collection) {
      /**
       * Populate time based propositions to those elements which are of length 1
       */
      if (collection !== this.get('timeBasedCollection')) {
        return;
      }
      var timeOnPageDefinition = this.getDefinition('time_on_page');
      var timeOnSiteDefinition = this.getDefinition('time_on_site');

      this.get('editedVisitorEngagement.predicateCollections').forEach(function (predicateCollection) {
        var collectionDefinitions = get(predicateCollection, 'definitions');
        if (collection !== predicateCollection && get(predicateCollection, 'propositions').length === 1) {
          var isTimeOnPageDefinitionPresent = collectionDefinitions.any(function (definition) {
            return get(definition, 'id') === 'time_on_page';
          });
          var isTimeOnSiteDefinitionPresent = collectionDefinitions.any(function (definition) {
            return get(definition, 'id') === 'time_on_site';
          });
          if (!isTimeOnPageDefinitionPresent) {
            collectionDefinitions.pushObject(timeOnPageDefinition);
          }
          if (!isTimeOnSiteDefinitionPresent) {
            collectionDefinitions.pushObject(timeOnSiteDefinition);
          }
        }
      });
      this.set('timeBasedCollection', null);
    },
    removeTimeBasedPropositionsFromAllCollections: function removeTimeBasedPropositionsFromAllCollections() {
      /**
       * Remove time based propositions from all collections except the one having them
       */
      var timeOnPageDefinition = this.getDefinition('time_on_page');
      var timeOnSiteDefinition = this.getDefinition('time_on_site');
      var timeBasedCollection = this.get('timeBasedCollection');
      this.get('editedVisitorEngagement.predicateCollections').forEach(function (predicateCollection) {
        if (timeBasedCollection !== predicateCollection) {
          get(predicateCollection, 'definitions').removeObject(timeOnPageDefinition);
          get(predicateCollection, 'definitions').removeObject(timeOnSiteDefinition);
        }
      });
    },
    getDefinition: function getDefinition(definitionId) {
      var definitions = this.get('store').peekAll('definition').filterBy('group', 'ENGAGEMENT');
      return definitions.findBy('id', definitionId);
    }
  });
});