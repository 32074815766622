define("frontend-cp/components/ko-on-boarding/experience/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "box": "ko-on-boarding_experience__box_ytz0lq",
    "box__number": "ko-on-boarding_experience__box__number_ytz0lq",
    "box__title": "ko-on-boarding_experience__box__title_ytz0lq",
    "box__content": "ko-on-boarding_experience__box__content_ytz0lq",
    "box__text": "ko-on-boarding_experience__box__text_ytz0lq",
    "box__description": "ko-on-boarding_experience__box__description_ytz0lq"
  };
});