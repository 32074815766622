define("frontend-cp/components/ko-profile-menu/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-profile-menu__trigger_9rv2l8",
    "dropdown": "ko-profile-menu__dropdown_9rv2l8 _dropdown_jvqwfg"
  };
});