define('frontend-cp/components/ko-channel-selector/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    channel: null,
    channels: null,
    qaClass: null,
    isNote: false,
    onclick: function onclick() {},
    onchange: function onchange() {},

    // HTML
    tagName: '',

    // CPs
    disabled: computed('isNote', 'channel.channelType', function () {
      var isNote = this.get('isNote');
      var channelType = this.get('channel.channelType');

      return isNote && channelType && channelType !== 'NOTE';
    }),

    actions: {
      handleMouseDown: function handleMouseDown(e) {
        if (this.get('disabled')) {
          e.stopPropagation();
          this.get('onclick').apply(undefined, Array.prototype.slice.call(arguments).concat([e]));
        }
      }
    }
  });
});