define('frontend-cp/helpers/group-by-day', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.groupByDay = groupByDay;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Helper = Ember.Helper;
  var get = Ember.get;
  var service = Ember.inject.service;
  var observer = Ember.observer;
  var readOnly = Ember.computed.readOnly;
  var assign = Ember.assign;
  function groupByDay(_ref) {
    var _ref3 = _slicedToArray(_ref, 1),
        items = _ref3[0];

    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        key = _ref2.key,
        timezone = _ref2.timezone;

    (false && !(key) && Ember.assert('Must pass a key e.g. (group-by-day items key="createdAt")', key));


    var result = [];

    items.forEach(function (item) {
      var dateUTC = get(item, key);
      var day = dayForDate(dateUTC, timezone);
      var entry = result.findBy('day', day);

      if (!entry) {
        entry = { day: day, items: [] };
        result.push(entry);
      }

      entry.items.push(item);
    });

    return result;
  }

  function dayForDate(date, timezone) {
    date = (0, _moment.default)(date);

    if (timezone) {
      date.tz(timezone);
    }

    return date.format('YYYY-MM-DD');
  }

  exports.default = Helper.extend({
    moment: service(),

    timezone: readOnly('moment.timeZone'),

    timezoneDidChange: observer('timezone', function () {
      this.recompute();
    }),

    compute: function compute(args) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var timezone = this.get('timezone');

      return groupByDay(args, assign({}, options, { timezone: timezone }));
    }
  });
});