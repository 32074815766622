define('frontend-cp/session/agent/cases/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    showingViewsList: true,
    views: null,
    teams: null,
    customViews: null,

    caseListTab: service('case-list-tab'),

    inboxView: readOnly('caseListTab.inboxView'),

    actions: {
      clearSelectedCaseIds: function clearSelectedCaseIds() {
        this.send('setSelectedCaseIds', []);
      },
      bulkUpdateComplete: function bulkUpdateComplete() {
        this.send('setSelectedCaseIds', []);
        this.send('refreshCaseList');
      },
      setSelectedCaseIds: function setSelectedCaseIds(checkedRows) {
        this.set('selectedCaseIds', checkedRows || []);
      }
    }
  });
});