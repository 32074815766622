define('frontend-cp/components/ko-admin/messenger-persistent/component', ['exports', 'ember-concurrency', 'frontend-cp/config/environment', 'npm:lodash', 'frontend-cp/lib/scroll-to', 'frontend-cp/session/admin/styles'], function (exports, _emberConcurrency, _environment, _npmLodash, _scrollTo, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var isEmpty = Ember.isEmpty;
  var Promise = Ember.RSVP.Promise;
  var $ = Ember.$;
  exports.default = Component.extend({
    i18n: service(),
    notification: service(),
    metricsService: service('metrics'),
    store: service(),
    confirmation: service(),

    defaultLocale: '',
    brands: [],
    messengerSettings: [],
    metrics: {},
    twitterAccounts: [],
    selectedTwitterAccount: {}, // Check
    selectedSectionId: null,
    brand: {},
    categoriesTree: [],
    activeTab: 'customize',
    primaryColor: '',
    activeSolidColor: '',
    activeGradient: '',
    activePattern: '',
    backgroundMode: '',
    replyTimeLocale: '',
    enableSuggestions: false,
    linkedBusinessHour: null,
    originalSettingsForSelectedBrand: '',
    unsavedChanges: {},
    tweets: [],

    // States
    isOnlinePresenceEnabled: true,
    isArticlesEnabled: false,
    isTwitterEnabled: false,

    titleLocaleFields: {},
    welcomeMessageLocaleFields: {},

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('registerAs')(this);

      var settings = this.get('store').peekAll('setting');
      this.set('defaultLocale', settings.findBy('key', 'account.default_language').get('value'));

      var loadInitialState = function loadInitialState() {
        _this.setInitialSelectedBrand();

        _this.set('activeTab', _this.get('hasMultipleBrands') ? 'brandSelection' : 'customize');

        _this.loadMessengerSettingsForSelectedBrand();
        _this.get('fetchTweets').perform();
        _this.get('fetchCategories').perform().then(function () {
          _this.loadArticleWidgetState();
        });
      };

      loadInitialState();
    },
    getMessengerSettingsForSelectedBrand: function getMessengerSettingsForSelectedBrand() {
      return this.get('messengerSettings').findBy('brand.id', this.get('brand.id'));
    },
    loadMessengerSettingsForSelectedBrand: function loadMessengerSettingsForSelectedBrand() {
      var messengerSetting = this.getMessengerSettingsForSelectedBrand();
      if (messengerSetting) {
        this.loadState(this.convertSettingsToState(messengerSetting));
      } else {
        this.loadState(this.defaultState());
      }
      this.set('originalSettingsForSelectedBrand', this.getSerializedSettings());
    },
    getSerializedSettings: function getSerializedSettings() {
      // This is only to be used for comparing original vs edited fields.
      // So that we can show `discard changes` dialog
      // Here we chose to discard article settings from this comparison because its state
      // is calculated async(fetchCategories task). If a user switches between brands too fast he will be incorrectly prompted
      // of `discard changes` dialog
      var settings = this.convertStateToSettings();
      delete settings.metadata.widgets.articles;
      return JSON.stringify(settings);
    },
    isEdited: function isEdited() {
      // Returns whether or not any customizations have been made for current brand
      return this.get('originalSettingsForSelectedBrand') !== this.getSerializedSettings();
    },
    createLocaleFields: function createLocaleFields() {
      var result = this.publicLocales().map(function (localeModel) {
        var locale = localeModel.get('locale');
        var localeField = EmberObject.create({ locale: locale, translation: '' });
        return localeField;
      });
      return result;
    },
    defaultTitle: function defaultTitle() {
      return this.get('i18n').t('admin.messenger.tabs.customize.title_default_value');
    },
    defaultWelcomeMessage: function defaultWelcomeMessage() {
      return this.get('i18n').t('admin.messenger.tabs.customize.welcome_default_value', { brand: this.get('brand.name') });
    },
    defaultState: function defaultState() {
      return {
        // Customize tab
        title: this.defaultTitle(),
        titleLocaleFields: this.createLocaleFields(),
        welcomeMessageLocaleFields: this.createLocaleFields(),
        welcomeMessage: this.defaultWelcomeMessage(),
        primaryColor: '#F1703F',

        activeSolidColor: '#FF3B30',
        activeGradient: '0deg, #889CFF 0%, #9BE1D3 100%',
        activePattern: '1',
        backgroundMode: 'gradient',

        // Widgets
        isOnlinePresenceEnabled: true,
        isArticlesEnabled: false,
        selectedSectionId: this.getPreSelectedSection(),
        isTwitterEnabled: false,
        selectedTwitterAccount: this.getPreSelectedTwitterAccount(),

        // Options
        replyTimeLocale: '',
        linkedBusinessHour: null,
        enableSuggestions: false
      };
    },
    loadState: function loadState(state) {
      var _this2 = this;

      Object.keys(state).forEach(function (property) {
        return _this2.set(property, state[property]);
      });
    },
    getArticleState: function getArticleState(articles) {
      var _this3 = this;

      // takes in article widget settings
      // To be called when sections for current brand have been fetched
      var sectionExists = function sectionExists(sectionId) {
        var categories = _this3.get('categoriesTree');
        return _npmLodash.default.find(categories, function (category) {
          return !!_npmLodash.default.find(category.children, { id: sectionId });
        });
      };

      var isArticlesEnabled = articles.enabled && sectionExists(articles.sectionId);
      return {
        isArticlesEnabled: isArticlesEnabled,
        selectedSectionId: isArticlesEnabled ? articles.sectionId : this.getPreSelectedSection()
      };
    },
    getReplyTimeLocale: function getReplyTimeLocale(replyTimeExpectation) {
      if (!replyTimeExpectation) {
        return '';
      }
      var expectationToLocaleMap = {
        AUTO: '',
        ASAP: 'reply.asap',
        FEW_MINS: 'reply.in.few.minutes',
        FEW_HOURS: 'reply.in.few.hours'
      };
      return expectationToLocaleMap[replyTimeExpectation];
    },
    getReplyTimeExpectation: function getReplyTimeExpectation(replyTimeLocale) {
      var localeToExpecationMap = {
        '': 'AUTO',
        'reply.asap': 'ASAP',
        'reply.in.few.minutes': 'FEW_MINS',
        'reply.in.few.hours': 'FEW_HOURS'
      };
      return localeToExpecationMap[replyTimeLocale];
    },
    extractLocaleTranslationFromSettings: function extractLocaleTranslationFromSettings(settings, fieldName, locale) {
      var localeField = settings.get(fieldName).findBy('locale', locale);
      return localeField ? localeField.get('translation') : '';
    },
    convertSettingsToState: function convertSettingsToState(settings) {
      var _this4 = this;

      // This function recovers the component state from messenger settings
      var state = {};

      // wherever necessary we can fallback to default settings if needed
      var defaultState = this.defaultState();

      // home titles
      var titleLocaleFields = this.createLocaleFields();
      titleLocaleFields.forEach(function (localeField) {
        var locale = localeField.get('locale');
        var translation = _this4.extractLocaleTranslationFromSettings(settings, 'homeTitles', locale);

        if (locale !== _this4.get('defaultLocale')) {
          localeField.set('translation', translation);
          return;
        }

        if (translation) {
          state.title = translation;
          return;
        }

        state.title = _this4.defaultTitle();
      });
      state.titleLocaleFields = titleLocaleFields;

      // home subtitles
      var welcomeMessageLocaleFields = this.createLocaleFields();
      welcomeMessageLocaleFields.forEach(function (localeField) {
        var locale = localeField.get('locale');
        var translation = _this4.extractLocaleTranslationFromSettings(settings, 'homeSubtitles', locale);

        if (locale !== _this4.get('defaultLocale')) {
          localeField.set('translation', translation);
          return;
        }

        if (translation) {
          state.welcomeMessage = translation;
          return;
        }

        state.welcomeMessage = _this4.defaultWelcomeMessage();
      });
      state.welcomeMessageLocaleFields = welcomeMessageLocaleFields;

      var metadata = settings.get('metadata');
      var widgets = metadata.widgets,
          styles = metadata.styles;

      // widgets

      state.isOnlinePresenceEnabled = widgets.presence.enabled;

      var articles = widgets.articles,
          twitter = widgets.twitter;

      // Article sections need to be fetched async and validated
      // To quicken up the UI we load default settings here and then overwrite them later

      state.isArticlesEnabled = articles.enabled;
      state.selectedSectionId = defaultState.selectedSectionId;

      var getTwitterAccount = function getTwitterAccount(handle) {
        return _this4.get('twitterAccounts').findBy('id', handle);
      };

      state.isTwitterEnabled = twitter.enabled && getTwitterAccount(twitter.twitterHandle) ? true : false;
      state.selectedTwitterAccount = state.isTwitterEnabled ? getTwitterAccount(twitter.twitterHandle) : this.getPreSelectedTwitterAccount();

      // styles
      state.primaryColor = styles.primaryColor;

      var homeBackground = styles.homeBackground,
          homePattern = styles.homePattern;


      state.activeGradient = defaultState.activeGradient;
      state.activeSolidColor = defaultState.activeSolidColor;

      var isSolidColor = /^#[0-9A-F]{6}$/i.test(homeBackground);

      // apply settings
      if (isSolidColor) {
        state.activeSolidColor = homeBackground;
        state.backgroundMode = 'color';
      } else {
        state.activeGradient = homeBackground;
        state.backgroundMode = 'gradient';
      }

      if (homePattern === '') {
        state.activePattern = false;
      } else {
        state.activePattern = homePattern.replace(_environment.default.messengerAssetsUrl, '')[0];
      }

      state.replyTimeLocale = this.getReplyTimeLocale(settings.get('replyTimeExpectation'));
      state.linkedBusinessHour = settings.get('businesshour') || defaultState.linkedBusinessHour;
      state.enableSuggestions = settings.get('enableSuggestions');

      return state;
    },
    getBusinessHourId: function getBusinessHourId() {
      var businesshour = this.get('linkedBusinessHour');
      return businesshour ? Number(businesshour.get('id')) : null;
    },
    convertStateToSettings: function convertStateToSettings() {
      var settings = {
        reply_time_expectation: this.getReplyTimeExpectation(this.get('replyTimeLocale')),
        enable_suggestions: Boolean(this.get('enableSuggestions')),
        home_titles: this.getLocaleFieldValues('titleLocaleFields', 'title'),
        home_subtitles: this.getLocaleFieldValues('welcomeMessageLocaleFields', 'welcomeMessage'),
        businesshour_id: this.getBusinessHourId(),
        metadata: {
          widgets: {
            presence: {
              enabled: this.get('isOnlinePresenceEnabled')
            },
            twitter: {
              enabled: this.get('isTwitterEnabled'),
              twitterHandle: this.get('selectedTwitterAccount.id') || null
            },
            articles: {
              enabled: Boolean(this.get('isArticlesEnabled')),
              sectionId: this.get('selectedSectionId')
            }
          },
          styles: {
            primaryColor: this.get('primaryColor'),
            homeBackground: this.get('backgroundMode') === 'color' ? this.get('activeSolidColor') : this.get('activeGradient'),
            homePattern: this.get('patternUrl') ? this.get('patternUrl') : '',
            homeTextColor: this.get('isLightText') ? '#FFFFFF' : '#2D3138'
          }
        }
      };

      return settings;
    },


    // CPs
    hasMultipleBrands: computed.gt('brands.length', 1),

    isTwitterConnected: computed.readOnly('twitterAccounts.length'),

    fetchCategories: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var limit, store, brandId, categories, categoryIds, sections, tree;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              limit = 999;
              store = this.get('store');
              brandId = this.get('brand.id');
              _context.next = 5;
              return store.query('category', { brandId: brandId, limit: limit });

            case 5:
              categories = _context.sent;

              if (!isEmpty(categories)) {
                _context.next = 9;
                break;
              }

              this.set('categoriesTree', []);
              return _context.abrupt('return');

            case 9:
              categoryIds = categories.mapBy('id').join(',');
              _context.next = 12;
              return store.query('section', { categoryIds: categoryIds, limit: limit });

            case 12:
              sections = _context.sent;
              tree = [];


              sections.forEach(function (section) {
                var category = section.get('category');
                var sectionNode = {
                  id: section.get('id'),
                  value: section.get('title')
                };
                var categoryNode = tree.findBy('value', category.get('title'));

                if (!categoryNode) {
                  categoryNode = {
                    value: category.get('title'),
                    children: []
                  };

                  tree.push(categoryNode);
                }

                categoryNode.children.push(sectionNode);
              });

              this.set('categoriesTree', tree);

            case 16:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    fetchTweets: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var id, tweets;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              id = this.get('selectedTwitterAccount.id');

              if (id) {
                _context2.next = 3;
                break;
              }

              return _context2.abrupt('return');

            case 3:
              _context2.next = 5;
              return this.get('store').query('twitter-tweet', {
                twitterIds: id
              });

            case 5:
              tweets = _context2.sent;

              this.set('tweets', tweets);

            case 7:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).restartable(),

    hasNoSections: computed.not('categoriesTree.length'),

    articles: computed('selectedSectionId', function () {
      var store = this.get('store');
      var section_id = this.get('selectedSectionId');
      var limit = 3;

      if (section_id) {
        return store.query('article', { section_id: section_id, limit: limit });
      } else {
        return [];
      }
    }),

    isDarkSolidColor: computed('activeSolidColor', function () {
      var solidColor = this.get('activeSolidColor');
      if (this.getColorDarkness(solidColor) < 172) {
        return true;
      }
    }),

    isDarkGradient: computed('activeGradient', function () {
      var gradientValue = this.get('activeGradient');
      if (gradientValue) {
        var colors = gradientValue.match(/#\w{6}/g);
        var lightness1 = this.getColorDarkness(colors[0]);
        var lightness2 = this.getColorDarkness(colors[1]);

        if (lightness1 < 135 && lightness2 < 190) {
          return true;
        }
      }
    }),

    isLightText: computed('isDarkSolidColor', 'isDarkGradient', 'backgroundMode', function () {
      var mode = this.get('backgroundMode');
      var darkGradient = this.get('isDarkGradient');
      var darkSolidColor = this.get('isDarkSolidColor');

      return mode === 'gradient' && darkGradient || mode === 'color' && darkSolidColor;
    }),

    publicLocales: function publicLocales() {
      return this.get('store').peekAll('locale').filterBy('isPublic');
    },


    patternUrl: computed('activePattern', 'activeSolidColor', 'activeGradient', 'backgroundMode', function () {
      var mode = this.get('backgroundMode');
      var suffix = '';
      var url = '';

      if (this.get('activePattern')) {
        if (mode === 'gradient' && this.get('isDarkGradient')) {
          suffix = '--dark';
        } else if (mode === 'color') {
          if (this.getColorDarkness(this.get('activeSolidColor')) > 190) {
            suffix = '--dark';
          }
        }
        url = '' + _environment.default.messengerAssetsUrl + this.get('activePattern') + suffix + '.svg';
      }
      return url;
    }),

    getLocaleFieldValues: function getLocaleFieldValues(fieldName, defaultLocaleFieldValue) {
      var _this5 = this;

      var fields = this.get(fieldName);
      if (!fields) return;
      var defaultLocale = this.get('defaultLocale');
      return fields.map(function (locale) {
        var translation = locale.get('translation') ? locale.get('translation') : '';
        return {
          locale: locale.get('locale'),
          translation: defaultLocale === locale.get('locale') ? _this5.get(defaultLocaleFieldValue) : translation
        };
      });
    },


    generatedCode: computed('brand.{domain,name,subDomain}', function () {
      var apiUrl = 'https://' + this.get('brand.subDomain') + '.' + this.get('brand.domain') + '/api/v1';
      // Escaping the quotes from brand name since they can mess up the embed code.
      return '<script>(function(d,a){function c(){var b=d.createElement("script");b.async=!0;b.type="text/javascript";b.src=a._settings.messengerUrl;b.crossOrigin="anonymous";var c=d.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}window.kayako=a;a.readyQueue=[];a.newEmbedCode=!0;a.ready=function(b){a.readyQueue.push(b)};a._settings={apiUrl:"' + apiUrl + '",messengerUrl:"https://' + this.get('brand.subDomain') + '.kayakocdn.com/messenger",realtimeUrl:"' + _environment.default.kreSocket + '"};window.attachEvent?window.attachEvent("onload",c):window.addEventListener("load",c,!1)})(document,window.kayako||{});</script>';
    }),

    getTabNumberLocale: function getTabNumberLocale(tabName) {
      // default tab positions
      var lookup = {
        brandSelection: 1, // Only visible for multiple brands. Should always be the first tab, other tabs must adjust if this one is visible.
        customize: 1,
        widgets: 2,
        options: 3,
        embed: 4
      };

      var number = lookup[tabName];
      if (typeof number !== 'number') {
        throw 'Tab not found';
      }

      if (tabName !== 'brandSelection' && this.get('hasMultipleBrands')) {
        number++;
      }

      return this.get('i18n').t(this.getNumberLocale(number));
    },

    getNumberLocale: function getNumberLocale(number) {
      // Why are we using locales for numbers?
      var localePrefix = 'admin.messenger.tabNumbers';
      var lookup = ['one', 'two', 'three', 'four', 'five'];
      return localePrefix + '.' + lookup[number - 1];
    },


    // returns first section of first category in the dropdown
    getPreSelectedSection: function getPreSelectedSection() {
      var firstSectionId = this.get('categoriesTree.firstObject.children.firstObject.id');
      return firstSectionId ? firstSectionId : '';
    },


    // Pre-selects first available twitter account
    getPreSelectedTwitterAccount: function getPreSelectedTwitterAccount() {
      if (this.get('twitterAccounts.length')) {
        return this.get('twitterAccounts.firstObject');
      }
    },
    getColorDarkness: function getColorDarkness(hexCode) {
      var hex = hexCode.replace('#', '');
      var r = parseInt(hex.substring(0, 2), 16);
      var g = parseInt(hex.substring(2, 4), 16);
      var b = parseInt(hex.substring(4, 6), 16);
      return Math.round((parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000);
    },
    setInitialSelectedBrand: function setInitialSelectedBrand() {
      this.set('brand', this.get('brands').findBy('isDefault', true));
    },
    loadArticleWidgetState: function loadArticleWidgetState() {
      var messengerSettings = this.getMessengerSettingsForSelectedBrand();
      if (messengerSettings) {
        var articles = messengerSettings.get('metadata').widgets.articles;

        this.loadState(this.getArticleState(articles));
      } else {
        this.loadState({ selectedSectionId: this.getPreSelectedSection() });
      }
    },
    reloadMessengerSettingsStore: function reloadMessengerSettingsStore() {
      var _this6 = this;

      this.get('store').query('messenger-setting', {}).then(function (result) {
        _this6.set('messengerSettings', result);
      });
    },


    saveSettings: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var _this7 = this;

      var brandId, settings;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              brandId = Number(this.get('brand.id'));
              settings = this.convertStateToSettings();
              _context3.next = 4;
              return Promise.all([this.publishToHelpcenter(), this.get('store').adapterFor('messenger-setting').saveMessengerSettings(brandId, settings).then(function () {
                return _this7.reloadMessengerSettingsStore();
              })]);

            case 4:

              this.get('notification').add({
                type: 'success',
                title: '' + this.get('i18n').t('admin.messenger.tabs.options.save_success'),
                autodismiss: true
              });

              this.set('activeTab', 'embed');
              this.scrollToTopOfTab();
              this.set('originalSettingsForSelectedBrand', this.getSerializedSettings());

            case 8:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    publishToHelpcenter: function publishToHelpcenter() {
      var brandId = this.get('brand.id');
      var name = 'helpcenter_kayako_messenger';
      var contents = this.get('generatedCode');

      return this.get('store').adapterFor('template').updateTempalte(brandId, name, contents);
    },
    scrollToTopOfTab: function scrollToTopOfTab() {
      (0, _scrollTo.scrollTo)({ position: _scrollTo.TOP, parent: $('.' + _styles.default['content-container']), animated: true });
    },
    switchBrand: function switchBrand(brand) {
      var _this8 = this;

      this.set('brand', brand);
      this.loadMessengerSettingsForSelectedBrand();
      this.get('fetchCategories').perform().then(function () {
        _this8.loadArticleWidgetState();
      });
    },


    actions: {
      switchTab: function switchTab(activeTab) {
        this.set('activeTab', activeTab);
        this.scrollToTopOfTab();
      },
      brandChanged: function brandChanged(brand) {
        var _this9 = this;

        if (!this.isEdited()) {
          this.switchBrand(brand);
          return;
        }
        this.get('confirmation').confirm({
          intlConfirmationHeader: 'generic.confirm.lose_changes_header',
          intlConfirmationBody: 'admin.messenger.confirm_lose_changes',
          intlConfirmLabel: 'generic.confirm.lose_changes_button'
        }).then(function () {
          _this9.switchBrand(brand);
        });
      },
      setSection: function setSection(sectionNode) {
        this.set('selectedSectionId', get(sectionNode, 'id'));
      },
      twitterAccountChanged: function twitterAccountChanged(account) {
        this.set('selectedTwitterAccount', account);
        this.get('fetchTweets').perform();
      },
      copiedToClipboard: function copiedToClipboard() {
        this.get('metricsService').trackEvent({
          event: 'Messenger - Copy to clipboard',
          category: 'Admin'
        });

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.copied_to_clipboard'),
          autodismiss: true
        });
      }
    }
  });
});