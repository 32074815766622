define("frontend-cp/components/ko-channel-selector/selected-channel/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-channel-selector_selected-channel__container_1pva8i",
    "text": "ko-channel-selector_selected-channel__text_1pva8i",
    "dormant": "ko-channel-selector_selected-channel__dormant_1pva8i"
  };
});