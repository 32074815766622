define("frontend-cp/components/ko-agent-dropdown/layout/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "header": "ko-agent-dropdown_layout__header_42ew4z",
    "contents": "ko-agent-dropdown_layout__contents_42ew4z",
    "title": "ko-agent-dropdown_layout__title_42ew4z",
    "footer": "ko-agent-dropdown_layout__footer_42ew4z"
  };
});