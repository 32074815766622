define('frontend-cp/services/tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    store: service('store'),

    getTagByName: function getTagByName(tagName) {
      var tag = this.get('store').peekAll('tag').find(function (tag) {
        return tag.get('name') === tagName;
      });
      return tag ? tag : this.get('store').createRecord('tag', { name: tagName });
    }
  });
});