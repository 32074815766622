define("frontend-cp/components/ko-entity-list/sidebar/proposition-logic-operator/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "proposition-operator": "ko-entity-list_sidebar_proposition-logic-operator__proposition-operator_iy7kct",
    "pill": "ko-entity-list_sidebar_proposition-logic-operator__pill_iy7kct",
    "green-pill": "ko-entity-list_sidebar_proposition-logic-operator__green-pill_iy7kct ko-entity-list_sidebar_proposition-logic-operator__pill_iy7kct",
    "orange-pill": "ko-entity-list_sidebar_proposition-logic-operator__orange-pill_iy7kct ko-entity-list_sidebar_proposition-logic-operator__pill_iy7kct",
    "chevron": "ko-entity-list_sidebar_proposition-logic-operator__chevron_iy7kct"
  };
});