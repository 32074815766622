define("frontend-cp/components/ko-trial-notification/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "sandbox-trigger": "ko-trial-notification__sandbox-trigger_3r5ysr",
    "sandbox-text": "ko-trial-notification__sandbox-text_3r5ysr",
    "sandbox-days": "ko-trial-notification__sandbox-days_3r5ysr",
    "sandbox-dropdown": "ko-trial-notification__sandbox-dropdown_3r5ysr",
    "list-action-item": "ko-trial-notification__list-action-item_3r5ysr",
    "list-item": "ko-trial-notification__list-item_3r5ysr",
    "seperator": "ko-trial-notification__seperator_3r5ysr",
    "upgrade": "ko-trial-notification__upgrade_3r5ysr",
    "upgrade-text": "ko-trial-notification__upgrade-text_3r5ysr",
    "upgrade-action": "ko-trial-notification__upgrade-action_3r5ysr"
  };
});