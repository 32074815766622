define("frontend-cp/components/ko-table/column/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-table_column__container_d89rse",
    "sortable": "ko-table_column__sortable_d89rse",
    "dense": "ko-table_column__dense_d89rse",
    "ellipsis": "ko-table_column__ellipsis_d89rse",
    "selected": "ko-table_column__selected_d89rse"
  };
});