define('frontend-cp/components/ko-insights/charts/sla-resolution-target/component', ['exports', 'npm:lodash', 'frontend-cp/components/ko-insights/highcharts/kayako-theme', 'frontend-cp/components/ko-insights/highcharts/default-options', 'frontend-cp/components/ko-insights/highcharts/tooltip-formatters/percent'], function (exports, _npmLodash, _kayakoTheme, _defaultOptions, _percent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    i18n: service(),
    insights: service(),

    data: null,
    interval: null,

    isSingleInterval: computed.equal('data.interval_count', 1),

    chartOptions: computed('isSingleInterval', function () {
      var markerEnabled = this.get('isSingleInterval');
      var i18n = this.get('i18n');

      return _npmLodash.default.merge(_npmLodash.default.cloneDeep((0, _defaultOptions.default)(i18n)), {
        yAxis: [{
          labels: {
            format: '{value:.,0f}%'
          },
          tickInterval: 20,
          min: 0,
          max: 100
        }],
        tooltip: {
          formatter: (0, _percent.default)(i18n)
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: markerEnabled
            }
          },
          line: {
            marker: {
              enabled: markerEnabled
            }
          },
          area: {
            marker: {
              enabled: markerEnabled
            }
          }
        }
      });
    }),

    content: computed('data', function () {
      var data = this.get('data');
      var i18n = this.get('i18n');
      var interval = this.get('interval');
      var insights = this.get('insights');

      var _insights$prepareSeri = insights.prepareSeriesData(data.series.data, data.series.previous, data.start_at, data.end_at, data.previous_start_at, interval),
          point = _insights$prepareSeri.point,
          pointInterval = _insights$prepareSeri.pointInterval,
          zones = _insights$prepareSeri.zones,
          current = _insights$prepareSeri.current,
          previous = _insights$prepareSeri.previous;

      return [{
        name: i18n.t('insights.chart.current_period'),
        color: _kayakoTheme.default.colors[0],
        data: current,
        pointStart: point,
        pointInterval: pointInterval,
        type: 'area',
        start_at: data.start_at,
        end_at: data.end_at
      }, {
        name: i18n.t('insights.chart.previous_period'),
        color: _kayakoTheme.default.colors[1],
        data: previous,
        pointStart: point,
        pointInterval: pointInterval,
        type: 'line',
        zoneAxis: 'x',
        zones: zones,
        start_at: data.previous_start_at,
        end_at: data.previous_end_at
      }];
    })
  });
});