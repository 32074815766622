define("frontend-cp/session/admin/manage/views/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "session_admin_manage_views_index__title_1f0pgl",
    "caption": "session_admin_manage_views_index__caption_1f0pgl"
  };
});