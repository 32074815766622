define("frontend-cp/components/ko-case-content/cc/above/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "center": "ko-case-content_cc_above__center_3rvgkm",
    "mailbox-cc": "ko-case-content_cc_above__mailbox-cc_3rvgkm",
    "mailbox-cc--title": "ko-case-content_cc_above__mailbox-cc--title_3rvgkm",
    "mailbox-cc--subtitle": "ko-case-content_cc_above__mailbox-cc--subtitle_3rvgkm"
  };
});