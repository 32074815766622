define('frontend-cp/components/ko-insights/section/cases/component', ['exports', 'frontend-cp/lib/computed/humanize-seconds'], function (exports, _humanizeSeconds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    onDateRangeChange: function onDateRangeChange() {},

    // Attrs
    startAt: null,
    endAt: null,
    interval: null,
    casesCompletion: null,
    casesResponse: null,
    casesResolution: null,

    casesCompletionMetric: readOnly('casesCompletion.metric'),

    casesResponseMetricValue: (0, _humanizeSeconds.default)('casesResponse.metric.value'),
    casesResponseMetricPrevious: (0, _humanizeSeconds.default)('casesResponse.metric.previous'),
    casesResolutionMetricValue: (0, _humanizeSeconds.default)('casesResolution.metric.value'),
    casesResolutionMetricPrevious: (0, _humanizeSeconds.default)('casesResolution.metric.previous')
  });
});