define("frontend-cp/components/ko-identities/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-identities__container_1yqelv",
    "list": "ko-identities__list_1yqelv",
    "heading": "ko-identities__heading_1yqelv"
  };
});