define('frontend-cp/helpers/ko-is-email', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.koIsEmail = koIsEmail;
  var helper = Ember.Helper.helper;
  var typeOf = Ember.typeOf;
  function koIsEmail(params /*, hash*/) {
    var term = void 0;
    switch (typeOf(params)) {
      case 'array':
        term = params[0];
        break;
      case 'string':
        term = params;
        break;
      default:
        return false;
    }

    return (/\S+@\S+\.\S+/.test(term)
    );
  }

  exports.default = helper(koIsEmail);
});