define('frontend-cp/session/agent/insights/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    notification: service(),
    insights: service(),
    session: service(),
    permissions: service(),

    currentUser: readOnly('session.user'),
    userPermissions: readOnly('session.permissions'),

    queryParams: {
      startAt: { refreshModel: true },
      endAt: { refreshModel: true },
      interval: { refreshModel: true },
      trial: { refreshModel: true }
    },

    _transitionToCases: function _transitionToCases(transition) {
      if (transition.targetName === 'session.agent.insights.index') {
        this.transitionTo('session.agent.insights.general.cases');
      }
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('permissions').has('insights.access', this.get('currentUser'))) {
        this.transitionTo('session.agent');
      } else {
        this._transitionToCases(transition);
      }
    },


    resetController: function resetController(controller, isExiting, transition) {
      this._super(arguments);

      if (isExiting) {
        controller.set('trial', null);
        this.get('notification').removeAll();
        this.get('insights').restoreTrialNotification();
      }
    },

    actions: {
      willTransition: function willTransition(transition) {
        this._transitionToCases(transition);
      }
    }
  });
});