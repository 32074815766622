define('frontend-cp/components/ko-insights/charts/messages-received-by-channel/component', ['exports', 'npm:lodash', 'frontend-cp/components/ko-insights/highcharts/default-options', 'frontend-cp/components/ko-insights/highcharts/tooltip-formatters/stacked'], function (exports, _npmLodash, _defaultOptions, _stacked) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var COLOR_BY_CHANNEL = {
    FACEBOOK: '#3b5998',
    TWITTER: '#4099FF',
    MESSENGER: '#6dd9c2',
    CALL: '#57BE42',
    MAIL: '#f28068',
    HELPCENTER: '#f9c821'
  };

  exports.default = Component.extend({
    tagName: '',

    i18n: service(),
    insights: service(),

    data: null,
    interval: null,

    isSingleInterval: computed.equal('data.interval_count', 1),

    chartOptions: computed('isSingleInterval', function () {
      var i18n = this.get('i18n');
      var markerEnabled = this.get('isSingleInterval');

      return _npmLodash.default.merge(_npmLodash.default.cloneDeep((0, _defaultOptions.default)(i18n)), {
        chart: {
          type: 'area',
          height: 400,
          spacingTop: 20,
          marginTop: 20
        },
        yAxis: [{
          title: {
            text: null
          },
          gridLineDashStyle: 'Dot',
          min: 0,
          showFirstLabel: true
        }],
        tooltip: {
          formatter: (0, _stacked.default)(i18n)
        },
        legend: {
          labelFormat: '{name}',
          shadow: false,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
          floating: false,
          itemMarginBottom: 0,
          x: 0,
          y: 0
        },
        plotOptions: {
          area: {
            marker: {
              enabled: markerEnabled
            },
            stacking: 'normal',
            dataLabels: {
              enabled: false
            }
          }
        }
      });
    }),

    content: computed('data', function () {
      var i18n = this.get('i18n');
      var data = this.get('data');
      var interval = this.get('interval');
      var insights = this.get('insights');
      var allowedChannels = Object.keys(COLOR_BY_CHANNEL);

      var _insights$prepareSeri = insights.prepareSeriesData(null, null, data.start_at, data.end_at, null, interval),
          point = _insights$prepareSeri.point,
          pointInterval = _insights$prepareSeri.pointInterval;

      return data.channel_series.filter(function (channel) {
        return allowedChannels.indexOf(channel.channel) > -1;
      }).map(function (channel) {
        return {
          name: i18n.t('insights.chart.channel_stats.' + channel.channel),
          data: channel.series.data,
          color: COLOR_BY_CHANNEL[channel.channel] ? COLOR_BY_CHANNEL[channel.channel] : null,
          type: 'area',
          pointStart: point,
          pointInterval: pointInterval,
          visible: channel.channel !== 'MESSENGER'
        };
      });
    })
  });
});