define("frontend-cp/components/ko-case-content/field/assignee/trigger-value/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-case-content_field_assignee_trigger-value__trigger_oixpij",
    "separator": "ko-case-content_field_assignee_trigger-value__separator_oixpij",
    "agent": "ko-case-content_field_assignee_trigger-value__agent_oixpij",
    "avatar": "ko-case-content_field_assignee_trigger-value__avatar_oixpij",
    "agent-name": "ko-case-content_field_assignee_trigger-value__agent-name_oixpij",
    "team": "ko-case-content_field_assignee_trigger-value__team_oixpij"
  };
});