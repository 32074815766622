define('frontend-cp/components/ko-entity-list/sidebar/collection-logic-operator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    collectionOperators: null,
    currentCollectionOperators: null,

    // State
    focused: false,

    // HTML
    focusIn: function focusIn() {
      this.set('focused', true);
    },
    focusOut: function focusOut() {
      this.set('focused', false);
    }
  });
});