define('frontend-cp/serializers/application', ['exports', 'ember-data', 'npm:lodash'], function (exports, _emberData, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var service = Ember.inject.service;
  var makeArray = Ember.makeArray;
  var underscore = Ember.String.underscore;
  var assign = Ember.assign;


  var errorCodes = ['FIELD_REQUIRED', 'FIELD_DUPLICATE', 'FIELD_EMPTY', 'FIELD_INVALID'];
  var errorMessages = _npmLodash.default.fromPairs(errorCodes.map(function (e) {
    return [e, 'generic.error.' + e.toLowerCase()];
  }));
  var isValidationError = function isValidationError(e) {
    return errorCodes.includes(e.code);
  };
  var inflector = new Ember.Inflector(Ember.Inflector.defaultRules);

  exports.default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,

    i18n: service(),

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var resourceName = payload.resource;

      payload = this._normalizePayload(payload);

      // Ensures only one record is returned for queryRecord:
      // DEPRECATION: The adapter returned an array for the primary data of a `queryRecord` response.
      // This is deprecated as `queryRecord` should return a single record.
      // [deprecation id: ds.serializer.rest.queryRecord-array-response]
      if (requestType === 'queryRecord') {
        var primaryKey = inflector.pluralize(resourceName);
        var primaryData = payload[primaryKey];
        payload[inflector.singularize(resourceName)] = primaryData[0];
        Reflect.deleteProperty(payload, primaryKey);
      }

      return this._super.apply(this, arguments);
    },
    keyForAttribute: function keyForAttribute(key /*, method*/) {
      return underscore(key);
    },
    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      modelClass.eachRelationship(function (name, relationship) {
        var underscoredName = underscore(name);
        if (resourceHash[underscoredName]) {
          if (relationship.kind === 'belongsTo') {
            var data = resourceHash[underscoredName];
            if (!data.hasOwnProperty('id') || !data.hasOwnProperty('type') || Object.keys(data).length !== 2) {
              resourceHash[underscoredName] = { id: data.id, type: data.resource_type };
            }
          } else {
            // has many
            resourceHash[underscoredName].forEach(function (entry) {
              entry.type = entry.resource_type;
              Reflect.deleteProperty(entry, 'resource_type');
            });
          }
        }
      });
      return this._super.apply(this, arguments);
    },
    extractMeta: function extractMeta(store, typeClass, payload) {
      if (payload.meta) {
        if (typeof payload.meta.total_count !== 'undefined') {
          payload.meta.total = payload.meta.total_count;
          Reflect.deleteProperty(payload.meta, 'total_count');
        }
        if (typeof payload.meta.next_url !== 'undefined') {
          payload.meta.next = payload.meta.next_url;
          Reflect.deleteProperty(payload.meta, 'next_url');
        }
      }
      return this._super.apply(this, arguments);
    },
    keyForRelationship: function keyForRelationship(key, relationship, method) {
      if (!method || method === 'serialize') {
        return underscore(key) + (relationship === 'belongsTo' ? '_id' : '');
      } else {
        return underscore(key);
      }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, snapshot, options) {
      assign(hash, this.serialize(snapshot, options));
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      var customTypePropertyName = underscore(snapshot.modelName) + '_type';

      if (json.hasOwnProperty(customTypePropertyName)) {
        json.type = json[customTypePropertyName];
        Reflect.deleteProperty(json, customTypePropertyName);
      }
      if (json.hasOwnProperty('viewNotes')) {
        Reflect.deleteProperty(json, 'viewNotes');
      }
      return json;
    },
    extractErrors: function extractErrors(store, typeClass, payload, id) {
      var _this = this;

      if (payload && (typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) === 'object' && payload.errors) {
        var messageForError = function messageForError(e) {
          return _this.get('i18n').t(errorMessages[e.code]);
        };
        var errors = [];
        payload.errors.forEach(function (error) {
          if (isValidationError(error)) {
            errors.push({
              detail: messageForError(error),
              source: {
                pointer: 'data/attributes/' + _this._formatJSONPointer(error.parameter)
              },
              parameter: error.parameter
            });
          }
        });
        payload.errors = errors;
      }
      return this._super.apply(this, arguments);
    },
    _formatJSONPointer: function _formatJSONPointer(pointer) {
      // ember data breaks with .s - just change how we look for them
      pointer = pointer.replace('.', '-');

      // API returns arrays as <relationshipName>[<index>] - JSONPointer expects it as a slash - <relationshipName>/<index>
      pointer = pointer.replace('[', '/').replace(']', '');
      return pointer;
    },
    pushPayload: function pushPayload(store, payload) {
      payload = this._normalizePayload(payload);
      this._super(store, payload);
    },
    _normalizePayload: function _normalizePayload(payload) {
      payload[inflector.pluralize(payload.resource)] = makeArray(payload.data);
      Reflect.deleteProperty(payload, 'status');
      Reflect.deleteProperty(payload, 'resource');
      Reflect.deleteProperty(payload, 'logs');

      Object.keys(payload.resources || {}).forEach(function (modelType) {
        payload[inflector.pluralize(modelType)] = Object.values(payload.resources[modelType]);
      });

      Reflect.deleteProperty(payload, 'resources');
      Reflect.deleteProperty(payload, 'data');
      Object.keys(payload).forEach(function (key) {
        var value = payload[key];
        if (Array.isArray(value)) {
          value.forEach(function (resource) {
            if (resource.hasOwnProperty('resource_type')) {
              if (resource.hasOwnProperty('type')) {
                resource[resource.resource_type + '_type'] = resource.type;
              }
              resource.type = resource.resource_type;
              Reflect.deleteProperty(resource, 'resource_type');
            }
          });
        }
      });
      var hasMeta = false;
      var meta = ['total_count', 'offset', 'next_url', 'limit'].reduce(function (meta, attrName) {
        if (payload.hasOwnProperty(attrName)) {
          meta[attrName] = payload[attrName];
          Reflect.deleteProperty(payload, attrName);
          hasMeta = true;
        }
        return meta;
      }, {});
      if (hasMeta) {
        payload.meta = meta;
      }
      return payload;
    }
  });
});