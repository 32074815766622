define('frontend-cp/app', ['exports', 'frontend-cp/resolver', 'ember-load-initializers', 'frontend-cp/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Application = Ember.Application;
  var LinkComponent = Ember.LinkComponent;


  // Set CSRF token received from kayako website
  var regex = /^#CsrfToken=(.+)/;
  var matches = window.location.hash.match(regex);
  var csrfToken = matches && matches[1];

  if (csrfToken) {
    localStorage.setItem(_environment.default.localStore.prefix + ':' + _environment.default.localStore.defaultNamespace + ':csrf', JSON.stringify(csrfToken));

    // Remove CSRF hash param from URL
    history.replaceState(undefined, undefined, window.location.pathname + window.location.search);
  }

  var App = void 0;

  App = Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
  var computed = Ember.computed;

  LinkComponent.reopen({
    queryParams: computed({
      get: function get() {},
      set: function set(k, qps) {
        if (qps && !('values' in qps)) {
          return { values: {} };
        } else {
          return qps;
        }
      }
    })
  });

  _resolver.default.reopen({
    pluralizedTypes: {
      process: 'processes',
      timeline: 'timelines'
    }
  });
});