define("frontend-cp/session/admin/people/businesshours/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "session_admin_people_businesshours_index__title_1fser7",
    "caption": "session_admin_people_businesshours_index__caption_1fser7"
  };
});