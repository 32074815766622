define("frontend-cp/components/ko-admin/holidays/row/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "ko-admin_holidays_row__title_2qvlep",
    "caption": "ko-admin_holidays_row__caption_2qvlep"
  };
});