define('frontend-cp/state-managers/organization', ['exports', 'ember-service/inject', 'npm:lodash', 'frontend-cp/lib/edited-custom-fields', 'ember-concurrency', 'frontend-cp/services/virtual-model', 'frontend-cp/utils/object', 'frontend-cp/lib/convert-errors-to-map', 'frontend-cp/lib/upload-file'], function (exports, _inject, _npmLodash, _editedCustomFields, _emberConcurrency, _virtualModel, _object, _convertErrorsToMap, _uploadFile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var EmberObject = Ember.Object;
  var observer = Ember.observer;
  var computed = Ember.computed;
  var get = Ember.get;
  var isEmpty = Ember.isEmpty;
  var scheduleOnce = Ember.run.scheduleOnce;
  var assign = Ember.assign;
  var service = Ember.inject.service;
  var Evented = Ember.Evented;


  var organizationSchema = (0, _virtualModel.model)('organization', {
    name: (0, _virtualModel.attr)(),
    isShared: (0, _virtualModel.attr)(),
    domains: (0, _virtualModel.many)((0, _virtualModel.model)('identity-domain', {
      id: (0, _virtualModel.attr)(),
      domain: (0, _virtualModel.attr)()
    })),
    customFields: (0, _virtualModel.many)((0, _virtualModel.fragment)('organization-field-value', {
      field: (0, _virtualModel.attr)(),
      value: (0, _virtualModel.attr)()
    }))
  });

  var cleanupAttachments = function cleanupAttachments(attachments) {
    attachments.removeObjects(attachments.filter(function (attachment) {
      return !isEmpty(attachment.get('error'));
    }));
    return attachments;
  };

  exports.default = EmberObject.extend(Evented, {
    launchDarkly: (0, _inject.default)(),

    organization: null,
    editedOrganization: null,
    errorMap: null,
    posts: null,
    bottomPostsAvailable: false,
    topPostsAvailable: true,
    customFields: null,
    editedTags: null,
    replyContents: '',
    tab: null,
    attachedPostFiles: null,

    // Services (injected externally)
    i18n: service(),
    notification: service(),
    permissions: service(),
    store: service(),
    tabStore: service(),
    tagsService: service('tags'),
    virtualModel: service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.resetEdits();
      var savedState = (0, _object.jsonToObject)(this.get('tab').state.organization);

      // attachments should be re-created as correct UploadFile objects
      if (savedState.attachedPostFiles) {
        savedState.attachedPostFiles = savedState.attachedPostFiles.map(function (attachment) {
          return _uploadFile.default.create(attachment);
        });
      }

      this.setProperties(savedState);
      this.persistTabState();
    },
    resetPosts: function resetPosts(postId) {
      this.setProperties({
        posts: [],
        topPostsAvailable: true,
        bottomPostsAvailable: Boolean(postId)
      });
    },
    resetEdits: function resetEdits() {
      this.resetSidebar();
      this.setProperties({
        attachedPostFiles: [],
        replyContents: ''
      });
    },
    resetSidebar: function resetSidebar() {
      var organization = this.get('organization');
      var editedOrganization = this.get('virtualModel').makeSnapshot(organization, organizationSchema);

      this.setProperties({
        errorMap: EmberObject.create(),
        editedOrganization: editedOrganization,
        customFields: _editedCustomFields.default.create({
          originalCustomFields: this.get('organization.customFields'),
          editedCustomFields: editedOrganization.get('customFields')
        }),
        editedTags: this.get('organization.tags').map(function (tag) {
          return EmberObject.create({ name: tag.get('name'), isNew: false });
        })
      });
    },
    persistTabState: function persistTabState() {
      this.get('tabStore').updateState(this.get('tab'), {
        organization: assign(this.getProperties('replyContents', 'attachedPostFiles'), {
          _isEdited: this.get('isEdited')
        })
      });
    },
    setCaseAccess: function setCaseAccess(sharedState) {
      this.set('editedOrganization.isShared', sharedState);
      this.set('errorMap.is_shared', null);
    },
    addDomain: function addDomain(_ref) {
      var domain = _ref.domain;

      this.get('editedOrganization.domains').pushObject({
        domain: domain,
        isNew: !this.get('organization.domains').findBy('domain', domain)
      });
      this.set('errorMap.domains', null);
    },
    removeDomain: function removeDomain(domain) {
      this.get('editedOrganization.domains').removeObject(domain);
      this.set('errorMap.domains', null);
    },
    addTag: function addTag(_ref2) {
      var name = _ref2.name,
          actualName = _ref2.actualName;

      this.get('editedTags').pushObject({
        name: actualName || name,
        isNew: !this.get('organization.tags').find(function (tag) {
          return tag.get('name') === (actualName || name);
        })
      });
      this.set('errorMap.tags', null);
    },
    removeTag: function removeTag(tag) {
      this.get('editedTags').removeObject(tag);
      this.set('errorMap.tags', null);
    },
    addAttachment: function addAttachment(attachment) {
      var attachments = cleanupAttachments(this.get('attachedPostFiles'));

      attachments.pushObject(attachment);
      this.set('attachedPostFiles', attachments);
      this.persistTabState();
    },
    cancelAttachment: function cancelAttachment(attachment) {
      var attachments = cleanupAttachments(this.get('attachedPostFiles'));

      attachments.removeObjects(attachments.filter(function (a) {
        return a === attachment;
      }));

      this.set('attachedPostFiles', attachments);
      this.persistTabState();
    },
    updateAttachments: function updateAttachments() {
      this.persistTabState();
    },
    isUploadInProgress: function isUploadInProgress() {
      var uploads = this.get('attachedPostFiles');
      return uploads.any(function (u) {
        return u.get('status') === 'PROGRESS';
      });
    },
    setCustomField: function setCustomField(field, value) {
      if (value) {
        value = get(value, 'id') || value;
      }
      this.get('errorMap').set(field.get('key'), false);
      this.get('customFields').setValue(field, value);
    },
    setReplyContents: function setReplyContents(replyContents) {
      this.set('replyContents', replyContents);
      this.persistTabState();
    },


    // Tasks
    submit: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var task;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              task = this.get('isContentEdited') ? 'saveEverything' : 'updateProperties';
              _context.next = 3;
              return this.get(task).perform();

            case 3:
              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('organization.updated'),
                autodismiss: true
              });
              this.resetEdits();
              this.persistTabState();

            case 6:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    updateProperties: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('save').perform();

            case 2:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    saveEverything: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return this.get('save').perform();

            case 2:
              _context3.next = 4;
              return this.get('postNote').perform();

            case 4:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).drop(),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      var launchDarkly, organization, originalTags, tagsService, tags;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');

              if (this.get('hasUpdateOrganizationPermission')) {
                _context4.next = 3;
                break;
              }

              return _context4.abrupt('return');

            case 3:
              organization = this.get('organization');
              originalTags = organization.get('tags').map(function (tag) {
                return tag;
              });
              tagsService = this.get('tagsService');
              tags = this.get('editedTags').map(function (tag) {
                return tagsService.getTagByName(get(tag, 'name'));
              });


              organization.setProperties({ tags: tags });

              _context4.prev = 8;
              _context4.next = 11;
              return this.get('virtualModel').save(organization, this.get('editedOrganization'), organizationSchema);

            case 11:
              _context4.next = 13;
              return organization.get('tags').reload();

            case 13:
              // Get rid of unsaved domains and tags hanging around
              organization.set('tags', organization.get('tags').filterBy('isNew', false));
              organization.set('domains', organization.get('domains').filter(function (domain) {
                return !domain.get('isNew');
              }));
              this.persistTabState();
              if (launchDarkly.get('release-apps')) {
                this.trigger('updated');
              }
              _context4.next = 24;
              break;

            case 19:
              _context4.prev = 19;
              _context4.t0 = _context4['catch'](8);

              this.set('errorMap', (0, _convertErrorsToMap.default)(_context4.t0.errors));
              organization.setProperties({ tags: originalTags });
              throw _context4.t0;

            case 24:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this, [[8, 19]]);
    })).drop(),

    postNote: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5() {
      var uploads, attachmentIds, contents;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              if (!this.isUploadInProgress()) {
                _context5.next = 3;
                break;
              }

              this.get('notification').add({
                type: 'warning',
                title: 'Upload in progress',
                autodismiss: true
              });
              return _context5.abrupt('return');

            case 3:
              uploads = this.get('attachedPostFiles').filter(function (attachment) {
                return isEmpty(attachment.get('error'));
              });
              attachmentIds = uploads.mapBy('attachmentId').compact();
              contents = this.get('replyContents').trim();
              _context5.next = 8;
              return this.get('store').createRecord('organization-note', {
                contents: contents,
                organization: this.get('organization'),
                attachmentFileIds: attachmentIds
              }).save();

            case 8:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })),

    suggestTags: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee6(searchTerm) {
      var addNewMessage, data, exactMatch;
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              _context6.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              addNewMessage = this.get('i18n').t('generic.addtagname', { tag: searchTerm });
              _context6.next = 5;
              return this.get('store').query('tag', { name: searchTerm });

            case 5:
              data = _context6.sent;
              exactMatch = !!data.toArray().findBy('name', searchTerm) || !!this.get('editedTags').findBy('name', searchTerm);
              return _context6.abrupt('return', _npmLodash.default.difference(data.mapBy('name'), this.get('editedTags').mapBy('name')).map(function (name) {
                return { name: name };
              }).concat(exactMatch ? [] : [{ name: addNewMessage, actualName: searchTerm }]));

            case 8:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    })).restartable(),

    // CPs
    hasUpdateOrganizationPermission: computed(function () {
      return this.get('permissions').has('organizations.update');
    }),

    isCaseAccessEdited: computed('organization.isShared', 'editedOrganization.isShared', function () {
      return this.get('organization.isShared') !== this.get('editedOrganization.isShared');
    }),

    isDomainsEdited: computed('editedOrganization.domains.@each.domain', 'organization.domains.@each.domain', function () {
      var editedDomains = this.get('editedOrganization.domains').compact().mapBy('domain').compact();
      var domains = this.get('organization.domains').compact().mapBy('domain').compact();
      return editedDomains.length !== domains.length || _npmLodash.default.intersection(editedDomains, domains).length !== domains.length;
    }),

    isTagsFieldEdited: computed('editedTags.@each.name', 'organization.tags.@each.name', function () {
      var editedTags = this.get('editedTags').mapBy('name');
      var tags = this.get('organization.tags').mapBy('name');
      return editedTags.length !== tags.length || _npmLodash.default.intersection(editedTags, tags).length !== tags.length;
    }),

    isContentEdited: computed('replyContents', function () {
      return this.get('replyContents').trim() !== '';
    }),

    arePropertiesEdited: or('isDomainsEdited', 'isCaseAccessEdited', 'customFields.isEdited', 'isTagsFieldEdited'),

    isEdited: or('isContentEdited', 'arePropertiesEdited'),

    // We have to set this magic property, because after
    // save/restore stte operation, computed property will
    // stop updating.
    isEditedChanged: observer('isEdited', function () {
      scheduleOnce('sync', this, 'persistTabState');
    })
  });
});