define('frontend-cp/services/case-list-tab', ['exports', 'npm:lodash', 'frontend-cp/config/environment', 'ember-concurrency', 'frontend-cp/lib/column-mappings/map-columns-to-fields-and-includes'], function (exports, _npmLodash, _environment, _emberConcurrency, _mapColumnsToFieldsAndIncludes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var readOnly = Ember.computed.readOnly;
  var resolve = Ember.RSVP.resolve;
  var computed = Ember.computed;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var run = Ember.run;


  var CASE_VIEW_LIMIT = _environment.default.APP.views.maxLimit;
  var CASE_PAGE_SIZE = _environment.default.casesPageSize;
  var CASE_POLLING_TIME = _environment.default.APP.views.casesPollingInterval * 1000;

  exports.default = Service.extend({
    // Services
    store: service(),
    socket: service(),
    router: service('-routing'),
    permissions: service(),
    session: service(),

    // State
    views: null,
    latestCases: null,
    viewCounts: null,
    suspendedMailsCount: null,
    forceNextLoad: false,
    currentCachedView: null,
    currentCachedParams: null,
    currentView: null,
    currentParams: null,
    viewsCountPollingTimer: null,
    casesPollingTimer: null,

    refresh: function refresh() {
      var _this = this;

      this.updateViewCounts();
      this.get('fetchCases').perform().then(function () {
        _this.transitionToPreviousPageIfEmpty();
      }).catch(function (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      });
    },
    transitionToPreviousPageIfEmpty: function transitionToPreviousPageIfEmpty() {
      var cases = this.get('latestCases');
      if (cases.get('length')) {
        return;
      }

      var totalCases = cases.get('meta.total');
      if (!totalCases) {
        return;
      }

      var lastPageWithContent = Math.ceil(totalCases / CASE_PAGE_SIZE);
      this.get('router').transitionTo('session.agent.cases.index.view', [this.get('currentView.id')], { page: lastPageWithContent });
    },
    getViews: function getViews() {
      var _this2 = this;

      var store = this.get('store');
      var views = this.get('views');
      var forceNextLoad = this.get('forceNextLoad');
      var limit = CASE_VIEW_LIMIT;
      var fields = 'resource_type,type,is_default,is_enabled,title,columns,order_by,order_by_column';
      var include = [];

      if (!forceNextLoad && views) {
        return views;
      }

      return store.query('view', { limit: limit, fields: fields, include: include }, { reload: true }).then(function (views) {
        _this2.set('views', views);
        _this2.set('forceNextLoad', false);
        return views;
      });
    },
    getCasesForView: function getCasesForView(view, params) {
      if (view !== this.get('currentCachedView')) {
        this.set('currentCachedView', null);
      }

      this.set('currentView', view);
      this.set('currentViewParms', params);

      Reflect.deleteProperty(params, 'view_id');
      var paramsAreEqual = _npmLodash.default.isEqual(params, this.get('currentCachedParams'));

      if (view === this.get('currentCachedView') && paramsAreEqual) {
        this.get('fetchCases').perform();
        return resolve();
      } else {
        return this.get('fetchCases').perform();
      }
    },


    fetchCases: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var view, params, cases;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!Ember.testing) {
                this.initializeCasesPolling();
              }

              view = this.get('currentView');
              params = this.get('currentViewParms');
              _context.next = 5;
              return this.get('refreshCases').perform(view, params);

            case 5:
              cases = _context.sent;

              this.set('currentCachedView', view);
              this.set('currentCachedParams', params);
              this.set('latestCases', cases);

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    updateViewCounts: function updateViewCounts(offset) {
      var _this3 = this;

      offset = offset || 0;
      var limit = 10;
      var options = {
        reload: true,
        fields: 'count,realtime_channel,view(is_default)',
        include: 'view',
        limit: limit,
        offset: offset || 0
      };
      var self = this;
      return this.get('store').query('view-count', options).then(function (data) {
        if (offset + limit < data.meta.total) {
          self.updateViewCounts(offset + limit);
        } else {
          var viewSuspended = _this3.get('permissions').has('cases.view_suspended', _this3.get('session').get('user'));
          if (viewSuspended) {
            return _this3.get('store').query('mail', { is_suspended: true, offset: 0, limit: 0 }).then(function (mails) {
              _this3.setProperties({ viewCounts: data, suspendedMailsCount: mails.meta.total });
            });
          } else {
            _this3.setProperties({ viewCounts: data, suspendedMailsCount: 0 });
          }
        }
      });
    },


    isRefreshingCases: readOnly('refreshCases.isRunning'),

    refreshCases: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(view, params) {
      var columnNames, _mapToFieldsAndInclud, fields, includes, result, store, count, viewCount;

      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (view) {
                _context2.next = 2;
                break;
              }

              return _context2.abrupt('return', null);

            case 2:
              columnNames = ['conversation'].concat(_toConsumableArray(view.get('columns').mapBy('name')));
              _mapToFieldsAndInclud = (0, _mapColumnsToFieldsAndIncludes.default)(columnNames), fields = _mapToFieldsAndInclud.fields, includes = _mapToFieldsAndInclud.includes;


              fields += ',created_at';

              _context2.next = 7;
              return this.get('store').query('case', {
                limit: CASE_PAGE_SIZE,
                parent: view,
                offset: (parseInt(params.page, 10) - 1) * CASE_PAGE_SIZE,
                order_by: params.orderBy,
                fields: fields,
                order_by_column: params.orderByColumn,
                include: includes
              });

            case 7:
              result = _context2.sent;
              store = this.get('store');
              count = result.meta.total;
              _context2.next = 12;
              return view.get('viewCount');

            case 12:
              viewCount = _context2.sent;


              if (viewCount) {
                store.push({
                  data: {
                    id: viewCount.get('id'),
                    type: 'view-count',
                    attributes: { count: count }
                  }
                });
              }

              return _context2.abrupt('return', result);

            case 15:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).restartable(),

    pollView: function pollView(views, params) {
      var _this4 = this;

      var view = views.findBy('id', params.view_id);

      return this.get('refreshCases').perform(view, params).then(function (cases) {
        if (view) {
          view.set('casesQuery', cases);
        }

        _this4.set('latestCases', cases);

        return view;
      });
    },
    initializeCasesPolling: function initializeCasesPolling() {
      this.set('numberOfPollsSinceSomeoneAsked', 0);
      if (this.casesPollingTimer) {
        run.cancel(this.casesPollingTimer);
      }
      this.casesPollingTimer = run.later(this, this.pollCases, CASE_POLLING_TIME);
    },
    pollCases: function pollCases() {
      var _this5 = this;

      var view = this.get('currentCachedView');
      var params = this.get('currentCachedParams');
      this.incrementProperty('numberOfPollsSinceSomeoneAsked');

      this.get('refreshCases').perform(view, params).then(function (cases) {
        return _this5.set('latestCases', cases);
      });

      var pollingDelayFactor = Math.ceil(this.get('numberOfPollsSinceSomeoneAsked') / 10);
      if (this.casesPollingTimer) {
        run.cancel(this.casesPollingTimer);
      }
      this.casesPollingTimer = run.later(this, this.pollCases, CASE_POLLING_TIME * pollingDelayFactor);
    },
    cancelCasePolling: function cancelCasePolling() {
      if (this.casesPollingTimer) {
        run.cancel(this.casesPollingTimer);
      }
    },


    inboxCount: computed('viewCounts.@each.view', function () {
      var viewCounts = this.get('viewCounts');

      return viewCounts && viewCounts.findBy('view.isDefault');
    }),

    inboxView: computed('views', function () {
      var views = this.get('views');
      return views && views.findBy('isDefault');
    }),

    enabledViewsWithoutInbox: computed('views', 'inboxView', function () {
      var _this6 = this;

      return this.get('views').filter(function (v) {
        return v.id !== _this6.get('inboxView').id && v.get('isEnabled');
      });
    })
  });
});