define('frontend-cp/components/locale-select/component', ['exports', 'frontend-cp/lib/keycodes'], function (exports, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),

    options: [],

    disabled: false,
    selected: null,
    onChange: null,

    selectedLocale: computed('options', 'selected', function () {
      var _this = this;

      var selectedOption = this.get('options').find(function (option) {
        return option.code === _this.get('selected');
      });
      return selectedOption || null;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var locales = this.get('store').peekAll('locale').filterBy('isPublic');
      var localeOptions = [];
      locales.forEach(function (element) {
        localeOptions.push({
          code: element.get('locale'),
          language: element.get('name')
        });
      });
      this.set('options', localeOptions);
    },


    // Actions
    actions: {
      preventSubmissionOnEnter: function preventSubmissionOnEnter(_, e) {
        if (e.keyCode === _keycodes.enter) {
          e.preventDefault();
        }
      }
    }
  });
});