define("frontend-cp/components/ko-admin/team/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "loader": "ko-admin_team__loader_zemr8",
    "header-bar": "ko-admin_team__header-bar_zemr8",
    "header": "ko-admin_team__header_zemr8",
    "search": "ko-admin_team__search_zemr8",
    "search-result": "ko-admin_team__search-result_zemr8",
    "search-result-avatar": "ko-admin_team__search-result-avatar_zemr8",
    "member-avatar": "ko-admin_team__member-avatar_zemr8",
    "member-name": "ko-admin_team__member-name_zemr8",
    "secondary": "ko-admin_team__secondary_zemr8",
    "new-member-row": "ko-admin_team__new-member-row_zemr8",
    "member-search-already-included": "ko-admin_team__member-search-already-included_zemr8"
  };
});