define('frontend-cp/helpers/first-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.firstName = firstName;
  var capitalize = Ember.String.capitalize;
  var Helper = Ember.Helper;
  function firstName(name) {
    if (Array.isArray(name)) {
      name = name[0];
    }

    if (typeof name !== 'string') {
      return;
    }

    name = name.trim();
    if (name.length) {
      name = name.split(' ').get('firstObject');
    }
    return capitalize(name);
  }

  exports.default = Helper.helper(firstName);
});