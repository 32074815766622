define('frontend-cp/components/ko-admin/predicate-builder/proposition/component', ['exports', 'npm:lodash', 'frontend-cp/lib/computed-fallback-if-undefined', 'ember-concurrency'], function (exports, _npmLodash, _computedFallbackIfUndefined, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var guidFor = Ember.guidFor;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var get = Ember.get;
  var dasherize = Ember.String.dasherize;
  var EmberObject = Ember.Object;
  exports.default = Component.extend({
    tagName: '',

    i18n: service(),
    store: service(),

    canDeleteProposition: false,
    definitions: (0, _computedFallbackIfUndefined.default)([]),
    definitionsSort: ['groupLabel:asc'],
    onPropositionDeletion: null,
    onPropositionChange: null,
    proposition: null,
    uniqueId: null,
    disabled: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.uniqueId = guidFor(this);
    },


    definition: computed('definitions.@each.id', 'proposition.field', function () {
      return this.get('definitions').findBy('id', this.get('proposition.field'));
    }),

    /**
     * Used for specific propositions which require different
     * localisation than normal. Can add some more with OR
     * conditions if required.
     */
    isMiscellaneousProposition: computed.equal('proposition.field', 'business_hour'),

    groupedDefinitions: computed('definitions.@each.id', 'definitions.@each.disabled', function () {
      var _this = this;

      return _npmLodash.default.map(_npmLodash.default.groupBy(this.get('definitions').toArray(), function (definition) {
        return definition.get('group');
      }), function (options, group) {
        return {
          groupName: _this.get('i18n').t('admin.predicate_builder.group.' + group),
          disabled: options.every(function (o) {
            return o.disabled;
          }),
          options: options.sort(function (option) {
            return option.label;
          })
        };
      }).sort(function (group) {
        return group.groupBy;
      });
    }),

    definitionInputType: computed('definition.inputType', function () {
      var inputType = this.get('definition.inputType');

      if (inputType === 'BOOLEAN_TRUE' || inputType === 'BOOLEAN_FALSE') {
        this.set('proposition.operator', this.get('definition.operators.firstObject'));
        this.set('proposition.value', true);
      }

      if (inputType) {
        return dasherize(inputType);
      } else {
        return null;
      }
    }),

    dateRelativeValues: computed('definition.values.[]', function () {
      return this.get('definition.values').mapBy('value');
    }),

    value: computed('proposition.value', 'definition.values.@each.value', 'definitionInputType', 'autocompleteType', function () {
      var propositionValue = this.get('proposition.value');

      switch (this.get('definitionInputType')) {
        case 'options':
          {
            var definitionValues = this.get('definition.values');

            if (definitionValues) {
              return definitionValues.findBy('value', propositionValue);
            } else {
              return null;
            }
          }
        case 'autocomplete':
          {
            if (propositionValue) {
              var presetValue = this.get('definition.values').findBy('value', propositionValue);
              if (presetValue) {
                return presetValue;
              } else {
                return this.get('store').findRecord(this.get('autocompleteType'), propositionValue);
              }
            } else {
              return null;
            }
          }
        case 'tags':
          {
            if (propositionValue) {
              return propositionValue.split(',').map(function (t) {
                return EmberObject.create({ name: t });
              });
            } else {
              return [];
            }
          }
        case 'multiple':
          {
            var _definitionValues = this.get('definition.values');

            if (propositionValue) {
              return propositionValue.split(',').map(function (t) {
                return _definitionValues.findBy('value', t.trim());
              }); // AI-GEN - Sweep
            } else {
              return [];
            }
          }
        case 'time':
          {
            if (propositionValue) {
              var trimmed = propositionValue.trim();

              if (/^\d+$/.test(trimmed)) {
                return parseInt(trimmed, 10) / 3600;
              }
            }

            return null;
          }
        default:
          return propositionValue;
      }
    }),

    autocompleteType: computed('proposition.field', function () {
      switch (this.get('proposition.field')) {
        case 'cases.organizationid':
        case 'users.organizationid':
          return 'organization';
        default:
          return 'user';
      }
    }),

    autocompleteQueryOptions: computed('proposition.field', function () {
      switch (this.get('proposition.field')) {
        case 'cases.assigneeagentid':
        case 'assigneeagentid':
          return { in: 'ADMINS,AGENTS,COLLABORATORS' };
        default:
          return {};
      }
    }),

    autocompleteOptionLabelPath: computed('autocompleteType', function () {
      switch (this.get('autocompleteType')) {
        case 'user':
          return 'fullName';
        default:
          return 'name';
      }
    }),

    multipleRemainingOptions: computed('value', 'definition.values', function () {
      var allOptions = this.get('definition.values').slice();
      return allOptions.removeObjects(this.get('value'));
    }),

    booleanOptions: computed(function () {
      return ['true', 'false'];
    }),

    triggerPropositionChange: function triggerPropositionChange() {
      if (this.get('onPropositionChange')) {
        return this.get('onPropositionChange')(this.get('proposition'));
      }
    },


    suggestTags: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(term) {
      var store, value, used, tags, result;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              store = this.get('store');
              value = this.get('value') || [];
              used = value.mapBy('name');
              _context.next = 7;
              return store.query('tag', { name: term });

            case 7:
              tags = _context.sent;
              result = tags.mapBy('name').filter(function (name) {
                return !used.includes(name);
              }).map(function (name) {
                return { name: name };
              });
              return _context.abrupt('return', result);

            case 10:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    actions: {
      convertValueToSeconds: function convertValueToSeconds(value) {
        var trimmed = value.trim();
        var result = '';

        if (/^\d+$/.test(trimmed)) {
          result = 3600 * parseInt(trimmed, 10) + '';
        }

        this.set('proposition.value', result);
      },
      selectDefinition: function selectDefinition(definition) {
        this.set('proposition.field', definition.get('id'));
        this.set('proposition.value', null);
        this.set('proposition.operator', null);
        this.triggerPropositionChange();
      },
      selectValue: function selectValue(value) {
        this.set('proposition.value', value.get('value'));
        this.triggerPropositionChange();
      },
      setMultipleValue: function setMultipleValue(newSelection) {
        this.set('proposition.value', newSelection.mapBy('value').join(','));
        this.triggerPropositionChange();
      },
      selectAutocompleteValue: function selectAutocompleteValue(value) {
        this.set('proposition.value', value.get('id') || value.get('value'));
        this.triggerPropositionChange();
      },
      addTagToValue: function addTagToValue(tag) {
        this.set('proposition.value', this.get('value').mapBy('name').concat(get(tag, 'name')).join(','));
        this.triggerPropositionChange();
      },
      changeOperator: function changeOperator(value) {
        this.set('proposition.operator', value);
        this.triggerPropositionChange();
      },
      setValue: function setValue(value) {
        this.set('proposition.value', value);
        this.triggerPropositionChange();
      },
      removeTagFromValue: function removeTagFromValue(tag) {
        this.set('proposition.value', _npmLodash.default.without(this.get('value'), tag).mapBy('name').join(','));
        this.triggerPropositionChange();
      },
      searchAutocomplete: function searchAutocomplete(text) {
        var _this2 = this;

        var autocompleteType = this.get('autocompleteType');

        return this.get('store').query(autocompleteType, _npmLodash.default.extend({}, this.get('autocompleteQueryOptions'), { name: text })).then(function (results) {
          var matchStr = text.replace(/\W/, '').toLowerCase();
          var matched = _this2.get('definition.values').filter(function (definition) {
            return definition.get('string').replace(/\W/, '').toLowerCase().indexOf(matchStr) !== -1;
          });
          return matched.concat(results.toArray());
        });
      }
    }
  });
});