define('frontend-cp/components/ko-admin/sla/edit/target/component', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var on = Ember.on;
  var findLast = _npmLodash.default.findLast;
  exports.default = Component.extend({
    // Attributes
    targets: null,
    priority: null,
    type: null,
    onValueChange: function onValueChange() {},

    // State
    unit: null,

    // HTML
    localClassNames: ['target'],

    // Services
    i18n: service(),

    // Init
    initUnit: on('init', function () {
      var units = this.get('units');
      var goalInSeconds = this.get('goalInSeconds');
      this.set('unit', findLast(units, function (unit) {
        return goalInSeconds % unit.seconds === 0;
      }) || units[0]);
    }),

    // CPs
    currentTarget: computed('priority', 'type', 'targets.[]', function () {
      var priority = this.get('priority');
      var type = this.get('type');
      return this.get('targets').find(function (metric) {
        return metric.get('priority') === priority && metric.get('slaTargetType') === type;
      });
    }),

    goalInSeconds: readOnly('currentTarget.goalInSeconds'),

    units: computed(function () {
      var _this = this;

      var t = function t(unit) {
        return _this.get('i18n').t('admin.sla.edit.targets.units.' + unit);
      };
      return [{ label: t('minutes'), id: 'minutes', seconds: 60 }, { label: t('hours'), id: 'hours', seconds: 60 * 60 }, { label: t('days'), id: 'days', seconds: 60 * 60 * 24 }];
    }),

    value: computed('goalInSeconds', 'unit', function () {
      var goalInSeconds = this.get('goalInSeconds');
      return _npmLodash.default.isNumber(goalInSeconds) ? goalInSeconds / this.get('unit').seconds : '';
    }),

    actions: {
      setValue: function setValue(value) {
        var unit = this.get('unit');
        var trimmedValue = value.trim();
        if (trimmedValue === '') {
          this.get('onValueChange')(null);
        } else if (trimmedValue === '0') {
          this.$('input').val('');
          this.get('onValueChange')(null);
        } else if (/^\d+$/.test(trimmedValue)) {
          this.$('input').val(trimmedValue);
          this.get('onValueChange')(unit.seconds * parseInt(trimmedValue, 10));
        } else {
          this.$('input').val(this.get('value'));
          this.get('onValueChange')(this.get('goalInSeconds'));
        }
      },
      setUnit: function setUnit(unit) {
        var oldUnit = this.get('unit');
        this.set('unit', unit);
        var seconds = this.get('goalInSeconds') / oldUnit.seconds * unit.seconds;
        this.get('onValueChange')(seconds);
      }
    }
  });
});