define('frontend-cp/models/automation-action', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    option: (0, _attr.default)('string'),
    value: (0, _attr.default)(),
    attributes: (0, _attr.default)()
  });
});