define('frontend-cp/components/ko-admin/macros/edit/action-handlers/reply-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    serialize: function serialize(action, macro, store) {
      this.reset(macro);

      if (action.value) {
        macro.set('replyType', action.value);
      }

      return macro;
    },
    deserialize: function deserialize(macro, actions) {
      var replyType = macro.get('replyType');

      if (replyType) {
        actions.push({
          name: 'reply-type',
          option: 'CHANGE',
          value: replyType
        });
      }

      return actions;
    },
    reset: function reset(macro) {
      macro.set('replyType', null);
    }
  };
});