define('frontend-cp/components/ko-grid-picker/component', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone;
  var computed = Ember.computed;
  var on = Ember.on;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    initialGrid: null,
    legend: null,

    // State
    grid: null,
    startRow: null,
    startCol: null,
    prevRow: null,
    prevCol: null,
    rowCount: null,
    colCount: null,

    isSelecting: false,

    gridCopy: null,

    initGrid: on('init', function () {
      var initialGrid = this.get('initialGrid');
      this.set('rowCount', initialGrid.get('length'));
      this.set('colCount', _npmLodash.default.max(initialGrid.map(function (row) {
        return row.length;
      })));
      this.set('grid', this.getFreshGrid(initialGrid));
    }),

    cols: computed('colCount', function () {
      return _npmLodash.default.range(this.get('colCount'));
    }),

    displayLegend: computed('legend.[]', function () {
      if (this.get('legend.length')) {
        return this.get('legend');
      } else {
        return this.get('cols');
      }
    }),

    getFreshGrid: function getFreshGrid(grid) {
      var _this = this;

      return _npmLodash.default.range(this.get('rowCount')).map(function (index) {
        return _this.get('cols').map(function (hour) {
          return grid[index][hour];
        });
      });
    },
    toggleCell: function toggleCell(grid, row, col) {
      var isSelected = this.get('gridCopy')[row][col];
      grid[row][col] = !isSelected;
    },
    isPrevCell: function isPrevCell(row, col) {
      return row === this.get('prevRow') && col === this.get('prevCol');
    },
    highlightRegion: function highlightRegion(startRow, startCol, endRow, endCol) {
      if (startRow > endRow) {
        var _ref = [endRow, startRow];
        startRow = _ref[0];
        endRow = _ref[1];
      }
      if (startCol > endCol) {
        var _ref2 = [endCol, startCol];
        startCol = _ref2[0];
        endCol = _ref2[1];
      }

      var grid = this.getFreshGrid(this.get('gridCopy'));

      for (var i = startRow; i <= endRow; i++) {
        for (var j = startCol; j <= endCol; j++) {
          this.toggleCell(grid, i, j);
        }
      }
      this.set('grid', grid);
    },


    actions: {
      handleMouseUp: function handleMouseUp() {
        this.set('isSelecting', false);
        this.attrs.onRangeSelect(this.get('grid'));
      },
      handleMouseDown: function handleMouseDown(event) {
        event.preventDefault();
        var target = event.target;
        if (isNone(target.dataset.row) || isNone(target.dataset.col)) {
          return;
        }

        var row = parseInt(target.dataset.row, 10);
        var col = parseInt(target.dataset.col, 10);

        this.set('gridCopy', this.getFreshGrid(this.get('grid')));

        this.set('isSelecting', true);
        this.set('startRow', row);
        this.set('startCol', col);
        this.set('prevRow', row);
        this.set('prevCol', col);

        this.highlightRegion(row, col, row, col);
      },
      handleMouseMove: function handleMouseMove(event) {
        var target = event.target;
        if (isNone(target.dataset.row) || isNone(target.dataset.col)) {
          return;
        }
        var row = parseInt(target.dataset.row, 10);
        var col = parseInt(target.dataset.col, 10);

        if (this.get('isSelecting') && !this.isPrevCell(row, col)) {
          this.highlightRegion(this.get('startRow'), this.get('startCol'), row, col);
          this.set('prevRow', row);
          this.set('prevCol', col);
        }
      }
    }
  });
});