define('frontend-cp/helpers/ko-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var Helper = Ember.Helper;
  exports.default = Helper.helper(function (_ref) {
    var _ref2 = _toArray(_ref),
        func = _ref2[0],
        rest = _ref2.slice(1);

    return Reflect.apply(func, undefined, rest);
  });
});