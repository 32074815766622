define('frontend-cp/models/automation-action-definition', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    label: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    options: (0, _attr.default)(),
    inputType: (0, _attr.default)('string'),
    valueType: (0, _attr.default)('string'),
    values: (0, _attr.default)(),
    attributes: (0, _attr.default)(),
    group: (0, _attr.default)('string'),

    // Services
    i18n: service(),

    // CPs
    groupLabel: computed('label', 'group', function () {
      var _getProperties = this.getProperties('group', 'label'),
          group = _getProperties.group,
          label = _getProperties.label;

      if (group) {
        return this.get('i18n').t('admin.automation_actions_builder.group.' + group) + ': ' + label;
      }

      return label;
    }),

    valuesSortedAphabetically: computed('values', function () {
      return this.get('values').sortBy('value');
    })
  });
});