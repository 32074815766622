define('frontend-cp/serializers/locale', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.normalizeIsLocalized = normalizeIsLocalized;
  var assign = Ember.assign;
  exports.default = _application.default.extend({
    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      resourceHash.links = {
        strings: 'strings'
      };
      return this._super.apply(this, arguments);
    },
    normalize: function normalize(modelClass, resourceHash) {
      resourceHash = normalizeIsLocalized(resourceHash);

      return this._super(modelClass, resourceHash);
    }
  });
  function normalizeIsLocalized(resourceHash) {
    var result = assign({}, resourceHash);
    var isLocalised = result.is_localised;

    Reflect.deleteProperty(result, 'is_localised');

    if (result.is_localized === undefined) {
      result.is_localized = isLocalised;
    }

    return result;
  }
});