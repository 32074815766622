define('frontend-cp/components/ko-organization-content/action-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // Params
    organization: null,
    canDelete: false,
    filter: null,
    onSetFilter: function onSetFilter() {},
    closeTab: function closeTab() {},

    // Services
    session: service(),
    permissions: service(),
    confirmation: service(),

    // CPs
    hasDeleteOrganizationPermission: computed('session.permissions', 'organization', function () {
      return this.get('canDelete') && this.get('permissions').has('app.organization.delete', this.get('organization'));
    }),

    // Actions
    actions: {
      deleteOrganization: function deleteOrganization() {
        var _this = this;

        return this.get('confirmation').confirm({ intlConfirmationBody: 'generic.confirm.delete' }).then(function () {
          return _this.get('organization').destroyRecord();
        }).then(function () {
          return _this.get('closeTab')();
        });
      }
    }
  });
});