define('frontend-cp/session/admin/people/roles/edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    // Actions
    actions: {
      transitionToIndex: function transitionToIndex() {
        this.transitionToRoute('session.admin.people.roles.index');
      }
    },

    // Methods
    isEdited: function isEdited() {
      var model = this.get('model');
      return model.get('hasDirtyAttributes') && Object.keys(model.changedAttributes()).length > 0;
    },
    initEdits: function initEdits() {
      this.get('model').rollbackAttributes();
    }
  });
});