define("frontend-cp/components/article-file-upload/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "file-upload-container": "article-file-upload__file-upload-container_16xzu1",
    "file-upload-label": "article-file-upload__file-upload-label_16xzu1",
    "choose-files-btn": "article-file-upload__choose-files-btn_16xzu1",
    "drag-drop-text": "article-file-upload__drag-drop-text_16xzu1",
    "file-input": "article-file-upload__file-input_16xzu1",
    "uploaded-files-list": "article-file-upload__uploaded-files-list_16xzu1",
    "file-item": "article-file-upload__file-item_16xzu1",
    "remove-file-btn": "article-file-upload__remove-file-btn_16xzu1",
    "file-size": "article-file-upload__file-size_16xzu1"
  };
});