define('frontend-cp/components/ko-admin/billing-form/component', ['exports', 'ember-countries', 'npm:lodash', 'npm:js-vat'], function (exports, _emberCountries, _npmLodash, _npmJsVat) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({

    tagName: '',

    // attributes
    billing: {},
    saving: false,
    vatId: null,

    // state
    countries: _emberCountries.countries,
    billingRules: computed(function () {
      return {
        first_name: ['exists'],
        last_name: ['exists'],
        personal_email: ['exists', 'isEmail'],
        home_phone: ['exists', 'isPhone'],
        address1: ['exists'],
        city: ['exists'],
        state: ['exists'],
        postal_code: ['exists'],
        country: ['exists']
      };
    }),

    billingErrorMessages: computed(function () {
      var _this = this;

      return (0, _npmLodash.default)(this.get('billing')).map(function (value, field) {
        return [field, _this.get('i18n').t('account.trial.billing.error.' + field)];
      }).fromPairs().value();
    }),

    formErrors: {},

    // Services
    validation: service(),
    ratePlansService: service('rateplans'),
    i18n: service(),
    notificationService: service('notification'),

    // Cps
    vatRequired: computed('billing.country', function () {
      return this.get('ratePlansService').countryHasVat(this.get('billing.country.iso'));
    }),

    // methods
    validateBillingDetails: function validateBillingDetails() {
      var data = this.get('billing');
      var rules = this.get('billingRules');
      var messages = this.get('billingErrorMessages');
      this.set('formErrors', this.get('validation').validateAll(data, rules, messages));
    },
    validateVatId: function validateVatId() {
      var vatId = this.get('vatId');
      Reflect.deleteProperty(this.get('formErrors'), 'vat_id');
      if (!vatId || !this.get('vatRequired')) {
        return;
      }
      var validateVatId = (0, _npmJsVat.default)(this.get('vatId'), this.get('billing.country.iso'));
      if (!validateVatId.valid_country || !validateVatId.valid_vat) {
        this.set('formErrors.vat_id', [{ message: this.get('i18n').t('account.trial.billing.error.vat_id') }]);
      }
    },


    actions: {
      validateForm: function validateForm() {
        this.validateBillingDetails();
        this.validateVatId();
      },
      focusErrorFields: function focusErrorFields() {
        var errorField = _npmLodash.default.first(_npmLodash.default.keys(this.get('formErrors')));
        $('input[name="' + errorField + '"]').focus();
      },
      hasErrors: function hasErrors() {
        return _npmLodash.default.size(this.get('formErrors'));
      }
    }

  });
});