define("frontend-cp/components/ko-cases-list/sidebar/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "sidebar": "ko-cases-list_sidebar__sidebar_1uky7l",
    "hr": "ko-cases-list_sidebar__hr_1uky7l",
    "item": "ko-cases-list_sidebar__item_1uky7l",
    "suspended-mails": "ko-cases-list_sidebar__suspended-mails_1uky7l",
    "manage-views": "ko-cases-list_sidebar__manage-views_1uky7l",
    "counter": "ko-cases-list_sidebar__counter_1uky7l"
  };
});