define('frontend-cp/components/ko-admin/automation-actions-builder/multiple/component', ['exports', 'frontend-cp/components/ko-admin/automation-actions-builder/base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var get = Ember.get;
  var isBlank = Ember.isBlank;
  exports.default = _component.default.extend({
    // CPs
    selected: computed('automationAction.value', function () {
      var value = get(this, 'automationAction.value');
      var ids = isBlank(value) ? [] : value.split(',').map(function (id) {
        return id.trim();
      });
      return get(this, 'definition.values').filter(function (v) {
        return ids.indexOf(get(v, 'id')) > -1;
      });
    }),

    // Actions
    actions: {
      updateValue: function updateValue(newSelection) {
        this.set('automationAction.value', newSelection.mapBy('id').join(','));
      }
    }
  });
});