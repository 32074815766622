define("frontend-cp/components/ko-text-editor/file-upload/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "file-upload": "ko-text-editor_file-upload__file-upload_1ufmnc",
    "overflowed": "ko-text-editor_file-upload__overflowed_1ufmnc",
    "files": "ko-text-editor_file-upload__files_1ufmnc"
  };
});