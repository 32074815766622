define("frontend-cp/session/admin/security/policy/customers/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "field": "session_admin_security_policy_customers__field_d92zer",
    "field-column": "session_admin_security_policy_customers__field-column_d92zer",
    "description": "session_admin_security_policy_customers__description_d92zer"
  };
});