define('frontend-cp/session/admin/apps/endpoints/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    store: service(),

    model: function model() {
      return this.get('store').findAll('endpoint', { reload: true });
    }
  });
});