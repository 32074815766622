define('frontend-cp/components/ko-notification-center/notification-case-assigned-to-agent/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    notification: null,
    close: function close() {},

    user: computed.reads('notification.activity.objectActorUser')
  });
});