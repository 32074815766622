define('frontend-cp/components/ko-case-content/info-block/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',
    isUserOnline: false,

    // Services
    router: service('-routing'),
    notification: service(),
    i18n: service(),
    session: service(),

    // Attributes:
    user: null,
    processingOrg: false,

    // CPs
    isMe: computed('session.user.id', 'user.id', function () {
      return this.get('session.user.id') === this.get('user.id');
    }),

    isNewCase: computed('user', function () {
      return this.get('router.currentRouteName').includes('session.agent.cases.new');
    }),

    // Tasks
    updateName: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(name, oldName) {
      var i18n, user;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              i18n = this.get('i18n');
              user = this.get('user');

              user.set('fullName', name);
              _context.prev = 3;
              _context.next = 6;
              return user.save({ adapterOptions: { updateName: true } });

            case 6:
              this.get('notification').success(i18n.t('users.name_update_success'));
              _context.next = 13;
              break;

            case 9:
              _context.prev = 9;
              _context.t0 = _context['catch'](3);

              this.get('notification').error(i18n.t('users.name_update_failure'));
              user.set('name', oldName);

            case 13:
              return _context.abrupt('return', user);

            case 14:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[3, 9]]);
    })).drop(),

    actions: {
      updateOrgRemovalState: function updateOrgRemovalState(value, org) {
        this.sendAction('updateOrgRemovalState', value, org);
      },
      setName: function setName(name) {
        var oldName = this.get('user.fullName');
        if (name === oldName) {
          return;
        }
        this.get('updateName').perform(name, oldName);
      },
      onPresenceChanged: function onPresenceChanged() {
        var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var id = this.get('user.id');
        var metas = data[id] && data[id].metas;
        var isOnline = !!(metas && metas.length);

        this.set('isUserOnline', isOnline);
      }
    }

  });
});