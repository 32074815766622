define('frontend-cp/models/product-rateplan', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    productRateplanType: _emberData.default.attr('string'),
    key: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    minimum_amount_notification: _emberData.default.attr('string'),
    minimum_purchase_amount: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    charges: _emberData.default.hasMany('product-rateplan-charge'),
    product: _emberData.default.belongsTo()
  });
});