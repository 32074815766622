define("frontend-cp/utils/format-validations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validateEmailFormat = validateEmailFormat;
  exports.validateTwitterHandleFormat = validateTwitterHandleFormat;
  exports.validatePhoneFormat = validatePhoneFormat;
  var EMAIL_REGEX = /^[^@]+@([^@.]+\.)+[^@.]{2,}$/;
  var TWITTER_REGEX = /(^|[^@\w])@(\w{1,15})\b$/;
  var PHONE_REGEX = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;

  function validateEmailFormat(email) {
    return EMAIL_REGEX.test(email);
  }

  function validateTwitterHandleFormat(email) {
    return TWITTER_REGEX.test(email);
  }

  function validatePhoneFormat(phone) {
    return PHONE_REGEX.test(phone);
  }
});