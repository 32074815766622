define('frontend-cp/adapters/impersonate', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    impersonate: function impersonate(data) {
      var url = this.namespace + '/impersonation/impersonate';
      return this.ajax(url, 'POST', { data: data });
    }
  });
});