define("frontend-cp/components/ko-insights/section/articles/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-insights_section_articles__container_5ybrqa",
    "table": "ko-insights_section_articles__table_5ybrqa"
  };
});