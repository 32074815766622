define('frontend-cp/components/ko-admin/apps/manage/edit/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    installedApp: null,
    prompts: null,

    appsService: service('apps'),

    app: reads('installedApp.app'),

    onSave: function onSave() {},
    onCancel: function onCancel() {},
    onUninstall: function onUninstall() {},

    values: null,

    init: function init() {
      this._super.apply(this, arguments);

      var values = {};
      var prompts = this.get('prompts');

      prompts.forEach(function (prompt) {
        var _prompt$getProperties = prompt.getProperties('key', 'value'),
            key = _prompt$getProperties.key,
            value = _prompt$getProperties.value;

        values[key] = value;
      });

      this.set('values', values);
    },


    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var installation, values;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              installation = this.get('installedApp');
              values = this.get('values');
              _context.next = 4;
              return this.get('appsService').updatePrompts(installation, values);

            case 4:
              _context.next = 6;
              return this.get('onSave')();

            case 6:
              return _context.abrupt('return', _context.sent);

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    uninstall: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var installation;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              installation = this.get('installedApp');
              _context2.next = 3;
              return this.get('appsService').uninstall(installation);

            case 3:
              _context2.next = 5;
              return this.get('onUninstall')(installation);

            case 5:
              return _context2.abrupt('return', _context2.sent);

            case 6:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    actions: {
      updateValue: function updateValue(key, value) {
        var values = this.get('values');
        values[key] = value;
      }
    }

  });
});