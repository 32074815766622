define('frontend-cp/components/ko-info-bar/field/radio/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    title: null,
    options: null,
    value: null,
    isErrored: false,
    isEdited: false,
    isKREEdited: false,
    isDisabled: false,
    onValueChange: null,
    hasEmptyOption: false,
    emptyLabel: null,

    // HTML
    tagName: ''
  });
});