define("frontend-cp/components/ko-sidebar/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-sidebar_item__item_ngcuux",
    "inactive-item": "ko-sidebar_item__inactive-item_ngcuux"
  };
});