define("frontend-cp/components/ko-text-wrapper-for-button/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "toggle-wrapper": "ko-text-wrapper-for-button__toggle-wrapper_t5idvr",
    "toggle": "ko-text-wrapper-for-button__toggle_t5idvr"
  };
});