define('frontend-cp/lib/at-mentions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;


  function _getId(item) {
    return $(item).attr('id');
  }

  function _getSubjectId(item) {
    return $(item).attr('data-mention-id');
  }

  function _getType(item) {
    return $(item).attr('data-mention-type');
  }

  function extractMentions(html) {
    var $html = $('<div />', { html: html });

    return $html.find('.ko-mention').toArray().map(function (item) {
      var id = _getId(item);
      var subjectId = _getSubjectId(item);
      var type = _getType(item);
      var text = $(item).text().trim();

      if (id && subjectId && type) {
        return { id: id, subjectId: subjectId, type: type, text: text };
      }
    }).filter(Boolean);
  }

  function replaceMentionsWithPlainText(html) {
    var mentions = extractMentions(html);

    return mentions.reduce(function (h, mention) {
      return replaceMention(h, mention.id, mention.text);
    }, html);
  }

  function replaceMention(html, id, replacement) {
    var $html = $('<div />', { html: html });
    var mention = $html.find('#' + id)[0];

    if (mention) {
      var $mention = $(mention);
      $mention.replaceWith(replacement);
    }

    return $html.html();
  }

  exports.extractMentions = extractMentions;
  exports.replaceMentionsWithPlainText = replaceMentionsWithPlainText;
  exports.replaceMention = replaceMention;
});