define('frontend-cp/session/admin/customizations/privacy/edit/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    exports.default = Route.extend({
        model: function model(params) {
            return this.store.findRecord('privacy-policy', params.privacy_id);
        },
        setupController: function setupController(controller, privacy) {
            controller.setProperties({ privacy: privacy });
            controller.initEdits();
        }
    });
});