define("frontend-cp/components/ko-timeline-2/list/activity/standard/actions-summary-text/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "inline-property-avatar": "ko-timeline-2_list_activity_standard_actions-summary-text__inline-property-avatar_s1i0hx",
    "summary-line-actions": "ko-timeline-2_list_activity_standard_actions-summary-text__summary-line-actions_s1i0hx",
    "status-pill": "ko-timeline-2_list_activity_standard_actions-summary-text__status-pill_s1i0hx"
  };
});