define('frontend-cp/components/ko-admin/automation-actions-builder/autocomplete-agent/before-options/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: ''
  });
});