define('frontend-cp/session/admin/apps/api/oauth-apps/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.store.query('oauth-client', { limit: 9999 });
    }
  });
});