define("frontend-cp/components/ko-admin/brands/form/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "domain": "ko-admin_brands_form__domain_1fb6v1",
    "domain-input": "ko-admin_brands_form__domain-input_1fb6v1",
    "domain-available": "ko-admin_brands_form__domain-available_1fb6v1",
    "domain-not-available": "ko-admin_brands_form__domain-not-available_1fb6v1",
    "alias-container": "ko-admin_brands_form__alias-container_1fb6v1",
    "check-dns-button": "ko-admin_brands_form__check-dns-button_1fb6v1",
    "dns-valid": "ko-admin_brands_form__dns-valid_1fb6v1",
    "dns-invalid": "ko-admin_brands_form__dns-invalid_1fb6v1",
    "ssl-set-icon": "ko-admin_brands_form__ssl-set-icon_1fb6v1",
    "ssl-not-set-icon": "ko-admin_brands_form__ssl-not-set-icon_1fb6v1",
    "ssl-label": "ko-admin_brands_form__ssl-label_1fb6v1",
    "ssl-icon": "ko-admin_brands_form__ssl-icon_1fb6v1",
    "cancel-ssl-button": "ko-admin_brands_form__cancel-ssl-button_1fb6v1",
    "textarea": "ko-admin_brands_form__textarea_1fb6v1"
  };
});