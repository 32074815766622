define('frontend-cp/components/ko-creditcard-icon/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend({
    tagName: 'span',
    iconDiff: 39,
    types: ['VISA', 'DISCOVER', 'AMERICANEXPRESS', 'MASTER'],
    iconStyles: computed('type', function () {
      var typeIndex = this.get('types').indexOf(this.get('type'));
      var diff = typeIndex === 1 ? this.get('iconDiff') : this.get('iconDiff') * typeIndex;
      return htmlSafe('background-position: -' + diff + 'px');
    })
  });
});