define("frontend-cp/components/ko-admin/empty/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-admin_empty__container_1tajvv",
    "no-user-found": "ko-admin_empty__no-user-found_1tajvv"
  };
});