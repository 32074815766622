define('frontend-cp/services/sound-alerts', ['exports', 'ember-service/inject', 'ember-concurrency', 'moment', 'frontend-cp/utils/bugsnag'], function (exports, _inject, _emberConcurrency, _moment, _bugsnag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.DONK = undefined;
  var Service = Ember.Service;
  var getOwner = Ember.getOwner;
  var RSVP = Ember.RSVP;
  var DONK = exports.DONK = 'alert-01';
  var LIGHTSABER = '21c5b96';
  var BLASTER = '01a4c70';

  var SOUNDS = [{ key: DONK, path: '/sounds/donk.mp3' }, { key: LIGHTSABER, path: '/sounds/21c5b96.mp3' }, { key: BLASTER, path: '/sounds/01a4c70.mp3' }];

  var NullAudio = function NullAudio() {};
  NullAudio.prototype.play = function () {
    return RSVP.resolve();
  };

  exports.default = Service.extend({
    launchDarkly: (0, _inject.default)(),

    sounds: null,

    init: function init() {
      this._super.apply(this, arguments);

      this._sounds = {};
    },
    initializeSounds: function initializeSounds() {
      var AudioClass = window.Audio || NullAudio;

      var sounds = SOUNDS.reduce(function (sounds, props) {
        var sound = new AudioClass(props.path);

        sounds[props.key] = sound;

        return sounds;
      }, {});

      this._sounds = sounds;
    },
    play: function play(filename) {
      var launchDarkly = this.get('launchDarkly');
      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('ops-new-message-pill-sound-enhancement')) {
        // <base64>U3RhciBXYXJzIERheSBFYXN0ZXIgRWdnLiBNYXkgdGhlIDR0aCBCZSBXaXRoIFlvdSE=</base64>

        if (this._shouldEnhance() || launchDarkly.get('ops-force-sound-enhancement')) {
          var sound = this.get('_sound');

          if (!sound) {
            var sounds = [LIGHTSABER, BLASTER];
            sound = sounds[Math.floor(Math.random() * sounds.length)];

            this.set('_sound', sound);
          }

          return this.get('_play').perform(sound);
        }
      }

      return this.get('_play').perform(filename);
    },


    _play: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(filename) {
      var _this = this;

      var sound, result, error, context;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              sound = this._sounds[filename];

              if (!sound) {
                _context.next = 10;
                break;
              }

              _context.next = 4;
              return sound.play().catch(function (reason) {
                var error = new Error('Error playing sound file: ' + reason + ' : ' + filename);
                var context = (0, _bugsnag.getMetaData)(null, getOwner(_this));
                window.Bugsnag.notifyException(error, 'Error playing sound file', context, 'error');
              });

            case 4:
              result = _context.sent;
              _context.next = 7;
              return (0, _emberConcurrency.timeout)(1500);

            case 7:
              return _context.abrupt('return', result);

            case 10:
              error = new Error('Unrecognized sound file: ' + filename);
              context = (0, _bugsnag.getMetaData)(null, getOwner(this));

              window.Bugsnag.notifyException(error, 'Unrecognized sound file', context, 'error');

              return _context.abrupt('return', RSVP.resolve());

            case 14:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    _shouldEnhance: function _shouldEnhance() {
      var m = (0, _moment.default)();
      var month = m.month();
      var day = m.date();

      return month === 4 && day === 4;
    }
  });
});