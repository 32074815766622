define('frontend-cp/components/ko-cases-list/sidebar/item/component', ['exports', 'ember-service/inject', 'ember-concurrency'], function (exports, _inject, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var $ = Ember.$;
  var dasherize = Ember.String.dasherize;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    tagName: '',

    metrics: service(),
    caseListTab: service(),
    store: service(),

    // Attributes
    casesView: null,
    viewHasCount: true,

    // CPs
    disabled: computed('casesView.viewCount.count', 'viewHasCount', function () {
      return !this.get('casesView.viewCount.count') && this.get('viewHasCount');
    }),

    isRefreshingCases: computed.readOnly('caseListTab.isRefreshingCases'),
    currentCachedView: computed.readOnly('caseListTab.currentCachedView'),

    // Methods
    handleCountChange: function handleCountChange(data) {
      this.get('store').push({
        data: {
          id: data.resource_id,
          type: dasherize(data.resource_type),
          attributes: data.changed_properties
        }
      });
    },


    // Actions
    actions: {
      refresh: function refresh(e) {
        var launchDarkly = this.get('launchDarkly');

        var a = $(e.target).closest('a');
        if (!a.hasClass('active') || a.hasClass('ember-transitioning-out') || a.hasClass('ember-transitioning-in')) {
          return;
        }

        if (launchDarkly.get('ops-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'Views - Refresh',
            category: 'Agent'
          });
        }

        this.get('refresh').perform();
      }
    },

    // Tasks
    refresh: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('caseListTab').get('fetchCases').perform();

            case 2:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop()
  });
});