define("frontend-cp/components/ko-channel-selector/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "channel-selector": "ko-channel-selector__channel-selector_1gh454",
    "dropdown": "ko-channel-selector__dropdown_1gh454 _dropdown_jvqwfg",
    "item": "ko-channel-selector__item_1gh454",
    "item-text": "ko-channel-selector__item-text_1gh454",
    "trigger": "ko-channel-selector__trigger_1gh454",
    "trigger--active": "ko-channel-selector__trigger--active_1gh454",
    "trigger--dormant": "ko-channel-selector__trigger--dormant_1gh454"
  };
});