define("frontend-cp/components/ko-timeline-2/list/item/status/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "dropdown-trigger": "ko-timeline-2_list_item_status__dropdown-trigger_ykqdj5",
    "status": "ko-timeline-2_list_item_status__status_ykqdj5",
    "label": "ko-timeline-2_list_item_status__label_ykqdj5",
    "icon": "ko-timeline-2_list_item_status__icon_ykqdj5",
    "recipients-list": "ko-timeline-2_list_item_status__recipients-list_ykqdj5",
    "recipient": "ko-timeline-2_list_item_status__recipient_ykqdj5",
    "name": "ko-timeline-2_list_item_status__name_ykqdj5",
    "email": "ko-timeline-2_list_item_status__email_ykqdj5",
    "not-sent": "ko-timeline-2_list_item_status__not-sent_ykqdj5",
    "sent": "ko-timeline-2_list_item_status__sent_ykqdj5",
    "delivered": "ko-timeline-2_list_item_status__delivered_ykqdj5",
    "seen": "ko-timeline-2_list_item_status__seen_ykqdj5",
    "rejected": "ko-timeline-2_list_item_status__rejected_ykqdj5",
    "client-failed": "ko-timeline-2_list_item_status__client-failed_ykqdj5"
  };
});