define('frontend-cp/session/admin/customizations/brands/new/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend((0, _route.default)('brand'), {
    model: function model(params) {
      return RSVP.hash({
        brand: this.store.createRecord('brand'),
        locales: this.store.findAll('locale'),
        defaultLocaleSetting: this.store.peekAll('setting').findBy('key', 'account.default_language')
      });
    },
    setupController: function setupController(controller, model) {
      var brand = model.brand;
      brand.set('locale', model.locales.findBy('locale', model.defaultLocaleSetting.get('value')));
      brand.set('domain', 'kayako.com');
      controller.set('brand', brand);
      controller.set('locales', model.locales);
      controller.initEdits();
    }
  });
});