define('frontend-cp/models/billing', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    hosted_page_id: _emberData.default.attr('string'),
    hosted_page_url: _emberData.default.attr('string'),
    payment_gateway: _emberData.default.attr('string'),
    currency: _emberData.default.attr('string')
  });
});