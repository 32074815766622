define('frontend-cp/session/admin/manage/case-forms/schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var localeField = (0, _virtualModel.model)('locale-field', {
    id: (0, _virtualModel.attr)(),
    locale: (0, _virtualModel.attr)(),
    translation: (0, _virtualModel.attr)({ nonStrictMatching: true })
  });

  exports.default = (0, _virtualModel.model)('case-form', {
    title: (0, _virtualModel.attr)(),
    customerTitle: (0, _virtualModel.attr)(),
    description: (0, _virtualModel.attr)(),
    isVisibleToCustomers: (0, _virtualModel.attr)(),
    isEnabled: (0, _virtualModel.attr)(),
    fields: (0, _virtualModel.many)((0, _virtualModel.attr)()),
    brand: (0, _virtualModel.attr)(),

    customerTitles: (0, _virtualModel.many)(localeField),
    descriptions: (0, _virtualModel.many)(localeField)
  });
});