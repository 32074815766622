define('frontend-cp/components/ko-table/row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    rowContext: null,
    clickable: false,

    // TODO BUG when component is reusing existing dom (via Glimmer), selectable
    // will be undefined
    selectable: readOnly('parentView.parentView.selectable'),
    selected: false,
    selectableDisabled: false,

    // Lifecycle hooks

    // Actions
    actions: {
      toggleRow: function toggleRow(value, e) {
        if (!this.get('selectableDisabled')) {
          this.toggleProperty('selected');
          var action = this.get('onSelectChange');
          if (action) {
            action(this.get('selected'), e.shiftKey);
          }
        }
      },
      handleClick: function handleClick(event) {
        if (this.get('clickable')) {
          var hasModifier = event.metaKey || event.ctrlKey || event.shiftKey;
          this.attrs.onClick(this.get('rowContext'), hasModifier);
        }
      }
    }
  });
});