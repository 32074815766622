define("frontend-cp/components/ko-text-editor/secondary-controls/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-text-editor_secondary-controls__item_rppp04",
    "itemWrap": "ko-text-editor_secondary-controls__itemWrap_rppp04",
    "item-disabled": "ko-text-editor_secondary-controls__item-disabled_rppp04",
    "icon": "ko-text-editor_secondary-controls__icon_rppp04",
    "buttonsDropdownItem": "ko-text-editor_secondary-controls__buttonsDropdownItem_rppp04",
    "buttonsDropdownItemText": "ko-text-editor_secondary-controls__buttonsDropdownItemText_rppp04",
    "itemSecondary": "ko-text-editor_secondary-controls__itemSecondary_rppp04"
  };
});