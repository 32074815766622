define('frontend-cp/session/admin/apps/webhooks/new/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)('model'), {
    model: function model() {
      return this.get('store').createRecord('token', {
        isEnabled: true
      });
    },


    // Actions
    actions: {
      didSave: function didSave() {
        this.transitionTo('session.admin.apps.webhooks.edit', this.get('controller').get('model'));
      },
      cancel: function cancel(webhook) {
        this.transitionTo('session.admin.apps.webhooks');
      }
    }
  });
});