define('frontend-cp/components/ko-keyboard-help-modal/component', ['exports', 'frontend-cp/utils/platform'], function (exports, _platform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    cmdKey: computed(function () {
      return (0, _platform.isMac)() ? '⌘' : 'ctrl';
    }),

    shiftKey: computed(function () {
      return (0, _platform.isMac)() ? '⇧' : 'shift';
    }),

    altKey: computed(function () {
      return (0, _platform.isMac)() ? '⌥' : 'alt';
    })

  });
});