define('frontend-cp/adapters/notification-preference', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'notification_preferences';
    },
    updatePreferences: function updatePreferences(data) {
      var url = this.namespace + '/notification_preferences';
      return this.ajax(url, 'PUT', { data: { values: data } });
    }
  });
});