define('frontend-cp/models/identity-domain', ['exports', 'ember-data', 'frontend-cp/models/identity'], function (exports, _emberData, _identity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _identity.default.extend({
    domain: _emberData.default.attr('string'),

    user: _emberData.default.belongsTo('user', { async: true }),
    organization: _emberData.default.belongsTo('organization', { async: true })
  });
});