define("frontend-cp/components/ko-admin/case-forms/edit/fields/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "row": "ko-admin_case-forms_edit_fields__row_1lld7s",
    "action": "ko-admin_case-forms_edit_fields__action_1lld7s",
    "cell": "ko-admin_case-forms_edit_fields__cell_1lld7s",
    "action-cell": "ko-admin_case-forms_edit_fields__action-cell_1lld7s",
    "list": "ko-admin_case-forms_edit_fields__list_1lld7s",
    "title": "ko-admin_case-forms_edit_fields__title_1lld7s",
    "caption": "ko-admin_case-forms_edit_fields__caption_1lld7s"
  };
});