define('frontend-cp/session/controller', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var dasherize = Ember.String.dasherize;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    // Services
    urlService: service('url'),
    tabStore: service(),
    session: service(),
    permissions: service(),
    caseListTab: service(),
    onBoardingStatus: service(),
    routing: service('-routing'),
    plan: service(),
    realtimePush: service(),

    // Attributes
    showingKeyboardHelp: false,
    isSearchActive: false,
    appAccessModal: false,
    signatureModal: false,
    twoFactorModal: false,
    twoFactorModalRole: null,
    changePasswordModal: false,

    // State
    /**
     * Array of listeners which will be automatically removed once this controller is destroyed
     * @type {Object[]}
     */
    disposableListeners: null,

    // Lifecycle

    init: function init() {
      this._super.apply(this, arguments);
      this.set('disposableListeners', []);
    },


    /**
     * Remove the disposable listeners once the controller is destroyed
     */
    willDestroy: function willDestroy() {
      this._super();
      this.get('disposableListeners').forEach(function (listener) {
        var subject = listener.subject,
            event = listener.event,
            handler = listener.handler;

        subject.off(event, handler);
      });
    },


    /**
     * Add an event listener which will be automatically removed once this controller is destroyed
     * @param {Evented} subject Event emitter
     * @param {string} event Event name to listen to
     * @param {function} handler Listener handler function
     */
    addDisposableListener: function addDisposableListener(subject, event, handler) {
      // Add the listener
      subject.on(event, handler);
      this.get('disposableListeners').push({ subject: subject, event: event, handler: handler });
    },


    // CPs
    currentUser: readOnly('session.user'),
    userPermissions: readOnly('session.permissions'),
    inboxCount: readOnly('caseListTab.inboxCount.count'),
    viewingCaseViews: computed('routing.currentRouteName', function () {
      return this.get('routing.currentRouteName') === 'session.agent.cases.index.view';
    }),

    params: computed('launchDarkly.feature-push-notifications', function () {
      var launchDarkly = this.get('launchDarkly');

      if (!launchDarkly.get('feature-push-notifications')) {
        return {};
      }

      var device = this.get('realtimePush').findRegisteredDevice(this.get('realtimePush').getPushUuid());
      if (device && device.get('id')) {
        return {
          device_id: device.get('id')
        };
      }
    }),

    hasInsightsAccess: computed('userPermissions', 'currentUser.role.roleType', function () {
      var currentUser = this.get('currentUser');

      if (currentUser) {
        return this.get('permissions').has('insights.access', currentUser);
      } else {
        return false;
      }
    }),

    pendingSteps: readOnly('onBoardingStatus.pendingSteps'),

    handleInboxCountChange: function handleInboxCountChange(data) {
      this.get('store').push({
        data: {
          id: data.resource_id,
          type: dasherize(data.resource_type),
          attributes: data.changed_properties
        }
      });
    },


    actions: {
      seeMore: function seeMore(term) {
        this.send('openAdvancedSearch', term);
      },
      loadSearchRoute: function loadSearchRoute() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.send.apply(this, ['openSearchResult'].concat(_toConsumableArray(args)));
      },
      close: function close(tab) {
        this.get('tabStore').close(tab);
      },
      manageAppAccess: function manageAppAccess() {
        this.set('appAccessModal', true);
      },
      closeAppAccessModal: function closeAppAccessModal() {
        this.set('appAccessModal', false);
      },
      editSignature: function editSignature() {
        this.set('signatureModal', true);
      },
      closeSignatureModal: function closeSignatureModal() {
        this.set('signatureModal', false);
      },
      openTwoFactorModal: function openTwoFactorModal() {
        this.set('twoFactorModal', true);
        this.set('twoFactorModalRole', 'enable');
      },
      openDisableTwoFactorModal: function openDisableTwoFactorModal() {
        this.set('twoFactorModal', true);
        this.set('twoFactorModalRole', 'disable');
      },
      closeTwoFactorModal: function closeTwoFactorModal() {
        this.set('twoFactorModal', false);
      },
      openPasswordModal: function openPasswordModal() {
        this.set('changePasswordModal', true);
      },
      closePasswordModal: function closePasswordModal() {
        this.set('changePasswordModal', false);
      },
      refreshUserData: function refreshUserData() {
        this.get('store').queryRecord('user', { id: 'me' });
      }
    }
  });
});