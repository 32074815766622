define('frontend-cp/helpers/extract-path-from-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.extractPathFromUrl = extractPathFromUrl;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var helper = Ember.Helper.helper;
  function extractPathFromUrl(pageUrl) {
    pageUrl = pageUrl.trim();

    if (typeof pageUrl !== 'string' || pageUrl === '') {
      return '';
    }

    // If scheme is absent prepend it to the url
    if (!pageUrl.match(/^[a-zA-Z]+:\/\//)) {
      pageUrl = 'http://' + pageUrl;
    }

    var a = document.createElement('a');
    a.href = pageUrl;

    // If pathname is empty return hostname instead
    return a.pathname === '/' ? a.hostname : a.pathname;
  }

  exports.default = helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        url = _ref2[0];

    return extractPathFromUrl(url);
  });
});