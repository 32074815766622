define("frontend-cp/components/ko-admin/rateplans/units/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "plan-units": "ko-admin_rateplans_units__plan-units_1wpx3s",
    "agents-count": "ko-admin_rateplans_units__agents-count_1wpx3s",
    "label-helper": "ko-admin_rateplans_units__label-helper_1wpx3s",
    "operator": "ko-admin_rateplans_units__operator_1wpx3s"
  };
});