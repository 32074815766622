define("frontend-cp/components/ko-agent-content/hierarchical-action-menu/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-agent-content_hierarchical-action-menu__trigger_8eg6tb ko-power-select-triggers_chevron__trigger_71zyep",
    "dropdown": "ko-agent-content_hierarchical-action-menu__dropdown_8eg6tb"
  };
});