define("frontend-cp/components/ko-admin/fields/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "icon": "ko-admin_fields_index__icon_1ul4un",
    "label": "ko-admin_fields_index__label_1ul4un",
    "caption": "ko-admin_fields_index__caption_1ul4un"
  };
});