define("frontend-cp/trial-data/insights/cases/response", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "status": 200,
    "data": {
      "start_at": "2016-05-28T00:00:00+00:00",
      "end_at": "2016-06-27T23:59:59+00:00",
      "previous_start_at": "2016-04-16T00:00:00+00:00",
      "previous_end_at": "2016-05-16T23:59:59+00:00",
      "interval": "DAY",
      "interval_count": 31,
      "series": {
        "name": "average_response_time",
        "data": [27384, 181195, 25295, 12714, 13419, 6506, 8006, 92810, 81653, 10926, 14952, 22421, 22231, 11265, 32996, 23321, 16696, 13207, 9921, 7134, 4319, 103591, 103736, 11427, 6792, 10629, 9394, 9607, 23445, 41366, 20769],
        "previous": [32255, 68226, 14876, 19573, 10091, 7792, 8368, 55034, 63241, 17536, 12341, 11956, 20485, 11510, 63440, 140437, 18013, 10754, 25920, 10078, 15042, 70576, 132005, 32838, 48935, 62759, 29092, 16821, 150173, 100447, 15891],
        "resource_type": "report_series_comparison"
      },
      "metric": {
        "name": "average_response_time",
        "value": 12030,
        "delta_percent": -40.791,
        "previous": 20318,
        "resource_type": "report_value_comparison"
      },
      "resource_type": "report_average_response"
    },
    "resources": [],
    "resource": "report_average_response"
  };
});