define('frontend-cp/initializers/print-git-info', ['exports', 'frontend-cp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'print-git-info',

    initialize: function initialize() {
      if (_environment.default.environment !== 'test') {
        console.log(_environment.default.currentRevision);
      }
    }
  };
});