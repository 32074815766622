define('frontend-cp/session/admin/security/authentication/index/controller', ['exports', 'frontend-cp/session/admin/security/authentication/index/settings', 'ember-concurrency', 'frontend-cp/lib/comma-separate-list'], function (exports, _settings, _emberConcurrency, _commaSeparateList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var or = Ember.computed.or;
  var Controller = Ember.Controller;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    // Attributes
    settings: null,
    editedSettings: null,

    // State
    editedIpRestrictions: '',

    // Services
    i18n: service(),
    notification: service(),
    settingsService: service('settings'),
    virtualModel: service(),
    confirmation: service(),
    plan: service(),

    // CPs
    tabs: computed(function () {
      return [{
        id: 'case',
        label: this.get('i18n').t('admin.settings.security.tabs.agents'),
        routeName: 'session.admin.security.authentication.index',
        dynamicSegments: [],
        queryParams: null
      }, {
        id: 'case',
        label: this.get('i18n').t('admin.settings.security.tabs.customers'),
        routeName: 'session.admin.security.authentication.customers',
        dynamicSegments: [],
        queryParams: null
      }];
    }),

    googleAppsSSO: computed(function () {
      return this.get('plan').has('sso_team_google_apps');
    }),

    authenticationModeOptions: computed(function () {
      return ['internal', 'jwt'];
    }),

    isSettingsEdited: readOnly('editedSettings.isEdited'),

    isGoogleDomainsEdited: computed('googleDomains.[]', 'editedGoogleDomains.[]', function () {
      var current = JSON.stringify(this.get('googleDomains'));
      var edited = JSON.stringify(this.get('editedGoogleDomains'));

      return current !== edited;
    }),

    isEdited: or('isSettingsEdited', 'isGoogleDomainsEdited'),

    schema: computed(function () {
      return this.get('settingsService').generateSchema(_settings.default);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.googleDomains = [];
      this.editedGoogleDomains = [];
    },
    initEdits: function initEdits() {
      this.set('editedSettings', this.get('settingsService').initEdits(this.get('settings'), this.get('schema')));
      var googleDomains = this.get('editedSettings.security_agent_google_authentication_domain.value');
      var ipRestrictions = this.get('editedSettings.security_agent_ip_restriction.value');
      if (googleDomains) {
        var editedDomains = googleDomains.split(',').map(function (domain) {
          return { name: domain.trim() };
        });
        this.set('editedGoogleDomains', editedDomains);
        this.set('googleDomains', editedDomains.slice(0));
      }
      // Adding newline at the end so that when the field is activated, user can type straight
      // away to add a new entry
      this.set('editedIpRestrictions', ipRestrictions ? ipRestrictions.split(',').join('\n') + '\n' : ipRestrictions);
    },


    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var googleDomains;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              googleDomains = this.get('editedGoogleDomains').map(function (domain) {
                return domain.name;
              }).join(',');

              this.set('editedSettings.security_agent_google_authentication_domain.value', googleDomains);

              _context.next = 5;
              return this.get('virtualModel').save(this.get('settings'), this.get('editedSettings'), this.get('schema'));

            case 5:

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('generic.changes_saved'),
                autodismiss: true
              });

              this.initEdits();
              _context.next = 11;
              break;

            case 9:
              _context.prev = 9;
              _context.t0 = _context['catch'](0);

            case 11:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[0, 9]]);
    })).drop(),

    cancel: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!this.get('isEdited')) {
                _context2.next = 4;
                break;
              }

              _context2.next = 3;
              return this.get('confirmation').confirm({
                intlConfirmationHeader: 'generic.confirm.lose_changes_header',
                intlConfirmationBody: 'generic.confirm.lose_changes',
                intlConfirmLabel: 'generic.confirm.lose_changes_button'
              });

            case 3:
              this.initEdits();

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    actions: {
      editBooleanSetting: function editBooleanSetting(settingName, value) {
        this.set('editedSettings.' + settingName + '.value', value ? '1' : '0');
      },
      editTextSetting: function editTextSetting(settingName, e) {
        this.set('editedSettings.' + settingName + '.value', e.target.value);
      },
      editSetting: function editSetting(settingName, value) {
        this.set('editedSettings.' + settingName + '.value', value);
      },
      addDomain: function addDomain(newDomain) {
        var name = get(newDomain, 'name');
        var editedDomains = this.get('editedGoogleDomains');
        if (editedDomains.find(function (domain) {
          return get(domain, 'name') === name;
        })) {
          return;
        }
        editedDomains.pushObject({ name: name });
      },
      removeDomain: function removeDomain(domain) {
        this.get('editedGoogleDomains').removeObject(domain);
      },
      setIpRestrictions: function setIpRestrictions(e) {
        var value = e.target.value;
        this.set('editedIpRestrictions', value);
        this.set('editedSettings.security_agent_ip_restriction.value', (0, _commaSeparateList.default)(value));
      }
    }
  });
});