define("frontend-cp/components/ko-admin/fields/form/priorities/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "buttons": "ko-admin_fields_form_priorities__buttons_1sa0l0",
    "add-priority": "ko-admin_fields_form_priorities__add-priority_1sa0l0",
    "legend": "ko-admin_fields_form_priorities__legend_1sa0l0 ko-admin_forms_group__legend_9ysg3g",
    "loader": "ko-admin_fields_form_priorities__loader_1sa0l0",
    "label": "ko-admin_fields_form_priorities__label_1sa0l0"
  };
});