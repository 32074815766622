define('frontend-cp/components/ko-timeline-2/list/activity/large/component', ['exports', 'moment', 'frontend-cp/lib/facade/activity'], function (exports, _moment, _activity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    activity: null,
    type: null,

    // Services
    i18n: service(),
    serverClock: service(),

    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.set('activityFacade', new _activity.default({ activity: this.get('activity') }));
    },


    // CP's
    activityRatingComment: computed('activity.rating.comment', function () {
      var i18n = this.get('i18n');
      var comment = this.get('activityFacade.rating.comment');

      return comment + ' ' + i18n.t('generic.close_quotes');
    }),

    hasCompletedWithinTheLast10Minutes: computed('activityFacade.isCompleted', 'activity.createdAt', 'serverClock.date', function () {
      return this.get('activityFacade.isCompleted') && (0, _moment.default)(this.get('activity.createdAt')).add(10, 'minutes') > this.get('serverClock.date');
    }),

    // Functions
    localizedMetricName: function localizedMetricName(metricName) {
      var i18n = this.get('i18n');
      switch (metricName) {
        case 'FIRST_REPLY_TIME':
          return i18n.t('timeline.activity.firstReplyTimeBreach');
        case 'NEXT_REPLY_TIME':
          return i18n.t('timeline.activity.nextReplyTimeBreach');
        case 'RESOLUTION_TIME':
          return i18n.t('timeline.activity.resolutionTimeBreach');
      }
    }
  });
});