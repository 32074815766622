define('frontend-cp/initializers/print-logo', ['exports', 'frontend-cp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function makeBlocks(numBlocks) {
    // a block is 12x7 pix, but we're inputting 1px by 1px values. Try to stretch to make it accurate
    var stretchedBlocks = numBlocks + Math.floor(numBlocks / 2);
    return '%c' + Array(stretchedBlocks + 1).join('\u2588');
  } /* eslint-disable no-console */

  function makeStyle(color) {
    return 'color: ' + color;
  }

  var DARK_GREEN = '#69aab5';
  var LIGHT_GREEN = '#85b8c1';
  var PINK = '#f39d90';
  var DARK_RED = '#f66f6d';
  var SKIN = '#ffb1a2';
  var LIGHT_ORANGE = '#f37036';
  var DARK_PINK = '#fa8176';
  var DARK_ORANGE = '#f35918';

  exports.default = {
    name: 'print-logo',

    initialize: function initialize() {
      if (_environment.default.environment === 'development' || window.location.hostname === 'support.kayako.com') {
        var _console;

        var chars = '';
        var styles = [];

        chars += makeBlocks(14);styles.push(makeStyle(DARK_GREEN));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(13);styles.push(makeStyle(DARK_GREEN));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(12);styles.push(makeStyle(DARK_GREEN));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(11);styles.push(makeStyle(DARK_GREEN));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(10);styles.push(makeStyle(DARK_GREEN));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(2);styles.push(makeStyle(PINK));
        chars += makeBlocks(7);styles.push(makeStyle(DARK_GREEN));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(4);styles.push(makeStyle(PINK));
        chars += makeBlocks(4);styles.push(makeStyle(DARK_GREEN));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(5);styles.push(makeStyle(PINK));
        chars += makeBlocks(2);styles.push(makeStyle(DARK_GREEN));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(6);styles.push(makeStyle(PINK));
        chars += makeBlocks(2);styles.push(makeStyle(SKIN));
        chars += makeBlocks(7);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(1);styles.push(makeStyle(DARK_RED));
        chars += makeBlocks(4);styles.push(makeStyle(PINK));
        chars += makeBlocks(4);styles.push(makeStyle(SKIN));
        chars += makeBlocks(5);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(2);styles.push(makeStyle(DARK_RED));
        chars += makeBlocks(2);styles.push(makeStyle(PINK));
        chars += makeBlocks(6);styles.push(makeStyle(SKIN));
        chars += makeBlocks(3);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(3);styles.push(makeStyle(DARK_RED));
        chars += makeBlocks(8);styles.push(makeStyle(SKIN));
        chars += makeBlocks(1);styles.push(makeStyle(LIGHT_GREEN));
        chars += '\n';

        chars += makeBlocks(3);styles.push(makeStyle(DARK_RED));
        chars += makeBlocks(8);styles.push(makeStyle(SKIN));
        chars += makeBlocks(1);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(2);styles.push(makeStyle(DARK_RED));
        chars += makeBlocks(2);styles.push(makeStyle(DARK_PINK));
        chars += makeBlocks(6);styles.push(makeStyle(SKIN));
        chars += makeBlocks(3);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(1);styles.push(makeStyle(DARK_RED));
        chars += makeBlocks(4);styles.push(makeStyle(DARK_PINK));
        chars += makeBlocks(4);styles.push(makeStyle(SKIN));
        chars += makeBlocks(5);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(6);styles.push(makeStyle(DARK_PINK));
        chars += makeBlocks(2);styles.push(makeStyle(SKIN));
        chars += makeBlocks(7);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(5);styles.push(makeStyle(DARK_PINK));
        chars += makeBlocks(2);styles.push(makeStyle(DARK_ORANGE));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(4);styles.push(makeStyle(DARK_PINK));
        chars += makeBlocks(4);styles.push(makeStyle(DARK_ORANGE));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(2);styles.push(makeStyle(DARK_PINK));
        chars += makeBlocks(7);styles.push(makeStyle(DARK_ORANGE));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(10);styles.push(makeStyle(DARK_ORANGE));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(11);styles.push(makeStyle(DARK_ORANGE));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(12);styles.push(makeStyle(DARK_ORANGE));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(13);styles.push(makeStyle(DARK_ORANGE));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        chars += makeBlocks(14);styles.push(makeStyle(DARK_ORANGE));
        chars += makeBlocks(9);styles.push(makeStyle(LIGHT_ORANGE));
        chars += '\n';

        (_console = console).log.apply(_console, [chars].concat(styles));
      }
    }
  };
});