define('frontend-cp/components/ko-on-boarding/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    session: service(),
    i18n: service(),
    onBoardingStatus: service(),

    progress: 0,
    learnKayakoFraction: 28,
    individualStepFraction: 12,
    isRedirected: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.setInitialProgress();
    },


    greeting: computed(function () {
      var i18n = this.get('i18n');
      var hours = new Date().getHours();

      if (hours >= 0 && hours < 12) {
        return i18n.t('onboarding.greeting.morning');
      } else if (hours >= 12 && hours < 17) {
        return i18n.t('onboarding.greeting.afternoon');
      } else {
        return i18n.t('onboarding.greeting.evening');
      }
    }),

    progressCSS: computed('progress', function () {
      return 'transform: translateX(' + this.get('progress') + '%)';
    }),

    setInitialProgress: function setInitialProgress() {
      var progress = this.get('onBoardingStatus.progress');
      var individualStepValue = this.get('individualStepFraction');

      if (progress.learn_kayako_completed) {
        this.incrementProperty('progress', this.get('learnKayakoFraction'));
      }

      if (progress.account_setup) {
        this.incrementProperty('progress', individualStepValue);
      }

      if (progress.account_connected) {
        this.incrementProperty('progress', individualStepValue);
      }

      if (progress.setup) {
        this.incrementProperty('progress', individualStepValue);
      }

      if (progress.team_added) {
        this.incrementProperty('progress', individualStepValue);
      }

      if (progress.agent_added) {
        this.incrementProperty('progress', individualStepValue);
      }

      if (this.get('onBoardingStatus.completedCases') > 2) {
        this.incrementProperty('progress', individualStepValue);
      }
    },


    actions: {
      completeLearnSection: function completeLearnSection() {
        this.incrementProperty('progress', this.get('learnKayakoFraction'));
        this.get('onBoardingStatus').updateStatus('learn_kayako_completed', { 'user.learn_kayako_completed': '1' });
      },
      completeStep: function completeStep() {
        this.incrementProperty('progress', this.get('individualStepFraction'));
      }
    }
  });
});