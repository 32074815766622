define('frontend-cp/session/admin/automation/sla/edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    notification: service(),
    i18n: service(),
    editComponent: null,

    // Actions
    actions: {
      cancel: function cancel() {
        this.transitionToRoute('session.admin.automation.sla.index');
      },
      updated: function updated() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.transitionToRoute('session.admin.automation.sla.index');
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.get('editComponent').initEdits();
    },
    isEdited: function isEdited() {
      return this.get('editComponent').isEdited();
    }
  });
});