define('frontend-cp/components/ko-admin/apps/endpoints/form/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Attributes
    endpoint: null,
    editedEndpoint: null,
    title: null,
    schema: null,

    onSave: function onSave() {},
    onCancel: function onCancel() {},
    onDelete: function onDelete() {},

    // Services
    confirmation: service(),
    virtualModel: service(),

    // CPs
    requestMethods: computed(function () {
      return [{ id: 'GET' }, { id: 'POST' }, { id: 'PUT' }, { id: 'PATCH' }, { id: 'DELETE' }];
    }),

    requestMethod: computed('editedEndpoint.requestMethod', 'requestMethods', function () {
      var requestMethod = this.get('editedEndpoint.requestMethod');
      return this.get('requestMethods').find(function (_ref) {
        var id = _ref.id;
        return id === requestMethod;
      });
    }),

    requestContentTypes: computed(function () {
      return [{ id: 'FORM' }, { id: 'XML' }, { id: 'JSON' }];
    }),

    requestContentType: computed('editedEndpoint.requestContentType', 'requestContentTypes', function () {
      var requestContentType = this.get('editedEndpoint.requestContentType');
      return this.get('requestContentTypes').find(function (_ref2) {
        var id = _ref2.id;
        return id === requestContentType;
      });
    }),

    showContentType: computed('editedEndpoint.requestMethod', function () {
      return ['POST', 'PUT', 'PATCH'].indexOf(this.get('editedEndpoint.requestMethod')) > -1;
    }),

    isDisabled: or('save.isRunning', 'performDelete.isRunning'),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var endpoint, editedEndpoint;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              endpoint = this.get('endpoint');
              editedEndpoint = this.get('editedEndpoint');

              if (!(!endpoint.get('isNew') && endpoint.get('requestContentType') !== editedEndpoint.get('requestContentType'))) {
                _context.next = 5;
                break;
              }

              _context.next = 5;
              return this.get('confirmation').confirm({
                intlConfirmLabel: 'generic.confirm_button',
                intlConfirmationBody: 'admin.apps.endpoints.confirm_content_change.body',
                intlConfirmationHeader: 'admin.apps.endpoints.confirm_content_change.title'
              });

            case 5:
              _context.next = 7;
              return this.get('virtualModel').save(endpoint, editedEndpoint, this.get('schema'));

            case 7:
              this.get('onSave')();

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    confirmDelete: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmationBody: 'admin.apps.endpoints.labels.delete_confirmation',
                intlConfirmationHeader: 'admin.apps.endpoints.labels.confirm_delete',
                intlConfirmLabel: 'generic.confirm.delete_button'
              });

            case 2:
              _context2.next = 4;
              return this.get('performDelete').perform();

            case 4:
              this.get('onDelete');

            case 5:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    performDelete: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return this.get('endpoint').destroyRecord();

            case 2:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    }))
  });
});