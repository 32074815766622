define("frontend-cp/components/ko-admin/messenger/customize/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "label": "ko-admin_messenger_customize__label_uv09rk",
    "localized-field": "ko-admin_messenger_customize__localized-field_uv09rk",
    "locale-input": "ko-admin_messenger_customize__locale-input_uv09rk",
    "background-mode": "ko-admin_messenger_customize__background-mode_uv09rk",
    "is-active": "ko-admin_messenger_customize__is-active_uv09rk",
    "color-palletes": "ko-admin_messenger_customize__color-palletes_uv09rk",
    "show-colors": "ko-admin_messenger_customize__show-colors_uv09rk",
    "color-palletes__wrap": "ko-admin_messenger_customize__color-palletes__wrap_uv09rk",
    "reply-time-locale": "ko-admin_messenger_customize__reply-time-locale_uv09rk",
    "radio-label-detail": "ko-admin_messenger_customize__radio-label-detail_uv09rk",
    "loader": "ko-admin_messenger_customize__loader_uv09rk",
    "pallete": "ko-admin_messenger_customize__pallete_uv09rk",
    "pallete-press": "ko-admin_messenger_customize__pallete-press_uv09rk",
    "scale-up": "ko-admin_messenger_customize__scale-up_uv09rk",
    "solid-color": "ko-admin_messenger_customize__solid-color_uv09rk",
    "gradient": "ko-admin_messenger_customize__gradient_uv09rk",
    "patterns": "ko-admin_messenger_customize__patterns_uv09rk",
    "pattern": "ko-admin_messenger_customize__pattern_uv09rk",
    "is-active-pattern": "ko-admin_messenger_customize__is-active-pattern_uv09rk",
    "color-input-container": "ko-admin_messenger_customize__color-input-container_uv09rk",
    "color-input": "ko-admin_messenger_customize__color-input_uv09rk",
    "text--small": "ko-admin_messenger_customize__text--small_uv09rk",
    "color-selector--background": "ko-admin_messenger_customize__color-selector--background_uv09rk",
    "color-selector--primary": "ko-admin_messenger_customize__color-selector--primary_uv09rk"
  };
});