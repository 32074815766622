define('frontend-cp/components/ko-notification-badge/component', ['exports', 'frontend-cp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // Because headwayapp doesn't have any kind of API
      // there is no way how to re-initialize it, after
      // element was destroyed on the page. So when
      // notification badge is hidden because of search bar
      // we need to add it to the page again. And for it to
      // work completely, we need to remove their special key
      // HW_UID_<key> from window.

      var scriptTags = document.getElementsByTagName('script');

      Object.keys(window).forEach(function (key) {
        if (key.match('HW_UID_')) {
          Reflect.deleteProperty(window, key);
        }
      });

      for (var i = 0, max = scriptTags.length; i < max; i++) {
        if (scriptTags[i] && scriptTags[i].src.match('headwayapp')) {
          scriptTags[i].parentNode.removeChild(scriptTags[i]);
        }
      }

      window.HW_config = {
        selector: '.ko-notification-badge',
        account: _environment.default.headAwayApp.key,
        enabled: true
      };

      var uv = document.createElement('script');
      uv.type = 'text/javascript';
      uv.async = true;
      uv.src = '//cdn.headwayapp.co/widget.js';
      var s = scriptTags[0];
      s.parentNode.insertBefore(uv, s);
    }
  });
});