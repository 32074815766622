define('frontend-cp/components/article-file-upload/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var set = Ember.set;
  exports.default = Component.extend({
    uploadedFiles: [],
    onUploadAttachments: function onUploadAttachments() {},
    disabled: false,
    uploading: false,

    uploadService: service('fileUpload'),

    handleAttachments: function handleAttachments(files) {
      var _this = this;

      files.forEach(function (file) {
        _this.get('uploadService').get('uploadFile').perform(file, null, null, function (file) {
          set(file, 'isUploading', true);
          _this.onUploadAttachments([file]);
        }, function (file) {
          set(file, 'isUploading', false);
          _this.onUploadAttachments([file]);
        });
      });
    },


    actions: {
      handleFileInput: function handleFileInput(event) {
        var files = Array.from(event.target.files || []);
        this.handleAttachments(files);
      },
      onDrop: function onDrop(files) {
        this.handleAttachments(files);
      },
      removeFile: function removeFile(file) {
        var index = this.uploadedFiles.findIndex(function (f) {
          return f.name === file.name && f.size === file.size;
        });
        if (index > -1) {
          var newUploadedFiles = [].concat(_toConsumableArray(this.uploadedFiles));
          newUploadedFiles.splice(index, 1);
          this.onUploadAttachments(newUploadedFiles, true);
        }
      }
    }
  });
});