define('frontend-cp/session/admin/customizations/privacy/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Controller = Ember.Controller;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    session: service(),
    i18n: service(),
    confirmation: service(),

    cookiePrivacyLinks: computed('model.[]', function () {
      return this.get('model').filter(function (privacy) {
        return privacy.get('privacyType') === 'COOKIE';
      });
    }),

    registerPrivacyLinks: computed('model.[]', function () {
      return this.get('model').filter(function (privacy) {
        return privacy.get('privacyType') === 'REGISTRATION';
      });
    }),

    actions: {
      makeDefault: function makeDefault(caseprivacy, e) {
        e.stopPropagation();
        var adapter = getOwner(this).lookup('adapter:application');
        var url = adapter.namespace + '/cases/privacies/default';

        this.store.peekAll('case-privacy').forEach(function (caseprivacy) {
          caseprivacy.set('isDefault', false);
        });
        caseprivacy.set('isDefault', true);
        //TODO: this model is left dirty - it is not an issue,
        //but ideally we would mark this as clean.

        var options = {
          data: { privacy_id: caseprivacy.get('id') }
        };

        adapter.ajax(url, 'PUT', options);
      },
      toggleEnabledStatus: function toggleEnabledStatus(caseprivacy, e) {
        e.stopPropagation();
        caseprivacy.toggleProperty('isEnabled');
        caseprivacy.save();
      },
      transitionToNewPrivacyPolicyLinkRoute: function transitionToNewPrivacyPolicyLinkRoute() {
        this.transitionToRoute('session.admin.customizations.privacy.new');
      },
      editPrivacy: function editPrivacy(privacy) {
        this.transitionToRoute('session.admin.customizations.privacy.edit', privacy.get('id'));
      },
      showDeleteConfirmation: function showDeleteConfirmation(privacy, e) {
        var _this = this;

        e.stopPropagation();

        this.get('confirmation').confirm({
          intlConfirmLabel: 'generic.confirm.delete_button',
          intlConfirmationBody: 'admin.privacies.confirm_delete.body',
          intlConfirmationHeader: 'admin.privacies.confirm_delete.title'
        }).then(function () {
          _this.send('deleteField', privacy);
        });
      },
      deleteField: function deleteField(privacy) {
        privacy.destroyRecord();
      }
    }
  });
});