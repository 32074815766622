define('frontend-cp/session/agent/search-new/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route'], function (exports, _tabbedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  exports.default = _tabbedRoute.default.extend({
    isDirty: false,
    transitionTabPath: null,

    processManager: service(),

    model: function model(_ref) {
      var hash = _ref.hash;

      return EmberObject.create({ id: hash });
    },
    afterModel: function afterModel(model, transition) {
      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(model, 'search-new');
      processManager.setForegroundProcess(process);

      var tabStore = this.get('tabStore');
      var tab = tabStore.open(transition);

      tabStore.update(tab, { process: process });

      this.set('tab', tab);
    },
    renderTemplate: function renderTemplate() {
      this.render('session.agent.search');
    },


    actions: {
      willTransition: function willTransition(transition) {
        var processManager = this.get('processManager');
        processManager.setForegroundProcess(null);

        var tabStore = this.get('tabStore');
        var activeTab = tabStore.get('activeTab');
        this.controllerFor('session.agent.search').get('search').cancelAll();

        var searchController = this.controllerFor('session.agent.search');

        if (activeTab && transition.targetName === 'session.agent.search') {
          searchController.set('transition', transition);

          var tabIndex = tabStore.get('tabs').indexOf(activeTab);
          searchController.set('tabIndex', tabIndex);
        }
      },
      didTransition: function didTransition() {
        var searchController = this.controllerFor('session.agent.search');

        //Reset Search Result entities
        searchController.set('searchTerm', '');
        searchController.set('showResults', false);
        searchController.set('tabIndex', null);

        // Ensuring search element gets focused.
        searchController.focusInput();

        return true;
      }
    }
  });
});