define('frontend-cp/components/ko-admin/facebook/index/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var filterBy = Ember.computed.filterBy;
  var $ = Ember.$;
  var A = Ember.A;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend({
    notifications: service('notification'),
    i18n: service(),
    store: service(),
    confirmation: service(),
    cookies: service(),

    pages: null,
    availablePages: null,
    saveInProgress: false,
    showModal: false,

    onPagesAdded: function onPagesAdded() {},


    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);

      var pages = this.get('pages');

      if (!pages) {
        this.set('pages', A());
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var showModal = this.get('showModal');

      if (showModal) {
        this.get('fetchAvailablePages').perform();
      }
    },


    // CPs
    enabledPages: filterBy('pages', 'isEnabled', true),
    disabledPages: filterBy('pages', 'isEnabled', false),
    submitDisabled: computed('availablePages.@each.import', function () {
      return (this.get('availablePages') || []).filter(function (page) {
        return !!page.get('import');
      }).length <= 0;
    }),

    // Tasks
    fetchAvailablePages: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var _this = this;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('store').query('facebook-page', { state: 'AVAILABLE' }).then(function (pages) {
                _this.set('availablePages', pages);
              });

            case 2:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    // Actions
    actions: {
      redirectToFacebookAuthenticationEndpoint: function redirectToFacebookAuthenticationEndpoint(isPageReconnect, e) {
        var _this2 = this;

        e.stopPropagation();

        var store = this.get('store');

        store.queryRecord('oauth-link', { callback: '/admin/channels/facebook/callback' }).then(function (link) {
          _this2.get('cookies').write('is_facebook_page_reconnect', isPageReconnect, {
            maxAge: 300,
            path: '/admin/channels'
          });
          window.location.href = link.get('id');
        });
      },
      toggleEnabled: function toggleEnabled(page, e) {
        var _this3 = this;

        e.stopPropagation();
        page.toggleProperty('isEnabled');
        page.save().then(function () {
          var notificationMessage = _this3.get('i18n').t(page.get('isEnabled') ? 'admin.facebook.enabled.message' : 'admin.facebook.disabled.message');
          _this3.get('notifications').success(notificationMessage);
        });
      },
      deletePage: function deletePage(page, e) {
        var _this4 = this;

        e.stopPropagation();
        this.get('confirmation').confirm({ intlConfirmationBody: 'generic.confirm.delete' }).then(function () {
          page.destroyRecord().then(function () {
            _this4.get('notifications').success(_this4.get('i18n').t('admin.facebook.deleted.message'));
          });
        });
      },
      editPage: function editPage(page, event) {
        if (event && $(event.target).closest('a').length) {
          return;
        }

        this.get('on-edit')(page);
      },
      importPages: function importPages() {
        var _this5 = this;

        var adapter = getOwner(this).lookup('adapter:application');
        var url = adapter.namespace + '/facebook/pages';

        this.set('saveInProgress', true);

        adapter.ajax(url, 'POST', {
          data: {
            page_ids: this.get('availablePages').filterBy('import', true).map(function (page) {
              return page.get('id');
            }).join(',')
          }
        }).then(function (pagesPayload) {
          _this5.get('store').pushPayload(pagesPayload);

          var pages = pagesPayload.facebook_pages.map(function (page) {
            return _this5.get('store').peekRecord('facebook-page', page.id)._internalModel;
          });

          _this5.get('pages').addObjects(pages);

          _this5.set('saveInProgress', false);

          _this5.attrs.onPagesAdded();
        }, function () {
          _this5.set('saveInProgress', false);
        });
      }
    }
  });
});