define('frontend-cp/services/process-manager', ['exports', 'ember-concurrency', 'frontend-cp/utils/bugsnag', 'frontend-cp/config/environment'], function (exports, _emberConcurrency, _bugsnag, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Service = Ember.Service;
  var emberArray = Ember.A;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  var debounce = Ember.run.debounce;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({
    processes: null,
    foregroundProcess: null,

    localStorage: service('localStore'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('processes', emberArray());
    },


    // Public API

    getProcessByPid: function getProcessByPid(pid) {
      var processes = this.get('processes');
      return processes.findBy('pid', pid);
    },
    getOrCreateProcess: function getOrCreateProcess(obj, type) {
      (false && !(type) && Ember.assert('process type is required', type));


      var process = this._getProcessForModel(obj, type);

      if (!process) {
        var Process = this._getProcessFactory(type);
        var pid = Process.class.generatePid(obj);
        var processes = this.get('processes');

        process = Process.create({ pid: pid });
        processes.pushObject(process);
        process.get('initialize').perform(obj).then(this._scheduleStashProcesses.bind(this)).catch(function (error) {
          if (!(0, _emberConcurrency.didCancel)(error)) {
            throw error;
          }
        });
      }

      return process;
    },
    restoreProcesses: function restoreProcesses() {
      var _this = this;

      var localStorage = this.get('localStorage');
      var data = localStorage.getItem(_environment.default.localStore.defaultNamespace, 'processes') || [];

      return RSVP.all(data.map(function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(json) {
          var Process, process, context;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  _context.prev = 0;

                  // TODO - remove this once no-one has old process JSON anymore
                  json = _this._convertLegacyJSON(json);
                  Process = _this._getProcessFactory(json.type);
                  process = Process.create({ pid: json.pid });
                  _context.next = 6;
                  return process.get('restore').perform(json);

                case 6:

                  _this.get('processes').pushObject(process);
                  _context.next = 12;
                  break;

                case 9:
                  _context.prev = 9;
                  _context.t0 = _context['catch'](0);

                  if (!Ember.testing && window.Bugsnag) {
                    context = (0, _bugsnag.getMetaData)(null, getOwner(_this));

                    context.json = json;
                    window.Bugsnag.notifyException(_context.t0, 'Failed to restore process', context, 'info');
                  }

                case 12:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, _this, [[0, 9]]);
        }));

        return function (_x) {
          return _ref.apply(this, arguments);
        };
      }()));
    },
    destroyProcess: function destroyProcess(process) {
      var processes = this.get('processes');
      var currentProcess = this.get('foregroundProcess');

      if (processes.includes(process)) {
        processes.removeObject(process);
        this._scheduleStashProcesses();
      }

      if (currentProcess === process) {
        this.setForegroundProcess(null);
      }

      process.trigger('willDestroy');

      process.destroy();
    },
    setForegroundProcess: function setForegroundProcess(process) {
      var currentProcess = this.get('foregroundProcess');

      if (currentProcess) {
        currentProcess.trigger('willBackground');
      }

      if (process) {
        process.trigger('willForeground');
      }

      this.set('foregroundProcess', process);
    },


    // Private API

    _getProcessForModel: function _getProcessForModel(model, type) {
      var Process = this._getProcessFactory(type);
      var pid = Process.class.generatePid(model);

      return this.getProcessByPid(pid);
    },
    _convertLegacyJSON: function _convertLegacyJSON(json) {
      if (!json.type) {
        json.type = json.pid.split(':')[0];
      }

      if (!json.modelId && json.caseId) {
        json.modelId = json.caseId;
      }

      return json;
    },
    _getProcessFactory: function _getProcessFactory(type) {
      return getOwner(this).factoryFor('process:' + type);
    },
    _scheduleStashProcesses: function _scheduleStashProcesses() {
      debounce(this, '_stashProcesses', 100);
    },
    _stashProcesses: function _stashProcesses() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var localStorage = this.get('localStorage');
      var processes = this.get('processes') || [];

      localStorage.setItem(_environment.default.localStore.defaultNamespace, 'processes', processes.map(function (process) {
        return process.serialize();
      }));
    }
  });
});