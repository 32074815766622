define('frontend-cp/models/article', ['exports', 'ember-data', 'frontend-cp/utils/locale'], function (exports, _emberData, _locale) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    allowComments: attr('boolean'),
    isFeatured: attr('boolean'),
    attachments: hasMany('attachment', { async: false }),
    author: belongsTo('user-minimal', { async: false }),
    creator: belongsTo('user-minimal', { async: false }),
    contents: attr('string'),
    downloadAll: attr('string'),
    keywords: attr('string'),
    slug: attr('string'),
    status: attr('string'),
    helpcenterUrl: attr('string'),
    titles: hasMany('locale-field', { async: false }),
    rank: attr('number'),
    rating: attr('number'),
    views: attr('number'),
    totalComments: attr('number'),
    tags: hasMany('tag', { async: true }),
    section: belongsTo('section', { async: false }),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    uuid: attr('string'),

    locale: service(),
    session: service(),
    titleLocale: computed('titles.[]', 'locale.accountDefaultLocaleCode', 'session.user.locale.locale', function () {
      return (0, _locale.findLocaleFieldBySettings)(this.get('titles'), this.get('locale'), this.get('session'));
    }),
    title: computed.readOnly('titleLocale.translation')
  });
});