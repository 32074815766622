define("frontend-cp/session/admin/manage/case-forms/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "session_admin_manage_case-forms_index__title_chsh6p",
    "caption": "session_admin_manage_case-forms_index__caption_chsh6p"
  };
});