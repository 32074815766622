define("frontend-cp/components/ko-table/row/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-table_row__container_1hsbcc",
    "selected": "ko-table_row__selected_1hsbcc"
  };
});