define('frontend-cp/helpers/ko-from-now', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var runBind = Ember.run.bind;
  var observer = Ember.observer;
  var get = Ember.get;
  var Helper = Ember.Helper;
  var service = Ember.inject.service;
  exports.default = Helper.extend({
    serverClock: service(),
    date: service(),
    timeFormatter: service(),
    moment: service(),

    localeOrTimeZoneChanged: observer('moment.locale', 'moment.timeZone', function () {
      this.recompute();
    }),

    compute: function compute(params, _ref) {
      var hideSuffix = _ref.hideSuffix,
          locale = _ref.locale,
          timeZone = _ref.timeZone,
          customRelativeTime = _ref.customRelativeTime,
          _ref$short = _ref.short,
          short = _ref$short === undefined ? false : _ref$short;

      if (!params[0]) {
        return '-';
      }
      var value = (0, _moment.default)(params[0]);
      var timeFormatter = this.get('timeFormatter');
      var interval = timeFormatter.calculateAppropriateInterval(value);
      var serverClock = this.get('serverClock');

      this.clearTimer();
      if (interval && !Ember.testing) {
        this.intervalTimer = setTimeout(runBind(this, this.recompute), interval);
      }

      var morphedMoment = this.morphMoment((0, _moment.default)(value), { locale: locale, timeZone: timeZone });

      if (customRelativeTime) {
        return timeFormatter.getLocalizedOutput(serverClock.getServerTime(), morphedMoment, hideSuffix);
      } else if (short) {
        return timeFormatter.shortFormatRelative(serverClock.getServerTime(), morphedMoment);
      } else {
        return morphedMoment.from(serverClock.getServerTime(), hideSuffix) || '-';
      }
    },
    morphMoment: function morphMoment(time, _ref2) {
      var locale = _ref2.locale,
          timeZone = _ref2.timeZone;

      var momentService = get(this, 'moment');

      locale = locale || get(momentService, 'locale');
      timeZone = timeZone || get(momentService, 'timeZone');

      if (locale && time.locale) {
        time = time.locale(locale);
      }

      if (timeZone && time.tz) {
        time = time.tz(timeZone);
      }

      return time;
    },
    clearTimer: function clearTimer() {
      clearTimeout(this.intervalTimer);
    },
    destroy: function destroy() {
      this.clearTimer();
      this._super.apply(this, arguments);
    }
  });
});