define("frontend-cp/components/ko-admin/messenger-persistent/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "messenger-page": "ko-admin_messenger-persistent__messenger-page_1krapp",
    "page-title": "ko-admin_messenger-persistent__page-title_1krapp",
    "inline-loader": "ko-admin_messenger-persistent__inline-loader_1krapp",
    "container": "ko-admin_messenger-persistent__container_1krapp",
    "column": "ko-admin_messenger-persistent__column_1krapp",
    "column--options": "ko-admin_messenger-persistent__column--options_1krapp",
    "column--preview": "ko-admin_messenger-persistent__column--preview_1krapp",
    "tabs": "ko-admin_messenger-persistent__tabs_1krapp",
    "tabs__item": "ko-admin_messenger-persistent__tabs__item_1krapp",
    "is-active": "ko-admin_messenger-persistent__is-active_1krapp",
    "tabs__number": "ko-admin_messenger-persistent__tabs__number_1krapp",
    "tab-container": "ko-admin_messenger-persistent__tab-container_1krapp",
    "tab-content": "ko-admin_messenger-persistent__tab-content_1krapp",
    "tab-content__wrapper": "ko-admin_messenger-persistent__tab-content__wrapper_1krapp",
    "label": "ko-admin_messenger-persistent__label_1krapp",
    "no-top-margin": "ko-admin_messenger-persistent__no-top-margin_1krapp",
    "widget-title": "ko-admin_messenger-persistent__widget-title_1krapp",
    "widget": "ko-admin_messenger-persistent__widget_1krapp",
    "is-disabled": "ko-admin_messenger-persistent__is-disabled_1krapp",
    "widget__label": "ko-admin_messenger-persistent__widget__label_1krapp",
    "widget__content": "ko-admin_messenger-persistent__widget__content_1krapp",
    "widget__switch": "ko-admin_messenger-persistent__widget__switch_1krapp",
    "widget__help-text": "ko-admin_messenger-persistent__widget__help-text_1krapp",
    "widget__dropdown": "ko-admin_messenger-persistent__widget__dropdown_1krapp",
    "copy-button": "ko-admin_messenger-persistent__copy-button_1krapp ko-button__primary_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "trigger": "ko-admin_messenger-persistent__trigger_1krapp _trigger_jvqwfg _statusIcon_jvqwfg",
    "dropdown": "ko-admin_messenger-persistent__dropdown_1krapp _dropdown_jvqwfg",
    "next-button-spacer": "ko-admin_messenger-persistent__next-button-spacer_1krapp",
    "text--small": "ko-admin_messenger-persistent__text--small_1krapp",
    "text--light": "ko-admin_messenger-persistent__text--light_1krapp",
    "mbottom": "ko-admin_messenger-persistent__mbottom_1krapp",
    "mbottom--tiny": "ko-admin_messenger-persistent__mbottom--tiny_1krapp",
    "mbottom--medium": "ko-admin_messenger-persistent__mbottom--medium_1krapp",
    "sdk-button": "ko-admin_messenger-persistent__sdk-button_1krapp",
    "nudge-upward": "ko-admin_messenger-persistent__nudge-upward_1krapp"
  };
});