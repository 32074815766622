define("frontend-cp/components/lightning-preview-ribbon/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "ribbon": "lightning-preview-ribbon__ribbon_45cl8g",
    "button": "lightning-preview-ribbon__button_45cl8g"
  };
});