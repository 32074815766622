define('frontend-cp/components/ko-info-bar/viewing-users/component', ['exports', 'ember-concurrency', 'frontend-cp/config/environment', 'frontend-cp/utils/presence', 'moment'], function (exports, _emberConcurrency, _environment, _presence, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var assign = Ember.assign;


  var inactiveThreshold = _environment.default.APP.viewingUsersInactiveThreshold;
  var taskTimeoutInterval = 5000; // 5 seconds

  exports.default = Component.extend({
    tagName: '',

    sessionService: service('session'),

    now: null,

    // Attributes
    presence: null,

    viewingUsers: computed('presence', 'tick', 'sessionService.user.id', function () {
      var presence = this.get('presence');
      if (!presence) {
        return [];
      }

      var now = new Date().getTime();
      var userID = this.get('sessionService.user.id');

      var metas = (0, _presence.list)(presence, function (id, _ref) {
        var metas = _ref.metas;

        var lastActiveTS = Math.max.apply(Math, _toConsumableArray(metas.map(function (m) {
          return m.last_active_at || 0;
        })));
        var typing = metas.any(function (m) {
          return m.is_typing;
        });
        var isViewing = metas.any(function (m) {
          return m.is_viewing;
        });
        var isForeground = metas.any(function (m) {
          return m.is_foreground;
        });
        var isUpdating = metas.any(function (m) {
          return m.is_updating;
        });
        var inactive = !isForeground || now - lastActiveTS > inactiveThreshold;
        var lastActiveAt = lastActiveTS ? (0, _moment.default)(lastActiveTS) : null;

        return assign({}, metas[0], {
          id: id,
          typing: typing,
          lastActiveAt: lastActiveAt,
          inactive: inactive,
          isViewing: isViewing,
          isForeground: isForeground,
          isUpdating: isUpdating
        });
      });

      return metas.filterBy('isViewing').filter(function (m) {
        return m.user && String(m.user.id) !== userID;
      });
    }),

    // causes viewingUsers to recalculate regardless of whether any presence info has changed
    // so that we can update the `inactive` field
    tickTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!Ember.testing) {
                _context.next = 2;
                break;
              }

              return _context.abrupt('return');

            case 2:
              if (!true) {
                _context.next = 8;
                break;
              }

              // eslint-disable-line
              this.set('tick', new Date().getTime());
              _context.next = 6;
              return (0, _emberConcurrency.timeout)(taskTimeoutInterval);

            case 6:
              _context.next = 2;
              break;

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).on('init')

  });
});