define("frontend-cp/components/ko-admin/localization/list-row/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "name": "ko-admin_localization_list-row__name_gh88o0",
    "caption": "ko-admin_localization_list-row__caption_gh88o0"
  };
});