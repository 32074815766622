define('frontend-cp/components/ko-toggle/component', ['exports', 'frontend-cp/lib/keycodes', 'frontend-cp/mixins/with-unique-id'], function (exports, _keycodes, _withUniqueId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_withUniqueId.default, {
    tagName: '',

    // Attributes
    label: '',
    qaClass: null,
    activated: null,
    onToggle: null,
    isDisabled: false,

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('activated')) {
        this.set('activated', false);
      }
    },


    // Actions
    actions: {
      keyDown: function keyDown(e) {
        if (e.keyCode === _keycodes.space) {
          return false;
        }
      },
      keyUp: function keyUp(e) {
        if (e.keyCode === _keycodes.space) {
          this.send('toggleRadio');
        }
        return false;
      },
      toggleRadio: function toggleRadio() {
        if (this.get('isDisabled')) {
          return;
        } else if (this.onToggle) {
          this.sendAction('onToggle', !this.get('activated'));
        } else {
          this.toggleProperty('activated');
        }
      }
    }
  });
});