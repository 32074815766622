define('frontend-cp/components/ko-search-suggestions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Attributes:
    activePage: null,
    target: null,

    tagName: '',

    searchSuggestions: service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.get('searchSuggestions').setRoot(this.get('activePage'), this.get('target'));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('searchSuggestions').clearRoute();
    }
  });
});