define('frontend-cp/components/ko-pagination/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;


  var LINKS_EITHER_SIDE_OF_CURRENT_PAGE = 2;

  exports.default = Component.extend({
    tagName: '',
    currentPage: 1,
    pageCount: 1,

    pagesToDisplay: computed('currentPage', 'pageCount', function () {
      var currentPage = this.get('currentPage');
      var lastPage = this.get('pageCount');
      var left = currentPage - LINKS_EITHER_SIDE_OF_CURRENT_PAGE;
      var right = currentPage + LINKS_EITHER_SIDE_OF_CURRENT_PAGE + 1;
      var range = [];
      var rangeWithDots = [];
      var l = void 0;

      for (var i = 1; i <= lastPage; i++) {
        if (i === 1 || i === lastPage || i >= left && i < right) {
          range.push(i);
        }
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = range[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _i = _step.value;

          if (l) {
            if (_i - l === 2) {
              rangeWithDots.push(l + 1);
            } else if (_i - l !== 1) {
              rangeWithDots.push('...');
            }
          }
          rangeWithDots.push(_i);
          l = _i;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return rangeWithDots;
    })
  });
});