define('frontend-cp/components/ko-admin/triggers/index/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var filterBy = Ember.computed.filterBy;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Attributes
    triggers: [],
    edit: null,

    // Services
    store: service(),
    i18n: service(),
    notification: service(),
    confirmation: service(),

    // CPs
    enabledTriggers: filterBy('triggers', 'isEnabled', true),
    disabledTriggers: filterBy('triggers', 'isEnabled', false),
    sortedEnabledTriggers: computed('enabledTriggers.@each.executionOrder', function () {
      return this.get('enabledTriggers').toArray().sortBy('executionOrder');
    }),
    sortedDisabledTriggers: computed('disabledTriggers.@each.title', function () {
      return this.get('disabledTriggers').toArray().sortBy('title');
    }),

    // Actions
    actions: {
      toggleStatus: function toggleStatus(trigger, e) {
        e.stopPropagation();
        e.preventDefault();
        var numberOfEnabledTriggers = this.get('enabledTriggers.length');
        var isEnabled = trigger.toggleProperty('isEnabled');
        trigger.set('executionOrder', isEnabled ? numberOfEnabledTriggers : null);
        trigger.save();
      },
      delete: function _delete(trigger, e) {
        var _this = this;

        e.preventDefault();
        e.stopPropagation();
        this.get('confirmation').confirm({ intlConfirmationBody: 'generic.confirm.delete' }).then(function () {
          return trigger.destroyRecord();
        }).then(function () {
          return _this.get('notification').success(_this.get('i18n').t('admin.triggers.delete.success_message'));
        });
      },
      reorder: function reorder(newOrder) {
        var _this2 = this;

        var data = { trigger_ids: newOrder.mapBy('id') };
        var appInstance = getOwner(this);
        var applicationAdapter = appInstance.lookup('adapter:application');
        var url = applicationAdapter.urlPrefix() + '/triggers/reorder';
        newOrder.forEach(function (trigger, index) {
          return trigger.set('executionOrder', index);
        });
        applicationAdapter.ajax(url, 'PUT', { data: data }).then(function (response) {
          _this2.get('store').pushPayload('triggers', response);
        });
      }
    }
  });
});