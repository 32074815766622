define('frontend-cp/session/admin/apps/api/index/controller', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    metrics: service(),
    i18n: service(),
    permissions: service(),

    tabs: computed(function () {
      return [{
        label: this.get('i18n').t('admin.apps.api.tabs.api'),
        routeName: 'session.admin.apps.api.index',
        dynamicSegments: []
      }, {
        label: this.get('i18n').t('admin.apps.api.tabs.oauth_apps'),
        routeName: 'session.admin.apps.api.oauth-apps',
        dynamicSegments: []
      }];
    }),

    canManageOAuthApps: computed(function () {
      return this.get('permissions').has('apps.manage');
    }),

    actions: {
      ctaClick: function ctaClick() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('ops-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'API - View landing page',
            category: 'Admin'
          });
        }
      }
    }
  });
});