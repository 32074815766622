define('frontend-cp/session/admin/customizations/organization-fields/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    customFields: service('custom-fields'),
    notification: service(),
    i18n: service(),
    session: service(),
    confirmation: service(),

    // CPs
    tabs: computed(function () {
      return [{
        id: 'conversation',
        label: this.get('i18n').t('admin.casefields.title'),
        routeName: 'session.admin.customizations.case-fields',
        dynamicSegments: [],
        queryParams: null
      }, {
        id: 'organization',
        label: this.get('i18n').t('admin.organizationfields.title'),
        routeName: 'session.admin.customizations.organization-fields',
        dynamicSegments: [],
        queryParams: null
      }, {
        id: 'user',
        label: this.get('i18n').t('admin.userfields.title'),
        routeName: 'session.admin.customizations.user-fields',
        dynamicSegments: [],
        queryParams: null
      }];
    }),

    customfields: computed('model.@each.isEnabled', 'model.@each.sortOrder', function () {
      return this.get('model').filter(function (field) {
        return field.get('isEnabled') && !field.get('isSystem');
      }).sortBy('sortOrder');
    }),

    disabledfields: computed('model.@each.isEnabled', function () {
      return this.get('model').filter(function (field) {
        return !field.get('isEnabled');
      });
    }),

    // Actions
    actions: {
      transitionToNewFieldRoute: function transitionToNewFieldRoute() {
        this.transitionToRoute('session.admin.customizations.organization-fields.select-type');
      },
      toggleEnabledStatus: function toggleEnabledStatus(field, e) {
        var _this = this;

        e.stopPropagation();
        this.get('customFields').toggleEnabled(field).then(function () {
          var notificationMessage = void 0;
          if (field.get('isEnabled')) {
            notificationMessage = _this.get('i18n').t('admin.organizationfields.enabled.success_message');
          } else {
            notificationMessage = _this.get('i18n').t('admin.organizationfields.disabled.success_message');
          }

          _this.get('notification').success(notificationMessage);
        });
      },
      delete: function _delete(field, e) {
        var _this2 = this;

        e.preventDefault();
        e.stopPropagation();

        return this.get('confirmation').confirm({
          intlConfirmationBody: 'generic.confirm.delete'
        }).then(function () {
          return field.destroyRecord();
        }).then(function () {
          var msg = _this2.get('i18n').t('admin.organizationfields.delete.success_message');
          _this2.get('notification').success(msg);
        });
      },
      editField: function editField(field) {
        this.transitionToRoute('session.admin.customizations.organization-fields.edit', field.id);
      },
      reorderCustomFields: function reorderCustomFields(orderedCustomFields) {
        this.get('customFields').reorder(this.get('model'), orderedCustomFields, this.get('session.sessionId'));
      }
    }
  });
});