define("frontend-cp/components/ko-case/macro-selector/trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-case_macro-selector_trigger__trigger_7wpnlb",
    "icon": "ko-case_macro-selector_trigger__icon_7wpnlb"
  };
});