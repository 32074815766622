define("frontend-cp/components/side-conversations-panel/individual-conversation/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "side-panel-header": "side-conversations-panel_individual-conversation__side-panel-header_klpnx1",
    "chat-container": "side-conversations-panel_individual-conversation__chat-container_klpnx1",
    "back-icon": "side-conversations-panel_individual-conversation__back-icon_klpnx1",
    "title": "side-conversations-panel_individual-conversation__title_klpnx1",
    "subtitle": "side-conversations-panel_individual-conversation__subtitle_klpnx1",
    "to-input": "side-conversations-panel_individual-conversation__to-input_klpnx1",
    "cc-input": "side-conversations-panel_individual-conversation__cc-input_klpnx1",
    "subject-input": "side-conversations-panel_individual-conversation__subject-input_klpnx1",
    "send-button": "side-conversations-panel_individual-conversation__send-button_klpnx1",
    "content": "side-conversations-panel_individual-conversation__content_klpnx1",
    "buttonLoading": "side-conversations-panel_individual-conversation__buttonLoading_klpnx1",
    "reply-box": "side-conversations-panel_individual-conversation__reply-box_klpnx1",
    "refresh-button": "side-conversations-panel_individual-conversation__refresh-button_klpnx1"
  };
});