define('frontend-cp/components/ko-admin/case-forms/edit/fields/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var filterBy = Ember.computed.filterBy;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    caseFields: [],
    availableCaseFields: [],
    onCaseFieldAddition: function onCaseFieldAddition() {},
    onCaseFieldRemoval: function onCaseFieldRemoval() {},
    onUpdate: function onUpdate() {},

    // Services
    store: service(),

    systemCaseFields: filterBy('caseFields', 'isSystem', true),

    customCaseFields: filterBy('caseFields', 'isSystem', false),

    unusedCaseFields: computed('caseFields.[]', 'availableCaseFields.@each.isSystem', function () {
      var usedFields = this.get('caseFields');

      return this.get('availableCaseFields').filter(function (field) {
        return usedFields.indexOf(field) === -1;
      });
    }),

    actions: {
      reorderFields: function reorderFields(reorderedFields) {
        this.get('onUpdate')(reorderedFields);
      }
    }
  });
});