define("frontend-cp/application/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "application__container_16lizu",
    "app-wrapper": "application__app-wrapper_16lizu",
    "notifications": "application__notifications_16lizu"
  };
});