define('frontend-cp/session/admin/manage/brands/edit/templates/controller', ['exports', 'ember-service/inject', 'ember-concurrency', 'frontend-cp/services/virtual-model'], function (exports, _inject, _emberConcurrency, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var or = Ember.computed.or;
  var oneWay = Ember.computed.oneWay;
  var Controller = Ember.Controller;
  var RSVP = Ember.RSVP;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;


  var schema = (0, _virtualModel.model)('template', {
    contents: (0, _virtualModel.attr)()
  });

  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    // Attributes
    brand: null,
    templates: null,

    // State
    editedTemplates: null,

    // Services
    i18n: service(),
    notification: service(),
    virtualModel: service(),
    metrics: service(),

    // CPs
    tabs: computed('brand.id', function () {
      return [{
        label: this.get('i18n').t('admin.manage.brands.edit.tabs.settings'),
        routeName: 'session.admin.manage.brands.edit.index',
        dynamicSegments: [this.get('brand.id')],
        queryParams: null
      }, {
        label: this.get('i18n').t('admin.manage.brands.edit.tabs.email_templates'),
        routeName: 'session.admin.manage.brands.edit.templates',
        dynamicSegments: [this.get('brand.id')],
        queryParams: null
      }];
    }),

    isEmailCaseNotificationEdited: computed('templates.emailCaseNotification.contents', 'editedTemplates.emailCaseNotification.contents', function () {
      return this.get('templates.emailCaseNotification.contents') !== this.get('editedTemplates.emailCaseNotification.contents');
    }),

    isEmailNotificationEdited: computed('templates.emailNotification.contents', 'editedTemplates.emailNotification.contents', function () {
      return this.get('templates.emailNotification.contents') !== this.get('editedTemplates.emailNotification.contents');
    }),

    isEmailSatisfactionEdited: computed('templates.emailSatisfaction.contents', 'editedTemplates.emailSatisfaction.contents', function () {
      return this.get('templates.emailSatisfaction.contents') !== this.get('editedTemplates.emailSatisfaction.contents');
    }),

    hasChanges: or('isEmailCaseNotificationEdited', 'isEmailNotificationEdited', 'isEmailSatisfactionEdited'),

    isEdited: oneWay('hasChanges'),

    // Tasks
    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var launchDarkly, virtualModel, _get, emailCaseNotification, emailNotification, emailSatisfaction, editedTemplates;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');
              virtualModel = this.get('virtualModel');
              _get = this.get('templates'), emailCaseNotification = _get.emailCaseNotification, emailNotification = _get.emailNotification, emailSatisfaction = _get.emailSatisfaction;
              editedTemplates = this.get('editedTemplates');
              _context.prev = 4;
              _context.next = 7;
              return RSVP.hash({
                emailCaseNotification: virtualModel.save(emailCaseNotification, editedTemplates.get('emailCaseNotification'), schema),
                emailNotification: virtualModel.save(emailNotification, editedTemplates.get('emailNotification'), schema),
                emailSatisfaction: virtualModel.save(emailSatisfaction, editedTemplates.get('emailSatisfaction'), schema)
              });

            case 7:

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('generic.changes_saved'),
                autodismiss: true
              });

              if (launchDarkly.get('release-event-tracking')) {
                this.get('metrics').trackEvent({
                  event: 'email_template_edited'
                });
              }

              this.transitionToRoute('session.admin.manage.brands.edit.index', this.get('brand.id'));
              _context.next = 14;
              break;

            case 12:
              _context.prev = 12;
              _context.t0 = _context['catch'](4);

            case 14:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[4, 12]]);
    })).drop(),

    // Actions
    actions: {
      cancel: function cancel() {
        this.transitionToRoute('session.admin.manage.brands.edit.index', this.get('brand.id'));
      },
      editorUpdated: function editorUpdated(contents) {
        this.set('editedTemplates.emailCaseNotification.contents', contents);
      }
    },

    // Methods
    initEdits: function initEdits() {
      var _get2 = this.get('templates'),
          emailCaseNotification = _get2.emailCaseNotification,
          emailNotification = _get2.emailNotification,
          emailSatisfaction = _get2.emailSatisfaction;

      this.set('editedTemplates', EmberObject.create({
        emailCaseNotification: this.get('virtualModel').makeSnapshot(emailCaseNotification, schema),
        emailNotification: this.get('virtualModel').makeSnapshot(emailNotification, schema),
        emailSatisfaction: this.get('virtualModel').makeSnapshot(emailSatisfaction, schema)
      }));
    }
  });
});