define('frontend-cp/models/identity-twitter', ['exports', 'ember-data', 'frontend-cp/models/identity'], function (exports, _emberData, _identity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _identity.default.extend({
    twitterId: _emberData.default.attr('string'),
    fullName: _emberData.default.attr('string'),
    screenName: _emberData.default.attr('string'),
    followerCount: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    profileImageUrl: _emberData.default.attr('string'),
    locale: _emberData.default.attr('string'),

    // Relations
    user: _emberData.default.belongsTo('user', { async: true }),

    // CPs
    canBeValidated: false
  });
});