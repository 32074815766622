define('frontend-cp/components/ko-info-bar/field/select-multiple/trigger/component', ['exports', 'ember-power-select/components/power-select-multiple/trigger', 'frontend-cp/components/ko-info-bar/field/select-multiple/trigger/template', 'frontend-cp/utils/dispatch-event', 'frontend-cp/lib/keycodes'], function (exports, _trigger, _template, _dispatchEvent, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var run = Ember.run;
  var observer = Ember.observer;
  var isBlank = Ember.isBlank;
  var jQuery = Ember.$;
  exports.default = _trigger.default.extend({
    layout: _template.default,

    // Observers
    isOpenObserver: observer('select.isOpen', function () {
      var isOpen = this.get('select.isOpen');
      var wasOpen = this.get('wasOpen');

      if (!wasOpen && isOpen) {
        this._openedAt = new Date();
        run.schedule('afterRender', this, function () {
          jQuery('#ember-power-select-multiple-options-' + this.get('select.uniqueId') + ' input').focus();
        });
      }

      this.set('wasOpen', isOpen);
    }),

    // Actions
    actions: {
      keepOpened: function keepOpened(e) {
        if (this.get('select.isOpen')) {
          e.stopPropagation();
        }
      },
      handleBlur: function handleBlur(e) {
        // We fake an enter keydown event here so it goes through the
        // usual power-select path of making a selection.
        // wiring up the blur event would require a fair bit of extra
        // code and the end result is the same.
        var select = this.get('select');
        if (select.isOpen && select.highlighted) {
          if (new Date() - this._openedAt < 200) {
            return;
          }
          (0, _dispatchEvent.default)(e.target, 'keydown', { keyCode: _keycodes.enter });
        } else if (select.results.length === 0 && !isBlank(select.searchText)) {
          (0, _dispatchEvent.default)(e.target, 'keydown', { keyCode: _keycodes.enter });
        }
      }
    }
  });
});