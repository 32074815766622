define('frontend-cp/components/ko-trial-notification/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    plan: service(),
    routing: service('-routing'),

    actions: {
      upgrade: function upgrade(dropdown) {
        dropdown.actions.close();
        this.get('routing').transitionTo('session.admin.account.trial');
      }
    }
  });
});