define("frontend-cp/components/ko-admin/monitors/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "ko-admin_monitors_index__title_11ubhd",
    "caption": "ko-admin_monitors_index__caption_11ubhd"
  };
});