define('frontend-cp/adapters/business-hour', ['exports', 'frontend-cp/adapters/application', 'frontend-cp/adapters/unpanginate-mixin'], function (exports, _application, _unpanginateMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_unpanginateMixin.default, {
    pathForType: function pathForType() {
      return 'businesshours';
    }
  });
});