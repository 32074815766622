define('frontend-cp/components/ko-tab-strip/case-tab/new-messages-pill/component', ['exports', 'ember-service/inject', 'ember-diff-attrs', 'frontend-cp/services/sound-alerts'], function (exports, _inject, _emberDiffAttrs, _soundAlerts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    tagName: '',

    soundAlerts: service(),
    window: service(),
    browserTab: service(),

    // Public API
    unreadCount: 0,

    // Internal State
    initialCount: 0,
    countText: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('initialCount', this.get('unreadCount'));
    },


    didReceiveAttrs: (0, _emberDiffAttrs.default)('unreadCount', function (changedAttrs) {
      var launchDarkly = this.get('launchDarkly');
      var launchDarkly = this.get('launchDarkly');

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      if (!changedAttrs || changedAttrs.unreadCount) {
        var count = this._calculateCount();

        if (count > 0) {
          var text = this._generateCountText(count);

          this.set('countText', text);

          if (launchDarkly.get('release-live-chat-notification-improvements')) {
            this.get('browserTab').registerAppUpdate();

            if (launchDarkly.get('ops-audible-reply-alert-only-when-browser-not-focussed')) {
              var windowIsFocussed = this.get('window.visible');

              if (!windowIsFocussed) {
                this._playSound();
              }
            } else {
              this._playSound();
            }
          }
        } else {
          this.set('countText', null);
        }
      }
    }),

    _calculateCount: function _calculateCount() {
      var count = this.get('unreadCount') - this.get('initialCount');
      return count < 0 ? 0 : count;
    },
    _generateCountText: function _generateCountText(count) {
      if (count > 9) {
        return '9+';
      } else {
        return count;
      }
    },
    _playSound: function _playSound() {
      return this.get('soundAlerts').play(_soundAlerts.DONK);
    }
  });
});