define('frontend-cp/adapters/organization-note', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = new Ember.Inflector(Ember.Inflector.defaultRules);

  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'notes';
    },
    urlForQuery: function urlForQuery(query, modelName) {
      var parent = query.parent;
      if (parent) {
        Reflect.deleteProperty(query, 'parent');
        var id = parent.id;
        var url = this._super.apply(this, arguments);
        var pathForType = this.pathForType();
        return url.replace(pathForType, inflector.pluralize(parent._internalModel.modelName) + '/' + id + '/' + pathForType);
      }
      return this._super.apply(this, arguments);
    },
    urlForCreateRecord: function urlForCreateRecord(modelType, snapshot) {
      var pathForType = this.pathForType();
      var url = this._super.apply(this, arguments);
      return url.replace(pathForType, 'organizations/' + snapshot.record.get('organization.id') + '/' + pathForType);
    },
    query: function query(store, type, _query) {
      var organization = _query.parent;

      return this._super.apply(this, arguments).then(function (payload) {
        payload.data.forEach(function (entry) {
          entry.parent = {
            id: organization.get('id'),
            type: 'organization'
          };
        });

        // Need to unload posts for the current organization when we request new organization notes
        // because currently the API gives us notes, and we don't have the same API as in
        // case posts to draw the Profile Timeline. To reuse the current Timeline code
        // we have to push a "post" model. So when we request organization-notes, we
        // actually create a post model and we don't push the organization-note into the store.
        // @see serializers/organization-note
        store.peekAll('post').forEach(function (post) {
          if (parseInt(organization.get('id')) === parseInt(post.get('original.parent.id'))) {
            store.unloadRecord(post);
          }
        });

        return payload;
      });
    }
  });
});