define('frontend-cp/components/ko-email-iframe/component', ['exports', 'ember-resize/mixins/resize-aware'], function (exports, _resizeAware) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var guidFor = Ember.guidFor;
  var computed = Ember.computed;
  var jQuery = Ember.$;
  var run = Ember.run;
  var scheduleOnce = Ember.run.scheduleOnce;
  var debounce = Ember.run.debounce;


  var RESIZE_DEBOUNCE_INTERVAL = 100;

  exports.default = Component.extend(_resizeAware.default, {
    tagName: '',

    content: null,
    qaClass: null,
    height: null,

    eid: computed(function () {
      return 'ko-' + guidFor(this);
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      run.next(function () {
        var content = _this.get('content');

        if (content) {
          _this._updateContent(content);
          _this._handleIframeResize();
        }
      });
      scheduleOnce('afterRender', this, '_handleIframeResize', 50);
    },
    didResize: function didResize() {
      debounce(this, '_handleIframeResize', RESIZE_DEBOUNCE_INTERVAL);
    },


    actions: {
      iframeLoaded: function iframeLoaded() {
        var _this2 = this;

        run.next(function () {
          _this2._handleIframeResize();
        });
      }
    },

    _updateContent: function _updateContent(content) {
      var doc = jQuery('#' + this.get('eid'))[0].contentDocument;
      doc.open();
      doc.write('\n      <base target="_blank">\n      <!DOCTYPE html>\n      ' + content + '\n    ');
      doc.close();
    },
    _handleIframeResize: function _handleIframeResize() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      var $iframe = jQuery('#' + this.get('eid'))[0];

      if ($iframe) {
        var doc = $iframe.contentDocument;
        this.set('height', doc.body.scrollHeight + 10);
        this.set('width', doc.body.scrollWidth + 20);
      }
    }
  });
});