define("frontend-cp/session/agent/cases/index/suspended-messages/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "session_agent_cases_index_suspended-messages__container_2bvtoz",
    "title": "session_agent_cases_index_suspended-messages__title_2bvtoz",
    "heading": "session_agent_cases_index_suspended-messages__heading_2bvtoz",
    "not-found": "session_agent_cases_index_suspended-messages__not-found_2bvtoz",
    "not-found-image": "session_agent_cases_index_suspended-messages__not-found-image_2bvtoz",
    "not-found-message": "session_agent_cases_index_suspended-messages__not-found-message_2bvtoz"
  };
});