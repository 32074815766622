define('frontend-cp/components/ko-admin/sla/edit/operational-hours/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    sla: null,
    metric: null,

    // Services
    i18n: service(),

    // CPs
    selected: computed('sla.targets.@each.operationalHours', function () {
      var _this = this;

      var target = this.get('sla.targets').find(function (metric) {
        return metric.get('slaTargetType') === _this.get('metric');
      });
      var hoursId = target ? target.get('operationalHours') : 'BUSINESS_HOURS';
      return this.get('options').find(function (option) {
        return option.id === hoursId;
      });
    }),

    options: computed(function () {
      var _this2 = this;

      var t = function t(hours) {
        return _this2.get('i18n').t('admin.sla.edit.operational_hours.' + hours);
      };
      return [{ label: t('business_hours'), id: 'BUSINESS_HOURS' }, { label: t('calendar_hours'), id: 'CALENDAR_HOURS' }];
    }),

    actions: {
      onChange: function onChange(hours) {
        var _this3 = this;

        this.get('sla.targets').filter(function (metric) {
          return metric.get('slaTargetType') === _this3.get('metric');
        }).forEach(function (metric) {
          return metric.set('operationalHours', hours.id);
        });
      }
    }
  });
});