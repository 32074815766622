define('frontend-cp/components/ko-avatar-update/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    // Services
    i18n: service(),
    store: service(),
    notification: service(),
    confirmation: service(),
    errorHandler: service(),

    // Attributes
    user: null,
    dropdown: null,

    isLoading: computed('handleAvatarDeletion.isRunning', 'updateAvatar.isRunning', function () {
      return this.get('handleAvatarDeletion.isRunning') || this.get('updateAvatar.isRunning');
    }),

    options: computed(function () {
      var i18n = this.get('i18n');

      this.get('fetchAvatar').perform();

      return [{ label: i18n.t('users.upload_photo'), id: 'upload' }, { label: i18n.t('users.remove_photo'), id: 'remove' }];
    }),

    // Tasks
    removeAvatar: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(userid) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('store').adapterFor('user').deleteAvatar(userid);

            case 2:
              return _context.abrupt('return', _context.sent);

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    updateAvatar: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(userid, image) {
      var removeOption, data;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              removeOption = { label: this.get('i18n').t('users.remove_photo'), id: 'remove' };
              _context2.next = 3;
              return this.get('store').adapterFor('user').updateAvatar(userid, image);

            case 3:
              data = _context2.sent;


              this.set('user.avatar', data.data.url);
              this.get('user').save();

              if (this.get('options').filterBy('id', 'remove').length === 0) {
                this.get('options').pushObject(removeOption);
              }

            case 7:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).restartable(),

    fetchAvatar: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var _this = this;

      var userid;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              userid = this.get('user.id');
              _context3.next = 3;
              return this.get('errorHandler').disableWhile(function () {
                return _this.get('store').adapterFor('user').fetchAvatar(userid).catch(function (err) {
                  _this.get('options').popObject();
                });
              });

            case 3:
              return _context3.abrupt('return', _context3.sent);

            case 4:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).restartable(),

    handleAvatarDeletion: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4(userid) {
      var i18n;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              i18n = this.get('i18n');
              _context4.next = 3;
              return this.get('confirmation').confirm({
                intlConfirmationHeader: 'users.confirm.delete_avatar.header',
                intlConfirmationBody: 'users.confirm.delete_avatar.body',
                intlConfirmLabel: 'users.confirm.delete_avatar.confirm'
              });

            case 3:
              _context4.prev = 3;
              _context4.next = 6;
              return this.get('removeAvatar').perform(userid);

            case 6:
              this.get('notification').success(i18n.t('users.avatar_removal_success'));
              this.set('user.avatar', null);
              this.get('user').save();
              this.get('options').popObject();
              _context4.next = 15;
              break;

            case 12:
              _context4.prev = 12;
              _context4.t0 = _context4['catch'](3);

              this.get('notification').error(i18n.t('users.avatar_removal_failure'));

            case 15:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this, [[3, 12]]);
    })).drop(),

    // Actions
    actions: {
      selectItem: function selectItem(option, dropdown) {
        var userid = this.get('user.id');
        switch (option.id) {
          case 'upload':
            this.set('dropdown', dropdown);
            break;
          case 'remove':
            dropdown.actions.close();
            this.get('handleAvatarDeletion').perform(userid);
            break;
        }
      },
      onAttachFiles: function onAttachFiles(avatar) {
        var userid = this.get('user.id');

        var image = avatar[0];
        if (image.type.includes('image')) {
          var reader = new FileReader();

          var imageData = {
            data: null,
            type: image.type
          };

          var that = this;
          reader.onload = function () {
            imageData.data = this.result;

            var index = imageData.data.indexOf(',');
            imageData.data = imageData.data.slice(index + 1);

            index = imageData.type.indexOf('/');
            imageData.type = imageData.type.slice(index + 1);

            that.get('updateAvatar').perform(userid, imageData);
          };

          reader.readAsDataURL(image);
        } else {
          this.get('notification').error(this.get('i18n').formatHtmlMessage('users.incorrect_file_type', { filename: image.name }));
        }

        this.get('dropdown').actions.close();
      }
    }
  });
});