define("frontend-cp/components/ko-admin/identity-verification/buttons/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "buttons": "ko-admin_identity-verification_buttons__buttons_7hw5z5",
    "copy-button-wrapper": "ko-admin_identity-verification_buttons__copy-button-wrapper_7hw5z5",
    "copy-button": "ko-admin_identity-verification_buttons__copy-button_7hw5z5 ko-button__primary_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv"
  };
});