define('frontend-cp/components/ko-admin/add-new-card/component', ['exports', 'frontend-cp/config/environment', 'frontend-cp/utils/bugsnag', 'ember-concurrency'], function (exports, _environment, _bugsnag, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var observer = Ember.observer;
  var computed = Ember.computed;
  var Component = Ember.Component;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Attributes
    show: false,
    style: 'modal', // can be inline

    // State
    iframeLoaded: false,
    generatingToken: false,
    reloadingIframe: false,
    iframeLoadFailed: false,

    // Observers
    whenShowChanged: observer('show', function () {
      if (this.get('show')) {
        this.get('trackIframeLoading').cancelAll();
        this.resetState();
        this.renderZuoraForm();
      }
    }),

    // Services
    notificationService: service('notification'),
    i18n: service(),
    plansService: service('plan'),
    launchDarkly: service(),

    // LifeCycle Hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.registerPaymentEventListener();
      if (this.get('show')) {
        this.renderZuoraForm();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.removePaymentEventListener();
    },


    // Methods
    registerPaymentEventListener: function registerPaymentEventListener() {
      this._onIframePostMessage = this.onIframePostMessage.bind(this);
      if (!window.addEventListener) {
        window.attachEvent('onmessage', this._onIframePostMessage);
      } else {
        window.addEventListener('message', this._onIframePostMessage, false);
      }
    },
    removePaymentEventListener: function removePaymentEventListener() {
      if (!window.removeEventListener) {
        window.detachEvent('onmessage', this._onIframePostMessage);
      } else {
        window.removeEventListener('message', this._onIframePostMessage, false);
      }
    },
    resetState: function resetState() {
      this.set('iframeLoaded', false);
      this.set('reloadingIframe', false);
      this.set('iframeLoadFailed', false);
      // We don't need to reset token as one time getting is enough
    },


    iframeReloadTime: computed('launchDarkly.app-version-notification-frequency', function () {
      var defaultIframeReloadTime = 10000;
      return this.get('launchDarkly.zoura-reloading-time') || defaultIframeReloadTime;
    }),

    trackIframeLoading: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var context;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(this.get('iframeReloadTime'));

            case 2:
              context = (0, _bugsnag.getMetaData)(null, getOwner(this));

              // if iframe didn't load in 5 seconds try to reload

              if (this.get('reloadingIframe')) {
                _context.next = 8;
                break;
              }

              window.Bugsnag.notifyException(new Error('Reloading Iframe for Zoura payment form'), 'Reloading Iframe for Zoura payment form', context, 'info');
              this.set('reloadingIframe', true);
              this.renderZuoraForm();
              return _context.abrupt('return');

            case 8:

              this.set('iframeLoadFailed', true);
              window.Bugsnag.notifyException(new Error('Iframe load failed for Zoura payment form'), 'Iframe load failed for Zoura payment form', context, 'error');

            case 10:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    injectIFrame: function injectIFrame(params) {
      this.set('generatingToken', false);
      var queryString = $.param(params);
      this.set('iframeSrc', _environment.default.zuoraSandboxUrl + '?' + queryString);

      //start tracking time to load Zuora Iframe
      this.get('trackIframeLoading').perform();
    },
    generateToken: function generateToken() {
      this.set('generatingToken', true);
      var adapter = getOwner(this).lookup('adapter:application');
      return adapter.ajax(adapter.namespace + '/account/creditcards/token', 'POST');
    },
    abortNewCardTransaction: function abortNewCardTransaction() {
      this.sendAction('onCancel');
      this.get('notificationService').error(this.get('i18n').t('account.billing.zuora.renderError'));
    },
    onPaymentSuccess: function onPaymentSuccess(params) {
      this.sendAction('cardAdded', params);
    },
    onPaymentFailure: function onPaymentFailure(response) {
      var body = response.errorMessage && decodeURIComponent(response.errorMessage);
      this.get('notificationService').error(this.get('i18n').t('account.billing.card.notAdded'), { body: body });
      this.renderZuoraForm();
    },
    resizeIframe: function resizeIframe(params) {
      var paymentIframe = document.getElementById('billing-iframe');
      paymentIframe.width = params.width;
      paymentIframe.height = params.height;
    },
    renderZuoraForm: function renderZuoraForm() {
      var _this = this;

      this.generateToken().then(function (response) {
        var body = response.data;
        var params = {
          tenantId: body.tenant_id,
          hostedPageId: _this.get('plansService.billing.hosted_page_id'),
          signature: body.signature,
          token: body.token,
          key: body.key,
          hostedPageUrl: _this.get('plansService.billing.hosted_page_url'),
          paymentGateway: _this.get('plansService.billing.payment_gateway')
        };
        _this.injectIFrame(params);
      });
    },
    onIframePostMessage: function onIframePostMessage(message) {
      if (_environment.default.zuoraSandboxUrl.indexOf(message.origin) <= -1 || !message.data || !message.data.type) {
        return;
      }

      switch (message.data.type) {
        case 'rendered':
          this.set('iframeLoaded', true);
          this.get('trackIframeLoading').cancelAll();
          break;
        case 'resize':
          this.resizeIframe(message.data.data);
          break;
        case 'error':
          this.abortNewCardTransaction();
          break;
        case 'paymentSuccess':
          this.onPaymentSuccess(message.data.data);
          break;
        case 'paymentFailure':
          this.onPaymentFailure(message.data.data);
          break;
      }
    },


    actions: {
      saveNewCard: function saveNewCard() {
        var paymentIframe = document.getElementById('billing-iframe').contentWindow;
        paymentIframe.postMessage({ type: 'submit' }, _environment.default.zuoraSandboxUrl);
      }
    }
  });
});