define('frontend-cp/session/agent/insights/general/cases/route', ['exports', 'ember-service/inject', 'frontend-cp/lib/get-date-range-at'], function (exports, _inject, _getDateRangeAt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    launchDarkly: (0, _inject.default)(),

    insights: service(),
    metrics: service(),

    model: function model() {
      var insights = this.get('insights');
      var insightsParams = this.paramsFor('session.agent.insights');

      var startAt = insightsParams.startAt ? insightsParams.startAt : (0, _getDateRangeAt.monthFormat)(new Date(new Date().getTime() - 86400000 * 30));
      var endAt = insightsParams.endAt ? insightsParams.endAt : (0, _getDateRangeAt.monthFormat)(new Date());
      var interval = insightsParams.interval ? insightsParams.interval : 'DAY';

      var _insights$getPrevious = insights.getPreviousDates(startAt, endAt),
          previousStart = _insights$getPrevious.previousStart,
          previousEnd = _insights$getPrevious.previousEnd;

      var metricsQueryParams = {
        data: {
          start_at: startAt + 'T00:00:00.000Z',
          end_at: endAt + 'T23:59:59.999Z',
          previous_start_at: previousStart + 'T00:00:00.000Z',
          previous_end_at: previousEnd + 'T23:59:59.999Z',
          interval: interval,
          trial: insights.isTrialMode() && insightsParams.trial
        }
      };

      return RSVP.hash({
        startAt: startAt,
        endAt: endAt,
        interval: interval,
        metricsQueryParams: metricsQueryParams
      });
    },
    setupController: function setupController(controller, data) {
      var _this = this;

      var insights = this.get('insights');

      this.get('controller').setProperties({
        startAt: data.startAt,
        endAt: data.endAt,
        interval: data.interval,
        metricsQueryParams: data.metricsQueryParams
      });

      if (insights.isTrialMode()) {
        insights.pushTrialNotification(function () {
          _this.transitionTo({ queryParams: { trial: false } });
        });
      }
    },


    actions: {
      didTransition: function didTransition() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'conversation_insights_viewed'
          });
        }
      }
    }
  });
});