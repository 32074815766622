define('frontend-cp/components/ko-admin/billing/card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({

    // Attributes
    card: {},

    // Computed Properties
    expiryMonth: computed('card.expiryMonth', function () {
      var expiryMonth = this.get('card.expiryMonth');
      return expiryMonth < 10 ? '0' + expiryMonth : expiryMonth;
    }),

    cardNumber: computed('card.number', function () {
      var cardNumber = this.get('card.number');
      return typeof cardNumber === 'string' ? cardNumber.substr(cardNumber.length - 4) : null;
    }),

    // Actions
    actions: {
      markAsDefault: function markAsDefault() {
        this.sendAction('whenMarkAsDefault', this.get('card'));
      },


      /*
       * removing a card
       */
      removeCard: function removeCard(card) {
        card.destroyRecord().catch(function () {
          card.rollbackAttributes();
        });
      }
    }

  });
});