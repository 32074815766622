define('frontend-cp/components/ko-app-slot/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    apps: service(),

    name: null,

    appsForSlot: computed('name', function () {
      return this.get('apps').appsForSlot(this.get('name'));
    })
  });
});