define('frontend-cp/lib/convert-errors-to-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (errors) {
    return (errors || []).reduce(function (errorMap, error) {
      // non-validation errors (403 permission denied etc) don't have error.source
      if (error.source && error.source.pointer) {
        var attrName = error.source.pointer.replace('data/attributes/', '');
        errorMap.set(attrName, true);
      }
      return errorMap;
    }, EmberObject.create({}));
  };

  var EmberObject = Ember.Object;
});