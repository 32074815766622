define("frontend-cp/components/ko-notification-center/-base-notification/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-notification-center_-base-notification__item_lhqtx1",
    "is-seen": "ko-notification-center_-base-notification__is-seen_lhqtx1",
    "meta": "ko-notification-center_-base-notification__meta_lhqtx1",
    "time": "ko-notification-center_-base-notification__time_lhqtx1",
    "read": "ko-notification-center_-base-notification__read_lhqtx1",
    "item-link": "ko-notification-center_-base-notification__item-link_lhqtx1"
  };
});