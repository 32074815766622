define("frontend-cp/components/ko-info-bar/field/select/arrow/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "arrow": "ko-info-bar_field_select_arrow__arrow_1m26lz",
    "arrow-inset": "ko-info-bar_field_select_arrow__arrow-inset_1m26lz",
    "active": "ko-info-bar_field_select_arrow__active_1m26lz"
  };
});