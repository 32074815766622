define('frontend-cp/services/plan', ['exports', 'moment', 'frontend-cp/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var bool = Ember.computed.bool;
  exports.default = Service.extend({
    store: service(),
    date: service(),

    plan: null,

    accountId: reads('plan.accountId'),
    billing: reads('plan.billing'),
    expiryAt: reads('plan.expiryAt'),
    features: reads('plan.features'),
    isGrandfathered: reads('plan.isGrandfathered'),
    leadId: reads('plan.leadId'),
    limits: reads('plan.limits'),
    name: reads('plan.name'),
    opportunityId: reads('plan.opportunityId'),
    product: reads('plan.product'),
    subscriptionId: reads('plan.subscriptionId'),
    rateplanId: reads('plan.rateplanId'),

    limitFor: function limitFor(name) {
      return this.get('limits.' + name);
    },
    has: function has(code) {
      return (this.get('features') || []).isAny('code', code);
    },


    isTrial: computed('plan.{accountId,isGrandfathered,leadId,opportunityId,rateplanId,subscriptionId}', function () {
      if (_environment.default.APP.forceTrial) {
        return true;
      }

      if (!this.get('plan')) {
        return false;
      }

      var hasLead = !!this.get('plan.leadId');
      var notYetConfigured = !this.get('plan.leadId') && !this.get('plan.opportunityId') && !this.get('plan.accountId') && !this.get('plan.subscriptionId') && !this.get('plan.rateplanId') && !this.get('plan.isGrandfathered');

      return hasLead || notYetConfigured;
    }),

    isSandbox: bool('plan.opportunityId'),

    isOnDemandSandbox: computed('isSandbox', 'plan.isGrandfathered', 'plan.accountId', function () {
      return this.get('isSandbox') && !this.get('plan.isGrandfathered') && !this.get('plan.accountId');
    }),

    daysLeftInTrial: computed('plan.expiryAt', function () {
      var currentDate = this.get('date').getCurrentDate();
      return (0, _moment.default)(this.get('plan.expiryAt')).diff((0, _moment.default)(currentDate), 'days');
    }),

    fetchPlan: function fetchPlan() {
      var _this = this;

      var store = this.get('store');

      store.unloadAll('plan');
      return store.queryRecord('plan', {}).then(function (plan) {
        _this.set('plan', plan);
      });
    }
  });
});