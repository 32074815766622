define("frontend-cp/components/ko-timeline-2/list/activity/summary-text/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "token-avatar": "ko-timeline-2_list_activity_summary-text__token-avatar_ge86tt",
    "actor": "ko-timeline-2_list_activity_summary-text__actor_ge86tt",
    "entity": "ko-timeline-2_list_activity_summary-text__entity_ge86tt",
    "text": "ko-timeline-2_list_activity_summary-text__text_ge86tt",
    "link": "ko-timeline-2_list_activity_summary-text__link_ge86tt"
  };
});