define("frontend-cp/components/ko-admin/rateplans/list/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "plans-footer": "ko-admin_rateplans_list__plans-footer_17hjw1",
    "listfilters": "ko-admin_rateplans_list__listfilters_17hjw1",
    "radio": "ko-admin_rateplans_list__radio_17hjw1",
    "term_savings": "ko-admin_rateplans_list__term_savings_17hjw1",
    "compare-plans": "ko-admin_rateplans_list__compare-plans_17hjw1",
    "update-message": "ko-admin_rateplans_list__update-message_17hjw1",
    "update-message-title": "ko-admin_rateplans_list__update-message-title_17hjw1",
    "update-message-body": "ko-admin_rateplans_list__update-message-body_17hjw1"
  };
});