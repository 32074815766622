define("frontend-cp/components/ko-bulk-sidebar/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-bulk-sidebar__container_6u6rdq",
    "title": "ko-bulk-sidebar__title_6u6rdq",
    "fields": "ko-bulk-sidebar__fields_6u6rdq",
    "button-bar": "ko-bulk-sidebar__button-bar_6u6rdq",
    "cancel": "ko-bulk-sidebar__cancel_6u6rdq",
    "field-placeholder": "ko-bulk-sidebar__field-placeholder_6u6rdq"
  };
});