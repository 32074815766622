define('frontend-cp/components/lightning-preview-ribbon/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    config: service(),
    cookies: service(),

    previewVersion: computed(function () {
      return this.get('cookies').read(this._cookieName());
    }).volatile(),

    _cookieName: function _cookieName() {
      return this.get('config.lightningVersionCookieName');
    },


    actions: {
      clearCookieAndReloadBrowser: function clearCookieAndReloadBrowser(e) {
        e.preventDefault();

        this.get('cookies').clear(this._cookieName(), { path: '/' });

        window.location.reload(true);
      }
    }
  });
});