define("frontend-cp/components/ko-sidebar/group/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "group": "ko-sidebar_group__group_56dt83",
    "header": "ko-sidebar_group__header_56dt83"
  };
});