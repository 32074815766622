define('frontend-cp/services/apps', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Service = Ember.Service;
  var service = Ember.inject.service;


  var DEV_MODE = window.location.search && !!window.location.search.match(/dev=true/);

  exports.default = Service.extend({
    installedApps: null,

    store: service(),

    isDevMode: DEV_MODE,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('installedApps', []);
    },
    setup: function setup() {
      var _this = this;

      var reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      return this.get('store').findAll('app-installation', { reload: reload }).then(function (installations) {
        _this.set('installedApps', installations);
      });
    },
    appsForSlot: function appsForSlot(name) {
      return this.get('installedApps').filter(function (inst) {
        return inst.get('app.slots').mapBy('location').includes(name);
      });
    },
    updatePrompts: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(installation, promptValues) {
        var iprompts, updated;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.get('store').adapterFor('app-installation-prompt').updatePrompts(installation, promptValues);

              case 2:

                // now that we've updated them, update our installedApp local values with the same so they get used when navigating around
                // installation only has the public values, so make sure we only update ones already present
                iprompts = installation.get('iprompts');
                updated = {};

                Object.keys(iprompts).forEach(function (key) {
                  updated[key] = promptValues[key];
                });
                installation.set('iprompts', updated);

                return _context.abrupt('return', true);

              case 7:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function updatePrompts(_x2, _x3) {
        return _ref.apply(this, arguments);
      }

      return updatePrompts;
    }(),
    install: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(app) {
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return this.get('store').adapterFor('app-installation').install(app);

              case 2:
                _context2.next = 4;
                return this.setup(true);

              case 4:
                return _context2.abrupt('return', this.get('installedApps').findBy('app.id', app.get('id')));

              case 5:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function install(_x4) {
        return _ref2.apply(this, arguments);
      }

      return install;
    }(),
    uninstall: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(installation) {
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return this.get('store').adapterFor('app-installation').uninstall(installation);

              case 2:

                // remove it from our local list of apps
                this.get('installedApps').removeObject(installation);

                return _context3.abrupt('return', true);

              case 4:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function uninstall(_x5) {
        return _ref3.apply(this, arguments);
      }

      return uninstall;
    }()
  });
});