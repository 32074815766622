define('frontend-cp/components/ko-admin/billing/index/component', ['exports', 'ember-countries', 'npm:lodash'], function (exports, _emberCountries, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Component.extend({

    // Attributes
    cards: [],
    account: {},

    // State
    showZuoraForm: false,
    linkingCreditCard: false,
    updatingAccountDetails: false,

    // services
    notificationService: service('notification'),
    i18n: service(),
    store: service(),

    // Computed Properties
    updatingResource: computed('linkingCreditCard', 'cards.isUpdating', function () {
      return this.get('linkingCreditCard') || this.get('cards.isUpdating');
    }),

    billing: computed('account.billTo', function () {
      return {
        first_name: this.get('account.billTo.firstName'),
        last_name: this.get('account.billTo.lastName'),
        personal_email: this.get('account.billTo.personalEmail'),
        home_phone: this.get('account.billTo.homePhone'),
        address1: this.get('account.billTo.address1'),
        address2: this.get('account.billTo.address2'),
        city: this.get('account.billTo.city'),
        state: this.get('account.billTo.state'),
        postal_code: this.get('account.billTo.postalCode'),
        country: {
          iso: this.get('account.billTo.country'),
          country: (0, _emberCountries.countryIsoToValue)(this.get('account.billTo.country'))
        }
      };
    }),

    actions: {
      onCancel: function onCancel() {
        this.set('showZuoraForm', false);
      },
      cardAdded: function cardAdded(data) {
        var _this = this;

        this.set('linkingCreditCard', true);
        this.set('showZuoraForm', false);
        this.get('notificationService').success(this.get('i18n').t('account.billing.card.added'));
        var adapter = getOwner(this).lookup('adapter:application');
        var options = {
          data: { default_payment: data.refId }
        };
        adapter.ajax(adapter.namespace + '/account', 'PUT', options).then(function () {
          _this.get('cards').update();
        }).finally(function () {
          _this.set('linkingCreditCard', false);
        });
      },
      addCard: function addCard() {
        this.set('showZuoraForm', true);
      },
      updateAccountDetails: function updateAccountDetails(billingForm) {
        this.set('updatingAccountDetails', true);

        var adapter = getOwner(this).lookup('adapter:application');
        var billingClone = _npmLodash.default.clone(this.get('billing'));
        billingClone.country = billingClone.country.iso;

        var options = {
          data: {
            bill_to_contacts: JSON.stringify(billingClone),
            vat_id: this.get('account.vatId')
          }
        };

        return new EmberPromise(function (resolve, reject) {
          billingForm.validateForm();
          if (billingForm.hasErrors()) {
            billingForm.focusErrorFields();
            return reject(false);
          }
          adapter.ajax(adapter.namespace + '/account', 'PUT', options).then(resolve).catch(reject);
        });
      },
      onSuccess: function onSuccess(response) {
        this.get('store').pushPayload('account', response);
        this.set('updatingAccountDetails', false);
        this.get('notificationService').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });
      },
      onError: function onError() {
        this.set('updatingAccountDetails', false);
        this.get('notificationService').add({
          type: 'error',
          title: this.get('i18n').t('generic.generic_error'),
          autodismiss: true
        });
      },
      markCardAsDefault: function markCardAsDefault(card) {
        if (card.get('isDefault')) {
          return;
        }

        var existingDefaultCard = this.get('cards').findBy('isDefault', true);
        if (existingDefaultCard) {
          existingDefaultCard.set('isDefault', false);
        }
        card.set('isDefault', true);

        card.save().catch(function () {
          card.set('isDefault', false);
          if (existingDefaultCard) {
            existingDefaultCard.set('isDefault', true);
          }
        });
      }
    }
  });
});