define('frontend-cp/components/ko-insights/loader/cases/channel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectProxy = Ember.ObjectProxy;
  var PromiseProxyMixin = Ember.PromiseProxyMixin;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend({
    tagName: '',
    insights: service(),
    metricsQueryParams: null,

    request: computed('metricsQueryParams', function () {
      var _this = this;

      var insights = this.get('insights');
      var adapter = getOwner(this).lookup('adapter:application');
      var endpoint = insights.channelEndpoint();

      return ObjectProxy.extend(PromiseProxyMixin).create({
        promise: adapter.ajax(endpoint, 'GET', this.get('metricsQueryParams')).then(function (data) {
          if (_this.isDestroying || _this.isDestroyed) {
            return;
          }

          _this.set('series', data.data);
        })
      });
    }),

    series: null,

    totalMessages: computed('series', function () {
      var total = 0;
      var series = this.get('series');

      if (!series) {
        return 0;
      }

      series.channel_series.forEach(function (channel) {
        total = channel.series.data.reduce(function (a, b) {
          return parseInt(a) + parseInt(b);
        }, total);
      });

      return total;
    })
  });
});