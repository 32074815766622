define('frontend-cp/components/ko-admin/twitter/index/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var filterBy = Ember.computed.filterBy;
  var $ = Ember.$;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),
    i18n: service(),
    notification: service(),
    confirmation: service(),

    // CPs
    enabledAccounts: filterBy('accounts', 'isEnabled', true),
    disabledAccounts: filterBy('accounts', 'isEnabled', false),

    // Tasks
    redirectToTwitterAuthenticationEndpoint: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(e) {
      var link;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              e.stopPropagation();
              _context.next = 3;
              return this.get('store').queryRecord('oauth-link', { callback: '/admin/channels/twitter/callback' });

            case 3:
              link = _context.sent;

              window.location.href = link.get('id');

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    redirectToTwitterAuthenticationForReauthorize: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(account, e) {
      var link;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              e.stopPropagation();
              _context2.next = 3;
              return this.get('store').queryRecord('oauth-link', { callback: '/admin/channels/twitter/reauthorize' });

            case 3:
              link = _context2.sent;

              window.location.href = link.get('id');

            case 5:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    // Actions
    actions: {
      toggleEnabledProperty: function toggleEnabledProperty(account, e) {
        var _this = this;

        e.stopPropagation();
        account.toggleProperty('isEnabled');
        account.save().then(function () {
          var notificationMessage = _this.get('i18n').t(account.get('isEnabled') ? 'admin.twitter.enabled.message' : 'admin.twitter.disabled.message');
          _this.get('notification').success(notificationMessage);
        });
      },
      editAccount: function editAccount(account, event) {
        if (event && $(event.target).hasClass('js-admin_channels_twitter__delete-button')) {
          return;
        }

        this.get('on-edit')(account);
      },
      delete: function _delete(account) {
        var _this2 = this;

        return this.get('confirmation').confirm({
          intlConfirmationBody: 'generic.confirm.delete'
        }).then(function () {
          return account.destroyRecord();
        }).then(function () {
          var msg = _this2.get('i18n').t('admin.twitter.deleted.message');
          _this2.get('notification').success(msg);
        });
      }
    }
  });
});