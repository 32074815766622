define("frontend-cp/components/ko-admin/triggers/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "ko-admin_triggers_index__title_ye5y8j",
    "caption": "ko-admin_triggers_index__caption_ye5y8j"
  };
});