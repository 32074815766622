define('frontend-cp/utils/platform', ['exports', 'npm:platform'], function (exports, _npmPlatform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isRetina = undefined;
  exports.isMac = isMac;
  function isMac() {
    return _npmPlatform.default.os && _npmPlatform.default.os.family && _npmPlatform.default.os.family.match(/os x|ios/i);
  }

  var isRetina = exports.isRetina = function () {
    var mediaQuery = '(-webkit-min-device-pixel-ratio: 1.5),(min--moz-device-pixel-ratio: 1.5),(-o-min-device-pixel-ratio: 3/2),(min-resolution: 1.5dppx)';
    return window.devicePixelRatio > 1 || !!(window.matchMedia && window.matchMedia(mediaQuery).matches);
  }();
});