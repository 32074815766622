define('frontend-cp/components/ko-tab-strip/user-new-tab/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    tab: null,
    'on-close': function onClose() {}
  });
});