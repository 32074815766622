define('frontend-cp/components/ko-admin/fields/form/priorities/component', ['exports', 'ember-service/inject', 'ember-concurrency', 'frontend-cp/services/virtual-model'], function (exports, _inject, _emberConcurrency, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var observer = Ember.observer;
  var computed = Ember.computed;


  var schema = (0, _virtualModel.model)('case-priority', {
    label: (0, _virtualModel.attr)()
  });

  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    // HTML
    tagName: '',

    // Attributes
    priorities: [],
    onEditedChange: function onEditedChange() {},

    // State
    originalPriority: null,
    editedPriority: null,

    // Services
    confirmation: service(),
    plan: service(),
    sorter: service(),
    virtualModel: service(),
    metrics: service(),

    sortedPriorities: computed('priorities.@each.level', function () {
      return this.get('priorities').sortBy('level');
    }),

    isPlanEnabled: computed('plan', function () {
      return this.get('plan').has('custom_case_priorities');
    }),

    // Tasks
    addPriority: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var priority;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              priority = this.get('priorities').createRecord({
                level: this.get('sortedPriorities.lastObject.level') + 1
              });
              _context.prev = 1;
              _context.next = 4;
              return this.get('startEditing').perform(priority);

            case 4:
              _context.next = 9;
              break;

            case 6:
              _context.prev = 6;
              _context.t0 = _context['catch'](1);

              priority.rollbackAttributes();

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[1, 6]]);
    })).drop(),

    startEditing: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(priority, e) {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('cancelEditing').perform();

            case 2:
              this.set('originalPriority', priority);
              this.set('editedPriority', this.get('virtualModel').makeSnapshot(priority, schema));

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    cancelEditing: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              if (!(this.get('editedPriority') && (0, _virtualModel.isEdited)(this.get('originalPriority'), this.get('editedPriority'), schema))) {
                _context3.next = 3;
                break;
              }

              _context3.next = 3;
              return this.get('confirmation').confirm({
                intlConfirmationHeader: 'generic.confirm.lose_changes_header',
                intlConfirmationBody: 'generic.confirm.lose_changes',
                intlConfirmLabel: 'generic.confirm.lose_changes_button'
              });

            case 3:
              if (this.get('originalPriority.isNew')) {
                this.get('originalPriority').rollbackAttributes();
              }
              this.set('originalPriority', null);
              this.set('editedPriority', null);

            case 6:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).drop(),

    savePriority: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      var launchDarkly;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');
              _context4.next = 3;
              return this.get('virtualModel').save(this.get('originalPriority'), this.get('editedPriority'), schema);

            case 3:
              if (launchDarkly.get('release-event-tracking') && this.get('originalPriority.isNew')) {
                this.get('metrics').trackEvent({
                  event: 'priority_created'
                });
              }
              this.set('originalPriority', null);
              this.set('editedPriority', null);

            case 6:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })).drop(),

    removePriority: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5(priority) {
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              _context5.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmLabel: 'generic.confirm.delete_button',
                intlConfirmationBody: 'generic.confirm_delete.body'
              });

            case 2:
              priority.destroyRecord();

            case 3:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })).drop(),

    editedPriorityObserver: observer('editedPriority', function () {
      this.get('onEditedChange')(Boolean(this.get('editedPriority')));
    }),

    willDestroyElement: function willDestroyElement() {
      if (this.get('originalPriority.isNew')) {
        this.get('originalPriority').rollbackAttributes();
      }
    },


    actions: {
      reorderList: function reorderList(casePriorities) {
        this.get('sorter').sort(casePriorities);
      },
      removePriority: function removePriority(priority, event) {
        event.stopPropagation();
        this.get('removePriority').perform(priority);
      }
    }
  });
});