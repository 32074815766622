define('frontend-cp/components/ko-text-editor/file-upload/upload-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var equal = Ember.computed.equal;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    i18n: service(),
    plan: service(),

    // Attributes
    upload: null,
    onCancel: function onCancel() {},

    // CPs
    inProgress: equal('upload.status', 'PROGRESS'),
    success: equal('upload.status', 'SUCCESS'),
    error: equal('upload.status', 'ERROR'),

    uploadProgress: computed('upload.progress', function () {
      return Math.round(this.get('upload.progress'));
    }),

    errorText: computed('upload.error', function () {
      var error = this.get('upload.error');
      var i18n = this.get('i18n');

      if (error === 'TOO_LARGE') {
        var uploadLimit = this.get('plan.limits.attachment_size_limit');
        return i18n.t('generic.uploads.toolarge', { filesizeLimit: uploadLimit });
      } else if (error === 'TWITTER_VALIDATION_FAILED') {
        return i18n.t('generic.uploads.twitter_validation_failed');
      } else if (error === 'UNKNOWN') {
        return i18n.t('generic.uploads.unknown');
      } else {
        return error;
      }
    }),

    actions: {
      cancel: function cancel() {
        this.get('upload').cancel();
        this.get('onCancel')(this.get('upload'));
      }
    }
  });
});