define('frontend-cp/serializers/case-reply', ['exports', 'frontend-cp/serializers/application', 'frontend-cp/lib/custom-field-serialization', 'npm:uuid/v4'], function (exports, _application, _customFieldSerialization, _v) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      channelType: { key: 'channel' },
      caseType: { key: 'type_id' },
      case: { serialize: false },
      posts: { serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      var form = snapshot.belongsTo('form');

      json.field_values = (0, _customFieldSerialization.serializeCustomFields)(snapshot.attr('fieldValues'), form);

      json = (0, _customFieldSerialization.serializeChannelOptions)(json, snapshot.attr('channelOptions'));

      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      store.peekRecord(payload.data.case.resource_type, payload.data.case.id).rollbackAttributes();
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var idToUse = id || (0, _v.default)();
      payload.case_replies[0].id = idToUse;
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});