define("frontend-cp/components/ko-universal-search/result-see-more/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "seeMore": "ko-universal-search_result-see-more__seeMore_qavehz",
    "link": "ko-universal-search_result-see-more__link_qavehz",
    "button": "ko-universal-search_result-see-more__button_qavehz",
    "slide": "ko-universal-search_result-see-more__slide_qavehz"
  };
});