define("frontend-cp/components/ko-info-bar/field/basic-drill-down/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-info-bar_field_basic-drill-down__trigger_1dvptk _trigger_jvqwfg _statusIcon_jvqwfg",
    "dropdown": "ko-info-bar_field_basic-drill-down__dropdown_1dvptk _dropdown_jvqwfg"
  };
});