define('frontend-cp/helpers/inline-svg', ['exports', 'ember-inline-svg/helpers/inline-svg', 'frontend-cp/svgs'], function (exports, _inlineSvg, _svgs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var helper = Ember.Helper.helper;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = helper(function (_ref, options) {
    var _ref2 = _slicedToArray(_ref, 1),
        path = _ref2[0];

    var svg = (0, _inlineSvg.inlineSvg)(_svgs.default, path, options);
    svg = svg.string.replace(/%UID%/g, randomString(10));
    return htmlSafe(svg);
  });


  function randomString(length) {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    var i = void 0;
    for (i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }

    return result;
  }
});