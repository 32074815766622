define('frontend-cp/components/ko-sla/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var or = Ember.computed.or;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    metric: null,

    // Services
    serverClock: service(),
    developerTools: service(),

    // CPs
    serverTime: reads('serverClock.date'),

    isNotCompletedButPastDue: computed('metric.{dueAt,lastPausedAt,completedAt,stage}', 'serverTime', function () {
      if (this.get('metric.isPaused')) {
        return this.get('metric.completedAt') === null && (0, _moment.default)(this.get('metric.lastPausedAt')).isAfter(this.get('metric.dueAt'));
      } else {
        return this.get('metric.completedAt') === null && this.get('serverTime').isAfter(this.get('metric.dueAt'));
      }
    }),

    isNotCompletedButPastDueByAMinuteOrMore: computed('metric.dueAt', 'metric.completedAt', 'serverTime', function () {
      return this.get('metric.completedAt') === null && this.get('serverTime').isAfter((0, _moment.default)(this.get('metric.dueAt')).add(59, 'seconds'));
    }),

    isCompletedButPastDue: computed('metric.{dueAt,lastPausedAt,completedAt,stage}', 'serverTime', function () {
      if (this.get('metric.isPaused')) {
        return this.get('metric.completedAt') !== null && (0, _moment.default)(this.get('metric.lastPausedAt')).isAfter(this.get('metric.dueAt'));
      } else {
        return (0, _moment.default)(this.get('metric.completedAt')).isAfter(this.get('metric.dueAt'));
      }
    }),

    isCompletedButPastDueByAMinuteOrMore: computed('metric.{completedAt,dueAt}', function () {
      return (0, _moment.default)(this.get('metric.completedAt')).isAfter((0, _moment.default)(this.get('metric.dueAt')).add(59, 'seconds'));
    }),

    isBreached: or('isCompletedButPastDue', 'isNotCompletedButPastDue'),

    isBreachedByAMinuteOrMore: or('isCompletedButPastDueByAMinuteOrMore', 'isNotCompletedButPastDueByAMinuteOrMore'),

    iconClassName: computed('metric.stage', 'isBreached', function () {
      switch (this.get('metric.stage')) {
        case 'PAUSED':
          return 'i-paused';
        case 'ACTIVE':
          return 'i-clock';
        case 'COMPLETED':
          return this.get('isBreached') ? 'i-cross-bold' : 'i-tick';
      }
    }),

    status: computed('metric.stage', 'isBreached', function () {
      var stage = this.get('metric.stage');
      var isBreached = this.get('isBreached');
      if (stage === 'COMPLETED') {
        return isBreached ? 'bad' : 'good';
      } else if (isBreached) {
        return 'breached';
      } else {
        return 'open';
      }
    }),

    sign: computed('isBreachedByAMinuteOrMore', function () {
      return this.get('isBreachedByAMinuteOrMore') ? '-' : '';
    })
  });
});