define('frontend-cp/models/rating', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    score: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    case: _emberData.default.belongsTo('case'),
    creator: _emberData.default.belongsTo('user'),
    createdAt: _emberData.default.attr('date')
  });
});