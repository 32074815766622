define("frontend-cp/components/ko-admin/messenger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "messenger-page": "ko-admin_messenger__messenger-page_lrzyai",
    "page-title": "ko-admin_messenger__page-title_lrzyai",
    "container": "ko-admin_messenger__container_lrzyai",
    "column": "ko-admin_messenger__column_lrzyai",
    "column--options": "ko-admin_messenger__column--options_lrzyai",
    "column--preview": "ko-admin_messenger__column--preview_lrzyai",
    "tabs": "ko-admin_messenger__tabs_lrzyai",
    "tabs__item": "ko-admin_messenger__tabs__item_lrzyai",
    "is-active": "ko-admin_messenger__is-active_lrzyai",
    "tabs__number": "ko-admin_messenger__tabs__number_lrzyai",
    "tab-container": "ko-admin_messenger__tab-container_lrzyai",
    "tab-content": "ko-admin_messenger__tab-content_lrzyai",
    "tab-content__wrapper": "ko-admin_messenger__tab-content__wrapper_lrzyai",
    "label": "ko-admin_messenger__label_lrzyai",
    "widget-title": "ko-admin_messenger__widget-title_lrzyai",
    "widget": "ko-admin_messenger__widget_lrzyai",
    "is-disabled": "ko-admin_messenger__is-disabled_lrzyai",
    "widget__label": "ko-admin_messenger__widget__label_lrzyai",
    "widget__content": "ko-admin_messenger__widget__content_lrzyai",
    "widget__switch": "ko-admin_messenger__widget__switch_lrzyai",
    "widget__help-text": "ko-admin_messenger__widget__help-text_lrzyai",
    "widget__dropdown": "ko-admin_messenger__widget__dropdown_lrzyai",
    "copy-button": "ko-admin_messenger__copy-button_lrzyai ko-button__primary_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "trigger": "ko-admin_messenger__trigger_lrzyai _trigger_jvqwfg _statusIcon_jvqwfg",
    "dropdown": "ko-admin_messenger__dropdown_lrzyai _dropdown_jvqwfg",
    "next-button-spacer": "ko-admin_messenger__next-button-spacer_lrzyai",
    "text--small": "ko-admin_messenger__text--small_lrzyai",
    "text--light": "ko-admin_messenger__text--light_lrzyai",
    "mbottom": "ko-admin_messenger__mbottom_lrzyai",
    "mbottom--tiny": "ko-admin_messenger__mbottom--tiny_lrzyai"
  };
});