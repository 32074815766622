define("frontend-cp/components/kre-connection-ribbon/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "ribbon": "kre-connection-ribbon__ribbon_1hh2lu",
    "button": "kre-connection-ribbon__button_1hh2lu",
    "link": "kre-connection-ribbon__link_1hh2lu"
  };
});