define('frontend-cp/components/ko-user-cases/dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var ITEMS_TO_SHOW = 8;

  exports.default = Component.extend({
    tagName: '',

    // Attributes
    cases: null,
    user: null,
    case: null,
    inRecentCasesMode: false,
    showTotalCountButton: false,
    casesCount: 0,

    // Service
    routing: service('-routing'),
    store: service(),
    tabStore: service(),
    advancedSearch: service(),

    // CPs
    lastFewCases: computed('cases', function () {
      return this.get('cases').slice(0, ITEMS_TO_SHOW);
    }),

    searchString: computed('user', function () {
      return 'in:conversations requester:\'' + this.get('user.id') + '\'';
    }),

    actions: {
      openActiveCase: function openActiveCase(recentCase, dropdown, event) {
        var id = recentCase.get('id');
        var caseId = this.get('case.id');

        var hasModifier = event.metaKey || event.ctrlKey || event.shiftKey;
        if (hasModifier && caseId !== id) {
          this.get('tabStore').createTabNextToActiveTab('session.agent.cases.case', recentCase);
        } else {
          dropdown.actions.close();
          this.get('tabStore').transitionAndInsertTabNextToActiveTab('session.agent.cases.case.index', [id]);
        }
      },
      openSearchTabForUserCases: function openSearchTabForUserCases(dropdown) {
        dropdown.actions.close();
        this.get('tabStore').transitionAndInsertTabNextToActiveTab('session.agent.search', [this.get('searchString')]);
      }
    }
  });
});