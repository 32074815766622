define('frontend-cp/session/admin/people/teams/edit/controller', ['exports', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/people/teams/schema'], function (exports, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    // Attributes
    businessHours: null,
    team: null,

    // State
    editedTeam: null,

    // Services
    i18n: service(),
    notification: service(),
    virtualModel: service(),

    schema: _schema.default,

    initEdits: function initEdits() {
      this.set('editedTeam', this.get('virtualModel').makeSnapshot(this.get('team'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('team'), this.get('editedTeam'), _schema.default);
    },


    actions: {
      updated: function updated() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        this.transitionToRoute('session.admin.people.teams.index');
      },
      deleted: function deleted() {
        this.initEdits();
        this.transitionToRoute('session.admin.people.teams.index');
      },
      redirectToIndex: function redirectToIndex() {
        this.transitionToRoute('session.admin.people.teams.index');
      }
    }
  });
});