define("frontend-cp/session/admin/security/policy/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "field": "session_admin_security_policy_index__field_13vnj9",
    "field-column": "session_admin_security_policy_index__field-column_13vnj9",
    "description": "session_admin_security_policy_index__description_13vnj9"
  };
});