define('frontend-cp/session/admin/apps/manage/show/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    notification: service(),
    i18n: service(),

    actions: {
      installed: function installed(installedApp) {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('admin.apps.manage.notifications.installed_app'),
          autodismiss: true
        });

        this.transitionToRoute('session.admin.apps.manage.edit', installedApp.get('id'));
      },
      uninstalled: function uninstalled() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('admin.apps.manage.notifications.uninstalled_app'),
          autodismiss: true
        });

        this.transitionToRoute('session.admin.apps.manage.index');
      }
    }
  });
});