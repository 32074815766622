define("frontend-cp/components/ko-admin/inline-locale-edit/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "arrow-size": "9px",
    "arrow-border": "1px solid #BEC4C7",
    "container": "ko-admin_inline-locale-edit__container_17qbpf",
    "modalButton": "ko-admin_inline-locale-edit__modalButton_17qbpf",
    "modalButtonEdited": "ko-admin_inline-locale-edit__modalButtonEdited_17qbpf",
    "text": "ko-admin_inline-locale-edit__text_17qbpf",
    "popover": "ko-admin_inline-locale-edit__popover_17qbpf",
    "locale-fields": "ko-admin_inline-locale-edit__locale-fields_17qbpf",
    "buttons": "ko-admin_inline-locale-edit__buttons_17qbpf",
    "arrow": "ko-admin_inline-locale-edit__arrow_17qbpf",
    "dropdownContent": "ko-admin_inline-locale-edit__dropdownContent_17qbpf"
  };
});