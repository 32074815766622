define('frontend-cp/services/macro', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;


  var OFFSET = 0;
  var LIMIT = 250;

  /*
   * Recursively request all macros via pagination
   *
   *  - Fetching 300+ macros is too much work for API
   *  - Querys are not cached via ember data
   *  - Loop through macros 20 at a time, and ensure that we only ever
   *    do this once when we hit the agent root
   */

  exports.default = Service.extend({
    store: service(),

    hasRequestedMacros: false,

    fetchMacros: function fetchMacros() {
      if (this.get('hasRequestedMacros')) {
        return;
      }
      this.set('hasRequestedMacros', true);

      this._fetchMacroRange(OFFSET, LIMIT);
    },
    trackUsage: function trackUsage(id) {
      var adapter = getOwner(this).lookup('adapter:application');
      var url = adapter.namespace + '/cases/macros/' + id + '/used';
      return adapter.ajax(url, 'PUT');
    },
    _fetchMacroRange: function _fetchMacroRange(offset, limit) {
      var _this = this;

      this.get('store').query('macro', { offset: offset, limit: limit, include: '', fields: 'title' }).then(function (newMacros) {
        var total = newMacros.meta.total;
        var currentMax = offset + limit;

        if (total && total > currentMax) {
          _this._fetchMacroRange(currentMax, limit);
        }
      });
    }
  });
});