define("frontend-cp/components/ko-datepicker/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-datepicker__container_dbk0gr",
    "header": "ko-datepicker__header_dbk0gr",
    "days": "ko-datepicker__days_dbk0gr",
    "month": "ko-datepicker__month_dbk0gr",
    "year": "ko-datepicker__year_dbk0gr",
    "arrow": "ko-datepicker__arrow_dbk0gr",
    "previous": "ko-datepicker__previous_dbk0gr",
    "next": "ko-datepicker__next_dbk0gr",
    "weekday": "ko-datepicker__weekday_dbk0gr",
    "date": "ko-datepicker__date_dbk0gr",
    "dateCurrentMonth": "ko-datepicker__dateCurrentMonth_dbk0gr",
    "date--today": "ko-datepicker__date--today_dbk0gr",
    "date--selected": "ko-datepicker__date--selected_dbk0gr",
    "actions": "ko-datepicker__actions_dbk0gr",
    "action": "ko-datepicker__action_dbk0gr",
    "icon--today": "ko-datepicker__icon--today_dbk0gr",
    "icon--clear": "ko-datepicker__icon--clear_dbk0gr",
    "icon--close": "ko-datepicker__icon--close_dbk0gr"
  };
});