define('frontend-cp/models/side-conversation', ['exports', 'ember-data', 'frontend-cp/models/postable'], function (exports, _emberData, _postable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _postable.default.extend({
    subject: _emberData.default.attr('string'),
    uuid: _emberData.default.attr('string'),
    firstMessage: _emberData.default.belongsTo('case-message', { async: false }),
    postType: 'side_conversation',
    messageCount: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date')
  });
});