define("frontend-cp/components/ko-identities/identity/trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "name": "ko-identities_identity_trigger__name_76sggn",
    "chevron": "ko-identities_identity_trigger__chevron_76sggn"
  };
});