define('frontend-cp/services/developer-tools', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    // Static
    slaMetricsInSeconds: false,

    // Services
    serverClock: service(),

    showSlaMetricsInSeconds: function showSlaMetricsInSeconds() {
      this.set('slaMetricsInSeconds', true);
      this.get('serverClock').restartRunningTick();
    }
  });
});