define('frontend-cp/session/agent/cases/new/organization/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  var get = Ember.get;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    timelineService: service('timeline'),
    store: service(),

    model: function model(params) {
      var caseModel = this.modelFor('session.agent.cases.new');
      var requester = get(caseModel, 'requester');
      var organization = requester ? requester.get('organization') : null;
      var timelineService = this.get('timelineService');
      return RSVP.hash({
        case: caseModel,
        organization: organization,
        caseFields: this.get('store').findAll('case-field')
      }).then(function (data) {
        data.timeline = timelineService.timelineForCaseOrganization(data.case, data.organization);
        return data;
      });
    },
    afterModel: function afterModel(_ref) {
      var organization = _ref.organization;

      if (organization) {
        return RSVP.hash({
          domains: organization.get('domains'),
          tags: organization.get('tags')
        });
      }
    },
    setupController: function setupController(controller, models) {
      controller.setProperties(models);
      var parentController = this.controllerFor('session.agent.cases.new');
      var tab = parentController.get('tab');
      var OrganizationStateManager = getOwner(this).factoryFor('state-manager:organization');
      var state = OrganizationStateManager.create({ organization: models.organization, tab: tab });
      controller.set('state', state);
    }
  });
});