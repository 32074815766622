define("frontend-cp/components/ko-member-list/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "member-component": "ko-member-list__member-component_o1ffhy",
    "section-title": "ko-member-list__section-title_o1ffhy",
    "avatar-list": "ko-member-list__avatar-list_o1ffhy",
    "expanded": "ko-member-list__expanded_o1ffhy",
    "avatar-container": "ko-member-list__avatar-container_o1ffhy",
    "avatar-image": "ko-member-list__avatar-image_o1ffhy",
    "overlay": "ko-member-list__overlay_o1ffhy",
    "add-icon": "ko-member-list__add-icon_o1ffhy",
    "is-active": "ko-member-list__is-active_o1ffhy",
    "add-avatar-plus-element": "ko-member-list__add-avatar-plus-element_o1ffhy",
    "stub": "ko-member-list__stub_o1ffhy",
    "subtext": "ko-member-list__subtext_o1ffhy",
    "user-search": "ko-member-list__user-search_o1ffhy",
    "header": "ko-member-list__header_o1ffhy",
    "search-text": "ko-member-list__search-text_o1ffhy",
    "disabled": "ko-member-list__disabled_o1ffhy",
    "add-member-email": "ko-member-list__add-member-email_o1ffhy",
    "search-message": "ko-member-list__search-message_o1ffhy",
    "no-user-search-message": "ko-member-list__no-user-search-message_o1ffhy ko-member-list__search-message_o1ffhy",
    "no-user-icon": "ko-member-list__no-user-icon_o1ffhy",
    "search-results": "ko-member-list__search-results_o1ffhy",
    "trigger": "ko-member-list__trigger_o1ffhy",
    "dropdown": "ko-member-list__dropdown_o1ffhy _dropdown_1ilz5g _dropdown_jvqwfg",
    "results-list": "ko-member-list__results-list_o1ffhy",
    "member-item": "ko-member-list__member-item_o1ffhy",
    "grey-text": "ko-member-list__grey-text_o1ffhy",
    "member-item-avatar": "ko-member-list__member-item-avatar_o1ffhy",
    "add-user-form": "ko-member-list__add-user-form_o1ffhy",
    "form-head": "ko-member-list__form-head_o1ffhy",
    "add-user-actions": "ko-member-list__add-user-actions_o1ffhy",
    "hide": "ko-member-list__hide_o1ffhy",
    "loader": "ko-member-list__loader_o1ffhy",
    "no-members-no-manager-perm": "ko-member-list__no-members-no-manager-perm_o1ffhy"
  };
});