define('frontend-cp/session/admin/channels/twitter/callback/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    notification: service('notification'),
    i18n: service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var params = transition.queryParams;

      if (params.oauth_token && params.oauth_verifier) {
        this.store.createRecord('twitter-account-callback', {
          oauthToken: params.oauth_token,
          oauthVerifier: params.oauth_verifier
        }).save().then(function () {
          _this.get('notification').add({
            type: 'success',
            title: _this.get('i18n').t('generic.changes_saved'),
            autodismiss: true
          });

          _this.transitionTo('session.admin.channels.twitter.index');
        }).catch(function () {
          _this.transitionTo('session.admin.channels.twitter.index');
        });
      }
    }
  });
});