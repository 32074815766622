define('frontend-cp/adapters/oauth-link', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.callback.match('twitter')) {
        return this.buildURL() + '/twitter/account/link';
      } else if (query.callback.match('facebook')) {
        return this.buildURL() + '/facebook/account/link';
      } else {
        throw new Error('You have to specify a query type');
      }
    }
  });
});