define('frontend-cp/components/ko-entity-list/sidebar/collection-logic-operator-trigger/component', ['exports', 'frontend-cp/lib/keycodes'], function (exports, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    select: null,

    // HTML
    tagName: '',

    actions: {
      handleMouseDown: function handleMouseDown(e) {
        if (this.get('select.isOpen')) {
          e.stopPropagation();
        }
      },
      handleKeyDown: function handleKeyDown(e) {
        var select = this.get('select');
        if (e.keyCode === _keycodes.enter && select.isOpen) {
          select.actions.choose(select.highlighted, e);
          e.stopPropagation();
        }
        // Don't use space to select currently highlighed option
        if (e.keyCode === _keycodes.space && select.isOpen) {
          e.stopPropagation();
        }
        if (!this.get('select.isOpen') && e.keyCode !== _keycodes.tab && e.keyCode !== _keycodes.enter) {
          e.preventDefault();
        }
      }
    }
  });
});