define("frontend-cp/components/ko-sla/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-sla__container_1g6nbp",
    "metric": "ko-sla__metric_1g6nbp",
    "metric-breached": "ko-sla__metric-breached_1g6nbp",
    "icon": "ko-sla__icon_1g6nbp",
    "icon-good": "ko-sla__icon-good_1g6nbp",
    "icon-bad": "ko-sla__icon-bad_1g6nbp",
    "icon-open": "ko-sla__icon-open_1g6nbp",
    "icon-breached": "ko-sla__icon-breached_1g6nbp",
    "text": "ko-sla__text_1g6nbp",
    "text-breached": "ko-sla__text-breached_1g6nbp"
  };
});