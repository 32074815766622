define("frontend-cp/components/ko-admin/engagement-actions-builder/preview/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "engagement__block__container": "ko-admin_engagement-actions-builder_preview__engagement__block__container_4uq7od",
    "engagement__block": "ko-admin_engagement-actions-builder_preview__engagement__block_4uq7od",
    "avatar__container": "ko-admin_engagement-actions-builder_preview__avatar__container_4uq7od",
    "multiple__avatars": "ko-admin_engagement-actions-builder_preview__multiple__avatars_4uq7od",
    "engagement__block__text__container": "ko-admin_engagement-actions-builder_preview__engagement__block__text__container_4uq7od",
    "engagement__block__message": "ko-admin_engagement-actions-builder_preview__engagement__block__message_4uq7od",
    "engagement__block__message__text": "ko-admin_engagement-actions-builder_preview__engagement__block__message__text_4uq7od",
    "engagement__block__reply-to": "ko-admin_engagement-actions-builder_preview__engagement__block__reply-to_4uq7od",
    "engagement__block__message__time": "ko-admin_engagement-actions-builder_preview__engagement__block__message__time_4uq7od",
    "engagement__block__reply": "ko-admin_engagement-actions-builder_preview__engagement__block__reply_4uq7od",
    "engagement__block__reply__text": "ko-admin_engagement-actions-builder_preview__engagement__block__reply__text_4uq7od",
    "engagement__block__sender__name": "ko-admin_engagement-actions-builder_preview__engagement__block__sender__name_4uq7od",
    "icon__items": "ko-admin_engagement-actions-builder_preview__icon__items_4uq7od",
    "icon__item": "ko-admin_engagement-actions-builder_preview__icon__item_4uq7od",
    "icon__item--emoji": "ko-admin_engagement-actions-builder_preview__icon__item--emoji_4uq7od",
    "bubble__container": "ko-admin_engagement-actions-builder_preview__bubble__container_4uq7od",
    "bubble": "ko-admin_engagement-actions-builder_preview__bubble_4uq7od",
    "bubble__icon": "ko-admin_engagement-actions-builder_preview__bubble__icon_4uq7od"
  };
});