define("frontend-cp/components/ko-prompt-modal/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "main": "ko-prompt-modal__main_woql3f",
    "header": "ko-prompt-modal__header_woql3f",
    "body": "ko-prompt-modal__body_woql3f",
    "button": "ko-prompt-modal__button_woql3f",
    "label": "ko-prompt-modal__label_woql3f",
    "extra": "ko-prompt-modal__extra_woql3f"
  };
});