define('frontend-cp/session/admin/account/overview/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return hash({
        subscription: this.store.findAll('subscription', { reload: true }).then(function (subscription) {
          return subscription.get('firstObject');
        }),
        cards: this.store.findAll('creditcard', { reload: true }),
        invoices: this.store.findAll('invoice', { reload: true })
      });
    }
  });
});