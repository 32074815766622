define('frontend-cp/models/device-property', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    lastIpAddress: _emberData.default.attr('string'),
    subscriptionInfo: _emberData.default.attr('string'),
    osVersion: _emberData.default.attr('string'),
    deviceManufacturer: _emberData.default.attr('string'),
    deviceModel: _emberData.default.attr('string'),
    screenSize: _emberData.default.attr('string')
  });
});