define("frontend-cp/components/ko-form/field/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-form_field__container_7bfahe",
    "gap": "ko-form_field__gap_7bfahe",
    "error": "ko-form_field__error_7bfahe"
  };
});