define('frontend-cp/components/ko-insights/custom-reports/index-new/component', ['exports', 'ember-service/inject', 'ember-concurrency'], function (exports, _inject, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    tagName: '',
    store: service(),
    confirmation: service(),
    notification: service(),
    i18n: service(),
    metrics: service(),
    reportsService: service('reports'),

    generate: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(report) {
      var id, adapter, response;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              report.set('status', 'TRIGGERED');

              id = report.get('id');
              adapter = getOwner(this).lookup('adapter:application');
              _context.next = 5;
              return adapter.ajax(adapter.namespace + '/reports/' + id + '/generate', 'POST');

            case 5:
              response = _context.sent;

              this.get('store').pushPayload('report-case', response);

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    actions: {
      download: function download(report, e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        this.get('reportsService').download(report);
      },
      generate: function generate(report, e) {
        var launchDarkly = this.get('launchDarkly');

        e.preventDefault();
        e.stopImmediatePropagation();

        this.get('generate').perform(report);
        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'custom_reports_run'
          });
        }
      },
      delete: function _delete(report) {
        var _this = this;

        return this.get('confirmation').confirm({
          intlConfirmLabel: 'generic.confirm.delete_button',
          intlConfirmationBody: 'insights.custom_reports.confirm_delete.body',
          intlConfirmationHeader: 'insights.custom_reports.confirm_delete.title'
        }).then(function () {
          return report.destroyRecord().then(function () {
            _this.get('notification').add({
              type: 'success',
              title: _this.get('i18n').t('insights.custom_reports.deleted'),
              autodismiss: true
            });
          });
        });
      }
    }
  });
});