define("frontend-cp/components/ko-info-bar/field/select/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "power-select": "ko-info-bar_field_select__power-select_1sltg1",
    "trigger": "ko-info-bar_field_select__trigger_1sltg1",
    "dropdown": "ko-info-bar_field_select__dropdown_1sltg1 _dropdown_1ilz5g _dropdown_jvqwfg"
  };
});