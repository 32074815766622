define("frontend-cp/session/admin/customizations/user-fields/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "icon": "session_admin_customizations_user-fields_index__icon_jgsin3",
    "title": "session_admin_customizations_user-fields_index__title_jgsin3"
  };
});