define('frontend-cp/helpers/format-time', ['exports', 'ember-intl/helpers/format-time'], function (exports, _formatTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _formatTime.default.extend({
    i18n: service(),

    compute: function compute(params, hash) {
      var timeFormatSettings = Object.assign({}, hash);

      timeFormatSettings.hour12 = this.get('i18n.hour12');
      if (params[0]) {
        return this._super(params, timeFormatSettings);
      } else {
        return null;
      }
    }
  });
});