define('frontend-cp/components/ko-pill-input/trigger/component', ['exports', 'ember-power-select/components/power-select-multiple/trigger', 'frontend-cp/components/ko-pill-input/trigger/template', 'frontend-cp/utils/dispatch-event', 'frontend-cp/lib/keycodes'], function (exports, _trigger, _template, _dispatchEvent, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isBlank = Ember.isBlank;
  exports.default = _trigger.default.extend({
    layout: _template.default,

    actions: {
      onBlur: function onBlur(e) {
        var select = this.get('select');
        if (this.get('isEmpty') && !isBlank(select.searchText)) {
          // If we don't do this, the text in the input element won't be converted
          // into a pill, when we click outside the pill input
          (0, _dispatchEvent.default)(e.target, 'keydown', { keyCode: _keycodes.enter });
        }
      }
    }
  });
});