define("frontend-cp/components/ko-simple-list/actions/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "actions": "ko-simple-list_actions__actions_tl32wa",
    "hidden": "ko-simple-list_actions__hidden_tl32wa"
  };
});