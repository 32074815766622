define('frontend-cp/services/roles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  exports.default = Service.extend({
    i18n: service(),

    editableSystemTypes: computed('allTypes', function () {
      var _this = this;

      return ['ADMIN', 'AGENT'].map(function (typeId) {
        return _this.get('allTypes').findBy('id', typeId);
      });
    }),

    allTypes: computed('i18n.locale', function () {
      var _this2 = this;

      return ['ADMIN', 'AGENT', 'COLLABORATOR', 'CUSTOMER', 'OWNER'].map(function (typeId) {
        return EmberObject.create({
          id: typeId,

          label: _this2.get('i18n').t('admin.roles.type.' + typeId.toLowerCase())
        });
      });
    }),

    availableTypes: computed('allTypes', function () {
      return this.get('allTypes').filter(function (type) {
        return ['ADMIN', 'AGENT'].includes(type.get('id'));
      });
    }),

    availableAgentCaseAccessTypes: computed('i18n.locale', function () {
      var _this3 = this;

      return ['ALL', 'TEAMS', 'SELF'].map(function (agentCaseAccessTypeId) {
        return EmberObject.create({
          id: agentCaseAccessTypeId,

          label: _this3.get('i18n').t('admin.roles.agent_case_access_type.' + agentCaseAccessTypeId.toLowerCase())
        });
      });
    })
  });
});