define('frontend-cp/components/ko-form/buttons/component', ['exports', 'frontend-cp/components/ko-form/component', 'frontend-cp/lib/computed-fallback-if-undefined'], function (exports, _component, _computedFallbackIfUndefined) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var oneWay = Ember.computed.oneWay;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Params
    submitLabel: null,
    cancelLabel: null,
    deleteLabel: null,
    onCancel: null,
    onSubmit: null,
    isValid: true,
    onDelete: null,
    isSaving: false,
    cancelButtonClass: (0, _computedFallbackIfUndefined.default)('qa-ko-form_buttons__cancel'),
    submitClass: (0, _computedFallbackIfUndefined.default)('qa-ko-form_buttons__submit'),

    i18n: service(),

    koForm: computed(function () {
      return this.nearestOfType(_component.default);
    }),

    // TODO depracate and replace with isSaving
    isSubmitting: oneWay('koForm.isSubmitting'),

    actions: {
      deleteAction: function deleteAction() {
        var _this = this;

        if (this.get('koForm')) {
          this.set('koForm.isSubmitting', true);
        }
        this.get('onDelete')().finally(function (result) {
          if (_this.get('koForm')) {
            _this.set('koForm.isSubmitting', false);
          }
        });
      }
    }
  });
});