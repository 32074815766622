define('frontend-cp/adapters/intl', ['exports', 'ember-intl/adapters/-intl-adapter', 'frontend-cp/locales/new-locale'], function (exports, _intlAdapter, _newLocale) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _intlAdapter.default.extend({
    locales: {},

    findLanguage: function findLanguage(locale) {
      if (locale instanceof _newLocale.default) {
        return locale;
      }

      if (typeof locale === 'string') {
        if (!this.locales[locale]) {
          this.locales[locale] = new (_newLocale.default.extend({
            locale: locale
          }))();
        }
        return this.locales[locale];
      }
    }
  });
});