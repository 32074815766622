define('frontend-cp/components/ko-admin/macros/edit/action-handlers/remove-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    serialize: function serialize(action, macro, store) {
      this.reset(macro);

      if (action.value) {
        var tags = action.value && action.value.split(',') || null;
        var removeTags = macro.get('removeTags');

        if (removeTags) {
          macro.get('removeTags').setObjects(tags);
        } else {
          macro.set('removeTags', tags);
        }
      }

      return macro;
    },
    deserialize: function deserialize(macro, actions) {
      var removeTags = macro.get('removeTags');

      if (removeTags && removeTags.length) {
        actions.push({
          name: 'remove-tags',
          option: 'REMOVE',
          value: removeTags.join(',')
        });
      }

      return actions;
    },
    reset: function reset(macro) {
      macro.set('removeTags', []);
    }
  };
});