define('frontend-cp/serializers/business-hour', ['exports', 'ember-data', 'frontend-cp/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      holidays: { embedded: 'always' }
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      json.zones = JSON.stringify(json.zones);
      return json;
    }
  });
});