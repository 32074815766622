define('frontend-cp/session/agent/cases/case/user/controller', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    tabStore: service('tab-store'),
    queryParams: ['timestamp'],
    timestamp: null,

    actions: {
      updateQueryParams: function updateQueryParams(changes) {
        this.setProperties(changes);
        return true;
      },
      createNewCase: function createNewCase(userId) {
        this.get('tabStore').transitionAndInsertTabNextToActiveTab('session.agent.cases.new', [(0, _moment.default)().format('YYYY-MM-DD-hh-mm-ss')], { queryParams: { requester_id: userId } });
      }
    }
  });
});