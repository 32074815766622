define('frontend-cp/adapters/user', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    ajax: function ajax(url, method, params) {
      if (params && params.data) {
        switch (this._mode(params.data)) {
          case 'userList':
            delete params.data.offset;
            delete params.data.limit;

            return this._super(url, 'POST', params);
          default:
            return this._super(url, method, params);
        }
      } else {
        return this._super(url, method, params);
      }
    },
    urlForQuery: function urlForQuery(query) {
      switch (this._mode(query)) {
        case 'autocomplete':
          return this.urlPrefix() + '/autocomplete/users';
        case 'userList':
          return this.urlPrefix() + '/users/filter?offset=' + query.offset + '&limit=' + query.limit;
        default:
          return this._super.apply(this, arguments);
      }
    },
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.id === 'me') {
        Reflect.deleteProperty(query, 'id');
        return '/api/v1/me';
      } else {
        return this._super.apply(this, arguments);
      }
    },
    findHasMany: function findHasMany(store, snapshot, url, relationship) {
      if (relationship.key === 'recentCases') {
        var data = {
          limit: 999,
          fields: 'requester,assigned_agent(*),assigned_team(*),status,updated_at,subject',
          include: ['case_status']
        };
        url = this.urlPrefix(url, this.buildURL(snapshot.modelName, snapshot.id, snapshot, 'findHasMany'));
        return this.ajax(url, 'GET', { data: data });
      }

      return this._super.apply(this, arguments);
    },


    // We assume that all endpoints, apart from /cases/:id, might return cases with
    // some fields potentially missing. _isFullyLoaded is set automatically during
    // queryRecord.
    shouldReloadRecord: function shouldReloadRecord(store, snapshot) {
      return !snapshot.record.get('_isFullyLoaded');
    },
    deleteAvatar: function deleteAvatar(userid) {
      var url = this.namespace + '/profile/avatar';
      return this.ajax(url, 'DELETE');
    },
    fetchAvatar: function fetchAvatar(userid) {
      var url = this.namespace + '/users/' + userid + '/avatar';
      return this.ajax(url, 'GET');
    },
    updateAvatar: function updateAvatar(userid, data) {
      var url = this.namespace + '/users/' + userid + '/avatar';
      return this.ajax(url, 'PUT', { data: data });
    },
    updateSignature: function updateSignature(userId, signature) {
      var url = this.namespace + '/users/' + userId;
      return this.ajax(url, 'PUT', {
        data: {
          signature: signature
        }
      });
    },
    getQrCode: function getQrCode() {
      var url = this.namespace + '/profile/twofactor';
      return this.ajax(url, 'GET');
    },
    sendTwoFactorCode: function sendTwoFactorCode(data) {
      var url = this.namespace + '/profile/twofactor';
      return this.ajax(url, 'POST', { data: data });
    },
    removeSelfTwoFactorAuth: function removeSelfTwoFactorAuth() {
      var url = this.namespace + '/profile/twofactor';
      return this.ajax(url, 'DELETE');
    },
    removeTwoFactorAuth: function removeTwoFactorAuth(userId) {
      var url = this.namespace + '/users/' + userId + '/twofactor';
      return this.ajax(url, 'DELETE');
    },
    changePassword: function changePassword(password, newPassword) {
      var url = this.namespace + '/profile/password';
      return this.ajax(url, 'PUT', {
        data: {
          password: password,
          new_password: newPassword
        }
      });
    },
    _mode: function _mode(param) {
      if (param.predicates) {
        return 'userList';
      } else if (param.name) {
        return 'autocomplete';
      } else {
        return '';
      }
    }
  });
});