define("frontend-cp/components/ko-admin/views/edit/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "predicate-header": "ko-admin_views_edit__predicate-header_byj5fo",
    "sorting": "ko-admin_views_edit__sorting_byj5fo",
    "sort-direction": "ko-admin_views_edit__sort-direction_byj5fo",
    "team-sharing": "ko-admin_views_edit__team-sharing_byj5fo",
    "team-selector": "ko-admin_views_edit__team-selector_byj5fo"
  };
});