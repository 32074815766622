define("frontend-cp/components/ko-pill-input/trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "input": "ko-pill-input_trigger__input_rdfpbw",
    "loading": "ko-pill-input_trigger__loading_rdfpbw _loading-icon_jvqwfg"
  };
});