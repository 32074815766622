define("frontend-cp/components/ko-avatar-update/trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "overlay": "ko-avatar-update_trigger__overlay_8ueu1f",
    "avatar-overlay": "ko-avatar-update_trigger__avatar-overlay_8ueu1f",
    "loading-overlay": "ko-avatar-update_trigger__loading-overlay_8ueu1f"
  };
});