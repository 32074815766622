define('frontend-cp/session/agent/knowledgebase/article-view/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Route = Ember.Route;
  var hash = Ember.RSVP.hash;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    store: service(),
    session: service(),
    locale: service(),

    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(params) {
        var articleId, articleAdapter, article, defaultLocale, userLocale, titleObj, contentObj;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                articleId = params.id;
                articleAdapter = this.store.adapterFor('article');
                _context.next = 4;
                return articleAdapter.fetchArticleById(articleId);

              case 4:
                article = _context.sent;
                defaultLocale = this.get('locale.accountDefaultLocaleCode');
                userLocale = this.get('session.user.locale.locale');
                titleObj = article.titles.find(function (t) {
                  return t.locale === defaultLocale;
                }) || article.titles.find(function (t) {
                  return t.locale === userLocale;
                }) || article.titles[0];

                article.title = titleObj.translation;

                contentObj = article.contents.find(function (c) {
                  return c.locale === defaultLocale;
                }) || article.contents.find(function (c) {
                  return c.locale === userLocale;
                }) || article.contents[0];

                article.content = contentObj.translation;

                return _context.abrupt('return', hash({
                  article: article
                }));

              case 12:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function model(_x) {
        return _ref.apply(this, arguments);
      }

      return model;
    }(),


    actions: {
      refreshRoute: function refreshRoute() {
        var _this = this;

        this.refresh().then(function () {
          var controller = _this.controllerFor(_this.routeName);
          controller.set('isArticleLoading', false);
        });
      }
    }
  });
});