define('frontend-cp/instance-initializers/bugsnag', ['exports', 'frontend-cp/config/environment', 'ember-cli-bugsnag/utils/errors', 'frontend-cp/utils/bugsnag', 'bugsnag'], function (exports, _environment, _errors, _bugsnag, _bugsnag2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var get = Ember.get,
      setProperties = Ember.setProperties;
  function initialize(instance) {
    var _this = this;

    if (_bugsnag2.default.apiKey === undefined) {
      return;
    }
    var currentEnv = _environment.default.environment;
    var bugsnagConfig = _environment.default.bugsnag || {};
    var releaseStage = bugsnagConfig.releaseStage || currentEnv;

    if (currentEnv !== 'test' && _bugsnag2.default.notifyReleaseStages.indexOf(releaseStage) !== -1) {
      var owner = instance.lookup ? instance : instance.container;
      var router = owner.lookup('router:main');

      setProperties(this, {
        owner: owner,
        router: router
      });

      Ember.onerror = function (error) {
        return _this._onError(error);
      };

      router.didTransition = this._didTransition();
    }
  }

  exports.default = {
    name: 'bugsnag-error-service',

    initialize: initialize,

    _didTransition: function _didTransition() {
      var router = get(this, 'router');
      var originalDidTransition = router.didTransition || function () {};

      return function () {
        _bugsnag2.default.refresh();
        return originalDidTransition.apply(this, arguments);
      };
    },
    _onError: function _onError(error) {
      this._setContext();
      this._setUser();
      this._setNotifyException(error);

      /* eslint-disable no-console */
      console.error(error.stack);
      /* eslint-enable no-console */
    },
    _setContext: function _setContext() {
      var router = get(this, 'router');
      _bugsnag2.default.context = (0, _errors.getContext)(router);
    },
    _setNotifyException: function _setNotifyException(error) {
      var owner = get(this, 'owner');
      var metaData = _bugsnag.getMetaData ? _bugsnag.getMetaData(error, owner) : {};
      _bugsnag2.default.notifyException(error, null, metaData);
    },
    _setUser: function _setUser() {
      var owner = get(this, 'owner');
      if (_bugsnag.getUser) {
        var user = _bugsnag.getUser(owner);
        _bugsnag2.default.user = user;
      }
    }
  };
});