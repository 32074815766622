define("frontend-cp/components/ko-table/header/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-table_header__container_1qqvlu",
    "row": "ko-table_header__row_1qqvlu"
  };
});