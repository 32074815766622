define('frontend-cp/lib/noisy-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  exports.default = EmberObject.extend({
    length: null,
    isTruthy: true,

    unknownProperty: function unknownProperty(key) {
      var path = this.get('key') + '.' + key;
      var error = new Error(path);
      console.error(error); /* eslint no-console: "off" */
    },
    makeNoisyChild: function makeNoisyChild(key, attrs) {
      return this.constructor.create(Object.assign({
        key: this.get('key') + '.' + key
      }, attrs));
    }
  });
});