define('frontend-cp/components/ko-select/multiple/trigger/component', ['exports', 'ember-power-select/components/power-select-multiple/trigger', 'frontend-cp/components/ko-select/multiple/trigger/template', 'frontend-cp/utils/dispatch-event', 'frontend-cp/lib/keycodes'], function (exports, _trigger, _template, _dispatchEvent, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isBlank = Ember.isBlank;
  exports.default = _trigger.default.extend({
    layout: _template.default,

    actions: {
      keepOpened: function keepOpened(e) {
        if (this.get('select.isOpen')) {
          e.stopPropagation();
        }
      },
      handleBlur: function handleBlur(e) {
        // We fake an enter keydown event here so it goes through the
        // usual power-select path of making a selection.
        // wiring up the blur event would require a fair bit of extra
        // code and the end result is the same.
        var select = this.get('select');
        if ((this.get('isEmpty') || select.isOpen) && !isBlank(select.searchText)) {
          (0, _dispatchEvent.default)(e.target, 'keydown', { keyCode: _keycodes.enter });
        }
      }
    }
  });
});