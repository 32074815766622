define("frontend-cp/components/ko-timeline-2/list/item/at-mention/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "mention": "ko-timeline-2_list_item_at-mention__mention_177f1h",
    "mention-anchor": "ko-timeline-2_list_item_at-mention__mention-anchor_177f1h",
    "mention-me": "ko-timeline-2_list_item_at-mention__mention-me_177f1h"
  };
});