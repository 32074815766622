define('frontend-cp/session/admin/automation/triggers/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      var store = this.get('store');

      return hash({
        definitions: store.query('definition', { type: 'trigger' }),
        automationActionDefinitions: store.query('automation-action-definition', { type: 'trigger' })
      });
    }
  });
});