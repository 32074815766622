define('frontend-cp/components/ko-admin/page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    title: null,
    buttonText: null,
    cancelButtonText: 'Cancel',
    onCancel: null,
    onSave: null,
    showFooter: false,
    tabs: null,
    isValid: true,
    onDelete: null,
    isSaving: null,
    needsPlanUpgrade: false,
    isBeta: false,

    plan: service(),

    titleTrail: computed('title', function () {
      var title = this.get('title');
      return title.slice(0, title.lastIndexOf('/') + 1);
    }),

    upgradeUrl: computed('plan.isTrial', function () {
      return this.get('plan.isTrial') ? 'session.admin.account.trial' : 'session.admin.account.plans';
    }),

    titleSuffix: computed('title', function () {
      var title = this.get('title');
      return title.slice(title.lastIndexOf('/') + 1).trim();
    })
  });
});