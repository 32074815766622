define("frontend-cp/components/ko-admin/sla/edit/target/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "target": "ko-admin_sla_edit_target__target_ed8fi2",
    "value": "ko-admin_sla_edit_target__value_ed8fi2",
    "unit": "ko-admin_sla_edit_target__unit_ed8fi2"
  };
});