define('frontend-cp/models/attachment', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    size: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'), // TODO should exist on attachment within posts/:id
    height: _emberData.default.attr('number'), // TODO should exist on attachment within posts/:id
    type: _emberData.default.attr('string'), // TODO should exist on attachment within posts/:id
    attachmentType: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'), // TODO should exist on attachment within posts/:id
    urlDownload: _emberData.default.attr('string'), // TODO should exist on attachment within posts/:id
    thumbnails: _emberDataModelFragments.default.fragmentArray('thumbnail'),
    createdAt: _emberData.default.attr('date'), // TODO should exist on attachment within posts/:id

    // Virtual parent field
    message: _emberData.default.belongsTo('case-message', { async: true })
  });
});