define("frontend-cp/components/ko-admin/businesshours/edit/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "ko-admin_businesshours_edit__title_1pht6q",
    "add-holiday": "ko-admin_businesshours_edit__add-holiday_1pht6q"
  };
});