define('frontend-cp/session/admin/apps/webhooks/edit/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)(), {
    model: function model(params) {
      return this.store.findRecord('token', params.webhook_id);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties(this.modelFor('session.admin.apps.webhooks'));
    },


    // Actions
    actions: {
      didSave: function didSave() {
        this.transitionTo('session.admin.apps.webhooks');
      },
      cancel: function cancel() {
        this.transitionTo('session.admin.apps.webhooks');
      }
    }
  });
});