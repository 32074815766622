define("frontend-cp/components/ko-admin/predicate-builder/proposition/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-admin_predicate-builder_proposition__container_1wbeaz",
    "fadeIn": "ko-admin_predicate-builder_proposition__fadeIn_1wbeaz",
    "actions": "ko-admin_predicate-builder_proposition__actions_1wbeaz",
    "label": "ko-admin_predicate-builder_proposition__label_1wbeaz",
    "containerRemoving": "ko-admin_predicate-builder_proposition__containerRemoving_1wbeaz",
    "item": "ko-admin_predicate-builder_proposition__item_1wbeaz",
    "input-time-group": "ko-admin_predicate-builder_proposition__input-time-group_1wbeaz",
    "input-time": "ko-admin_predicate-builder_proposition__input-time_1wbeaz",
    "input-time-label": "ko-admin_predicate-builder_proposition__input-time-label_1wbeaz",
    "remove": "ko-admin_predicate-builder_proposition__remove_1wbeaz",
    "layout": "ko-admin_predicate-builder_proposition__layout_1wbeaz"
  };
});