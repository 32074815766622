define('frontend-cp/components/ko-admin/time-tracking/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var jQuery = Ember.$;
  exports.default = Component.extend({
    value: null,

    session: service(),
    store: service(),
    notification: service(),
    i18n: service(),

    updateValue: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var value;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              this.toggleProperty('value');
              value = this.get('value') ? '1' : '0';
              _context.prev = 2;
              _context.next = 5;
              return jQuery.ajax({
                url: '/api/v1/settings',
                headers: { 'X-CSRF-Token': this.get('session.csrfToken') },
                method: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify({ values: { 'cases.timetracking': value } })
              });

            case 5:

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('admin.apps.timetracking.notification', { isEnabled: value }),
                autodismiss: true
              });
              _context.next = 11;
              break;

            case 8:
              _context.prev = 8;
              _context.t0 = _context['catch'](2);

              this.get('notification').add({
                type: 'error',
                title: this.get('i18n').t('generic.generic_error'),
                autodismiss: true
              });

            case 11:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[2, 8]]);
    }))
  });
});