define('frontend-cp/session/agent/insights/help-center/articles/route', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  exports.default = Route.extend({
    launchDarkly: (0, _inject.default)(),

    insights: service(),
    metrics: service(),
    plan: service(),

    model: function model() {
      var insights = this.get('insights');
      var insightsParams = this.paramsFor('session.agent.insights');

      var metricsQueryParams = {
        data: {
          trial: insights.isTrialMode() && insightsParams.trial
        }
      };

      return hash({
        metricsQueryParams: metricsQueryParams
      });
    },
    beforeModel: function beforeModel(transition) {
      var launchDarkly = this.get('launchDarkly');
      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('release-restrict-insights')) {
        if (!this.get('plan').has('helpcenter_insights') && !launchDarkly.get('feature-restrict-helpcenter-insights')) {
          this.transitionTo('session.agent.insights.general.cases');
        }
      }
    },
    setupController: function setupController(controller, hash) {
      var _this = this;

      this._super.apply(this, arguments);

      var insights = this.get('insights');

      controller.setProperties({
        metricsQueryParams: hash.metricsQueryParams
      });

      if (insights.isTrialMode()) {
        insights.pushTrialNotification(function () {
          _this.transitionTo({ queryParams: { trial: false } });
        });
      }
    },


    actions: {
      didTransition: function didTransition() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'article_insights_viewed'
          });
        }
      }
    }
  });
});