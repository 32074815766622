define('frontend-cp/components/ko-insights/sla-resolution-target/component', ['exports', 'frontend-cp/lib/humanize-seconds'], function (exports, _humanizeSeconds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    i18n: service(),
    tagName: '',
    data: null,
    performance: null,
    interval: null,
    section: null,

    onIntervalChange: null,

    targets: computed('data.targets', function () {
      var _this = this;

      var targets = this.get('data.targets');

      return targets.map(function (target) {
        target.metrics = target.metrics.map(function (metric) {
          if (metric.name === 'percentage_achieved') {
            return {
              name: metric.name,
              value: metric.value ? Math.round(metric.value) + '%' : 0,
              previous: metric.previous ? Math.round(metric.previous) + '%' : 0,
              delta: Math.round(metric.delta_percent),
              deltaInverted: false
            };
          } else if (['average_time_to_target', 'average_time_overdue_to_breach'].indexOf(metric.name) > -1) {
            return {
              name: metric.name,
              value: metric.value ? (0, _humanizeSeconds.default)(metric.value) : 0,
              previous: metric.previous ? (0, _humanizeSeconds.default)(metric.previous) : 0,
              delta: Math.round(metric.delta_percent),
              deltaInverted: true
            };
          } else if (metric.name === 'total_breached') {
            return {
              name: metric.name,
              value: metric.value ? _this.get('i18n').formatNumber(metric.value) : 0,
              previous: metric.previous,
              delta: Math.round(metric.delta_percent),
              deltaInverted: true
            };
          }
        });

        return target;
      });
    }),

    performanceData: computed('performance', 'performance.performance_series', function () {
      var performance = this.get('performance');
      var performanceSeries = this.get('performance.performance_series.firstObject') || {};

      if (!performance) {
        return [];
      }

      performanceSeries.start_at = performance.start_at;
      performanceSeries.end_at = performance.end_at;
      performanceSeries.previous_start_at = performance.previous_start_at;
      performanceSeries.previous_end_at = performance.previous_end_at;
      performanceSeries.interval = performance.interval;
      performanceSeries.interval_count = performance.interval_count;

      return performanceSeries;
    })
  });
});