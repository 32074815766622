define('frontend-cp/session/admin/manage/views/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    i18n: service(),
    sessionService: service('session'),
    notifications: service('notification'),
    confirmation: service(),
    caseListTab: service('case-list-tab'),

    // CPs
    inboxView: computed('model.[]', function () {
      return this.get('model').find(function (caseView) {
        return caseView.get('viewType') === 'INBOX';
      });
    }),

    trashView: computed('model.[]', function () {
      return this.get('model').find(function (caseView) {
        return caseView.get('viewType') === 'TRASH';
      });
    }),

    enabledViews: computed('model.@each.isEnabled', 'model.@each.sortOrder', function () {
      var _this = this;

      return this.get('model').filter(function (view) {
        return view.get('isEnabled') && ![_this.get('inboxView'), _this.get('trashView')].includes(view);
      }).sortBy('sortOrder');
    }),

    disabledViews: computed('model.@each.isEnabled', 'model.@each.sortOrder', function () {
      return this.get('model').filter(function (view) {
        return !view.get('isEnabled');
      }).sortBy('sortOrder');
    }),

    // Actions
    actions: {
      transitionToNewViewRoute: function transitionToNewViewRoute() {
        this.transitionToRoute('session.admin.manage.views.new');
      },
      editView: function editView(view) {
        this.transitionToRoute('session.admin.manage.views.edit', view);
      },
      reorderViews: function reorderViews(orderedViews) {
        var _this2 = this;

        var adapter = getOwner(this).lookup('adapter:application');
        var url = adapter.namespace + '/views/reorder';

        var startingSortOrderNumber = 2; // The inbox is always frst

        var orderedIds = orderedViews.getEach('id');

        var sortOrder = startingSortOrderNumber;
        orderedViews.forEach(function (customView) {
          customView.set('sortOrder', sortOrder);
          sortOrder++;
        });

        var options = {
          data: {
            view_ids: orderedIds.toString() // eslint-disable-line camelcase
          }
        };

        adapter.ajax(url, 'PUT', options).then(function () {
          _this2.get('notifications').success(_this2.get('i18n').t('admin.views.order_saved.message'));
          _this2.get('caseListTab').set('forceNextLoad', true);
        });
      },
      toggleEnabledStatus: function toggleEnabledStatus(view, e) {
        var _this3 = this;

        e.stopPropagation();
        view.toggleProperty('isEnabled');
        view.save().then(function () {
          var notificationMessage = void 0;
          if (view.get('isEnabled')) {
            notificationMessage = _this3.get('i18n').t('admin.views.enabled.message');
          } else {
            notificationMessage = _this3.get('i18n').t('admin.views.disabled.message');
          }

          _this3.get('caseListTab').set('forceNextLoad', true);
          _this3.get('notifications').success(notificationMessage);
        });
      },
      delete: function _delete(view, e) {
        var _this4 = this;

        e.preventDefault();
        e.stopPropagation();
        return this.get('confirmation').confirm({
          intlConfirmationBody: 'generic.confirm.delete'
        }).then(function () {
          return view.destroyRecord();
        }).then(function () {
          var msg = _this4.get('i18n').t('admin.views.deleted.message');
          _this4.get('notifications').success(msg);
          _this4.get('caseListTab').set('forceNextLoad', true);
        });
      }
    }
  });
});