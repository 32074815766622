define("frontend-cp/components/ko-admin/apps/endpoints/list-row/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "row__warning": "ko-admin_apps_endpoints_list-row__row__warning_1s9tsp",
    "container": "ko-admin_apps_endpoints_list-row__container_1s9tsp",
    "icon": "ko-admin_apps_endpoints_list-row__icon_1s9tsp",
    "row__description": "ko-admin_apps_endpoints_list-row__row__description_1s9tsp",
    "row__last-attempt": "ko-admin_apps_endpoints_list-row__row__last-attempt_1s9tsp",
    "row__warning-icon": "ko-admin_apps_endpoints_list-row__row__warning-icon_1s9tsp",
    "row__title": "ko-admin_apps_endpoints_list-row__row__title_1s9tsp",
    "row-details": "ko-admin_apps_endpoints_list-row__row-details_1s9tsp"
  };
});