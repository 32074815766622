define("frontend-cp/components/ko-notification-center-new/-base-notification/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-notification-center-new_-base-notification__item_2sws5g",
    "is-seen": "ko-notification-center-new_-base-notification__is-seen_2sws5g",
    "meta": "ko-notification-center-new_-base-notification__meta_2sws5g",
    "time": "ko-notification-center-new_-base-notification__time_2sws5g",
    "read": "ko-notification-center-new_-base-notification__read_2sws5g",
    "item-link": "ko-notification-center-new_-base-notification__item-link_2sws5g"
  };
});