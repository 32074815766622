define('frontend-cp/components/ko-admin/staff/list-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    //attributes
    user: null,
    list: null,

    // Services
    tabStore: service(),
    router: service(),

    // CPs
    teamList: computed('user.teams.[]', function () {
      return this.get('user.teams').map(function (team) {
        return team.get('title');
      }).join(', ');
    }),

    actions: {
      viewUser: function viewUser(user, event) {
        var router = this.get('router');
        var route = 'session.agent.users.user';
        var hasModifier = event.metaKey || event.ctrlKey || event.shiftKey;

        if (hasModifier) {
          this.get('tabStore').createTab(route, user);
        } else {
          router.transitionTo(route, user.get('id'));
        }
      }
    }
  });
});