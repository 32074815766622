define('frontend-cp/session/admin/index/route', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    launchDarkly: (0, _inject.default)(),
    redirect: function redirect() {
      var launchDarkly = this.get('launchDarkly');

      if (!launchDarkly.get('release-admin-landing-page')) {
        this.transitionTo('session.admin.people.staff');
      }
    }
  });
});