define('frontend-cp/session/admin/apps/salesforce/controller', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    metrics: service(),

    actions: {
      ctaClick: function ctaClick() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('ops-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'Salesforce - View landing page',
            category: 'Admin'
          });
        }
      }
    }
  });
});