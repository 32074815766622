define('frontend-cp/helpers/ko-seconds-to-time', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.koSecondsToTime = koSecondsToTime;
  var helper = Ember.Helper.helper;
  function koSecondsToTime(totalSeconds) {
    var duration = _moment.default.duration(totalSeconds[0], 'seconds');
    var days = duration.days();
    var hours = duration.hours();
    var minutes = duration.minutes();
    var seconds = duration.seconds();

    var time = '';
    time += days ? days + 'd ' : '';
    time += hours ? hours + 'h ' : '';
    time += minutes ? minutes + 'm ' : '';
    time += seconds ? seconds + 's' : '';

    return time;
  }

  exports.default = helper(koSecondsToTime);
});