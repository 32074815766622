define('frontend-cp/components/ko-admin/automation-actions-builder/notification/component', ['exports', 'frontend-cp/components/ko-admin/automation-actions-builder/base/component', 'ember-concurrency'], function (exports, _component, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var equal = Ember.computed.equal;
  var computed = Ember.computed;
  var set = Ember.set;
  var get = Ember.get;
  var service = Ember.inject.service;


  function normalizeUser(u) {
    return { id: get(u, 'id'), text: get(u, 'fullName') };
  }

  exports.default = _component.default.extend({
    store: service(),
    i18n: service(),

    attributeMultilineMappings: {
      subject: false, // <input>
      message: true // <textarea>
    },

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      var action = this.get('automationAction');
      var attributes = this.get('definition.attributes').reduce(function (obj, attr) {
        if (!obj.hasOwnProperty(attr)) {
          obj[attr] = null;
        }
        return obj;
      }, get(action, 'attributes') || {});
      set(action, 'attributes', attributes);
    },


    // CPs
    options: computed('definition.valuesSortedAphabetically', function () {
      var originalData = this.get('definition.valuesSortedAphabetically');
      var sortedOptions = originalData.map(function (e) {
        return { id: e.id, text: e.value };
      });
      var cta = this.get('i18n').t('generic.select.select_or_type_to_search');
      return [{ text: cta, disabled: true }].concat(_toConsumableArray(sortedOptions));
    }),

    selected: computed('automationAction.value', 'options', {
      get: function get() {
        var id = this.get('automationAction.value');
        if (!id) {
          return null;
        }
        var name = this.get('automationAction.name');
        if (name === 'notificationteam') {
          return this.get('options').findBy('id', id);
        } else {
          return this.get('options').findBy('id', id) || this.get('store').find('user', id).then(normalizeUser);
        }
      },
      set: function set(_, selection) {
        this.set('automationAction.value', selection.id);
        return selection;
      }
    }),

    searchEnabled: equal('definition.inputType', 'NOTIFICATION_USER'),

    // Tasks
    search: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(name) {
      var users, lowercaseTerm, specialResults, searchResults;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              _context.next = 4;
              return this.get('store').query('user', { name: name, in: 'ADMINS,AGENTS,COLLABORATORS' });

            case 4:
              users = _context.sent;
              lowercaseTerm = name.toLowerCase();
              specialResults = this.get('options').filter(function (e) {
                return e.text.toLowerCase().indexOf(lowercaseTerm) > -1;
              });
              searchResults = users.map(normalizeUser);
              return _context.abrupt('return', specialResults.concat(searchResults));

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    // Actions
    actions: {
      updateMetadata: function updateMetadata(propertyName, e) {
        this.set('automationAction.attributes.' + propertyName, e.target.value);
      }
    }
  });
});