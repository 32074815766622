define("frontend-cp/components/ko-admin/page/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "header": "ko-admin_page__header_1mhmh2",
    "header--bar": "ko-admin_page__header--bar_1mhmh2",
    "title": "ko-admin_page__title_1mhmh2",
    "spacer": "ko-admin_page__spacer_1mhmh2",
    "footer": "ko-admin_page__footer_1mhmh2",
    "footer-upgrade": "ko-admin_page__footer-upgrade_1mhmh2",
    "caption": "ko-admin_page__caption_1mhmh2",
    "beta-label": "ko-admin_page__beta-label_1mhmh2"
  };
});