define('frontend-cp/session/admin/automation/macros/edit/controller', ['exports', 'frontend-cp/lib/macro-schema', 'frontend-cp/services/virtual-model', 'frontend-cp/models/macro-wrapper'], function (exports, _macroSchema, _virtualModel, _macroWrapper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    i18n: service(),
    notification: service(),
    parentRootName: 'session.admin.automation.macros.index',
    virtualModel: service(),
    store: service(),

    // Actions
    actions: {
      updated: function updated() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        this.transitionToRoute(this.get('parentRootName'));
      },
      canceled: function canceled() {
        this.transitionToRoute(this.get('parentRootName'));
      }
    },

    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('model'), this.get('editedMacro.macro'), _macroSchema.default);
    },
    initEdits: function initEdits() {
      var macro = this.get('virtualModel').makeSnapshot(this.get('model'), _macroSchema.default);
      var store = this.get('store');
      this.set('editedMacro', _macroWrapper.default.create({ macro: macro, store: store }));
    }
  });
});