define("frontend-cp/components/ko-admin/billing-form/form-input/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "prefixed-input": "ko-admin_billing-form_form-input__prefixed-input_17ctb2",
    "focused": "ko-admin_billing-form_form-input__focused_17ctb2",
    "prefixed-input-prefix": "ko-admin_billing-form_form-input__prefixed-input-prefix_17ctb2",
    "prefixed-input-input": "ko-admin_billing-form_form-input__prefixed-input-input_17ctb2"
  };
});