define('frontend-cp/models/activity', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    activity: _emberData.default.attr('string'),
    actor: _emberDataModelFragments.default.fragment('activity-object'),
    verb: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    actions: _emberData.default.hasMany('action'),
    object: _emberDataModelFragments.default.fragment('activity-object'),
    apiEvent: _emberData.default.belongsTo('event'),
    helpcenterComment: _emberData.default.belongsTo('comment'),
    actorUser: _emberData.default.belongsTo('user'),
    objectActorUser: _emberData.default.belongsTo('user'),
    caseMessage: _emberData.default.belongsTo('case-message'),
    rating: _emberData.default.belongsTo('rating'),
    objectActor: _emberDataModelFragments.default.fragment('activity-object'),
    location: _emberDataModelFragments.default.fragment('activity-location'),
    place: _emberDataModelFragments.default.fragment('activity-object'),
    target: _emberDataModelFragments.default.fragment('activity-object'),
    result: _emberDataModelFragments.default.fragment('activity-object'),
    note: _emberData.default.belongsTo('note'),
    inReplyTo: _emberDataModelFragments.default.fragment('activity-object'),
    participant: _emberDataModelFragments.default.fragment('activity-object'),
    portal: _emberData.default.attr('string'),
    weight: _emberData.default.attr('number'),
    ipAddress: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    case: _emberData.default.belongsTo('case', { async: true }),
    isActivity: true
  });
});