define('frontend-cp/components/ko-insights/section/agents-teams/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    permissions: service(),
    tagName: '',

    onActorChange: function onActorChange() {},
    onDateRangeChange: function onDateRangeChange() {},

    // Attrs
    section: null,
    startAt: null,
    endAt: null,
    interval: null,
    actorId: null,
    slaId: null,
    actorQueryTerm: '',
    slaQueryTerm: '',
    casesCompletion: null,
    slaTarget: null,
    slaPerformance: null,
    csat: null,

    actorLoaderComponent: computed('section', function () {
      if (this.get('section') === 'agent') {
        return 'ko-insights/loader/actor/agent';
      }

      return 'ko-insights/loader/actor/team';
    }),

    slaSectionEnabled: computed('permissions', function () {
      return this.get('permissions').has('slas.manage');
    })
  });
});