define('frontend-cp/session/admin/channels/email/settings/controller', ['exports', 'frontend-cp/session/admin/channels/email/settings/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    // Attributes
    settings: null,
    editedSettings: null,

    // Services
    i18n: service(),
    notification: service(),
    settingsService: service('settings'),
    virtualModel: service(),

    // CPs
    tabs: computed(function () {
      return [{
        id: 'organization',
        label: this.get('i18n').t('admin.email.tabs.mailboxes'),
        routeName: 'session.admin.channels.email.index',
        dynamicSegments: []
      }, {
        id: 'user',
        label: this.get('i18n').t('admin.email.tabs.settings'),
        routeName: 'session.admin.channels.email.settings',
        dynamicSegments: []
      }];
    }),

    hasChanges: readOnly('editedSettings.isEdited'),

    schema: computed(function () {
      return this.get('settingsService').generateSchema(_settings.default);
    }),

    domain: computed(function () {
      return window.location.hostname;
    }),

    // Actions
    actions: {
      editBooleanSetting: function editBooleanSetting(settingName, value) {
        this.set('editedSettings.' + settingName + '.value', value ? '1' : '0');
      },
      save: function save() {
        return this.get('virtualModel').save(this.get('settings'), this.get('editedSettings'), this.get('schema'));
      },
      cancel: function cancel() {
        this.transitionToRoute('session.admin.channels.email');
      },
      handleSuccess: function handleSuccess() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.transitionToRoute('session.admin.channels.email');
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedSettings', this.get('settingsService').initEdits(this.get('settings'), this.get('schema')));
    },
    isEdited: function isEdited() {
      return this.get('hasChanges');
    }
  });
});