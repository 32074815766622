define('frontend-cp/components/ko-table/cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    onClick: null,
    minWidth: null,
    maxWidth: null,
    compact: false,
    dasherize: false,

    // CPs
    selectable: readOnly('parentView.selectable'),

    cellStyle: computed('width', function () {
      var maxWidth = this.get('maxWidth');
      var minWidth = this.get('minWidth');
      var styles = minWidth ? 'min-width: ' + minWidth + 'px;' : '';
      styles += maxWidth ? ' max-width: ' + maxWidth + 'px;' : '';
      return htmlSafe(styles);
    }),

    actions: {
      handleClick: function handleClick(event) {
        var onClick = this.get('onClick');
        if (onClick) {
          event.preventDefault();
          event.stopPropagation();
          onClick(event);
        }
      }
    }
  });
});