define('frontend-cp/transitions', ['exports', 'frontend-cp/lib/is-ie'], function (exports, _isIe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    if (!_isIe.default) {
      this.transition(this.hasClass('leftHandSideBarAnimation'), this.toValue(true), this.use('toLeft', { duration: 250 }), this.reverse('toRight', { duration: 250 }));
      this.transition(this.hasClass('animate-widget'), this.toValue(false), this.use('fade', { duration: 100 }));
    }
  };
});