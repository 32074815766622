define("frontend-cp/components/ko-power-select-triggers/chevron/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-power-select-triggers_chevron__trigger_71zyep",
    "chevron": "ko-power-select-triggers_chevron__chevron_71zyep"
  };
});