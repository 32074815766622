define('frontend-cp/components/ko-admin/add-new-card/footer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    iframeLoaded: false
  });
});