define('frontend-cp/components/ko-editable-text/component', ['exports', 'frontend-cp/lib/keycodes'], function (exports, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var run = Ember.run;
  var computed = Ember.computed;
  var jQuery = Ember.$;
  var guidFor = Ember.guidFor;
  exports.default = Component.extend({
    tagName: 'span',

    //Params:
    onValueChange: null,
    placeholder: null,
    value: null,
    isErrored: false,
    isKREEdited: false,
    isDisabled: false,
    size: 'medium', // medium | large
    showPencilOnOver: false,
    onKeyDown: function onKeyDown() {},

    isEditing: false,
    isEdited: false,
    qaClass: null,
    labelComponent: null,

    // CPs
    isEmpty: computed.not('value'),
    displayText: computed.or('value', 'placeholder'),
    valueToSave: computed('value', {
      get: function get() {
        return this.get('value');
      },
      set: function set(_, v) {
        return v;
      }
    }),
    iconSize: computed('size', function () {
      return this.get('size') === 'large' ? 16 : 12;
    }),

    inputId: computed(function () {
      return guidFor(this) + '-id';
    }),

    // Actions
    actions: {
      edit: function edit() {
        if (!this.get('isDisabled')) {
          this.startEditing();
        }
      },
      handleKeyDown: function handleKeyDown(e) {
        if (e.keyCode === _keycodes.enter) {
          this.stopEditing();
        }
        this.sendAction('onKeyDown', e);
      },
      handleFocusOut: function handleFocusOut() {
        this.stopEditing();
      }
    },

    startEditing: function startEditing() {
      var _this = this;

      this.set('isEditing', true);
      this.set('valueToSave', this.get('value'));
      run.scheduleOnce('afterRender', this, function () {
        jQuery('#' + _this.get('inputId')).focus();
      });
    },
    stopEditing: function stopEditing() {
      this.set('isEditing', false);

      if (this.get('onValueChange')) {
        this.sendAction('onValueChange', this.get('valueToSave'));
      } else {
        this.set('value', this.get('valueToSave'));
      }
    }
  });
});