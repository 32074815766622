define('frontend-cp/components/ko-info-bar/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    id: null,
    qaClass: null,
    isEdited: false,
    isKREEdited: false,
    isActive: false,
    isDisabled: false,
    hasError: false,

    onClick: function onClick() {},
    onMouseDown: function onMouseDown() {}
  });
});