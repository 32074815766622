define('frontend-cp/components/ko-timeline-2/list/item/created-at/email-modal/component', ['exports', 'ember-concurrency', 'frontend-cp/lib/copy-to-clipboard'], function (exports, _emberConcurrency, _copyToClipboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Component.extend({
    tagName: '',

    email: null,
    errorType: null,

    store: service(),
    errorHandler: service(),
    notification: service(),
    i18n: service(),

    onClose: function onClose() {},

    init: function init() {
      this._super.apply(this, arguments);

      this.get('fetchEmailContents').perform();
    },


    fetchEmailContents: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var _this = this;

      var _getProperties, store, model;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _getProperties = this.getProperties('store', 'model'), store = _getProperties.store, model = _getProperties.model;
              _context.next = 3;
              return this.get('errorHandler').disableWhile(function () {
                return store.findRecord('email-original', get(model, 'id')).then(function (email) {
                  _this.set('email', email);
                }, function (_ref) {
                  var errors = _ref.errors;

                  if (errors && errors[0] && errors[0].code === 'RESOURCE_NOT_FOUND') {
                    _this.set('errorType', 404);
                  } else {
                    _this.set('errorType', 500);
                  }
                });
              });

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    actions: {
      copyToClipboard: function copyToClipboard(text) {
        (0, _copyToClipboard.default)(text.replace(/<br \/>/g, ''));

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.copied_to_clipboard'),
          autodismiss: true
        });
      }
    }
  });
});