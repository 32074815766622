define('frontend-cp/models/creditcard', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    cardType: _emberData.default.attr('string'),
    number: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    expiryMonth: _emberData.default.attr('string'),
    expiryYear: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    isDefault: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string')
  });
});