define("frontend-cp/components/ko-timeline-2/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-timeline-2__container_vlsdot",
    "load-link": "ko-timeline-2__load-link_vlsdot",
    "loader": "ko-timeline-2__loader_vlsdot",
    "loader--idle": "ko-timeline-2__loader--idle_vlsdot ko-timeline-2__loader_vlsdot",
    "loader--running": "ko-timeline-2__loader--running_vlsdot ko-timeline-2__loader_vlsdot",
    "previous-activities-container-wrapper": "ko-timeline-2__previous-activities-container-wrapper_vlsdot",
    "fadein-timeline": "ko-timeline-2__fadein-timeline_vlsdot",
    "previous-activities-container": "ko-timeline-2__previous-activities-container_vlsdot",
    "previous-activities": "ko-timeline-2__previous-activities_vlsdot",
    "previous-activities-blob": "ko-timeline-2__previous-activities-blob_vlsdot",
    "previous-activities-arrow": "ko-timeline-2__previous-activities-arrow_vlsdot",
    "jump-bar": "ko-timeline-2__jump-bar_vlsdot"
  };
});