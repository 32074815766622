define('frontend-cp/adapters/atlasai', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType(modelName) {
      return 'atlasai';
    },
    register: function register() {
      return this.ajax('/api/v1/atlasai/register', 'POST');
    },
    deregister: function deregister() {
      return this.ajax('/api/v1/atlasai/deregister', 'POST');
    }
  });
});