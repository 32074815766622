define('frontend-cp/helpers/ko-intl-datetime-format', ['exports', 'moment', 'frontend-cp/utils/moment-to-intl'], function (exports, _moment, _momentToIntl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var service = Ember.inject.service;
  var Helper = Ember.Helper;
  var observer = Ember.observer;
  exports.default = Helper.extend({
    moment: service(),
    date: service(),

    timezoneDidChange: observer('moment.timeZone', function () {
      this.recompute();
    }),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          dateTime = _ref2[0];

      if (!dateTime) {
        return null;
      }

      var date = (0, _moment.default)(dateTime).tz(this.get('moment.timeZone'));

      // timeZone can be null if the browser settings are not usual.
      // in this case, just return the date
      if (!date) {
        return this.get('date').getNewDate(dateTime);
      }

      return (0, _momentToIntl.default)(date);
    }
  });
});