define('frontend-cp/processes/user-new', ['exports', 'frontend-cp/processes/process'], function (exports, _process) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var get = Ember.get;


  var UserNewProcess = _process.default.extend({
    type: 'user-new',

    restoreModel: function restoreModel(json) {
      return EmberObject.create({
        creationTimestamp: json.modelId
      });
    },
    serialize: function serialize() {
      return {
        pid: this.get('pid'),
        type: this.get('type'),
        modelId: this.get('model.creationTimestamp')
      };
    }
  });

  UserNewProcess.reopenClass({
    generatePid: function generatePid(model) {
      var id = get(model, 'creationTimestamp');
      return 'user-new:' + id;
    }
  });

  exports.default = UserNewProcess;
});