define('frontend-cp/components/ko-case-content/cc/above/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    select: null,

    store: service(),

    hasNoResults: computed('select.searchText', 'select.resultsCount', function () {
      var searchText = this.get('select.searchText');
      var resultsCount = this.get('select.resultsCount');

      return searchText && !resultsCount;
    }),

    isMailboxCC: computed('hasNoResults', function () {
      var hasNoResults = this.get('hasNoResults');
      var searchText = this.get('select.searchText');

      if (!hasNoResults) return false;

      var mailboxAddresses = this.get('store').peekAll('channel').filterBy('isChannelTypeMailbox').getEach('handle');
      return mailboxAddresses.includes(searchText);
    })
  });
});