define("frontend-cp/components/ko-feedback/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-feedback__container_1aaqo5",
    "metric": "ko-feedback__metric_1aaqo5",
    "caption": "ko-feedback__caption_1aaqo5",
    "no-feedback": "ko-feedback__no-feedback_1aaqo5",
    "list": "ko-feedback__list_1aaqo5",
    "item": "ko-feedback__item_1aaqo5",
    "heading": "ko-feedback__heading_1aaqo5"
  };
});