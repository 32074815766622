define("frontend-cp/components/ko-admin/twitter/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "error": "ko-admin_twitter_index__error_1pkrb5",
    "title": "ko-admin_twitter_index__title_1pkrb5",
    "caption": "ko-admin_twitter_index__caption_1pkrb5"
  };
});