define('frontend-cp/models/mail', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    from: _emberData.default.attr('string'),
    to: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    text: _emberData.default.attr('string'),
    html: _emberData.default.attr('string'),
    isSuspended: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    reason: _emberData.default.attr('string'),
    size: _emberData.default.attr('number'),
    suspensionCode: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date')
  });
});