define('frontend-cp/adapters/engagement', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'engagements';
    },
    reorderEngagements: function reorderEngagements(data) {
      var url = this.namespace + '/' + this.pathForType() + '/reorder';
      return this.ajax(url, 'PUT', { data: data });
    },
    updateStatus: function updateStatus(engagementId, data) {
      var url = this.namespace + '/' + this.pathForType() + '/' + engagementId;
      return this.ajax(url, 'PUT', { data: data });
    }
  });
});