define("frontend-cp/components/ko-admin/identity-verification/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "component-description": "ko-admin_identity-verification_index__component-description_ioroje",
    "token-container": "ko-admin_identity-verification_index__token-container_ioroje",
    "token-input-label": "ko-admin_identity-verification_index__token-input-label_ioroje",
    "token-input": "ko-admin_identity-verification_index__token-input_ioroje",
    "instructions-container": "ko-admin_identity-verification_index__instructions-container_ioroje",
    "internal-separator": "ko-admin_identity-verification_index__internal-separator_ioroje",
    "step": "ko-admin_identity-verification_index__step_ioroje",
    "step-heading": "ko-admin_identity-verification_index__step-heading_ioroje",
    "step-number": "ko-admin_identity-verification_index__step-number_ioroje",
    "step-content": "ko-admin_identity-verification_index__step-content_ioroje",
    "step-description-list": "ko-admin_identity-verification_index__step-description-list_ioroje",
    "step-description-item": "ko-admin_identity-verification_index__step-description-item_ioroje",
    "code-block-container": "ko-admin_identity-verification_index__code-block-container_ioroje",
    "code-block": "ko-admin_identity-verification_index__code-block_ioroje",
    "external-article-wrapper": "ko-admin_identity-verification_index__external-article-wrapper_ioroje",
    "lang-label": "ko-admin_identity-verification_index__lang-label_ioroje"
  };
});