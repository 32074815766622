define("frontend-cp/components/ko-user-avatar/image/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "image": "ko-user-avatar_image__image_kpxzg",
    "sizeNano": "ko-user-avatar_image__sizeNano_kpxzg",
    "sizeMicro": "ko-user-avatar_image__sizeMicro_kpxzg",
    "sizeSmall": "ko-user-avatar_image__sizeSmall_kpxzg",
    "sizeSubmedium": "ko-user-avatar_image__sizeSubmedium_kpxzg",
    "sizeMedium": "ko-user-avatar_image__sizeMedium_kpxzg",
    "sizeLarge": "ko-user-avatar_image__sizeLarge_kpxzg",
    "sizeXLarge": "ko-user-avatar_image__sizeXLarge_kpxzg",
    "loading": "ko-user-avatar_image__loading_kpxzg",
    "round": "ko-user-avatar_image__round_kpxzg"
  };
});