define("frontend-cp/components/ko-admin/staff/list-row/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "username": "ko-admin_staff_list-row__username_1xc1lt",
    "team-list": "ko-admin_staff_list-row__team-list_1xc1lt"
  };
});