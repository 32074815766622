define('frontend-cp/serializers/definition-improved-contract', ['exports', 'frontend-cp/serializers/application', 'npm:lodash'], function (exports, _application, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.normalizeToImprovedContract = normalizeToImprovedContract;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _application.default.extend({
    primaryKey: 'field',

    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      resourceHash.values = _npmLodash.default.map(resourceHash.values, function (val, id) {
        return {
          value: id,
          string: val
        };
      });
      return this._super.apply(this, arguments);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.data || !Array.isArray(payload.data)) {
        return this._super.apply(this, arguments);
      }

      return normalizeToImprovedContract(payload);

      //debugger;
      //return this._super(store, primaryModelClass, modifiedPayload, id, requestType);
    }
  });
  function normalizeToImprovedContract(payload) {
    var data = [];
    var operators = [];
    var inputs = [];

    payload.data.forEach(function (record, i) {
      if (!data.mapBy('attributes.label').includes(record.label)) {
        //build a new data object with operators as relationships
        data.push({
          id: record.field,
          type: 'definition_improved_contract',
          attributes: {
            label: record.label
          },
          relationships: {
            operators: {
              data: record.operators.map(function (operator) {
                return {
                  id: buildUniqueFieldId(record, operator),
                  type: 'definition_improved_contract_operator'
                };
              })
            }
          }
        });
      } else {
        //this is a field that needs to be coalesced
        //find the existing entry in the new data object and append the operators as relationships to the existing operator collection
        var existingField = data.find(function (obj) {
          return obj.attributes.label === record.label;
        });

        existingField.relationships.operators.data = [].concat(_toConsumableArray(existingField.relationships.operators.data), _toConsumableArray(record.operators.map(function (operator) {
          return {
            id: buildUniqueFieldId(record, operator),
            type: 'definition_improved_contract_operator'
          };
        })));
      }

      record.operators.forEach(function (operator, i) {
        //build operator includes
        operators.push({
          id: buildUniqueFieldId(record, operator),
          type: 'definition_improved_contract_operator',
          attributes: {
            label: operator,
            originalFieldName: record.field
          },
          relationships: {
            input: {
              data: {
                id: record.field,
                type: 'definition_improved_contract_input'
              }

            }
          }
        });

        if (!inputs.mapBy('id').includes(record.field)) {
          //build input includes
          inputs.push({
            id: record.field,
            type: 'definition_improved_contract_input',
            attributes: {
              inputType: record.input_type,
              values: convertKeyValueObjectToArrayOfKeyValueObjects(record.values)
            }
          });
        }
      });
    });

    return {
      data: data,
      included: [].concat(operators, inputs)
    };
  }

  function buildUniqueFieldId(record, operator) {
    return record.label.toLowerCase().replace(/\s+/g, '_') + '_' + operator;
  }

  function convertKeyValueObjectToArrayOfKeyValueObjects(obj) {
    if (!obj) {
      return;
    }

    var keys = Object.keys(obj);
    var values = Object.values(obj);
    var keyValueObjects = [];

    keys.forEach(function (key, i) {
      var obj = {};
      obj[key] = values[i];

      keyValueObjects.push(obj);
    });

    return keyValueObjects;
  }
});