define('frontend-cp/components/ko-tooltip/component', ['exports', 'ember-cli-tooltipster/components/tool-tipster', 'frontend-cp/components/ko-tooltip/styles'], function (exports, _toolTipster, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _toolTipster.default.extend({

    classNameBindings: ['qaCls'],

    // Attributes
    title: '',
    content: '',
    delay: 300,
    qaCls: null,
    side: 'bottom',
    disable: false,
    arrow: false,
    animationDuration: 400,

    triggerEvent: 'hover',

    theme: 'tooltipster-default ' + _styles.default.tooltip,
    animation: 'grow',

    // CPs
    contentAsHTML: computed('title', 'content', function () {
      var content = this.get('content') || this.get('title');
      return content ? Boolean(content.toHTML) : false;
    })
  });
});