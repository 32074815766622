define('frontend-cp/services/error-handler/license-expired-strategy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({
    notification: service('notification'),
    i18n: service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.records = [];
    },
    accept: function accept(record) {
      this.records.push(record);
    },
    process: function process() {
      var i18n = this.get('i18n');
      var router = getOwner(this).lookup('router:main');
      var recordsCount = this.records.length;
      var extendLicenseRoute = 'session.admin.account.plans';

      if (recordsCount) {
        this.get('notification').add({
          type: 'error',
          title: i18n.t('generic.license_expired'),
          autodismiss: false,
          dismissable: true,
          href: Reflect.apply(router.generate, router, [extendLicenseRoute]),
          hrefTarget: '_self',
          hrefText: i18n.t('generic.license_expired_href'),
          onClose: function onClose() {
            router.transitionTo(extendLicenseRoute);
          }
        });

        this.records = [];
      }

      return recordsCount;
    }
  });
});