define('frontend-cp/components/ko-insights/custom-reports/index/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    store: service(),
    reportsService: service('reports'),

    generate: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(report) {
      var id, adapter, response;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              id = report.get('id');
              adapter = getOwner(this).lookup('adapter:application');
              _context.next = 4;
              return adapter.ajax(adapter.namespace + '/reports/' + id + '/generate', 'POST');

            case 4:
              response = _context.sent;

              this.get('store').pushPayload('report-case', response);

            case 6:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    actions: {
      download: function download(report, e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        this.get('reportsService').download(report);
      },
      generate: function generate(report, e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        this.get('generate').perform(report);
      }
    }

  });
});