define('frontend-cp/lib/computed/humanize-seconds', ['exports', 'frontend-cp/lib/humanize-seconds'], function (exports, _humanizeSeconds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (paramName) {
    return computed(paramName, function () {
      var param = this.get(paramName);

      if (!param) {
        return null;
      }

      return (0, _humanizeSeconds.default)(param);
    });
  };

  var computed = Ember.computed;
});