define('frontend-cp/serializers/case-status', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _application.default.extend({
    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      if (resourceHash.case_status_type) {
        resourceHash.status_type = resourceHash.case_status_type;
        Reflect.deleteProperty(resourceHash, 'case_status_type');
      }
      return this._super.apply(this, arguments);
    },


    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;
      json[key] = get(snapshot, key).map(function (obj) {
        return obj.serialize({ includeId: false });
      });

      if (json[key].length === 0) {
        Reflect.deleteProperty(json, key);
      }

      return json;
    }
  });
});