define("frontend-cp/components/ko-toast/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "toast": "ko-toast__toast_kp2yt0",
    "container": "ko-toast__container_kp2yt0",
    "dismissable": "ko-toast__dismissable_kp2yt0",
    "multiline": "ko-toast__multiline_kp2yt0",
    "error": "ko-toast__error_kp2yt0",
    "warning": "ko-toast__warning_kp2yt0",
    "info": "ko-toast__info_kp2yt0",
    "success": "ko-toast__success_kp2yt0",
    "addModifier": "ko-toast__addModifier_kp2yt0",
    "addModifierActive": "ko-toast__addModifierActive_kp2yt0",
    "bounce": "ko-toast__bounce_kp2yt0",
    "removeModifier": "ko-toast__removeModifier_kp2yt0",
    "removeModifierActive": "ko-toast__removeModifierActive_kp2yt0",
    "close": "ko-toast__close_kp2yt0",
    "icon": "ko-toast__icon_kp2yt0",
    "title": "ko-toast__title_kp2yt0",
    "title-text": "ko-toast__title-text_kp2yt0",
    "href": "ko-toast__href_kp2yt0",
    "link": "ko-toast__link_kp2yt0",
    "body": "ko-toast__body_kp2yt0"
  };
});