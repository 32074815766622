define('frontend-cp/services/article-events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    triggerArticleCreated: null,
    triggerArticleUpdated: null,
    triggerArticleDeleted: null,

    setArticleCreatedCallback: function setArticleCreatedCallback(callback) {
      this.set('triggerArticleCreated', callback);
    },
    setArticleUpdatedCallback: function setArticleUpdatedCallback(callback) {
      this.set('triggerArticleUpdated', callback);
    },
    setArticleDeletedCallback: function setArticleDeletedCallback(callback) {
      this.set('triggerArticleDeleted', callback);
    },
    articleCreated: function articleCreated() {
      if (this.get('triggerArticleCreated')) {
        this.get('triggerArticleCreated')();
      }
    },
    articleUpdated: function articleUpdated() {
      if (this.get('triggerArticleUpdated')) {
        this.get('triggerArticleUpdated')();
      }
    },
    articleDeleted: function articleDeleted() {
      if (this.get('triggerArticleDeleted')) {
        this.get('triggerArticleDeleted')();
      }
    }
  });
});