define("frontend-cp/components/ko-admin/add-new-card/footer/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "form-footer": "ko-admin_add-new-card_footer__form-footer_1wcn5b",
    "cancel": "ko-admin_add-new-card_footer__cancel_1wcn5b"
  };
});