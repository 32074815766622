define('frontend-cp/session/admin/customizations/case-fields/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    i18n: service(),

    tabs: computed(function () {
      return [{
        id: 'conversation',
        label: this.get('i18n').t('admin.casefields.title'),
        routeName: 'session.admin.customizations.case-fields',
        dynamicSegments: [],
        queryParams: null
      }, {
        id: 'organization',
        label: this.get('i18n').t('admin.organizationfields.title'),
        routeName: 'session.admin.customizations.organization-fields',
        dynamicSegments: [],
        queryParams: null
      }, {
        id: 'user',
        label: this.get('i18n').t('admin.userfields.title'),
        routeName: 'session.admin.customizations.user-fields',
        dynamicSegments: [],
        queryParams: null
      }];
    }),

    actions: {
      transitionToNewCaseFieldRoute: function transitionToNewCaseFieldRoute() {
        this.transitionToRoute('session.admin.customizations.case-fields.select-type');
      }
    }
  });
});