define('frontend-cp/components/side-conversations-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;


  var VIEW_TYPES = Object.freeze({
    CONVERSATIONS_LIST: 'CONVERSATIONS_LIST',
    NEW_CONVERSATION: 'NEW_CONVERSATION',
    INDIVIDUAL_CONVERSATION: 'INDIVIDUAL_CONVERSATION'
  });

  exports.default = Component.extend({
    tagName: '',

    // services
    i18n: service(),

    isVisible: false,
    isAddingNewConversation: false,
    currentConversation: null,
    shouldRefetchConversations: false,

    currentView: computed('currentConversation.id', function () {
      return this.get('currentConversation.id') ? VIEW_TYPES.INDIVIDUAL_CONVERSATION : VIEW_TYPES.CONVERSATIONS_LIST;
    }),

    actions: {
      refetchConversations: function refetchConversations(value) {
        this.set('shouldRefetchConversations', value);
      },
      openConversation: function openConversation(conversation) {
        this.send('setView', VIEW_TYPES.INDIVIDUAL_CONVERSATION);
        this.set('currentConversation', conversation);
      },
      openPanel: function openPanel() {
        this.set('isVisible', true);
      },
      closePanel: function closePanel() {
        this.set('isVisible', false);
      },
      setView: function setView(viewType) {
        if (Object.values(VIEW_TYPES).includes(viewType)) {
          this.set('currentView', viewType);
          if (viewType !== VIEW_TYPES.INDIVIDUAL_CONVERSATION) {
            this.set('currentConversation', null);
          }
        }
      }
    }
  });
});