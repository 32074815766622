define('frontend-cp/components/ko-timeline-2/list/item/at-mention/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    subjectId: null,
    subjectType: null,
    placeholderText: null,

    _subject: null,

    session: service(),
    tabStore: service(),
    store: service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var _getProperties = this.getProperties('subjectId', 'subjectType'),
          subjectId = _getProperties.subjectId,
          subjectType = _getProperties.subjectType;

      if (subjectId && subjectType) {
        this.get('store').findRecord(subjectType, subjectId).then(function (subject) {
          return _this.set('_subject', subject);
        });
      }
    },


    isCurrentUser: computed('subjectId', 'session.user', function () {
      return this.get('session.user.id') === this.get('subjectId');
    }),

    text: computed('subjectType', '_userText', function () {
      var type = this.get('subjectType');

      switch (type) {
        case 'user':
          {
            return this.get('_userText');
          }

        default:
          {
            return 'UNKNOWN';
          }
      }
    }),

    _userText: computed('_subject.fullName', 'placeholderText', function () {
      var name = this.get('_subject.fullName');
      var placeholder = this.get('placeholderText');

      if (name) {
        return '@' + name;
      }

      return placeholder;
    }),

    actions: {
      openMention: function openMention(event) {
        event.preventDefault();

        var id = this.get('subjectId');
        var tabStore = this.get('tabStore');
        var hasModifier = event.metaKey || event.ctrlKey || event.shiftKey;

        if (hasModifier) {
          this.get('store').findRecord('user', id).then(function (user) {
            tabStore.createTabNextToActiveTab('session.agent.users.user', user);
          });
        } else {
          tabStore.transitionAndInsertTabNextToActiveTab('session.agent.users.user.index', [id]);
        }
      }
    }
  });
});