define("frontend-cp/session/agent/knowledgebase/article-view/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "loading-overlay": "session_agent_knowledgebase_article-view__loading-overlay_14o1xp",
    "loading-message": "session_agent_knowledgebase_article-view__loading-message_14o1xp",
    "article-header": "session_agent_knowledgebase_article-view__article-header_14o1xp",
    "article-title": "session_agent_knowledgebase_article-view__article-title_14o1xp",
    "article-actions": "session_agent_knowledgebase_article-view__article-actions_14o1xp",
    "edit-button": "session_agent_knowledgebase_article-view__edit-button_14o1xp",
    "back-button": "session_agent_knowledgebase_article-view__back-button_14o1xp",
    "article-meta": "session_agent_knowledgebase_article-view__article-meta_14o1xp",
    "meta-left": "session_agent_knowledgebase_article-view__meta-left_14o1xp",
    "last-modified": "session_agent_knowledgebase_article-view__last-modified_14o1xp",
    "article-content-section": "session_agent_knowledgebase_article-view__article-content-section_14o1xp",
    "article-content": "session_agent_knowledgebase_article-view__article-content_14o1xp",
    "attachments-section": "session_agent_knowledgebase_article-view__attachments-section_14o1xp",
    "attachment-item": "session_agent_knowledgebase_article-view__attachment-item_14o1xp",
    "attachment-name": "session_agent_knowledgebase_article-view__attachment-name_14o1xp",
    "attachment-size": "session_agent_knowledgebase_article-view__attachment-size_14o1xp",
    "attachment-link": "session_agent_knowledgebase_article-view__attachment-link_14o1xp",
    "edit-pane-overlay": "session_agent_knowledgebase_article-view__edit-pane-overlay_14o1xp",
    "edit-pane": "session_agent_knowledgebase_article-view__edit-pane_14o1xp",
    "edit-pane-open": "session_agent_knowledgebase_article-view__edit-pane-open_14o1xp",
    "tags-section": "session_agent_knowledgebase_article-view__tags-section_14o1xp",
    "tag-item": "session_agent_knowledgebase_article-view__tag-item_14o1xp",
    "edit-pane-content": "session_agent_knowledgebase_article-view__edit-pane-content_14o1xp"
  };
});