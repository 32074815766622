define('frontend-cp/serializers/atlasai', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            // The /atlasai endpoint returns resource type=setting. Overriding it to "atlasai" so that it uses the correct models and serializers.
            payload.data.forEach(function (item) {
                item.resource_type = 'atlasai';
            });
            payload.resource = 'atlasai';
            return this._super.apply(this, arguments);
        }
    });
});