define('frontend-cp/session/admin/automation/macros/new/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend((0, _route.default)('model'), {
    store: service(),

    model: function model() {
      return this.get('store').createRecord('macro');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('referenceData', this.controllerFor('session.admin.automation.macros').get('referenceData'));
      controller.initEdits();
    }
  });
});