define('frontend-cp/adapters/engagement-action-definition', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      if (query.type === 'engagement') {
        Reflect.deleteProperty(query, 'type');
        return '/api/v1/engagements/actions/definitions';
      }
    }
  });
});