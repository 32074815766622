define("frontend-cp/components/ko-admin/apps/manage/details/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "app": "ko-admin_apps_manage_details__app_1udxgu",
    "sidebar": "ko-admin_apps_manage_details__sidebar_1udxgu",
    "main": "ko-admin_apps_manage_details__main_1udxgu",
    "image": "ko-admin_apps_manage_details__image_1udxgu",
    "description": "ko-admin_apps_manage_details__description_1udxgu",
    "buttons": "ko-admin_apps_manage_details__buttons_1udxgu"
  };
});