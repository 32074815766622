define("frontend-cp/components/ko-text-editor/file-upload/upload-item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-text-editor_file-upload_upload-item__item_1ub1pn",
    "action": "ko-text-editor_file-upload_upload-item__action_1ub1pn",
    "name": "ko-text-editor_file-upload_upload-item__name_1ub1pn",
    "name--is-progressing": "ko-text-editor_file-upload_upload-item__name--is-progressing_1ub1pn",
    "size": "ko-text-editor_file-upload_upload-item__size_1ub1pn",
    "error": "ko-text-editor_file-upload_upload-item__error_1ub1pn",
    "percentage": "ko-text-editor_file-upload_upload-item__percentage_1ub1pn"
  };
});