define("frontend-cp/components/ko-timeline-2/list/post/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "post": "ko-timeline-2_list_post__post_1nm4l4",
    "note": "ko-timeline-2_list_post__note_1nm4l4",
    "item": "ko-timeline-2_list_post__item_1nm4l4",
    "located": "ko-timeline-2_list_post__located_1nm4l4",
    "large": "ko-timeline-2_list_post__large_1nm4l4",
    "standard": "ko-timeline-2_list_post__standard_1nm4l4",
    "new-messages-separator": "ko-timeline-2_list_post__new-messages-separator_1nm4l4",
    "new-messages-separator__text": "ko-timeline-2_list_post__new-messages-separator__text_1nm4l4",
    "external-url": "ko-timeline-2_list_post__external-url_1nm4l4",
    "event-url-container": "ko-timeline-2_list_post__event-url-container_1nm4l4",
    "event-url": "ko-timeline-2_list_post__event-url_1nm4l4"
  };
});