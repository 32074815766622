define('frontend-cp/services/date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    getNewDate: function getNewDate(dateTime) {
      return new Date(dateTime);
    },
    getCurrentDate: function getCurrentDate() {
      return new Date();
    }
  });
});