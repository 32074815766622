define('frontend-cp/models/product', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var not = Ember.computed.not;
  exports.default = _model.default.extend({
    family: (0, _attr.default)('string'),
    features: (0, _attr.default)(),
    name: (0, _attr.default)('string'),
    plan: (0, _relationships.belongsTo)(),
    productType: (0, _attr.default)('string'),
    rateplans: (0, _relationships.hasMany)('product-rateplan'),

    isLatest: computed('family', 'productType', function () {
      var family = this.get('family');
      var productType = this.get('productType');

      return family === productType;
    }),

    isCurrent: alias('isLatest'),
    isLegacy: not('isLatest')
  });
});