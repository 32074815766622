define('frontend-cp/components/ko-identities/create/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    disabled: false,
    onAddEmail: function onAddEmail() {},
    onAddPhone: function onAddPhone() {},
    onAddTwitter: function onAddTwitter() {},
    qaClass: null,

    // Services
    i18n: service(),

    // CPs
    options: computed(function () {
      var i18n = this.get('i18n');
      return [{ label: i18n.t('generic.identities.add_email_identity'), id: 'add_email' }, { label: i18n.t('generic.identities.add_phone_identity'), id: 'add_phone' }, { label: i18n.t('generic.identities.add_twitter_identity'), id: 'add_twitter' }];
    }),

    actions: {
      selectItem: function selectItem(item) {
        switch (item.id) {
          case 'add_email':
            this.get('onAddEmail')();
            break;

          case 'add_phone':
            this.get('onAddPhone')();
            break;

          case 'add_twitter':
            this.get('onAddTwitter')();
            break;
        }
      }
    }
  });
});