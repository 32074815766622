define('frontend-cp/session/admin/manage/time-tracking/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.get('store').findAll('setting', { reload: true }).then(function (settings) {
        return settings.findBy('name', 'timetracking');
      }).then(function (setting) {
        return setting && setting.get('toBoolean');
      });
    }
  });
});