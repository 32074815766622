define("frontend-cp/components/ko-timeline-2/list/days/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "day-separator": "ko-timeline-2_list_days__day-separator_1bbqo9",
    "day-separator__text": "ko-timeline-2_list_days__day-separator__text_1bbqo9",
    "day-separator__relative-term": "ko-timeline-2_list_days__day-separator__relative-term_1bbqo9",
    "day-separator__date": "ko-timeline-2_list_days__day-separator__date_1bbqo9"
  };
});