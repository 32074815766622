define('frontend-cp/lib/macro-schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _virtualModel.model)('macro', {
    id: (0, _virtualModel.attr)(),
    title: (0, _virtualModel.attr)(),
    visibilityType: (0, _virtualModel.attr)(),
    replyType: (0, _virtualModel.attr)(),
    replyContents: (0, _virtualModel.attr)(),
    assigneeType: (0, _virtualModel.attr)(),
    priorityAction: (0, _virtualModel.attr)(),
    addTags: (0, _virtualModel.list)(),
    removeTags: (0, _virtualModel.list)(),

    visibleToTeam: (0, _virtualModel.attr)(),
    assignedTeam: (0, _virtualModel.attr)(),
    assignedAgent: (0, _virtualModel.attr)(),
    priority: (0, _virtualModel.attr)(),
    status: (0, _virtualModel.attr)(),
    agent: (0, _virtualModel.attr)(),
    caseType: (0, _virtualModel.attr)(),

    isEnabled: (0, _virtualModel.attr)(),
    createdAt: (0, _virtualModel.attr)(),
    updatedAt: (0, _virtualModel.attr)()
  });
});