define("frontend-cp/components/ko-case-content/info-block/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-case-content_info-block__container_1wottp",
    "info-card": "ko-case-content_info-block__info-card_1wottp"
  };
});