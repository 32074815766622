define('frontend-cp/session/agent/cases/new/user/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Route.extend({
    tabStore: service(),
    store: service(),
    timelineService: service('timeline'),

    model: function model() {
      var caseModel = this.modelFor('session.agent.cases.new');
      var timelineService = this.get('timelineService');
      var requester = void 0;
      if (caseModel.get('requester.id')) {
        requester = caseModel.get('requester');
      } else if (this.get('tabStore.activeTab.state.case.requesterId')) {
        var id = this.get('tabStore.activeTab.state.case.requesterId');
        requester = this.get('store').findRecord('user', id).then(function (requester) {
          return caseModel.set('requester', requester);
        });
      }

      return RSVP.hash({
        case: caseModel,
        user: requester,
        caseFields: this.get('store').findAll('case-field')
      }).then(function (data) {
        data.timeline = timelineService.timelineForCaseUser(data.case, data.user);
        return data;
      });
    },
    afterModel: function afterModel(_ref) {
      var user = _ref.user;

      if (user) {
        return user.get('tags');
      }
    },
    setupController: function setupController(controller, models) {
      controller.setProperties(models);
      var parentController = this.controllerFor('session.agent.cases.new');
      var tab = parentController.get('tab');
      var UserStateManager = getOwner(this).factoryFor('state-manager:user');
      var state = UserStateManager.create({ user: models.user, tab: tab });
      controller.set('state', state);
    }
  });
});