define('frontend-cp/helpers/ko-linkify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.linkify = linkify;
  exports.stripDomainFromLinkHrefs = stripDomainFromLinkHrefs;
  exports.isInternalURL = isInternalURL;
  exports.stripDomain = stripDomain;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var typeOf = Ember.typeOf;
  var helper = Ember.Helper.helper;
  var htmlSafe = Ember.String.htmlSafe;


  var ALLOWED_ATTRIBUTE_NAMES = ['rel', 'class'];

  var urlRegex = function urlRegex() {
    return (/(["'])?(?:(?:(?:(?:https?|ftp\w):)?\/\/)|(?:www.))(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:1\d\d|2[0-4]\d|25[0-4]|[1-9]\d?))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s\]]*)?\1(?=$|\s|])/ig
    );
  };

  function linkify(params, options) {
    var textToLinkify = Ember.Handlebars.Utils.escapeExpression(params[0]);
    var sharedAttributes = opts2attrs(options);

    textToLinkify = textToLinkify.replace(urlRegex(), function (s) {
      var url = void 0;
      var displayText = s.trim();
      var matchedNewLines = s.match(/\n/g) || [];

      if (s.trim().match(/^www\./ig)) {
        url = 'http://' + s.trim();
      } else {
        url = s.trim();
      }

      var href = isInternalURL(url) ? stripDomain(url) : url;
      var internalAttributes = isInternalURL(url) ? [] : ['target="_blank"', 'rel="noreferrer noopener"'];
      var attributes = [].concat(internalAttributes, _toConsumableArray(sharedAttributes)).join(' ');
      var link = '<a href="' + href + '" ' + attributes + '>' + displayText + '</a>';
      return link + (matchedNewLines.length ? matchedNewLines[0] : '');
    });

    return htmlSafe(textToLinkify);
  }

  exports.default = helper(linkify);
  function stripDomainFromLinkHrefs(content) {
    var domainRegex = new RegExp('href=[\'"]https?://' + window.location.host + '/(agent|admin)([^\'"]*)[\'"]', 'gi');
    return content.replace(domainRegex, 'href="/$1$2"');
  }

  function isInternalURL(url) {
    var agentPanel = window.location.origin + '/agent';
    var adminPanel = window.location.origin + '/admin';
    return url.indexOf(agentPanel) === 0 || url.indexOf(adminPanel) === 0;
  }

  function stripDomain(url) {
    return url.replace(/^.*\/\/[^/]+/, '');
  }

  function opts2attrs(options) {
    var stringOfAttributes = [''];

    if (typeOf(options) === 'object') {
      for (var i = 0; i < ALLOWED_ATTRIBUTE_NAMES.length; i++) {
        var attributeName = ALLOWED_ATTRIBUTE_NAMES[i];
        if (attributeName in options) {
          stringOfAttributes.push(attributeName + '="' + options[attributeName] + '"');
        }
      }
    }

    return stringOfAttributes.join(' ');
  }
});