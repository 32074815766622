define('frontend-cp/services/session', ['exports', 'ember-service/inject', 'frontend-cp/config/environment', 'frontend-cp/utils/bugsnag', 'moment', 'frontend-cp/utils/hash-params'], function (exports, _inject, _environment, _bugsnag, _moment, _hashParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.secureCookies = exports.sessionIdCookieDomain = exports.rememberMeCookieName = exports.sessionIdCookieName = undefined;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var RSVP = Ember.RSVP;
  var getOwner = Ember.getOwner;
  var sessionIdCookieName = exports.sessionIdCookieName = _environment.default.sessionIdCookieName;
  var rememberMeCookieName = exports.rememberMeCookieName = _environment.default.rememberMeCookieName;
  var domainParts = window.location.hostname.split('.');
  // Top-level domains, such as novo or localhost, won't work, so we need to set this to the empty string
  var sessionIdCookieDomain = exports.sessionIdCookieDomain = domainParts.length > 1 ? '.' + domainParts.join('.') : '';

  // Upon instance creation, a session cookie is be set on kayako.com, and the user
  // gets redirected to the domain of the new instance. We should clean this cookie as
  // soon as the user logs in.
  var kayakoDomain = domainParts.length > 2 ? '.' + domainParts.slice(1).join('.') : null;

  // secure cookies don't work in test because they are run over http not https
  var secureCookies = exports.secureCookies = _environment.default.environment !== 'test';

  // Far future date to use for remember me cookies so they don't expire
  var REMEMBER_ME_EXPIRY_DATE = (0, _moment.default)().add(10, 'years').toDate();

  // Set impersonation token token received from kayako website
  var IMPERSONATION_TOKEN = (0, _hashParams.getImpersonationHash)(window.location.hash);
  var WINDOW_HASH = (0, _hashParams.removeImpersonationHash)(window.location.hash);

  if (IMPERSONATION_TOKEN) {
    history.replaceState(undefined, undefined, '' + window.location.pathname + window.location.search + WINDOW_HASH);
  }

  exports.default = Service.extend({
    launchDarkly: (0, _inject.default)(),


    /**
     * Property for use in computed properties and observers
     * Ember automatically converts this to an ember array.
     * @type {Array}
     */
    session: null,
    user: null,
    email: null,
    password: null,
    rememberMe: false,
    refreshSession: false,
    otp: null,
    authToken: null,

    permissions: [],

    planService: service('plan'),
    localStore: service(),
    notification: service(),
    i18n: service(),
    store: service(),
    locale: service(),
    moment: service(),
    cookies: service(),
    impersonationToken: IMPERSONATION_TOKEN,

    // CPs
    sessionId: computed({
      get: function get() {
        return this._getSessionIdCookie();
      },
      set: function set(_, id) {
        if (id) {
          this._setSessionIdCookie(id);
        } else {
          this._removeSessionIdCookie();
        }
        return id;
      }
    }).volatile(),

    rememberMeToken: computed({
      get: function get() {
        return this._getRememberMeCookie();
      },
      set: function set(_, token) {
        if (token) {
          this._setRememberMeCookie(token);
        } else {
          this._removeRememberMeCookie();
        }
        return token;
      }
    }).volatile(),

    csrfToken: computed({
      get: function get() {
        return this.get('localStore').getItem(_environment.default.localStore.defaultNamespace, 'csrf', { persist: true });
      },
      set: function set(_, token) {
        if (token) {
          this.get('localStore').setItem(_environment.default.localStore.defaultNamespace, 'csrf', token, { persist: true });
        } else {
          this.get('localStore').removeItem(_environment.default.localStore.defaultNamespace, 'csrf', { persist: true });
        }
        return token;
      }
    }).volatile(),

    // Methods
    clear: function clear() {
      var launchDarkly = this.get('launchDarkly');
      var parameters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (launchDarkly.get('release-remember-me')) {
        parameters = Object.assign({
          sessionId: true,
          session: true,
          csrfToken: true,
          rememberMeToken: false,
          localStore: true
        }, parameters);

        var updatedProperties = {};

        if (parameters.sessionId) {
          updatedProperties.sessionId = null;
        }
        if (parameters.session) {
          updatedProperties.session = null;
        }
        if (parameters.csrfToken) {
          updatedProperties.csrfToken = null;
        }
        if (parameters.rememberMeToken) {
          updatedProperties.rememberMeToken = null;
        }

        this.setProperties(updatedProperties);

        if (parameters.localStore) {
          this.get('localStore').clearAllByNamespace(_environment.default.localStore.defaultNamespace);
        }
      } else {
        this.setProperties({
          sessionId: null,
          session: null,
          csrfToken: null
        });

        this.get('localStore').clearAllByNamespace(_environment.default.localStore.defaultNamespace);
      }
    },
    _getSessionWrapper: function _getSessionWrapper() {
      var _this = this;

      // sessionId saved in local storage
      return this._getSession().catch(function (e) {
        _this.clear();

        if (console && console.error) {
          console.error(e);
        }

        throw e;
      });
    },
    impersonationLogin: function impersonationLogin() {
      var _this2 = this;

      return this._getSession().catch(function (e) {
        _this2.clear();
        _this2.set('impersonationToken', 'error');

        if (console && console.error) {
          console.error(e);
        }

        throw e;
      });
    },
    userPassLogin: function userPassLogin() {
      if (!this.get('sessionId')) {
        this.clear();
        return RSVP.reject(new Error('No session ID'));
      }

      if (!this.get('csrfToken')) {
        this.clear();
        return RSVP.reject(new Error('No CSRF Token'));
      }

      return this._getSessionWrapper();
    },
    rememberMeLogin: function rememberMeLogin() {
      return this._getSessionWrapper();
    },
    getSession: function getSession() {
      var launchDarkly = this.get('launchDarkly');

      var session = this.get('session');

      if (session) {
        return RSVP.resolve(session);
      }

      if (this.get('impersonationToken') !== '') {
        return this.impersonationLogin();
      }

      if (launchDarkly.get('release-remember-me')) {
        var rememberMeToken = this.get('rememberMeToken');
        if (rememberMeToken) {
          return this.rememberMeLogin();
        }
      }

      return this.userPassLogin();
    },
    _getSessionIdCookie: function _getSessionIdCookie() {
      return this.get('cookies').read(sessionIdCookieName);
    },
    _setSessionIdCookie: function _setSessionIdCookie(sessionId) {
      this.get('cookies').write(sessionIdCookieName, sessionId, {
        domain: sessionIdCookieDomain,
        path: '/',
        secure: secureCookies
      });
    },
    _removeSessionIdCookie: function _removeSessionIdCookie() {
      this.get('cookies').clear(sessionIdCookieName, {
        domain: sessionIdCookieDomain,
        path: '/',
        secure: secureCookies
      });
    },
    _getRememberMeCookie: function _getRememberMeCookie() {
      return this.get('cookies').read(rememberMeCookieName);
    },
    _setRememberMeCookie: function _setRememberMeCookie(rememberMeToken) {
      this.get('cookies').write(rememberMeCookieName, rememberMeToken, {
        domain: sessionIdCookieDomain,
        path: '/',
        secure: secureCookies,
        expires: REMEMBER_ME_EXPIRY_DATE
      });
    },
    _removeRememberMeCookie: function _removeRememberMeCookie() {
      this.get('cookies').clear(rememberMeCookieName, {
        domain: sessionIdCookieDomain,
        path: '/',
        secure: secureCookies
      });
    },
    _removeKayakoSessionIdCookie: function _removeKayakoSessionIdCookie() {
      if (kayakoDomain) {
        this.get('cookies').clear(sessionIdCookieName, {
          domain: kayakoDomain,
          path: '/',
          secure: secureCookies
        });
      }
    },
    _getSession: function _getSession() {
      var _this3 = this;

      var launchDarkly = this.get('launchDarkly');

      var queryParams = {};

      if (launchDarkly.get('release-remember-me')) {
        if (this.get('rememberMe')) {
          queryParams.remember_me = true;
        }
      }

      return this.get('store').queryRecord('session', queryParams).then(function (session) {
        if (session.get('user.role.roleType') === 'CUSTOMER') {
          return _this3._handleAttemptedCustomerLogin();
        }

        _this3.set('session', session);
        _this3._removeKayakoSessionIdCookie();
        _this3.set('sessionId', session.get('id'));

        if (session.get('rememberMeToken')) {
          _this3.set('rememberMeToken', session.get('rememberMeToken'));
        }
        if (session.get('csrfToken')) {
          _this3.set('csrfToken', session.get('csrfToken'));
        }

        return _this3.get('planService').fetchPlan();
      }).then(function () {
        _this3.get('store').unloadAll('permission');
        return _this3.get('store').queryRecord('user', { id: 'me' }).then(function (me) {
          _this3.set('user', me);
          _this3.get('store').query('channel', { user_id: me.get('id') });
          if (me.get('timeZone')) {
            _this3.get('moment').changeTimeZone(me.get('timeZone'));
          }

          // The /me endpoint returns sideloaded permissions, but not enough for ember-data to
          // establish relationships. We can receive other permissions which do not belong to this user
          // when administrating permissions, so we need to break the mapping to the store
          var permissions = _this3.get('store').peekAll('permission').map(function (permission) {
            return permission;
          });
          _this3.set('permissions', permissions);
        });
      });
    },
    logout: function logout() {
      var _this4 = this;

      var store = this.get('store');

      return store.adapterFor('session').deleteSession().then(function () {
        return _this4._saveUserLocale();
      }).then(function () {
        return _this4.clear({ rememberMeToken: true });
      }).finally(function () {
        return _this4._redirectToLogin();
      });
    },
    _saveUserLocale: function _saveUserLocale() {
      var userLocale = this.get('user.locale');
      if (userLocale) {
        this.get('locale').persistLocaleToLocalStorage(userLocale);
      }

      return RSVP.resolve();
    },
    _redirectToLogin: function _redirectToLogin() {
      var store = this.get('store');
      var redirectToLogin = function redirectToLogin() {
        window.location = '/agent/login';
      };

      this.clear();

      return store.findAll('auth-provider').then(function (providers) {
        var ssoProvider = providers.findBy('scheme', 'SSO');
        if (ssoProvider) {
          window.location = ssoProvider.get('logoutUrl');
        } else {
          redirectToLogin();
        }
      }).catch(function () {
        return redirectToLogin();
      });
    },
    reportSessionExpiry: function reportSessionExpiry(reason) {
      var context = (0, _bugsnag.getMetaData)(null, getOwner(this));
      var setting = this.get('store').peekAll('setting').findBy('key', 'security.agent.session_expiry');
      var expiry = setting && parseInt(setting.get('value')) || 8;

      var lastActivityAt = _moment.default.utc(this.get('session.lastActivityAt'));
      var now = _moment.default.utc();
      var diff = now.diff(lastActivityAt, 'hours', true);

      if (diff < expiry) {
        context.involuntaryLogout = {
          expectedExpiry: expiry,
          actualExpiry: diff,
          isInvoluntary: true
        };
      }

      if (!Ember.testing && window.Bugsnag) {
        window.Bugsnag.notify('SessionExpired', reason, context, 'info');
      }
    },


    /**
     * Sends email and password to backend for authentication.
     * @param  {string} email - email
     * @param  {string} password - password
     * @return {Promise} RSVP.Promise containing session
     */
    requestSession: function requestSession(_ref) {
      var _this5 = this;

      var launchDarkly = this.get('launchDarkly');
      var launchDarkly = this.get('launchDarkly');
      var email = _ref.email,
          password = _ref.password,
          rememberMe = _ref.rememberMe,
          otp = _ref.otp,
          authToken = _ref.authToken;

      var unset = function unset() {
        _this5.set('email', null);
        _this5.set('password', null);
        if (launchDarkly.get('release-remember-me')) {
          _this5.set('rememberMe', false);
          _this5.set('otp', null);
          _this5.set('authToken', null);
        }
      };

      if (launchDarkly.get('release-remember-me')) {
        this.setProperties({ email: email, password: password, rememberMe: rememberMe, otp: otp, authToken: authToken });
      } else {
        this.setProperties({ email: email, password: password });
      }

      return this._getSession().then(unset, function (e) {
        unset();
        throw e;
      });
    },
    _handleAttemptedCustomerLogin: function _handleAttemptedCustomerLogin() {
      this.get('notification').add({
        type: 'error',
        title: this.get('i18n').t('generic.permissions_denied'),
        autodismiss: true,
        dismissable: true
      });

      return RSVP.Promise.reject(new Error('Customers cannot log into the agent panel'));
    }
  });
});