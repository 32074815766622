define("frontend-cp/components/ko-admin/brands/list-row/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "brandName": "ko-admin_brands_list-row__brandName_1nt7bu",
    "caption": "ko-admin_brands_list-row__caption_1nt7bu"
  };
});