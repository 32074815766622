define('frontend-cp/services/locale', ['exports', 'ember-service/inject', 'frontend-cp/config/environment', 'moment', 'frontend-cp/locales/en-us'], function (exports, _inject, _environment, _moment, _enUs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var computed = Ember.computed;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;


  var USER_LOCALE_KEY = 'user-locale-v2';
  var FALLBACK_ACCOUNT_DEFAULT_LOCALE_CODE = _environment.default.defaultLocale;

  exports.default = Service.extend({
    launchDarkly: (0, _inject.default)(),

    store: service(),
    intl: service(),
    session: service(),
    localStore: service(),
    moment: service(),

    // CPs
    userLocale: computed('session.user', function () {
      return this.get('session.user.locale');
    }),

    settings: computed(function () {
      var store = this.get('store');
      return store.peekAll('setting');
    }),

    accountDefaultLanguageSetting: computed('settings.[]', function () {
      var settings = this.get('settings');
      var setting = settings.findBy('key', 'account.default_language');

      return setting;
    }),

    accountDefaultLocaleCode: computed('accountDefaultLanguageSetting.value', function () {
      return this.get('accountDefaultLanguageSetting.value') || FALLBACK_ACCOUNT_DEFAULT_LOCALE_CODE;
    }),

    // Methods
    setup: function setup() {
      var _this = this;

      var store = this.get('store');
      this.get('intl').set('adapterType', 'intl');

      return store.findAll('locale').then(function (locales) {
        var currentLocale = _this.getCurrentLocale(locales);
        _this.persistLocaleToLocalStorage(currentLocale);
        return _this._populateTranslations(currentLocale);
      });
    },
    _requestLocaleStrings: function _requestLocaleStrings(locale) {
      var adapter = getOwner(this).lookup('adapter:application');
      return adapter.ajax(adapter.namespace + '/locales/' + locale + '/strings', 'GET');
    },
    _populateTranslations: function _populateTranslations(locale) {
      var launchDarkly = this.get('launchDarkly');

      var intl = this.get('intl');

      var localeCode = locale.get('locale');

      intl.addTranslations(localeCode, {});
      intl.setLocale(localeCode);

      // this only sets the locale for the moment service
      // not for when we import moment from 'moment':
      // this.get('moment').changeLocale(localeCode);
      // set on imported version which applies globally:
      _moment.default.locale(localeCode);

      // This check corresponds to the getCurrentLocale method where en-gb is bypassed
      // Will be using this until it is localized
      if (['en-gb', FALLBACK_ACCOUNT_DEFAULT_LOCALE_CODE].includes(localeCode)) {
        intl.addTranslations(localeCode, _enUs.default);
        return RSVP.resolve();
      } else {
        var promise = void 0;

        if (launchDarkly.get('ops-use-locale-code-instead-of-id')) {
          promise = this._requestLocaleStrings(localeCode);
        } else {
          promise = this._requestLocaleStrings(locale.id);
        }

        return promise.then(function (strings) {
          // Converts a collection of translations like `"a.b.c": "hello"` to `{ a: { b: { c: "Hello" } } }`
          // as it is the format expected by ember-intl since 2.0.0
          var translations = strings.data.reduce(function (accum, translation) {
            if (translation.id.slice(0, 13) === 'frontend.api.') {
              var parts = translation.id.slice(13).split('.');
              var key = parts[parts.length - 1];
              var lastObject = accum[parts[0]] = accum[parts[0]] || {};
              try {
                parts.slice(1, -1).forEach(function (part) {
                  if (!lastObject[part]) {
                    lastObject[part] = {};
                  }
                  lastObject = lastObject[part];
                });
                lastObject[key] = translation.value;
              } catch (e) {
                window.console.warn('Error converting translations: ' + e.message);
              }
            }
            return accum;
          }, {});

          intl.addTranslations(localeCode, translations);
        });
      }
    },
    getCurrentLocale: function getCurrentLocale(locales) {
      var code = this.get('userLocale.locale') || this.getLocaleCodeFromLocalStorage();
      var locale = locales.findBy('locale', code);

      // Bypassing this makes sure moment gets correct locale
      if (locale && (locale.get('isLocalized') || code.toLowerCase() === 'en-gb')) {
        return locale;
      } else {
        return locales.findBy('locale', _environment.default.defaultLocale) || locales.findBy('isPublic');
      }
    },
    getLocaleLanguage: function getLocaleLanguage(localeCode) {
      var locales = this.get('store').peekAll('locale').filterBy('isPublic');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = locales[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var locale = _step.value;

          if (locale.get('locale') === localeCode) {
            return locale.get('name');
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return '';
    },
    getLocaleCodeFromLocalStorage: function getLocaleCodeFromLocalStorage() {
      return this.get('localStore').getItem('locale', USER_LOCALE_KEY, { persist: true });
    },
    persistLocaleToLocalStorage: function persistLocaleToLocalStorage(locale) {
      var localStore = this.get('localStore');
      var code = locale.get('locale');

      localStore.setItem('locale', USER_LOCALE_KEY, code, { persist: true });
    }
  });
});