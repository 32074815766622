define('frontend-cp/helpers/middle-ellipsis', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.middleEllipsis = middleEllipsis;
    var Helper = Ember.Helper;
    function middleEllipsis(text, _ref) {
        var startPart = _ref.startPart;

        var str = String(text);

        var start = str.substr(0, Math.ceil(str.length / 2));
        var end = str.substr(Math.ceil(str.length / 2));

        return startPart ? start : end;
    }

    exports.default = Helper.helper(middleEllipsis);
});