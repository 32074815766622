define("frontend-cp/components/ko-info-bar/field/text/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "text": "ko-info-bar_field_text__text_11xdxt",
    "input": "ko-info-bar_field_text__input_11xdxt",
    "header": "ko-info-bar_field_text__header_11xdxt"
  };
});