define('frontend-cp/components/ko-cases-list/kre-case/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    reloadCase: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              this.get('on-update')();

            case 1:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).keepLatest()

  });
});