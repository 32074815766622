define("frontend-cp/components/ko-notification-center-new/notification/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-notification-center-new_notification__item_1sxnkq",
    "is-seen": "ko-notification-center-new_notification__is-seen_1sxnkq",
    "item-link": "ko-notification-center-new_notification__item-link_1sxnkq",
    "avatar-container": "ko-notification-center-new_notification__avatar-container_1sxnkq",
    "for-breach": "ko-notification-center-new_notification__for-breach_1sxnkq",
    "avatar": "ko-notification-center-new_notification__avatar_1sxnkq",
    "summary": "ko-notification-center-new_notification__summary_1sxnkq",
    "time": "ko-notification-center-new_notification__time_1sxnkq"
  };
});