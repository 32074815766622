define('frontend-cp/components/ko-otp-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var run = Ember.run;
  var $ = Ember.$;
  exports.default = Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._focusToken = run.later(function () {
        return $('#' + _this.get('uniqueId')).focus();
      }, 200);
    },
    willDestroyElement: function willDestroyElement() {
      run.cancel(this._focusToken);
    }
  });
});