define("frontend-cp/components/ko-cases-list/column/conv-composite/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "avatarContainer": "ko-cases-list_column_conv-composite__avatarContainer_ev64fv",
    "avatar": "ko-cases-list_column_conv-composite__avatar_ev64fv",
    "avatarUnassigned": "ko-cases-list_column_conv-composite__avatarUnassigned_ev64fv",
    "agentAvatarContainer": "ko-cases-list_column_conv-composite__agentAvatarContainer_ev64fv",
    "agentAvatar": "ko-cases-list_column_conv-composite__agentAvatar_ev64fv",
    "title": "ko-cases-list_column_conv-composite__title_ev64fv",
    "separator": "ko-cases-list_column_conv-composite__separator_ev64fv",
    "requesterFullName": "ko-cases-list_column_conv-composite__requesterFullName_ev64fv",
    "status": "ko-cases-list_column_conv-composite__status_ev64fv",
    "bottom-text": "ko-cases-list_column_conv-composite__bottom-text_ev64fv",
    "bullet": "ko-cases-list_column_conv-composite__bullet_ev64fv",
    "preview": "ko-cases-list_column_conv-composite__preview_ev64fv",
    "preview-message": "ko-cases-list_column_conv-composite__preview-message_ev64fv",
    "appended-button": "ko-cases-list_column_conv-composite__appended-button_ev64fv",
    "append-button": "ko-cases-list_column_conv-composite__append-button_ev64fv",
    "remove-button": "ko-cases-list_column_conv-composite__remove-button_ev64fv",
    "showPrimaryBadge": "ko-cases-list_column_conv-composite__showPrimaryBadge_ev64fv",
    "subject-text": "ko-cases-list_column_conv-composite__subject-text_ev64fv",
    "status-icon": "ko-cases-list_column_conv-composite__status-icon_ev64fv",
    "attachments": "ko-cases-list_column_conv-composite__attachments_ev64fv"
  };
});