define('frontend-cp/session/admin/people/roles/new/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)('model'), {
    model: function model() {
      return this.store.createRecord('role');
    }
  });
});