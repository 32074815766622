define("frontend-cp/components/ko-insights/custom-reports/form/preview/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "preview": "ko-insights_custom-reports_form_preview__preview_1ykpt2",
    "preview-items": "ko-insights_custom-reports_form_preview__preview-items_1ykpt2",
    "preview-items-spinner": "ko-insights_custom-reports_form_preview__preview-items-spinner_1ykpt2",
    "no-preview-items": "ko-insights_custom-reports_form_preview__no-preview-items_1ykpt2",
    "preview-item": "ko-insights_custom-reports_form_preview__preview-item_1ykpt2",
    "preview-header": "ko-insights_custom-reports_form_preview__preview-header_1ykpt2",
    "preview-info": "ko-insights_custom-reports_form_preview__preview-info_1ykpt2"
  };
});