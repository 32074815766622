define("frontend-cp/components/ko-info-bar/update-log/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-info-bar_update-log__container_pxw5nw",
    "box": "ko-info-bar_update-log__box_pxw5nw",
    "close": "ko-info-bar_update-log__close_pxw5nw",
    "log-title": "ko-info-bar_update-log__log-title_pxw5nw",
    "log-item": "ko-info-bar_update-log__log-item_pxw5nw",
    "name": "ko-info-bar_update-log__name_pxw5nw",
    "tooltipstered": "ko-info-bar_update-log__tooltipstered_pxw5nw",
    "detail": "ko-info-bar_update-log__detail_pxw5nw",
    "header": "ko-info-bar_update-log__header_pxw5nw"
  };
});