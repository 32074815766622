define('frontend-cp/session/admin/people/roles/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var sort = Ember.computed.sort;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    modelSorting: ['isSystem:desc', 'title'],
    arrangedModel: sort('model', 'modelSorting'),
    plan: service(),

    // CPs
    needsPlanUpgrade: computed(function () {
      return !this.get('plan').has('custom_roles_and_permissions');
    }),

    // Actions
    actions: {
      transitionToNew: function transitionToNew() {
        this.transitionToRoute('session.admin.people.roles.new');
      },
      transitionToEdit: function transitionToEdit(role) {
        this.transitionToRoute('session.admin.people.roles.edit', role.get('id'));
      }
    }
  });
});