define('frontend-cp/components/ko-cases-list/column/custom-field/multi-choice/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    caseField: null,

    tagName: '',

    store: service(),

    value: computed('caseField', function () {
      var _this = this;

      var ids = this.get('caseField.value').split(',');

      var choices = ids.filter(function (id) {
        return id;
      }).map(function (id) {
        var option = _this.get('store').peekRecord('field-option', id);
        return option ? option.get('value') : null;
      });

      return choices.join(', ');
    })
  });
});