define('frontend-cp/components/ko-notification-center-new/component', ['exports', 'ember-service/inject', 'ember-concurrency', 'frontend-cp/services/sound-alerts'], function (exports, _inject, _emberConcurrency, _soundAlerts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var Component = Ember.Component;
  var run = Ember.run;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    tagName: '',

    store: service(),
    notificationCenter: service(),
    session: service(),
    browserTab: service(),
    soundAlerts: service(),
    notificationPreferences: service(),
    profilePreferences: service(),

    hasNewNotification: false,
    showPreferencesModal: false,
    isOpen: false,

    hasMore: computed.readOnly('notificationCenter.hasMore'),

    notifications: computed.filterBy('notificationCenter.notifications', 'activity.isActivity'),

    notificationChannel: computed(function () {
      return this.get('session.user.notificationChannel');
    }),

    /**
     * Task to paginate over notifications. Not droppable
     * since there is no way to trigger this task
     * multiple times.
     */
    getNotifications: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(reload) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('notificationCenter').paginate(reload);

            case 2:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    markAllAsRead: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              this._removeUnreadMark();
              _context2.next = 3;
              return this.get('notificationCenter').markAllAsRead();

            case 3:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    markAllAsSeen: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              this._removeUnreadMark();
              _context3.next = 3;
              return this.get('notificationCenter').markAllAsSeen();

            case 3:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).drop(),

    _showUnreadMark: function _showUnreadMark() {
      this.set('hasNewNotification', true);
    },
    _removeUnreadMark: function _removeUnreadMark() {
      this.set('hasNewNotification', false);
    },
    _handleNewNotification: function _handleNewNotification(id) {
      var _this = this;

      var options = {
        include: 'activity'
      };

      return this.get('store').findRecord('notification', id, options).then(function (notification) {
        var isDesktopNotification = _this._isDesktopNotification(notification);
        var isSoundPreferencesEnabled = _this._isSoundPreferenceEnabled(notification);

        if (isDesktopNotification && isSoundPreferencesEnabled) {
          _this.get('soundAlerts').play(_soundAlerts.DONK);
        }
      });
    },
    _isDesktopNotification: function _isDesktopNotification(notification) {
      var notificationType = notification.get('notificationType');
      return this.get('notificationPreferences').isDesktopNotification(notificationType);
    },
    _isSoundPreferenceEnabled: function _isSoundPreferenceEnabled(notification) {
      var profilePreferences = this.get('profilePreferences');

      if (!profilePreferences.get('preferences.desktopSoundAlerts')) {
        return false;
      }

      return true;
    },


    actions: {
      onOpen: function onOpen() {
        var _this2 = this;

        var reload = true;
        this.set('isOpen', true);
        this.get('getNotifications').perform(reload).then(function () {
          return _this2.get('markAllAsSeen').perform();
        });
      },
      onClose: function onClose() {
        var _this3 = this;

        this.set('isOpen', false);
        this.get('markAllAsSeen').perform().then(function () {
          return _this3.get('notificationCenter').reset();
        });
      },
      closeDropdown: function closeDropdown(dropdown) {
        // Using 'run.later' to keep dropdown from closing before the route
        // transitioning happens
        run.later(function () {
          dropdown.actions.close();
        });
      },
      showPreferences: function showPreferences(e) {
        e.preventDefault();
        this.set('showPreferencesModal', true);
      },
      closePreferecesModal: function closePreferecesModal() {
        this.set('showPreferencesModal', false);
      },
      onNewNotificationEvent: function onNewNotificationEvent(_ref) {
        var launchDarkly = this.get('launchDarkly');
        var resource_id = _ref.resource_id;

        if (this.get('isOpen')) {
          var reload = true;
          this.get('getNotifications').perform(reload);
        } else {
          this._showUnreadMark();
        }

        if (launchDarkly.get('release-live-chat-notification-improvements')) {
          this.get('browserTab').registerAppUpdate();
          this._handleNewNotification(resource_id);
        }
      }
    }
  });
});