define('frontend-cp/components/ko-admin/businesshours/edit/component', ['exports', 'npm:lodash', 'moment', 'ember-concurrency'], function (exports, _npmLodash, _moment, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var EmberObject = Ember.Object;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var Component = Ember.Component;


  // Weekday codes that match with businessHour.zones keys in order matching moment.weekdays()
  var LOCALE_WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  exports.default = Component.extend({
    tagName: '',

    // Attributes
    title: null,
    schema: null,
    businessHour: null,
    editedBusinessHour: null,
    onCancel: function onCancel() {},
    onSave: function onSave() {},
    onDelete: function onDelete() {},

    // Services
    confirmation: service(),
    errorHandler: service(),
    virtualModel: service(),

    // State
    newHoliday: null,

    // CPs
    titles: computed('businessHour.zones', function () {
      var weekdays = [].concat(_toConsumableArray(_moment.default.weekdays()));
      _npmLodash.default.times(_moment.default.localeData().firstDayOfWeek(), function () {
        return weekdays.push(weekdays.shift());
      });
      return weekdays;
    }).readOnly(),

    localeWeekdays: computed(function () {
      var result = [].concat(LOCALE_WEEKDAYS);
      _npmLodash.default.times(_moment.default.localeData().firstDayOfWeek(), function () {
        return result.push(result.shift());
      });
      return result;
    }).readOnly(),

    businessHourGrid: computed('editedBusinessHour', function () {
      var _this = this;

      var localeWeekdays = this.get('localeWeekdays');

      return localeWeekdays.map(function (weekday) {
        return _npmLodash.default.range(24).map(function (col) {
          return _this.get('editedBusinessHour.zones')[weekday].includes(col);
        });
      });
    }).readOnly(),

    hoursLegend: computed(function () {
      return _npmLodash.default.range(24).map(function (hour) {
        var label = hour + ':00';
        return hour < 10 ? '0' + label : label;
      });
    }),

    canBeDeleted: computed('businessHour.isDefault', 'businessHour.isNew', function () {
      return !(this.get('businessHour.isNew') || this.get('businessHour.isDefault'));
    }),

    isDisabled: computed.or('save.isRunning', 'performDelete.isRunning'),

    // Tasks
    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var businessHour, editedBusinessHour, duplicated;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              businessHour = this.get('businessHour');
              editedBusinessHour = this.get('editedBusinessHour');
              _context.next = 4;
              return this.get('virtualModel').save(businessHour, editedBusinessHour, this.get('schema'));

            case 4:
              duplicated = businessHour.get('holidays').filterBy('id', null);

              duplicated.forEach(function (holiday) {
                return holiday.unloadRecord();
              });
              this.get('onSave')();

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    confirmDelete: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmationBody: 'admin.businesshours.labels.delete_confirmation',
                intlConfirmationHeader: 'admin.businesshours.labels.confirm_delete',
                intlConfirmLabel: 'generic.confirm.delete_button'
              });

            case 2:
              _context2.next = 4;
              return this.get('performDelete').perform();

            case 4:
              this.get('onDelete');

            case 5:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    performDelete: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return this.get('businessHour').destroyRecord();

            case 2:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).drop(),

    actions: {
      businessHourRangeSelect: function businessHourRangeSelect(grid) {
        var zones = {};
        var localeWeekdays = this.get('localeWeekdays');

        localeWeekdays.forEach(function (weekday, index) {
          zones[weekday] = grid[index].map(function (col, index) {
            return col ? index : col;
          }).filter(function (col) {
            return col !== false;
          });
        });
        this.set('editedBusinessHour.zones', zones);
      },
      showHolidayForm: function showHolidayForm() {
        this.set('newHoliday', EmberObject.create({
          title: '',
          date: '',
          openHours: []
        }));
      },
      cancelAddingHoliday: function cancelAddingHoliday() {
        this.set('newHoliday', null);
      },
      deleteHoliday: function deleteHoliday(holiday) {
        this.get('editedBusinessHour.holidays').removeObject(holiday);
      },
      addHoliday: function addHoliday() {
        this.get('editedBusinessHour.holidays').pushObject(this.get('newHoliday'));
        this.set('newHoliday', null);
      }
    }
  });
});