define("frontend-cp/components/ko-text-editor/draggable-dropzone/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "dropzone": "ko-text-editor_draggable-dropzone__dropzone_m415o0",
    "border": "ko-text-editor_draggable-dropzone__border_m415o0",
    "fade-in": "ko-text-editor_draggable-dropzone__fade-in_m415o0",
    "message": "ko-text-editor_draggable-dropzone__message_m415o0",
    "disabled": "ko-text-editor_draggable-dropzone__disabled_m415o0"
  };
});