define('frontend-cp/session/agent/organizations/new/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route'], function (exports, _tabbedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = _tabbedRoute.default.extend({
    i18n: service(),
    processManager: service(),

    model: function model(_ref) {
      var timestamp = _ref.timestamp;

      var newOrg = this.store.peekAll('organization').findBy('creationTimestamp', timestamp);
      if (newOrg) {
        return newOrg;
      }

      return this.store.createRecord('organization', { creationTimestamp: timestamp });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(model, 'organization-new');
      processManager.setForegroundProcess(process);

      var tabStore = this.get('tabStore');
      var tab = tabStore.open(transition);
      tabStore.update(tab, { process: process });

      this.set('tab', tab);

      return RSVP.hash(model.getProperties('domains', 'tags')).then(function () {
        var OrganizationStateManager = getOwner(_this).factoryFor('state-manager:organization');
        var state = OrganizationStateManager.create({ organization: model, tab: tab });
        _this.set('state', state);
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('state', this.get('state'));
    },


    actions: {
      willTransition: function willTransition(transition) {
        var tabStore = this.get('tabStore');
        var activeTab = tabStore.get('activeTab');
        var newOrgController = this.controllerFor('session.agent.organizations.new.index');
        var openInSameTab = newOrgController.get('openInSameTab');

        // Record the current tab basePath so we can update the correct tab in `afterModel`
        if (activeTab && openInSameTab && transition.targetName === 'session.agent.organizations.organization.index') {
          newOrgController.set('transitionTabPath', activeTab.basePath);
        } else {
          newOrgController.set('openInSameTab', false);
          newOrgController.set('transitionTabPath', null);
        }
      },
      closeTab: function closeTab() {
        this.get('tabStore').close(this.tab);
      }
    }
  });
});