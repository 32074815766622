define('frontend-cp/components/ko-text-editor/mode-selector/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    active: false,
    isCase: false,
    qaClass: null,
    onClick: function onClick() {}
  });
});