define("frontend-cp/session/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "left-navigation": "session__left-navigation_1xlirl",
    "left-navigation-section": "session__left-navigation-section_1xlirl",
    "logo": "session__logo_1xlirl",
    "left-navigation-item": "session__left-navigation-item_1xlirl",
    "case-count-pill": "session__case-count-pill_1xlirl",
    "main": "session__main_1xlirl",
    "header": "session__header_1xlirl",
    "tabs": "session__tabs_1xlirl",
    "header-secondary": "session__header-secondary_1xlirl",
    "search-icon": "session__search-icon_1xlirl",
    "is-active": "session__is-active_1xlirl",
    "outlet": "session__outlet_1xlirl"
  };
});