define("frontend-cp/components/ko-admin/sla/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "ko-admin_sla_index__title_1n2sfj",
    "caption": "ko-admin_sla_index__caption_1n2sfj"
  };
});