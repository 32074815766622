define('frontend-cp/models/organization', ['exports', 'ember-data', 'ember-data-model-fragments', 'frontend-cp/models/has-posts'], function (exports, _emberData, _emberDataModelFragments, _hasPosts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var evented = Ember.Evented;
  var computed = Ember.computed;
  var bool = Ember.computed.bool;
  exports.default = _hasPosts.default.extend(evented, {
    name: _emberData.default.attr('string'),
    isShared: _emberData.default.attr('boolean'),
    brand: _emberData.default.belongsTo('brand', { async: true }),
    pinned: _emberData.default.attr('number'),
    customFields: _emberDataModelFragments.default.fragmentArray('organization-field-value'),
    fieldValues: _emberDataModelFragments.default.fragmentArray('organization-field-value', { defaultValue: [] }), // write only
    followers: _emberData.default.hasMany('user', { async: true, inverse: null }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    domains: _emberData.default.hasMany('identity-domain', { async: true }),
    phones: _emberData.default.hasMany('identity-phone', { async: true }),
    pinnedNotesCount: _emberData.default.attr('number'),
    viewNotes: computed(function () {
      return [];
    }),

    // Shadow children fields
    notes: _emberData.default.hasMany('organization-note', { async: true }),
    tags: _emberData.default.hasMany('tag', { async: true }),
    posts: _emberData.default.hasMany('post', { async: true }),

    // Indicates whether all fields of a case have been loaded, also see adapter/serializer.
    _isFullyLoaded: _emberData.default.attr('boolean', { defaultValue: false }),

    // used in the creation steps
    creationTimestamp: null,

    resourceType: 'organization',

    // CPs
    hasPinnedNotes: bool('pinnedNotesCount')
  });
});