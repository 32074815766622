define("frontend-cp/components/ko-attachments/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "attachments": "ko-attachments__attachments_125p7k",
    "attachments-header": "ko-attachments__attachments-header_125p7k",
    "attachments-count": "ko-attachments__attachments-count_125p7k",
    "separator": "ko-attachments__separator_125p7k",
    "download-all": "ko-attachments__download-all_125p7k"
  };
});