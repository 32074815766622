define('frontend-cp/components/ko-admin/engagements/index/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({

    // Services
    store: service(),
    i18n: service(),
    notification: service(),

    // CPs
    sortedEngagements: computed('engagements.@each.executionOrder', function () {
      return this.get('engagements').sortBy('executionOrder');
    }),

    // Tasks
    reorderEngagements: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(newOrder) {
      var data, response;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              data = { engagement_ids: newOrder.mapBy('id') };

              newOrder.forEach(function (engagement, index) {
                return engagement.set('executionOrder', index);
              });
              _context.prev = 2;
              _context.next = 5;
              return this.get('store').adapterFor('engagement').reorderEngagements(data);

            case 5:
              response = _context.sent;

              this.get('store').pushPayload('engagement', response);
              _context.next = 12;
              break;

            case 9:
              _context.prev = 9;
              _context.t0 = _context['catch'](2);

              this.get('notification').error(this.get('i18n').t('admin.engagements.notifications.error.reorder'));

            case 12:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[2, 9]]);
    })),

    // Actions
    actions: {
      stop: function stop(event) {
        event.stopPropagation();
      }
    }
  });
});