define('frontend-cp/lib/copy-to-clipboard', ['exports', 'npm:clipboard/lib/clipboard-action'], function (exports, _clipboardAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (text) {
    var emitter = {
      emit: function emit() {}
    };
    var container = document.body;
    var action = new _clipboardAction.default({ text: text, emitter: emitter, container: container });
    action.destroy();
  };
});