define('frontend-cp/components/ko-journey-timeline/component', ['exports', 'frontend-cp/components/ko-agent-content/layout/styles', 'frontend-cp/components/ko-journey-timeline/styles', 'ember-concurrency', 'frontend-cp/components/ko-timeline-2/streaks', 'frontend-cp/lib/scroll-to'], function (exports, _styles, _styles2, _emberConcurrency, _streaks, _scrollTo2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var run = Ember.run;
  var debounce = Ember.run.debounce;
  var schedule = Ember.run.schedule;
  var scheduleOnce = Ember.run.scheduleOnce;
  var jQuery = Ember.$;
  var RSVP = Ember.RSVP;


  var LAST_POST = 'LAST_POST';

  var TIMELINE_EVENTS = ['didFetchNewer', 'didFetchOlder', 'didFetchMostRecent', 'didFetchNewerAfterReply', 'didAddSentPosts', 'didFetchAtTimestamp'];

  var JUMP_BLIP_DELAY = 500;
  var JUMP_BLIP_DURATION = 500;

  exports.default = Component.extend({
    tagName: '',

    moreOlderPosts: reads('timeline.moreOlderPosts'),
    moreNewerPosts: reads('timeline.moreNewerPosts'),

    previousTimeline: null,
    previousPostId: null,
    previousTimestamp: null,

    timeline: null,
    mode: null,
    model: null,
    timestamp: null,

    allPosts: computed('timeline.posts.[]', function () {
      return this.get('timeline.posts') || [];
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._registerScrollHandler();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _getProperties = this.getProperties('timeline', 'postId', 'timestamp', 'previousTimestamp'),
          timeline = _getProperties.timeline,
          postId = _getProperties.postId,
          timestamp = _getProperties.timestamp,
          previousTimestamp = _getProperties.previousTimestamp;

      if (timeline) {
        if (this._isSwitchingTimelines() || timestamp !== previousTimestamp) {
          this._switchTimelines();
        }

        this.setProperties({
          previousTimeline: timeline,
          previousPostId: postId,
          previousTimestamp: timestamp
        });
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      (0, _streaks.default)(jQuery('.' + _styles2.default.container));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unsubscribeFromTimeline(this.get('timeline'));
      this._unregisterScrollHandler();
    },


    // Tasks
    loadPosition: function loadPosition(timestamp) {
      return this.get('timeline.loadPosition').perform(timestamp);
    },
    loadMostRecent: function loadMostRecent() {
      return this.get('timeline.loadMostRecent').perform();
    },
    fetchAtTimestamp: function fetchAtTimestamp(timestamp) {
      return this.get('timeline.fetchAtTimestamp').perform(timestamp);
    },
    fetchMostRecent: function fetchMostRecent() {
      return this.get('timeline.fetchMostRecent').perform();
    },
    fetchOlder: function fetchOlder() {
      return this.get('timeline.fetchOlder').perform();
    },
    fetchNewer: function fetchNewer() {
      return this.get('timeline.fetchNewer').perform();
    },


    _blipPost: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(postId) {
      var element;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              element = this._findPostById(postId);

              if (!(element && element.length)) {
                _context.next = 8;
                break;
              }

              _context.next = 4;
              return (0, _emberConcurrency.timeout)(JUMP_BLIP_DELAY);

            case 4:
              element.addClass(_styles2.default.located);
              _context.next = 7;
              return (0, _emberConcurrency.timeout)(JUMP_BLIP_DURATION);

            case 7:
              element.removeClass(_styles2.default.located);

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    _isSwitchingTimelines: function _isSwitchingTimelines() {
      var _getProperties2 = this.getProperties('timeline', 'previousTimeline'),
          timeline = _getProperties2.timeline,
          previousTimeline = _getProperties2.previousTimeline;

      return timeline !== previousTimeline;
    },
    _switchTimelines: function _switchTimelines() {
      var _getProperties3 = this.getProperties('timestamp', 'timeline', 'previousTimeline'),
          timestamp = _getProperties3.timestamp,
          timeline = _getProperties3.timeline,
          previousTimeline = _getProperties3.previousTimeline;

      this._unsubscribeFromTimeline(previousTimeline);
      this._subscribeToTimeline(timeline);

      if (timestamp) {
        this.fetchAtTimestamp(timestamp);
      } else if (timeline.get('posts.length')) {
        this._restoreScrollPosition();
      } else {
        this.fetchMostRecent();
      }
    },
    _subscribeToTimeline: function _subscribeToTimeline(timeline) {
      var _this = this;

      if (!timeline) {
        return;
      }
      TIMELINE_EVENTS.forEach(function (name) {
        return timeline.on(name, _this, name);
      });
    },
    _unsubscribeFromTimeline: function _unsubscribeFromTimeline(timeline) {
      var _this2 = this;

      if (!timeline) {
        return;
      }
      TIMELINE_EVENTS.forEach(function (name) {
        return timeline.off(name, _this2, name);
      });
    },
    _registerScrollHandler: function _registerScrollHandler() {
      var _this3 = this;

      this._scrollParent().on('scroll.timeline', function () {
        return _this3._didScroll();
      });
    },
    _unregisterScrollHandler: function _unregisterScrollHandler() {
      this._scrollParent().off('scroll.timeline');
      run.cancel(this._scrollTimer);
    },
    _didScroll: function _didScroll() {
      this._scrollTimer = debounce(this, '_saveScrollData', 250);
      this._autoload();
    },
    _autoload: function _autoload() {
      var $sp = this._scrollParent();

      if ((0, _scrollTo2.isAtTop)($sp)) {
        debounce(this, '_didReachTop', 250, true);
      }

      if ((0, _scrollTo2.isAtBottom)($sp)) {
        debounce(this, '_didReachBottom', 250, true);
      }
    },
    _didReachTop: function _didReachTop() {
      if (this.get('timeline.moreOlderPosts')) {
        this._saveScrollData();
        this.fetchOlder();
      }
    },
    _didReachBottom: function _didReachBottom() {
      if (this.get('timeline.moreNewerPosts')) {
        this._saveScrollData();
        this.fetchNewer();
      }
    },


    // fetched newer as a result of pushing the "load more" button
    didFetchNewer: function didFetchNewer() {},
    didAddSentPosts: function didAddSentPosts() {
      var _this4 = this;

      this.scrollTo({ position: _scrollTo2.BOTTOM, animated: true }).then(function () {
        return _this4._saveScrollData();
      });
    },


    // fetched newer posts after a reply has been submitted
    didFetchNewerAfterReply: function didFetchNewerAfterReply() {
      var _this5 = this;

      this.scrollTo({ position: _scrollTo2.BOTTOM, animated: true }).then(function () {
        return _this5._saveScrollData();
      });
    },
    didFetchOlder: function didFetchOlder() {
      this._restoreScrollPosition();
    },
    didFetchPosition: function didFetchPosition(_ref) {
      var _this6 = this;

      var postId = _ref.postId;

      this.scrollTo({ postId: postId, position: _scrollTo2.MIDDLE }).then(function () {
        return _this6._saveScrollData();
      }).then(function () {
        return _this6._autoload();
      });
    },
    didFetchMostRecent: function didFetchMostRecent() {
      var _this7 = this;

      this.scrollTo({ postId: LAST_POST, position: _scrollTo2.BOTTOM }).then(function () {
        return _this7._saveScrollData();
      });
    },
    didFetchAtTimestamp: function didFetchAtTimestamp() {
      var _this8 = this;

      var targetActivityTimestamp = parseInt(this.get('timestamp'));
      var activityTimestamp = void 0;

      var postId = this.get('allPosts').filter(function (post) {
        activityTimestamp = post.get('createdAt').getTime() / 1000;
        return activityTimestamp === targetActivityTimestamp;
      }).get('firstObject.id');

      this.scrollTo({ postId: postId, position: _scrollTo2.MIDDLE }).then(function () {
        _this8._saveScrollData();
        _this8.get('_blipPost').perform(postId);
      });
    },
    scrollTo: function scrollTo() {
      var _this9 = this;

      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref2$postId = _ref2.postId,
          postId = _ref2$postId === undefined ? null : _ref2$postId,
          _ref2$position = _ref2.position,
          position = _ref2$position === undefined ? _scrollTo2.BOTTOM : _ref2$position,
          _ref2$animated = _ref2.animated,
          animated = _ref2$animated === undefined ? false : _ref2$animated;

      return new RSVP.Promise(function (resolve, reject) {
        scheduleOnce('afterRender', function () {
          var timeline = _this9._scrollParent();
          if (!timeline.length) {
            return reject(new Error('No timeline'));
          }

          var timelineItem = void 0;
          if (postId === LAST_POST) {
            timelineItem = _this9._findLastPost();
          } else if (postId) {
            timelineItem = _this9._findPostById(postId);
          }

          (0, _scrollTo2.scrollTo)({
            parent: timeline,
            element: timelineItem,
            position: position,
            animated: animated
          }).then(resolve).catch(reject);
        });
      });
    },
    _saveScrollData: function _saveScrollData() {
      var $scrollParent = this._scrollParent();

      var post = this._findFirstVisiblePostElement($scrollParent);

      if (post) {
        var $post = jQuery(post);
        var offsetFromParent = $post.offset().top - $scrollParent.offset().top;
        var postId = $post.data('id');

        var timeline = this.get('timeline');

        timeline.set('scrollData', {
          offset: offsetFromParent,
          id: postId
        });
      }
    },
    _restoreScrollPosition: function _restoreScrollPosition() {
      var _this10 = this;

      var scrollData = this.get('timeline.scrollData');

      if (scrollData) {
        schedule('afterRender', function () {
          var offset = scrollData.offset,
              id = scrollData.id;

          var $scrollParent = _this10._scrollParent();
          var $post = _this10._findPostById(id);

          $scrollParent[0].scrollTop = $post[0].offsetTop - offset;
        });
      } else {
        this.scrollTo({ postId: LAST_POST, position: _scrollTo2.BOTTOM });
      }
    },
    _findFirstVisiblePostElement: function _findFirstVisiblePostElement($scrollParent) {
      var $posts = jQuery('.' + _styles2.default.item);
      return $posts.toArray().find(function (el) {
        return jQuery(el).offset().top - $scrollParent.offset().top >= 0;
      });
    },
    _findPostById: function _findPostById(postId) {
      return jQuery('.' + _styles2.default.item + '[data-id="' + postId + '"]');
    },
    _findLastPost: function _findLastPost() {
      return jQuery('.' + _styles2.default.container + ' > :last-child');
    },
    _scrollParent: function _scrollParent() {
      return jQuery('.' + _styles.default.timeline);
    },
    scrollPostIntoViewIfNeeded: function scrollPostIntoViewIfNeeded(post) {
      var postId = post.get('id');
      if (!(0, _scrollTo2.isVisibleInScrollArea)(this._findPostById(postId), this._scrollParent())) {
        this.scrollTo({ postId: postId, position: _scrollTo2.BOTTOM, animated: true });
      }
    },


    actions: {
      scrollPostIntoView: function scrollPostIntoView(post) {
        scheduleOnce('afterRender', this, 'scrollPostIntoViewIfNeeded', post);
      }
    }

  });
});