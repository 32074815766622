define('frontend-cp/components/ko-entity-list/sidebar/definition/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var computed = Ember.computed;
  var notEmpty = Ember.computed.notEmpty;
  exports.default = Component.extend({
    tagName: '',

    //Attrs
    definition: null,
    currentPredicateCollections: null,
    currentPropositionOperators: null,

    //State
    isChecked: false,
    oldCurrentPredicateCollections: false,

    //Serivces

    //Lifecycle Hooks
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var oldPropositionOperators = this.get('oldPropositionOperators');
      var newPropositionOperators = this.get('currentPropositionOperators');

      if (oldPropositionOperators && oldPropositionOperators !== newPropositionOperators) {
        this.notifyPropertyChange('currentPropositionOperators');
      }

      this.set('oldPropositionOperators', [].concat(_toConsumableArray(newPropositionOperators)));

      var oldCurrentPredicateCollections = this.get('oldCurrentPredicateCollections');
      var newCurrentPredicateCollections = this.get('currentPredicateCollections');

      if (oldCurrentPredicateCollections) {
        if (oldCurrentPredicateCollections.length !== newCurrentPredicateCollections.length) {
          this.notifyPropertyChange('currentPropositions');
          return;
        }

        oldCurrentPredicateCollections.forEach(function (proposition, i) {
          if (proposition.label !== newCurrentPredicateCollections[i].label || proposition.field !== newCurrentPredicateCollections[i].field || proposition.operator !== newCurrentPredicateCollections[i].operator || proposition.value !== newCurrentPredicateCollections[i].value) {

            _this.notifyPropertyChange('currentPropositions');
            return;
          }
        });
      }

      this.set('oldCurrentPredicateCollections', [].concat(_toConsumableArray(newCurrentPredicateCollections)));
    },


    //CP's
    currentPropositions: computed('definition', 'currentPredicateCollections', function () {
      var definitionLabel = this.get('definition.label').toLowerCase();
      var currentPredicateCollections = this.get('currentPredicateCollections');

      return currentPredicateCollections.get(definitionLabel) || [];
    }),

    hasPropositions: notEmpty('currentPropositions'),

    propositionOperators: computed(function () {
      return ['AND', 'OR'];
    }),

    currentPropositionOperator: computed('currentPropositionOperators', 'definition.label', function () {
      var currentPropositionOperators = this.get('currentPropositionOperators');
      var definitionLabel = this.get('definition.label').toLowerCase();

      return currentPropositionOperators.get(definitionLabel);
    }),

    //Actions
    actions: {
      onCheck: function onCheck() {
        var definition = this.get('definition');
        var label = definition.get('label').toLowerCase();
        var currentPropositions = this.get('currentPropositions');
        var hasPropositions = this.get('hasPropositions');

        if (hasPropositions) {
          this.sendAction('onPropositionsRemoveAll', definition);
        } else {
          this.sendAction('onPropositionsChange', currentPropositions.length, {
            label: label,
            field: this.get('definition.operators.firstObject.originalFieldName'),
            operator: this.get('definition.operators.firstObject.label')
          });
        }
      },
      onAddProposition: function onAddProposition() {
        var currentPropositions = this.get('currentPropositions');
        var label = this.get('definition.label').toLowerCase();

        this.sendAction('onPropositionsChange', currentPropositions.length, {
          label: label,
          field: this.get('definition.operators.firstObject.originalFieldName'),
          operator: this.get('definition.operators.firstObject.label')
        });
      },
      onRemoveProposition: function onRemoveProposition(i, proposition) {
        this.sendAction('onPropositionsRemoval', i, proposition);
      },
      setPropositionOperator: function setPropositionOperator(operator) {
        var label = this.get('definition.label').toLowerCase();
        this.sendAction('onPropositionOperatorChange', label, operator);
      }
    }
  });
});