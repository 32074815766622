define("frontend-cp/components/ko-stub/user-info-block/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "stub": "ko-stub_user-info-block__stub_11jw54",
    "user": "ko-stub_user-info-block__user_11jw54",
    "avatar": "ko-stub_user-info-block__avatar_11jw54",
    "rows": "ko-stub_user-info-block__rows_11jw54",
    "title": "ko-stub_user-info-block__title_11jw54",
    "subtitle": "ko-stub_user-info-block__subtitle_11jw54",
    "list-item": "ko-stub_user-info-block__list-item_11jw54",
    "icon": "ko-stub_user-info-block__icon_11jw54",
    "description": "ko-stub_user-info-block__description_11jw54"
  };
});