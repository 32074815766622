define("frontend-cp/components/ko-tabs/tab/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-tabs_tab__item_oqn505",
    "item--shrink": "ko-tabs_tab__item--shrink_oqn505",
    "link": "ko-tabs_tab__link_oqn505"
  };
});