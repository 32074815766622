define('frontend-cp/components/ko-admin/macros/edit/action-handlers', ['exports', 'frontend-cp/components/ko-admin/macros/edit/action-handlers/priority', 'frontend-cp/components/ko-admin/macros/edit/action-handlers/remove-tags', 'frontend-cp/components/ko-admin/macros/edit/action-handlers/add-tags', 'frontend-cp/components/ko-admin/macros/edit/action-handlers/case-type', 'frontend-cp/components/ko-admin/macros/edit/action-handlers/status', 'frontend-cp/components/ko-admin/macros/edit/action-handlers/reply-type', 'frontend-cp/components/ko-admin/macros/edit/action-handlers/reply-contents', 'frontend-cp/components/ko-admin/macros/edit/action-handlers/assignee'], function (exports, _priority, _removeTags, _addTags, _caseType, _status, _replyType, _replyContents, _assignee) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    priority: _priority.default,
    'remove-tags': _removeTags.default,
    'add-tags': _addTags.default,
    'case-type': _caseType.default,
    status: _status.default,
    'reply-type': _replyType.default,
    'reply-contents': _replyContents.default,
    assignee: _assignee.default
  };
});