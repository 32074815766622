define("frontend-cp/components/ko-timeline-2/list/item/created-at/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "ko-timeline-2_list_item_created-at__title_11wkv7",
    "link": "ko-timeline-2_list_item_created-at__link_11wkv7"
  };
});