define('frontend-cp/models/note', ['exports', 'ember-data', 'frontend-cp/models/postable'], function (exports, _emberData, _postable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  exports.default = _postable.default.extend({
    bodyText: _emberData.default.attr('string'),
    bodyHtml: _emberData.default.attr('string'),
    body: or('bodyHtml', 'bodyText'),
    contents: _emberData.default.attr('string'),
    isPinned: _emberData.default.attr('boolean'),
    pinnedBy: _emberData.default.belongsTo('user', { async: false }),
    attachments: _emberData.default.hasMany('attachment', { async: true }),
    attachmentFileIds: _emberData.default.attr('string'),
    downloadAll: _emberData.default.attr('string'),

    user: _emberData.default.belongsTo('user', { async: true }),
    parent: _emberData.default.belongsTo('case', { async: true, polymorphic: true }),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    resourceUrl: _emberData.default.attr('string'),

    isAiDraft: _emberData.default.attr('boolean'),
    noteType: _emberData.default.attr('string'),

    postType: 'note'
  });
});