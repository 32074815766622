define('frontend-cp/components/ko-admin/holidays/edit/component', ['exports', 'npm:lodash', 'moment', 'frontend-cp/services/virtual-model'], function (exports, _npmLodash, _moment, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var on = Ember.on;


  var schema = (0, _virtualModel.model)('businesshour-holiday', {
    title: (0, _virtualModel.attr)(),
    date: (0, _virtualModel.attr)(),
    openHours: (0, _virtualModel.list)()
  });

  exports.default = Component.extend({
    tagName: '',

    // Attributes
    holiday: null,
    onCancel: null,

    // Services
    virtualModel: service(),

    initEditedHoliday: on('init', function () {
      this.set('editedHoliday', this.get('virtualModel').makeSnapshot(this.get('holiday'), schema));
    }),

    holidayGrid: computed('editedHoliday.openHours.[]', function () {
      var openHours = this.get('editedHoliday.openHours');
      return [_npmLodash.default.range(24).map(function (hour) {
        return openHours.includes(hour);
      })];
    }).readOnly(),

    hoursLegend: computed(function () {
      return _npmLodash.default.range(24).map(function (hour) {
        var label = hour + ':00';
        return hour < 10 ? '0' + label : label;
      });
    }),

    holidayDate: computed('editedHoliday.date', function () {
      return this.get('editedHoliday.date') ? (0, _moment.default)(this.get('editedHoliday.date'), 'DD/MM/YYYY').toDate() : null;
    }),

    actions: {
      selectHolidayDate: function selectHolidayDate(date) {
        this.set('editedHoliday.date', date ? (0, _moment.default)(date).format('DD/MM/YYYY') : null);
      },
      selectRange: function selectRange(grid) {
        var hours = grid.get('firstObject').map(function (col, index) {
          return col ? index : col;
        });
        this.set('editedHoliday.openHours', hours.filter(function (col) {
          return col !== false;
        }));
      },
      save: function save(event) {
        event.stopPropagation();
        if (!this.get('editedHoliday.date')) {
          this.set('editedHoliday.date', (0, _moment.default)().format('DD/MM/YYYY'));
        }

        this.get('virtualModel').patch(this.get('holiday'), this.get('editedHoliday'), schema);
        this.get('onSave')();
      },
      cancelEditing: function cancelEditing(event) {
        event.stopPropagation();
        this.get('onCancel')();
      }
    }
  });
});