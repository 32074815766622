define("frontend-cp/session/errors/not-found/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "wrapper": "session_errors_not-found__wrapper_dha614",
    "image-container": "session_errors_not-found__image-container_dha614",
    "image": "session_errors_not-found__image_dha614",
    "header": "session_errors_not-found__header_dha614",
    "body": "session_errors_not-found__body_dha614"
  };
});