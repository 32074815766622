define('frontend-cp/session/admin/customizations/localization/settings/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var filterBy = Ember.computed.filterBy;
  var sort = Ember.computed.sort;
  var oneWay = Ember.computed.oneWay;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  var getOwner = Ember.getOwner;
  exports.default = Controller.extend({
    // Attributes:
    originalSettings: null,
    currentSettings: null,

    // Services
    i18n: service(),
    notification: service(),
    store: service(),
    timezone: service('timezones'),

    // CPs
    availableTimeFormats: computed(function () {
      var i18n = this.get('i18n');
      return [{ id: '24hour', value: i18n.t('generic.settings.24hour') }, { id: '12hour', value: i18n.t('generic.settings.12hour') }];
    }),

    selectedTimeFormat: computed('currentSettings.time_format', function () {
      var _this = this;

      return this.get('availableTimeFormats').find(function (timeFormat) {
        return timeFormat.id === _this.get('currentSettings.time_format');
      });
    }),

    availableLanguages: computed(function () {
      return this.get('store').findAll('locale');
    }),

    enabledLanguages: filterBy('availableLanguages', 'isPublic'),
    languagesSorting: ['navtiveName'],
    sortedLanguages: sort('enabledLanguages', 'languagesSorting'),

    availableTimezones: oneWay('timezone.timeZones'),

    tabs: computed(function () {
      return [{
        id: 'case',
        label: this.get('i18n').t('admin.localization.tabs.languages'),
        routeName: 'session.admin.customizations.localization.index',
        dynamicSegments: [],
        queryParams: null
      }, {
        id: 'case',
        label: this.get('i18n').t('admin.localization.tabs.settings'),
        routeName: 'session.admin.customizations.localization.settings',
        dynamicSegments: [],
        queryParams: null
      }];
    }),

    isTimeFormatEdited: computed('currentSettings.time_format', 'originalSettings.time_format', function () {
      return this.get('currentSettings.time_format') !== this.get('originalSettings.time_format');
    }),
    isDefaultLanguageEdited: computed('currentSettings.default_language', 'originalSettings.default_language', function () {
      return this.get('currentSettings.default_language') !== this.get('originalSettings.default_language');
    }),
    isTimezoneEdited: computed('currentSettings.timezone', 'originalSettings.timezone', function () {
      return this.get('currentSettings.timezone') !== this.get('originalSettings.timezone');
    }),
    hasChanges: computed('isTimezoneEdited', 'isDefaultLanguageEdited', 'isTimeFormatEdited', function () {
      return this.get('isTimezoneEdited') || this.get('isDefaultLanguageEdited') || this.get('isTimeFormatEdited');
    }),

    selectedLocale: computed('currentSettings.default_language', function () {
      var _this2 = this;

      return this.get('store').peekAll('locale').find(function (locale) {
        return locale.get('locale') === _this2.get('currentSettings.default_language');
      });
    }),

    selectedTimezone: computed('currentSettings.timezone', function () {
      var _this3 = this;

      return this.get('availableTimezones').find(function (timezone) {
        return timezone.id === _this3.get('currentSettings.timezone');
      });
    }),

    // Actions
    actions: {
      editLanguage: function editLanguage(locale) {
        this.set('currentSettings.default_language', locale.get('locale'));
      },
      editTimezone: function editTimezone(timezone) {
        this.set('currentSettings.timezone', timezone.id);
      },
      editTimeFormat: function editTimeFormat(timeFormat) {
        this.set('currentSettings.time_format', timeFormat.id);
      },
      save: function save() {
        var _this4 = this;

        var adapter = getOwner(this).lookup('adapter:application');

        return adapter.ajax(adapter.namespace + '/settings', 'PUT', {
          data: {
            values: {
              'account.default_language': this.get('currentSettings.default_language'),
              'account.timezone': this.get('currentSettings.timezone'),
              'account.time_format': this.get('currentSettings.time_format')
            }
          }
        }).then(function (result) {
          _this4.updateStore();
          return result;
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('session.admin.customizations.localization');
      },
      handleSuccess: function handleSuccess() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        // so no unsaved changes popup appears on transition
        this.set('originalSettings.default_language', this.get('currentSettings.default_language'));
        this.set('originalSettings.timezone', this.get('currentSettings.timezone'));
        this.set('originalSettings.time_format', this.get('currentSettings.time_format'));

        this.transitionToRoute('session.admin.customizations.localization');
      }
    },

    // Methods
    initEdits: function initEdits() {
      // noop
    },
    isEdited: function isEdited() {
      return this.get('hasChanges');
    },
    updateStore: function updateStore() {
      var _this5 = this;

      var store = this.get('store');
      var settings = store.peekAll('setting');
      var keys = ['default_language', 'timezone', 'time_format'];

      keys.forEach(function (key) {
        var value = _this5.get('currentSettings.' + key);
        var setting = settings.findBy('key', 'account.' + key);

        if (!setting) {
          return;
        }

        store.push({
          data: {
            id: setting.get('id'),
            type: 'setting',
            attributes: { value: value }
          }
        });
      });
    }
  });
});