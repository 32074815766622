define("frontend-cp/components/ko-layout/popup-with-arrow/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-layout_popup-with-arrow__container_l3wka5",
    "arrow": "ko-layout_popup-with-arrow__arrow_l3wka5"
  };
});