define('frontend-cp/session/agent/welcome/route', ['exports', 'frontend-cp/lib/role-types'], function (exports, _roleTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var EmberObject = Ember.Object;
  var resolve = Ember.RSVP.resolve;
  var hash = Ember.RSVP.hash;
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    session: service(),
    notification: service('notification'),
    i18n: service(),

    queryParams: {
      code: { refreshModel: true },
      state: { refreshModel: true },
      oauth_token: { refreshModel: true },
      oauth_verifier: { refreshModel: true }
    },

    _filterOutCustomerRoles: function _filterOutCustomerRoles(roles) {
      return roles.filterBy('isCollaboratorOrHigher');
    },
    _filterOutHigherRankedRoles: function _filterOutHigherRankedRoles(roles) {
      var sessionUserRoleType = this.get('session.user.role.roleType');
      var userRoleRank = _roleTypes.default[sessionUserRoleType] && _roleTypes.default[sessionUserRoleType].rank;

      var filteredRoles = roles.filter(function (role) {
        var roleType = role.get('roleType');
        var roleRank = _roleTypes.default[roleType] && _roleTypes.default[roleType].rank;
        return roleRank <= userRoleRank;
      });

      return resolve(filteredRoles);
    },
    model: function model(params, transition) {
      var store = this.get('store');

      var roles = store.findAll('role').then(this._filterOutCustomerRoles.bind(this)).then(this._filterOutHigherRankedRoles.bind(this));
      var teams = store.findAll('team');
      var invitation = EmberObject.create({
        users: A([])
      });
      var brand = store.findAll('brand');
      var settings = store.findAll('setting');
      var twitterAccount = store.findAll('twitterAccount');
      var facebookPage = store.findAll('facebookPage');
      var agentMetrics = store.queryRecord('conversation-starter', {});
      var isRedirected = transition.queryParams.trial;

      return hash({
        invitation: invitation,
        roles: roles,
        teams: teams,
        brand: brand,
        settings: settings,
        twitterAccount: twitterAccount,
        facebookPage: facebookPage,
        agentMetrics: agentMetrics,
        params: params,
        isRedirected: isRedirected
      });
    }
  });
});