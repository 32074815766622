define('frontend-cp/session/admin/automation/monitors/edit/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend((0, _route.default)(), {
    i18n: service(),
    notification: service(),

    model: function model(params) {
      return this.get('store').findRecord('monitor', params.monitor_id);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties(this.modelFor('session.admin.automation.monitors'));
    },


    // Actions
    actions: {
      didSave: function didSave() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });
        this.transitionTo('session.admin.automation.monitors');
      },
      cancel: function cancel() {
        this.transitionTo('session.admin.automation.monitors');
      }
    }
  });
});