define("frontend-cp/session/admin/apps/api/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "header": "session_admin_apps_api_index__header_1si60s",
    "contentBlock": "session_admin_apps_api_index__contentBlock_1si60s",
    "title": "session_admin_apps_api_index__title_1si60s",
    "subtitle": "session_admin_apps_api_index__subtitle_1si60s",
    "content": "session_admin_apps_api_index__content_1si60s",
    "messagesBlock": "session_admin_apps_api_index__messagesBlock_1si60s",
    "definitionList": "session_admin_apps_api_index__definitionList_1si60s",
    "definitionTitle": "session_admin_apps_api_index__definitionTitle_1si60s",
    "definitionText": "session_admin_apps_api_index__definitionText_1si60s",
    "findOutCTA": "session_admin_apps_api_index__findOutCTA_1si60s"
  };
});