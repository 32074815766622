define("frontend-cp/components/ko-admin/triggers/form/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "channel-layout": "ko-admin_triggers_form__channel-layout_ypztkr",
    "channels": "ko-admin_triggers_form__channels_ypztkr",
    "events": "ko-admin_triggers_form__events_ypztkr"
  };
});