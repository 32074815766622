define('frontend-cp/components/ko-insights/searches-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var A = Ember.A;
  var Component = Ember.Component;
  var dasherize = Ember.String.dasherize;
  var computed = Ember.computed;
  exports.default = Component.extend({
    searches: null,

    columnList: A([EmberObject.create({ id: 'query', name: 'query' }), EmberObject.create({ id: 'count', name: 'count' })]),

    searchSorting: ['attempt_count:desc'],
    sortedSearches: computed.sort('searches', 'searchSorting'),

    maxWidthForColumn: function maxWidthForColumn(columnName) {
      if (columnName === 'count') {
        return 80;
      }

      return null;
    },
    componentForHeader: function componentForHeader(column) {
      return 'ko-insights/searches-list/header/' + dasherize(column.get('name'));
    },
    componentForColumn: function componentForColumn(column) {
      return 'ko-insights/searches-list/column/' + dasherize(column.get('name'));
    }
  });
});