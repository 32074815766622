define('frontend-cp/session/admin/manage/views/new/route', ['exports', 'ember-service/inject', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _inject, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)('caseView'), {
    launchDarkly: (0, _inject.default)(),
    model: function model() {
      var launchDarkly = this.get('launchDarkly');

      var newView = this.store.createRecord('view');
      /* Setup defaults and recache the relationships */
      var firstProposition = this.store.createRecord('proposition', {});

      newView.get('predicateCollections').createRecord({
        propositions: [firstProposition]
      });

      return this.store.findAll('column').then(function (columns) {
        var defaults = ['caseid', 'subject', 'casestatusid', 'assigneeagentid', 'updatedat', 'requesterid'];
        if (launchDarkly.get('release-refactor-columns-new-view')) {
          defaults = ['casestatusid', 'assigneeagentid', 'createdat', 'updatedat'];
        }
        var filtered = columns.filter(function (column) {
          return defaults.indexOf(column.get('id')) > -1;
        });
        newView.get('columns').pushObjects(filtered);
        return newView;
      });
    },
    setupController: function setupController(controller, caseView) {
      controller.setProperties({ caseView: caseView });
      controller.initEdits();
    }
  });
});