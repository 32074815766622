define('frontend-cp/session/admin/customizations/brands/edit/index/controller', ['exports', 'ember-service/inject', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/customizations/brands/schema'], function (exports, _inject, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    // Attributes
    brand: null,
    editedBrand: null,
    schema: _schema.default,

    // Services
    notification: service(),
    i18n: service(),
    virtualModel: service(),
    metrics: service(),

    // CPs
    tabs: computed('brand.id', function () {
      return [{
        label: this.get('i18n').t('admin.manage.brands.edit.tabs.settings'),
        routeName: 'session.admin.customizations.brands.edit.index',
        dynamicSegments: [this.get('brand.id')],
        queryParams: null
      }, {
        label: this.get('i18n').t('admin.manage.brands.edit.tabs.email_templates'),
        routeName: 'session.admin.customizations.brands.edit.templates',
        dynamicSegments: [this.get('brand.id')],
        queryParams: null
      }];
    }),

    // Actions
    actions: {
      canceled: function canceled() {
        this.transitionToRoute('session.admin.customizations.brands.index');
      },
      updated: function updated() {
        var launchDarkly = this.get('launchDarkly');

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });
        this.initEdits();
        if (launchDarkly.get('release-event-tracking') && (this.get('editedBrand.alias') !== '' || this.get('editedBrand.alias') !== null)) {
          this.get('metrics').trackEvent({
            event: 'custom_domain_added',
            object: this.get('editedBrand.id'),
            brand: this.get('editedBrand.domain'),
            alias: this.get('editedBrand.alias')
          });
        }
        this.transitionToRoute('session.admin.customizations.brands.index');
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedBrand', this.get('virtualModel').makeSnapshot(this.get('brand'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('brand'), this.get('editedBrand'), _schema.default);
    }
  });
});