define('frontend-cp/components/ko-tab-strip/tabs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    'on-close': function onClose() {},

    tabComponentName: function tabComponentName(type) {
      if (type) {
        return 'ko-tab-strip/' + type + '-tab';
      }

      throw new Error('Unknown tab type: ' + type);
    }
  });
});