define('frontend-cp/login-admin/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    sessionService: service('session'),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var sessionService = this.get('sessionService');
      return sessionService.getSession().then(function () {
        _this.transitionTo('session.admin');
      }, function () {
        sessionService.set('loginRedirectPath', 'session.admin');
        _this.transitionTo('login-agent');
      });
    }
  });
});