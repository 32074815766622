define('frontend-cp/components/ko-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var set = Ember.set;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Params
    fields: EmberObject.create({}), // it's ok for this to be in the class definition since this object
    // is never mutated
    onSubmit: function onSubmit() {},
    onError: function onError() {},
    onSuccess: function onSuccess() {},
    onCancel: function onCancel() {},

    isSubmitting: false,

    notificationService: service('notification'),

    tagName: 'form',
    classNameBindings: ['isValid:ko-form--is-valid', 'isSubmitting:ko-form--is-submitting'],

    fieldsArray: computed('fields', function () {
      var fields = this.get('fields');
      return Object.keys(fields).map(function (key) {
        return fields[key];
      });
    }),

    fieldValuesArray: computed('fieldsArray.@each.value', function () {
      return this.get('fieldsArray').map(function (field) {
        return field.value;
      });
    }),

    fieldValidatorsArray: computed('fieldsArray.@each.validator', function () {
      return this.get('fieldsArray').map(function (field) {
        return field.validator;
      });
    }),

    isFormValid: computed('fieldValuesArray.[]', 'fieldValidatorsArray.[]', function () {
      // TODO: Fix flaky Ember watching
      this.get('fieldValuesArray');
      this.get('fieldValidatorsArray');

      var fields = this.get('fields');
      return getAreFieldsValid(fields);

      function getAreFieldsValid(fields) {
        return Object.keys(fields).every(function (key) {
          var field = fields[key];
          return isFieldValid(field.value, field.validator);
        });
      }

      function isFieldValid(value, validator) {
        if (!validator || !value) {
          return true;
        }
        var errors = validator(value);
        var hasErrors = errors && (typeof errors === 'string' || Array.isArray(errors) && errors.length > 0);
        return !hasErrors;
      }
    }),

    isSubmitDisabled: computed('isFormValid', 'isSubmitting', function () {
      return !this.get('isFormValid') || this.get('isSubmitting');
    }),

    submit: function submit(e) {
      var _this = this;

      e.preventDefault();
      e.stopPropagation();
      if (!this.get('isFormValid')) {
        return false;
      }
      if (this.get('isSubmitting')) {
        return false;
      }
      var fields = this.get('fields');

      var fieldValues = Object.keys(fields).reduce(function (fieldValues, key) {
        fieldValues[key] = fields[key].value;
        return fieldValues;
      }, {});
      this.set('isSubmitting', true);
      return this.attrs.onSubmit(fieldValues).then(function (response) {
        if (_this.attrs.onSuccess) {
          _this.attrs.onSuccess(response);
        }
        return response;
      }).catch(function (error) {
        Object.keys(fields).forEach(function (field) {
          set(get(fields, field), 'errors', []);
        });
        if (error && error.errors && error.errors.length) {
          error.errors.filter(function (errorData) {
            return Boolean(errorData.parameter);
          }).forEach(function (errorData) {
            var fieldName = errorData.parameter;
            var relatedField = get(fields, fieldName);
            if (relatedField) {
              relatedField.errors.pushObject(EmberObject.create({
                message: errorData.message
              }));
            }
          });
        }
        if (_this.attrs.onError) {
          _this.attrs.onError(error);
        }
      }).finally(function () {
        if (_this.get('isDestroying') || _this.get('isDestroyed')) {
          return;
        }
        _this.set('isSubmitting', false);
      });
    },


    actions: {
      cancel: function cancel() {
        this.attrs.onCancel();
      }
    }
  });
});