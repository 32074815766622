define('frontend-cp/services/permissions', ['exports', 'frontend-cp/lib/role-types'], function (exports, _roleTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var get = Ember.get;


  var adminOrAgentToCustomer = function adminOrAgentToCustomer(roleType, targetRoleType) {
    if (roleType.rank === _roleTypes.default.AGENT.rank) {
      return _roleTypes.default.CUSTOMER.rank === targetRoleType.rank;
    }

    return roleType.rank >= _roleTypes.default.ADMIN.rank;
  };

  /*
   * A map of system actions to a function that checks if the roleType provided
   * has the ability to carry out this action.
   * The actions should match values in `permissions` list for a given roleType
   *
   * The 1st argument of the function will always be the roleType of the current
   * logged in user. Any other arguments passed to the helper are provided as
   * a list.
   *
   * The function allows us to perform additional checks on top of the existence
   * of the permission e.g. 'app.delete.user' requires that the roleType.rank of
   * the logged in user be greater than that of the user they are trying to
   * delete.
   */
  var applicationActions = {
    'app.user.signature.edit': function appUserSignatureEdit(myRoleType, me, target) {

      // I can change my own signature or anyone's if I am an Owner
      return target && me === target || me.get('role.roleType') === 'OWNER';
    },
    'app.user.disable': function appUserDisable(roleType, subject, user) {
      var userRoleType = user.get('role.roleType');

      // I cannot disable myself!
      if (subject && user && subject === user) {
        return false;
      }
      return adminOrAgentToCustomer(roleType, _roleTypes.default[userRoleType]);
    },
    'app.user.password.change': function appUserPasswordChange(roleType, user, target) {
      // I can change my own password:
      if (user && target && user === target) {
        return true;
      }

      var userRoleType = user.get('role.roleType');
      return adminOrAgentToCustomer(roleType, userRoleType) && target.get('emails').toArray().length;
    },
    'app.organization.delete': function appOrganizationDelete(roleType, user, organization) {
      return roleType.rank >= _roleTypes.default.AGENT.rank;
    },
    'app.user.change_agent_access_permission': function appUserChange_agent_access_permission(myRoleType, me, targetUser) {
      return targetUser.get('role.roleType') !== 'CUSTOMER' && myRoleType.rank >= _roleTypes.default.ADMIN.rank;
    },
    'app.user.change_organization_access_permission': function appUserChange_organization_access_permission(myRoleType, me, targetUser) {
      return targetUser.get('role.roleType') === 'CUSTOMER' && myRoleType.rank >= _roleTypes.default.ADMIN.rank;
    },
    'app.user.change_team_permission': function appUserChange_team_permission(myRoleType, me, targetUser) {
      return targetUser.get('role.roleType') !== 'CUSTOMER' && myRoleType.rank >= _roleTypes.default.ADMIN.rank;
    },
    'app.user.view_team_permission': function appUserView_team_permission(myRoleType, me, targetUser) {
      return targetUser.get('role.roleType') !== 'CUSTOMER' && myRoleType.rank >= _roleTypes.default.COLLABORATOR.rank;
    },
    'app.user.change_role_permission': function appUserChange_role_permission(myRoleType, me, target) {
      // Cannot change myself
      if (me === target || get(target, 'isDeleted')) {
        return false;
      }
      return myRoleType.rank >= _roleTypes.default.ADMIN.rank && myRoleType.rank >= _roleTypes.default[target.get('role.roleType')].rank;
    },
    'app.admin.access': function appAdminAccess(myRoleType) {
      return myRoleType.rank >= _roleTypes.default.ADMIN.rank;
    },
    'app.user.post_private_note': function appUserPost_private_note() {
      return true;
    },
    'app.organization.post_private_note': function appOrganizationPost_private_note() {
      return true;
    }
  };

  exports.default = Service.extend({
    sessionService: service('session'),
    notificationService: service('notification'),
    i18n: service(),
    notificationHandler: service('error-handler/notification-strategy'),

    showError: function showError() {
      this.get('notificationHandler').processAll([{
        type: 'error',
        message: this.get('i18n').t('generic.permissions_denied'),
        sticky: false
      }]);
    },
    has: function has(action, target) {
      var role = this.get('sessionService.user.role');
      var subjectRoleType = _roleTypes.default[role.get('roleType')];
      var permissions = this.get('sessionService.permissions');

      // First check the permission exists
      if (subjectRoleType && subjectRoleType.permissions.indexOf(action) > -1) {
        // Then allow the permission function to run
        return applicationActions[action](subjectRoleType, this.get('sessionService.user'), target);
      }

      var roleRegex = new RegExp('(customer|collaborator|agent|admin|owner).', 'i');

      // Check role permissions
      return permissions.filter(function (perm) {
        // this is required as we want to compare only real permission name
        // excluding role type in the beginning of the string
        var permissionName = perm.get('name').replace(roleRegex, '');
        var actionName = action.replace(roleRegex, '');
        return permissionName === actionName && perm.get('value');
      }).length > 0;
    }
  });
});