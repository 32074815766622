define('frontend-cp/session/agent/insights/reporting/custom-reports/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['page'],
    page: 1,

    actions: {
      editReport: function editReport(report) {
        this.transitionToRoute('session.agent.insights.reporting.custom-reports.edit', report.get('id'));
      },
      newReport: function newReport() {
        this.transitionToRoute('session.agent.insights.reporting.custom-reports.new');
      }
    }
  });
});