define('frontend-cp/adapters/activity', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = new Ember.Inflector(Ember.Inflector.defaultRules);

  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      if (query.parent) {
        var id = query.parent.id;
        var pluralParentType = inflector.pluralize(query.parent.constructor.modelName);
        var url = this._super.apply(this, arguments);
        Reflect.deleteProperty(query, 'parent');
        return url.replace('/activities', '/' + pluralParentType + '/' + id + '/activities');
      }
      return this._super.apply(this, arguments);
    }
  });
});