define('frontend-cp/serializers/notification', ['exports', 'frontend-cp/serializers/application', 'frontend-cp/serializers/activity'], function (exports, _application, _activity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.resources && payload.resources.activity) {
        var obj = payload.resources.activity;
        Object.keys(obj).forEach(function (key) {
          return (0, _activity.addResourcesToActivity)(obj[key]);
        });
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});