define('frontend-cp/session/admin/automation/sla/new/controller', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    notification: service(),
    i18n: service(),
    editComponent: null,
    metrics: service(),

    // Actions
    actions: {
      cancel: function cancel() {
        this.transitionToRoute('session.admin.automation.sla.index');
      },
      created: function created() {
        var launchDarkly = this.get('launchDarkly');

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'sla_created'
          });
        }

        this.transitionToRoute('session.admin.automation.sla.index');
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.get('editComponent').initEdits();
    },
    isEdited: function isEdited() {
      return this.get('editComponent').isEdited();
    }
  });
});