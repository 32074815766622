define('frontend-cp/adapters/twitter-tweet', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      if (query.twitterIds) {
        var ids = query.twitterIds;
        Reflect.deleteProperty(query, 'twitterIds');
        return this.urlPrefix() + ('/twitter/tweets/latest?account_ids=' + ids);
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});