define("frontend-cp/components/ko-case-content/field/status/swatch-with-label/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-case-content_field_status_swatch-with-label__container_1rbmox",
    "swatch": "ko-case-content_field_status_swatch-with-label__swatch_1rbmox",
    "label": "ko-case-content_field_status_swatch-with-label__label_1rbmox",
    "prefix": "ko-case-content_field_status_swatch-with-label__prefix_1rbmox"
  };
});