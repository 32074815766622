define("frontend-cp/components/ko-admin/billing/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "section": "ko-admin_billing_index__section_dghg8j",
    "section-title": "ko-admin_billing_index__section-title_dghg8j",
    "section-body": "ko-admin_billing_index__section-body_dghg8j",
    "tile": "ko-admin_billing_index__tile_dghg8j"
  };
});