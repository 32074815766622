define('frontend-cp/session/admin/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    active: false,
    permissions: service(),

    beforeModel: function beforeModel(transition) {
      if (!this.get('permissions').has('app.admin.access')) {
        this.get('permissions').showError();
        this.transitionTo('session.agent');
      } else if (transition.queryParams.trial === 'true') {
        this.transitionTo('session.agent.welcome', { queryParams: { trial: 'true' } });
      }
      return RSVP.hash({
        locales: this.store.findAll('locale')
      });
    },
    activate: function activate() {
      this.set('active', true);
    },
    deactivate: function deactivate() {
      this.set('active', false);
    },


    actions: {
      loading: function loading(transition, originRoute) {
        var controller = this.controllerFor('session.admin');
        controller.set('isLoading', true);
        transition.promise.finally(function () {
          controller.set('isLoading', false);
        });
        return true;
      }
    }
  });
});