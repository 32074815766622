define('frontend-cp/session/agent/cases/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    afterModel: function afterModel() {
      return this.store.findAll('case-field');
    }
  });
});