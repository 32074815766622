define("frontend-cp/components/ko-admin/rateplans/summary/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "plan-notification": "ko-admin_rateplans_summary__plan-notification_paqoi0",
    "flex-empty-column": "ko-admin_rateplans_summary__flex-empty-column_paqoi0",
    "flex-content-column": "ko-admin_rateplans_summary__flex-content-column_paqoi0",
    "sticky": "ko-admin_rateplans_summary__sticky_paqoi0",
    "slidein": "ko-admin_rateplans_summary__slidein_paqoi0",
    "flex-box": "ko-admin_rateplans_summary__flex-box_paqoi0",
    "inline-loader": "ko-admin_rateplans_summary__inline-loader_paqoi0",
    "disabled": "ko-admin_rateplans_summary__disabled_paqoi0",
    "summary-label": "ko-admin_rateplans_summary__summary-label_paqoi0",
    "summary-content": "ko-admin_rateplans_summary__summary-content_paqoi0"
  };
});