define("frontend-cp/components/ko-info-bar/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-info-bar_item__container_1k1nl0",
    "containerActive": "ko-info-bar_item__containerActive_1k1nl0",
    "containerEdited": "ko-info-bar_item__containerEdited_1k1nl0",
    "containerKREEdited": "ko-info-bar_item__containerKREEdited_1k1nl0",
    "containerError": "ko-info-bar_item__containerError_1k1nl0",
    "containerDisabled": "ko-info-bar_item__containerDisabled_1k1nl0"
  };
});