define('frontend-cp/session/admin/channels/email/schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _virtualModel.model)('mailbox', {
    address: (0, _virtualModel.attr)(),
    brand: (0, _virtualModel.attr)()
  });
});