define("frontend-cp/components/ko-cases-list/column/conversation/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "conversation": "ko-cases-list_column_conversation__conversation_dioq2i",
    "avatar": "ko-cases-list_column_conversation__avatar_dioq2i",
    "text": "ko-cases-list_column_conversation__text_dioq2i",
    "firstRow": "ko-cases-list_column_conversation__firstRow_dioq2i",
    "requester": "ko-cases-list_column_conversation__requester_dioq2i",
    "secondRow": "ko-cases-list_column_conversation__secondRow_dioq2i",
    "subject": "ko-cases-list_column_conversation__subject_dioq2i",
    "preview": "ko-cases-list_column_conversation__preview_dioq2i",
    "bullet": "ko-cases-list_column_conversation__bullet_dioq2i",
    "ago": "ko-cases-list_column_conversation__ago_dioq2i",
    "status-icon": "ko-cases-list_column_conversation__status-icon_dioq2i",
    "unreadDot": "ko-cases-list_column_conversation__unreadDot_dioq2i",
    "attachments": "ko-cases-list_column_conversation__attachments_dioq2i"
  };
});