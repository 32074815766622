define('frontend-cp/utils/object', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.jsonToObject = jsonToObject;
  var EmberObject = Ember.Object;


  function convertValue(value) {
    if (_npmLodash.default.isArray(value)) {
      return convertArray(value);
    } else if (_npmLodash.default.isObject(value)) {
      return jsonToObject(value);
    } else {
      return value;
    }
  }

  function convertArray(array) {
    return array.map(convertValue);
  }

  function jsonToObject(object) {
    return EmberObject.create(_npmLodash.default.mapValues(object, convertValue));
  }
});