define('frontend-cp/services/metrics', ['exports', 'ember-metrics/services/metrics'], function (exports, _metrics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var service = Ember.inject.service;
  exports.default = _metrics.default.extend({
    session: service(),
    plan: service(),

    trackEvent: function trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var standardFields = {
        actor: this.get('session.user.uuid'),
        actor_role: this.get('session.user.role.roleType'),
        instance_name: this.get('session.session.instanceName'),
        plan_name: this.get('plan.name'),
        product: this.get('plan.product.productType'),
        source: 'WEBAPP'
      };
      var newOptions = Object.assign(options, standardFields);

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this.invoke.apply(this, ['trackEvent', newOptions].concat(_toConsumableArray(args)));
    }
  });
});