define('frontend-cp/session/admin/apps/manage/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    store: service(),

    model: function model(_ref) {
      var app_id = _ref.app_id;

      return this.get('store').findRecord('app', app_id);
    },
    setupController: function setupController(controller, app) {
      controller.set('app', app);
    }
  });
});