define("frontend-cp/components/ko-profile-menu/header/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-profile-menu_header__container_6sw7ik",
    "avatar": "ko-profile-menu_header__avatar_6sw7ik",
    "right": "ko-profile-menu_header__right_6sw7ik",
    "name": "ko-profile-menu_header__name_6sw7ik",
    "email": "ko-profile-menu_header__email_6sw7ik"
  };
});