define('frontend-cp/components/ko-admin/macros/edit/action-handlers/case-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    serialize: function serialize(action, macro, store) {
      this.reset(macro);

      if (action.value) {
        var caseType = store.peekRecord('case-type', action.value);
        macro.set('caseType', caseType);
      }

      return macro;
    },
    deserialize: function deserialize(macro, actions) {
      var caseType = macro.get('caseType');

      if (caseType) {
        actions.push({
          name: 'case-type',
          option: 'CHANGE',
          value: caseType.get('id').toString()
        });
      }

      return actions;
    },
    reset: function reset(macro) {
      macro.set('caseType', null);
    }
  };
});