define('frontend-cp/components/ko-entity-list/sidebar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    //Attrs
    definitions: null,
    currentPredicateCollections: null,
    currentPropositionOperators: null,
    currentCollectionOperator: null,

    //State
    oldPredicateCollections: null,

    //Serivces

    //Lifecycle Hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var oldPredicateCollections = this.get('oldPredicateCollections');
      var newPredicateCollections = this.get('currentPredicateCollections');

      if (oldPredicateCollections && oldPredicateCollections.values() !== newPredicateCollections.values()) {
        this.notifyPropertyChange('currentPredicateCollections');
      }

      this.set('oldPredicateCollections', new Set(newPredicateCollections));

      var oldPropositionOperators = this.get('oldPropositionOperators');
      var newPropositionOperators = this.get('currentPropositionOperators');

      if (oldPropositionOperators && oldPropositionOperators !== newPropositionOperators) {
        this.notifyPropertyChange('currentPropositionOperators');
      }

      this.set('oldPropositionOperators', newPropositionOperators);
    },


    //CP's
    collectionOperators: computed(function () {
      return ['OR', 'AND'];
    }),

    //Functions

    //Actions
    actions: {
      setCollectionOperator: function setCollectionOperator(operator) {
        this.sendAction('onCollectionOperatorChange', operator);
      }
    }
  });
});