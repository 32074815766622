define("frontend-cp/components/ko-merge-conversation-modal/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "main": "ko-merge-conversation-modal__main_e5zss7",
    "main-confirmation": "ko-merge-conversation-modal__main-confirmation_e5zss7",
    "header": "ko-merge-conversation-modal__header_e5zss7",
    "body": "ko-merge-conversation-modal__body_e5zss7",
    "button": "ko-merge-conversation-modal__button_e5zss7",
    "button-loading-container": "ko-merge-conversation-modal__button-loading-container_e5zss7",
    "selected-heading": "ko-merge-conversation-modal__selected-heading_e5zss7",
    "conversations-selected": "ko-merge-conversation-modal__conversations-selected_e5zss7",
    "buttons": "ko-merge-conversation-modal__buttons_e5zss7",
    "primary-conversation-container": "ko-merge-conversation-modal__primary-conversation-container_e5zss7",
    "primary-conversation-message": "ko-merge-conversation-modal__primary-conversation-message_e5zss7",
    "primary-conversation": "ko-merge-conversation-modal__primary-conversation_e5zss7"
  };
});