define('frontend-cp/state-managers/user', ['exports', 'ember-service/inject', 'npm:lodash', 'frontend-cp/lib/edited-custom-fields', 'ember-concurrency', 'frontend-cp/services/virtual-model', 'frontend-cp/utils/object', 'frontend-cp/lib/convert-errors-to-map', 'frontend-cp/lib/upload-file'], function (exports, _inject, _npmLodash, _editedCustomFields, _emberConcurrency, _virtualModel, _object, _convertErrorsToMap, _uploadFile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var EmberObject = Ember.Object;
  var observer = Ember.observer;
  var computed = Ember.computed;
  var get = Ember.get;
  var isEmpty = Ember.isEmpty;
  var scheduleOnce = Ember.run.scheduleOnce;
  var assign = Ember.assign;
  var service = Ember.inject.service;
  var Evented = Ember.Evented;


  var userSchema = (0, _virtualModel.model)('user', {
    isEnabled: (0, _virtualModel.attr)(),
    role: (0, _virtualModel.attr)(),
    timeZone: (0, _virtualModel.attr)(),
    locale: (0, _virtualModel.attr)(),
    fullName: (0, _virtualModel.attr)(),
    signature: (0, _virtualModel.attr)({ nonStrictMatching: true }),
    agentCaseAccess: (0, _virtualModel.attr)(),
    organizationCaseAccess: (0, _virtualModel.attr)(),
    customFields: (0, _virtualModel.many)((0, _virtualModel.fragment)('user-field-value', {
      field: (0, _virtualModel.attr)(),
      value: (0, _virtualModel.attr)()
    }))
  });

  var cleanupAttachments = function cleanupAttachments(attachments) {
    attachments.removeObjects(attachments.filter(function (attachment) {
      return !isEmpty(attachment.get('error'));
    }));
    return attachments;
  };

  exports.default = EmberObject.extend(Evented, {
    launchDarkly: (0, _inject.default)(),

    user: null,
    replyContent: '',
    editedTags: null,
    editedTeams: null,
    editedUser: null,
    errorMap: null,
    customFields: null,
    posts: null,
    bottomPostsAvailable: false,
    topPostsAvailable: true,
    loadingTop: false,
    loadingBottom: false,
    recentFeedback: [],
    isSaving: false,
    tab: null,
    attachedPostFiles: null,
    noteDestination: null,

    // Services - initialized outside
    i18n: service(),
    confirmation: service(),
    metrics: service(),
    notification: service(),
    permissions: service(),
    store: service(),
    tabStore: service(),
    tagsService: service('tags'),
    virtualModel: service(),
    agentCache: service('cache/agent-cache'),

    init: function init() {
      this._super.apply(this, arguments);
      this.resetEdits();
      var savedState = (0, _object.jsonToObject)(this.get('tab').state.user);

      // attachments should be re-created as correct UploadFile objects
      if (savedState.attachedPostFiles) {
        savedState.attachedPostFiles = savedState.attachedPostFiles.map(function (attachment) {
          return _uploadFile.default.create(attachment);
        });
      }

      this.setProperties(savedState);
      this.persistTabState();
    },


    // CP
    isBeingDeleted: computed('deleteUser.isRunning', 'user.isDeleted', function () {
      return this.get('deleteUser.isRunning') || this.get('user.isDeleted');
    }),

    hasUpdateUserPermission: computed(function () {
      return this.get('permissions').has('users.update');
    }),

    isAccessLevelEdited: computed('user.{agentCaseAccess,organizationCaseAccess}', 'editedUser.{agentCaseAccess,organizationCaseAccess}', 'isBeingDeleted', function () {
      if (this.get('isBeingDeleted')) {
        return false;
      }

      return this.get('user.agentCaseAccess') !== this.get('editedUser.agentCaseAccess') || this.get('user.organizationCaseAccess') !== this.get('editedUser.organizationCaseAccess');
    }),

    isContentEdited: computed('replyContent', 'isBeingDeleted', function () {
      if (this.get('isBeingDeleted')) {
        return false;
      }

      return this.get('replyContent').trim() !== '';
    }),

    isRoleEdited: computed('user.role', 'editedUser.role', 'isBeingDeleted', function () {
      if (this.get('isBeingDeleted')) {
        return false;
      }

      return this.get('user.role') !== this.get('editedUser.role');
    }),

    isTagsFieldEdited: computed('editedTags.@each.name', 'user.tags.@each.name', 'isBeingDeleted', function () {
      if (this.get('isBeingDeleted')) {
        return false;
      }

      var editedTags = this.get('editedTags').mapBy('name');
      var tags = this.get('user.tags').mapBy('name');
      return editedTags.length !== tags.length || _npmLodash.default.intersection(editedTags, tags).length !== tags.length;
    }),

    isTimezoneEdited: computed('user.timeZone', 'editedUser.timeZone', 'isBeingDeleted', function () {
      if (this.get('isBeingDeleted')) {
        return false;
      }

      return this.get('user.timeZone') !== this.get('editedUser.timeZone');
    }),

    isLocaleEdited: computed('user.locale.id', 'editedUser.locale.id', 'isBeingDeleted', function () {
      if (this.get('isBeingDeleted')) {
        return false;
      }

      return this.get('user.locale.id') !== this.get('editedUser.locale.id');
    }),

    isTeamsFieldEdited: computed('editedTeams.@each.id', 'user.teams.@each.id', 'isBeingDeleted', function () {
      if (this.get('isBeingDeleted')) {
        return false;
      }

      var editedTeams = this.get('editedTeams').mapBy('id');
      var teams = this.get('user.teams').mapBy('id');
      return editedTeams.length !== teams.length || _npmLodash.default.intersection(editedTeams, teams).length !== teams.length;
    }),

    isSignatureEdited: computed('user.signature', 'editedUser.signature', 'isBeingDeleted', function () {
      if (this.get('isBeingDeleted')) {
        return false;
      }

      return this.get('user.signature') !== this.get('editedUser.signature') && (this.get('user.signature') || this.get('editedUser.signature'));
    }),

    arePropertiesEdited: or('customFields.isEdited', 'isTagsFieldEdited', 'isTimezoneEdited', 'isRoleEdited', 'isAccessLevelEdited', 'isTeamsFieldEdited', 'isLocaleEdited'),

    isEdited: or('isContentEdited', 'arePropertiesEdited'),

    // We have to set this magic property, because after
    // save/restore stte operation, computed property will
    // stop updating.
    isEditedChanged: observer('isEdited', function () {
      scheduleOnce('sync', this, 'persistTabState');
    }),

    // Methods
    resetEdits: function resetEdits() {
      this.resetSidebar();
      this.setProperties({
        attachedPostFiles: [],
        replyContent: '',
        noteDestination: null
      });
    },
    resetSidebar: function resetSidebar() {
      var user = this.get('user');
      var editedUser = this.get('virtualModel').makeSnapshot(user, userSchema);

      this.setProperties({
        errorMap: EmberObject.create(),
        editedUser: editedUser,
        customFields: _editedCustomFields.default.create({
          originalCustomFields: get(user, 'customFields'),
          editedCustomFields: get(editedUser, 'customFields')
        }),
        editedTeams: get(user, 'teams').map(function (team) {
          return {
            id: team.get('id'),
            title: team.get('title')
          };
        }),
        editedTags: get(user, 'tags').map(function (tag) {
          return EmberObject.create({ name: tag.get('name'), isKREEdited: false, isNew: false });
        })
      });
    },
    persistTabState: function persistTabState() {
      this.get('tabStore').updateState(this.get('tab'), {
        user: assign(this.getProperties('replyContent', 'attachedPostFiles', 'noteDestination'), {
          _isEdited: this.get('isEdited')
        })
      });
    },
    resetPosts: function resetPosts(postId) {
      this.setProperties({
        posts: [],
        topPostsAvailable: true,
        bottomPostsAvailable: Boolean(postId)
      });
    },
    updatePostContent: function updatePostContent(newContent) {
      this.set('replyContent', newContent);
      this.persistTabState();
    },
    setRole: function setRole(role) {
      this.set('editedUser.role', role);
      this.set('errorMap.role_id', null);
    },
    setCustomField: function setCustomField(field, value) {
      if (value) {
        value = get(value, 'id') || value;
      }
      this.get('errorMap').set(field.get('key'), false);
      this.get('customFields').setValue(field, value);
    },
    setSignature: function setSignature(signature) {
      this.set('editedUser.signature', signature);
    },
    setOrganization: function setOrganization(org) {
      this.set('editedUser.organization', org);
      this.set('errorMap.organization_id', null);
    },
    setName: function setName(name) {
      this.set('editedUser.fullName', name);
    },
    setAgentAccessLevel: function setAgentAccessLevel(level) {
      this.set('editedUser.agentCaseAccess', level);
      this.set('editedUser.organizationCaseAccess', null);
      this.set('errorMap.agent_case_access', null);
    },
    setOrganizationAccessLevel: function setOrganizationAccessLevel(level) {
      this.set('editedUser.organizationCaseAccess', level);
      this.set('editedUser.agentCaseAccess', null);
      this.set('errorMap.organization_case_access', null);
    },
    setTimezone: function setTimezone(timezone) {
      this.set('editedUser.timeZone', timezone);
      this.set('errorMap.time_zone', null);
    },
    setLocale: function setLocale(locale) {
      this.set('editedUser.locale', locale);
      this.set('errorMap.locale', null);
    },
    addTeam: function addTeam(team) {
      if (!team.id) {
        return;
      }

      this.get('editedTeams').pushObject({
        id: team.get('id'),
        title: team.get('title'),
        isNew: !this.get('user.teams').find(function (t) {
          return t.get('id') === team.get('id');
        })
      });
      this.set('errorMap.team_ids', null);
    },
    removeTeam: function removeTeam(team) {
      this.get('editedTeams').removeObject(team);
      this.set('errorMap.team_ids', null);
    },
    addTag: function addTag(_ref) {
      var name = _ref.name,
          actualName = _ref.actualName;

      this.get('editedTags').pushObject({
        name: actualName || name,
        isNew: !this.get('user.tags').find(function (tag) {
          return tag.get('name') === (actualName || name);
        })
      });
      this.set('errorMap.tags', null);
    },
    removeTag: function removeTag(tag) {
      this.get('editedTags').removeObject(tag);
      this.set('errorMap.tags', null);
    },
    addAttachment: function addAttachment(attachment) {
      var attachments = cleanupAttachments(this.get('attachedPostFiles'));

      attachments.pushObject(attachment);
      this.set('attachedPostFiles', attachments);
      this.persistTabState();
    },
    cancelAttachment: function cancelAttachment(attachment) {
      var attachments = cleanupAttachments(this.get('attachedPostFiles'));

      attachments.removeObjects(attachments.filter(function (a) {
        return a === attachment;
      }));

      this.set('attachedPostFiles', attachments);
      this.persistTabState();
    },
    updateAttachments: function updateAttachments() {
      this.persistTabState();
    },
    isUploadInProgress: function isUploadInProgress() {
      var uploads = this.get('attachedPostFiles');
      return uploads.any(function (u) {
        return u.get('status') === 'PROGRESS';
      });
    },
    setNoteDestination: function setNoteDestination(destination) {
      this.set('noteDestination', destination);
      this.persistTabState();
    },


    toggleUserState: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var previousState;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              previousState = this.get('editedUser.isEnabled');


              this.set('editedUser.isEnabled', !previousState);

              _context.prev = 2;
              _context.next = 5;
              return this.get('save').perform('toggleUserState');

            case 5:
              _context.next = 11;
              break;

            case 7:
              _context.prev = 7;
              _context.t0 = _context['catch'](2);

              this.set('editedUser.isEnabled', previousState);

              throw _context.t0;

            case 11:

              if (this.get('user.role.isCollaboratorOrHigher')) {
                this.get('agentCache').invalidateCache();
              }

            case 12:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[2, 7]]);
    })),

    confirmRoleChange: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var CUSTOMER, oldRoleType, newRoleType;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              CUSTOMER = 'CUSTOMER';
              oldRoleType = this.get('user.role.roleType');
              newRoleType = this.get('editedUser.role.roleType');

              if (!(oldRoleType === CUSTOMER && newRoleType !== CUSTOMER)) {
                _context2.next = 6;
                break;
              }

              _context2.next = 6;
              return this.get('confirmation').confirm({
                intlConfirmLabel: 'users.change_role.from_customer_confirm_button',
                intlConfirmationBody: 'users.change_role.from_customer',
                intlConfirmationHeader: 'users.change_role.from_customer_header'
              });

            case 6:
              if (!(oldRoleType !== CUSTOMER && newRoleType === CUSTOMER)) {
                _context2.next = 9;
                break;
              }

              _context2.next = 9;
              return this.get('confirmation').confirm({
                intlConfirmLabel: 'users.change_role.to_customer_confirm_button',
                intlConfirmationBody: 'users.change_role.to_customer',
                intlConfirmationHeader: 'users.change_role.to_customer_header'
              });

            case 9:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    submit: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var task;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return this.get('confirmRoleChange').perform();

            case 2:
              task = this.get('isContentEdited') ? 'saveEverything' : 'updateProperties';
              _context3.next = 5;
              return this.get(task).perform();

            case 5:
              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('users.user.updated'),
                autodismiss: true
              });
              this.resetEdits();
              this.persistTabState();

            case 8:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).drop(),

    updateProperties: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              _context4.next = 2;
              return this.get('save').perform();

            case 2:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })).drop(),

    saveEverything: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5() {
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              _context5.next = 2;
              return this.get('save').perform();

            case 2:
              _context5.next = 4;
              return this.get('postNote').perform();

            case 4:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })).drop(),

    postNote: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee6() {
      var uploads, attachmentIds, contents;
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              if (!this.isUploadInProgress()) {
                _context6.next = 3;
                break;
              }

              this.get('notification').add({
                type: 'warning',
                title: 'Upload in progress',
                autodismiss: true
              });
              return _context6.abrupt('return');

            case 3:
              uploads = this.get('attachedPostFiles').filter(function (attachment) {
                return isEmpty(attachment.get('error'));
              });
              attachmentIds = uploads.mapBy('attachmentId').compact();
              contents = this.get('replyContent').trim();
              _context6.next = 8;
              return this.get('store').createRecord('user-note', {
                contents: contents,
                user: this.get('user'),
                attachmentFileIds: attachmentIds
              }).save();

            case 8:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    })),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee7(callee) {
      var _this = this;

      var launchDarkly, user, originalTags, originalTeams, tagsService, tags, teams, changeAffectsAgentList, options;
      return regeneratorRuntime.wrap(function _callee7$(_context7) {
        while (1) {
          switch (_context7.prev = _context7.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');

              if (this.get('hasUpdateUserPermission')) {
                _context7.next = 3;
                break;
              }

              return _context7.abrupt('return');

            case 3:
              user = this.get('user');
              originalTags = user.get('tags').map(function (tag) {
                return tag;
              });
              originalTeams = user.get('teams').map(function (team) {
                return team;
              });
              tagsService = this.get('tagsService');
              tags = this.get('editedTags').map(function (tag) {
                return tagsService.getTagByName(get(tag, 'name'));
              });
              teams = this.get('editedTeams').map(function (team) {
                return _this.get('store').peekRecord('team', get(team, 'id'));
              });


              user.setProperties({ tags: tags, teams: teams });

              changeAffectsAgentList = this.shouldBreakAgentCache(user, this.get('editedUser'));
              _context7.prev = 11;
              options = {
                adapterOptions: {}
              };

              if (callee === 'toggleUserState') {
                options.adapterOptions.toggleUser = true;
              }
              _context7.next = 16;
              return this.get('virtualModel').save(user, this.get('editedUser'), userSchema, options);

            case 16:
              _context7.next = 18;
              return user.get('tags').reload();

            case 18:
              user.set('tags', user.get('tags').filterBy('isNew', false));

              if (changeAffectsAgentList) {
                this.get('agentCache').invalidateCache();
              }

              this.persistTabState();
              if (launchDarkly.get('release-apps')) {
                this.trigger('updated');
              }
              _context7.next = 29;
              break;

            case 24:
              _context7.prev = 24;
              _context7.t0 = _context7['catch'](11);

              this.set('errorMap', (0, _convertErrorsToMap.default)(_context7.t0.errors));
              user.setProperties({
                tags: originalTags,
                teams: originalTeams
              });
              throw _context7.t0;

            case 29:
            case 'end':
              return _context7.stop();
          }
        }
      }, _callee7, this, [[11, 24]]);
    })).drop(),

    deleteUser: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee8() {
      return regeneratorRuntime.wrap(function _callee8$(_context8) {
        while (1) {
          switch (_context8.prev = _context8.next) {
            case 0:
              _context8.next = 2;
              return this.get('user').destroyRecord();

            case 2:
            case 'end':
              return _context8.stop();
          }
        }
      }, _callee8, this);
    })).drop(),

    shouldBreakAgentCache: function shouldBreakAgentCache(originalUser, newUser) {
      if (originalUser.get('role.isCollaboratorOrHigher') !== newUser.get('role.isCollaboratorOrHigher')) {
        return true;
      }
    }
  });
});