define("frontend-cp/components/ko-admin/sla/edit/targets/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "row": "ko-admin_sla_edit_targets__row_4rzctl",
    "column": "ko-admin_sla_edit_targets__column_4rzctl"
  };
});