define('frontend-cp/models/oauth-client', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data-model-fragments'], function (exports, _model, _attr, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    key: (0, _attr.default)('string'),
    secret: (0, _attr.default)('string'),
    scopes: (0, _attr.default)('array'),
    description: (0, _attr.default)('string'),
    author: (0, _attr.default)('string'),
    author_url: (0, _attr.default)('string'),
    logo: _emberDataModelFragments.default.fragment('oauth-client-logo', { async: false }),
    lastUsedAt: (0, _attr.default)('date'),

    logoFileId: (0, _attr.default)('string')
  });
});