define('frontend-cp/components/ko-agent-dropdown/create-organization/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    onCreate: function onCreate() {},
    onCancel: function onCancel() {},

    // State
    fields: null,
    organization: null,

    // Services
    store: service(),

    // CPs
    domains: computed('fields.domains.value.[]', function () {
      return this.get('fields.domains.value').map(function (domain) {
        return {
          name: domain.get('domain')
        };
      });
    }),

    init: function init() {
      this._super();
      this.set('organization', this.get('store').createRecord('organization'));

      this.set('fields', EmberObject.create({
        domains: {
          value: []
        }
      }));
    },


    actions: {
      addDomain: function addDomain(domain) {
        var domainRecord = this.get('store').createRecord('identity-domain', { domain: get(domain, 'name') });
        this.get('fields.domains.value').pushObject(domainRecord);
      },
      removeDomain: function removeDomain(domain) {
        var domainName = get(domain, 'name');
        this.get('fields.domains.value').removeObject(this.get('fields.domains.value').find(function (domain) {
          return get(domain, 'domain') === domainName;
        }));
      },
      submit: function submit() {
        var _this = this;

        var organization = this.get('organization');
        var onSubmit = this.get('onSubmit');
        organization.set('domains', this.get('fields.domains.value'));
        if (onSubmit) {
          onSubmit();
        }
        return organization.save().then(function (organization) {
          organization.set('domains', organization.get('domains').filter(function (domain) {
            return domain.get('id');
          }));
          _this.get('dropdown.actions').close();

          return organization;
        });
      }
    }
  });
});