define('frontend-cp/adapters/credential', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    headers: {
      Accept: 'application/json',
      'X-Options': 'flat',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
});