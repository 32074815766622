define('frontend-cp/models/session', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    portal: _emberData.default.attr('string'),
    ipAddress: _emberData.default.attr('string'),
    userAgent: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user', { async: false }),
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    lastActivityAt: _emberData.default.attr('date'),
    csrfToken: _emberData.default.attr('string'),
    rememberMeToken: _emberData.default.attr('string'),
    instanceId: _emberData.default.attr('string'),
    instanceName: _emberData.default.attr('string')
  });
});