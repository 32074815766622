define('frontend-cp/services/merge-conversation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var EmberPromise = Ember.RSVP.Promise;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    i18n: service(),
    activeMerge: null,
    confirmationPromise: null,

    /* Store functions to accept/reject current confirmation promise */
    _acceptMerge: null,
    _rejectMerge: null,

    confirm: function confirm(_ref) {
      var _this = this;

      var _ref$intlMergeHeader = _ref.intlMergeHeader,
          intlMergeHeader = _ref$intlMergeHeader === undefined ? 'cases.merge_conversation.modal.header' : _ref$intlMergeHeader,
          _ref$intlMergeConfirm = _ref.intlMergeConfirmationHeader,
          intlMergeConfirmationHeader = _ref$intlMergeConfirm === undefined ? 'cases.merge_conversation.modal.merge_confirmation_header' : _ref$intlMergeConfirm,
          _ref$intlMergeLabel = _ref.intlMergeLabel,
          intlMergeLabel = _ref$intlMergeLabel === undefined ? 'cases.merge_conversation.modal.button' : _ref$intlMergeLabel,
          _ref$intlNextLabel = _ref.intlNextLabel,
          intlNextLabel = _ref$intlNextLabel === undefined ? 'generic.next' : _ref$intlNextLabel,
          _ref$intlSelectedLabe = _ref.intlSelectedLabel,
          intlSelectedLabel = _ref$intlSelectedLabe === undefined ? 'generic.selected' : _ref$intlSelectedLabe,
          _ref$intlBackLabel = _ref.intlBackLabel,
          intlBackLabel = _ref$intlBackLabel === undefined ? 'generic.back' : _ref$intlBackLabel,
          _ref$intlConversation = _ref.intlConversationsSelectedLabel,
          intlConversationsSelectedLabel = _ref$intlConversation === undefined ? 'cases.merge_conversation.modal.conversations_selected' : _ref$intlConversation,
          _ref$intlPrimaryConve = _ref.intlPrimaryConversationMessageBold,
          intlPrimaryConversationMessageBold = _ref$intlPrimaryConve === undefined ? 'cases.merge_conversation.modal.primary_conversation_message_bold' : _ref$intlPrimaryConve,
          _ref$intlPrimaryConve2 = _ref.intlPrimaryConversationMessageEnd,
          intlPrimaryConversationMessageEnd = _ref$intlPrimaryConve2 === undefined ? 'cases.merge_conversation.modal.primary_conversation_message_end' : _ref$intlPrimaryConve2,
          requesterName = _ref.requesterName,
          currentCase = _ref.currentCase,
          cases = _ref.cases,
          skipSelection = _ref.skipSelection;

      var selectedCases = [];

      if (cases) {
        var _cases$sortBy = cases.sortBy('createdAt'),
            _cases$sortBy2 = _toArray(_cases$sortBy),
            primary = _cases$sortBy2[0],
            auxiliary = _cases$sortBy2.slice(1);

        currentCase = primary;
        selectedCases = auxiliary;
      }

      this.set('activeMerge', {
        header: intlMergeHeader ? this.get('i18n').t(intlMergeHeader) : null,
        mergeConfirmationHeader: this.get('i18n').t(intlMergeConfirmationHeader),
        mergeLabel: this.get('i18n').t(intlMergeLabel),
        nextLabel: this.get('i18n').t(intlNextLabel),
        selectedLabel: this.get('i18n').t(intlSelectedLabel),
        backLabel: this.get('i18n').t(intlBackLabel),
        conversationsSelectedLabel: this.get('i18n').t(intlConversationsSelectedLabel),
        primaryConversationMessageBold: this.get('i18n').t(intlPrimaryConversationMessageBold),
        primaryConversationMessageEnd: this.get('i18n').t(intlPrimaryConversationMessageEnd),
        requesterName: requesterName,
        currentCase: currentCase,
        selectedCases: selectedCases,
        skipSelection: skipSelection
      });

      return new EmberPromise(function (accept, cancel) {
        _this.set('_acceptMerge', accept);
        _this.set('_rejectMerge', cancel);
      }).finally(function () {
        _this.set('activeMerge', null);
        _this.set('_acceptMerge', null);
        _this.set('_rejectMerge', null);
      });
    },
    cancelMerge: function cancelMerge() {
      this.get('_rejectMerge').apply(undefined, arguments);
    },
    acceptMerge: function acceptMerge() {
      this.get('_acceptMerge').apply(undefined, arguments);
    }
  });
});