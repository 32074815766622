define('frontend-cp/session/admin/people/teams/new/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)('team'), {
    model: function model() {
      var _this = this;

      return this.store.findAll('business-hour', { reload: true }).then(function (businessHours) {
        var businesshour = businessHours.findBy('isDefault', true);
        var team = _this.store.createRecord('team', { businesshour: businesshour });

        return { team: team, businessHours: businessHours };
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      controller.initEdits();
    }
  });
});