define('frontend-cp/components/ko-insights/metric/component', ['exports', 'frontend-cp/components/ko-insights/metric/styles'], function (exports, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    i18n: service(),
    tagName: '',

    // Attributes
    isLoading: false,
    section: null,
    type: null,
    name: null,
    value: null,
    previous: null,
    delta: null,
    deltaInverted: false,
    first: false,

    deltaTooltip: computed('type', 'value', 'previous', function () {
      var type = this.get('type');
      var value = this.get('value');
      var previous = this.get('previous');

      var valueFormatted = type === 'number' ? this.get('i18n').formatNumber(value) : value;
      var previousFormatted = type === 'number' ? this.get('i18n').formatNumber(previous) : previous;

      return valueFormatted + ' ' + this.get('i18n').t('insights.insights.vs') + ' ' + (previousFormatted === null ? '-' : previousFormatted);
    }),

    deltaRounded: computed('delta', function () {
      return Math.round(this.get('delta'));
    }),

    triangleStyle: computed('deltaInverted', 'delta', function () {
      var delta = this.get('delta');
      var inverted = this.get('deltaInverted');

      if (delta > 0 && !inverted) {
        return _styles.default.triangleUpPositive;
      } else if (delta < 0 && !inverted) {
        return _styles.default.triangleDownNegative;
      } else if (delta > 0 && inverted) {
        return _styles.default.triangleUpNegative;
      } else if (delta < 0 && inverted) {
        return _styles.default.triangleDownPositive;
      }
    }),

    deltaStyle: computed('deltaInverted', 'delta', function () {
      var delta = this.get('delta');
      var inverted = this.get('deltaInverted');

      if (delta < 0 && !inverted || delta > 0 && inverted) {
        return _styles.default.deltaNegative;
      }
    }),

    title: computed('name', function () {
      if (this.get('name')) {
        return this.get('i18n').t('insights.metric.' + this.get('name'));
      }

      return '';
    }),

    titleTooltip: computed('name', 'section', function () {
      if (this.get('name')) {
        return this.get('i18n').t('insights.metric.tooltip.' + this.get('section') + '.' + this.get('name'));
      }

      return '';
    })
  });
});