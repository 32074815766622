define('frontend-cp/services/appcues', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;


  var NullAppcues = {
    identify: function identify() {},
    start: function start() {},
    track: function track() {}
  };

  exports.default = Service.extend({
    session: service(),
    plan: service(),

    appcues: computed(function () {
      var Appcues = window.Appcues;

      if (Ember.testing || !Appcues) {
        return NullAppcues;
      } else {
        return Appcues;
      }
    }),

    identify: function identify() {
      var user = this.get('session.user');

      if (!user) {
        return;
      }

      var props = {
        name: user.get('fullName'),
        email: user.get('primaryEmailAddress'),
        role: user.get('role.roleType'),

        created_at: dateToUnixTimestamp(user.get('createdAt')),
        last_seen: dateToUnixTimestamp(user.get('lastSeenAt')),
        last_active: dateToUnixTimestamp(user.get('lastActiveAt')),
        last_activity: dateToUnixTimestamp(user.get('lastActivityAt')),
        last_logged_in_at: dateToUnixTimestamp(user.get('lastLoggedInAt')),

        instance_name: this.get('session.session.instanceName'),
        instance_id: this.get('session.session.instanceId'),
        is_trial: this.get('plan.isTrial'),
        expiry: dateToUnixTimestamp(this.get('plan.expiryAt')),

        account_id: this.get('plan.accountId'),
        lead_id: this.get('plan.leadId'),
        plan_name: this.get('plan.name'),
        opportunity_id: this.get('plan.opportunityId'),
        subscription_id: this.get('plan.subscriptionId'),
        rateplan_id: this.get('plan.rateplanId'),
        product_id: this.get('plan.product.id'),
        is_grandfathered: this.get('plan.isGrandfathered')
      };

      this.get('appcues').identify(user.get('uuid'), props);
    },
    start: function start() {
      this.get('appcues').start();
    },
    track: function track() {
      var _get;

      (_get = this.get('appcues')).track.apply(_get, arguments);
    }
  });


  function dateToUnixTimestamp(date) {
    if (!date) {
      return null;
    }
    return Math.round(date.getTime() / 1000);
  }
});