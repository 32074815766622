define('frontend-cp/session/admin/manage/case-forms/new/controller', ['exports', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/manage/case-forms/schema'], function (exports, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    notification: service('notification'),
    i18n: service(),
    virtualModel: service(),

    schema: _schema.default,

    initEdits: function initEdits() {
      this.set('editedCaseForm', this.get('virtualModel').makeSnapshot(this.get('caseForm'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('caseForm'), this.get('editedCaseForm'), _schema.default);
    },


    actions: {
      created: function created() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        this.transitionToRoute('session.admin.manage.case-forms.index');
      },
      redirectToIndex: function redirectToIndex() {
        this.transitionToRoute('session.admin.manage.case-forms.index');
      }
    }
  });
});