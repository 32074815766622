define('frontend-cp/routes/abstract/tabbed-route-child', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    // Actions
    actions: {
      // When a child route of a tabbed route is activated it updates the routeName
      // of the tab to point to it.
      didTransition: function didTransition() {
        this.send('subrouteDidRender', this.routeName);
        return true;
      }
    }
  });
});