define('frontend-cp/components/apps-dev-mode-ribbon/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    apps: service(),

    actions: {
      exitDevMode: function exitDevMode(e) {
        e.preventDefault();
        window.location = window.location.pathname; // reload and strip any query params
      }
    }
  });
});