define('frontend-cp/metrics-adapters/segment', ['exports', 'ember-metrics/metrics-adapters/segment', 'ember-metrics/utils/object-transforms'], function (exports, _segment, _objectTransforms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _segment.default.extend({
    group: function group() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var groupId = options.groupId;
      Reflect.deleteProperty(options, 'groupId');

      window.analytics.group(groupId, (0, _objectTransforms.compact)(options));
    }
  });
});