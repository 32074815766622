define("frontend-cp/components/ko-notification-center/notification-case-updated/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "avatar-container": "ko-notification-center_notification-case-updated__avatar-container_izkq81",
    "for-breach": "ko-notification-center_notification-case-updated__for-breach_izkq81",
    "avatar": "ko-notification-center_notification-case-updated__avatar_izkq81"
  };
});