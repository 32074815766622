define('frontend-cp/components/ko-timeline-2/list/item/menu/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    qaClass: null,
    title: '',
    disabled: false,
    onClick: function onClick() {},
    onClose: function onClose() {},

    actions: {
      clicked: function clicked() {
        if (!this.get('disabled')) {
          this.get('onClose').apply(undefined, arguments);
          this.get('onClick').apply(undefined, arguments);
        }
      }
    }
  });
});