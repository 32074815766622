define("frontend-cp/components/ko-on-boarding/setup/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "smoothTransition": "cubic-bezier(0.19, 1, 0.22, 1)",
    "box": "ko-on-boarding_setup__box_9z2s9j",
    "box__number": "ko-on-boarding_setup__box__number_9z2s9j",
    "box__header": "ko-on-boarding_setup__box__header_9z2s9j",
    "main-heading": "ko-on-boarding_setup__main-heading_9z2s9j",
    "box__content": "ko-on-boarding_setup__box__content_9z2s9j",
    "item": "ko-on-boarding_setup__item_9z2s9j",
    "item__text": "ko-on-boarding_setup__item__text_9z2s9j",
    "item__title": "ko-on-boarding_setup__item__title_9z2s9j",
    "is-done": "ko-on-boarding_setup__is-done_9z2s9j",
    "item__description": "ko-on-boarding_setup__item__description_9z2s9j",
    "item__complete": "ko-on-boarding_setup__item__complete_9z2s9j",
    "modal-section": "ko-on-boarding_setup__modal-section_9z2s9j",
    "is-active": "ko-on-boarding_setup__is-active_9z2s9j",
    "is-aligned": "ko-on-boarding_setup__is-aligned_9z2s9j",
    "transition-delay-1": "ko-on-boarding_setup__transition-delay-1_9z2s9j",
    "transition-delay-2": "ko-on-boarding_setup__transition-delay-2_9z2s9j",
    "no-transition": "ko-on-boarding_setup__no-transition_9z2s9j",
    "modal-section--spaced": "ko-on-boarding_setup__modal-section--spaced_9z2s9j",
    "modal-section--narrow": "ko-on-boarding_setup__modal-section--narrow_9z2s9j",
    "modal-section--wide": "ko-on-boarding_setup__modal-section--wide_9z2s9j",
    "modal-section--low-scale": "ko-on-boarding_setup__modal-section--low-scale_9z2s9j",
    "modal-section--allset": "ko-on-boarding_setup__modal-section--allset_9z2s9j",
    "add-agents-fields": "ko-on-boarding_setup__add-agents-fields_9z2s9j",
    "narrow-success-message": "ko-on-boarding_setup__narrow-success-message_9z2s9j",
    "modal-section__wrapper": "ko-on-boarding_setup__modal-section__wrapper_9z2s9j",
    "modal-section__content": "ko-on-boarding_setup__modal-section__content_9z2s9j",
    "modal-section__social": "ko-on-boarding_setup__modal-section__social_9z2s9j",
    "heading": "ko-on-boarding_setup__heading_9z2s9j",
    "label": "ko-on-boarding_setup__label_9z2s9j",
    "paired-buttons": "ko-on-boarding_setup__paired-buttons_9z2s9j",
    "messenger-preview": "ko-on-boarding_setup__messenger-preview_9z2s9j",
    "messenger-right-text": "ko-on-boarding_setup__messenger-right-text_9z2s9j",
    "messenger-right-lede-text": "ko-on-boarding_setup__messenger-right-lede-text_9z2s9j",
    "messenger-text-transition": "ko-on-boarding_setup__messenger-text-transition_9z2s9j",
    "messenger-text-in": "ko-on-boarding_setup__messenger-text-in_9z2s9j",
    "list": "ko-on-boarding_setup__list_9z2s9j",
    "list-item": "ko-on-boarding_setup__list-item_9z2s9j",
    "allset-tick": "ko-on-boarding_setup__allset-tick_9z2s9j",
    "light-text": "ko-on-boarding_setup__light-text_9z2s9j",
    "gray-box": "ko-on-boarding_setup__gray-box_9z2s9j",
    "gray-box__text": "ko-on-boarding_setup__gray-box__text_9z2s9j",
    "button-text": "ko-on-boarding_setup__button-text_9z2s9j",
    "next-step": "ko-on-boarding_setup__next-step_9z2s9j",
    "download-container": "ko-on-boarding_setup__download-container_9z2s9j",
    "note": "ko-on-boarding_setup__note_9z2s9j",
    "space-between": "ko-on-boarding_setup__space-between_9z2s9j",
    "copy-button": "ko-on-boarding_setup__copy-button_9z2s9j",
    "input-addon": "ko-on-boarding_setup__input-addon_9z2s9j",
    "facebook-pages": "ko-on-boarding_setup__facebook-pages_9z2s9j",
    "facebook-pages__item": "ko-on-boarding_setup__facebook-pages__item_9z2s9j",
    "facebook-pages-loader": "ko-on-boarding_setup__facebook-pages-loader_9z2s9j",
    "no-pages": "ko-on-boarding_setup__no-pages_9z2s9j",
    "team-column": "ko-on-boarding_setup__team-column_9z2s9j",
    "team__label": "ko-on-boarding_setup__team__label_9z2s9j",
    "team-field": "ko-on-boarding_setup__team-field_9z2s9j",
    "add-more": "ko-on-boarding_setup__add-more_9z2s9j",
    "remove-team": "ko-on-boarding_setup__remove-team_9z2s9j"
  };
});