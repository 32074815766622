define('frontend-cp/session/admin/people/businesshours/edit/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)(), {
    model: function model(params) {
      return this.get('store').findRecord('business-hour', params.businesshour_id);
    },
    setupController: function setupController(controller, businessHour) {
      controller.setProperties({ businessHour: businessHour });
      controller.initEdits();
    }
  });
});