define('frontend-cp/components/ko-admin/triggers/form/component', ['exports', 'frontend-cp/services/virtual-model', 'ember-diff-attrs'], function (exports, _virtualModel, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var gt = Ember.computed.gt;
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;


  var schema = (0, _virtualModel.model)('trigger', {
    id: (0, _virtualModel.attr)(),
    title: (0, _virtualModel.attr)(),
    executionOrder: (0, _virtualModel.attr)(),
    channel: (0, _virtualModel.attr)(),
    event: (0, _virtualModel.attr)(),
    predicateCollections: (0, _virtualModel.many)((0, _virtualModel.model)('predicate-collection', {
      id: (0, _virtualModel.attr)(),
      propositions: (0, _virtualModel.many)((0, _virtualModel.model)('proposition', {
        id: (0, _virtualModel.attr)(),
        field: (0, _virtualModel.attr)(),
        operator: (0, _virtualModel.attr)(),
        value: (0, _virtualModel.attr)()
      }))
    })),
    actions: (0, _virtualModel.many)((0, _virtualModel.model)('automation-action', {
      label: (0, _virtualModel.attr)(),
      name: (0, _virtualModel.attr)(),
      option: (0, _virtualModel.attr)(),
      value: (0, _virtualModel.attr)(),
      attributes: (0, _virtualModel.attr)()
    })),
    isEnabled: (0, _virtualModel.attr)(),
    createdAt: (0, _virtualModel.attr)(),
    updatedAt: (0, _virtualModel.attr)()
  });

  exports.default = Component.extend({
    // Attributes
    theTrigger: null,
    channels: null,
    definitions: null,
    registerAs: function registerAs() {},
    didSave: function didSave() {},
    cancel: function cancel() {},

    // Services
    virtualModel: service(),
    confirmation: service(),

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.get('registerAs')(this);
    },


    didReceiveAttrs: (0, _emberDiffAttrs.default)('theTrigger', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);
      if (!changedAttrs || changedAttrs.theTrigger) {
        this.initEdits();
      }
    }),

    // CP's
    channelList: computed('channels.[]', function () {
      var channels = this.get('channels').toArray();
      var any = EmberObject.create({ id: null });
      return [any].concat(_toConsumableArray(channels));
    }),

    selectedChannelEvents: computed('editedTrigger.channel', function () {
      var _this = this;

      var currentlySelectedChannel = this.get('channels').find(function (channel) {
        return channel.get('id') === _this.get('editedTrigger.channel');
      });

      if (!currentlySelectedChannel) {
        return [];
      }

      var events = currentlySelectedChannel.get('events').toArray().map(function (id) {
        return EmberObject.create({ id: id });
      });

      var any = EmberObject.create({ id: null });
      return [any].concat(_toConsumableArray(events));
    }),

    channelHasMultipleEvents: gt('selectedChannelEvents.length', 1),

    filteredDefinitions: computed('definitions', 'editedTrigger.channel', function () {
      var definitions = this.get('definitions');
      var channel = this.get('editedTrigger.channel');
      var channelsToFilter = ['TWITTER', 'MAIL', 'FACEBOOK', 'SYSTEM', 'MESSENGER', 'API'];

      definitions.forEach(function (definition) {
        if (definition.get('group') === channel || !channelsToFilter.includes(definition.get('group'))) {
          definition.set('disabled', false);
        } else {
          definition.set('disabled', true);
        }
      });

      return definitions;
    }),

    canBeDeleted: computed('theTrigger.isNew', function () {
      return !this.get('theTrigger.isNew');
    }),

    // Actions
    actions: {
      setChannel: function setChannel(channel) {
        this.set('editedTrigger.channel', channel.get('id'));
        this.set('editedTrigger.event', null);
      },
      setEvent: function setEvent(event) {
        this.set('editedTrigger.event', event.get('id'));
      },
      addCollection: function addCollection() {
        this.get('editedTrigger.predicateCollections').pushObject({
          propositions: [{}]
        });
      },
      removeCollection: function removeCollection(predicateCollection) {
        this.get('editedTrigger.predicateCollections').removeObject(predicateCollection);
      },
      addPropositionToCollection: function addPropositionToCollection(predicateCollection) {
        get(predicateCollection, 'propositions').pushObject({});
      },
      removePropositionFromCollection: function removePropositionFromCollection(predicateCollection, proposition) {
        get(predicateCollection, 'propositions').removeObject(proposition);
      },
      addAction: function addAction() {
        this.get('editedTrigger.actions').pushObject({});
      },
      removeAction: function removeAction(action) {
        this.get('editedTrigger.actions').removeObject(action);
      },
      save: function save() {
        var _this2 = this;

        var trigger = this.get('theTrigger');
        var editedTrigger = this.get('editedTrigger');

        return this.get('virtualModel').save(trigger, editedTrigger, schema).then(function () {
          trigger.get('predicateCollections').toArray().forEach(function (predColl) {
            if (predColl.get('isNew')) {
              predColl.get('propositions').invoke('unloadRecord');
              predColl.unloadRecord();
            } else {
              predColl.get('propositions').filterBy('isNew').invoke('unloadRecord');
            }
          });
          trigger.get('actions').filterBy('isNew').invoke('unloadRecord');
          _this2.set('editedTrigger', _this2.get('virtualModel').makeSnapshot(_this2.get('theTrigger'), schema));
        }).then(this.get('didSave'));
      },
      deleteTrigger: function deleteTrigger() {
        var _this3 = this;

        return this.get('confirmation').confirm({
          intlConfirmationBody: 'admin.triggers.labels.delete_confirmation',
          intlConfirmationHeader: 'admin.triggers.labels.confirm_delete',
          intlConfirmLabel: 'generic.confirm.delete_button'
        }).then(function () {
          return _this3.get('theTrigger').destroyRecord().then(function () {
            return _this3.attrs.didSave();
          });
        });
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedTrigger', this.get('virtualModel').makeSnapshot(this.get('theTrigger'), schema));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('theTrigger'), this.get('editedTrigger'), schema);
    }
  });
});