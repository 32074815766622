define('frontend-cp/session/admin/customizations/brands/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    // Services
    i18n: service(),
    notification: service(),
    confirmation: service(),
    plan: service(),

    // CPs
    enabledBrands: computed('model.@each.{isEnabled,name}', function () {
      return this.get('model').filterBy('isEnabled', true).sortBy('name');
    }),
    disabledBrands: computed('model.@each.{isEnabled,name}', function () {
      return this.get('model').filterBy('isEnabled', false).sortBy('name');
    }),

    reachedLimit: computed('model', function () {
      return this.get('plan').limitFor('brands') <= this.get('model.meta.total');
    }),

    actions: {
      transitionToAddNewBrands: function transitionToAddNewBrands() {
        this.transitionToRoute('session.admin.customizations.brands.new');
      },
      editBrand: function editBrand(brand) {
        this.transitionToRoute('session.admin.customizations.brands.edit', brand.id);
      },
      toggleEnabledProperty: function toggleEnabledProperty(brand, e) {
        var _this = this;

        e.stopPropagation();
        brand.toggleProperty('isEnabled');
        brand.save().then(function () {
          var notificationMessage = _this.get('i18n').t(brand.get('isEnabled') ? 'admin.brands.enabled.message' : 'admin.brands.disabled.message');
          _this.get('notification').success(notificationMessage);
        }).catch(function () {
          brand.rollbackAttributes();
        });
      },
      makeDefault: function makeDefault(brand, e) {
        var _this2 = this;

        e.stopPropagation();

        this.get('model').forEach(function (brand) {
          brand.set('isDefault', false);
        });
        brand.set('isDefault', true);
        brand.save().then(function () {
          var notificationMessage = _this2.get('i18n').t('admin.brands.default.message');
          _this2.get('notification').success(notificationMessage);
          _this2.get('model').update();
        });
      },
      delete: function _delete(brand, e) {
        var _this3 = this;

        e.preventDefault();
        e.stopPropagation();
        return this.get('confirmation').confirm({
          intlConfirmationBody: 'admin.brands.delete.message'
        }).then(function () {
          return brand.destroyRecord();
        }).then(function () {
          var msg = _this3.get('i18n').t('admin.brands.deleted.message');
          _this3.get('notification').success(msg);
        });
      }
    }
  });
});