define('frontend-cp/session/admin/apps/endpoints/new/controller', ['exports', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/apps/endpoints/schema'], function (exports, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    notification: service(),
    i18n: service(),
    endpoints: service(),
    virtualModel: service(),

    schema: _schema.default,

    // CPs
    title: computed('endpoint', function () {
      return this.get('endpoints').getTitleBreadcrumbs(this.get('endpoint'));
    }),

    // Methods
    initEdits: function initEdits() {
      this.set('editedEndpoint', this.get('virtualModel').makeSnapshot(this.get('endpoint'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('endpoint'), this.get('editedEndpoint'), _schema.default);
    },


    // Actions
    actions: {
      created: function created() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        this.transitionToRoute('session.admin.apps.endpoints.index');
      },
      canceled: function canceled() {
        this.transitionToRoute('session.admin.apps.endpoints.index');
      }
    }
  });
});