define('frontend-cp/components/ko-admin/automation-actions-builder/endpoint-http/component', ['exports', 'frontend-cp/components/ko-admin/automation-actions-builder/base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isBlank = Ember.isBlank;
  var set = Ember.set;
  var computed = Ember.computed;
  exports.default = _component.default.extend({
    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.originalValue = this.get('automationAction.value');
    },


    // CPs
    payloadFormat: computed('automationAction.value', {
      get: function get() {
        return isBlank(this.get('automationAction.value')) ? 'simple' : 'custom';
      },
      set: function set(_, v) {
        if (v === 'custom') {
          this.set('automationAction.value', this.originalValue || '{"": ""}');
        } else if (v === 'simple') {
          this.originalValue = this.get('automationAction.value');
          this.set('automationAction.value', '');
        }
        return v;
      }
    }),

    parameters: computed('automationAction.value', {
      get: function get() {
        var val = this.get('automationAction.value');
        var parameters = [];

        try {
          var object = JSON.parse(val);
          Object.keys(object).forEach(function (name) {
            return parameters.push({ name: name, value: object[name] });
          });
        } catch (jsonParseError) {
          parameters.push({ name: null, value: null });
        }
        return parameters;
      },
      set: function set(_, v) {
        this.set('automationAction.value', this.serializeParameters(v));
        return v;
      }
    }),

    // Actions
    actions: {
      addParameter: function addParameter() {
        var parameters = this.get('parameters');
        if (parameters.some(function (param) {
          return isBlank(param.name);
        })) {
          return;
        }
        this.set('parameters', parameters.concat([{ name: '', value: '' }]));
      },
      removeParameter: function removeParameter(pair) {
        var newParameters = this.get('parameters').filter(function (obj) {
          return obj !== pair;
        });
        this.set('parameters', newParameters);
      },
      updatePair: function updatePair(pair, attr, e) {
        set(pair, attr, e.target.value);
        this.set('parameters', this.get('parameters'));
      }
    },

    // Methods
    serializeParameters: function serializeParameters(parameters) {
      var obj = parameters.reduce(function (accum, param) {
        accum[param.name] = param.value;
        return accum;
      }, {});
      return JSON.stringify(obj);
    }
  });
});