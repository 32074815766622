define('frontend-cp/session/agent/cases/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    caseListTab: service('case-list-tab'),
    viewsCountPollingTimer: null,

    model: function model() {
      var store = this.get('store');
      var caseListTab = this.get('caseListTab');

      return RSVP.hash({
        views: caseListTab.getViews(),
        teams: store.findAll('team')
      });
    },
    setupController: function setupController(controller, _ref) {
      var views = _ref.views,
          teams = _ref.teams;

      var customViews = this.get('caseListTab.enabledViewsWithoutInbox');
      controller.setProperties({
        showingViewsList: true,
        views: views,
        teams: teams,
        customViews: customViews,
        selectedCaseIds: []
      });
    },


    actions: {
      refreshCaseList: function refreshCaseList() {
        this.get('caseListTab').refresh();
      }
    }
  });
});