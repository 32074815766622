define('frontend-cp/session/agent/insights/sla/overview/route', ['exports', 'ember-service/inject', 'frontend-cp/lib/get-date-range-at'], function (exports, _inject, _getDateRangeAt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  var isEmpty = Ember.isEmpty;
  exports.default = Route.extend({
    launchDarkly: (0, _inject.default)(),

    insights: service(),
    metrics: service(),
    plan: service(),
    permissions: service(),

    queryParams: {
      sla: { refreshModel: true }
    },

    slas: null,

    beforeModel: function beforeModel() {
      var _this = this;

      var launchDarkly = this.get('launchDarkly');
      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('release-restrict-insights')) {
        if (!(this.get('plan').has('insights_sla') && this.get('permissions').has('slas.manage')) && !launchDarkly.get('feature-restrict-sla-insights')) {
          this.transitionTo('session.agent.insights.general.cases');
        }
      }
      return this.get('insights').requestSLAs().then(function (slas) {
        if (isEmpty(slas)) {
          _this.transitionTo('session.agent.insights.general.cases');
        }
      });
    },
    model: function model(params) {
      var insights = this.get('insights');
      var insightsParams = this.paramsFor('session.agent.insights');

      var startAt = insightsParams.startAt ? insightsParams.startAt : (0, _getDateRangeAt.monthFormat)(new Date(new Date().getTime() - 86400000 * 30));
      var endAt = insightsParams.endAt ? insightsParams.endAt : (0, _getDateRangeAt.monthFormat)(new Date());
      var interval = insightsParams.interval ? insightsParams.interval : 'DAY';
      var sla = params.sla ? params.sla : null;

      var metricsQueryParams = {
        data: {
          start_at: startAt + 'T00:00:00.000Z',
          end_at: endAt + 'T23:59:59.999Z',
          interval: interval,
          sla_id: sla,
          trial: insights.isTrialMode() && insightsParams.trial
        }
      };

      return RSVP.hash({
        startAt: startAt,
        endAt: endAt,
        interval: interval,
        slaId: sla,
        metricsQueryParams: metricsQueryParams
      });
    },
    setupController: function setupController(controller, data) {
      var _this2 = this;

      var insights = this.get('insights');

      this.get('controller').setProperties({
        sla: data.slaId,
        startAt: data.startAt,
        endAt: data.endAt,
        interval: data.interval,
        metricsQueryParams: data.metricsQueryParams
      });

      if (insights.isTrialMode()) {
        insights.pushTrialNotification(function () {
          _this2.transitionTo({ queryParams: { trial: false } });
        });
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.controllerFor('session.agent.insights.sla.overview').set('sla', null);
      }
    },


    actions: {
      didTransition: function didTransition() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'sla_overview_insights_viewed'
          });
        }
      }
    }
  });
});