define("frontend-cp/components/ko-universal-search/help-block/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-universal-search_help-block__container_swdxj1",
    "modifiers": "ko-universal-search_help-block__modifiers_swdxj1",
    "modifier": "ko-universal-search_help-block__modifier_swdxj1",
    "emphasis": "ko-universal-search_help-block__emphasis_swdxj1",
    "separator": "ko-universal-search_help-block__separator_swdxj1",
    "help": "ko-universal-search_help-block__help_swdxj1",
    "help__arrow": "ko-universal-search_help-block__help__arrow_swdxj1"
  };
});