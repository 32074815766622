define('frontend-cp/adapters/team', ['exports', 'frontend-cp/adapters/application', 'frontend-cp/adapters/unpanginate-mixin'], function (exports, _application, _unpanginateMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _application.default.extend(_unpanginateMixin.default, {
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    },
    addMembers: function addMembers(team, members) {
      var teamId = get(team, 'id');
      var agentIds = members.mapBy('id').join(',');
      var url = this.namespace + '/teams/' + teamId + '/members';
      var options = { data: { agent_ids: agentIds } };

      return this.ajax(url, 'POST', options);
    },
    removeMembers: function removeMembers(team, members) {
      var teamId = get(team, 'id');
      var agentIds = members.mapBy('id').join(',');
      var url = this.namespace + '/teams/' + teamId + '/members?agent_ids=' + agentIds;

      return this.ajax(url, 'DELETE');
    }
  });
});