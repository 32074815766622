define("frontend-cp/components/ko-organization-content/info-block/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-organization-content_info-block__container_1nq2q9",
    "info-card": "ko-organization-content_info-block__info-card_1nq2q9",
    "organization-info": "ko-organization-content_info-block__organization-info_1nq2q9",
    "organization-name": "ko-organization-content_info-block__organization-name_1nq2q9",
    "timeline-header-image": "ko-organization-content_info-block__timeline-header-image_1nq2q9",
    "user": "ko-organization-content_info-block__user_1nq2q9",
    "avatar": "ko-organization-content_info-block__avatar_1nq2q9",
    "rows": "ko-organization-content_info-block__rows_1nq2q9",
    "title": "ko-organization-content_info-block__title_1nq2q9",
    "subtitle": "ko-organization-content_info-block__subtitle_1nq2q9",
    "list-item": "ko-organization-content_info-block__list-item_1nq2q9",
    "icon": "ko-organization-content_info-block__icon_1nq2q9",
    "description": "ko-organization-content_info-block__description_1nq2q9"
  };
});