define('frontend-cp/adapters/conversation-starter', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'conversations/starter';
    },
    fetchForBrand: function fetchForBrand(brandId) {
      var url = '/api/v1/conversations/starter';
      return this.ajax(url, 'GET', { data: { include: '', brand: brandId } });
    }
  });
});