define('frontend-cp/components/ko-pill-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var jQuery = Ember.$;
  var guidFor = Ember.guidFor;
  exports.default = Component.extend({
    // HTML
    localClassNames: ['view'],

    // Attributes
    isDisabled: false,
    onValueAddition: null,
    onValueRemoval: null,
    placeholder: '',
    searchField: 'name',
    value: [],
    qaClass: null,
    size: 'medium', // possible values: small, medium, full

    // State
    focused: false,

    focusIn: function focusIn() {
      this.set('focused', true);
    },
    focusOut: function focusOut() {
      this.set('focused', false);
    },


    inputId: computed(function () {
      return guidFor(this) + '-id';
    }),

    actions: {
      onClick: function onClick() {
        jQuery('#' + this.get('inputId')).focus();
      }
    }
  });
});