define('frontend-cp/services/settings', ['exports', 'npm:lodash', 'frontend-cp/services/virtual-model'], function (exports, _npmLodash, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var reject = Ember.RSVP.reject;
  var A = Ember.A;
  var or = Ember.computed.or;
  var EmberObject = Ember.Object;
  var defineProperty = Ember.defineProperty;
  var computed = Ember.computed;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  var MapWithDefault = Ember.MapWithDefault;


  var cleanupKey = function cleanupKey(setting) {
    return setting.split('.').join('_');
  };

  exports.default = Service.extend({
    // Services
    store: service(),
    virtualModel: service(),

    selectByKeys: function selectByKeys(keys) {
      var _this = this;

      var allSettings = this.get('store').peekAll('setting');
      var settings = _npmLodash.default.fromPairs(keys.map(function (key) {
        return allSettings.findBy('key', key);
      }).map(function (setting) {
        return [cleanupKey(setting.get('key')), setting];
      }));

      var wrapper = EmberObject.create(settings);
      wrapper.save = function () {
        var adapter = getOwner(_this).lookup('adapter:application');
        return Reflect.apply(_this._save, wrapper, [settings, adapter]);
      };
      return wrapper;
    },
    generateSchema: function generateSchema(settingKeys) {
      return (0, _virtualModel.model)('', _npmLodash.default.fromPairs(settingKeys.map(function (key) {
        return [cleanupKey(key), (0, _virtualModel.model)('setting', {
          value: (0, _virtualModel.attr)()
        })];
      })));
    },
    initEdits: function initEdits(settings, schema) {
      var editedSettings = this.set('editedSettings', this.get('virtualModel').makeSnapshot(settings, schema));
      var names = Object.keys(editedSettings);
      names.forEach(function (name) {
        var editedSetting = editedSettings.get(name);
        var originalSetting = settings.get(name);
        editedSetting.set('original', originalSetting);
        defineProperty(editedSetting, 'isEdited', computed('value', 'original.value', function () {
          // eslint-disable-line prefer-reflect
          return this.get('value') !== this.get('original.value');
        }));
      });
      var editedKeys = names.map(function (name) {
        return name + '.isEdited';
      });
      defineProperty(editedSettings, 'isEdited', or.apply(undefined, _toConsumableArray(editedKeys))); // eslint-disable-line prefer-reflect
      return editedSettings;
    },
    _save: function _save(settings, adapter) {
      var _this2 = this;

      var values = _npmLodash.default.fromPairs(_npmLodash.default.map(settings, function (setting) {
        return [setting.get('key'), setting.get('value')];
      }));

      return adapter.ajax(adapter.namespace + '/settings', 'PUT', {
        data: {
          values: values
        }
      }).catch(function (result) {
        var errors = MapWithDefault.create({
          defaultValue: function defaultValue() {
            return A();
          }
        });

        result.errors.forEach(function (error) {
          var regex = /\/values\/([\w.]+)/;
          var results = error.pointer.match(regex);
          var key = results[1];
          errors.get(cleanupKey(key)).pushObject({ message: error.message });
        });

        _this2.set('errors', {
          errorsByAttributeName: errors
        });

        return reject(result);
      });
    }
  });
});