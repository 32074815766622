define('frontend-cp/components/ko-impersonation-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    session: service(),

    message: computed('session.impersonationToken', 'session.session', function () {
      var iTok = this.get('session.impersonationToken');
      var sessionId = this.get('session.session');

      if (iTok && sessionId) {
        return 'Impersonation Mode';
      }
      if (!sessionId && iTok && iTok === 'error') {
        return 'Impersonation Failed';
      }
      if (!sessionId && iTok) {
        return 'Trying to Impersonate';
      }

      return '';
    })
  });
});