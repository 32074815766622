define('frontend-cp/components/ko-admin/apps/endpoints/select-type/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    endpoints: service(),

    onTypeSelect: function onTypeSelect() {},

    actions: {
      selectType: function selectType(type) {
        this.attrs.onTypeSelect(type);
      }
    }
  });
});