define('frontend-cp/initializers/socket', ['exports', 'phoenix'], function (exports, _phoenix) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var socketConstructor = application.socketConstructor || _phoenix.Socket;
    application.register('constructor:socket', socketConstructor, { instantiate: false });
  }

  exports.default = {
    name: 'phoenix-socket',
    initialize: initialize
  };
});