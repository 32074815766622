define('frontend-cp/models/twitter-message', ['exports', 'ember-data', 'frontend-cp/models/postable'], function (exports, _emberData, _postable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _postable.default.extend({
    uuid: attr('string'),
    attachments: _emberData.default.hasMany('attachment', { async: false }),
    contents: _emberData.default.attr('string'),
    downloadAll: _emberData.default.attr('string'),
    messsageId: _emberData.default.attr('string'),

    recipient: _emberData.default.belongsTo('identity-twitter', { async: false }),
    sender: _emberData.default.belongsTo('identity-twitter', { async: false }),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    postType: 'twitterMessage',
    isMessage: true
  });
});