define("frontend-cp/session/admin/people/teams/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "search": "session_admin_people_teams_index__search_17837l",
    "teams": "session_admin_people_teams_index__teams_17837l",
    "team": "session_admin_people_teams_index__team_17837l",
    "team-title": "session_admin_people_teams_index__team-title_17837l",
    "secondary": "session_admin_people_teams_index__secondary_17837l"
  };
});