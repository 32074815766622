define("frontend-cp/components/ko-simple-list/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "list": "ko-simple-list__list_djax4",
    "header": "ko-simple-list__header_djax4"
  };
});