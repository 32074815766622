define('frontend-cp/models/activity-object', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    name: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    prefix: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    fullTitle: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    preposition: _emberData.default.attr('string')
  });
});