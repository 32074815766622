define('frontend-cp/components/ko-admin/messenger/preview/component', ['exports', 'ember-concurrency', 'frontend-cp/helpers/first-name'], function (exports, _emberConcurrency, _firstName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend({
    tagName: '',

    preview: null,
    activeAgents: null,
    agentAvatars: [],
    existingStyle: '',

    didReceiveAttrs: function didReceiveAttrs() {
      this.getAgents();
    },


    hasMultipleAgents: computed.gt('preview.lastActiveAgents.length', 1),

    primaryColor: computed.readOnly('preview.primaryColor'),

    colorStyle: computed('primaryColor', function () {
      return htmlSafe('color: ' + this.get('primaryColor'));
    }),

    buttonStyle: computed('primaryColor', function () {
      return htmlSafe('background-color: ' + this.get('primaryColor'));
    }),

    backgroundStyle: computed('preview.patternUrl', 'preview.activeGradient', 'preview.activeSolidColor', 'preview.backgroundMode', function () {
      this.get('animateBackground').perform();

      var mode = this.get('preview.backgroundMode');
      var patternUrl = this.get('preview.patternUrl');
      var gradientValue = this.get('preview.activeGradient');
      var gradient = '';
      var pattern = '';
      var separator = '';
      var solidColor = '';
      var backgroundImage = 'none';

      if (mode === 'gradient') {
        gradient = 'linear-gradient(' + gradientValue + ')';
      }
      if (patternUrl) {
        pattern = 'url(' + patternUrl + ')';
      }
      if (mode === 'gradient' && pattern) {
        separator = ',';
      }
      if (mode === 'gradient' || pattern) {
        backgroundImage = '' + pattern + separator + gradient;
      }
      if (mode === 'color') {
        solidColor = 'background-color:' + this.get('preview.activeSolidColor');
      }

      return htmlSafe('background-image: ' + backgroundImage + ';' + solidColor);
    }),

    animateBackground: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(520);

            case 2:
              this.set('existingStyle', this.get('backgroundStyle'));

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    getAgents: function getAgents() {
      var firstNames = [];
      this.get('preview.lastActiveAgents').mapBy('fullName').forEach(function (agent) {
        firstNames.push((0, _firstName.firstName)(agent));
      });

      var names = firstNames.join(', ');
      var avatars = this.get('preview.lastActiveAgents').mapBy('avatar');
      this.set('activeAgents', names);
      this.set('agentAvatars', avatars);
    }
  });
});