define('frontend-cp/components/ko-cases-list/sidebar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var computed = Ember.computed;
    var service = Ember.inject.service;
    exports.default = Component.extend({
        tagName: '',
        suspendedMailsCount: null,
        permissions: service(),

        caseListTab: service('case-list-tab'),
        session: service(),

        filteredViews: computed('customViews', function () {
            return this.get('customViews').filter(function (view) {
                return view.get('viewType') !== 'TRASH';
            });
        }),

        trashView: computed('customViews', function () {
            return this.get('customViews').findBy('viewType', 'TRASH');
        }),

        suspendedView: function () {
            return this.get('permissions').has('cases.view_suspended', this.get('session').get('user'));
        }.property(),

        viewSeparator: computed('trashView', 'suspendedView', function () {
            return this.get('suspendedView') || this.get('trashView');
        })
    });
});