define('frontend-cp/models/timetracking-log', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    logType: (0, _attr.default)('string'),
    case: (0, _relationships.belongsTo)('case'),
    agent: (0, _relationships.belongsTo)('user'),
    creator: (0, _relationships.belongsTo)('user'),
    tracked: (0, _relationships.belongsTo)('timetracking-tracked'),
    timeSpent: (0, _attr.default)('number'),
    currentTime: (0, _attr.default)('number')
  });
});