define('frontend-cp/components/ko-insights/articles-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var A = Ember.A;
  var Component = Ember.Component;
  var dasherize = Ember.String.dasherize;
  var computed = Ember.computed;
  exports.default = Component.extend({
    articles: null,

    columnList: A([EmberObject.create({ id: 'title', name: 'title' }), EmberObject.create({ id: 'upvote-count', name: 'upvote-count' }), EmberObject.create({ id: 'view-count', name: 'view-count' }), EmberObject.create({ id: 'comment-count', name: 'comment-count' })]),

    articleSorting: ['upvote_count:desc'],
    sortedArticles: computed.sort('articles', 'articleSorting'),

    maxWidthForColumn: function maxWidthForColumn(columnName) {
      if (['upvote-count', 'view-count', 'comment-count'].indexOf(columnName) !== -1) {
        return 80;
      }

      return null;
    },
    componentForHeader: function componentForHeader(column) {
      return 'ko-insights/articles-list/header/' + dasherize(column.get('name'));
    },
    componentForColumn: function componentForColumn(column) {
      return 'ko-insights/articles-list/column/' + dasherize(column.get('name'));
    }
  });
});