define('frontend-cp/components/ko-cases-list/component', ['exports', 'ember-keyboard-shortcuts/mixins/component', 'ember-concurrency', 'frontend-cp/components/ko-cases-list/styles', 'frontend-cp/utils/compute-selected'], function (exports, _component, _emberConcurrency, _styles, _computeSelected) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var EmberObject = Ember.Object;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var $ = Ember.$;
  var scheduleOnce = Ember.run.scheduleOnce;
  exports.default = Component.extend(_component.default, {
    tagName: '',

    // Attributes
    activeView: null,
    isLoading: false,
    cases: [],
    onCaseListSort: null,
    onClick: function onClick() {},
    columns: [],
    title: null,
    orderBy: null,
    onSetSelectedCaseIds: function onSetSelectedCaseIds() {},
    onTrashCases: function onTrashCases() {},
    afterMerge: function afterMerge() {},
    trashable: false,
    isTrash: false,
    selectedCaseIds: null,
    orderByColumn: null,
    currentFocusedID: null,

    bulkService: service('case-bulk-update'),
    i18n: service(),
    notification: service(),
    permissions: service(),
    confirmation: service(),
    session: service(),
    store: service(),
    mergeConversation: service(),
    caseListTab: service(),

    keyboardShortcuts: {
      space: {
        action: 'toggleCaseSelect',
        preventDefault: true,
        global: false
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this._casesToUpdate = {};
    },


    //Lifecycle Hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      scheduleOnce('afterRender', this, 'scrollToFocusedCaseIfOutOfView');
    },


    //CP's
    hasPermissionToTrashCases: computed(function () {
      return this.get('permissions').has('cases.trash');
    }),

    hasPermissionToEmptyTrash: computed(function () {
      return this.get('session.user.role.isAdminOrHigher');
    }),

    columnList: computed('columns.[]', function () {
      var conversationTitle = this.get('i18n').t('cases.columns.conversation');
      var columns = this.get('columns').filter(function (column) {
        return column.id !== 'subject' && column.id !== 'requesterid';
      });
      return [EmberObject.create({
        id: 'conversation', name: 'conversation', title: conversationTitle, disableSorting: true
      })].concat(_toConsumableArray(columns.toArray()));
    }),

    isSomeChecked: computed.gt('selectedCaseIds.length', 0),
    isUpdatingCases: computed.or('trashCases.isRunning', 'emptyTrash.isRunning'),

    isEverythingChecked: computed('selectedCaseIds.length', 'cases.length', function () {
      var selected = this.get('selectedCaseIds.length');
      var total = this.get('cases.length');

      return selected === total && total > 0;
    }),

    //Methods
    scrollToFocusedCaseIfOutOfView: function scrollToFocusedCaseIfOutOfView() {
      if (this.get('cases') && this.get('cases').mapBy('id').length === 0 && this.get('cases.query.offset') > 0) {
        this.get('caseListTab').refresh();
      }

      var parent = $('.' + _styles.default.container).parent();
      var child = $('.' + _styles.default['row--focused']);

      if (child.length) {
        var parentBox = parent[0].getBoundingClientRect();
        var childBox = child[0].getBoundingClientRect();

        if (parentBox.bottom < childBox.bottom) {
          parent.scrollTop(parent.scrollTop() + childBox.bottom - parentBox.bottom);
        }

        if (childBox.top < parentBox.top) {
          parent.scrollTop(parent.scrollTop() + childBox.top - parentBox.top);
        }
      }
    },
    classForRow: function classForRow(rowCase, trashable) {
      var classes = [_styles.default.row];
      if (trashable && rowCase.get('state') === 'TRASH') {
        classes.push(_styles.default['row--trashed']);
      }
      return classes.join(' ');
    },
    minWidthForColumn: function minWidthForColumn(columnName) {
      if (columnName === 'conversation') {
        return 300;
      } else {
        return 80;
      }
    },
    maxWidthForColumn: function maxWidthForColumn(columnName) {
      var mediumWidthColumns = ['casestatusid', 'casepriorityid', 'casetypeid', 'brandid'];

      var smallWidthColumns = ['caseid'];

      if (mediumWidthColumns.includes(columnName)) {
        return 100;
      } else if (smallWidthColumns.includes(columnName)) {
        return 70;
      } else if (columnName === 'conversation') {
        return null;
      } else {
        return 150;
      }
    },


    updateCase: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(caseToUpdate) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (this._casesToUpdate[caseToUpdate.get('id')]) {
                _context.next = 2;
                break;
              }

              return _context.abrupt('return');

            case 2:
              Reflect.deleteProperty(this._casesToUpdate, caseToUpdate.get('id'));
              _context.next = 5;
              return caseToUpdate.reload();

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).enqueue(),

    trashCases: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var _this = this;

      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmationBody: 'generic.confirm.trashcases'
              }).then(function () {
                return _this.get('bulkService').trashCases(_this.get('selectedCaseIds')).then(function () {
                  _this.attrs.onTrashCases();
                  _this.get('notification').success(_this.get('i18n').t('generic.casestrashed'));
                }).catch(function () {
                  _this.get('notification').error(_this.get('i18n').t('generic.case_trashing_failed'));
                });
              });

            case 2:
              return _context2.abrupt('return', _context2.sent);

            case 3:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    mergeCases: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var store, mergeConversation, ids, cases, skipSelection, afterMerge, primary;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              store = this.get('store');
              mergeConversation = this.get('mergeConversation');
              ids = this.get('selectedCaseIds');
              cases = ids.map(function (id) {
                return store.peekRecord('case', id);
              });
              skipSelection = true;
              afterMerge = this.get('afterMerge');
              _context3.next = 8;
              return mergeConversation.confirm({ cases: cases, skipSelection: skipSelection });

            case 8:
              primary = _context3.sent;


              if (afterMerge) {
                afterMerge(primary);
              }

            case 10:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).drop(),

    emptyTrash: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      var _this2 = this;

      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              _context4.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmationHeader: 'generic.confirm.emptytrash.header',
                intlConfirmationBody: 'generic.confirm.emptytrash.body',
                intlConfirmLabel: 'generic.confirm.emptytrash.confirm',
                confirmButtonType: 'alert'
              }).then(function () {
                return _this2.get('store').adapterFor('case').emptyTrash().then(function () {
                  _this2.attrs.onTrashCases();
                  _this2.get('notification').success(_this2.get('i18n').t('generic.casestrashed'));
                }).catch(function () {
                  _this2.get('notification').error(_this2.get('i18n').t('generic.case_trashing_failed'));
                });
              });

            case 2:
              return _context4.abrupt('return', _context4.sent);

            case 3:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })),

    actions: {
      enqueueCaseUpdate: function enqueueCaseUpdate(caseToUpdate) {
        this._casesToUpdate[caseToUpdate.get('id')] = true;
        this.get('updateCase').perform(caseToUpdate);
      },
      toggleCheck: function toggleCheck(rowCase, checked, shiftKey) {
        var selectedCaseIds = [].concat(_toConsumableArray(this.get('selectedCaseIds')));

        if (shiftKey && selectedCaseIds.length) {
          var allCaseIds = this.get('cases').mapBy('id');
          var selectedRows = (0, _computeSelected.default)(rowCase.id, checked, selectedCaseIds, allCaseIds);
          this.attrs.onSetSelectedCaseIds(selectedRows);
          return;
        }

        if (checked) {
          selectedCaseIds.push(rowCase.id);
        } else {
          selectedCaseIds.removeObject(rowCase.id);
        }

        this.attrs.onSetSelectedCaseIds(selectedCaseIds);
      },
      selectAll: function selectAll() {
        this.get('onSetSelectedCaseIds')(this.get('cases').mapBy('id'));
      },
      deselectAll: function deselectAll() {
        this.get('onSetSelectedCaseIds')([]);
      },
      toggleCaseSelect: function toggleCaseSelect() {
        var selectedCaseIds = [].concat(_toConsumableArray(this.get('selectedCaseIds')));
        var currentFocusedID = this.get('currentFocusedID');
        if (!currentFocusedID) {
          return;
        }

        if (selectedCaseIds.includes(currentFocusedID)) {
          selectedCaseIds.removeObject(currentFocusedID);
        } else {
          selectedCaseIds.addObject(currentFocusedID);
        }

        this.attrs.onSetSelectedCaseIds(selectedCaseIds);
      }
    }
  });
});