define('frontend-cp/components/ko-bulk-invitation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var A = Ember.A;
  var EmberObject = Ember.Object;


  var createRecipient = function createRecipient() {
    return EmberObject.create({
      fullname: '',
      email: '',
      teams: A([]),
      role: null,
      errors: null
    });
  };

  exports.default = Component.extend({
    invitation: null,
    referenceData: {
      teams: [],
      roles: []
    },
    onRowsChanged: null,

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('invitation.users.length')) {
        this.get('invitation.users').pushObject(createRecipient());
      }
    },


    actions: {
      addRecipient: function addRecipient() {
        this.get('invitation.users').pushObject(createRecipient());

        var onRowsChanged = this.get('onRowsChanged');
        if (typeof onRowsChanged === 'function') {
          onRowsChanged('added', this.get('invitation.users.length'));
        }
      },
      removeRecipient: function removeRecipient(recipient) {
        this.get('invitation.users').removeObject(recipient);

        var onRowsChanged = this.get('onRowsChanged');
        if (typeof onRowsChanged === 'function') {
          onRowsChanged('removed', this.get('invitation.users.length'));
        }
      }
    }
  });
});