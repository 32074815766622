define('frontend-cp/session/agent/insights/reporting/custom-reports/schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _virtualModel.model)('report', {
    label: (0, _virtualModel.attr)(),
    visibility: (0, _virtualModel.attr)(),
    visibilityToTeams: (0, _virtualModel.many)((0, _virtualModel.attr)()),
    predicateCollections: (0, _virtualModel.many)((0, _virtualModel.model)('predicate-collection', {
      id: (0, _virtualModel.attr)(),
      propositions: (0, _virtualModel.many)((0, _virtualModel.model)('proposition', {
        id: (0, _virtualModel.attr)(),
        field: (0, _virtualModel.attr)(),
        operator: (0, _virtualModel.attr)(),
        value: (0, _virtualModel.attr)()
      }))
    }))
  });
});