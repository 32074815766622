define('frontend-cp/components/ko-case-content/cc/trigger/component', ['exports', 'ember-power-select/components/power-select-multiple/trigger', 'frontend-cp/lib/keycodes', 'frontend-cp/components/ko-case-content/cc/trigger/template', 'frontend-cp/utils/format-validations'], function (exports, _trigger, _keycodes, _template, _formatValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var run = Ember.run;
  var isBlank = Ember.isBlank;
  var observer = Ember.observer;
  exports.default = _trigger.default.extend({
    layout: _template.default,

    // State
    oldSearchText: '',

    onCCInput: function onCCInput() {},
    addImmediateCC: function addImmediateCC() {},

    selectObserver: observer('select.searchText', function () {
      var select = this.get('select');
      var searchText = select.searchText;
      var oldSearchText = this.get('oldSearchText');
      if (isBlank(searchText) && !isBlank(oldSearchText)) {
        run.schedule('actions', null, select.actions.close, null, true);
      } else if (!isBlank(searchText) && isBlank(oldSearchText)) {
        run.schedule('actions', null, select.actions.open);
      }
      this.set('oldSearchText', searchText);
    }),

    actions: {
      stopPropagation: function stopPropagation(e) {
        e.stopPropagation();
      },
      handleKeydown: function handleKeydown(e) {
        var isSpecialKeyWhileClosed = !this.get('select.isOpen') && [_keycodes.enter, _keycodes.escape, _keycodes.up, _keycodes.down].indexOf(e.keyCode) > -1;
        if (isSpecialKeyWhileClosed) {
          e.stopPropagation();
        } else {
          if (e.keyCode === _keycodes.enter && (0, _formatValidations.validateEmailFormat)(this.get('select.searchText'))) {
            this.sendAction('addImmediateCC', e, this.get('select.searchText'), this.get('select'));
          }
          this.send('onKeydown', e);
        }
      }
    }
  });
});