define('frontend-cp/components/ko-info-bar/field/select/arrow/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    show: true,
    active: false
  });
});