define('frontend-cp/session/admin/channels/facebook/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.store.query('facebook-page', { state: 'IMPORTED' });
    },


    actions: {
      showModal: function showModal() {
        var flag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        this.transitionTo('session.admin.channels.facebook.index', { queryParams: { showModal: flag } });
      },
      editPage: function editPage(page) {
        this.transitionTo('session.admin.channels.facebook.edit', page.get('id'));
      }
    }
  });
});