define('frontend-cp/components/ko-text-editor/component', ['exports', 'frontend-cp/components/ko-text-editor/styles', 'frontend-cp/components/ko-case-content/styles', 'frontend-cp/components/ko-text-editor/link-manager/styles', 'frontend-cp/config/environment', 'frontend-cp/utils/platform', 'frontend-cp/lib/html-to-text', 'frontend-cp/lib/keycodes', 'frontend-cp/utils/dispatch-event'], function (exports, _styles, _styles2, _styles3, _environment, _platform, _htmlToText, _keycodes, _dispatchEvent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EDITOR_SELECTOR = undefined;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = Ember.inject.service;
  var scheduleOnce = Ember.run.scheduleOnce;
  var cancel = Ember.run.cancel;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var $ = Ember.$;
  var run = Ember.run;
  var EDITOR_SELECTOR = exports.EDITOR_SELECTOR = '.' + _styles.default.froalaTextArea + ' .froala-editor-instance';

  var _COMMANDS = {
    bold: {
      command: ['commands.bold'],
      format: 'strong'
    },
    italic: {
      command: ['commands.italic'],
      format: 'em'
    },
    underline: {
      command: ['commands.underline'],
      format: 'u'
    },
    strikeThrough: {
      command: ['commands.strikeThrough'],
      format: 's'
    },
    outdent: {
      command: ['commands.outdent']
    },
    indent: {
      command: ['commands.indent']
    },
    url: {
      command: []
    },
    ul: {
      command: ['lists.format', 'UL']
    },
    ol: {
      command: ['lists.format', 'OL']
    },
    'quote.increase': {
      command: []
    },
    'quote.decrease': {
      command: []
    },
    'paragraph.format': {
      command: []
    }
  };

  exports.default = Component.extend({
    session: service(),
    notificationService: service('notification'),
    i18n: service(),
    store: service(),
    metrics: service(),

    // Attributes
    isRichFormattingAvailable: true,
    onAttachFiles: null,
    placeholder: null,
    isErrored: true,
    allowFormatting: true,
    isNote: false,
    showMacros: false,
    onTextChanged: null,
    value: '',
    onCancelAttachment: function onCancelAttachment() {},
    attachedFiles: null,
    autofocus: true,
    _editor: null,
    _editorEventsAdded: false,
    footerText: null,

    classNameBindings: ['disabledClass'],
    localClassNames: ['root'],

    isButtonBold: false,
    isButtonItalic: false,
    isButtonUl: false,
    isButtonOl: false,
    isButtonIndentDisabled: false,
    isButtonOutdentDisabled: false,
    editorFocussed: false,
    isImageUploading: false,
    isGeneratingSummary: false,
    generatedSummary: '',
    isAddingSummaryToCase: false,

    messengerHelpText: null,

    isSummaryModalVisible: computed('generatedSummary', function () {
      return !!this.get('generatedSummary');
    }),

    froalaParams: computed('placeholder', 'plugins', function () {
      var lineHeight = 20;
      var padding = 24;
      return {
        requestHeaders: { 'X-CSRF-Token': this.get('session.csrfToken') },
        heightMin: 1 * lineHeight + padding,
        heightMax: 10 * lineHeight + padding,
        key: _environment.default.froalaEditor.key,
        toolbarContainer: false,
        charCounterCount: false,
        shortcutsEnabled: ['undo', 'redo', 'bold', 'italic', 'underline'],
        spellcheck: true,
        placeholderText: this.get('placeholder'),
        imageResizeWithPercent: true,
        imagePaste: true,
        imageUploadURL: '/api/v1/media?include=*',
        imageUploadParam: 'files',
        imageDefaultDisplay: 'inline',
        imageEditButtons: [],
        imageOutputSize: true,
        imageDefaultWidth: 0,
        imageSplitHTML: true,
        enter: $.FroalaEditor.ENTER_DIV,
        toolbarButtons: [],
        toolbarButtonsMD: [],
        toolbarButtonsSM: [],
        toolbarButtonsXS: [],
        toolbarInline: false,
        toolbarVisibleWithoutSelection: false,
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        pluginsEnabled: this.get('plugins'),
        paragraphFormat: {
          N: 'Normal',
          PRE: 'Code'
        },
        zIndex: 60
      };
    }),

    plugins: ['align', 'link', 'lists', 'paragraphFormat', 'quote', 'url', 'image'],

    _isRelatedToTarget: function _isRelatedToTarget(target, className) {
      return $(target).hasClass(className) || $(target).parents('.' + className).length;
    },


    shortcutPrefix: computed(function () {
      return (0, _platform.isMac)() ? '⌘' : 'Ctrl+';
    }),

    submitShortcut: computed(function () {
      return (0, _platform.isMac)() ? '⌘ + Enter' : 'Ctrl + Enter';
    }),

    showSubmitTip: computed.bool('value'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._scheduleApplyPlaceholder();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupFocusEvents();
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this._applyDisabledState();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.tearDownFocusEvents();
      this._tearDownFroalaEvents(this.get('_editor'));

      cancel(this._afterRenderTimer);
    },
    scheduleEditorFocus: function scheduleEditorFocus() {
      this._afterRenderTimer = scheduleOnce('afterRender', this, 'focusEditor');
    },
    focusEditor: function focusEditor() {
      this.getFroalaElement().froalaEditor('events.focus');
      this._refreshToolbarIcons();
    },
    focusIn: function focusIn() {
      var _this = this;

      run.next(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.set('editorFocussed', true);
      });
    },
    focusOut: function focusOut() {
      var _this2 = this;

      run.next(function () {
        if (_this2.isDestroyed || _this2.isDestroying) {
          return;
        }
        _this2.set('editorFocussed', false);
      });
    },
    setupFocusEvents: function setupFocusEvents() {
      var _this3 = this;

      this.$(document).on('click.' + this.get('elementId'), function (event) {
        var isPlaceholder = _this3._isRelatedToTarget(event.target, 'ko-feed_item_menu') || _this3._isRelatedToTarget(event.target, 'ember-power-select-dropdown') || _this3._isRelatedToTarget(event.target, _styles.default.dropdownContent) || _this3._isRelatedToTarget(event.target, _styles.default.dropdown) || _this3._isRelatedToTarget(event.target, _styles2.default.replyOptions) || _this3._isRelatedToTarget(event.target, _styles3.default.container);

        if (isPlaceholder || _this3.$() && $.contains(_this3.$()[0], event.target)) {
          return;
        }
      });
    },
    tearDownFocusEvents: function tearDownFocusEvents() {
      this.$(document).off('click.' + this.get('elementId'));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      if (!this.getFroalaElement()) {
        return;
      }

      var editor = this.get('_editor');
      if (this.get('isRichFormattingAvailable')) {
        if (editor && !this.get('_editorEventsAdded')) {
          this._setupFroalaEvents(editor);
        }
      } else {
        this._tearDownFroalaEvents(editor);
      }
    },
    _applyDisabledState: function _applyDisabledState() {
      var element = this.getFroalaElement();
      if (!element) {
        return;
      }

      element.froalaEditor(this.get('disabled') ? 'edit.off' : 'edit.on');
    },
    getFroalaElement: function getFroalaElement() {
      return this.$(EDITOR_SELECTOR);
    },


    disabledClass: computed('disabled', function () {
      if (this.get('disabled')) {
        return _styles.default.disabled;
      }
    }),

    _refreshToolbarIcons: function _refreshToolbarIcons() {
      var _this4 = this;

      var _editor = this.get('_editor');

      if (!_editor) {
        return;
      }

      Object.keys(_COMMANDS).forEach(function (key) {
        var command = _COMMANDS[key];

        if (command.format) {
          _this4.set(('isButton_' + key).camelize(), _editor.format.is(command.format));
        } else if (['ul', 'ol'].indexOf(key) > -1 && _editor.lists) {
          var div = $('<div />');

          _editor.lists.refresh(div, key.toUpperCase());

          _this4.set(('isButton_' + key).camelize(), div.hasClass('fr-active'));
        } else if (key === 'indent') {
          var indent = $('<div />');

          _editor.refresh.indent(indent);

          _this4.set('isButtonIndentDisabled', indent.hasClass('fr-disabled'));
        } else if (key === 'outdent') {
          var outdent = $('<div />');

          _editor.refresh.outdent(outdent);

          _this4.set('isButtonOutdentDisabled', outdent.hasClass('fr-disabled'));
        }
      });
    },
    _setupFroalaEvents: function _setupFroalaEvents(editor) {
      var _this5 = this;

      if (!editor) {
        return;
      }

      editor.$el.on('mouseup.ko keyup.ko blur.ko focus.ko contentChanged.ko', function () {
        _this5._refreshToolbarIcons();
      });

      // AI-GEN - Cursor and GPT4
      this.getFroalaElement().froalaEditor('events.on', 'keydown', function (e) {
        // re-broadcast mod+enter so KeyboardShortcuts can handle it elsewhere
        var modKey = e.ctrlKey || e.metaKey;
        if (modKey && e.keyCode === _keycodes.enter) {
          // Check if image is still uploading
          if (_this5.get('isImageUploading')) {
            // Show a popup if ctrl+enter is pressed before image upload is complete
            // alert('Image upload is still in progress. Please wait until the upload is complete before submitting.');
            _this5.get('notificationService').error(_this5.get('i18n').t('generic.texteditor.image_upload_in_progress'));
            return false;
          }

          e.stopImmediatePropagation();
          e.preventDefault();

          (0, _dispatchEvent.default)(_this5.get('element'), e.type, {
            ctrlKey: e.ctrlKey,
            shiftKey: e.shiftKey,
            altKey: e.altKey,
            metaKey: e.metaKey,
            keyCode: e.keyCode,
            charCode: e.charCode
          });

          return false;
        }
      }, true);

      this.getFroalaElement().on('froalaEditor.image.beforePasteUpload', function (e, editor, response) {
        _this5.set('isImageUploading', true);
        _this5.send('setImageUploadStatus', true);
      });

      this.getFroalaElement().on('froalaEditor.image.uploaded', function (e, editor, response) {
        var imageUrl = JSON.parse(response).data[0].attachment.url;
        _this5.send('insertImage', imageUrl);
        return false;
      });

      this.getFroalaElement().on('froalaEditor.image.inserted', function () {
        _this5.set('isImageUploading', false);
        _this5.send('setImageUploadStatus', false);
      });

      this.getFroalaElement().on('froalaEditor.image.removed', function () {
        _this5.set('isImageUploading', false);
        _this5.send('setImageUploadStatus', false);
      });

      this.set('_editorEventsAdded', true);
    },
    _tearDownFroalaEvents: function _tearDownFroalaEvents(editor) {
      if (!editor) {
        return;
      }

      editor.$el.off('blur.ko mouseup.ko keyup.ko blur.ko focus.ko contentChanged.ko');
      this.set('_editorEventsAdded', false);
    },
    _scheduleApplyPlaceholder: function _scheduleApplyPlaceholder() {
      var placeholder = this.get('placeholder');

      if (placeholder === this._prevPlaceholder) {
        return;
      }

      this._prevPlaceholder = placeholder;

      scheduleOnce('afterRender', this, '_applyPlaceholder');
    },
    _applyPlaceholder: function _applyPlaceholder() {
      // From https://stackoverflow.com/a/46444280/273093

      var froala = this.getFroalaElement();
      var data = froala.data('froala.editor');
      var opts = data && data.opts;

      if (opts) {
        opts.placeholderText = this.get('placeholder');
        froala.froalaEditor('placeholder.refresh');
      }
    },
    _uploadingImagePlaceholder: function _uploadingImagePlaceholder() {
      var $placeholder = $('img.fr-uploading');

      if ($placeholder.length > 0) {
        return $placeholder;
      }
    },


    actions: {
      closeTicketSummaryModal: function closeTicketSummaryModal() {
        if (!this.get('isAddingSummaryToCase')) {
          var caseId = this.get('case').id;
          this.get('metrics').trackEvent({
            event: 'AI Summary Popup Dismissed',
            caseId: caseId
          });
          this.set('generatedSummary', '');
        }
      },
      generateTicketSummary: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
          var caseId, store, caseSummaryAdapter, summary;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  caseId = this.get('case').id;

                  this.get('metrics').trackEvent({
                    event: 'AI Summary Button Clicked',
                    caseId: caseId
                  });
                  store = this.get('store');
                  caseSummaryAdapter = store.adapterFor('case-ai-summary');

                  this.set('isGeneratingSummary', true);
                  _context.prev = 5;
                  _context.next = 8;
                  return caseSummaryAdapter.generateSummary(caseId);

                case 8:
                  summary = _context.sent;

                  this.set('generatedSummary', summary);
                  _context.next = 15;
                  break;

                case 12:
                  _context.prev = 12;
                  _context.t0 = _context['catch'](5);

                  this.get('notificationService').error(this.get('i18n').t('generic.generic_error'));

                case 15:
                  _context.prev = 15;

                  this.set('isGeneratingSummary', false);
                  return _context.finish(15);

                case 18:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this, [[5, 12, 15, 18]]);
        }));

        function generateTicketSummary() {
          return _ref.apply(this, arguments);
        }

        return generateTicketSummary;
      }(),
      addSummaryToCase: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
          var caseId, store, caseSummaryAdapter, generatedSummary;
          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  caseId = this.get('case').id;

                  this.get('metrics').trackEvent({
                    event: 'AI Summary Added to Case',
                    caseId: caseId
                  });
                  store = this.get('store');
                  caseSummaryAdapter = store.adapterFor('case-ai-summary');

                  this.set('isAddingSummaryToCase', true);
                  generatedSummary = this.get('generatedSummary');
                  _context2.prev = 6;
                  _context2.next = 9;
                  return caseSummaryAdapter.addSummaryToCase(caseId, generatedSummary);

                case 9:
                  _context2.next = 11;
                  return this.get('fetchNewerPosts').perform();

                case 11:
                  this.set('generatedSummary', '');
                  _context2.next = 17;
                  break;

                case 14:
                  _context2.prev = 14;
                  _context2.t0 = _context2['catch'](6);

                  this.get('notificationService').error(this.get('i18n').t('generic.generic_error'));

                case 17:
                  _context2.prev = 17;

                  this.set('isAddingSummaryToCase', false);
                  return _context2.finish(17);

                case 20:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this, [[6, 14, 17, 20]]);
        }));

        function addSummaryToCase() {
          return _ref2.apply(this, arguments);
        }

        return addSummaryToCase;
      }(),
      setImageUploadStatus: function setImageUploadStatus(status) {
        this.get('imageUploadStatus')(status);
      },
      allowFormattingCommands: function allowFormattingCommands(editor, cmd) {
        if (['bold', 'italic', 'underline'].indexOf(cmd) > -1) {
          return this.get('allowFormatting');
        } else {
          return true;
        }
      },
      allowToolbar: function allowToolbar() {
        return this.get('allowFormatting');
      },
      processPastedContentAfterFroalaClean: function processPastedContentAfterFroalaClean(_editor, html) {
        if (this.get('atMentionSupport.target')) {
          return this.get('atMentionSupport.target').handlePaste(html);
        }
      },
      processPastedContent: function processPastedContent(editor, html) {
        // FT-1321 - In Firefox (and IE?) Froala triggers this twice on a paste event
        // the first time, `html` is an object (the event) and the HTML isn't available
        // the second time we get what we expect and all is good.
        if (typeof html !== 'string') {
          return null;
        }

        var postContent = processPastedContentCore(html, !this.get('allowFormatting'));
        var knownHtmlEntityEscapingGlitches = [['¤', '&curren'], ['¶', '&para']];
        var htmlToReprocess = undefined;
        for (var i = 0; i < knownHtmlEntityEscapingGlitches.length; i++) {
          var _knownHtmlEntityEscap = _slicedToArray(knownHtmlEntityEscapingGlitches[i], 2),
              encoded = _knownHtmlEntityEscap[0],
              source = _knownHtmlEntityEscap[1];

          if (postContent.indexOf(encoded) !== -1 && html.indexOf(source) !== -1) {
            if (!htmlToReprocess) htmlToReprocess = html;
            var regex = new RegExp(source + '(?=[^;]|$)', 'g');
            htmlToReprocess = htmlToReprocess.replaceAll(regex, '&amp;' + source.substr(1));
          }
        }
        if (htmlToReprocess) {
          postContent = processPastedContentCore(htmlToReprocess, !this.get('allowFormatting'));
        }

        return postContent;
      },
      openDropdown: function openDropdown(dropdown, e) {
        e.preventDefault();

        try {
          this.get('_editor').selection.save();
        } catch (err) {
          // this catch is specific to avoid problem in safari with bug triggered in froala
        }
      },
      insertImage: function insertImage(url) {
        var editor = this.get('_editor');
        var sanitize = true;
        var data = '';
        var $existingImage = this._uploadingImagePlaceholder();
        var response = null;

        if ($existingImage) {
          editor.selection.restore();
          editor.image.insert(url, sanitize, data, $existingImage, response);
          editor.events.trigger('contentChanged');
        }
      },
      uploadImage: function uploadImage(files) {
        this.set('isImageUploading', true);
        this.get('_editor').image.upload(files);

        // clear file value after uploading so it can be used again
        $('input[type=file][accept="image/*"]').val('');
      },
      triggerControl: function triggerControl(type, e) {
        e.preventDefault();

        if (!_COMMANDS[type]) {
          return;
        }

        var $editor = this.getFroalaElement();
        var editor = this.get('_editor');

        if (type === 'outdent' && !this.get('isButtonOutdentDisabled')) {
          $editor.froalaEditor.apply($editor, _COMMANDS.outdent.command); // eslint-disable-line prefer-reflect,prefer-spread
        } else if (_COMMANDS[type].command && _COMMANDS[type].command.length) {
          $editor.froalaEditor.apply($editor, _COMMANDS[type].command); // eslint-disable-line prefer-reflect,prefer-spread
        } else if (type === 'quote.increase') {
          $editor.froalaEditor('quote.apply', 'increase');
          editor.selection.clear();
        } else if (type === 'quote.decrease') {
          $editor.froalaEditor('quote.apply', 'decrease');
        }

        $editor.froalaEditor('placeholder.refresh');

        this._refreshToolbarIcons();
      },
      initialized: function initialized(editor) {
        this.set('_editor', editor._editor);
        this._setupFroalaEvents(editor._editor);
        if (this.get('autofocus')) {
          this.scheduleEditorFocus();
        }
      },
      setParagraph: function setParagraph(code, dropdown, e) {
        var editor = this.get('_editor');

        editor.selection.restore();
        editor.paragraphFormat.apply(code); // eslint-disable-line prefer-reflect

        dropdown.actions.close();

        e.preventDefault();
      },
      addLink: function addLink(dropdown, url, text, e) {
        var editor = this.get('_editor');

        if (!url) {
          return;
        }

        editor.selection.restore();
        editor.link.insert(url, text);

        var $editor = this.getFroalaElement();
        var editorHtmlContent = $editor.froalaEditor('html.get');
        var divArray = editorHtmlContent.split('</div>');
        var isFirstRowHaveLinks = divArray[0].search('<a');
        if (isFirstRowHaveLinks > -1) {
          $editor.froalaEditor('html.set', '<div>&nbsp;</div>' + editorHtmlContent);
        }
        dropdown.actions.close();
        e.preventDefault();

        editor.events.trigger('contentChanged');
      },
      closeLinkManager: function closeLinkManager(dropdown, e) {
        dropdown.actions.close();
        e.preventDefault();
      }
    }
  });


  var processPastedContentCore = function processPastedContentCore(html, stripFormatting) {
    if (stripFormatting) {
      return (0, _htmlToText.stripFormattingFromHTML)(html);
    } else {
      var el = document.createElement('div');
      el.innerHTML = html;
      $(el).find('.br-wrapper.br-wrapper--multiple').replaceWith('<br><br>');
      $(el).find('.br-wrapper.br-wrapper--single').replaceWith('<br>');
      return el.innerHTML;
    }
  };
});