define('frontend-cp/helpers/ko-is-active', ['exports', 'frontend-cp/utils/bugsnag'], function (exports, _bugsnag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var Helper = Ember.Helper;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Helper.extend({
    router: service(),
    eventedRouter: service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this._fn = function () {
        return _this.recompute();
      };
      this.get('eventedRouter').on('didTransition', this._fn);
    },
    willDestroy: function willDestroy() {
      this.get('eventedRouter').off('didTransition', this._fn);
    },
    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref),
          routeName = _ref2[0],
          models = _ref2.slice(1);

      if (Array.isArray(routeName)) {
        var _routeName = routeName;

        var _routeName2 = _toArray(_routeName);

        routeName = _routeName2[0];
        models = _routeName2.slice(1);
      }

      var filteredModels = models.filter(function (model) {
        if (model.isQueryParams) {
          return false;
        }
        return true;
      });

      try {
        var _get;

        return (_get = this.get('router')).isActive.apply(_get, [routeName].concat(_toConsumableArray(filteredModels)));
      } catch (e) {
        if (!Ember.testing && window.Bugsnag) {
          var context = (0, _bugsnag.getMetaData)(null, getOwner(this));
          context['route checked'] = {
            routeName: routeName,
            filteredModels: filteredModels
          };
          context.api = undefined;
          window.Bugsnag.notifyException(e, 'Failed to check if route was active', context, 'info');
        }
      }
    }
  });
});