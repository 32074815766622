define('frontend-cp/components/basic-dropdown/component', ['exports', 'ember-basic-dropdown/components/basic-dropdown', 'ember-basic-dropdown/utils/calculate-position', 'ember-basic-dropdown/utils/computed-fallback-if-undefined'], function (exports, _basicDropdown, _calculatePosition, _computedFallbackIfUndefined) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isBlank = Ember.isBlank;


  function calculatePositionWithMargins(trigger, dropdown, destination, options) {
    var result = (0, _calculatePosition.default)(trigger, dropdown, destination, options);
    if (isBlank(result.style) || isBlank(result.style.width)) {
      return result;
    }

    var dropdownStyle = window.getComputedStyle(dropdown);
    var marginLeft = parseFloat(dropdownStyle.marginLeft);
    var marginRight = parseFloat(dropdownStyle.marginRight);
    var marginTotal = marginLeft + marginRight;

    result.style.width = parseFloat(result.style.width) - marginTotal;

    return result;
  }

  exports.default = _basicDropdown.default.extend({
    calculatePosition: (0, _computedFallbackIfUndefined.default)(calculatePositionWithMargins)
  });
});