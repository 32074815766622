define('frontend-cp/timelines/timeline', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Evented = Ember.Evented;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = EmberObject.extend(Evented, {
    limit: null,
    scrollTop: null,

    store: service(),

    parent: null,
    parentType: computed(function () {
      return this.get('parent.constructor.modelName');
    }),

    posts: null,

    moreNewerPosts: false,
    moreOlderPosts: false,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('posts', []);
    },
    clear: function clear() {
      this.setProperties({
        posts: [],
        moreNewerPosts: false,
        moreOlderPosts: false
      });
    },


    fetchNewerAfterReply: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var data;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              this.trigger('willFetchNewerAfterReply');
              _context.next = 3;
              return this.get('_fetchNewer').perform();

            case 3:
              data = _context.sent;

              this.trigger('didFetchNewerAfterReply');
              return _context.abrupt('return', data);

            case 6:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    fetchNewer: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var data;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              this.trigger('willFetchNewer');
              _context2.next = 3;
              return this.get('_fetchNewer').perform();

            case 3:
              data = _context2.sent;

              this.trigger('didFetchNewer');
              return _context2.abrupt('return', data);

            case 6:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    loadMostRecent: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              this.clear();
              _context3.next = 3;
              return this.get('fetchMostRecent').perform();

            case 3:
              return _context3.abrupt('return', _context3.sent);

            case 4:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    fetchAtTimestamp: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4(timestamp) {
      var expectedNumberOfPosts, afterParams, afterData, firstAfterPostTimestamp, beforeParams, beforeData;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              this.clear();
              expectedNumberOfPosts = Math.round(this.get('limit') / 2);
              afterParams = this._paramsForAfterTimestamp(timestamp, expectedNumberOfPosts);
              _context4.next = 5;
              return this.get('_fetch').perform(afterParams);

            case 5:
              afterData = _context4.sent;

              if (!afterData.toArray().length) {
                _context4.next = 16;
                break;
              }

              firstAfterPostTimestamp = afterData.sortBy('createdAt').get('firstObject.createdAt').getTime() / 1000;
              beforeParams = this._paramsForBeforeTimestamp(firstAfterPostTimestamp, expectedNumberOfPosts);
              _context4.next = 11;
              return this.get('_fetch').perform(beforeParams);

            case 11:
              beforeData = _context4.sent;


              this._updateInternalState(afterData, afterData.meta.total, 'NEWER', expectedNumberOfPosts);
              this._updateInternalState(beforeData, afterData.meta.total, 'OLDER', expectedNumberOfPosts);
              _context4.next = 19;
              break;

            case 16:
              this._updateInternalState(afterData, afterData.meta.total, 'NEWER', expectedNumberOfPosts);
              _context4.next = 19;
              return this.get('fetchMostRecent').perform();

            case 19:

              this.trigger('didFetchAtTimestamp');

            case 20:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })),

    fetchMostRecent: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5() {
      var params, data;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              params = this._paramsForMostRecent();
              _context5.next = 3;
              return this.get('_fetch').perform(params);

            case 3:
              data = _context5.sent;

              this._updateInternalState(data, data.meta.total, 'NONE');

              this.trigger('didFetchMostRecent');
              return _context5.abrupt('return', data);

            case 7:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })),

    _fetchNewer: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee6() {
      var params, data;
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              params = this._paramsForNewer();
              _context6.next = 3;
              return this.get('_fetch').perform(params);

            case 3:
              data = _context6.sent;

              this._updateInternalState(data, data.meta.total, 'NEWER');
              return _context6.abrupt('return', data);

            case 6:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    })).drop(),

    fetchOlder: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee7() {
      var params, data;
      return regeneratorRuntime.wrap(function _callee7$(_context7) {
        while (1) {
          switch (_context7.prev = _context7.next) {
            case 0:
              params = this._paramsForOlder();
              _context7.next = 3;
              return this.get('_fetch').perform(params);

            case 3:
              data = _context7.sent;

              this._updateInternalState(data, data.meta.total, 'OLDER');

              this.trigger('didFetchOlder');
              return _context7.abrupt('return', data);

            case 7:
            case 'end':
              return _context7.stop();
          }
        }
      }, _callee7, this);
    })).drop(),

    restore: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee8() {
      var moreNewerPosts, params, _ref, total;

      return regeneratorRuntime.wrap(function _callee8$(_context8) {
        while (1) {
          switch (_context8.prev = _context8.next) {
            case 0:
              moreNewerPosts = this.get('moreNewerPosts');

              if (moreNewerPosts) {
                _context8.next = 8;
                break;
              }

              params = this._paramsForRestore();
              _context8.next = 5;
              return this.get('_fetch').perform(params);

            case 5:
              _ref = _context8.sent;
              total = _ref.meta.total;


              if (total > this.get('serverTotalPosts')) {
                this.setProperties({
                  serverTotalPosts: total,
                  moreNewerPosts: true
                });
              }

            case 8:
            case 'end':
              return _context8.stop();
          }
        }
      }, _callee8, this);
    })),

    _updateInternalState: function _updateInternalState(data, serverTotalPosts, paramsUsed) {
      var expectedNumberOfPosts = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : this.get('limit');

      this.set('serverTotalPosts', serverTotalPosts);
      var numItemsReturned = data.toArray().length;

      switch (paramsUsed) {
        case 'NEWER':
          this.set('posts', [].concat(_toConsumableArray(this.get('posts')), _toConsumableArray(this.sortPosts(data, 'ASC'))));
          this.set('moreNewerPosts', numItemsReturned === expectedNumberOfPosts);
          break;
        case 'OLDER':
          this.set('posts', [].concat(_toConsumableArray(this.sortPosts(data, 'ASC')), _toConsumableArray(this.get('posts'))));
          this.set('moreOlderPosts', numItemsReturned === expectedNumberOfPosts);
          break;
        case 'NONE':
          this.set('posts', this.sortPosts(data, 'ASC'));
          this.set('moreOlderPosts', numItemsReturned === expectedNumberOfPosts);
          break;
      }

      if (this.get('allPostsLoaded')) {
        this.set('moreNewerPosts', false);
        this.set('moreOlderPosts', false);
      }
    },
    containsPostId: function containsPostId(postId) {
      var matchingPost = this.get('posts').find(function (post) {
        return Number.parseInt(post.id) === Number.parseInt(postId);
      });

      return matchingPost !== null && matchingPost !== undefined;
    },


    // Interface to implement:

    _sortPosts: function _sortPosts(posts /*dir='ASC'*/) {
      return posts;
    },
    _paramsForRestore: function _paramsForRestore() {
      return {};
    },
    _paramsForOlder: function _paramsForOlder() {
      return {};
    },
    _paramsForNewer: function _paramsForNewer() {
      return {};
    },
    _paramsForAfterTimestamp: function _paramsForAfterTimestamp() {
      return {};
    },
    _paramsForBeforeTimestamp: function _paramsForBeforeTimestamp() {
      return {};
    },
    _paramsForMostRecent: function _paramsForMostRecent() {
      return {};
    },


    _fetch: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee9() {
      return regeneratorRuntime.wrap(function _callee9$(_context9) {
        while (1) {
          switch (_context9.prev = _context9.next) {
            case 0:
              _context9.next = 2;
              return [];

            case 2:
            case 'end':
              return _context9.stop();
          }
        }
      }, _callee9, this);
    }))

  });
});