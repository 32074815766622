define('frontend-cp/models/brand', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    alias: attr('string', { defaultValue: '' }),
    domain: attr('string', { defaultValue: '' }),
    subDomain: attr('string', { defaultValue: '' }),
    name: attr('string', { defaultValue: '' }),
    url: attr('string'),
    sslCertificate: attr('string', { defaultValue: '' }),
    isSslEnabled: attr('boolean'),
    privateKey: attr('string', { defaultValue: '' }),
    locale: belongsTo('locale', { async: false }),
    isEnabled: attr('boolean'),
    isDefault: attr('boolean'),
    mailboxes: hasMany('mailbox'),

    createdAt: attr('date'),
    updatedAt: attr('date')
  });
});