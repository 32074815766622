define("frontend-cp/components/ko-case-content/cc/trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-case-content_cc_trigger__container_12hma6",
    "options": "ko-case-content_cc_trigger__options_12hma6",
    "input": "ko-case-content_cc_trigger__input_12hma6",
    "placeholder": "ko-case-content_cc_trigger__placeholder_12hma6"
  };
});