define('frontend-cp/components/ko-admin/email/edit/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var filterBy = Ember.computed.filterBy;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend({
    // Attributes
    schema: null,
    mailbox: null,
    editedMailbox: null,
    brands: [],
    onCancel: null,
    onEdit: null,
    onSuccess: null,
    onError: null,

    // Services
    store: service(),
    virtualModel: service(),

    // CPs
    domain: computed(function () {
      return window.location.hostname;
    }),

    showDNS: reads('mailbox.isCustomDomain'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('showDNS') && !this.get('mailbox.isNew')) {
        this.get('fetchConfiguration').perform();
      }
    },


    dnsConfiguration: reads('fetchConfiguration.last.value'),
    dnsError: reads('fetchConfiguration.last.error'),

    enabledBrands: filterBy('brands', 'isEnabled'),

    fetchConfiguration: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var adapter, result;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              adapter = getOwner(this).lookup('adapter:application');
              _context.next = 3;
              return adapter.ajax(adapter.namespace + '/mailboxes/' + this.get('mailbox.id') + '/configuration');

            case 3:
              result = _context.sent;
              return _context.abrupt('return', result.data);

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    actions: {
      save: function save() {
        var virtualModel = this.get('virtualModel');
        var schema = this.get('schema');
        var mailbox = this.get('mailbox');
        var editedMailbox = this.get('editedMailbox');

        return virtualModel.save(mailbox, editedMailbox, schema).then(this.attrs.onEdit);
      }
    }
  });
});