define('frontend-cp/components/ko-info-bar/field/select/component', ['exports', 'frontend-cp/mixins/with-unique-id'], function (exports, _withUniqueId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  exports.default = Component.extend(_withUniqueId.default, {
    // Attributes
    title: '',
    value: null,
    options: [],
    onValueChange: null,
    labelPath: null,
    idPath: null,
    isErrored: false,
    isEdited: false,
    isKREEdited: false,
    isDisabled: false,
    hasEmptyOption: true,
    showBottomArrow: false,
    searchPlaceholder: null,
    qaClass: null,
    search: null,
    emptyLabel: null,

    // HTML
    tagName: '',

    // CPs
    normalizedOptions: computed('hasEmptyOption', 'labelPath', 'options.[]', 'emptyLabel', function () {
      var opts = this.get('options').toArray();
      if (this.get('hasEmptyOption')) {
        var labelPath = this.get('labelPath');
        var emptyOpt = void 0;
        if (labelPath) {
          emptyOpt = { id: '__empty_option__' };
          emptyOpt[labelPath] = this.get('emptyLabel') || '-';
        } else {
          emptyOpt = this.get('emptyLabel') || '-';
        }
        opts.unshift(emptyOpt);
      }
      return opts;
    }),

    normalizedValue: computed('idPath', 'value', 'normalizedOptions.[]', function () {
      var idPath = this.get('idPath');
      var value = this.get('value');
      if (idPath && value) {
        return this.get('normalizedOptions').findBy(idPath, value);
      } else if (idPath) {
        return this.get('normalizedOptions').findBy(idPath, '__empty_option__');
      } else {
        return value;
      }
    }),

    // Actions
    actions: {
      selectItem: function selectItem(item) {
        var normalizedItem = item;
        if (this.get('labelPath')) {
          if (!item || item.id === '__empty_option__') {
            normalizedItem = null;
          }
        } else if (item === this.get('emptyLabel')) {
          normalizedItem = null;
        }
        var action = this.get('onValueChange');
        var idPath = this.get('idPath');
        if (idPath) {
          action(normalizedItem && get(normalizedItem, idPath));
        } else {
          action(normalizedItem);
        }
      }
    }
  });
});