define("frontend-cp/locales/en-us/billing", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        "temporary_changes_title": "Temporary Changes to Enterprise Billing Procedures",
        "temporary_changes_description": "We are currently upgrading our billing infrastructure to enhance our service to our enterprise clients. During this transition period, please note the following temporary adjustments:",
        "current_procedure_title": "Current Procedure",
        "current_procedure_description": "For all billing-related matters, including payment processing, and updates to payment information, please contact our dedicated enterprise billing team at:",
        "email_address": "billing@kayako.com",
        "upcoming_enhancements_title": "Upcoming Enhancements",
        "upcoming_enhancements_description": "Upon completion of our system upgrade, clients will benefit from:",
        "self_service_invoice_access": "Self-Service Invoice Access: ",
        "self_service_invoice_access_description": "View and download invoices directly from your enterprise portal.",
        "streamlined_payment_processing": "Streamlined Payment Processing: ",
        "streamlined_payment_processing_description": "Execute payments efficiently through our secure payment gateway.",
        "account_management": "Account Management: ",
        "account_management_description": "Update and manage payment details with enhanced security measures.",
        "commitment_message": "We are committed to minimizing any inconvenience during this transition. Our support team remains available to assist you with any concerns."
    };
});