define('frontend-cp/session/agent/users/new/organization/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route-child'], function (exports, _tabbedRouteChild) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var getOwner = Ember.getOwner;
  exports.default = _tabbedRouteChild.default.extend({
    model: function model() {
      var user = this.modelFor('session.agent.users.new');
      var organization = user ? user.get('organization') : null;

      return RSVP.hash({
        user: user,
        organization: organization
      });
    },
    afterModel: function afterModel(_ref) {
      var organization = _ref.organization;

      if (organization) {
        return RSVP.hash({
          domains: organization.get('domains'),
          tags: organization.get('tags')
        });
      }
    },
    setupController: function setupController(controller, models) {
      controller.setProperties(models);
      var parentController = this.controllerFor('session.agent.users.new');
      var tab = parentController.get('tab');
      var OrganizationStateManager = getOwner(this).factoryFor('state-manager:organization');
      var state = OrganizationStateManager.create({ organization: models.organization, tab: tab });
      controller.set('state', state);
    }
  });
});