define('frontend-cp/services/local-store', ['exports', 'frontend-cp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    prefix: _environment.default.localStore.prefix,

    local: window.localStorage,
    session: window.sessionStorage,

    _store: function _store(persist) {
      return persist ? this.get('local') : this.get('session');
    },


    /**
     * Setup namespace for service usage
     *
     * @param {String} namespace Namespace
     * @param {String} key Key to concatenate with namespace
     * @returns {string}
     */
    _getKeyWithNamespace: function _getKeyWithNamespace(namespace, key) {
      return [this.get('prefix'), namespace, key].join(':');
    },


    /**
     * Retrieve an item from the store by key
     *
     * Items are stored as JSON strings
     *
     * @param  {string} namespace - Namespace in local storage
     * @param  {string} key - Key to retrieve
     * @param {Boolean} options.persist Use local storage instead of session storage
     * @return {*} Stored object
     */
    getItem: function getItem(namespace, key) {
      var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
          _ref$persist = _ref.persist,
          persist = _ref$persist === undefined ? false : _ref$persist;

      key = this._getKeyWithNamespace(namespace, key);

      var item = this._store(persist).getItem(key);

      // Note: stringified undefined will return 'undefined'
      if (item !== null && item !== 'undefined') {
        return JSON.parse(item);
      } else {
        return null;
      }
    },


    /**
     * Place item in the store as a JSON string.
     *
     * Note: only plain objects can be stored.
     *
     * @param {string}  namespace [description]
     * @param {string}  key       [description]
     * @param {mixed}  item      [description]
     * @param {Boolean} options.persist Use local storage instead of session storage
     */
    setItem: function setItem(namespace, key, item) {
      var _ref2 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
          _ref2$persist = _ref2.persist,
          persist = _ref2$persist === undefined ? false : _ref2$persist;

      key = this._getKeyWithNamespace(namespace, key);

      // using typeof for strict undefined check
      if (typeof item !== 'undefined') {
        this._store(persist).setItem(key, JSON.stringify(item));
      }
    },


    /**
     * Remove item from the store.
     *
     * @param {string}  namespace [description]
     * @param {[type]}  key       key of item to be removed
     * @param {Boolean} options.persist Use local storage instead of session storage
     */
    removeItem: function removeItem(namespace, key) {
      var _ref3 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
          _ref3$persist = _ref3.persist,
          persist = _ref3$persist === undefined ? false : _ref3$persist;

      key = this._getKeyWithNamespace(namespace, key);

      this._store(persist).removeItem(key);
    },


    /**
     * Clear local and session storage by namespace
     *
     * @param {String} namespace Namespace to clear
     */
    clearAllByNamespace: function clearAllByNamespace(namespace) {
      var nsRegexp = new RegExp('^' + this.get('prefix') + ':' + namespace + ':', 'i');
      this._clearStorageByRegex(this.get('local'), nsRegexp);

      // Note, ignores the prefix for the session because...
      var appRegexp = new RegExp('^' + this.get('prefix') + ':', 'i');
      this._clearStorageByRegex(this.get('session'), appRegexp);
    },
    _clearStorageByRegex: function _clearStorageByRegex(store, regex) {
      if (typeof store !== 'undefined') {
        var keys = [];
        for (var i = 0; i < store.length; i++) {
          keys.push(store.key(i));
        }

        keys.forEach(function (key) {
          if (regex.test(key)) {
            store.removeItem(key);
          }
        });
      }
    },


    /**
     * Clear everything in the store for the app's prefix
     */
    clearAll: function clearAll() {
      var appRegexp = new RegExp('^' + this.get('prefix') + ':', 'i');

      this._clearStorageByRegex(this.get('local'), appRegexp);
      this._clearStorageByRegex(this.get('session'), appRegexp);
    }
  });
});