define("frontend-cp/components/ko-timeline-2/list/item/menu/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-timeline-2_list_item_menu__trigger_1ukxbo ko-power-select-triggers_chevron__trigger_71zyep",
    "dropdown-body": "ko-timeline-2_list_item_menu__dropdown-body_1ukxbo"
  };
});