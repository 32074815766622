define('frontend-cp/services/cart-analytics', ['exports', 'ember-concurrency', 'frontend-cp/config/environment', 'npm:lodash'], function (exports, _emberConcurrency, _environment, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({
    // Constants
    STATUS_OPEN: 'open',
    STATUS_ABANDONED: 'abandoned',
    STATUS_FULFILLED: 'converted',

    session: service(),
    store: service(),
    user: computed.readOnly('session.user'),
    billingStatus: {},

    init: function init() {
      var brand = this.get('store').peekAll('brand').findBy('isDefault', true);
      this.set('brand', brand);
      var company = this.get('brand.subDomain');
      var instanceId = this.get('session.session.instanceId');
      var domain = 'https://' + this.get('brand.subDomain') + '.' + this.get('brand.domain');
      this.set('billingStatus', { company: company, instanceId: instanceId, domain: domain });
    },


    initUpdateCart: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(that) {
      var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('STATUS_OPEN');
      var name, email, phone, billingFirstName, billingLastName, billingEmail, billingPhone, billingAddress1, billingAddress2, billingCity, billingState, billingPostalCode, billingCountry, billingInformation, seats, cartValue, plan, planId;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:

              // yield timeout(debounceTime); //don't uncomment, otherwise we will lose some logs

              name = ''; // The billing information will have it's own fields

              email = ''; // The billing information will have it's own fields

              phone = that.get('billing.home_phone');

              // billing information

              billingFirstName = that.get('billing.first_name');
              billingLastName = that.get('billing.last_name');
              billingEmail = that.get('billing.personal_email');
              billingPhone = that.get('billing.home_phone');
              billingAddress1 = that.get('billing.address1');
              billingAddress2 = that.get('billing.address2');
              billingCity = that.get('billing.city');
              billingState = that.get('billing.state');
              billingPostalCode = that.get('billing.postal_code');
              billingCountry = that.get('billing.country');
              billingInformation = { billingFirstName: billingFirstName, billingLastName: billingLastName, billingEmail: billingEmail, billingPhone: billingPhone, billingAddress1: billingAddress1, billingAddress2: billingAddress2, billingCity: billingCity, billingState: billingState, billingPostalCode: billingPostalCode, billingCountry: billingCountry };
              seats = that.get('selectedNumberOfSeats');
              cartValue = that.get('subscriptionAmount');
              plan = that.get('selectedPlan.label');
              planId = that.get('selectedPlan.productId');


              this.updateCart(_npmLodash.default.merge(billingInformation, { status: status, name: name, email: email, plan: plan, planId: planId, phone: phone, seats: seats, cartValue: cartValue }));

            case 19:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).keepLatest(),

    updateCart: function updateCart(_ref) {
      var billingFirstName = _ref.billingFirstName,
          billingLastName = _ref.billingLastName,
          billingEmail = _ref.billingEmail,
          billingPhone = _ref.billingPhone,
          billingAddress1 = _ref.billingAddress1,
          billingAddress2 = _ref.billingAddress2,
          billingCity = _ref.billingCity,
          billingState = _ref.billingState,
          billingPostalCode = _ref.billingPostalCode,
          billingCountry = _ref.billingCountry,
          status = _ref.status,
          name = _ref.name,
          email = _ref.email,
          plan = _ref.plan,
          planId = _ref.planId,
          phone = _ref.phone,
          seats = _ref.seats,
          cartValue = _ref.cartValue;
      // this is here to keep track of all the parameters we have
      email = email || this.get('user.primaryEmailAddress');
      name = name || this.get('user.fullName');

      var billingStatus = this.get('billingStatus');
      billingStatus = _npmLodash.default.merge(billingStatus, { status: status, name: name, email: email, plan: plan, planId: planId, phone: phone, seats: seats, cartValue: cartValue, billingFirstName: billingFirstName, billingLastName: billingLastName, billingEmail: billingEmail, billingPhone: billingPhone, billingAddress1: billingAddress1, billingAddress2: billingAddress2, billingCity: billingCity, billingState: billingState, billingPostalCode: billingPostalCode, billingCountry: billingCountry });
      this.set('billingStatus', billingStatus);
      var adapter = getOwner(this).lookup('adapter:application');
      var data = billingStatus;
      adapter.ajax(_environment.default.cartServiceBaseUrl + '/api/cartlog/save', 'POST', { data: data });
    }
  });
});