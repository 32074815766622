define('frontend-cp/models/view', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string', { defaultValue: '' }),
    visibilityType: _emberData.default.attr('string'), // ALL | TEAM
    orderByColumn: _emberData.default.attr('string', { defaultValue: 'updatedat' }),
    orderBy: _emberData.default.attr('string', { defaultValue: 'ASC' }),
    sortOrder: _emberData.default.attr('number'),
    isEnabled: _emberData.default.attr('boolean', { defaultValue: true }),
    isDefault: _emberData.default.attr('boolean', { defaultValue: false }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    viewType: _emberData.default.attr('string'),

    // Relations
    agent: _emberData.default.belongsTo('user', { async: false }),
    visibilityToTeams: _emberData.default.hasMany('team', { async: false }),
    columns: _emberData.default.hasMany('column', { async: false }),
    predicateCollections: _emberData.default.hasMany('predicate-collection', { defaultValue: [], async: false }),
    viewCount: _emberData.default.belongsTo('view-count', { async: true }),
    cases: _emberData.default.hasMany('case', { async: true }),

    // Services
    i18n: service(),

    // CPs
    visibilityString: computed('visibilityType', 'visibilityToTeams', function () {
      var trans = 'admin.views.sharing.' + this.get('visibilityType');

      if (this.get('visibilityType') === 'TEAM') {
        return this.get('visibilityToTeams').map(function (team) {
          return team.get('title');
        }).join(', ');
      }

      return this.get('i18n').t(trans);
    })
  });
});