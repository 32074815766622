define('frontend-cp/session/admin/customizations/user-fields/select-type/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      // TODO: make this action to be generic across cases & users.
      setCaseFieldType: function setCaseFieldType(fieldType) {
        this.transitionToRoute('session.admin.customizations.user-fields.new', fieldType);
      },
      canceled: function canceled() {
        this.transitionToRoute('session.admin.customizations.user-fields.index');
      }
    }
  });
});