define('frontend-cp/lib/get-date-range-at', ['exports', 'frontend-cp/lib/left-pad'], function (exports, _leftPad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.monthFormat = monthFormat;
  exports.getMonthAt = getMonthAt;
  exports.getWeekAt = getWeekAt;
  function monthFormat(date) {
    return date.getUTCFullYear() + '-' + (0, _leftPad.default)(date.getUTCMonth() + 1) + '-' + (0, _leftPad.default)(date.getUTCDate());
  }

  function getMonthAt() {
    var period = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'start';
    var monthDiff = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    var date = new Date();
    var month = date.getMonth();
    var day = 1;
    var hour = 0;
    var minute = 0;
    var second = 0;

    if (period === 'end') {
      month += 1;
      day = 0;
      hour = 23;
      minute = 59;
      second = 59;
    }

    var range = new Date(date.getUTCFullYear(), month + monthDiff, day, hour, minute, second);

    return range.getUTCFullYear() + '-' + (0, _leftPad.default)(range.getUTCMonth() + 1) + '-' + (0, _leftPad.default)(range.getUTCDate());
  }

  function getWeekAt() {
    var period = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'start';
    var weekDiff = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + weekDiff * 7);

    var monday = currentDate.getDate() - currentDate.getDay() + 1;
    var sunday = monday + 6;

    var range = new Date(currentDate.setDate(monday));
    range.setHours(0);
    range.setMinutes(0);
    range.setSeconds(0);

    if (period === 'end') {
      range = new Date(currentDate.setDate(sunday));
      range.setHours(23);
      range.setMinutes(59);
      range.setSeconds(59);
    }

    return range.getUTCFullYear() + '-' + (0, _leftPad.default)(range.getUTCMonth() + 1) + '-' + (0, _leftPad.default)(range.getUTCDate());
  }
});