define("frontend-cp/components/ko-admin/views/edit/columns/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-admin_views_edit_columns__container_ebvh66",
    "item": "ko-admin_views_edit_columns__item_ebvh66",
    "action": "ko-admin_views_edit_columns__action_ebvh66",
    "content": "ko-admin_views_edit_columns__content_ebvh66"
  };
});