define('frontend-cp/services/endpoints', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    types: computed(function () {
      return [{
        name: 'EMAIL',
        titleKey: 'admin.apps.endpoints.types.email.title',
        descriptionKey: 'admin.apps.endpoints.types.email.description'
      }, {
        name: 'SLACK',
        titleKey: 'admin.apps.endpoints.types.slack.title',
        descriptionKey: 'admin.apps.endpoints.types.slack.description'
      }, {
        name: 'HTTP',
        titleKey: 'admin.apps.endpoints.types.webhook.title',
        descriptionKey: 'admin.apps.endpoints.types.webhook.description'
      }];
    }),

    i18n: service(),

    getTitleBreadcrumbs: function getTitleBreadcrumbs(model) {
      var title = [];
      var type = this.getTypeByName(model.get('fieldType'));
      var stateModifier = model.get('isNew') ? 'new' : 'edit';

      title.push(this._t('admin.apps.endpoints.title'));

      if (model.get('title')) {
        title.push(model.get('title'));
      } else {
        title.push(this._t(type.titleKey));
        title.push(this._t('admin.apps.endpoints.headings.' + stateModifier));
      }

      return title.join(' / ');
    },
    _t: function _t(key) {
      return this.get('i18n').t(key);
    },
    getTypeByName: function getTypeByName(name) {
      var matched = this.get('types').filter(function (record) {
        return record.name === name;
      });

      return matched.length ? matched[0] : {};
    }
  });
});