define('frontend-cp/session/agent/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    active: false,
    macro: service(),
    session: service(),

    beforeModel: function beforeModel(transition) {
      if (transition.queryParams.trial === 'true' && this.get('session.user.role.isAdminOrHigher')) {
        this.replaceWith('session.agent.welcome');
      }
    },
    afterModel: function afterModel() {
      this.get('macro').fetchMacros();
      return this._super.apply(this, arguments);
    },
    activate: function activate() {
      this.set('active', true);
    },
    deactivate: function deactivate() {
      this.set('active', false);
    },


    actions: {
      error: function error(_error, transition) {
        if (_error) {
          console.error(_error); // eslint-disable-line no-console
          return this.transitionTo('session.agent.cases.index');
        }
      }
    }
  });
});