define("frontend-cp/components/ko-universal-search/result/user/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "avatar": "ko-universal-search_result_user__avatar_14c2uv",
    "title": "ko-universal-search_result_user__title_14c2uv",
    "role": "ko-universal-search_result_user__role_14c2uv",
    "separator": "ko-universal-search_result_user__separator_14c2uv",
    "company": "ko-universal-search_result_user__company_14c2uv",
    "icon": "ko-universal-search_result_user__icon_14c2uv",
    "identity": "ko-universal-search_result_user__identity_14c2uv",
    "identityMain": "ko-universal-search_result_user__identityMain_14c2uv"
  };
});