define('frontend-cp/components/ko-admin/sla/edit/targets/component', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    sla: null,
    priorities: [],

    findTarget: function findTarget(priority, type) {
      return this.get('sla.targets').find(function (metric) {
        return metric.get('priority') === priority && metric.get('slaTargetType') === type;
      });
    },
    getOperationalHours: function getOperationalHours(slaTargetType) {
      var target = this.get('sla.targets').find(function (target) {
        return target.get('slaTargetType') === slaTargetType;
      });
      return target ? target.get('operationalHours') : 'BUSINESS_HOURS';
    },


    actions: {
      setTargetGoalInSeconds: function setTargetGoalInSeconds(priority, slaTargetType, goalInSeconds) {
        var existingTarget = this.findTarget(priority, slaTargetType);
        if (_npmLodash.default.isNumber(goalInSeconds) && !_npmLodash.default.isNaN(goalInSeconds)) {
          if (!existingTarget) {
            this.get('sla.targets').pushObject(EmberObject.create({
              priority: priority,
              slaTargetType: slaTargetType,
              goalInSeconds: goalInSeconds,
              operationalHours: this.getOperationalHours(slaTargetType)
            }));
          } else {
            existingTarget.set('goalInSeconds', goalInSeconds);
          }
        } else if (existingTarget) {
          this.get('sla.targets').removeObject(existingTarget);
        }
      }
    }
  });
});