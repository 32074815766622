define('frontend-cp/components/ko-timeline-2/list/item/status/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var empty = Ember.computed.empty;
  exports.default = Component.extend({
    tagName: '',
    item: null,
    noRecipients: empty('item.original.recipients')
  });
});