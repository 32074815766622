define('frontend-cp/services/error-handler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Service.extend({
    sessionLoadingFailedStrategy: service('error-handler/session-loading-failed-strategy'),
    notificationStrategy: service('error-handler/notification-strategy'),
    permissionDeniedStrategy: service('error-handler/permissions-denied-strategy'),
    resourceNotFoundStrategy: service('error-handler/resource-not-found-strategy'),
    credentialExpiredStrategy: service('error-handler/credential-expired-strategy'),
    formInvalidStrategy: service('error-handler/form-invalid-strategy'),
    genericStrategy: service('error-handler/generic-strategy'),
    licenseExpiredStrategy: service('error-handler/license-expired-strategy'),
    csrfInvalidStrategy: service('error-handler/csrf-invalid-strategy'),

    enabled: true,

    init: function init() {
      this._super.apply(this, arguments);

      var strategies = EmberObject.create({
        FIELD_INVALID: this.get('formInvalidStrategy'),
        FIELD_REDUNDANT: null,
        FIELD_REQUIRED: this.get('formInvalidStrategy'),
        FIELD_DUPLICATE: this.get('formInvalidStrategy'),
        FIELD_EMPTY: this.get('formInvalidStrategy'),
        AUTHENTICATION_FAILED: null,
        AUTHORIZATION_REQUIRED: this.get('sessionLoadingFailedStrategy'),
        NOTIFICATION: this.get('notificationStrategy'),
        PERMISSIONS_DENIED: this.get('permissionDeniedStrategy'),
        RESOURCE_NOT_FOUND: this.get('resourceNotFoundStrategy'),
        CREDENTIAL_EXPIRED: this.get('credentialExpiredStrategy'),
        LICENSE_EXPIRED: this.get('licenseExpiredStrategy'),
        PARAMETERS_INCONSISTENT: this.get('sessionLoadingFailedStrategy'),
        ANY_FIELD_REQUIRED: null,
        CSRF_FAILED: this.get('csrfInvalidStrategy'),
        _GENERIC: this.get('genericStrategy')
      });

      this.set('strategies', strategies);
    },
    disableWhile: function disableWhile(during) {
      var _this = this;

      this.set('enabled', false);
      return RSVP.resolve(during()).finally(function () {
        return _this.set('enabled', true);
      });
    },
    process: function process(error) {
      var _this2 = this;

      if (!this.get('enabled')) {
        throw error;
      }

      if (error && error.errors) {
        var strategies = this.get('strategies');
        var notifications = error.errors.filterBy('code', 'NOTIFICATION');

        if (notifications.length) {
          this.processNotifications(notifications);
          /*eslint-disable no-console */
          error.errors.filter(function (error) {
            return error.code !== 'NOTIFICATION';
          }).forEach(function (err) {
            return console.error(new Error(err.code + ': ' + err.message));
          });
          /*eslint-enable no-console */
        } else {
          this.processErrors(error.errors);
        }

        Object.keys(strategies).forEach(function (key) {
          _this2.processStrategy(key);
        });
      }

      // we have to throw error to reject Promise
      throw error;
    },
    processNotifications: function processNotifications(notifications) {
      var strategy = this.getStrategy('NOTIFICATION');
      notifications.forEach(function (record) {
        return strategy.accept(record);
      });
    },
    processErrors: function processErrors(errors) {
      var _this3 = this;

      errors.forEach(function (record) {
        var strategy = _this3.getStrategy('_GENERIC');

        if (_this3.hasStrategy(record.code)) {
          strategy = _this3.getStrategy(record.code);
        }

        if (strategy) {
          strategy.accept(record);
        }
      });
    },
    hasStrategy: function hasStrategy(code) {
      var strategies = this.get('strategies');
      return strategies[code] || strategies[code] === null;
    },
    getStrategy: function getStrategy(code) {
      var strategies = this.get('strategies');
      return strategies[code];
    },
    processStrategy: function processStrategy(key) {
      var strategies = this.get('strategies');
      if (strategies[key]) {
        return strategies[key].process();
      }

      return 0;
    }
  });
});