define('frontend-cp/session/admin/people/businesshours/schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _virtualModel.model)('business-hour', {
    title: (0, _virtualModel.attr)(),
    zones: (0, _virtualModel.model)('zone', {
      monday: (0, _virtualModel.list)(),
      tuesday: (0, _virtualModel.list)(),
      wednesday: (0, _virtualModel.list)(),
      thursday: (0, _virtualModel.list)(),
      friday: (0, _virtualModel.list)(),
      saturday: (0, _virtualModel.list)(),
      sunday: (0, _virtualModel.list)()
    }),
    holidays: (0, _virtualModel.many)((0, _virtualModel.model)('businesshour-holiday', {
      title: (0, _virtualModel.attr)(),
      date: (0, _virtualModel.attr)(),
      openHours: (0, _virtualModel.list)()
    }))
  });
});