define('frontend-cp/processes/case', ['exports', 'frontend-cp/processes/process'], function (exports, _process) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _process.default.extend({
    type: 'case',
    socket: service(),
    store: service(),

    setup: function setup(model) {
      var _this = this;

      this._super.apply(this, arguments);
      this.connectToChannel(model);
      model.addObserver('realtimeChannel', function () {
        return _this.connectToChannel(model);
      });
    },
    connectToChannel: function connectToChannel(model) {
      var channelName = model.get('realtimeChannel');
      if (!channelName) {
        return;
      }
      this._channel = this.get('socket').channel(channelName).lock();

      this._channel.on('CHANGE', this, 'onChange');
      this._channel.on('NEW_POST', this, 'onNewPost');
      this._channel.on('CHANGE_POST', this, 'onChangePost');
      this._channel.join();
    },
    onChange: function onChange(data) {
      var caseState = this.get('state');
      if (caseState) {
        caseState.updateCaseFromKRE(data);
      }
    },
    onNewPost: function onNewPost() {
      var timeline = this.get('timeline');
      if (timeline) {
        timeline.get('fetchNewerFromKRE').perform();
      }
    },
    onChangePost: function onChangePost(data) {
      var post = this.get('store').peekRecord('post', data.resource_id);
      if (post) {
        post.reload();
      }
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);

      if (this._channel) {
        this._channel.off('CHANGE', this, 'onChange');
        this._channel.off('NEW_POST', this, 'onNewPost');
        this._channel.off('CHANGE_POST', this, 'onChangePost');
        this._channel.unlock();
      }
    }
  });
});