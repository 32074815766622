define("frontend-cp/components/ko-admin/apps/endpoints/select-type/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-admin_apps_endpoints_select-type__container_4ep6mu",
    "icon": "ko-admin_apps_endpoints_select-type__icon_4ep6mu",
    "action": "ko-admin_apps_endpoints_select-type__action_4ep6mu",
    "title": "ko-admin_apps_endpoints_select-type__title_4ep6mu",
    "caption": "ko-admin_apps_endpoints_select-type__caption_4ep6mu"
  };
});