define("frontend-cp/login-agent/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "fieldHeight": "80px",
    "smoothTransition": "cubic-bezier(0.19, 1, 0.22, 1)",
    "login": "login-agent__login_jaajvo",
    "fade-in": "login-agent__fade-in_jaajvo",
    "box": "login-agent__box_jaajvo",
    "box-extended": "login-agent__box-extended_jaajvo",
    "header": "login-agent__header_jaajvo",
    "header--reset": "login-agent__header--reset_jaajvo",
    "sub-header": "login-agent__sub-header_jaajvo",
    "image": "login-agent__image_jaajvo",
    "actions": "login-agent__actions_jaajvo",
    "form-content": "login-agent__form-content_jaajvo",
    "fields-container-top": "login-agent__fields-container-top_jaajvo",
    "fields-container-bottom": "login-agent__fields-container-bottom_jaajvo",
    "form-content-down": "login-agent__form-content-down_jaajvo",
    "login-form": "login-agent__login-form_jaajvo",
    "reset-form": "login-agent__reset-form_jaajvo",
    "is-login": "login-agent__is-login_jaajvo",
    "button": "login-agent__button_jaajvo",
    "divider": "login-agent__divider_jaajvo",
    "divider-rule": "login-agent__divider-rule_jaajvo",
    "divider-text": "login-agent__divider-text_jaajvo",
    "google-signin": "login-agent__google-signin_jaajvo ko-button__primary_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "disabled": "login-agent__disabled_jaajvo",
    "is-loading": "login-agent__is-loading_jaajvo",
    "google-icon": "login-agent__google-icon_jaajvo",
    "message--good": "login-agent__message--good_jaajvo",
    "slide": "login-agent__slide_jaajvo",
    "message--bad": "login-agent__message--bad_jaajvo",
    "container": "login-agent__container_jaajvo",
    "front": "login-agent__front_jaajvo",
    "back": "login-agent__back_jaajvo",
    "flipped": "login-agent__flipped_jaajvo",
    "aside": "login-agent__aside_jaajvo",
    "aside__logo": "login-agent__aside__logo_jaajvo",
    "aside__promotion": "login-agent__aside__promotion_jaajvo",
    "promotion__wrapper": "login-agent__promotion__wrapper_jaajvo",
    "promotion__label": "login-agent__promotion__label_jaajvo",
    "promotion__link": "login-agent__promotion__link_jaajvo",
    "promotion__title": "login-agent__promotion__title_jaajvo",
    "promotion__description": "login-agent__promotion__description_jaajvo",
    "section": "login-agent__section_jaajvo",
    "loading": "login-agent__loading_jaajvo",
    "loading__dot": "login-agent__loading__dot_jaajvo",
    "loading-dots": "login-agent__loading-dots_jaajvo",
    "login-actions": "login-agent__login-actions_jaajvo"
  };
});