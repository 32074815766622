define('frontend-cp/session/admin/people/roles/new/controller', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    metrics: service(),

    // Actions
    actions: {
      saved: function saved() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'role_created',
            object: this.get('model').get('id'),
            type: this.get('model').get('roleType'),
            name: this.get('model').get('title')
          });
        }

        this.send('transitionToIndex');
      },
      transitionToIndex: function transitionToIndex() {
        this.transitionToRoute('session.admin.people.roles.index');
      }
    },

    // Methods
    isEdited: function isEdited() {
      var model = this.get('model');
      return model.get('hasDirtyAttributes') && Object.keys(model.changedAttributes()).length > 0;
    },
    initEdits: function initEdits() {
      this.get('model').rollbackAttributes();
    }
  });
});