define('frontend-cp/models/identity-autocomplete-email', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    identity: _emberData.default.belongsTo('identity-email'),

    user: _emberData.default.belongsTo('user', { async: true })
  });
});