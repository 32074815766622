define('frontend-cp/components/ko-admin/oauth-apps/edit/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var $ = Ember.$;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var not = Ember.computed.not;


  var MODE_ALL_SCOPES = 'all';
  var MODE_SPECIFIED_SCOPES = 'specified';

  var ACCESS_WRITE = 'write';
  var ACCESS_READ = 'read';

  exports.default = Component.extend({
    tagName: '',
    i18n: service(),
    notification: service(),
    virtualModel: service(),
    session: service(),
    app: null,
    editedApp: null,
    schema: null,
    onCancel: null,
    onDelete: null,
    onDone: null,
    isCreated: false,
    isUploading: false,

    canBeDeleted: not('app.isNew'),

    appLogo: computed('app.logo', function () {
      return {
        id: 0,
        url: this.get('app.logo.url'),
        data: null
      };
    }),

    selectedScopeMode: computed('app.scopes', function () {
      if (this.get('app.scopes').length > 0) {
        return MODE_SPECIFIED_SCOPES;
      } else {
        return MODE_ALL_SCOPES;
      }
    }),

    scopeModes: [MODE_ALL_SCOPES, MODE_SPECIFIED_SCOPES],

    scopes: computed('app.scopes', function () {
      var scopes = [];
      var usedScopes = [];
      this.get('app.scopes').forEach(function (name) {
        var _name$split = name.split(':', 2),
            _name$split2 = _slicedToArray(_name$split, 2),
            scope = _name$split2[0],
            level = _name$split2[1];

        usedScopes[scope] = level ? level : ACCESS_WRITE;
      });
      ['users', 'conversations', 'insights', 'search', 'configuration'].forEach(function (name) {
        var scope = {
          name: name,
          isEnabled: usedScopes.hasOwnProperty(name),
          level: usedScopes.hasOwnProperty(name) ? usedScopes[name] : ACCESS_WRITE
        };
        scopes.push(scope);
      });
      return scopes;
    }),

    scopeAccess: [ACCESS_WRITE, ACCESS_READ],

    getSelectedScopes: function getSelectedScopes() {
      return this.get('scopes').filter(function (scope) {
        return scope.isEnabled;
      }).map(function (scope) {
        return scope.name + ':' + scope.level;
      });
    },
    uploadFile: function uploadFile(image) {
      var _this = this;

      var formData = new FormData();

      var _get$getProperties = this.get('session').getProperties('sessionId', 'csrfToken'),
          sessionId = _get$getProperties.sessionId,
          csrfToken = _get$getProperties.csrfToken;

      formData.append('name', image.name);
      formData.append('content', image);

      this.set('isUploading', true);

      $.ajax({
        url: '/api/v1/files',
        type: 'POST',
        headers: {
          'X-Session-ID': sessionId,
          'X-CSRF-Token': csrfToken
        },
        data: formData,
        success: function success(response) {
          _this.set('appLogo.id', response.data.id);
          _this.set('isUploading', false);
        },
        error: function error(response) {
          _this.get('notification').error(_this.get('i18n').t('admin.oauthapps.edit.upload.failure'));
          _this.set('isUploading', false);
        },
        cache: false,
        contentType: false,
        processData: false
      });
    },


    finish: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              this.get('onDone')();

            case 1:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    actions: {
      save: function save() {
        var logoFileId = this.get('appLogo.id');
        if (logoFileId) {
          this.set('editedApp.logoFileId', logoFileId);
        }
        this.set('editedApp.scopes', this.getSelectedScopes());
        var app = this.get('app');
        var editedApp = this.get('editedApp');
        var schema = this.get('schema');
        return this.get('virtualModel').save(app, editedApp, schema);
      },
      triggerUpload: function triggerUpload() {
        $('#oauth-client-logo-file-field').click();
      },
      uploadLogo: function uploadLogo(files) {
        var _this2 = this;

        var image = files[0];

        if (image.type.includes('image')) {
          var reader = new FileReader();

          reader.onload = function () {
            _this2.set('appLogo.url', null);
            _this2.set('appLogo.data', reader.result);
          };

          reader.readAsDataURL(image);
          this.uploadFile(image);
        } else {
          this.get('notification').error(this.get('i18n').formatHtmlMessage('admin.oauthapps.edit.upload.incorrect_file_type', { filename: image.name }));
        }
      },
      copiedToClipboard: function copiedToClipboard() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.copied_to_clipboard'),
          autodismiss: true
        });
      }
    }
  });
});