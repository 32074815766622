define("frontend-cp/components/ko-pinned-notes/dropdown/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "root": "ko-pinned-notes_dropdown__root_12yvrb",
    "subject": "ko-pinned-notes_dropdown__subject_12yvrb",
    "note-info": "ko-pinned-notes_dropdown__note-info_12yvrb",
    "note-item": "ko-pinned-notes_dropdown__note-item_12yvrb",
    "unpin-note": "ko-pinned-notes_dropdown__unpin-note_12yvrb",
    "note-item-inner": "ko-pinned-notes_dropdown__note-item-inner_12yvrb",
    "processing": "ko-pinned-notes_dropdown__processing_12yvrb",
    "note-parent": "ko-pinned-notes_dropdown__note-parent_12yvrb",
    "cases-note": "ko-pinned-notes_dropdown__cases-note_12yvrb",
    "note-org-logo": "ko-pinned-notes_dropdown__note-org-logo_12yvrb",
    "title": "ko-pinned-notes_dropdown__title_12yvrb",
    "involved-users": "ko-pinned-notes_dropdown__involved-users_12yvrb",
    "note-meta": "ko-pinned-notes_dropdown__note-meta_12yvrb",
    "on": "ko-pinned-notes_dropdown__on_12yvrb",
    "avatar": "ko-pinned-notes_dropdown__avatar_12yvrb",
    "name": "ko-pinned-notes_dropdown__name_12yvrb",
    "last-updated": "ko-pinned-notes_dropdown__last-updated_12yvrb",
    "status": "ko-pinned-notes_dropdown__status_12yvrb",
    "middot": "ko-pinned-notes_dropdown__middot_12yvrb"
  };
});