define('frontend-cp/components/ko-agent-content/sidebar/requester-info-card/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Attributes:
    user: null,
    tagName: '',
    removedOrg: null,
    processingOrg: false,

    // State
    showAvatarChangeOptions: false,

    // Services
    i18n: service(),
    store: service(),
    session: service(),
    router: service('-routing'),
    confirmation: service(),
    notification: service(),

    // CPs
    isMe: computed('user', function () {
      return this.get('session.user.id') === this.get('user.id');
    }),

    isNewCase: computed('user', function () {
      return this.get('router.currentRouteName').includes('session.agent.cases.new');
    }),

    unsetOrgFromUser: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(user, org) {
      var i18n, opts, message;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              i18n = this.get('i18n');

              user.set('organization', null);
              opts = { adapterOptions: { setOrganization: true } };
              _context.prev = 3;
              _context.next = 6;
              return user.save(opts);

            case 6:
              message = i18n.t('organization.removal_passed', {
                name: user.get('fullName'),
                org: org.get('name')
              });

              this.get('notification').success(message);
              _context.next = 14;
              break;

            case 10:
              _context.prev = 10;
              _context.t0 = _context['catch'](3);

              this.get('notification').error(i18n.t('organization.removal_failed'));
              user.set('organization', org);

            case 14:
              _context.prev = 14;

              this.sendAction('updateOrgRemovalState', false, null);
              return _context.finish(14);

            case 17:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[3, 10, 14, 17]]);
    })),

    actions: {
      unsetOrganization: function unsetOrganization() {
        var _this = this;

        var i18n = this.get('i18n');
        var user = this.get('user');
        if (!user.id) {
          user = user.content;
        }

        var org = user.get('organization');

        this.get('confirmation').confirm({
          intlConfirmationHeader: i18n.t('organization.confirm.remove_header'),
          intlConfirmationBody: i18n.t('organization.confirm.remove_body', { name: user.get('fullName'), org: org.get('name') }),
          intlConfirmLabel: i18n.t('organization.confirm.remove_confirm'),
          isIntl: true
        }).then(function () {
          _this.set('removedOrg', org.get('name'));
          _this.get('unsetOrgFromUser').perform(user, org);
          _this.sendAction('updateOrgRemovalState', true, org.get('name'));
        });
      }
    }
  });
});