define("frontend-cp/components/ko-admin/messenger/preview/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "preview-container": "ko-admin_messenger_preview__preview-container_blt33",
    "preview": "ko-admin_messenger_preview__preview_blt33",
    "preview__content": "ko-admin_messenger_preview__preview__content_blt33",
    "preview__updated-background": "ko-admin_messenger_preview__preview__updated-background_blt33",
    "is-animating": "ko-admin_messenger_preview__is-animating_blt33",
    "preview-light-text": "ko-admin_messenger_preview__preview-light-text_blt33",
    "preview__main-text": "ko-admin_messenger_preview__preview__main-text_blt33",
    "preview__title": "ko-admin_messenger_preview__preview__title_blt33",
    "preview__welcome-message": "ko-admin_messenger_preview__preview__welcome-message_blt33",
    "preview__textarea": "ko-admin_messenger_preview__preview__textarea_blt33",
    "preview__textarea__content": "ko-admin_messenger_preview__preview__textarea__content_blt33",
    "preview__textarea__footer": "ko-admin_messenger_preview__preview__textarea__footer_blt33",
    "preview__button": "ko-admin_messenger_preview__preview__button_blt33",
    "preview__label": "ko-admin_messenger_preview__preview__label_blt33",
    "preview__widgets": "ko-admin_messenger_preview__preview__widgets_blt33",
    "preview__widget": "ko-admin_messenger_preview__preview__widget_blt33",
    "widget-in": "ko-admin_messenger_preview__widget-in_blt33",
    "preview__widget__title": "ko-admin_messenger_preview__preview__widget__title_blt33",
    "agents": "ko-admin_messenger_preview__agents_blt33",
    "agent-pic": "ko-admin_messenger_preview__agent-pic_blt33",
    "agent-names": "ko-admin_messenger_preview__agent-names_blt33",
    "bubble": "ko-admin_messenger_preview__bubble_blt33",
    "bubble__icon": "ko-admin_messenger_preview__bubble__icon_blt33",
    "article": "ko-admin_messenger_preview__article_blt33",
    "fadein-articles": "ko-admin_messenger_preview__fadein-articles_blt33",
    "no-data-message": "ko-admin_messenger_preview__no-data-message_blt33",
    "author-pic": "ko-admin_messenger_preview__author-pic_blt33",
    "article__title": "ko-admin_messenger_preview__article__title_blt33",
    "content-placeholder": "ko-admin_messenger_preview__content-placeholder_blt33",
    "shimmer": "ko-admin_messenger_preview__shimmer_blt33",
    "tweet": "ko-admin_messenger_preview__tweet_blt33",
    "tweet__text": "ko-admin_messenger_preview__tweet__text_blt33",
    "text--small": "ko-admin_messenger_preview__text--small_blt33",
    "text--light": "ko-admin_messenger_preview__text--light_blt33"
  };
});