define('frontend-cp/components/ko-text-editor/at-mention-support/component', ['exports', 'frontend-cp/lib/keycodes', 'ember-component-inbound-actions/inbound-actions', 'frontend-cp/lib/at-mentions'], function (exports, _keycodes, _inboundActions, _atMentions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var RSVP = Ember.RSVP;


  var MENTION_CLASS_NAME = 'ko-mention';

  exports.default = Component.extend(_inboundActions.default, {
    tagName: '',

    i18n: service(),
    session: service(),
    uuid: service(),
    agentCache: service('cache/agent-cache'),

    atTrigger: '@',
    data: null,
    searchKey: 'name',
    _editor: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var editor = this.get('_editor');

      if (!editor) {
        return;
      }

      this._fetchUsers().then(function (users) {
        return _this._configure(editor, users);
      }).then(function () {
        return _this._subscribeToEvents(editor);
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var editor = this.get('_editor');

      if (!editor) {
        return;
      }

      editor.$el.atwho('destroy');
    },
    handlePaste: function handlePaste(html) {
      return this._handlePaste(html);
    },
    _fetchUsers: function _fetchUsers() {
      var _this2 = this;

      var data = this.get('data');

      if (data) {
        return RSVP.resolve(data);
      }

      return this.get('agentCache').getAgentsForMentions().then(function (users) {
        return _this2._parseUsers(users);
      }).then(function (users) {
        _this2.set('data', users);
        return users;
      });
    },
    _headerTemplate: function _headerTemplate() {
      return '\n      <div class="mentions-header">\n        <div class="mentions-header-contents">\n          <div class="mentions-header-left"><span class="text--bold">&uarr;&darr;</span> ' + this.get('i18n').t('generic.to_browse') + '</div>\n          <div class="mentions-header-right">\n            <span class="text--bold text--capitalize">' + this.get('i18n').t('generic.keyboard_shortcuts.enter') + '</span> ' + this.get('i18n').t('generic.to_select') + '\n          </div>\n        </div>\n      </div>';
    },
    _itemTemplate: function _itemTemplate() {
      var _this3 = this;

      return function (map) {
        return '\n        <li class="mentions-list-item-wrapper">\n          <div class="mentions-list-item">\n            <div class="mentions-avatar-wrapper">\n              <img src="${avatar}" class="mentions-avatar">\n            </div>\n            <div class="mentions-user-data">\n              <div class="mentions-name-wrapper">\n                  <div class="mentions-full-name">${name}</div>\n                  ' + (map.isMe ? '\n                  <div class="mentions-you-wrapper">\n                    <span class="mentions-you-text">\n                      ' + _this3.get('i18n').t('generic.you') + '\n                    </span>\n                  </div>' : '') + '\n              </div>\n              <div class="mentions-primary-email">${email}</div>\n            </div>\n          </div>\n        </li>';
      };
    },
    _configure: function _configure(editor, users) {
      var config = Object.assign({}, this._config(), { data: users });

      editor.$el.atwho(config);
    },
    _subscribeToEvents: function _subscribeToEvents(editor) {
      this._handleEnterPressWhenMentionsPopupOpen(editor);
      this._handleMentionsSelection(editor);
    },
    _handleEnterPressWhenMentionsPopupOpen: function _handleEnterPressWhenMentionsPopupOpen(editor) {
      editor.events.on('keydown', function (e) {
        if (e.which === _keycodes.enter && editor.$el.atwho('isSelecting')) {
          return false;
        }
      }, true);
    },
    _handleMentionsSelection: function _handleMentionsSelection(editor) {
      var _this4 = this;

      editor.$el.off('selectstart.ko-mentions');
      editor.$el.on('selectstart.ko-mentions', function (e) {
        editor.$el.one('mouseup keyup', function (e) {
          var selection = window.getSelection();

          if (selection.rangeCount !== 1) {
            return;
          }

          var range = selection.getRangeAt(0);
          var newRange = range.cloneRange();

          var isSelectionStartInAMention = _this4._isSelectionContainerInsideMention(range.startContainer);
          var isSelectionEndInAMention = _this4._isSelectionContainerInsideMention(range.endContainer);

          if (isSelectionStartInAMention) {
            newRange.setStartBefore(range.startContainer);
          }

          if (isSelectionEndInAMention) {
            newRange.setEndAfter(range.endContainer);
          }

          if (isSelectionStartInAMention || isSelectionEndInAMention) {
            selection.removeAllRanges();
            selection.addRange(newRange);
          }
        });
      });
    },
    _isSelectionContainerInsideMention: function _isSelectionContainerInsideMention(container) {
      return container.parentElement && container.parentElement.classList.contains(MENTION_CLASS_NAME);
    },
    _config: function _config() {
      var currentUserId = this.get('session.user.id');
      var uuid = this.get('uuid');

      return {
        at: this.get('atTrigger'),
        displayTpl: this._itemTemplate(),
        headerTpl: this._headerTemplate(),
        searchKey: this.get('searchKey'),
        lookUpOnClick: false,
        limit: 25,
        callbacks: {
          beforeInsert: function beforeInsert(value, $li) {
            var data = $li.data('item-data');
            var id = data.id;
            var type = data.type;

            this.query.el.attr('id', uuid.uuid()).attr('data-mention-id', id).attr('data-mention-type', type).addClass('fr-deletable').addClass(MENTION_CLASS_NAME);

            if (id === currentUserId) {
              this.query.el.addClass('ko-mention-me');
            }

            return value;
          },
          beforeReposition: function beforeReposition(offset) {
            offset.top = this.rect().top - this.$el.find('.atwho-view').height();
          }
        }
      };
    },
    _parseUsers: function _parseUsers(users) {
      var _this5 = this;

      return users.toArray().filterBy('isEnabled').map(function (user) {
        return {
          id: get(user, 'id'),
          type: 'user',
          name: get(user, 'fullName'),
          email: get(user, 'primaryEmail.email'),
          avatar: get(user, 'avatar'),
          isMe: get(user, 'id') === _this5.get('session.user.id')
        };
      }).sortBy('name');
    },
    _handlePaste: function _handlePaste(html) {
      var uuid = this.get('uuid');
      var mentions = (0, _atMentions.extractMentions)(html);

      return mentions.reduce(function (html, _ref) {
        var id = _ref.id;

        var $html = $('<div />', { html: html });

        var mention = $html.find('#' + id)[0];

        if (mention) {
          var $mention = $(mention);
          $mention.attr('id', uuid.uuid());

          html = $html.html();
        }

        return html;
      }, html);
    }
  });
});