define('frontend-cp/services/rendered-text-width', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  var Service = Ember.Service;
  exports.default = Service.extend({
    createCanvas: on('init', function () {
      this.set('ctx', document.createElement('canvas').getContext('2d'));
    }),

    measureText: function measureText(text, font) {
      var ctx = this.get('ctx');
      if (font) {
        ctx.font = font;
      }
      return ctx.measureText(text).width;
    }
  });
});