define('frontend-cp/processes/process', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var service = Ember.inject.service;
  var Evented = Ember.Evented;
  var get = Ember.get;


  /**
   * A process instance is intended to function somewhat like a tab process in
   * Chrome.
   *
   * Our app supports opening cases, users, etc in tabs (within our application)
   * and we want to be able to have these tabs perform tasks in the background,
   * even if they are not the currently active tab.
   *
   * In order to do this, we are going to keep a collection of these long lived
   * process instances that will be responsible for running different tasks such
   * as fetching data or subscribing to channels etc.
   *
   * In general, for each application tab, there will be a corresponding
   * background process, however, not every process will necessarily correspond to
   * a tab.
   *
   * Processes can be serialized and saved to sessionStorage and therefore should
   * know how to `restore` themselves.
   *
   * When a process is destroyed, it will cancel any tasks that it currently has
   * running. By destroying a process, we can simply throw away all associated state
   * in one motion.
   *
   * Process Events
   * ==============
   *
   * The following are lifecycle events that one can hook in to in order to run
   * code at different points in the process' lifecycle.
   *
   * - `willForeground`
   * - `willBackground`
   * - `willDestroy`
   */

  var Process = EmberObject.extend(Evented, {
    store: service(),

    pid: null,
    model: null,
    type: null,

    initialize: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(model) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.setup(model);

            case 2:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    restore: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(json) {
      var model;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.restoreModel(json);

            case 2:
              model = _context2.sent;
              _context2.next = 5;
              return this.setup(model);

            case 5:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    restoreModel: function restoreModel(json) {
      return this.get('store').findRecord(this.get('type'), json.modelId);
    },
    setup: function setup(model) {
      this.set('model', model);
    },
    serialize: function serialize() {
      return {
        pid: this.get('pid'),
        type: this.get('type'),
        modelId: this.get('model.id')
      };
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);
    }
  });

  Process.reopenClass({
    generatePid: function generatePid(model) {
      var id = get(model, 'id');
      var type = model.constructor.modelName;
      return type + ':' + id;
    }
  });

  exports.default = Process;
});