define('frontend-cp/services/validation', ['exports', 'npm:lodash', 'frontend-cp/utils/format-validations'], function (exports, _npmLodash, _formatValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isBlank = Ember.isBlank;
  var Service = Ember.Service;
  exports.default = Service.extend({
    exists: function exists(value) {
      return !isBlank(value);
    },
    isEmail: _formatValidations.default.validateEmailFormat,
    isPhone: _formatValidations.default.validatePhoneFormat,

    /*
     * validates an object with an array of rules
     *
     * @param  {Object} data
     * @param  {Array} rules
     * @param  {Array} messages
     *
     * @return {Object}
     */
    validateAll: function validateAll(data, rules, messages) {
      var _this = this;

      return _npmLodash.default.reduce(data, function (result, value, field) {
        var validation = _this.validateField(value, rules[field]);
        if (!validation) {
          result[field] = [{ message: messages[field] }];
        }
        return result;
      }, {});
    },


    /*
     * returns a boolean whether the field has
     * failed any of the given validations
     *
     * @param  {Mixed} value
     * @param  {Rules} rules
     *
     * @return {Boolean}
     */
    validateField: function validateField(value, rules) {
      var _this2 = this;

      return _npmLodash.default.size(_npmLodash.default.filter(rules, function (rule) {
        return !_this2[rule](value);
      })) <= 0;
    }
  });
});