define('frontend-cp/helpers/ko-contextual-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var helper = Ember.Helper.helper;
  exports.default = helper(function (args) {
    return Reflect.apply(args[0], args[1], args.slice(2));
  });
});