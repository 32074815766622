define('frontend-cp/services/reports', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({
    download: function download(report) {
      var id = report.get('id');
      var adapter = getOwner(this).lookup('adapter:application');
      var url = adapter.namespace + '/reports/' + id + '/download';
      window.location = url;
    }
  });
});