define('frontend-cp/components/ko-admin/views/edit/columns/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    //Params:
    columns: null,
    availableColumns: null,

    onColumnAdd: function onColumnAdd() {},
    onColumnRemove: function onColumnRemove() {},
    onColumnReorder: function onColumnReorder() {},

    unusedColumns: computed('availableColumns.[]', 'columns.[]', function () {
      var usedColumns = this.get('columns');
      if (!usedColumns) {
        return this.get('availableColumns');
      }

      return this.get('availableColumns').filter(function (availableColumn) {
        return !usedColumns.includes(availableColumn);
      }).sortBy('title');
    })
  });
});