define('frontend-cp/components/ko-cases-list/column/user-composite/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    model: null,

    session: service(),

    //Lifecycle Hooks
    didReceiveAttrs: function didReceiveAttrs() {
      var identities = [];

      if (this.get('model.primaryEmailAddress')) {
        identities.push({ type: 'email', value: this.get('model.primaryEmailAddress') });
      }

      if (this.get('model.twitter.screenName')) {
        identities.push({ type: 'twitter', value: this.get('model.twitter.screenName') });
      }

      if (this.get('model.facebook.fullName')) {
        identities.push({ type: 'facebook', value: this.get('model.facebook.fullName') });
      }

      if (this.get('model.primaryPhoneNumber')) {
        identities.push({ type: 'phone', value: this.get('model.primaryPhoneNumber') });
      }

      this.set('identities', identities);
    },


    isMe: computed('session.user.id', 'model.id', function () {
      return this.get('session.user.id') === this.get('model.id');
    })
  });
});