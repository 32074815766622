define('frontend-cp/session/admin/messenger/engagements/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    store: service(),

    model: function model() {
      return this.get('store').query('engagement', { limit: 10000 });
    },


    // Actions
    actions: {
      edit: function edit(engagementId) {
        return this.transitionTo('session.admin.messenger.engagements.edit', engagementId);
      }
    }
  });
});