define('frontend-cp/components/ko-text-editor/link-manager/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    onAddLink: function onAddLink() {},
    onCancel: function onCancel() {},

    url: '',
    text: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('text', this.get('editor').selection.text());
    }
  });
});