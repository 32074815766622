define('frontend-cp/helpers/qa-cls', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var htmlSafe = Ember.String.htmlSafe;
  var helper = Ember.Helper.helper;
  exports.default = helper(function (classNames) {
    if (Ember.testing) {
      return htmlSafe(classNames.join(' '));
    }

    return '';
  });
});