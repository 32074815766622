define('frontend-cp/session/admin/customizations/brands/schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _virtualModel.model)('mailbox', {
    name: (0, _virtualModel.attr)(),
    locale: (0, _virtualModel.attr)(),
    domain: (0, _virtualModel.attr)(),
    subDomain: (0, _virtualModel.attr)(),
    alias: (0, _virtualModel.attr)({ nonStrictMatching: true }),
    sslCertificate: (0, _virtualModel.attr)({ nonStrictMatching: true }),
    privateKey: (0, _virtualModel.attr)({ nonStrictMatching: true })
  });
});