define('frontend-cp/components/ko-cases-list/column/user/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    user: computed('model', function () {
      var model = this.get('model');
      if (!model) {
        return null;
      }
      return model.get(this.get('propertyName'));
    })
  });
});