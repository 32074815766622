define('frontend-cp/session/admin/people/staff/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var oneWay = Ember.computed.oneWay;
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    permissions: service(),

    staff: oneWay('model'),

    enabledUsers: computed('filteredStaff.[]', function () {
      return this.get('filteredStaff').filterBy('isEnabled');
    }),

    disabledUsers: computed('filteredStaff.[]', function () {
      return this.get('filteredStaff').filterBy('isEnabled', false);
    }),

    filter: '',
    filteredStaff: computed('staff.[]', 'filter', function () {
      var staff = this.get('staff');
      var filter = this.get('filter');
      var regEx = new RegExp(filter, 'i');
      if (filter === '') {
        return staff.sortBy('fullName');
      }
      return staff.filter(function (staff) {
        return regEx.test(staff.get('fullName'));
      }).sortBy('fullName');
    }),

    canAddTeamMembers: computed(function () {
      var permissions = this.get('permissions');

      return permissions.has('users.update');
    })
  });
});