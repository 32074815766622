define('frontend-cp/serializers/organization', ['exports', 'frontend-cp/serializers/application', 'frontend-cp/lib/custom-field-serialization'], function (exports, _application, _customFieldSerialization) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _application.default.extend({
    plan: service(),
    attrs: {
      phones: { serialize: false },
      notes: { serialize: false },
      customFields: { serialize: false },
      createdAt: { serialize: false },
      updatedAt: { serialize: false }
    },

    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      resourceHash.links = {
        notes: 'notes',
        tags: 'tags'
      };
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      var plan = this.get('plan');
      if (!plan.has('shared_organizations')) {
        Reflect.deleteProperty(json, 'is_shared');
      }
      json.field_values = (0, _customFieldSerialization.serializeCustomFields)(snapshot.attr('customFields'));
      return json;
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      if (relationship.key === 'tags') {
        json.tags = (snapshot.hasMany('tags') || []).map(function (snapshot) {
          return snapshot.attr('name');
        }).toString();
      } else if (relationship.key === 'domains') {
        json.domains = (snapshot.hasMany('domains') || []).map(function (snapshot) {
          return snapshot.attr('domain');
        }).uniq().toString();
      } else {
        this._super.apply(this, arguments);
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var normalized = this._super.apply(this, arguments);
      if (requestType === 'findRecord') {
        normalized.data.attributes._isFullyLoaded = true;
      }
      return normalized;
    }
  });
});