define('frontend-cp/session/agent/cases/index/view/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    caseListTab: service(),
    tabStore: service(),

    model: function model(_ref) {
      var view_id = _ref.view_id;

      var model = this.modelFor('session.agent.cases.index').views.findBy('id', view_id);
      if (!model || !model.get('isEnabled')) {
        // isEnabled can be false if we move from view -> admin -> disable view
        // come back
        this.transitionTo('session.agent.cases.index');
      }
      return model;
    },
    afterModel: function afterModel() {
      // We just want to kick this request off in the background so no need to return the promise;
      this.get('caseListTab').updateViewCounts();
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      this.get('tabStore').setCasesViewId(model.get('id'));
    }
  });
});