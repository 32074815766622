define('frontend-cp/models/event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    color: _emberData.default.attr('string'),
    event: _emberData.default.attr('string'),
    iconUrl: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    properties: _emberData.default.attr()
  });
});