define('frontend-cp/session/admin/automation/triggers/edit/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)(), {
    model: function model(params) {
      return hash({
        channels: this.store.findAll('trigger-channel'),
        theTrigger: this.store.findRecord('trigger', params.trigger_id)
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      controller.setProperties(this.modelFor('session.admin.automation.triggers'));
    },


    // Actions
    actions: {
      didSave: function didSave() {
        this.transitionTo('session.admin.automation.triggers');
      },
      cancel: function cancel() {
        this.transitionTo('session.admin.automation.triggers');
      }
    }
  });
});