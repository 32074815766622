define('frontend-cp/adapters/note', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = new Ember.Inflector(Ember.Inflector.defaultRules);

  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      if (query.parent) {
        var id = query.parent.get('id');
        var pluralParentType = inflector.pluralize(query.parent.get('resourceType'));
        var url = this._super.apply(this, arguments);
        Reflect.deleteProperty(query, 'parent');
        return url.replace('/notes', '/' + pluralParentType + '/' + id + '/notes');
      }
      return this._super.apply(this, arguments);
    },
    urlForCreateRecord: function urlForCreateRecord(modelType, snapshot) {
      var url = this._super.apply(this, arguments);
      var type = snapshot.adapterOptions.type;
      var id = snapshot.adapterOptions[type];
      return url.replace('notes', type + 's' + '/' + id + '/notes');
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var _snapshot$adapterOpti = snapshot.adapterOptions,
          entityName = _snapshot$adapterOpti.entityName,
          entityId = _snapshot$adapterOpti.entityId;

      return this.namespace + '/' + entityName + '/' + entityId + '/' + modelName + 's/' + id;
    },
    getNoteSource: function getNoteSource(entity, entityId, noteId) {
      var limit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 15;

      var type = void 0;
      if (entity === 'cases') {
        type = 'posts';
      } else {
        type = 'activities';
      }
      var url = this.namespace + '/' + entity + '/' + entityId + '/' + type + '?resource_type=note&id=' + noteId + '&limit=' + limit;
      return this.ajax(url, 'GET');
    }
  });
});