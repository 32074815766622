define('frontend-cp/components/ko-lightbox/component', ['exports', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend(_component.default, {
    // Attributes
    imgSrc: null,

    tagName: '',

    // State
    showingLightbox: false,
    imgLoaded: false,

    // CPs
    imgStyle: computed('imgSrc', function () {
      return htmlSafe('background-image: url("' + this.get('imgSrc') + '")');
    }),

    keyboardShortcuts: {
      esc: function esc() {
        this.set('showingLightbox', false);
      }
    },

    actions: {
      toggleLightbox: function toggleLightbox() {
        this.toggleProperty('showingLightbox');
      },
      onImageLoaded: function onImageLoaded() {
        this.set('imgLoaded', true);
      }
    }
  });
});