define('frontend-cp/utils/banner-storage', ['exports', 'ember-smart-banner/utils/banner-storage.js'], function (exports, _bannerStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bannerStorage.default;
    }
  });
});