define('frontend-cp/components/ko-case-content/field/requester/trigger-value/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    dash: '-'
  });
});