define("frontend-cp/utils/country-properties", ["exports", "ember-countries/utils/country-properties"], function (exports, _countryProperties) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "countryIsoToValue", {
    enumerable: true,
    get: function () {
      return _countryProperties.countryIsoToValue;
    }
  });
  Object.defineProperty(exports, "isCountryWithState", {
    enumerable: true,
    get: function () {
      return _countryProperties.isCountryWithState;
    }
  });
  Object.defineProperty(exports, "isCountryWithoutZip", {
    enumerable: true,
    get: function () {
      return _countryProperties.isCountryWithoutZip;
    }
  });
});