define('frontend-cp/models/case-status', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    label: _emberData.default.attr('string', { defaultValue: '' }),
    statusType: _emberData.default.attr('string', { defaultValue: 'CUSTOM' }),
    sortOrder: _emberData.default.attr('number'),
    isSlaActive: _emberData.default.attr('boolean', { defaultValue: false }),
    isDeleted: _emberData.default.attr('boolean', { defaultValue: false }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});