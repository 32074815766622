define('frontend-cp/components/ko-universal-search/result/organization/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    result: null,

    domains: computed.readOnly('result.resultData.domains')
  });
});