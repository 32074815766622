define('frontend-cp/models/field-option', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _emberData.default.Model.extend({
    fielduuid: _emberData.default.attr('string'),
    values: _emberData.default.hasMany('locale-field', { async: false }),
    tag: _emberData.default.attr('string'),
    sortOrder: _emberData.default.attr('number', { default: 1 }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    parent: _emberData.default.belongsTo('any', { polymorphic: true, async: true }),

    // Services
    locale: service(),

    // CPs
    valueLocale: computed('values.[]', 'locale.accountDefaultLocaleCode', function () {
      return this.get('values').findBy('locale', this.get('locale.accountDefaultLocaleCode'));
    }),

    value: readOnly('valueLocale.translation')
  });
});