define('frontend-cp/components/ko-universal-search/result/article/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    result: null,

    creatorAvatar: computed.readOnly('result.resultData.creator.avatar'),
    creatorFullName: computed.readOnly('result.resultData.creator.fullName'),
    updatedAt: computed.readOnly('result.resultData.updatedAt'),
    status: computed.readOnly('result.resultData.status'),
    statusLabel: computed('status', function () {
      return 'generic.' + this.get('status').toLowerCase();
    }),

    section: computed.readOnly('result.resultData.section')
  });
});