define('frontend-cp/components/ko-insights/loader/cases/csat/component', ['exports', 'npm:lodash', 'frontend-cp/lib/object-difference', 'ember-diff-attrs'], function (exports, _npmLodash, _objectDifference, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectProxy = Ember.ObjectProxy;
  var PromiseProxyMixin = Ember.PromiseProxyMixin;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend({
    tagName: '',
    insights: service(),
    metricsQueryParams: null,
    onUpdate: function onUpdate() {},

    /** @private **/
    updatedQueryParams: null,

    didReceiveAttrs: (0, _emberDiffAttrs.default)('metricsQueryParams', function (changedAttrs) {
      var oldValues = changedAttrs && changedAttrs.metricsQueryParams ? changedAttrs.metricsQueryParams[0].data : {};
      var newValues = this.get('metricsQueryParams.data');

      Reflect.deleteProperty(oldValues, 'include');
      Reflect.deleteProperty(newValues, 'include');

      var differs = (0, _objectDifference.default)(oldValues, newValues);
      var allowedParameters = ['agent_id', 'team_id', 'interval', 'start_at', 'end_at', 'previous_start_at', 'previous_end_at', 'trial'];

      // if one of the allowed parameters changed, proceed with request
      if (_npmLodash.default.intersection(differs, allowedParameters).length) {
        this.set('updatedQueryParams', { data: newValues });
      }
    }),

    request: computed('updatedQueryParams', function () {
      var _this = this;

      var insights = this.get('insights');
      var adapter = getOwner(this).lookup('adapter:application');
      var endpoint = insights.csatEndpoint();

      return ObjectProxy.extend(PromiseProxyMixin).create({
        promise: adapter.ajax(endpoint, 'GET', this.get('updatedQueryParams')).then(function (data) {
          if (_this.isDestroying || _this.isDestroyed) {
            return null;
          }

          _this.attrs.onUpdate && _this.attrs.onUpdate(data.data);
          _this.set('series', data.data);

          return data;
        })
      });
    }),

    series: null,
    metric: computed.alias('series.metric')
  });
});