define('frontend-cp/session/admin/security/authentication/index/route', ['exports', 'frontend-cp/mixins/dirty-aware/route', 'frontend-cp/session/admin/security/authentication/index/settings'], function (exports, _route, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)(), {
    // Services
    settingsService: service('settings'),

    setupController: function setupController(controller) {
      controller.set('settings', this.get('settingsService').selectByKeys(_settings.default));
      controller.initEdits();
    }
  });
});