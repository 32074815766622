define("frontend-cp/components/ko-info-bar/field/checkbox/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "header": "ko-info-bar_field_checkbox__header_geubfu",
    "unset-mode-dropdown": "ko-info-bar_field_checkbox__unset-mode-dropdown_geubfu",
    "option": "ko-info-bar_field_checkbox__option_geubfu",
    "separator": "ko-info-bar_field_checkbox__separator_geubfu",
    "actions": "ko-info-bar_field_checkbox__actions_geubfu"
  };
});