define('frontend-cp/models/thumbnail', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    name: _emberData.default.attr('string'),
    size: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    url: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date')
  });
});