define('frontend-cp/session/admin/settings/users/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    redirect: function redirect() {
      if (!Ember.testing && window.Bugsnag) {
        window.Bugsnag.notify('ObsoleteRoute', 'An obsolete route was used', { route: this.get('routeName') }, 'info');
      }
      this.transitionTo('session.admin.security.settings');
    }
  });
});