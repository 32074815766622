define('frontend-cp/lib/upload-file', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  exports.default = EmberObject.extend({
    progress: 0,
    name: null,
    attachmentId: null,
    contentUrl: null,
    contentType: null,
    size: null,
    failed: false,
    error: '',
    xhr: null,
    status: 'PROGRESS',

    cancel: function cancel() {
      if (this.get('xhr') && this.get('xhr').abort) {
        this.get('xhr').abort();
      }
      this.set('status', 'CANCELLED');
      this.set('attachmentId', null);
    }
  });
});