define("frontend-cp/components/ko-input/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "input": "ko-input__input_v1243j",
    "monospace": "ko-input__monospace_v1243j",
    "small": "ko-input__small_v1243j",
    "medium": "ko-input__medium_v1243j",
    "full": "ko-input__full_v1243j",
    "addon": "ko-input__addon_v1243j"
  };
});