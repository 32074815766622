define('frontend-cp/components/ko-case-ai-chat/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var set = Ember.set;
  var computed = Ember.computed;
  var observer = Ember.observer;
  var scheduleOnce = Ember.run.scheduleOnce;
  var isPresent = Ember.isPresent;
  var Promise = Ember.RSVP.Promise;
  var service = Ember.inject.service;


  var AI_CHAT_STORAGE_PREFIX = 'ai_chat_';

  exports.default = Component.extend({
    store: service(),
    metrics: service(),
    notificationService: service('notification'),
    window: service(),

    // Properties
    isChatWithTicketPopupVisible: false,
    messages: null,
    displayMessages: null,
    userInput: '',

    init: function init() {
      this._super.apply(this, arguments);
      this._loadChatState();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      if (this.isChatWithTicketPopupVisible) {
        this._loadChatState();
      }
      this._resetUserInput();
    },


    // Chat storage and persistence methods
    _getChatStorageKey: function _getChatStorageKey(caseId) {
      return '' + AI_CHAT_STORAGE_PREFIX + caseId;
    },
    _fetchChatState: function _fetchChatState(caseId) {
      var storageKey = this._getChatStorageKey(caseId);
      var savedData = JSON.parse(localStorage.getItem(storageKey));
      return { storageKey: storageKey, savedData: savedData };
    },
    _loadChatState: function _loadChatState() {
      var caseId = this.get('case.id');

      var _fetchChatState2 = this._fetchChatState(caseId),
          storageKey = _fetchChatState2.storageKey,
          savedData = _fetchChatState2.savedData;

      var savedMessages = [];

      if (isPresent(savedData)) {
        var now = new Date().getTime();
        if (now < savedData.expiresAt) {
          savedMessages = savedData.messages;
        } else {
          localStorage.removeItem(storageKey);
        }
      }

      set(this, 'messages', savedMessages);
      set(this, 'displayMessages', savedMessages);
    },
    _persistChatState: function _persistChatState(caseId, message) {
      var _fetchChatState3 = this._fetchChatState(caseId),
          storageKey = _fetchChatState3.storageKey,
          savedData = _fetchChatState3.savedData;

      var messages = [];

      if (savedData && savedData.messages) {
        messages = savedData.messages;
        if (messages.length > 0 && messages[messages.length - 1].isLoading) {
          messages.pop();
        }
      }

      messages.push(message);
      var threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
      var dataToSave = {
        messages: messages,
        expiresAt: new Date().getTime() + threeDaysInMs
      };

      localStorage.setItem(storageKey, JSON.stringify(dataToSave));
    },
    _clearExpiredChats: function _clearExpiredChats() {
      for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i);

        if (key && key.startsWith(AI_CHAT_STORAGE_PREFIX)) {
          try {
            var savedData = JSON.parse(localStorage.getItem(key));
            if (savedData) {
              var now = new Date().getTime();
              if (now >= savedData.expiresAt) {
                localStorage.removeItem(key);
              }
            }
          } catch (error) {
            localStorage.removeItem(key);
          }
        }
      }
    },


    // Chat UI and interaction methods
    _scrollToBottom: function _scrollToBottom() {
      var forceScroll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var chatOutput = document.getElementById('chatOutputPopup');
      if (chatOutput) {
        var isAtBottom = chatOutput.scrollHeight - chatOutput.scrollTop - chatOutput.clientHeight < 50;
        if (forceScroll || isAtBottom) {
          chatOutput.scrollTop = chatOutput.scrollHeight;
        }
      }
    },
    _streamResponse: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(caseId, content) {
        var currentIndex, messageIndex;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                currentIndex = 0;
                messageIndex = this.displayMessages.length - 1;

              case 2:
                if (!(currentIndex < content.length)) {
                  _context.next = 13;
                  break;
                }

                if (!(!this.isChatWithTicketPopupVisible || this.get('case.id') !== caseId || !this.get('window.visible'))) {
                  _context.next = 6;
                  break;
                }

                this._loadChatState();
                return _context.abrupt('return');

              case 6:
                _context.next = 8;
                return new Promise(function (r) {
                  return setTimeout(r, 50);
                });

              case 8:

                this.set('displayMessages.' + messageIndex + '.content', content.slice(0, currentIndex + 1));
                currentIndex++;
                this._scrollToBottom();
                _context.next = 2;
                break;

              case 13:
                this.set('displayMessages.' + messageIndex + '.isStreaming', false);

              case 14:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _streamResponse(_x2, _x3) {
        return _ref.apply(this, arguments);
      }

      return _streamResponse;
    }(),
    _resetChat: function _resetChat() {
      set(this, 'messages', []);
      set(this, 'displayMessages', []);
    },
    _resetUserInput: function _resetUserInput() {
      this.set('userInput', '');
      var textarea = document.getElementById('chatInputPopup');
      if (textarea) {
        textarea.style.height = '35px';
      }
    },


    // API interaction methods
    _fetchAIResponse: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(caseId) {
        var _fetchChatState4, savedData, messages, chatHistory, adapter, response;

        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _fetchChatState4 = this._fetchChatState(caseId), savedData = _fetchChatState4.savedData;
                messages = savedData ? savedData.messages.slice(0, -1) : [];
                chatHistory = messages.map(function (msg) {
                  return {
                    role: msg.role,
                    content: msg.content
                  };
                });
                adapter = this.get('store').adapterFor('case-ai-ticket-chat');
                _context2.next = 6;
                return adapter.addChatToCase(caseId, chatHistory);

              case 6:
                response = _context2.sent;
                return _context2.abrupt('return', { content: response.data.ai_ticket_chat_response });

              case 8:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _fetchAIResponse(_x4) {
        return _ref2.apply(this, arguments);
      }

      return _fetchAIResponse;
    }(),


    // Computed properties
    isLoading: computed('displayMessages.@each.isLoading', function () {
      return this.displayMessages && this.displayMessages.findBy('isLoading', true);
    }),

    isStreaming: computed('displayMessages.@each.isStreaming', function () {
      return this.displayMessages && this.displayMessages.findBy('isStreaming', true);
    }),

    // Event observers
    messagesObserver: observer('displayMessages.[]', function () {
      scheduleOnce('afterRender', this, this._scrollToBottom, true);
    }),

    // Actions
    actions: {
      toggleChat: function toggleChat() {
        this.get('metrics').trackEvent({
          event: 'AI Ticket Chat Opened',
          caseId: this.get('case.id')
        });
        var willOpen = !this.isChatWithTicketPopupVisible;
        if (willOpen) {
          this._clearExpiredChats();
          this._loadChatState();
        } else {
          this._resetChat();
        }
        set(this, 'isChatWithTicketPopupVisible', willOpen);
      },
      sendMessage: function () {
        var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3() {
          var message, caseId, messageContent, userMessage, loadingMessage, response, assistantMessage, displayMessage;
          return regeneratorRuntime.wrap(function _callee3$(_context3) {
            while (1) {
              switch (_context3.prev = _context3.next) {
                case 0:
                  this.get('metrics').trackEvent({
                    event: 'AI Ticket Chat Message Sent',
                    caseId: this.get('case.id')
                  });
                  message = this.userInput;
                  caseId = this.get('case.id');

                  if (message) {
                    _context3.next = 5;
                    break;
                  }

                  return _context3.abrupt('return');

                case 5:
                  messageContent = void 0;
                  _context3.prev = 6;
                  userMessage = { role: 'user', content: message };

                  this._persistChatState(caseId, userMessage);
                  this.displayMessages.pushObject(userMessage);

                  this._resetUserInput();

                  loadingMessage = { role: 'assistant', content: '', isLoading: true };

                  this._persistChatState(caseId, loadingMessage);
                  this.displayMessages.pushObject(loadingMessage);

                  _context3.next = 16;
                  return this._fetchAIResponse(caseId);

                case 16:
                  response = _context3.sent;

                  messageContent = response.content;
                  _context3.next = 24;
                  break;

                case 20:
                  _context3.prev = 20;
                  _context3.t0 = _context3['catch'](6);

                  messageContent = 'Sorry, there was an error processing your request.';
                  this.get('notificationService').error(this.get('i18n').t('generic.generic_error'));

                case 24:
                  _context3.prev = 24;
                  assistantMessage = {
                    role: 'assistant',
                    content: messageContent,
                    isLoading: false
                  };

                  this._persistChatState(caseId, assistantMessage);

                  this.displayMessages.removeObject(this.displayMessages.findBy('isLoading', true));
                  displayMessage = { role: 'assistant', content: '', isLoading: false, isStreaming: true };

                  this.displayMessages.pushObject(displayMessage);

                  _context3.next = 32;
                  return this._streamResponse(caseId, messageContent);

                case 32:
                  return _context3.finish(24);

                case 33:
                case 'end':
                  return _context3.stop();
              }
            }
          }, _callee3, this, [[6, 20, 24, 33]]);
        }));

        function sendMessage() {
          return _ref3.apply(this, arguments);
        }

        return sendMessage;
      }(),
      closeChat: function closeChat() {
        this.get('metrics').trackEvent({
          event: 'AI Ticket Chat Closed',
          caseId: this.get('case.id')
        });
        this._resetChat();
        set(this, 'isChatWithTicketPopupVisible', false);
      },
      clearChat: function clearChat() {
        this.get('metrics').trackEvent({
          event: 'AI Ticket Chat Cleared',
          caseId: this.get('case.id')
        });
        var caseId = this.get('case.id');
        var storageKey = this._getChatStorageKey(caseId);
        this._resetChat();
        localStorage.removeItem(storageKey);
      },
      handleInputResize: function handleInputResize(event) {
        this.set('userInput', event.target.value);

        var textarea = event.target;
        textarea.style.height = 'auto';
        var maxHeight = 100;

        if (textarea.scrollHeight > maxHeight) {
          textarea.style.height = maxHeight + 'px';
          textarea.style.overflowY = 'auto';
        } else {
          textarea.style.height = textarea.scrollHeight + 'px';
          textarea.style.overflowY = 'hidden';
        }
      }
    }
  });
});