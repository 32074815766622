define('frontend-cp/session/admin/apps/api/oauth-apps/new/controller', ['exports', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/apps/api/oauth-apps/schema'], function (exports, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    notification: service(),
    i18n: service(),
    virtualModel: service(),
    editedApp: null,
    isCreated: false,

    schema: _schema.default,

    initEdits: function initEdits() {
      this.set('editedApp', this.get('virtualModel').makeSnapshot(this.get('model'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('model'), this.get('editedApp'), _schema.default) && !this.get('isCreated');
    },


    actions: {
      created: function created() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });
        this.set('isCreated', true);
      },
      done: function done() {
        this.transitionToRoute('session.admin.apps.api.oauth-apps.index');
      },
      canceled: function canceled() {
        this.transitionToRoute('session.admin.apps.api.oauth-apps.index');
      }
    }
  });
});