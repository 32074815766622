define("frontend-cp/components/ko-tabs/case/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "disabled": "ko-tabs_case__disabled_1cctzl",
    "subject": "ko-tabs_case__subject_1cctzl",
    "set-organization": "ko-tabs_case__set-organization_1cctzl",
    "setting-organization": "ko-tabs_case__setting-organization_1cctzl",
    "hidden": "ko-tabs_case__hidden_1cctzl",
    "tab-loader": "ko-tabs_case__tab-loader_1cctzl"
  };
});