define('frontend-cp/session/admin/automation/triggers/new/route', ['exports', 'ember-service/inject', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _inject, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend((0, _route.default)('theTrigger'), {
    launchDarkly: (0, _inject.default)(),

    metrics: service(),
    model: function model() {
      var store = this.get('store');

      return hash({
        channels: store.findAll('trigger-channel'),
        theTrigger: store.createRecord('trigger', {
          actions: [store.createRecord('automation-action')],
          isEnabled: true
        })
      });
    },
    setupController: function setupController(controller, _ref) {
      var channels = _ref.channels,
          theTrigger = _ref.theTrigger;

      theTrigger.get('predicateCollections').createRecord({
        propositions: [this.get('store').createRecord('proposition')]
      });
      controller.setProperties({ channels: channels, theTrigger: theTrigger });
      controller.setProperties(this.modelFor('session.admin.automation.triggers'));
    },


    // Actions
    actions: {
      didSave: function didSave() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'trigger_created',
            name: this.get('controller').get('theTrigger.title')
          });
        }
        this.transitionTo('session.admin.automation.triggers');
      },
      cancel: function cancel() {
        this.transitionTo('session.admin.automation.triggers');
      }
    }
  });
});