define('frontend-cp/serializers/relationship-fragment', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      resourceHash.relationship_id = resourceHash.id;
      resourceHash.relationship_type = resourceHash.resource_type;
      Reflect.deleteProperty(resourceHash, 'id');
      Reflect.deleteProperty(resourceHash, 'resource_type');
      return this._super.apply(this, arguments);
    }
  });
});