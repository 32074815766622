define("frontend-cp/components/ko-tabs/new-case/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "disabled": "ko-tabs_new-case__disabled_1tfzn3",
    "set-organization": "ko-tabs_new-case__set-organization_1tfzn3",
    "setting-organization": "ko-tabs_new-case__setting-organization_1tfzn3",
    "hidden": "ko-tabs_new-case__hidden_1tfzn3",
    "tab-loader": "ko-tabs_new-case__tab-loader_1tfzn3"
  };
});