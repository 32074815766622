define('frontend-cp/helpers/ko-imagify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.imagify = imagify;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var helper = Ember.Helper.helper;
  var htmlSafe = Ember.String.htmlSafe;


  var IMAGE_TAG_REGEX = /\[img (.+?)]/igm;
  var LINKIFY_OUTPUT_REGEX = /<a href="(\S+)".*>(.*)/ig;

  function imagify(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        content = _ref2[0];

    // This function depends on ko-linkify's output
    content = content.replace(IMAGE_TAG_REGEX, function (match, capture) {
      capture = capture.replace(/&#x3D;/g, '=').replace(/&quot;/g, '"').replace(LINKIFY_OUTPUT_REGEX, '$1 $2');
      return '<img ' + capture + ' />';
    });
    return htmlSafe(content);
  }

  exports.default = helper(imagify);
});