define('frontend-cp/components/ko-insights/highcharts/tooltip-formatters/time', ['exports', 'frontend-cp/lib/humanize-seconds'], function (exports, _humanizeSeconds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (i18n) {
    return function () {
      var tooltip = [];

      this.points.forEach(function (point) {
        var formattedDate = i18n.formatDate(point.point.date, { format: 'weekdayDate' });
        tooltip.push('<b>' + formattedDate + '</b>');
        tooltip.push('<span style="color:' + point.series.color + '">\u25CF</span> ' + point.series.name + ': ' + (0, _humanizeSeconds.default)(point.y));
      });

      return tooltip.join('<br/>');
    };
  };
});