define('frontend-cp/services/bulk-invitations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    emails: [],

    reset: function reset() {
      this.set('emails', []);
    }
  });
});