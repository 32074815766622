define('frontend-cp/services/insights', ['exports', 'frontend-cp/config/environment', 'moment', 'frontend-cp/lib/chart-point-intervals'], function (exports, _environment, _moment, _chartPointIntervals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({
    notification: service(),
    localstore: service('local-store'),
    plan: service(),
    i18n: service(),
    store: service(),

    restoreTrialNotification: function restoreTrialNotification() {
      this.get('localstore').removeItem(_environment.default.localStore.defaultNamespace, 'trial.insights', { persist: true });
    },
    isTrialDataEnabled: function isTrialDataEnabled() {
      var trialMarker = this.get('localstore').getItem(_environment.default.localStore.defaultNamespace, 'trial.insights', { persist: true });
      return trialMarker !== false;
    },
    isTrialMode: function isTrialMode() {
      return this.get('plan.isTrial') && this.isTrialDataEnabled();
    },
    metricsEndpoint: function metricsEndpoint() {
      var adapter = getOwner(this).lookup('adapter:application');

      var endpoint = adapter.namespace + '/insights/cases/metrics';

      if (this.isTrialMode()) {
        endpoint = 'assets/trial/insights/cases/metrics.json';
      }

      return endpoint;
    },
    caseResolutionEndpoint: function caseResolutionEndpoint() {
      var adapter = getOwner(this).lookup('adapter:application');

      var endpoint = adapter.namespace + '/insights/cases/resolution';

      if (this.isTrialMode()) {
        endpoint = 'assets/trial/insights/cases/resolution.json';
      }

      return endpoint;
    },
    csatEndpoint: function csatEndpoint() {
      var adapter = getOwner(this).lookup('adapter:application');

      var endpoint = adapter.namespace + '/insights/cases/csat';

      if (this.isTrialMode()) {
        endpoint = 'assets/trial/insights/cases/csat.json';
      }

      return endpoint;
    },
    casesCompletedEndpoint: function casesCompletedEndpoint() {
      var adapter = getOwner(this).lookup('adapter:application');

      var endpoint = adapter.namespace + '/insights/cases/completed';

      if (this.isTrialMode()) {
        endpoint = 'assets/trial/insights/cases/completed.json';
      }

      return endpoint;
    },
    caseResponseEndpoint: function caseResponseEndpoint() {
      var adapter = getOwner(this).lookup('adapter:application');

      var endpoint = adapter.namespace + '/insights/cases/response';

      if (this.isTrialMode()) {
        endpoint = 'assets/trial/insights/cases/response.json';
      }

      return endpoint;
    },
    channelEndpoint: function channelEndpoint() {
      var adapter = getOwner(this).lookup('adapter:application');

      var endpoint = adapter.namespace + '/insights/cases/channel';

      if (this.isTrialMode()) {
        endpoint = 'assets/trial/insights/cases/channel.json';
      }

      return endpoint;
    },
    articlesEndpoint: function articlesEndpoint() {
      var adapter = getOwner(this).lookup('adapter:application');

      var endpoint = adapter.namespace + '/insights/helpcenter/articles';

      if (this.isTrialMode()) {
        endpoint = 'assets/trial/insights/helpcenter/articles.json';
      }

      return endpoint;
    },
    searchEndpoint: function searchEndpoint() {
      var adapter = getOwner(this).lookup('adapter:application');

      var endpoint = adapter.namespace + '/insights/helpcenter/search';

      if (this.isTrialMode()) {
        endpoint = 'assets/trial/insights/helpcenter/search.json';
      }

      return endpoint;
    },
    slaTargetEndpoint: function slaTargetEndpoint() {
      var adapter = getOwner(this).lookup('adapter:application');

      var endpoint = adapter.namespace + '/insights/sla/target';

      if (this.isTrialMode()) {
        endpoint = 'assets/trial/insights/sla/target.json';
      }

      return endpoint;
    },
    slaPerformanceEndpoint: function slaPerformanceEndpoint() {
      var adapter = getOwner(this).lookup('adapter:application');

      var endpoint = adapter.namespace + '/insights/sla/performance';

      if (this.isTrialMode()) {
        endpoint = 'assets/trial/insights/sla/performance.json';
      }

      return endpoint;
    },
    pushTrialNotification: function pushTrialNotification(callback) {
      var notifications = this.get('notification');
      var localstore = this.get('localstore');
      var i18n = this.get('i18n');

      notifications.add({
        unique: true,
        type: 'info',
        title: i18n.t('insights.trial.notification.title'),
        autodismiss: false,
        dismissable: false,
        href: '#',
        hrefTarget: '_self',
        hrefText: i18n.t('insights.trial.notification.link'),
        onClose: function onClose() {
          localstore.setItem(_environment.default.localStore.defaultNamespace, 'trial.insights', false, { persist: true });

          callback();
        }
      });
    },
    skewDate: function skewDate(date, days) {
      var skewStart = new Date(date);
      skewStart.setDate(skewStart.getDate() + days);

      return (0, _moment.default)(skewStart).format('YYYY-MM-DD');
    },


    _getDayOfWeek: function _getDayOfWeek(date) {
      return new Date(date + 'T00:00:00.000Z').getDay();
    },

    getPreviousDates: function getPreviousDates(startAt, endAt) {
      var currentPeriodDays = Math.floor((new Date(endAt + 'T23:59:59.999Z') - new Date(startAt + 'T00:00:00.000Z')) / 86400000);
      var currentStartDay = this._getDayOfWeek(startAt);
      var currentEndDay = this._getDayOfWeek(endAt);

      // calculate amount of days to subtract to have correct previous period mapped by day to day
      var diff = currentEndDay - currentStartDay ? -7 + (currentEndDay - currentStartDay) : -1;
      var previousEnd = this.skewDate(new Date(startAt + 'T00:00:00.000Z'), diff);
      var previousStart = this.skewDate(new Date(previousEnd + 'T00:00:00.000Z'), -1 * currentPeriodDays);

      return {
        previousStart: previousStart,
        previousEnd: previousEnd
      };
    },
    prepareSeriesData: function prepareSeriesData(data, previous, startDate, endDate, previousStartDate, interval) {
      var startAt = new Date(startDate);
      var endAt = new Date(endDate);
      var point = Date.UTC(startAt.getFullYear(), startAt.getMonth(), startAt.getDate());
      var pointInterval = (0, _chartPointIntervals.getChartPointInterval)(interval);

      var currentData = void 0,
          previousData = void 0;

      var todayDate = new Date();
      var todayPoint = Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() + 1);

      var zones = [];
      if (endAt > todayDate) {
        zones = [{
          value: todayPoint,
          dashStyle: 'Solid'
        }, {
          dashStyle: 'Dot'
        }];
      }

      if (data) {
        var startTime = startAt.getTime();
        currentData = data.map(function (record) {
          var data = {
            y: record,
            date: startTime
          };
          startTime += pointInterval;
          return data;
        });
      }

      if (previous) {
        var previousStartTime = new Date(previousStartDate).getTime();
        previousData = previous.map(function (record) {
          var data = {
            y: record,
            date: previousStartTime
          };
          previousStartTime += pointInterval;
          return data;
        });
      }

      return {
        current: currentData,
        previous: previousData,
        point: point,
        zones: zones,
        pointInterval: pointInterval
      };
    },
    requestSLAs: function requestSLAs() {
      var _this = this;

      var adapter = getOwner(this).lookup('adapter:application');

      return this.get('store').findAll('sla').then(function (data) {
        if (!data.get('length') && _this.isTrialMode()) {
          return adapter.ajax('/assets/trial/insights/slas.json', 'GET').then(function (slas) {
            return slas.data;
          });
        }
        return data;
      });
    },
    restructureSlaPerformanceSeries: function restructureSlaPerformanceSeries(data, resources, slas, currentSla) {
      var performanceSeries = data.performance_series.map(function (performance) {
        return {
          series: performance.series,
          sla: resources.sla[performance.sla.id]
        };
      });
      var slaItem = void 0;

      if (this.isTrialMode()) {
        /**
         * In trial mode, we can't match returned SLA from server with current SLA
         * because ID of mocked data won't match with current ID in a system.
         * So we just return first object from performance_series.
         */
        performanceSeries = [performanceSeries.get('firstObject')];
        slaItem = slas.get('firstObject');
      } else {
        performanceSeries = performanceSeries.filter(function (series) {
          return parseInt(series.sla.id) === parseInt(currentSla);
        });
        slaItem = slas.find(function (sla) {
          return sla.get('id') === currentSla;
        });
      }

      data.performance_series = performanceSeries;

      return {
        data: data,
        slaItem: slaItem
      };
    }
  });
});