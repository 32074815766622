define('frontend-cp/components/ko-user-content/component', ['exports', 'ember-service/inject', 'ember-keyboard-shortcuts/mixins/component', 'ember-concurrency', 'npm:lodash', 'moment', 'frontend-cp/lib/keycodes', 'frontend-cp/utils/format-validations', 'ember-diff-attrs', 'frontend-cp/components/ko-user-content/styles', 'frontend-cp/components/ko-text-editor/component', 'frontend-cp/utils/bugsnag'], function (exports, _inject, _component, _emberConcurrency, _npmLodash, _moment, _keycodes, _formatValidations, _emberDiffAttrs, _styles, _component2, _bugsnag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var scheduleOnce = Ember.run.scheduleOnce;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var get = Ember.get;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var notEmpty = Ember.computed.notEmpty;
  var or = Ember.computed.or;
  var getOwner = Ember.getOwner;
  var run = Ember.run;
  var debounce = Ember.run.debounce;
  var jQuery = Ember.$;
  var capitalize = Ember.String.capitalize;


  var CUSTOMER_ROLE_ID = 4;
  var FOCUS_DEBOUNCE_TIME = 400;

  exports.default = Component.extend(_component.default, {
    launchDarkly: (0, _inject.default)(),

    // HTML
    localClassNames: ['root'],

    // Attributes
    user: null,
    case: null,
    tabId: null,
    tabsComponent: null,
    instantEntityTerm: '',
    instantEntityResults: null,
    redirectingToUser: false,
    username: '',
    setOrganizationMode: false,
    unsetOrgInProgress: false,
    removedOrg: null,
    timestamp: null,

    onCreateNewCase: null,
    onTabNameUpdate: null,

    // State
    editingSignature: null,
    roles: [],
    appAccessModal: false,
    signatureModal: false,
    disableTwoFactorModal: false,
    deleteUserModal: false,
    remove2FAButtonDisabled: false,
    teamRecords: [],
    state: null,
    grants: [],

    // Services
    store: service(),
    permissionService: service('permissions'),
    notification: service('notification'),
    errorHandler: service('error-handler'),
    customFieldsList: service('custom-fields/list'),
    i18n: service(),
    confirmation: service(),
    routing: service('-routing'),
    session: service(),
    uploadService: service('fileUpload'),
    tabStore: service(),
    metrics: service(),

    // State attributes
    errorMap: readOnly('state.errorMap'),
    editedTags: readOnly('state.editedTags'),
    editedTeams: readOnly('state.editedTeams'),
    editedUser: alias('state.editedUser'),
    fullName: readOnly('state.editedUser.fullName'),
    hasUpdateUserPermission: readOnly('state.hasUpdateUserPermission'),
    organization: readOnly('state.editedUser.organization'),
    role: readOnly('state.editedUser.role'),
    signature: readOnly('state.editedUser.signature'),
    timeZone: readOnly('state.editedUser.timeZone'),
    locale: readOnly('state.editedUser.locale'),
    agentCaseAccess: readOnly('state.editedUser.agentCaseAccess'),
    organizationCaseAccess: readOnly('state.editedUser.organizationCaseAccess'),
    attachedPostFiles: readOnly('state.attachedPostFiles'),
    uploadingFiles: readOnly('uploadService.uploadFile.isRunning'),
    selectedNoteDestination: readOnly('state.noteDestination'),

    keyboardShortcuts: {
      'ctrl+alt+n': {
        action: 'focusEditor',
        global: false,
        preventDefault: true
      },
      n: {
        action: 'focusEditor',
        global: false
      },
      'mod+enter': {
        action: 'submit',
        global: true,
        preventDefault: true
      }
    },

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.initRoles();
      this.initTeams();
      this.get('initGrants').perform();
    },


    didReceiveAttrs: (0, _emberDiffAttrs.default)('user', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      if (!changedAttrs || changedAttrs.user) {
        this.setProperties({
          editingSignature: null,
          appAccessModal: false,
          signatureModal: false,
          deleteUserModal: false
        });
        this.initRecentFeedback();
      }
    }),

    // CPs
    tabsModel: computed.or('case', 'user'),

    noteDestinations: computed('hasOrg', function () {
      var i18n = this.get('i18n');
      var options = [{
        id: 'user',
        text: i18n.t('users.title'),
        description: i18n.t('generic.texteditor.user_notes_reminder')
      }];

      if (this.get('hasOrg')) {
        options.addObject({
          id: 'org',
          text: i18n.t('organization.title'),
          description: i18n.t('generic.texteditor.org_notes_reminder')
        });
      }

      return options;
    }),

    noteDestination: or('selectedNoteDestination', 'noteDestinations.firstObject'),
    hasOrg: computed.bool('user.organization.name', 'removedOrg'),

    isMe: computed('session.user.id', 'user.id', function () {
      return this.get('session.user.id') === this.get('user.id');
    }),

    hasUser: computed('editedUser.fullName', function () {
      var username = this.get('editedUser.fullName') || this.get('user.fullName');

      return typeof username === 'string';
    }),

    isSaving: computed.or('state.save.isRunning', 'state.postNote.isRunning', 'updateName.isRunning', 'addExternalNote.isRunning', 'unsetOrgInProgress'),

    stateIsUnmodified: computed.not('state.isEdited'),
    submitDisabled: computed.or('isSaving', 'stateIsUnmodified', 'uploadingFiles'),

    agentAccessLevels: computed(function () {
      return [EmberObject.create({
        name: this.get('i18n').t('users.infobar.agentaccess.inherit'),
        value: 'INHERIT-FROM-ROLE'
      }), EmberObject.create({
        name: this.get('i18n').t('users.infobar.agentaccess.self'),
        value: 'SELF'
      }), EmberObject.create({
        name: this.get('i18n').t('users.infobar.agentaccess.teams'),
        value: 'TEAMS'
      }), EmberObject.create({
        name: this.get('i18n').t('users.infobar.agentaccess.all'),
        value: 'ALL'
      })];
    }),

    organizationAccessLevels: computed(function () {
      return [EmberObject.create({
        name: this.get('i18n').t('users.infobar.organizationaccess.organization'),
        value: 'ORGANIZATION'
      }), EmberObject.create({
        name: this.get('i18n').t('users.infobar.organizationaccess.requested'),
        value: 'REQUESTED'
      })];
    }),

    customFields: computed('user.customFields', function () {
      return this.get('user.customFields').map(function (field) {
        return field.get('field');
      }).sortBy('sortOrder');
    }),

    availableTeams: computed('teamRecords.@each.id', 'editedTeams.@each.id', function () {
      var editedTeamIds = this.get('editedTeams').mapBy('id');

      return this.get('teamRecords').filter(function (team) {
        return editedTeamIds.indexOf(team.get('id')) === -1;
      });
    }),

    canUpdateUser: computed('hasUpdateUserPermission', 'isSaving', function () {
      return this.get('hasUpdateUserPermission') && !this.get('isSaving');
    }),

    canAddNewIdentity: computed('canUpdateUser', 'user.isNew', function () {
      return this.get('canUpdateUser') && !this.get('user.isNew');
    }),

    canModifyUserState: computed('canUpdateUser', 'user.role', function () {
      return this.get('canUpdateUser') && this.get('permissionService').has('app.user.disable', this.get('user'));
    }),

    canFollowUser: computed('canUpdateUser', 'user.role', function () {
      return this.get('canUpdateUser') && this.get('permissionService').has('app.user.follow', this.get('user'));
    }),

    canChangeAgentAccessPermission: computed('hasUpdateUserPermission', 'role', function () {
      return this.get('hasUpdateUserPermission') && this.get('permissionService').has('app.user.change_agent_access_permission', this.get('state.editedUser'));
    }),

    canChangeOrganizationAccessPermission: computed('hasUpdateUserPermission', 'role', function () {
      return this.get('hasUpdateUserPermission') && this.get('permissionService').has('app.user.change_organization_access_permission', this.get('state.editedUser'));
    }),

    canChangeUserTeamPermission: computed('canUpdateUser', 'role', function () {
      return this.get('canUpdateUser') && this.get('permissionService').has('app.user.change_team_permission', this.get('state.editedUser'));
    }),

    canViewUserTeamPermission: computed('hasUpdateUserPermission', 'role', function () {
      return this.get('hasUpdateUserPermission') && this.get('permissionService').has('app.user.view_team_permission', this.get('state.editedUser'));
    }),

    canChangeRolePermission: computed('canUpdateUser', 'user.role', function () {
      return this.get('canUpdateUser') && this.get('permissionService').has('app.user.change_role_permission', this.get('user'));
    }),

    canPostUserNote: computed('role', function () {
      return this.get('permissionService').has('app.user.post_private_note', this.get('user'));
    }),

    showManageAppAccess: notEmpty('grants'),

    // Methods
    suggestTags: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(searchTerm) {
      var addNewMessage, data, exactMatch;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              addNewMessage = this.get('i18n').t('generic.addtagname', { tag: searchTerm });
              _context.next = 5;
              return this.get('store').query('tag', { name: searchTerm });

            case 5:
              data = _context.sent;
              exactMatch = !!data.toArray().findBy('name', searchTerm) || !!this.get('editedTags').findBy('name', searchTerm);
              return _context.abrupt('return', _npmLodash.default.difference(data.mapBy('name'), this.get('editedTags').mapBy('name')).map(function (name) {
                return { name: name };
              }).concat(exactMatch ? [] : [{ name: addNewMessage, actualName: searchTerm }]));

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    setCaseAccessLevelDefaults: function setCaseAccessLevelDefaults(oldRoleType, newRoleType) {
      var state = this.get('state');
      var originalAgentAccessLevel = state.get('user.agentCaseAccess');
      var originalOrganizationAccessLevel = state.get('user.organizationCaseAccess');
      var requestedCaseAccessLevel = 'REQUESTED';
      var inheritedFromRoleAccessLevel = 'INHERIT-FROM-ROLE';

      if (oldRoleType !== 'CUSTOMER' && newRoleType === 'CUSTOMER') {
        if (originalOrganizationAccessLevel) {
          state.setOrganizationAccessLevel(originalOrganizationAccessLevel);
        } else {
          state.setOrganizationAccessLevel(requestedCaseAccessLevel);
        }
      } else if (oldRoleType === 'CUSTOMER' && newRoleType !== 'CUSTOMER') {
        // if user swaps from agent -> customer -> agent, we shouldn't lose the original access level
        if (originalAgentAccessLevel) {
          state.setAgentAccessLevel(originalAgentAccessLevel);
        } else {
          state.setAgentAccessLevel(inheritedFromRoleAccessLevel);
        }
      }
    },


    submit: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var launchDarkly, _getProperties, state, isRoleEdited, note, teams;

      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');

              if (!this.get('submitDisabled')) {
                _context2.next = 3;
                break;
              }

              return _context2.abrupt('return');

            case 3:
              _getProperties = this.getProperties('state'), state = _getProperties.state;
              isRoleEdited = state.get('isRoleEdited');
              _context2.prev = 5;
              _context2.next = 8;
              return state.get('submit').perform();

            case 8:
              _context2.next = 10;
              return this.get('timeline.fetchNewerAfterReply').perform(this.get('filter'));

            case 10:
              note = _context2.sent;

              note = note.filterBy('note.id').get('firstObject.note.content');
              this.addNoteToViewNotesIfInNotesMode(note);
              if (launchDarkly.get('release-event-tracking') && isRoleEdited) {
                teams = this.get('user.role.roleType') === 'CUSTOMER' ? null : this.get('user.teams').getEach('id').join(',');

                this.get('metrics').trackEvent({
                  event: 'user_role_edited',
                  object: this.get('user.id'),
                  new_role: this.get('user.role.roleType'),
                  teams: teams
                });
              }
              _context2.next = 20;
              break;

            case 16:
              _context2.prev = 16;
              _context2.t0 = _context2['catch'](5);

              if (!(_context2.t0.message !== 'The adapter rejected the commit because it was invalid')) {
                _context2.next = 20;
                break;
              }

              throw _context2.t0;

            case 20:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this, [[5, 16]]);
    })).drop(),

    searchOrganization: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(query) {
      var adapter, url, request, response;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              if (query) {
                _context3.next = 2;
                break;
              }

              return _context3.abrupt('return', []);

            case 2:
              _context3.next = 4;
              return (0, _emberConcurrency.timeout)(300);

            case 4:
              adapter = getOwner(this).lookup('adapter:application');
              url = adapter.namespace + '/autocomplete/organizations';
              request = jQuery.ajax({
                url: url,
                contentType: 'application/json',
                data: {
                  name: query
                }
              });
              _context3.prev = 7;
              _context3.next = 10;
              return request;

            case 10:
              response = _context3.sent;
              return _context3.abrupt('return', response.data);

            case 14:
              _context3.prev = 14;
              _context3.t0 = _context3['catch'](7);
              return _context3.abrupt('return', []);

            case 17:
              _context3.prev = 17;

              request.abort();
              return _context3.finish(17);

            case 20:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this, [[7, 14, 17, 20]]);
    })).restartable(),

    deleteUser: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      var _this = this;

      var userId, tabs;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              _context4.prev = 0;
              userId = this.get('user.id');
              tabs = this.get('tabStore.tabs').slice(0).removeObject(this.get('tabStore.activeTab')).filter(function (tab) {
                if (tab.process.get('type') === 'case') {
                  return tab.process.get('model.requester.id') === userId;
                }

                if (tab.process.get('type') === 'user') {
                  return tab.process.get('model.id') === userId;
                }
              });
              _context4.next = 5;
              return this.get('state.deleteUser').perform();

            case 5:

              tabs.forEach(function (tab) {
                return _this.get('tabStore').close(tab);
              });
              this.get('tabStore').closeActiveTab(true);
              _context4.next = 11;
              break;

            case 9:
              _context4.prev = 9;
              _context4.t0 = _context4['catch'](0);

            case 11:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this, [[0, 9]]);
    })).drop(),

    createUser: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5(name, id, isTwitter) {
      var store, roleModel, userObject, email, twitter, user;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              store = this.get('store');
              _context5.next = 3;
              return store.findRecord('role', CUSTOMER_ROLE_ID);

            case 3:
              roleModel = _context5.sent;
              userObject = {
                role: roleModel,
                fullName: name
              };
              email = void 0, twitter = void 0;

              if (isTwitter) {
                _context5.next = 14;
                break;
              }

              email = store.createRecord('identity-email', {
                isPrimary: true,
                email: id
              });

              userObject.emails = [email];

              _context5.next = 11;
              return store.createRecord('user', userObject).save();

            case 11:
              return _context5.abrupt('return', _context5.sent);

            case 14:
              _context5.next = 16;
              return store.createRecord('user', userObject).save();

            case 16:
              user = _context5.sent;
              _context5.next = 19;
              return store.createRecord('identity-twitter', {
                user: user,
                isPrimary: true,
                screenName: id
              }).save();

            case 19:
              twitter = _context5.sent;


              user.set('twitter', [twitter]);

              return _context5.abrupt('return', user);

            case 22:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })).drop(),

    createOrg: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee6(name) {
      var store;
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              store = this.get('store');
              _context6.next = 3;
              return store.createRecord('organization', { name: name }).save();

            case 3:
              return _context6.abrupt('return', _context6.sent);

            case 4:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    })).drop(),

    persistOrgToUser: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee7(user, org) {
      var _this2 = this;

      var i18n, opts;
      return regeneratorRuntime.wrap(function _callee7$(_context7) {
        while (1) {
          switch (_context7.prev = _context7.next) {
            case 0:
              i18n = this.get('i18n');

              user.set('organization', org);
              opts = { adapterOptions: { setOrganization: true } };
              _context7.next = 5;
              return user.save(opts).then(function (user) {
                _this2.send('setInstantUser', user);
                _this2.get('notification').success(i18n.t('organization.assignment_passed', { name: user.get('fullName'), org: org.get('name') }));
              }).catch(function () {
                _this2.get('notification').error(i18n.t('organization.assignment_failed'));
                user.set('organization', null);
              });

            case 5:
              return _context7.abrupt('return', _context7.sent);

            case 6:
            case 'end':
              return _context7.stop();
          }
        }
      }, _callee7, this);
    })).drop(),

    updateSignature: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee8() {
      return regeneratorRuntime.wrap(function _callee8$(_context8) {
        while (1) {
          switch (_context8.prev = _context8.next) {
            case 0:
              _context8.next = 2;
              return this.get('user').save({ adapterOptions: { setSignature: true } });

            case 2:
              return _context8.abrupt('return', _context8.sent);

            case 3:
            case 'end':
              return _context8.stop();
          }
        }
      }, _callee8, this);
    })),

    updateName: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee9(name, oldName) {
      var i18n, user;
      return regeneratorRuntime.wrap(function _callee9$(_context9) {
        while (1) {
          switch (_context9.prev = _context9.next) {
            case 0:
              i18n = this.get('i18n');
              user = this.get('user');

              user.set('fullName', name);
              _context9.prev = 3;
              _context9.next = 6;
              return user.save({ adapterOptions: { updateName: true } });

            case 6:
              this.get('notification').success(i18n.t('users.name_update_success'));
              _context9.next = 14;
              break;

            case 9:
              _context9.prev = 9;
              _context9.t0 = _context9['catch'](3);

              this.get('notification').error(i18n.t('users.name_update_failure'));
              user.set('name', oldName);
              throw _context9.t0;

            case 14:
              return _context9.abrupt('return', user);

            case 15:
            case 'end':
              return _context9.stop();
          }
        }
      }, _callee9, this, [[3, 9]]);
    })).drop(),

    initGrants: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee10() {
      var grants;
      return regeneratorRuntime.wrap(function _callee10$(_context10) {
        while (1) {
          switch (_context10.prev = _context10.next) {
            case 0:
              if (!(this.get('user') !== this.get('session.user'))) {
                _context10.next = 2;
                break;
              }

              return _context10.abrupt('return');

            case 2:
              _context10.next = 4;
              return this.get('store').findAll('my-oauth-grant');

            case 4:
              grants = _context10.sent;

              this.set('grants', grants);

            case 6:
            case 'end':
              return _context10.stop();
          }
        }
      }, _callee10, this);
    })),

    fetchNotes: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee11() {
      var notes, context;
      return regeneratorRuntime.wrap(function _callee11$(_context11) {
        while (1) {
          switch (_context11.prev = _context11.next) {
            case 0:
              _context11.prev = 0;
              _context11.next = 3;
              return this.get('store').query('note', {
                parent: this.get('user'),
                limit: 999
              });

            case 3:
              notes = _context11.sent;

              this.set('user.viewNotes', notes.toArray());
              _context11.next = 10;
              break;

            case 7:
              _context11.prev = 7;
              _context11.t0 = _context11['catch'](0);

              if (!Ember.testing && window.Bugsnag) {
                context = (0, _bugsnag.getMetaData)(null, getOwner(this));

                window.Bugsnag.notifyException(_context11.t0, 'Failed to fetch notes', context, 'info');
              }

            case 10:
            case 'end':
              return _context11.stop();
          }
        }
      }, _callee11, this, [[0, 7]]);
    })).restartable(),

    addExternalNote: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee12(destination) {
      var user, organization, payload, type, note;
      return regeneratorRuntime.wrap(function _callee12$(_context12) {
        while (1) {
          switch (_context12.prev = _context12.next) {
            case 0:
              user = this.get('user.id');
              organization = this.get('user.organization.id');
              payload = {
                contents: this.get('state.replyContent'),
                attachmentFileIds: this.get('attachedPostFiles').mapBy('attachmentId').compact()
              };
              type = destination === 'user' ? 'user' : 'organization';
              _context12.next = 6;
              return this.get('store').createRecord('note', payload).save({ adapterOptions: { type: type, user: user, organization: organization } });

            case 6:
              note = _context12.sent;

              this.get('state').resetEdits();
              _context12.next = 10;
              return this.get('timeline.fetchNewerAfterReply').perform(this.get('filter'));

            case 10:
              this.addNoteToViewNotesIfInNotesMode(note);

            case 11:
            case 'end':
              return _context12.stop();
          }
        }
      }, _callee12, this);
    })),

    addNoteToViewNotesIfInNotesMode: function addNoteToViewNotesIfInNotesMode(note) {
      if (note) {
        this.get('user.viewNotes').addObject(note);
      }
    },
    focusInstantSearch: function focusInstantSearch() {
      var _this3 = this;

      scheduleOnce('afterRender', function () {
        if (_this3.isDestroying || _this3.isDestroyed) {
          return;
        }
        _this3.$('#' + _this3.get('elementId') + '-kie-instant-input').click();
      });
    },
    findAndClickOnSubjectField: function findAndClickOnSubjectField() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.$('.' + _styles.default['timeline-header-body']).find('span:not(.ember-view)').click();
    },
    focusSubject: function focusSubject() {
      scheduleOnce('afterRender', this, 'findAndClickOnSubjectField');
    },
    findAndTriggerFroalaFocusAction: function findAndTriggerFroalaFocusAction() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.$(_component2.EDITOR_SELECTOR).froalaEditor('events.focus');
    },
    focusFroalaEditor: function focusFroalaEditor() {
      scheduleOnce('afterRender', this, 'findAndTriggerFroalaFocusAction');
    },
    scheduleCheckingForEditableActiveElement: function scheduleCheckingForEditableActiveElement(force) {
      var subject = this.get('user.fullName');

      var el = document.activeElement;
      var isCurrentElementEditable = el && (el.isContentEditable || el.tagName.toUpperCase() === 'INPUT' || el.tagName.toUpperCase() === 'TEXTAREA');
      if (!isCurrentElementEditable || force) {
        if (typeof subject === 'string' && !subject.trim().length && this.get('editedUser')) {
          this.focusSubject();
        } else {
          this.focusFroalaEditor();
        }
      }
    },
    focusSubjectOrReply: function focusSubjectOrReply(force) {
      scheduleOnce('afterRender', this, 'scheduleCheckingForEditableActiveElement', force);
    },


    actions: {
      dispatch: function dispatch(method) {
        var _get;

        for (var _len2 = arguments.length, rest = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          rest[_key2 - 1] = arguments[_key2];
        }

        (_get = this.get('state'))[method].apply(_get, _toConsumableArray(rest));
      },
      newConversation: function newConversation() {
        var dateStamp = (0, _moment.default)().format('YYYY-MM-DD-hh-mm-ss');
        var userId = this.get('user.id');

        this.get('routing').transitionTo('session.agent.cases.new', [dateStamp], { requester_id: userId });
      },
      setNoteDestination: function setNoteDestination(destination) {
        this.get('state').setNoteDestination(destination);
        debounce(this, 'focusSubjectOrReply', FOCUS_DEBOUNCE_TIME);
      },
      setRole: function setRole(role) {
        var state = this.get('state');
        var newRoleType = role.get('roleType');
        var oldRoleType = this.get('state.editedUser.role.roleType');
        this.setCaseAccessLevelDefaults(oldRoleType, newRoleType);
        state.setRole(role);
      },
      manageAppAccess: function manageAppAccess() {
        this.set('appAccessModal', true);
      },
      revokeAccess: function revokeAccess(grant) {
        return this.get('confirmation').confirm({
          intlConfirmationBody: 'users.grant.confirmdelete',
          intlConfirmLabel: 'users.grant.revoke'
        }).then(function () {
          return grant.destroyRecord();
        });
      },
      closeAppAccessModal: function closeAppAccessModal() {
        this.set('appAccessModal', false);
      },
      editSignature: function editSignature(signature) {
        this.set('signatureModal', true);
        this.set('editingSignature', signature);
      },
      closeSignatureModal: function closeSignatureModal() {
        this.set('signatureModal', false);
      },
      updateSignature: function updateSignature() {
        var _this4 = this;

        var i18n = this.get('i18n');
        var oldSign = this.get('editedUser.signature') || this.get('user.signature');
        this.get('state').setSignature(this.get('editingSignature'));
        this.set('user.signature', this.get('editingSignature'));
        this.get('updateSignature').perform().then(function () {
          _this4.get('notification').success(i18n.t('users.sign_update_success'));
        }).catch(function () {
          _this4.get('notification').error(i18n.t('users.sign_update_failure'));
          _this4.get('state').setSignature(oldSign);
          _this4.set('user.signature', oldSign);
        }).finally(function () {
          _this4.set('signatureModal', false);
        });
      },
      setName: function setName(name) {
        var oldName = this.get('user.fullName');
        if (name === oldName) {
          return;
        }
        this.get('updateName').perform(name, oldName);
        if (this.attrs.onTabNameUpdate) {
          this.attrs.onTabNameUpdate(name);
        }
      },
      changeUserPassword: function changeUserPassword(primaryEmailAddress) {
        var _this5 = this;

        var adapter = getOwner(this).lookup('adapter:application');

        adapter.ajax(adapter.namespace + '/base/password/reset', 'POST', {
          data: {
            email: primaryEmailAddress
          }
        }).then(function () {}, function (response) {
          _this5.get('errorHandler').process(response.responseJSON);
        });
      },
      submit: function submit() {
        var _this6 = this;

        var destination = this.get('noteDestination.id');

        if (destination === 'org') {
          this.get('addExternalNote').perform(destination);
        } else {
          this.get('submit').perform().then(function () {
            if (_this6.get('tabsModel.organization.name')) {
              _this6.resetEntityParams();
            }
          }).catch(function (e) {
            if (!(0, _emberConcurrency.didCancel)(e)) {
              throw e;
            }
          });
        }
      },
      setOrganization: function setOrganization(org) {
        var _this7 = this;

        var processOrganizationPayload = function processOrganizationPayload(payload) {
          var organizationId = get(payload, 'id');
          var data = JSON.parse(JSON.stringify(payload));
          _this7.get('store').pushPayload({ organization: data });

          return _this7.get('store').peekRecord('organization', organizationId);
        };

        this.get('state').setOrganization(org ? processOrganizationPayload(org) : org);
      },
      focusEditor: function focusEditor() {
        run.later(function () {
          jQuery(_component2.EDITOR_SELECTOR).froalaEditor('events.focus');
        });
      },
      openDisableTwoFactorModal: function openDisableTwoFactorModal() {
        this.set('disableTwoFactorModal', true);
      },
      closeDisableTwoFactorModal: function closeDisableTwoFactorModal() {
        this.set('disableTwoFactorModal', false);
      },
      removeTwoFactorAuth: function removeTwoFactorAuth() {
        var _this8 = this;

        this.set('remove2FAButtonDisabled', true);

        var userId = this.get('user.id');

        this.get('store').adapterFor('user').removeTwoFactorAuth(userId).then(function () {
          _this8.set('disableTwoFactorModal', false);
          _this8.set('remove2FAButtonDisabled', false);
          _this8.get('notification').add({
            type: 'success',
            title: _this8.get('i18n').t('users.two_factor.disable.notification'),
            autodismiss: true
          });
        }).then(function () {
          var user = _this8.get('user');
          return user.reload();
        });
      },
      handleTabbingFromSubject: function handleTabbingFromSubject(event) {
        if (event.keyCode === _keycodes.tab) {
          event.preventDefault();
          this.focusFroalaEditor();
        }
      },
      handleTabbingFromReply: function handleTabbingFromReply(event) {
        if (event.keyCode === _keycodes.tab) {
          event.preventDefault();

          if (!(this.get('editedUser.fullName') || this.get('user.fullName'))) {
            this.focusSubject();
          } else {
            this.focusInstantSearch();
          }
        }
      },
      setInstantUser: function setInstantUser(user) {
        var _this9 = this;

        if (typeof user === 'string') {
          user = user.trim();

          // Create User flow
          var isTwitter = (0, _formatValidations.validateTwitterHandleFormat)(user);

          if (isTwitter) {
            user = user.slice(1);
          }

          var i18n = this.get('i18n');

          var name = void 0;
          var id = user;

          var username = id;

          if (!isTwitter) {
            username = id.slice(0, id.indexOf('@'));
          }

          if (username.includes('+')) {
            username = username.slice(0, username.indexOf('+'));
          }

          var _username$split = username.split('.'),
              _username$split2 = _slicedToArray(_username$split, 2),
              firstName = _username$split2[0],
              lastName = _username$split2[1];

          name = capitalize(firstName);
          if (lastName && lastName.trim().length) {
            name += ' ' + capitalize(lastName);
          }

          var savePromise = this.get('createUser').perform(name, id, isTwitter);
          savePromise.then(function (user) {
            _this9.get('notification').success(i18n.t('cases.new_conversation.user_created'));

            _this9.redirectToUser(user.get('id'), user.get('fullName'));
          }).finally(function () {
            _this9.set('instantEntityTerm', '');
            _this9.set('instantEntityResults', null);
          });
        } else {
          this.set('instantEntityTerm', '');
          this.set('instantEntityResults', null);

          this.redirectToUser(user.get('id'), user.get('fullName'));
        }
      },
      handleInstantEntityTabbing: function handleInstantEntityTabbing(key) {
        if (key === 'TAB') {
          this.focusSubjectOrReply(true);
        }
      },
      setOrganizationModeOn: function setOrganizationModeOn() {
        this.resetEntityParams(true);
      },
      setOrganizationModeOff: function setOrganizationModeOff() {
        this.resetEntityParams();
        this.focusSubjectOrReply(true);
      },
      setInstantOrg: function setInstantOrg(org) {
        var _this10 = this;

        if (typeof org === 'string') {
          org = capitalize(org.trim());

          // Create Org flow
          var i18n = this.get('i18n');

          var savePromise = this.get('createOrg').perform(org);
          savePromise.then(function (org) {
            _this10.get('notification').success(i18n.t('organization.created'));
            var user = _this10.get('user') || _this10.get('editedUser');
            _this10.get('persistOrgToUser').perform(user, org);
          }).finally(function () {
            _this10.resetEntityParams();
          });
        } else {
          this.resetEntityParams();
          var user = this.get('user') || this.get('editedUser');
          this.get('persistOrgToUser').perform(user, org);
        }
      },
      updateOrgRemovalState: function updateOrgRemovalState(value, org) {
        this.set('unsetOrgInProgress', value);
        this.set('removedOrg', org);

        var selectedNoteDestinationIsOrg = this.get('selectedNoteDestination.id') === 'org';

        if (!value && !org && selectedNoteDestinationIsOrg) {
          this.get('state').setNoteDestination(this.get('noteDestinations.firstObject'));
        }
      },
      onAttachFiles: function onAttachFiles(files) {
        var _this11 = this;

        var service = this.get('uploadService');
        var attachedPostFiles = this.get('attachedPostFiles');
        var onUploadAttachmentStart = function onUploadAttachmentStart() {
          var _get2;

          return (_get2 = _this11.get('state')).addAttachment.apply(_get2, arguments);
        };
        var onUploadAttachment = function onUploadAttachment() {
          var _get3;

          return (_get3 = _this11.get('state')).updateAttachments.apply(_get3, arguments);
        };
        files.forEach(function (file) {
          return service.get('uploadFile').perform(file, attachedPostFiles, null, onUploadAttachmentStart, onUploadAttachment).catch(function () {/* Swallow this as this isn't an error we can handle */});
        });
      },
      resetProperties: function resetProperties() {
        this.get('state').resetSidebar();
      }
    },

    resetEntityParams: function resetEntityParams(doSetOrg, doSetReq) {
      this.set('instantEntityTerm', '');
      this.set('instantEntityResults', null);
      this.set('setOrganizationMode', !!doSetOrg);
      this.set('setRequesterMode', !!doSetReq);
    },
    redirectToUser: function redirectToUser(id, name) {
      var _this12 = this;

      var router = this.get('routing');
      this.set('redirectingToUser', true);
      this.set('username', name);

      this.sendAction('openInSameTab');
      router.transitionTo('session.agent.users.user', [id]).then(function () {
        _this12.set('redirectingToUser', false);
      }).finally(function () {
        _this12.set('redirectingToUser', false);
      });
    },
    initRoles: function initRoles() {
      var _this13 = this;

      this.get('store').findAll('role').then(function (roles) {
        return _this13.set('roles', roles);
      });
    },
    initTeams: function initTeams() {
      var _this14 = this;

      this.get('store').findAll('team').then(function (teams) {
        _this14.set('teamRecords', teams);
      });
    },
    initRecentFeedback: function initRecentFeedback() {
      if (this.get('user.id')) {
        var state = this.get('state');
        state.set('recentFeedback', []);
        this.get('store').query('rating', {
          limit: 3,
          user_id: this.get('user.id'),
          include: ['case'],
          fields: 'case,score,created_at'
        }).then(function (ratings) {
          return state.set('recentFeedback', ratings.toArray());
        });
      }
    }
  });
});