define('frontend-cp/components/ko-edit-signature/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    notification: service('notification'),
    i18n: service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('editingSignature', this.get('currentUser.signature'));
    },


    updateSignature: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var userId, signature;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              userId = this.get('currentUser.id');
              signature = this.get('editingSignature');
              _context.prev = 2;
              _context.next = 5;
              return this.get('store').adapterFor('user').updateSignature(userId, signature);

            case 5:
              this.get('onClose')();
              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('generic.changes_saved'),
                autodismiss: true
              });
              this.get('onUpdate')();
              _context.next = 13;
              break;

            case 10:
              _context.prev = 10;
              _context.t0 = _context['catch'](2);

              this.get('notification').add({
                type: 'error',
                title: this.get('i18n').t('generic.generic_error'),
                autodismiss: true
              });

            case 13:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[2, 10]]);
    })).drop(),

    actions: {
      editSignature: function editSignature(signature) {
        this.set('editingSignature', signature);
      }
    }
  });
});