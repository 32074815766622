define('frontend-cp/services/launch-darkly', ['exports', 'ember-launch-darkly/services/launch-darkly'], function (exports, _launchDarkly) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _launchDarkly.default;
    }
  });
});