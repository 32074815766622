define('frontend-cp/components/ko-admin/macros/edit/action-handlers/add-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    serialize: function serialize(action, macro, store) {
      this.reset(macro);

      if (action.value) {
        var tags = action.value.split(',');
        var addTags = macro.get('addTags');

        if (addTags) {
          macro.get('addTags').setObjects(tags);
        } else {
          macro.set('addTags', tags);
        }
      }

      return macro;
    },
    deserialize: function deserialize(macro, actions) {
      var addTags = macro.get('addTags');

      if (addTags && addTags.length) {
        actions.push({
          name: 'add-tags',
          option: 'ADD',
          value: addTags.join(',')
        });
      }

      return actions;
    },
    reset: function reset(macro) {
      macro.set('addTags', []);
    }
  };
});