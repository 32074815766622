define('frontend-cp/models/engagement', ['exports', 'ember-data', 'frontend-cp/models/post-creator'], function (exports, _emberData, _postCreator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _postCreator.default.extend({
    title: _emberData.default.attr('string'),
    executionOrder: _emberData.default.attr('number'),
    isEnabled: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    metric: _emberData.default.attr(),

    // Relations
    predicateCollections: _emberData.default.hasMany('predicate-collection', { defaultValue: [], async: false }),
    actions: _emberData.default.hasMany('automation-action', { defaultValue: [], async: false })
  });
});