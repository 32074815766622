define('frontend-cp/adapters/template', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      var brandId = query.brand.id;
      var name = query.name;
      Reflect.deleteProperty(query, 'brand');
      Reflect.deleteProperty(query, 'name');
      return this.namespace + ('/brands/' + brandId + '/templates/' + name);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return this.namespace + id.split(this.namespace)[1];
    },
    updateTempalte: function updateTempalte(brandId, name, contents) {
      var url = this.namespace + '/brands/' + brandId + '/templates/' + name;
      return this.ajax(url, 'PUT', {
        data: {
          contents: contents
        }
      });
    }
  });
});