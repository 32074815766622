define('frontend-cp/components/ko-pagination/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    page: null,
    isActive: false,

    qaCls: computed('page', function () {
      return 'qa-pagination-' + this.get('page');
    })
  });
});