define("frontend-cp/components/ko-admin/fields/form/statuses/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "buttons": "ko-admin_fields_form_statuses__buttons_1c1bl5",
    "add-status": "ko-admin_fields_form_statuses__add-status_1c1bl5",
    "legend": "ko-admin_fields_form_statuses__legend_1c1bl5 ko-admin_forms_group__legend_9ysg3g",
    "loader": "ko-admin_fields_form_statuses__loader_1c1bl5",
    "label": "ko-admin_fields_form_statuses__label_1c1bl5",
    "caption": "ko-admin_fields_form_statuses__caption_1c1bl5"
  };
});