define('frontend-cp/components/ko-admin/team/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var get = Ember.get;


  var DEBOUNCE_MS = 250;
  var MIN_TERM_LENGTH = 3;

  exports.default = Component.extend({
    tagName: '',

    // Attributes
    title: null,
    businessHours: null,
    team: null,
    editedTeam: null,
    schema: null,
    onCancel: function onCancel() {},
    onDelete: function onDelete() {},
    onSuccess: function onSuccess() {},

    // State
    noMatchesMessage: null,

    // Services
    confirmation: service(),
    i18n: service(),
    session: service(),
    store: service(),
    errorHandler: service(),
    virtualModel: service(),
    router: service('-routing'),

    // Computed Properties
    members: computed('editedTeam.members.@each.fullName', function () {
      return this.get('editedTeam.members').sortBy('fullName');
    }),

    // Tasks
    findAgents: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(term) {
      var i18n, store, query, fields, include, limit, results, agents;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              i18n = this.get('i18n');


              this.set('noMatchesMessage', i18n.t('users.team_member_search.no_results'));

              if (!(!term || term.length < MIN_TERM_LENGTH)) {
                _context.next = 5;
                break;
              }

              this.set('noMatchesMessage', i18n.t('users.team_member_search.too_short', { count: MIN_TERM_LENGTH }));
              return _context.abrupt('return', []);

            case 5:
              _context.next = 7;
              return (0, _emberConcurrency.timeout)(DEBOUNCE_MS);

            case 7:
              store = this.get('store');
              query = '"' + term + '" in:users (role:agent OR role:collaborator)';
              fields = 'resource,resource_url,data(is_enabled,avatar,full_name,last_active_at,role(title))';
              include = 'user,role';
              limit = 10;
              _context.next = 14;
              return store.query('search-result', { query: query, fields: fields, include: include, limit: limit });

            case 14:
              results = _context.sent;
              _context.next = 17;
              return (0, _emberConcurrency.all)(results.mapBy('resultData'));

            case 17:
              agents = _context.sent;


              agents = agents.filterBy('isEnabled');

              return _context.abrupt('return', agents);

            case 20:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    addMember: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(user) {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              this.get('editedTeam.members').addObject(user);

            case 1:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    removeMember: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(user) {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              this.get('editedTeam.members').removeObject(user);

            case 1:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    viewMember: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4(user) {
      var router, id;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              router = this.get('router');
              id = get(user, 'id');


              router.transitionTo('session.agent.users.user', [id]);

            case 3:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5() {
      var team, editedTeam, virtualModel, schema, store, teamAdapter, oldMembers, newMembers, membersToAdd, membersToRemove, errors;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              team = this.get('team');
              editedTeam = this.get('editedTeam');
              virtualModel = this.get('virtualModel');
              schema = this.get('schema');
              store = this.get('store');
              teamAdapter = store.adapterFor('team');
              oldMembers = team.get('members');
              newMembers = editedTeam.get('members');
              membersToAdd = newMembers.filter(function (user) {
                return !oldMembers.includes(user);
              });
              membersToRemove = oldMembers.filter(function (user) {
                return !newMembers.includes(user);
              });
              _context5.prev = 10;
              _context5.next = 13;
              return virtualModel.save(team, editedTeam, schema);

            case 13:
              if (!membersToAdd.length) {
                _context5.next = 16;
                break;
              }

              _context5.next = 16;
              return teamAdapter.addMembers(team, membersToAdd);

            case 16:
              if (!membersToRemove.length) {
                _context5.next = 19;
                break;
              }

              _context5.next = 19;
              return teamAdapter.removeMembers(team, membersToRemove);

            case 19:
              _context5.next = 21;
              return team.save();

            case 21:

              this.get('onSuccess')();
              _context5.next = 28;
              break;

            case 24:
              _context5.prev = 24;
              _context5.t0 = _context5['catch'](10);
              errors = _context5.t0.responseJSON;

              this.get('errorHandler').process({ errors: errors });

            case 28:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this, [[10, 24]]);
    })).drop(),

    delete: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee6() {
      var confirmed;
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              _context6.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmLabel: 'generic.confirm.delete_button',
                intlConfirmationBody: 'admin.teams.labels.delete_team_confirmation',
                intlConfirmationHeader: 'admin.teams.labels.confirm_delete'
              });

            case 2:
              confirmed = _context6.sent;

              if (confirmed) {
                _context6.next = 5;
                break;
              }

              return _context6.abrupt('return');

            case 5:
              _context6.next = 7;
              return this.get('team').destroyRecord();

            case 7:

              this.get('onDelete')();

            case 8:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    })).drop(),

    cancel: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee7() {
      return regeneratorRuntime.wrap(function _callee7$(_context7) {
        while (1) {
          switch (_context7.prev = _context7.next) {
            case 0:
              this.get('onCancel')();

            case 1:
            case 'end':
              return _context7.stop();
          }
        }
      }, _callee7, this);
    }))
  });
});