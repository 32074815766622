define('frontend-cp/components/ko-instant-entity/component', ['exports', 'ember-concurrency', 'ember-keyboard-shortcuts/mixins/component', 'frontend-cp/lib/keycodes', 'frontend-cp/utils/format-validations', 'frontend-cp/components/ko-instant-entity/item/styles', 'frontend-cp/components/ko-instant-entity/styles'], function (exports, _emberConcurrency, _component, _keycodes, _formatValidations, _styles, _styles2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var on = Ember.on;
  var Component = Ember.Component;
  var scheduleOnce = Ember.run.scheduleOnce;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var or = Ember.computed.or;
  var bool = Ember.computed.bool;
  var equal = Ember.computed.equal;
  var reads = Ember.computed.reads;
  var observer = Ember.observer;
  var get = Ember.get;
  exports.default = Component.extend(_component.default, {
    // Attributes
    instantEntityTerm: '',
    instantEntityResults: null,
    actionTriggered: false,
    isProcessRunning: false,
    forceFocus: false,
    action: null,
    limit: 20,
    isCase: false,
    qaClass: null,

    /**
     * Type: Function
     */
    keyboardAction: null,
    cancelAction: null,

    /**
     * Type: String
     * Acceptable values: user | organization
     * Default: user
     */
    mode: 'user',
    disablePointer: false,
    isInputDirty: false,
    pointerLocation: {
      x: -1,
      y: -1
    },
    keyboardShortcuts: {
      esc: {
        action: 'triggerCancelAction',
        global: true,
        scoped: true,
        preventDefault: true
      }
    },

    // Services
    store: service(),
    i18n: service(),
    tabStore: service(),

    // Hooks
    didRender: function didRender() {
      this.focusInstantSearch();
      this.focusFirstResultIfNoneFocused();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },


    tabChanged: observer('tabStore.activeTab.basePath', 'currentPath', function () {
      this.focusInstantSearch();
    }),

    // CPs
    inUserMode: equal('mode', 'user'),

    pointerDisabled: bool('disablePointer'),

    cancellable: bool('cancelAction'),

    showAddOption: computed('inUserMode', 'resultIsSameAsTerm', 'isInstantLoadRunning', 'isValidInstantEntityTerm', function () {
      var inUserMode = this.get('inUserMode');
      var resultIsSameAsTerm = this.get('resultIsSameAsTerm');
      var isInstantLoadRunning = this.get('isInstantLoadRunning');
      var isValidInstantEntityTerm = this.get('isValidInstantEntityTerm');

      var term = this.get('instantEntityTerm');
      var isEmail = (0, _formatValidations.validateEmailFormat)(term);

      var ifTermIsValidWhileResultsAreNotLoading = isValidInstantEntityTerm && !isInstantLoadRunning;
      var ifOrgDoesNotExistInOrgMode = !inUserMode && !resultIsSameAsTerm;
      var ifUserDoesNotExistInUserMode = inUserMode && !resultIsSameAsTerm && isEmail;
      var entityDoesNotExist = ifOrgDoesNotExistInOrgMode || ifUserDoesNotExistInUserMode;

      return ifTermIsValidWhileResultsAreNotLoading && entityDoesNotExist;
    }),

    isInstantLoadRunning: or('loadInstantUsers.isRunning', 'loadInstantOrgs.isRunning'),

    invalidState: computed('isValidInstantEntityTerm', 'showAddOption', 'instantEntityResults', 'isInstantLoadRunning', function () {
      var isValidTerm = this.get('isValidInstantEntityTerm');
      var showAddOption = this.get('showAddOption');
      var isInstantLoadRunning = this.get('isInstantLoadRunning');
      var hasResults = Boolean(this.get('instantEntityResults.length'));

      if (!isValidTerm || isInstantLoadRunning) {
        return false;
      } else {
        return !showAddOption && !hasResults;
      }
    }),

    currentPath: reads('tabStore.activeTab.basePath'),

    resultIsSameAsTerm: computed('instantEntityTerm', 'instantEntityResults', function () {
      var term = this.get('instantEntityTerm').toLowerCase();

      var results = this.get('instantEntityResults');

      if (results) {
        if (this.get('inUserMode')) {
          var emails = results.getEach('primaryEmailAddress').compact().map(function (email) {
            return email.toLowerCase();
          }).includes(term);
          var twitter = results.getEach('primaryTwitterHandle').compact().map(function (handle) {
            return handle.toLowerCase();
          }).includes(term.slice(1));

          return emails || twitter;
        } else {
          return results.getEach('name').compact().map(function (name) {
            return name.toLowerCase();
          }).includes(term);
        }
      } else {
        return false;
      }
    }),

    isValidInstantEntityTerm: computed('instantEntityTerm', function () {
      return this.get('instantEntityTerm').trim().length > 2;
    }),

    // Tasks
    loadInstantUsers: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(term) {
      var results;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('store').query('search-result', {
                query: 'in:users ' + term,
                include: '*',
                offset: 0,
                limit: this.get('limit')
              });

            case 2:
              results = _context.sent;
              return _context.abrupt('return', results.mapBy('resultData'));

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    loadInstantOrgs: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(term) {
      var results;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('store').query('search-result', {
                query: 'in:organizations ' + term,
                include: '*',
                offset: 0,
                limit: this.get('limit')
              });

            case 2:
              results = _context2.sent;
              return _context2.abrupt('return', results.mapBy('resultData'));

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).restartable(),

    onOutsideClick: function onOutsideClick() {
      if (this.get('cancelAction')) {
        this.send('triggerCancelAction');
      }
    },

    handleOutsideClick: function handleOutsideClick(event) {
      var $element = this.$();
      var $target = $(event.target);

      if (!$target.closest($element).length) {
        this.onOutsideClick();
      }
    },

    setupOutsideClickListener: on('didInsertElement', function () {
      var clickHandler = this.get('handleOutsideClick').bind(this);

      return $(document).on('click.kie', clickHandler);
    }),

    removeOutsideClickListener: on('willDestroyElement', function () {
      return $(document).off('click.kie');
    }),

    findAndFocusOnInstantSearchBar: function findAndFocusOnInstantSearchBar() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var el = document.activeElement;
      var isCurrentElementEditable = el && (el.isContentEditable || el.tagName.toUpperCase() === 'INPUT' || el.tagName.toUpperCase() === 'TEXTAREA');
      // In case the target is removed from the DOM, the focus would go back to the last element, which maybe editable.
      // We don't want that to prevent focus from getting to KIE.
      if (!isCurrentElementEditable || this.get('forceFocus')) {
        $('#' + this.get('elementId') + '-instant-input').focus();
      }
    },
    focusInstantSearch: function focusInstantSearch() {
      scheduleOnce('afterRender', this, 'findAndFocusOnInstantSearchBar');
    },
    focusFirstResultIfNoneFocused: function focusFirstResultIfNoneFocused() {
      var list = this.$('.' + _styles2.default['instant-entity-list']);
      var focused = list.find(this.$('.' + _styles.default['instant-entity'] + '.' + _styles.default.focused));

      if (focused.length === 0) {
        $(list.find('.' + _styles.default['instant-entity'])[0]).addClass(_styles.default.focused);
      }
    },
    scrollToItemIfOutOfView: function scrollToItemIfOutOfView(item, e) {
      var parent = this.$(item).parent();
      var child = this.$(item);

      var parentBox = parent[0].getBoundingClientRect();
      var childBox = child[0].getBoundingClientRect();

      if (!this.get('disablePointer')) {
        this.set('disablePointer', true);
      }
      if (parentBox.bottom < childBox.bottom) {
        parent.scrollTop(parent.scrollTop() + childBox.bottom - parentBox.bottom);
      }

      if (childBox.top < parentBox.top) {
        parent.scrollTop(parent.scrollTop() + childBox.top - parentBox.top);
      }
    },


    actions: {
      triggerCancelAction: function triggerCancelAction() {
        if (this.get('cancelAction')) {
          this.sendAction('cancelAction');
        } else {
          this.set('instantEntityTerm', '');
          this.set('instantEntityResults', null);
          $('#' + this.get('elementId') + '-instant-input').val('');
        }
      },
      instantUserSearch: function instantUserSearch(value) {
        var _this = this;

        value = value.trim();

        this.set('instantEntityTerm', value);

        if ((0, _formatValidations.validateTwitterHandleFormat)(value)) {
          value = value.slice(1);
        }

        if (this.get('isValidInstantEntityTerm')) {
          this.get('loadInstantUsers').perform(value).then(function (results) {
            if (_this.get('isCase')) {
              results = results.filter(function (result) {
                return get(result, 'isEnabled') === true;
              });
            }
            _this.set('instantEntityResults', results);
          }).catch(function () {/* Catch TaskCancellation Error*/});
        } else {
          this.set('instantEntityTerm', '');
          this.set('instantEntityResults', null);
        }
      },
      instantOrgSearch: function instantOrgSearch(value) {
        var _this2 = this;

        value = value.trim();

        this.set('instantEntityTerm', value);

        if (this.get('isValidInstantEntityTerm')) {
          this.get('loadInstantOrgs').perform(value).then(function (results) {
            return _this2.set('instantEntityResults', results);
          }).catch(function () {/* Catch TaskCancellation Error*/});
        } else {
          this.set('instantEntityTerm', '');
          this.set('instantEntityResults', null);
        }
      },
      handleInstantUserSearchKeypress: function handleInstantUserSearchKeypress(val, event) {
        this.set('isInputDirty', true);
        if (event.keyCode === _keycodes.up || event.keyCode === _keycodes.down || event.keyCode === _keycodes.enter || event.keyCode === _keycodes.tab) {
          event.preventDefault();
        }

        if (event.keyCode === _keycodes.tab) {
          if (this.get('keyboardAction')) {
            this.send('triggerCancelAction');
            this.sendAction('keyboardAction', 'TAB');
          }
        }

        var list = this.$('.' + _styles2.default['instant-entity-list']);
        var results = list.find(this.$('.' + _styles.default['instant-entity']));
        var focused = list.find(this.$('.' + _styles.default['instant-entity'] + '.' + _styles.default.focused));
        list.find(this.$('.' + _styles.default['instant-entity'] + '.' + _styles.default.focused)).removeClass(_styles.default.focused);

        if (results && results.length) {
          var focusedIndex = results.toArray().indexOf(focused[0]);
          var index = focusedIndex === -1 ? 0 : focusedIndex;

          if (event.keyCode === _keycodes.up) {
            index = focusedIndex - 1 < 0 ? results.length - 1 : focusedIndex - 1;
          } else if (event.keyCode === _keycodes.down) {
            index = focusedIndex + 1 >= results.length ? 0 : focusedIndex + 1;
          }

          this.$(results[index]).addClass(_styles.default.focused);

          this.scrollToItemIfOutOfView(this.$(results[index]), event);

          if (event.keyCode === _keycodes.enter) {
            list.find(this.$('.' + _styles.default['instant-entity'] + '.' + _styles.default.focused)).click();
          }
        }
      },
      addFocus: function addFocus(event) {
        this.$('.' + _styles.default['instant-entity'] + '.' + _styles.default.focused).removeClass(_styles.default.focused);
        $(event.currentTarget).addClass(_styles.default.focused);
      },
      removeFocus: function removeFocus(event) {
        $(event.currentTarget).removeClass(_styles.default.focused);
      },
      setInstantEntity: function setInstantEntity(entity) {
        this.set('isInputDirty', false);
        try {
          this.get('loadInstantUsers').cancelAll();
          this.get('loadInstantOrgs').cancelAll();
        } catch (err) {
          // err is a Task Cancellation error.
          // Safely ignored.
        }

        this.set('actionTriggered', true);

        this.sendAction('action', entity);
      },
      enablePointer: function enablePointer(e) {
        var _get = this.get('pointerLocation'),
            x = _get.x,
            y = _get.y;

        if (e.clientX !== x || e.clientY !== y) {
          this.set('disablePointer', false);
          this.set('pointerLocation', { x: e.clientX, y: e.clientY });
        }
      },
      trackPointer: function trackPointer(e) {
        this.set('pointerLocation', { x: e.clientX, y: e.clientY });
      }
    }
  });
});