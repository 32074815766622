define('frontend-cp/components/ko-timeline-2/list/item/created-at/email-modal/iframe/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var guidFor = Ember.guidFor;
  var computed = Ember.computed;
  var jQuery = Ember.$;
  var run = Ember.run;
  exports.default = Component.extend({
    tagName: '',

    content: null,
    qaClass: null,

    eid: computed(function () {
      return 'ko-' + guidFor(this);
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      run.next(function () {
        var content = _this.get('content');

        if (content) {
          _this._updateContent(_this._convertEmailAddressesInAngleBracketsToValidHtml(content));
          _this._updateStyling();
        }
      });
    },
    _convertEmailAddressesInAngleBracketsToValidHtml: function _convertEmailAddressesInAngleBracketsToValidHtml(content) {
      return content.replace(/<.+?>/g, function (substring) {
        if (substring.includes('@')) {
          return substring.replace('<', '&lt').replace('>', '&gt');
        } else {
          return substring;
        }
      });
    },
    _updateContent: function _updateContent(content) {
      var doc = jQuery('#' + this.get('eid'))[0].contentDocument;
      doc.open();
      doc.write('<!DOCTYPE html>');
      doc.write(content);
      doc.close();
    },
    _updateStyling: function _updateStyling() {
      var styles = '\n    <style type="text/css">\n      body {\n        color: #5F6C73;\n        font-family: sans-serif;\n        font-size: 14px;\n        line-height: 1.6;\n        -webkit-font-smoothing: antialiased;\n        -moz-osx-font-smoothing: grayscale;\n      }\n\n      a {\n        color: #42aac8;\n        text-decoration: none;\n      }\n\n      a:hover {\n        color: #3496b2;\n      }\n    </style>\n    ';

      var head = jQuery('#' + this.get('eid'))[0].contentDocument.head;

      jQuery(head).prepend(styles);
    }
  });
});