define('frontend-cp/models/sla-version', ['exports', 'ember-data', 'frontend-cp/models/post-creator'], function (exports, _emberData, _postCreator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _postCreator.default.extend({
    title: _emberData.default.attr('string', { defaultValue: '' }),
    description: _emberData.default.attr('string', { defaultValue: '' }),
    createdAt: _emberData.default.attr('date')
  });
});