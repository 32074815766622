define('frontend-cp/session/admin/manage/views/schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _virtualModel.model)('view', {
    title: (0, _virtualModel.attr)(),
    isEnabled: (0, _virtualModel.attr)(),
    visibilityType: (0, _virtualModel.attr)(),
    visibilityToTeams: (0, _virtualModel.many)((0, _virtualModel.attr)()),
    predicateCollections: (0, _virtualModel.many)((0, _virtualModel.model)('predicate-collection', {
      id: (0, _virtualModel.attr)(),
      propositions: (0, _virtualModel.many)((0, _virtualModel.model)('proposition', {
        id: (0, _virtualModel.attr)(),
        field: (0, _virtualModel.attr)(),
        operator: (0, _virtualModel.attr)(),
        value: (0, _virtualModel.attr)()
      }))
    })),
    columns: (0, _virtualModel.many)((0, _virtualModel.attr)()),
    orderByColumn: (0, _virtualModel.attr)(),
    orderBy: (0, _virtualModel.attr)()
  });
});