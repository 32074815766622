define('frontend-cp/initializers/enable-aria-attributes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var LinkComponent = Ember.LinkComponent;
  function initialize() /* application */{
    LinkComponent.reopen({
      attributeBindings: ['aria-label']
    });
  }

  exports.default = {
    name: 'enable-aria-attributes',
    initialize: initialize
  };
});