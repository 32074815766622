define('frontend-cp/serializers/user-field', ['exports', 'frontend-cp/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      customerTitles: { embedded: 'always' },
      descriptions: { embedded: 'always' },
      options: { embedded: 'always' }
    },

    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      if (resourceHash.user_field_type) {
        resourceHash.field_type = resourceHash.user_field_type;
        Reflect.deleteProperty(resourceHash, 'user_field_type');
      }
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var payload = this._super.apply(this, arguments);
      payload.type = payload.field_type;
      Reflect.deleteProperty(payload, 'field_type');
      return payload;
    }
  });
});