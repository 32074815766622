define('frontend-cp/components/ko-insights/custom-reports/form/preview/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var reads = Ember.computed.reads;
  exports.default = Component.extend({
    tagName: '',

    store: service(),

    predicateCollections: null,

    previews: reads('preview.lastSuccessful.value'),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('predicateCollections')) {
        this.set('predicateCollections', []);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.get('preview').perform();
    },


    preview: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var collections;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(1000);

            case 2:
              collections = [];

              this.get('predicateCollections').forEach(function (collection) {

                var propositions = [];
                collection.get('propositions').forEach(function (proposition) {
                  var _proposition$getPrope = proposition.getProperties('field', 'operator', 'value'),
                      field = _proposition$getPrope.field,
                      operator = _proposition$getPrope.operator,
                      value = _proposition$getPrope.value;

                  if (field && operator && value) {
                    propositions.push({ field: field, operator: operator, value: value });
                  }
                });

                if (propositions.length) {
                  collections.push({ propositions: propositions });
                }
              });

              if (!(collections.length === 0)) {
                _context.next = 6;
                break;
              }

              return _context.abrupt('return', []);

            case 6:
              _context.prev = 6;
              _context.next = 9;
              return this.get('store').query('report-case', { predicate_collections: collections, object: 'CASES' });

            case 9:
              return _context.abrupt('return', _context.sent);

            case 12:
              _context.prev = 12;
              _context.t0 = _context['catch'](6);
              return _context.abrupt('return', []);

            case 15:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[6, 12]]);
    })).restartable()

  });
});