define("frontend-cp/components/ko-admin/automation-actions-builder/autocomplete-team-or-agent/assignee/trigger-value/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-admin_automation-actions-builder_autocomplete-team-or-agent_assignee_trigger-value__trigger_msor7c",
    "separator": "ko-admin_automation-actions-builder_autocomplete-team-or-agent_assignee_trigger-value__separator_msor7c",
    "agent": "ko-admin_automation-actions-builder_autocomplete-team-or-agent_assignee_trigger-value__agent_msor7c",
    "avatar": "ko-admin_automation-actions-builder_autocomplete-team-or-agent_assignee_trigger-value__avatar_msor7c",
    "agent-name": "ko-admin_automation-actions-builder_autocomplete-team-or-agent_assignee_trigger-value__agent-name_msor7c"
  };
});