define("frontend-cp/components/articles-view-list/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "loading-overlay": "articles-view-list__loading-overlay_1gq17o",
    "loading-message": "articles-view-list__loading-message_1gq17o",
    "header": "articles-view-list__header_1gq17o",
    "articles-table": "articles-view-list__articles-table_1gq17o",
    "show-filters-btn": "articles-view-list__show-filters-btn_1gq17o",
    "status": "articles-view-list__status_1gq17o",
    "published": "articles-view-list__published_1gq17o",
    "pagination": "articles-view-list__pagination_1gq17o",
    "selected": "articles-view-list__selected_1gq17o",
    "article-row": "articles-view-list__article-row_1gq17o",
    "hidden": "articles-view-list__hidden_1gq17o",
    "bulk-actions": "articles-view-list__bulk-actions_1gq17o",
    "trash-button": "articles-view-list__trash-button_1gq17o",
    "no-articles-message": "articles-view-list__no-articles-message_1gq17o"
  };
});