define('frontend-cp/adapters/user-note', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = new Ember.Inflector(Ember.Inflector.defaultRules);

  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'notes';
    },
    urlForQuery: function urlForQuery(query, modelName) {
      var parent = query.parent;
      if (parent) {
        Reflect.deleteProperty(query, 'parent');
        var id = parent.id;
        var url = this._super.apply(this, arguments);
        var pathForType = this.pathForType();
        return url.replace(pathForType, inflector.pluralize(parent._internalModel.modelName) + '/' + id + '/' + pathForType);
      }
      return this._super.apply(this, arguments);
    },
    urlForCreateRecord: function urlForCreateRecord(modelType, snapshot) {
      var pathForType = this.pathForType();
      var url = this._super.apply(this, arguments);
      return url.replace(pathForType, 'users/' + snapshot.record.get('user.id') + '/' + pathForType);
    },
    query: function query(store, type, _query) {
      var user = _query.parent;
      return this._super.apply(this, arguments).then(function (payload) {
        payload.data.forEach(function (entry) {
          entry.parent = { id: user.get('id'), type: 'user' };
        });

        // Need to unload posts for current user when we request new user notes
        // because currently API gives us notes, and don't have same API as in
        // case posts to draw Profile Timeline. To reuse current Timeline code
        // we have to push "post" model. So when we request user-notes, we
        // actually create post model and don't push user-note into the store.
        // @see serializers/user-note
        store.peekAll('post').forEach(function (post) {
          if (parseInt(user.get('id')) === parseInt(post.get('original.parent.id'))) {
            store.unloadRecord(post);
          }
        });

        return payload;
      });
    }
  });
});