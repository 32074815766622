define('frontend-cp/components/ko-admin/automation-actions-builder/action/component', ['exports', 'npm:lodash', 'ember-basic-dropdown/utils/computed-fallback-if-undefined'], function (exports, _npmLodash, _computedFallbackIfUndefined) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    type: '',
    currentAction: null,
    definition: null,
    definitions: (0, _computedFallbackIfUndefined.default)([]),
    shouldGroupDefinitions: (0, _computedFallbackIfUndefined.default)(false),
    showRemoveActionButton: (0, _computedFallbackIfUndefined.default)(false),
    onRemoveAction: function onRemoveAction() {},
    onChangeAction: function onChangeAction() {},


    i18n: service(),

    // CPs
    groupedDefinitions: computed('definitions.@each.id', function () {
      var _this = this;

      var _getProperties = this.getProperties('shouldGroupDefinitions', 'definitions'),
          shouldGroupDefinitions = _getProperties.shouldGroupDefinitions,
          definitions = _getProperties.definitions;

      if (!shouldGroupDefinitions) {
        return definitions;
      }

      return _npmLodash.default.map(_npmLodash.default.groupBy(definitions.toArray(), function (definition) {
        return definition.get('group');
      }), function (options, group) {
        return {
          groupName: _this.get('i18n').t('admin.automation_actions_builder.group.' + group),
          options: options.sort(function (option) {
            return option.label;
          })
        };
      }).sort(function (group) {
        return group.groupBy;
      });
    }),

    // Actions
    actions: {
      onRemoveAction: function onRemoveAction(action, definition) {
        this.get('onRemoveAction')(action, definition);
      },
      onChangeAction: function onChangeAction(action, previousDefinition, selectedDefinition) {
        this.get('onChangeAction')(action, previousDefinition, selectedDefinition);
      }
    }
  });
});