define("frontend-cp/components/ko-on-boarding/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "smoothTransition": "cubic-bezier(0.19, 1, 0.22, 1)",
    "container": "ko-on-boarding__container_wvn9fj",
    "wrapper": "ko-on-boarding__wrapper_wvn9fj",
    "header": "ko-on-boarding__header_wvn9fj",
    "title": "ko-on-boarding__title_wvn9fj",
    "subtitle": "ko-on-boarding__subtitle_wvn9fj",
    "progress-bar": "ko-on-boarding__progress-bar_wvn9fj",
    "progress": "ko-on-boarding__progress_wvn9fj",
    "progress-tip-container": "ko-on-boarding__progress-tip-container_wvn9fj",
    "progress-tip": "ko-on-boarding__progress-tip_wvn9fj",
    "progress-value": "ko-on-boarding__progress-value_wvn9fj"
  };
});