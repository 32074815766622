define('frontend-cp/models/chat', ['exports', 'ember-data', 'frontend-cp/models/account'], function (exports, _emberData, _account) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _account.default.extend({
    agent: _emberData.default.belongsTo('user', { async: true }),
    brand: _emberData.default.belongsTo('brand', { async: false }),
    createdAt: _emberData.default.attr('date'),
    creator: _emberData.default.belongsTo('user', { async: true }),
    email: _emberData.default.attr('string'),
    isProactive: _emberData.default.attr('boolean'),
    lastactivityAt: _emberData.default.attr('date'),
    name: _emberData.default.attr('string'),
    startedAt: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    team: _emberData.default.belongsTo('team', { async: true }),
    token: _emberData.default.attr('string'),
    uuid: _emberData.default.attr('string'),
    waitTime: _emberData.default.attr('number'),
    //This resource is returned from the posts endpoint for legacy chats
    //Being able to determine the model allows us to apply some specific behaviour
    //bodyhtml is all that is required from the old resource in addition to what is
    //above to render the message correctly on the timeline
    isLegacyPostType: true,
    bodyHtml: _emberData.default.attr('string')
  });
});