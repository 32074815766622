define('frontend-cp/session/admin/apps/api/oauth-apps/edit/controller', ['exports', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/apps/api/oauth-apps/schema'], function (exports, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    notification: service(),
    confirmation: service(),
    i18n: service(),
    virtualModel: service(),
    editedApp: null,

    schema: _schema.default,

    initEdits: function initEdits() {
      this.set('editedApp', this.get('virtualModel').makeSnapshot(this.get('model'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('model'), this.get('editedApp'), _schema.default);
    },


    actions: {
      updated: function updated() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });
        this.initEdits();
        this.transitionToRoute('session.admin.apps.api.oauth-apps.index');
      },
      canceled: function canceled() {
        this.transitionToRoute('session.admin.apps.api.oauth-apps.index');
      },
      deleted: function deleted() {
        var _this = this;

        return this.get('confirmation').confirm({
          intlConfirmLabel: this.get('i18n').t('admin.oauthapps.delete.button'),
          intlConfirmationBody: this.get('i18n').t('admin.oauthapps.delete.message', { name: this.get('model.name') }),
          isIntl: true
        }).then(function () {
          _this.get('model').destroyRecord().then(function () {
            _this.transitionToRoute('session.admin.apps.api.oauth-apps.index');
          });
        });
      }
    }
  });
});