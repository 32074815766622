define("frontend-cp/components/ko-agent-content/sidebar/requester-info-card/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "info-card": "ko-agent-content_sidebar_requester-info-card__info-card_148gqp",
    "org-logo": "ko-agent-content_sidebar_requester-info-card__org-logo_148gqp",
    "requester-overview": "ko-agent-content_sidebar_requester-info-card__requester-overview_148gqp",
    "overview-row": "ko-agent-content_sidebar_requester-info-card__overview-row_148gqp",
    "requester-name": "ko-agent-content_sidebar_requester-info-card__requester-name_148gqp",
    "requester-organization": "ko-agent-content_sidebar_requester-info-card__requester-organization_148gqp",
    "organization-row": "ko-agent-content_sidebar_requester-info-card__organization-row_148gqp",
    "cancel-icon": "ko-agent-content_sidebar_requester-info-card__cancel-icon_148gqp",
    "avatar-container": "ko-agent-content_sidebar_requester-info-card__avatar-container_148gqp",
    "org-removal-loader": "ko-agent-content_sidebar_requester-info-card__org-removal-loader_148gqp",
    "org-removal-text": "ko-agent-content_sidebar_requester-info-card__org-removal-text_148gqp"
  };
});