define('frontend-cp/session/admin/apps/api/oauth-apps/new/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)(), {
    model: function model(params) {
      return this.store.createRecord('oauth-client', {
        scopes: []
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({ model: model });
      controller.initEdits();
    }
  });
});