define('frontend-cp/components/ko-user-avatar/online-indicator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    size: 'normal',
    user: null,
    showTooltip: true,

    _userOnline: false,

    i18n: service(),
    session: service(),

    tooltipLabel: computed('session.user', 'user', 'showTooltip', function () {
      var user = this.get('user');
      var loggedInUser = this.get('session.user');
      var i18n = this.get('i18n');

      if (user.get('id') === loggedInUser.get('id')) {
        return i18n.t('generic.you_are_online');
      }

      return i18n.t('generic.user_is_online', { name: user.get('fullName') });
    }),

    actions: {
      onPresenceChanged: function onPresenceChanged() {
        var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var id = this.get('user.id');
        var metas = data[id] && data[id].metas;
        var isOnline = !!(metas && metas.length);

        this.set('_userOnline', isOnline);
      }
    }
  });
});