define("frontend-cp/components/ko-text-editor/mode-selector/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "root": "ko-text-editor_mode-selector__root_z6va3w",
    "active": "ko-text-editor_mode-selector__active_z6va3w",
    "case-mode": "ko-text-editor_mode-selector__case-mode_z6va3w"
  };
});