define('frontend-cp/models/search-result', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('date'),
    resultData: _emberData.default.belongsTo('any', { polymorphic: true }),
    relevance: _emberData.default.attr('number'),
    resource: _emberData.default.attr('string'),
    snippet: _emberData.default.attr('string'),
    title: _emberData.default.attr('string')
  });
});