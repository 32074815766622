define('frontend-cp/components/ko-organization-content/component', ['exports', 'ember-keyboard-shortcuts/mixins/component', 'ember-concurrency', 'ember-diff-attrs', 'frontend-cp/components/ko-text-editor/component', 'frontend-cp/utils/bugsnag', 'frontend-cp/utils/is-internal-tag'], function (exports, _component, _emberConcurrency, _emberDiffAttrs, _component2, _bugsnag, _isInternalTag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var EmberObject = Ember.Object;
  var run = Ember.run;
  var $ = Ember.$;
  var capitalize = Ember.String.capitalize;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend(_component.default, {
    tagName: '',

    // Attributes
    filter: '',
    routeContext: '',
    postId: null,
    organization: null,
    canDelete: false,
    tabId: null,
    tabsComponent: null,
    tabsModel: null,
    redirectingToOrg: false,
    orgMembers: null,
    orgName: '',
    instantOrgTerm: '',
    instantOrgResults: null,
    newOrgController: null,
    timestamp: null,
    openInSameTab: function openInSameTab() {},

    closeTab: null,

    // State
    state: null,

    // Services
    i18n: service(),
    permissions: service(),
    customFieldsList: service('custom-fields/list'),
    plan: service(),
    store: service(),
    notification: service(),
    routing: service('-routing'),
    uploadService: service('fileUpload'),

    keyboardShortcuts: {
      'ctrl+alt+n': {
        action: 'focusEditor',
        global: false,
        preventDefault: true
      },
      n: {
        action: 'focusEditor',
        global: false
      },
      'mod+enter': {
        action: 'submit',
        global: true,
        preventDefault: true
      }
    },

    attachedPostFiles: computed.readOnly('state.attachedPostFiles'),
    uploadingFiles: computed.readOnly('uploadService.uploadFile.isRunning'),

    visibleTags: computed('state.editedTags.@each.name', function () {
      return this.get('state.editedTags').filter(function (tag) {
        return !(0, _isInternalTag.default)(tag);
      });
    }),

    // Lifecycle hooks
    didReceiveAttrs: (0, _emberDiffAttrs.default)('organization', function (changedAttrs) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      if (!changedAttrs || changedAttrs.organization) {
        this.set('orgMembers', null);
        if (this.get('organization.id')) {
          this.get('loadOrganizationMembers').perform().then(function (members) {
            _this.set('orgMembers', members);
          }).catch(function (e) {
            if (!(0, _emberConcurrency.didCancel)(e)) {
              throw e;
            }
          });
        }
      }
    }),

    // Methods
    redirectToOrg: function redirectToOrg(id, name) {
      var _this2 = this;

      var router = this.get('routing');
      this.set('redirectingToOrg', true);
      this.set('orgName', name);

      this.sendAction('openInSameTab');
      router.transitionTo('session.agent.organizations.organization', [id]).then(function () {
        _this2.set('redirectingToOrg', false);
      }).finally(function () {
        _this2.set('redirectingToOrg', false);
      });
    },


    // State attributes
    isSaving: computed.or('state.save.isRunning', 'state.postNote.isRunning', 'updateName.isRunning'),

    // CPs
    hasOrg: computed('organization.name', function () {
      return typeof this.get('organization.name') === 'string';
    }),

    hasOrganizationUpdatePermission: computed(function () {
      return this.get('permissions').has('organizations.update');
    }),

    isSharedOrganization: computed(function () {
      return this.get('plan').has('shared_organizations');
    }),

    canUpdateOrganization: computed('state.hasUpdateOrganizationPermission', 'isSaving', function () {
      return this.get('state.hasUpdateOrganizationPermission') && !this.get('isSaving');
    }),

    canPostOrganizationNote: computed('role', function () {
      return this.get('permissions').has('app.organization.post_private_note', this.get('organization'));
    }),

    caseAccessList: computed(function () {
      return [EmberObject.create({
        name: this.get('i18n').t('organization.infobar.cases.shared.true'),
        value: true
      }), EmberObject.create({
        name: this.get('i18n').t('organization.infobar.cases.shared.false'),
        value: false
      })];
    }),

    loadOrganizationMembers: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var results;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('store').adapterFor('organization').fetchMembers(this.organization.id);

            case 2:
              results = _context.sent;
              return _context.abrupt('return', results.data);

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    stateIsUnmodified: computed.not('state.isEdited'),
    submitDisabled: computed.or('isSaving', 'stateIsUnmodified', 'uploadingFiles'),

    organizationDates: computed('organization.createdAt', 'organization.updatedAt', function () {
      return [{
        title: this.get('i18n').t('users.metadata.created'),
        value: this.get('organization.createdAt'),
        type: 'created'
      }, {
        title: this.get('i18n').t('users.metadata.updated'),
        value: this.get('organization.updatedAt'),
        type: 'updated'
      }];
    }),

    updatedDate: computed('organizationDates.[]', function () {
      return this.get('organizationDates').findBy('type', 'updated');
    }),

    customFields: computed('organization.customFields', function () {
      return this.get('organization.customFields').mapBy('field').compact().sortBy('sortOrder');
    }),

    // Tasks
    submit: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var _getProperties, state, note;

      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!this.get('submitDisabled')) {
                _context2.next = 2;
                break;
              }

              return _context2.abrupt('return');

            case 2:
              _getProperties = this.getProperties('state'), state = _getProperties.state;
              _context2.prev = 3;
              _context2.next = 6;
              return state.get('submit').perform();

            case 6:
              _context2.next = 8;
              return this.get('timeline.fetchNewerAfterReply').perform(this.get('filter'));

            case 8:
              note = _context2.sent;

              note = note.filterBy('note.id').get('firstObject.note.content');
              if (note) {
                this.get('organization.viewNotes').addObject(note);
              }
              _context2.next = 17;
              break;

            case 13:
              _context2.prev = 13;
              _context2.t0 = _context2['catch'](3);

              if (!(_context2.t0.message !== 'The adapter rejected the commit because it was invalid')) {
                _context2.next = 17;
                break;
              }

              throw _context2.t0;

            case 17:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this, [[3, 13]]);
    })).drop(),

    createOrg: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(name) {
      var store;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              store = this.get('store');
              _context3.next = 3;
              return store.createRecord('organization', { name: name }).save();

            case 3:
              return _context3.abrupt('return', _context3.sent);

            case 4:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).drop(),

    updateName: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4(name, oldName) {
      var i18n, org;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              i18n = this.get('i18n');
              org = this.get('organization');

              org.set('name', name);
              _context4.prev = 3;
              _context4.next = 6;
              return org.save({ adapterOptions: { updateName: true } });

            case 6:
              this.get('notification').success(i18n.t('organization.name_update_success'));
              _context4.next = 13;
              break;

            case 9:
              _context4.prev = 9;
              _context4.t0 = _context4['catch'](3);

              org.set('name', oldName);
              throw _context4.t0;

            case 13:
              return _context4.abrupt('return', org);

            case 14:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this, [[3, 9]]);
    })).drop(),

    fetchNotes: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5() {
      var notes, context;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              _context5.prev = 0;
              _context5.next = 3;
              return this.get('store').query('note', {
                parent: this.get('organization'),
                limit: 999
              });

            case 3:
              notes = _context5.sent;

              this.set('organization.viewNotes', notes.toArray());
              _context5.next = 10;
              break;

            case 7:
              _context5.prev = 7;
              _context5.t0 = _context5['catch'](0);

              if (!Ember.testing && window.Bugsnag) {
                context = (0, _bugsnag.getMetaData)(null, getOwner(this));

                window.Bugsnag.notifyException(_context5.t0, 'Failed to fetch notes', context, 'info');
              }

            case 10:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this, [[0, 7]]);
    })).restartable(),

    // Actions
    actions: {
      dispatch: function dispatch(method) {
        var _get;

        for (var _len2 = arguments.length, rest = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          rest[_key2 - 1] = arguments[_key2];
        }

        (_get = this.get('state'))[method].apply(_get, _toConsumableArray(rest));
      },
      focusEditor: function focusEditor() {
        run.later(function () {
          $(_component2.EDITOR_SELECTOR).froalaEditor('events.focus');
        });
      },
      submit: function submit() {
        this.get('submit').perform();
      },
      setName: function setName(name) {
        var oldName = this.get('organization.name');
        if (name === oldName) {
          return;
        }
        this.get('updateName').perform(name, oldName);
        if (this.attrs.onTabNameUpdate) {
          this.attrs.onTabNameUpdate(name);
        }
      },
      setInstantOrg: function setInstantOrg(org) {
        var _this3 = this;

        if (typeof org === 'string') {
          org = org.trim();

          // Create Org flow

          var i18n = this.get('i18n');

          org = capitalize(org);

          var savePromise = this.get('createOrg').perform(org);
          savePromise.then(function (org) {
            _this3.get('notification').success(i18n.t('organization.created'));

            _this3.redirectToOrg(org.get('id'), org.get('name'));
          }).finally(function () {
            _this3.set('instantOrgTerm', '');
            _this3.set('instantOrgResults', null);
          });
        } else {
          this.set('instantOrgTerm', '');
          this.set('instantOrgResults', null);

          this.redirectToOrg(org.get('id'), org.get('name'));
        }
      },
      onAttachFiles: function onAttachFiles(files) {
        var _this4 = this;

        var service = this.get('uploadService');
        var attachedPostFiles = this.get('attachedPostFiles');
        var onUploadAttachmentStart = function onUploadAttachmentStart() {
          var _get2;

          return (_get2 = _this4.get('state')).addAttachment.apply(_get2, arguments);
        };
        var onUploadAttachment = function onUploadAttachment() {
          var _get3;

          return (_get3 = _this4.get('state')).updateAttachments.apply(_get3, arguments);
        };
        files.forEach(function (file) {
          return service.get('uploadFile').perform(file, attachedPostFiles, null, onUploadAttachmentStart, onUploadAttachment).catch(function () {/* Swallow this as this isn't an error we can handle */});
        });
      },
      resetProperties: function resetProperties() {
        this.get('state').resetSidebar();
      }
    }
  });
});