define('frontend-cp/components/ko-admin/sla/index/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var filterBy = Ember.computed.filterBy;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Attributes
    slas: [],
    onOpenSla: null,
    onAddSla: null,

    // Services
    i18n: service(),
    plan: service(),
    notification: service(),
    confirmation: service(),
    sorter: service(),

    // CPs
    enabledSlas: filterBy('slas', 'isEnabled', true),
    disabledSlas: filterBy('slas', 'isEnabled', false),

    sortedSlas: computed('enabledSlas.@each.executionOrder', function () {
      return this.get('enabledSlas').sortBy('executionOrder');
    }),

    reachedLimit: computed('slas', function () {
      return this.get('plan.limits.slas') <= this.get('slas.meta.total');
    }),

    // Actions
    actions: {
      edit: function edit(sla, e) {
        e.stopPropagation();
        this.attrs.onOpenSla(sla);
      },
      toggleStatus: function toggleStatus(sla, e) {
        var _this = this;

        e.stopPropagation();
        sla.toggleProperty('isEnabled');
        sla.save().then(function () {
          _this.get('notification').success(_this.get('i18n').t(sla.get('isEnabled') ? 'admin.sla.enabled.message' : 'admin.sla.disabled.message'));
        });
      },
      delete: function _delete(sla, e) {
        var _this2 = this;

        e.stopPropagation();
        e.preventDefault();
        return this.get('confirmation').confirm({
          intlConfirmationBody: 'generic.confirm.delete'
        }).then(function () {
          return sla.destroyRecord();
        }).then(function () {
          var msg = _this2.get('i18n').t('admin.sla.delete.success_message');
          _this2.get('notification').success(msg);
        });
      },
      reorder: function reorder(orderedSlas) {
        this.get('sorter').sort(orderedSlas);
      }
    }
  });
});