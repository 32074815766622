define('frontend-cp/helpers/ko-icon-for-definition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var helper = Ember.Helper.helper;
  exports.default = helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        definition = _ref2[0];

    var label = definition.get('label').toLowerCase().replace(/ /g, '');
    var inputType = definition.get('operators.firstObject.input.inputType');

    switch (label) {
      case 'name':
        return 'definitions/name';
      case 'email':
        return 'definitions/email';
      case 'event':
        return 'definitions/date';
      case 'os':
        return 'definitions/os';
      case 'browser':
      case 'browserversion':
        return 'definitions/browser';
      case 'city':
      case 'country':
      case 'region':
      case 'language':
        return 'definitions/position';
      case 'organization':
        return 'definitions/organization';
      case 'createdat':
        return 'definitions/created';
      case 'updatedat':
        return 'definitions/updated';
      case 'lastseen':
        return 'definitions/last-seen';
      case 'lastloggedin':
        return 'definitions/last-logged-in';
      case 'timezone':
        return 'definitions/timezone';
      case 'date':
        return 'definitions/date';
      case 'tags':
        return 'definitions/tag';
      case 'integer':
        return 'definitions/integer';
      case 'decimal':
        return 'definitions/decimal';
      case 'role':
        return 'definitions/select';
      case 'twitter':
        return 'definitions/twitter';
      case 'phone':
        return 'definitions/phone';
      case 'facebook':
        return 'definitions/facebook';
    }

    if (inputType === 'OPTIONS' || inputType === 'MULTIPLE' || inputType === 'AUTOCOMPLETE') {
      return 'definitions/select';
    } else if (inputType === 'DATE_ABSOLUTE' || inputType === 'DATE_RELATIVE') {
      return 'definitions/date';
    } else if (inputType === 'BOOLEAN') {
      return 'definitions/toggle';
    } else if (inputType === 'TAGS') {
      return 'definitions/tag';
    } else if (inputType === 'INTEGER') {
      return 'definitions/integer';
    } else if (inputType === 'FLOAT') {
      return 'definitions/decimal';
    } else if (inputType === 'STRING') {
      return 'definitions/text';
    }
  });
});