define('frontend-cp/session/agent/cases/new/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      transitionToNewlyCreatedCase: function transitionToNewlyCreatedCase() {
        var _target;

        (_target = this.target).send.apply(_target, ['transitionToNewlyCreatedCase'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});