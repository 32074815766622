define("frontend-cp/trial-data/insights/cases/channel", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "status": 200,
    "data": {
      "start_at": "2016-05-28T00:00:00+00:00",
      "end_at": "2016-06-27T23:59:59+00:00",
      "interval": "DAY",
      "interval_count": 31,
      "channel_series": [{
        "channel": "FACEBOOK",
        "series": {
          "name": "total_messages_received",
          "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 14, 0, 4, 0, 2, 1, 0, 0, 0, 0, 0, 0],
          "resource_type": "report_series"
        },
        "resource_type": "report_channel_statistics"
      }, {
        "channel": "MESSENGER",
        "series": {
          "name": "total_messages_received",
          "data": [14, 5, 918, 1738, 1305, 2517, 2089, 23, 11, 2011, 1855, 1658, 1967, 2080, 17, 8, 1920, 1545, 1996, 2579, 1627, 15, 10, 2174, 3346, 2664, 2574, 1687, 16, 62, 556],
          "resource_type": "report_series"
        },
        "resource_type": "report_channel_statistics"
      }, {
        "channel": "CALL",
        "series": {
          "name": "total_messages_received",
          "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          "resource_type": "report_series"
        },
        "resource_type": "report_channel_statistics"
      }, {
        "channel": "TWITTER",
        "series": {
          "name": "total_messages_received",
          "data": [8, 10, 6, 9, 12, 11, 8, 8, 3, 8, 12, 11, 27, 15, 5, 4, 16, 10, 2, 6, 9, 5, 4, 3, 10, 6, 9, 10, 1, 3, 2],
          "resource_type": "report_series"
        },
        "resource_type": "report_channel_statistics"
      }, {
        "channel": "MAIL",
        "series": {
          "name": "total_messages_received",
          "data": [68, 50, 327, 515, 479, 572, 441, 70, 40, 416, 538, 559, 467, 443, 69, 78, 430, 468, 429, 403, 391, 46, 47, 522, 596, 547, 590, 330, 55, 124, 216],
          "resource_type": "report_series"
        },
        "resource_type": "report_channel_statistics"
      }, {
        "channel": "HELPCENTER",
        "series": {
          "name": "total_messages_received",
          "data": [20, 11, 75, 100, 84, 117, 97, 21, 19, 103, 119, 109, 82, 81, 14, 17, 94, 90, 105, 132, 97, 12, 37, 264, 99, 79, 101, 70, 9, 12, 31],
          "resource_type": "report_series"
        },
        "resource_type": "report_channel_statistics"
      }],
      "resource_type": "channel"
    },
    "resources": [],
    "resource": "channel"
  };
});