define("frontend-cp/components/ko-case/sla-dropdown/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "sidebar": "ko-case_sla-dropdown__sidebar_b6mi9m",
    "title": "ko-case_sla-dropdown__title_b6mi9m",
    "table": "ko-case_sla-dropdown__table_b6mi9m"
  };
});