define("frontend-cp/components/ko-text-wrapper-for-button/show-more-button/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "toggle": "ko-text-wrapper-for-button_show-more-button__toggle_118ipa",
    "dots": "ko-text-wrapper-for-button_show-more-button__dots_118ipa",
    "toggle--expanded": "ko-text-wrapper-for-button_show-more-button__toggle--expanded_118ipa"
  };
});