define('frontend-cp/components/ko-admin/apps/manage/index/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  exports.default = Component.extend({

    appsService: service('apps'),

    // passed in
    apps: null,

    installedApps: reads('appsService.installedApps'),
    availableApps: computed('apps.[]', 'installedApps.[]', function () {
      // return [];
      var installedIDs = this.get('installedApps').mapBy('app.id');
      return this.get('apps').reject(function (app) {
        return installedIDs.includes(app.get('id'));
      });
    }),

    install: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(app) {
      var installation;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('appsService').install(app);

            case 2:
              installation = _context.sent;
              _context.next = 5;
              return this.get('onInstall')(installation);

            case 5:
              return _context.abrupt('return', _context.sent);

            case 6:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    uninstall: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(installation) {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('appsService').uninstall(installation);

            case 2:
              _context2.next = 4;
              return this.get('onUninstall')(installation);

            case 4:
              return _context2.abrupt('return', _context2.sent);

            case 5:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop()

  });
});