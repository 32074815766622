define('frontend-cp/instance-initializers/load-sounds', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    var soundAlerts = appInstance.lookup('service:sound-alerts');
    soundAlerts.initializeSounds();
  }

  exports.default = {
    name: 'load-sounds',
    initialize: initialize
  };
});