define('frontend-cp/processes/search-results', ['exports', 'frontend-cp/processes/process'], function (exports, _process) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var get = Ember.get;


  var SearchResultsProcess = _process.default.extend({
    type: 'search-results',

    restoreModel: function restoreModel(json) {
      return EmberObject.create({
        id: json.modelId
      });
    }
  });

  SearchResultsProcess.reopenClass({
    generatePid: function generatePid(model) {
      var id = get(model, 'id');
      return 'search-results:' + id;
    }
  });

  exports.default = SearchResultsProcess;
});