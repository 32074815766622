define('frontend-cp/components/ko-admin/apps/manage/details/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var bool = Ember.computed.bool;
  exports.default = Component.extend({

    appsService: service('apps'),

    app: null,

    onInstall: function onInstall() {},
    onUninstall: function onUninstall() {},

    installedApps: reads('appsService.installedApps'),

    installedApp: computed('installedApps.[]', 'app.id', function () {
      return this.get('installedApps').findBy('app.id', this.get('app.id'));
    }),

    isInstalled: bool('installedApp'),

    install: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var app, installation;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              app = this.get('app');
              _context.next = 3;
              return this.get('appsService').install(app);

            case 3:
              installation = _context.sent;
              _context.next = 6;
              return this.get('onInstall')(installation);

            case 6:
              return _context.abrupt('return', _context.sent);

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    uninstall: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var installation;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              installation = this.get('installedApp');
              _context2.next = 3;
              return this.get('appsService').uninstall(installation);

            case 3:
              _context2.next = 5;
              return this.get('onUninstall')(installation);

            case 5:
              return _context2.abrupt('return', _context2.sent);

            case 6:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop()

  });
});