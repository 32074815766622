define('frontend-cp/models/mailbox', ['exports', 'ember-data', 'frontend-cp/models/account'], function (exports, _emberData, _account) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var match = Ember.computed.match;
  var not = Ember.computed.not;
  exports.default = _account.default.extend({
    uuid: _emberData.default.attr('string'),
    service: _emberData.default.attr('string'),
    encryption: _emberData.default.attr('string'),
    address: _emberData.default.attr('string', { defaultValue: '' }),
    prefix: _emberData.default.attr('string'),
    smtpType: _emberData.default.attr('string'),
    host: _emberData.default.attr('string'),
    port: _emberData.default.attr('number'),
    username: _emberData.default.attr('string'),
    preserveMails: _emberData.default.attr('boolean'),
    brand: _emberData.default.belongsTo('brand', { async: false }),
    isSystem: _emberData.default.attr('boolean', { defaultValue: false }),
    isDefault: _emberData.default.attr('boolean'),
    isEnabled: _emberData.default.attr('boolean', { defaultValue: true }),
    isVerified: _emberData.default.attr('boolean', { defaultValue: false }),
    isDeleted: _emberData.default.attr('boolean', { defaultValue: false }),

    isKayakoDomain: match('address', /\.kayako\.com$/),
    isCustomDomain: not('isKayakoDomain')
  });
});