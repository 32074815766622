define('frontend-cp/components/ko-insights/loader/sla/component', ['exports', 'npm:lodash', 'ember-diff-attrs'], function (exports, _npmLodash, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var ObjectProxy = Ember.ObjectProxy;
  var PromiseProxyMixin = Ember.PromiseProxyMixin;
  var RSVP = Ember.RSVP;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  var isEmpty = Ember.isEmpty;
  exports.default = Component.extend({
    tagName: '',
    insights: service(),
    metricsQueryParams: null,
    slaId: null,
    queryTerm: '',

    request: null,

    slas: null,
    slaTarget: null,
    slaPerformance: null,
    slaItem: null,

    _request: function _request(slaId, queryParams) {
      var insights = this.get('insights');
      var targetEndpoint = insights.slaTargetEndpoint();
      var performanceEndpoint = insights.slaPerformanceEndpoint();
      var adapter = getOwner(this).lookup('adapter:application');

      var requestHash = {};

      requestHash.slaTarget = adapter.ajax(targetEndpoint, 'GET', _npmLodash.default.merge({ data: queryParams }, { data: { sla_id: slaId } }));
      requestHash.slaPerformance = adapter.ajax(performanceEndpoint, 'GET', _npmLodash.default.merge({ data: queryParams }, { data: { sla_id: slaId } }));

      var promise = ObjectProxy.extend(PromiseProxyMixin).create({ promise: RSVP.hash(requestHash) });

      this.set('request', promise);

      return promise;
    },
    _handlePromise: function _handlePromise(data, slaId) {
      if (this.isDestroying || this.isDestroyed) {
        return null;
      }

      var slaData = this.get('insights').restructureSlaPerformanceSeries(data.slaPerformance.data, data.slaPerformance.resources, this.get('slas'), slaId);

      this.set('slaTarget', data.slaTarget.data);
      this.set('slaPerformance', slaData.data);
      this.set('slaItem', slaData.slaItem);

      return data;
    },


    didReceiveAttrs: (0, _emberDiffAttrs.default)('metricsQueryParams', 'slaId', function (changedAttrs) {
      var _this = this;

      var newQueryParams = this.get('metricsQueryParams.data') || {};

      Reflect.deleteProperty(newQueryParams, 'include');

      if (!changedAttrs || !this.get('slas')) {
        this.get('insights').requestSLAs().then(function (slas) {
          if (_this.isDestroying || _this.isDestroyed) {
            return;
          }

          if (!isEmpty(slas)) {
            _this.set('slas', slas);
            var slaId = _this.get('slaId') || slas.get('firstObject.id');

            _this._request(slaId, newQueryParams).then(function (data) {
              return _this._handlePromise(data, slaId);
            });
          }
        });
      } else {
        if (!isEmpty(this.get('slas'))) {
          var slaId = this.get('slaId') || this.get('slas.firstObject.id');

          this._request(slaId, newQueryParams).then(function (data) {
            return _this._handlePromise(data, slaId);
          });
        }
      }
    }),

    filteredSlas: computed('slas', 'queryTerm', function () {
      var queryTerm = this.get('queryTerm').toLowerCase();
      var slas = this.get('slas') || [];

      return slas.filter(function (sla) {
        return sla && String(get(sla, 'title')).toLowerCase().match(queryTerm);
      });
    })
  });
});