define('frontend-cp/components/ko-merge-conversation-modal/suggestions/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    // Attrs
    requester: null,
    idsToExclude: null,
    onFetchSuggestions: function onFetchSuggestions() {},

    // Services
    store: service(),
    i18n: service(),

    // State
    requesterId: null,
    casesForUser: null,
    highlightedResult: null,

    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('idsToExclude').length) {
        this.set('idsToExclude', []);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var currentRequesterId = this.get('requester.id');
      var previousRequesterId = this.get('requesterId');
      if (currentRequesterId !== previousRequesterId) {
        this.get('fetchCasesForUser').perform();
        this.set('requesterId', currentRequesterId);
        this.get('onFetchSuggestions')(this.get('casesForUser'));
      }
    },


    // CP's
    headingLabel: computed('requester.fullName', function () {
      return this.get('i18n').t('cases.merge_conversation.modal.recent_conversations_from').toUpperCase() + ' ' + this.get('requester.fullName').toUpperCase();
    }),

    suggestedConversations: computed('casesForUser.[]', 'idsToExclude.[]', function (convo) {
      var _this = this;

      var casesForUser = this.get('casesForUser');
      if (casesForUser) {
        return casesForUser.map(function (convo) {
          if (!_this.get('idsToExclude').includes(convo.get('id'))) {
            return new EmberObject({
              id: convo.get('id'),
              title: convo.get('subject'),
              resource: 'case',
              resultData: {
                status: convo.get('status'),
                requester: convo.get('requester'),
                assignedAgent: convo.get('assignedAgent'),
                createdAt: convo.get('createdAt'),
                updatedAt: convo.get('updatedAt')
              }
            });
          }
        }).compact();
      }
    }),

    // Tasks
    fetchCasesForUser: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var requesterId, casesForUser;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              requesterId = this.get('requester.id');

              if (!requesterId) {
                _context.next = 7;
                break;
              }

              _context.next = 4;
              return this.get('store').query('user-case', { userId: requesterId, statuses: 'NEW, OPEN, PENDING, CUSTOM' });

            case 4:
              casesForUser = _context.sent;

              this.set('casesForUser', casesForUser);
              this.get('onFetchSuggestions')(casesForUser);

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    actions: {
      highlightResult: function highlightResult(highlightedResult) {
        this.set('highlightedResult', highlightedResult);
      }
    }
  });
});