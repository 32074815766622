define('frontend-cp/models/locale', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    locale: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    nativeName: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    nativeRegion: _emberData.default.attr('string'),
    script: _emberData.default.attr('string'),
    variant: _emberData.default.attr('string'),
    direction: _emberData.default.attr('string'),
    isPublic: _emberData.default.attr('boolean'),
    isLocalized: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    strings: _emberData.default.hasMany('locale-string', { async: true })
  });
});