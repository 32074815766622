define('frontend-cp/services/file-upload', ['exports', 'frontend-cp/lib/upload-file', 'ember-concurrency'], function (exports, _uploadFile, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var $ = Ember.$;
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var Service = Ember.Service;
  var run = Ember.run;

  var FileUploadError = function (_Error) {
    _inherits(FileUploadError, _Error);

    function FileUploadError() {
      _classCallCheck(this, FileUploadError);

      var _this = _possibleConstructorReturn(this, (FileUploadError.__proto__ || Object.getPrototypeOf(FileUploadError)).apply(this, arguments));

      _this.name = 'FileUploadError';
      return _this;
    }

    return FileUploadError;
  }(Error);

  exports.default = Service.extend({
    i18n: service(),
    notificationService: service('notification'),
    sessionService: service('session'),

    headers: computed('sessionService.sessionId', function () {
      var _get$getProperties = this.get('sessionService').getProperties('sessionId', 'csrfToken'),
          sessionId = _get$getProperties.sessionId,
          csrfToken = _get$getProperties.csrfToken;

      var headers = {};

      if (csrfToken) {
        headers['X-CSRF-Token'] = csrfToken;
      }

      if (sessionId) {
        headers['X-Session-ID'] = sessionId;
      }

      return headers;
    }),

    validateUploads: function validateUploads(uploadFile, uploads, channel) {
      if (!this.isUploadValid(uploadFile, uploads, channel)) {
        this.get('notificationService').add({
          type: 'error',
          title: this.get('i18n').t('generic.uploads.failed'),
          autodismiss: true,
          dismissable: false
        });
      }
    },


    uploadFile: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(file, uploads, channel, onUploadStart, onUploadEnd) {
      var _this2 = this;

      var formData, uploadFile;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              formData = new FormData();
              uploadFile = _uploadFile.default.create({ name: file.name, size: file.size });


              formData.append('name', file.name);
              formData.append('content', file);

              if (onUploadStart) {
                onUploadStart(uploadFile);
              }
              _context.next = 7;
              return new RSVP.Promise(function (resolve, reject) {
                $.ajax({
                  url: '/api/v1/core/file',
                  type: 'POST',
                  headers: _this2.get('headers'),
                  //Ajax events
                  success: function success(response) {
                    uploadFile.set('progress', 100);
                    uploadFile.set('status', 'SUCCESS');
                    uploadFile.set('contentUrl', response.data.content_url);
                    uploadFile.set('contentType', response.data.content_type);
                    uploadFile.set('size', response.data.size);
                    uploadFile.set('attachmentId', response.data.id);

                    _this2.validateUploads(uploadFile, uploads, channel);
                    if (onUploadEnd) {
                      onUploadEnd(uploadFile);
                    }
                    run(null, resolve, uploadFile);
                  },
                  error: function error(response) {
                    uploadFile.set('status', 'ERROR');

                    if (response.status === 413) {
                      uploadFile.set('error', 'TOO_LARGE');
                    } else if (response.status === 402 && response.responseJSON.errors[0].code === 'LICENSE_LIMIT_REACHED') {
                      uploadFile.set('error', 'TOO_LARGE');
                    } else if (response.responseJSON && response.responseJSON.notifications) {
                      uploadFile.set('error', response.responseJSON.notifications[0].message);
                    } else {
                      uploadFile.set('error', 'UNKNOWN');
                    }

                    _this2.validateUploads(uploadFile, uploads, channel);
                    if (onUploadEnd) {
                      onUploadEnd(uploadFile);
                    }
                    var error = new FileUploadError(uploadFile.get('error'));
                    run(null, reject, error);
                  },
                  xhr: function xhr(XMLHttpRequest) {
                    var xhr = new window.XMLHttpRequest();

                    //Upload progress
                    xhr.upload.addEventListener('progress', function (evt) {
                      uploadFile.set('status', 'PROGRESS');
                      uploadFile.set('progress', evt.loaded * 100 / evt.total);
                    }, false);

                    uploadFile.set('xhr', xhr);

                    return xhr;
                  },
                  // Form data
                  data: formData,
                  //Options to tell jQuery not to process data or worry about content-type.
                  cache: false,
                  contentType: false,
                  processData: false
                });
              });

            case 7:
              return _context.abrupt('return', _context.sent);

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    isUploadValid: function isUploadValid(upload, uploads, channel) {
      if (!channel) {
        return true;
      }

      switch (channel.get('channelType')) {
        case 'TWITTER':
          return this.validateTwitterUpload(upload, uploads);
        default:
          return true;
      }
    },
    validateTwitterUpload: function validateTwitterUpload(upload, uploads) {
      // User may attach up to 4 photos less than 3MB each, 1 animated GIF less than 3MB or 1 MP4 less than 15MB
      var isImageType = ['image/jpeg', 'image/png', 'image/webp'];
      var isAnimatedType = ['image/gif'];
      var isVideoType = ['video/mp4', 'video/quicktime'];
      var allowedTypes = [].concat(isImageType).concat(isAnimatedType).concat(isVideoType);
      var size3MB = 3000000;
      var size15MB = 15000000;

      var images = 0;
      var videos = 0;
      var animated = 0;

      uploads.forEach(function (uploaded) {
        if (isImageType.indexOf(uploaded.get('contentType')) > -1) {
          images++;
        } else if (isAnimatedType.indexOf(uploaded.get('contentType')) > -1) {
          animated++;
        } else if (isVideoType.indexOf(uploaded.get('contentType')) > -1) {
          videos++;
        }
      });

      var allowedCountsFailed = false;

      if (images > 4 || animated > 1 || videos > 1) {
        allowedCountsFailed = true;
      } else if (images > 0 && animated > 0) {
        allowedCountsFailed = true;
      } else if (images > 0 && videos > 0) {
        allowedCountsFailed = true;
      } else if (animated > 0 && videos > 0) {
        allowedCountsFailed = true;
      }

      if (allowedCountsFailed) {
        upload.setProperties({ status: 'ERROR', error: 'TWITTER_VALIDATION_FAILED' });
        return false;
      }

      if (allowedTypes.indexOf(upload.get('contentType')) === -1) {
        upload.setProperties({ status: 'ERROR', error: 'TWITTER_VALIDATION_FAILED' });
        return false;
      }

      // image size should be less than 3MB
      if (isImageType.indexOf(upload.get('contentType')) > -1 && upload.get('size') > size3MB) {
        upload.setProperties({ status: 'ERROR', error: 'TOO_LARGE' });
        return false;
      }

      // animated gif size should be less than 3MB
      if (isAnimatedType.indexOf(upload.get('contentType')) > -1 && upload.get('size') > size3MB) {
        upload.setProperties({ status: 'ERROR', error: 'TOO_LARGE' });
        return false;
      }

      // video MP4 size should be less than 15MB
      if (isVideoType.indexOf(upload.get('contentType')) > -1 && upload.get('size') > size15MB) {
        upload.setProperties({ status: 'ERROR', error: 'TOO_LARGE' });
        return false;
      }

      return true;
    }
  });
});