define("frontend-cp/components/ko-identities/create/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-identities_create__trigger_j232du",
    "dropdown": "ko-identities_create__dropdown_j232du _dropdown_1ilz5g _dropdown_jvqwfg"
  };
});