define("frontend-cp/components/ko-universal-search/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "overlay": "ko-universal-search__overlay_oc5t7r",
    "show-overlay": "ko-universal-search__show-overlay_oc5t7r",
    "containerAbsolute": "ko-universal-search__containerAbsolute_oc5t7r",
    "open-search": "ko-universal-search__open-search_oc5t7r",
    "containerInline": "ko-universal-search__containerInline_oc5t7r",
    "input-container": "ko-universal-search__input-container_oc5t7r",
    "input-container-inline": "ko-universal-search__input-container-inline_oc5t7r",
    "input-container-block": "ko-universal-search__input-container-block_oc5t7r",
    "shimmer": "ko-universal-search__shimmer_oc5t7r",
    "has-inline-input-border": "ko-universal-search__has-inline-input-border_oc5t7r",
    "input-focused": "ko-universal-search__input-focused_oc5t7r",
    "close-absolute": "ko-universal-search__close-absolute_oc5t7r",
    "close-text-absolute": "ko-universal-search__close-text-absolute_oc5t7r",
    "close-inline": "ko-universal-search__close-inline_oc5t7r",
    "icon-container": "ko-universal-search__icon-container_oc5t7r",
    "input-inline": "ko-universal-search__input-inline_oc5t7r",
    "input": "ko-universal-search__input_oc5t7r",
    "content-inline": "ko-universal-search__content-inline_oc5t7r",
    "content-absolute": "ko-universal-search__content-absolute_oc5t7r",
    "results": "ko-universal-search__results_oc5t7r",
    "results-list": "ko-universal-search__results-list_oc5t7r",
    "no-preview": "ko-universal-search__no-preview_oc5t7r",
    "list": "ko-universal-search__list_oc5t7r",
    "fadeOut": "ko-universal-search__fadeOut_oc5t7r",
    "text": "ko-universal-search__text_oc5t7r",
    "searching": "ko-universal-search__searching_oc5t7r",
    "no-results": "ko-universal-search__no-results_oc5t7r",
    "no-results-message": "ko-universal-search__no-results-message_oc5t7r",
    "no-results-message2": "ko-universal-search__no-results-message2_oc5t7r"
  };
});