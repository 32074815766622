define("frontend-cp/components/ko-admin/rateplans/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "cell": "ko-admin_rateplans_item__cell_1y732x",
    "selected": "ko-admin_rateplans_item__selected_1y732x",
    "disabled": "ko-admin_rateplans_item__disabled_1y732x",
    "plan-item": "ko-admin_rateplans_item__plan-item_1y732x",
    "plan-name": "ko-admin_rateplans_item__plan-name_1y732x",
    "plan-description": "ko-admin_rateplans_item__plan-description_1y732x",
    "plan-maths": "ko-admin_rateplans_item__plan-maths_1y732x",
    "label-helper": "ko-admin_rateplans_item__label-helper_1y732x"
  };
});