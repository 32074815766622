define("frontend-cp/components/ko-admin/overview/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "billing-address": "ko-admin_overview_index__billing-address_12np9f",
    "account-action": "ko-admin_overview_index__account-action_12np9f",
    "note": "ko-admin_overview_index__note_12np9f",
    "invoices-list": "ko-admin_overview_index__invoices-list_12np9f",
    "title": "ko-admin_overview_index__title_12np9f",
    "caption": "ko-admin_overview_index__caption_12np9f"
  };
});