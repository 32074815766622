define("frontend-cp/components/ko-admin/engagement-actions-builder/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "automation-action": "ko-admin_engagement-actions-builder__automation-action_1yqz74",
    "fadeIn": "ko-admin_engagement-actions-builder__fadeIn_1yqz74",
    "automation-action-form": "ko-admin_engagement-actions-builder__automation-action-form_1yqz74",
    "slots": "ko-admin_engagement-actions-builder__slots_1yqz74",
    "small-slot": "ko-admin_engagement-actions-builder__small-slot_1yqz74",
    "big-slot": "ko-admin_engagement-actions-builder__big-slot_1yqz74",
    "actions": "ko-admin_engagement-actions-builder__actions_1yqz74"
  };
});