define("frontend-cp/components/ko-admin/oauth-apps/edit/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "app-logo-container": "ko-admin_oauth-apps_edit__app-logo-container_18p4fu",
    "app-logo-upload": "ko-admin_oauth-apps_edit__app-logo-upload_18p4fu",
    "loader": "ko-admin_oauth-apps_edit__loader_18p4fu",
    "app-logo": "ko-admin_oauth-apps_edit__app-logo_18p4fu",
    "file-field": "ko-admin_oauth-apps_edit__file-field_18p4fu",
    "copy-button": "ko-admin_oauth-apps_edit__copy-button_18p4fu ko-button__default_ka3fcv ko-button__bordered_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "app-secret": "ko-admin_oauth-apps_edit__app-secret_18p4fu",
    "app-scopes-container": "ko-admin_oauth-apps_edit__app-scopes-container_18p4fu",
    "app-scope": "ko-admin_oauth-apps_edit__app-scope_18p4fu",
    "app-scope-access-level": "ko-admin_oauth-apps_edit__app-scope-access-level_18p4fu",
    "app-item-container": "ko-admin_oauth-apps_edit__app-item-container_18p4fu"
  };
});