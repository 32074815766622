define('frontend-cp/services/uuid', ['exports', 'npm:uuid/v4'], function (exports, _v) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    uuid: function uuid() {
      return (0, _v.default)();
    }
  });
});