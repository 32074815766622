define('frontend-cp/components/ko-loader/component', ['exports', 'ember-concurrency', 'frontend-cp/components/ko-loader/styles'], function (exports, _emberConcurrency, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    large: false,
    delay: 500,
    overlay: false,

    // Available sizes: S, M, L
    size: 'S',

    // HTML
    tagName: '',

    showSpinner: true,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('showSpinnerAfterDelay').perform();
    },


    showSpinnerAfterDelay: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(this.get('delay'));

            case 2:
              this.set('showSpinner', true);

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    sizeClass: computed('size', 'large', function () {
      if (this.get('large') || this.get('size') === 'L') {
        return _styles.default.containerLarge;
      } else if (this.get('size') === 'M') {
        return _styles.default.containerMedium;
      }

      return _styles.default.containerSmall;
    })
  });
});