define("frontend-cp/components/ko-case-ai-chat/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "chat-icon": "ko-case-ai-chat__chat-icon_1qq3d7",
    "open": "ko-case-ai-chat__open_1qq3d7",
    "chat-popup": "ko-case-ai-chat__chat-popup_1qq3d7",
    "show": "ko-case-ai-chat__show_1qq3d7",
    "chat-popup-header": "ko-case-ai-chat__chat-popup-header_1qq3d7",
    "close-chat": "ko-case-ai-chat__close-chat_1qq3d7",
    "chat-bubble": "ko-case-ai-chat__chat-bubble_1qq3d7",
    "user-message": "ko-case-ai-chat__user-message_1qq3d7",
    "ai-message": "ko-case-ai-chat__ai-message_1qq3d7",
    "chat-output": "ko-case-ai-chat__chat-output_1qq3d7",
    "input-container": "ko-case-ai-chat__input-container_1qq3d7",
    "input-row": "ko-case-ai-chat__input-row_1qq3d7",
    "chat-input": "ko-case-ai-chat__chat-input_1qq3d7",
    "send-button": "ko-case-ai-chat__send-button_1qq3d7",
    "active": "ko-case-ai-chat__active_1qq3d7",
    "clear-button": "ko-case-ai-chat__clear-button_1qq3d7",
    "loading-indicator": "ko-case-ai-chat__loading-indicator_1qq3d7",
    "typing-dots": "ko-case-ai-chat__typing-dots_1qq3d7",
    "bounce": "ko-case-ai-chat__bounce_1qq3d7",
    "trial-position": "ko-case-ai-chat__trial-position_1qq3d7"
  };
});