define("frontend-cp/components/ko-notification-center-new/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "wrapper": "ko-notification-center-new__wrapper_1vo4gv",
    "bell": "ko-notification-center-new__bell_1vo4gv",
    "is-active": "ko-notification-center-new__is-active_1vo4gv",
    "unread-mark": "ko-notification-center-new__unread-mark_1vo4gv",
    "scale-up-dot": "ko-notification-center-new__scale-up-dot_1vo4gv",
    "header": "ko-notification-center-new__header_1vo4gv",
    "content": "ko-notification-center-new__content_1vo4gv",
    "title": "ko-notification-center-new__title_1vo4gv",
    "read-all-action": "ko-notification-center-new__read-all-action_1vo4gv",
    "activated": "ko-notification-center-new__activated_1vo4gv",
    "pop-icon": "ko-notification-center-new__pop-icon_1vo4gv",
    "list": "ko-notification-center-new__list_1vo4gv",
    "loader": "ko-notification-center-new__loader_1vo4gv",
    "load-more": "ko-notification-center-new__load-more_1vo4gv",
    "no-more": "ko-notification-center-new__no-more_1vo4gv",
    "preferences-icon": "ko-notification-center-new__preferences-icon_1vo4gv"
  };
});