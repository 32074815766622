define("frontend-cp/components/ko-time-tracking/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "time-tracking": "ko-time-tracking__time-tracking_8qya66",
    "pill": "ko-time-tracking__pill_8qya66",
    "active": "ko-time-tracking__active_8qya66",
    "has-worked-time": "ko-time-tracking__has-worked-time_8qya66",
    "pill__icon": "ko-time-tracking__pill__icon_8qya66",
    "has-active-timer": "ko-time-tracking__has-active-timer_8qya66",
    "pill__time": "ko-time-tracking__pill__time_8qya66",
    "timer": "ko-time-tracking__timer_8qya66",
    "worked-time": "ko-time-tracking__worked-time_8qya66",
    "dropdown": "ko-time-tracking__dropdown_8qya66",
    "title": "ko-time-tracking__title_8qya66",
    "time-entry": "ko-time-tracking__time-entry_8qya66",
    "is-editing": "ko-time-tracking__is-editing_8qya66",
    "is-disabled": "ko-time-tracking__is-disabled_8qya66",
    "button-group": "ko-time-tracking__button-group_8qya66",
    "time-input": "ko-time-tracking__time-input_8qya66",
    "input-labels": "ko-time-tracking__input-labels_8qya66",
    "inputs": "ko-time-tracking__inputs_8qya66",
    "is-updating": "ko-time-tracking__is-updating_8qya66",
    "input": "ko-time-tracking__input_8qya66",
    "play-button": "ko-time-tracking__play-button_8qya66",
    "checkbox": "ko-time-tracking__checkbox_8qya66",
    "cancel": "ko-time-tracking__cancel_8qya66",
    "stat": "ko-time-tracking__stat_8qya66",
    "label": "ko-time-tracking__label_8qya66",
    "list": "ko-time-tracking__list_8qya66",
    "not-editable": "ko-time-tracking__not-editable_8qya66",
    "list-item": "ko-time-tracking__list-item_8qya66",
    "avatar": "ko-time-tracking__avatar_8qya66",
    "flex": "ko-time-tracking__flex_8qya66",
    "inline-icon": "ko-time-tracking__inline-icon_8qya66",
    "total": "ko-time-tracking__total_8qya66",
    "total-worked": "ko-time-tracking__total-worked_8qya66",
    "total-billed": "ko-time-tracking__total-billed_8qya66"
  };
});