define('frontend-cp/components/ko-identities/component', ['exports', 'frontend-cp/lib/copy-to-clipboard'], function (exports, _copyToClipboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var dasherize = Ember.String.dasherize;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    canAddNewIdentity: true,
    primaryOnly: false,

    // Services
    store: service(),
    i18n: service(),
    notification: service('error-handler/notification-strategy'),
    notificationService: service('notification'),
    confirmation: service(),

    // CPs
    emailIdentities: computed('parent.emails.@each.isPrimary', function () {
      return this.sortIdentitiesByPrimary('emails');
    }),
    twitterIdentities: computed('parent.twitter.@each.isPrimary', function () {
      return this.sortIdentitiesByPrimary('twitter');
    }),
    phoneIdentities: computed('parent.phones.@each.isPrimary', function () {
      return this.sortIdentitiesByPrimary('phones');
    }),
    facebookIdentities: computed('parent.facebook.@each.isPrimary', function () {
      return this.sortIdentitiesByPrimary('facebook');
    }),

    sortIdentitiesByPrimary: function sortIdentitiesByPrimary(identity) {
      if (this.get('parent.' + identity + '.length')) {
        var identities = this.get('parent.' + identity).filterBy('isNew', false);
        if (!this.get('primaryOnly')) {
          return identities.sortBy('isPrimary').reverse();
        }
        return identities.filterBy('isPrimary');
      }
    },


    // Actions
    actions: {
      copyToClipboard: function copyToClipboard(text) {
        (0, _copyToClipboard.default)(text);
      },
      makePrimaryIdentity: function makePrimaryIdentity(identity) {
        var _this = this;

        var identities = void 0;
        if (identity.constructor.modelName === 'identity-email') {
          identities = this.get('parent.emails');
        } else if (identity.constructor.modelName === 'identity-twitter') {
          identities = this.get('parent.twitter');
        } else if (identity.constructor.modelName === 'identity-phone') {
          identities = this.get('parent.phones');
        } else if (identity.constructor.modelName === 'identity-facebook') {
          identities = this.get('parent.facebook');
        }

        identity.set('isPrimary', true);
        identity.save().then(function (identity) {
          return _this._handleMarkAsPrimaryResponse(identities, identity);
        });
      },
      validateIdentity: function validateIdentity(identity) {
        if (identity.constructor.modelName === 'identity-email') {
          var adapter = getOwner(this).lookup('adapter:application');
          var url = adapter.namespace + '/identities/emails/' + identity.get('id') + '/send_verification_email';
          adapter.ajax(url, 'PUT');
        }
      },
      markEmailAsValidated: function markEmailAsValidated(identity) {
        var _this2 = this;

        if (identity.constructor.modelName === 'identity-email') {
          identity.set('isValidated', true);
          identity.save().then(function () {
            return _this2.get('notificationService').success(_this2.get('i18n').t('generic.identities.mark_email_as_validated.success_message', { email: identity.get('email') }));
          });
        }
      },


      // sendValidationEmail(identity) {
      //   const adapter = getOwner(this).lookup('adapter:application');
      //   adapter.ajax(`${adapter.namespace}/identities/emails/${identity.get('id')}/send_validation_email`, 'POST');
      // },

      removeIdentity: function removeIdentity(identity) {
        var _this3 = this;

        var promise = void 0;
        if (identity.get('isNew')) {
          promise = RSVP.resolve();
        } else {
          promise = this.get('confirmation').confirm({ intlConfirmationBody: 'generic.confirm.delete' });
        }
        promise.then(function () {
          return identity.destroyRecord();
        }).then(function () {
          _this3.get('notificationService').success(_this3.get('i18n').t('generic.identities.removed.success_message'));
        });
      },
      addEmail: function addEmail() {
        this.set('newIdentity', this.get('store').createRecord('identity-email'));
      },
      addTwitter: function addTwitter() {
        this.set('newIdentity', this.get('store').createRecord('identity-twitter'));
      },
      addPhone: function addPhone() {
        this.set('newIdentity', this.get('store').createRecord('identity-phone'));
      },
      saveIdentity: function saveIdentity(identity) {
        var _this4 = this;

        identity.set(this.get('parent').constructor.modelName, this.get('parent'));
        return identity.save().then(function () {
          _this4.get('notificationService').success(_this4.get('i18n').t('generic.identities.added.success_message'));
          return _this4.set('newIdentity', null);
        }, function (e) {
          identity.set('parent', null);
          throw e;
        });
      },
      removeNewIdentity: function removeNewIdentity() {
        this.set('newIdentity', null);
      }
    },

    // Marking an identity as primary means that all other identities of the same type for the same
    // user have to me marked as non primary.
    //
    // Presumably, the backend already takes care of that, but the payload only returns the data of
    // the identity being modified, so the changes in other identities are implicit.
    //
    // Due to this, we need to push to the store the updates in the other identitities. That way
    // those identies are modified without making them as dirty for ember-data.
    //
    _handleMarkAsPrimaryResponse: function _handleMarkAsPrimaryResponse(identities, updatedIdentity) {
      var store = this.get('store');

      identities.forEach(function (identity) {
        if (updatedIdentity !== identity) {
          var nonPrimaryPayload = {
            status: 200,
            data: {
              id: identity.get('id'),
              type: dasherize(updatedIdentity.constructor.modelName),
              attributes: {
                isPrimary: false
              }
            }
          };

          store.push(nonPrimaryPayload);
        }
      });
    }
  });
});