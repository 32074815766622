define('frontend-cp/components/ko-admin/apps/webhooks/form/component', ['exports', 'frontend-cp/services/virtual-model', 'ember-diff-attrs'], function (exports, _virtualModel, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var schema = (0, _virtualModel.model)('token', {
    id: (0, _virtualModel.attr)(),
    label: (0, _virtualModel.attr)(),
    description: (0, _virtualModel.attr)(),
    isEnabled: (0, _virtualModel.attr)(),
    createdAt: (0, _virtualModel.attr)(),
    updatedAt: (0, _virtualModel.attr)()
  });
  exports.default = Component.extend({
    // Attributes
    webhook: null,
    registerAs: function registerAs() {},
    didSave: function didSave() {},
    cancel: function cancel() {},

    // Services
    virtualModel: service(),
    i18n: service(),
    notification: service(),
    confirmation: service(),

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.get('registerAs')(this);
    },


    didReceiveAttrs: (0, _emberDiffAttrs.default)('webhook', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);
      if (!changedAttrs || changedAttrs.webhook) {
        this.initEdits();
      }
    }),

    // CPs
    canBeDeleted: computed.not('webhook.isNew'),

    // Actions
    actions: {
      save: function save() {
        var _this = this;

        var webhook = this.get('webhook');
        var editedWebhook = this.get('editedWebhook');

        return this.get('virtualModel').save(webhook, editedWebhook, schema).then(function () {
          _this.set('editedWebhook', _this.get('virtualModel').makeSnapshot(_this.get('webhook'), schema));
        }).then(this.get('didSave'));
      },
      deleteWebhook: function deleteWebhook() {
        var _this2 = this;

        return this.get('confirmation').confirm({
          intlConfirmationBody: 'admin.apps.webhooks.labels.delete_confirmation',
          intlConfirmationHeader: 'admin.apps.webhooks.labels.confirm_delete',
          intlConfirmLabel: 'generic.confirm.delete_button'
        }).then(function () {
          return _this2.get('webhook').destroyRecord().then(function () {
            return _this2.attrs.didSave();
          });
        });
      },
      onCopiedToClipboard: function onCopiedToClipboard() {
        var successText = this.get('i18n').t('generic.copied_to_clipboard');
        this.get('notification').success(successText);
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedWebhook', this.get('virtualModel').makeSnapshot(this.get('webhook'), schema));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('webhook'), this.get('editedWebhook'), schema);
    }
  });
});