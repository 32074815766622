define('frontend-cp/components/ko-case/macro-selector/component', ['exports', 'ember-service/inject', 'frontend-cp/components/ko-select/drill-down/component', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _inject, _component, _component2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend(_component2.default, {
    launchDarkly: (0, _inject.default)(),

    metrics: service(),
    tagName: '',

    // Attributes
    onMacroSelect: null,
    isDisabled: false,
    macros: [],

    keyboardShortcuts: {
      'ctrl+alt+m': {
        action: 'open',
        global: false,
        preventDefault: true
      },
      m: {
        action: 'open',
        global: false
      }
    },

    // HTML
    classNames: ['ko-case_macro-selector'],

    // build a value list for the option drilldown
    macroValueList: computed('macros.[]', function () {
      return (0, _component.buildTreeFromList)(this.get('macros'), function (item) {
        return {
          id: get(item, 'id'),
          value: get(item, 'title'),
          object: item
        };
      });
    }),

    actions: {
      onMacroSelect: function onMacroSelect(_ref) {
        var launchDarkly = this.get('launchDarkly');
        var object = _ref.object;

        this.attrs.onMacroSelect(object);

        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'macro_applied',
            object: object.get('id')
          });
        }
      },
      open: function open() {
        this.get('drillDownComponent').send('open');
      }
    }
  });
});