define('frontend-cp/components/ko-timeline-2/list/post/component', ['exports', 'frontend-cp/lib/facade/activity'], function (exports, _activity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var reads = Ember.computed.reads;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Attrs
    tagName: '',

    activity: reads('post.original'),
    post: null,
    posts: null,
    isLastReadPost: false,
    canResend: true,
    isItemMenuOpen: false,
    onResend: function onResend() {},
    onItemMenuOpen: function onItemMenuOpen() {},

    // CPs
    isMessageOrNote: computed('post.original.postType', function () {
      var postType = this.get('post.original.postType');
      return ['message', 'note'].includes(postType);
    }),

    activityFacade: computed('activity', function () {
      if (this.get('activity.isActivity')) {
        return new _activity.default({ activity: this.get('activity') });
      }
    })
  });
});