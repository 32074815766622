define("frontend-cp/components/ko-insights/sla-resolution-target/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "targets": "ko-insights_sla-resolution-target__targets_kmlsya",
    "target": "ko-insights_sla-resolution-target__target_kmlsya",
    "title": "ko-insights_sla-resolution-target__title_kmlsya",
    "metrics": "ko-insights_sla-resolution-target__metrics_kmlsya",
    "charts": "ko-insights_sla-resolution-target__charts_kmlsya",
    "chart": "ko-insights_sla-resolution-target__chart_kmlsya",
    "filters": "ko-insights_sla-resolution-target__filters_kmlsya",
    "filtersControls": "ko-insights_sla-resolution-target__filtersControls_kmlsya"
  };
});