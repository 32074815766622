define('frontend-cp/adapters/unpanginate-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    ajaxOptions: function ajaxOptions(url, type) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (type === 'GET') {
        options.data = options.data || {};
        if (!options.data.limit) {
          options.data.limit = '10000';
        }
      }

      return this._super(url, type, options);
    }
  });
});