define("frontend-cp/components/ko-confirm-modal/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "main": "ko-confirm-modal__main_4lnue5",
    "header": "ko-confirm-modal__header_4lnue5",
    "body": "ko-confirm-modal__body_4lnue5",
    "button": "ko-confirm-modal__button_4lnue5"
  };
});