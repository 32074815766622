define("frontend-cp/components/ko-timeline-2/list/activity/standard/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "activity": "ko-timeline-2_list_activity_standard__activity_1hqxr1",
    "activity-line-major": "ko-timeline-2_list_activity_standard__activity-line-major_1hqxr1",
    "has-token-avatar": "ko-timeline-2_list_activity_standard__has-token-avatar_1hqxr1",
    "activity-line-minor": "ko-timeline-2_list_activity_standard__activity-line-minor_1hqxr1",
    "activity-text": "ko-timeline-2_list_activity_standard__activity-text_1hqxr1",
    "activity-time": "ko-timeline-2_list_activity_standard__activity-time_1hqxr1",
    "box": "ko-timeline-2_list_activity_standard__box_1hqxr1",
    "expand": "ko-timeline-2_list_activity_standard__expand_1hqxr1",
    "suggestions": "ko-timeline-2_list_activity_standard__suggestions_1hqxr1",
    "suggestions-wrapper": "ko-timeline-2_list_activity_standard__suggestions-wrapper_1hqxr1",
    "suggestion-index": "ko-timeline-2_list_activity_standard__suggestion-index_1hqxr1",
    "suggestion-text": "ko-timeline-2_list_activity_standard__suggestion-text_1hqxr1",
    "properties": "ko-timeline-2_list_activity_standard__properties_1hqxr1",
    "property": "ko-timeline-2_list_activity_standard__property_1hqxr1",
    "property-name": "ko-timeline-2_list_activity_standard__property-name_1hqxr1",
    "property-value": "ko-timeline-2_list_activity_standard__property-value_1hqxr1",
    "small-dot-spacer": "ko-timeline-2_list_activity_standard__small-dot-spacer_1hqxr1",
    "comment-block-container": "ko-timeline-2_list_activity_standard__comment-block-container_1hqxr1",
    "comment-container": "ko-timeline-2_list_activity_standard__comment-container_1hqxr1",
    "helpcenter-comment-text": "ko-timeline-2_list_activity_standard__helpcenter-comment-text_1hqxr1",
    "helpcenter-comment-text-open-quote": "ko-timeline-2_list_activity_standard__helpcenter-comment-text-open-quote_1hqxr1"
  };
});