define("frontend-cp/session/agent/cases/index/suspended-messages/show/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "button": "session_agent_cases_index_suspended-messages_show__button_1iwqe3",
    "table": "session_agent_cases_index_suspended-messages_show__table_1iwqe3",
    "table-header": "session_agent_cases_index_suspended-messages_show__table-header_1iwqe3",
    "table-cell": "session_agent_cases_index_suspended-messages_show__table-cell_1iwqe3",
    "message-text": "session_agent_cases_index_suspended-messages_show__message-text_1iwqe3"
  };
});