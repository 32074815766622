define('frontend-cp/models/case', ['exports', 'ember-data', 'ember-data-model-fragments', 'frontend-cp/models/has-posts'], function (exports, _emberData, _emberDataModelFragments, _hasPosts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var bool = Ember.computed.bool;
  var reads = Ember.computed.reads;
  var or = Ember.computed.or;
  var not = Ember.computed.not;
  exports.default = _hasPosts.default.extend({
    assignedTeam: _emberData.default.belongsTo('team', { async: true }),
    assignedAgent: _emberData.default.belongsTo('user', { async: true, inverse: null }),
    subject: _emberData.default.attr('string', { defaultValue: '' }),
    portal: _emberData.default.attr('string'),
    sourceChannel: _emberData.default.belongsTo('channel', { async: true }),
    lastPublicChannel: _emberData.default.belongsTo('channel', { async: true }),
    requester: _emberData.default.belongsTo('user', { async: true, inverse: null }),
    creator: _emberData.default.belongsTo('user', { async: true, inverse: null }),
    identity: _emberData.default.belongsTo('identity', { polymorphic: true, async: true }),
    slaVersion: _emberData.default.belongsTo('sla-version', { async: true }),
    slaMetrics: _emberData.default.hasMany('sla-metric', { async: true }),
    lastAssignedBy: _emberData.default.belongsTo('user', { async: true }),
    brand: _emberData.default.belongsTo('brand', { async: true }),
    status: _emberData.default.belongsTo('case-status', { async: true }),
    priority: _emberData.default.belongsTo('case-priority', { async: true }),
    caseType: _emberData.default.belongsTo('case-type', { async: true }),
    form: _emberData.default.belongsTo('case-form', { async: true }),
    readMarker: _emberData.default.belongsTo('read-marker', { async: true }),
    customFields: _emberDataModelFragments.default.fragmentArray('case-field-value', { defaultValue: [] }),
    // metadata // TODO nested json
    lastReplier: _emberData.default.belongsTo('user', { async: true, inverse: null }),
    lastUpdatedBy: _emberData.default.belongsTo('user', { async: true, inverse: null }),
    lastReplierIdentity: _emberData.default.belongsTo('identity', { async: true }),
    creationMode: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    hasNotes: _emberData.default.attr('boolean'),
    pinnedNotesCount: _emberData.default.attr('number'),
    viewNotes: computed(function () {
      return [];
    }),
    hasAttachments: _emberData.default.attr('boolean'),
    rating: _emberData.default.attr('string'),
    ratingStatus: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    lastAgentActivityAt: _emberData.default.attr('date'),
    lastReplyByAgentAt: _emberData.default.attr('date'),
    lastRepliedAt: _emberData.default.attr('date'),
    lastReplyByRequesterAt: _emberData.default.attr('date'),
    lastCustomerActivityAt: _emberData.default.attr('date'),
    lastCompletedAt: _emberData.default.attr('date'),
    latestAssigneeUpdate: _emberData.default.attr('date'),
    realtimeChannel: _emberData.default.attr('string'),
    attachmentFileIds: _emberData.default.attr('string'),
    lastMessagePreview: _emberData.default.attr('string'),
    lastPostStatus: _emberData.default.attr('string'),

    // Children fields
    messages: _emberData.default.hasMany('case-message', { async: true }),
    posts: _emberData.default.hasMany('post', { async: true }),
    activities: _emberData.default.hasMany('activity', { async: true }),
    replyChannels: _emberData.default.hasMany('channel', { async: true }),
    reply: _emberData.default.hasMany('case-reply', { async: true }),
    channelOptions: _emberDataModelFragments.default.fragment('case-reply-options'),
    tags: _emberData.default.hasMany('tag', { async: true }),

    // Parent field
    view: _emberData.default.belongsTo('view', { async: true }),

    // Creation Fields
    contents: _emberData.default.attr('string'),
    channel: _emberData.default.attr('string'),
    channelId: _emberData.default.attr('string'),

    // used in the creation steps
    creationTimestamp: null,

    // Indicates whether all fields of a case have been loaded, also see adapter/serializer.
    _isFullyLoaded: _emberData.default.attr('boolean', { defaultValue: false }),

    resourceType: 'case',

    // CPs
    hasPinnedNotes: bool('pinnedNotesCount'),

    // models/user needs recentCases.@each.status.statusType, but can't go 2 levels deep after an @each
    // so alias statusType here
    statusType: reads('status.statusType'),

    canReload: not('cannotReload'),
    cannotReload: or('isLoading', 'isReloading', 'isSaving')
  });
});