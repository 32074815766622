define("frontend-cp/components/ko-instant-entity/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "instant-entity-component": "ko-instant-entity__instant-entity-component_r95hms",
    "search-add-user-input": "ko-instant-entity__search-add-user-input_r95hms",
    "cancellable": "ko-instant-entity__cancellable_r95hms",
    "invalid": "ko-instant-entity__invalid_r95hms",
    "cancel-icon": "ko-instant-entity__cancel-icon_r95hms",
    "instant-entity-input-container": "ko-instant-entity__instant-entity-input-container_r95hms",
    "loader-container": "ko-instant-entity__loader-container_r95hms",
    "instant-entity-results": "ko-instant-entity__instant-entity-results_r95hms",
    "instant-entity-list": "ko-instant-entity__instant-entity-list_r95hms",
    "has-results": "ko-instant-entity__has-results_r95hms",
    "disable-cursor-overlay": "ko-instant-entity__disable-cursor-overlay_r95hms",
    "show": "ko-instant-entity__show_r95hms"
  };
});