define("frontend-cp/components/ko-timeline-2/list/item/menu/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-timeline-2_list_item_menu_item__item_w9k1x8",
    "disabled": "ko-timeline-2_list_item_menu_item__disabled_w9k1x8"
  };
});