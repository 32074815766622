define("frontend-cp/components/ko-agent-dropdown/create-case/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "dropdownUser": "ko-agent-dropdown_create-case__dropdownUser_1serve",
    "userInfo": "ko-agent-dropdown_create-case__userInfo_1serve",
    "dropdownUserImage": "ko-agent-dropdown_create-case__dropdownUserImage_1serve",
    "caption": "ko-agent-dropdown_create-case__caption_1serve",
    "is-active": "ko-agent-dropdown_create-case__is-active_1serve",
    "buttons": "ko-agent-dropdown_create-case__buttons_1serve"
  };
});