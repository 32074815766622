define('frontend-cp/services/window', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var Evented = Ember.Evented;
  exports.default = Service.extend(Evented, {
    visible: null,

    currentPath: function currentPath() {
      return window.location.pathname;
    },
    open: function open(url) {
      var windowName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var windowFeatures = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      windowFeatures = Object.keys(windowFeatures).map(function (key) {
        return key + '=' + windowFeatures[key];
      }).join(',');

      window.open(url, windowName, windowFeatures);
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('visible', document.hasFocus());

      if (!Ember.testing) {
        this._bindWindowEvents();
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this._unbindWindowEvents();
    },
    _bindWindowEvents: function _bindWindowEvents() {
      var _this = this;

      this._onBlur = function () {
        _this.set('visible', false);
        _this.trigger('blur');
      };

      this._onFocus = function () {
        _this.set('visible', true);
        _this.trigger('focus');
      };

      window.addEventListener('blur', this._onBlur, false);
      window.addEventListener('focus', this._onFocus, false);
    },
    _unbindWindowEvents: function _unbindWindowEvents() {
      window.removeEventListener('blur', this._onBlur, false);
      window.removeEventListener('focus', this._onFocus, false);

      this.setProperties({
        _onBlur: null,
        _onFocus: null
      });
    }
  });
});