define('frontend-cp/models/user-note', ['exports', 'ember-data', 'frontend-cp/models/postable'], function (exports, _emberData, _postable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  exports.default = _postable.default.extend({
    uuid: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    bodyText: _emberData.default.attr('string'),
    bodyHtml: _emberData.default.attr('string'),
    body: or('bodyHtml', 'bodyText'),
    contents: _emberData.default.attr('string'),
    attachmentFileIds: _emberData.default.attr('string'),

    isPinned: _emberData.default.attr('boolean'),
    color: _emberData.default.attr('string', { defaultValue: 'YELLOW' }), // TODO enum YELLOW, RED, GREEN, BLUE, ORANGE, PURPLE
    creator: _emberData.default.belongsTo('user', { async: false }),
    identity: _emberData.default.belongsTo('identity', { async: false }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    user: _emberData.default.belongsTo('user', { async: false, inverse: 'notes' }),
    note: _emberData.default.belongsTo('note', { async: false }),
    post: _emberData.default.belongsTo('post', { async: true }),

    postType: 'note'
  });
});