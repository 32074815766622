define('frontend-cp/session/admin/security/settings/controller', ['exports', 'frontend-cp/session/admin/security/settings/settings', 'ember-concurrency', 'frontend-cp/lib/comma-separate-list'], function (exports, _settings, _emberConcurrency, _commaSeparateList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    // Attributes
    settings: null,
    editedSettings: null,

    // Services
    i18n: service(),
    notification: service(),
    settingsService: service('settings'),
    virtualModel: service(),
    confirmation: service(),

    // CPs
    hasChanges: readOnly('editedSettings.isEdited'),

    schema: computed(function () {
      return this.get('settingsService').generateSchema(_settings.default);
    }),

    // Tasks
    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              _context.next = 3;
              return this.get('virtualModel').save(this.get('settings'), this.get('editedSettings'), this.get('schema'));

            case 3:

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('generic.changes_saved'),
                autodismiss: true
              });
              this.initEdits();
              _context.next = 9;
              break;

            case 7:
              _context.prev = 7;
              _context.t0 = _context['catch'](0);

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[0, 7]]);
    })).drop(),

    cancel: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!this.get('isEdited')) {
                _context2.next = 4;
                break;
              }

              _context2.next = 3;
              return this.get('confirmation').confirm({
                intlConfirmationHeader: 'generic.confirm.lose_changes_header',
                intlConfirmationBody: 'generic.confirm.lose_changes',
                intlConfirmLabel: 'generic.confirm.lose_changes_button'
              });

            case 3:
              this.initEdits();

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    // Actions
    actions: {
      editBooleanSetting: function editBooleanSetting(settingName, value) {
        this.set('editedSettings.' + settingName + '.value', value ? '1' : '0');
      },
      editTextSetting: function editTextSetting(settingName, e) {
        this.set('editedSettings.' + settingName + '.value', e.target.value);
      },
      setCommaSeparatedField: function setCommaSeparatedField(settingName, e) {
        var value = e.target.value;
        this.set(settingName, value);
        this.set('editedSettings.' + settingName + '.value', (0, _commaSeparateList.default)(value));
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedSettings', this.get('settingsService').initEdits(this.get('settings'), this.get('schema')));
      var whitelist = this.get('editedSettings.users_email_whitelist.value');
      var blacklist = this.get('editedSettings.users_email_blacklist.value');
      // Adding newline at the end so that when the field is activated, user can type straight
      // away to add a new entry
      this.set('editedEmailWhitelist', whitelist ? whitelist.split(',').join('\n') + '\n' : whitelist);
      this.set('editedEmailBlacklist', blacklist ? blacklist.split(',').join('\n') + '\n' : blacklist);
    },
    isEdited: function isEdited() {
      return this.get('hasChanges');
    }
  });
});