define('frontend-cp/components/ko-timeline-2/component', ['exports', 'frontend-cp/components/ko-agent-content/layout/styles', 'frontend-cp/components/ko-timeline-2/styles', 'frontend-cp/components/ko-timeline-2/list/post/styles', 'frontend-cp/components/ko-timeline-2/streaks', 'ember-concurrency', 'frontend-cp/lib/facade/operation-as-post', 'frontend-cp/utils/is-internal-tag', 'frontend-cp/lib/scroll-to'], function (exports, _styles, _styles2, _styles3, _streaks, _emberConcurrency, _operationAsPost, _isInternalTag, _scrollTo2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var computed = Ember.computed;
  var or = Ember.computed.or;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  var jQuery = Ember.$;
  var run = Ember.run;
  var schedule = Ember.run.schedule;
  var scheduleOnce = Ember.run.scheduleOnce;
  var RSVP = Ember.RSVP;


  var LAST_POST = 'LAST_POST';
  var LAST_POST_OF_TYPE_MESSAGE_OR_NOTE = 'LAST_POST_OF_TYPE_MESSAGE_OR_NOTE';
  var UNREAD = 'UNREAD';

  // how many px scrolled up from the bottom do we still consider as being "pinned"
  // so we scroll to the bottom when new content comes in
  var PIN_BUFFER = 50;

  var TIMELINE_EVENTS = ['didFetchNewer', 'didFetchOlder', 'didFetchPosition', 'didFetchMostRecent', 'didFetchNewerAfterReply', 'willFetchNewerFromKRE', 'didFetchNewerFromKRE', 'didAddSentPosts', 'didAddSendingOperation', 'didUpdateSendingOperation'];

  var JUMP_BLIP_DELAY = 500;
  var JUMP_BLIP_DURATION = 500;

  exports.default = Component.extend({
    tagName: '',

    // Attrs
    postId: null,
    noteId: null,
    canResend: true,

    onCopyLink: function onCopyLink() {},
    onReplyToPost: function onReplyToPost() {},
    onReplyWithQuote: function onReplyWithQuote() {},
    onAddCC: function onAddCC() {},
    onResend: function onResend() {},

    // Services
    store: service(),
    router: service('-routing'),
    window: service(),
    session: service(),

    // State
    timeline: null,
    isScrolling: false,
    isPinnedToBottom: false,
    isItemMenuOpen: false,

    isLoading: or('timeline.fetchMostRecent.isRunning', 'timeline.fetchPosition.isRunning'),

    posts: computed('timeline.posts', function () {
      return this.get('timeline.posts') || [];
    }),

    moreOlderPosts: computed.alias('timeline.moreOlderPosts'),
    moreNewerPosts: computed.alias('timeline.moreNewerPosts'),
    previousTimeline: null,
    previousPostId: null,
    previousNoteId: null,

    hasUnseenPosts: computed.gt('model.readMarker.unreadCount', 0),
    hasLatestReadState: computed.and('timeline.updateRemoteReadState.isIdle', 'timeline.markAllAsSeen.isIdle'),
    inArchiveMode: computed('postId', function () {
      var postId = this.get('postId');
      return !isEmpty(postId) && postId !== UNREAD;
    }),
    isNotPinnedToBottom: computed.not('isPinnedToBottom'),
    shouldDisplayBlueBar: computed.and('hasUnseenPosts', // are there unseen posts?
    'hasLatestReadState', // have we loaded/persisted all read state?
    'isNotPinnedToBottom' // are we scrolled away from the bottom of the timeline?
    ),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _getProperties = this.getProperties('timeline', 'postId', 'noteId'),
          timeline = _getProperties.timeline,
          postId = _getProperties.postId,
          noteId = _getProperties.noteId;

      if (timeline) {
        if (this._isSwitchingTimelines()) {
          this._switchTimelines();
        } else if (this._isSwitchingQueryParameters()) {
          this._switchQueryParameters();
        } else {
          // DOUBLE RENDER KABOOOOM!!!!
        }

        this.setProperties({
          previousTimeline: timeline,
          previousPostId: postId,
          previousNoteId: noteId
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._registerScrollHandler();
      this._registerWindowFocusHandler();
      this._updateLocalReadState();
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      (0, _streaks.default)(jQuery('.' + _styles2.default.container));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unsubscribeFromTimeline(this.get('timeline'));
      this._unregisterScrollHandler();
      this._unregisterWindowFocusHandler();
    },


    // event listeners

    _subscribeToTimeline: function _subscribeToTimeline(timeline) {
      var _this = this;

      if (!timeline) {
        return;
      }
      TIMELINE_EVENTS.forEach(function (name) {
        return timeline.on(name, _this, name);
      });
    },
    _unsubscribeFromTimeline: function _unsubscribeFromTimeline(timeline) {
      var _this2 = this;

      if (!timeline) {
        return;
      }
      TIMELINE_EVENTS.forEach(function (name) {
        return timeline.off(name, _this2, name);
      });
    },
    _registerScrollHandler: function _registerScrollHandler() {
      this._scrollParent().on('scroll.timeline', run.bind(this, '_didScroll'));
    },
    _unregisterScrollHandler: function _unregisterScrollHandler() {
      this._scrollParent().off('scroll.timeline');
      run.cancel(this._scrollTimer);
    },
    _registerWindowFocusHandler: function _registerWindowFocusHandler() {
      this.get('window').on('focus', this, '_updateRemoteReadState');
    },
    _unregisterWindowFocusHandler: function _unregisterWindowFocusHandler() {
      this.get('window').off('focus', this, '_updateRemoteReadState');
    },


    // CP's
    allPosts: computed('meaningfulPosts.[]', 'timeline.{sentPosts,sendingOperations}.[]', function () {
      var posts = this.get('meaningfulPosts');
      var sentPosts = (this.get('timeline.sentPosts') || []).filter(function (post) {
        return !posts.includes(post);
      });
      var creator = this.get('session.user');
      var sendingOperations = this.get('timeline.sendingOperations') || [];
      var seenClientIds = [].concat(_toConsumableArray(posts.mapBy('clientId')), _toConsumableArray(sentPosts.mapBy('clientId')));
      var sendingPosts = sendingOperations.filter(function (operation) {
        return !seenClientIds.includes(operation.attrs.clientId);
      }).map(function (operation) {
        return _operationAsPost.default.create({ operation: operation, creator: creator });
      });

      return [].concat(_toConsumableArray(posts), _toConsumableArray(sentPosts), _toConsumableArray(sendingPosts));
    }),

    meaningfulPosts: computed('posts', function () {
      return this.get('posts').map(removeInternalTagsPost).filter(isPostMeaningful);
    }),

    postsPriorToConversationCreation: computed('allPosts', function () {
      var allPosts = this.get('allPosts');
      var conversationStartDate = this.get('model.createdAt');
      return allPosts.filter(function (post) {
        return conversationStartDate > post.get('createdAt');
      });
    }),

    hasPostsPriorToConversationCreation: computed.gt('postsPriorToConversationCreation.length', 0),

    postsAfterConversationCreation: computed('allPosts', function () {
      var allPosts = this.get('allPosts');
      var conversationStartDate = this.get('model.createdAt');
      return allPosts.filter(function (post) {
        return conversationStartDate <= post.get('createdAt');
      });
    }),

    // Tasks
    _blipPost: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(postId) {
      var element;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              element = this._findPostById(postId).children('div');

              if (!(element && element.length)) {
                _context.next = 8;
                break;
              }

              _context.next = 4;
              return (0, _emberConcurrency.timeout)(JUMP_BLIP_DELAY);

            case 4:
              element.addClass(_styles2.default.located);
              _context.next = 7;
              return (0, _emberConcurrency.timeout)(JUMP_BLIP_DURATION);

            case 7:
              element.removeClass(_styles2.default.located);

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    handleDidFetchNewerFromKRE: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!(!this._wasPinnedToBottom || !this.get('window.visible'))) {
                _context2.next = 5;
                break;
              }

              _context2.next = 3;
              return this.get('model').reload();

            case 3:
              _context2.next = 5;
              return this._updateLocalReadState();

            case 5:
              if (!this._wasPinnedToBottom) {
                _context2.next = 12;
                break;
              }

              _context2.next = 8;
              return this.scrollTo({ position: _scrollTo2.BOTTOM, animated: true });

            case 8:
              _context2.next = 10;
              return this._saveScrollData();

            case 10:
              _context2.next = 12;
              return this._updateRemoteReadState();

            case 12:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).restartable(),

    loadPosition: function loadPosition(_ref) {
      var postId = _ref.postId,
          noteId = _ref.noteId;

      if (postId === UNREAD) {
        postId = this.get('model.readMarker.lastReadPostId');
      }
      if (postId) {
        return this.get('timeline.loadPosition').perform({ postId: postId });
      }
      return this.get('timeline.loadPosition').perform({ noteId: noteId });
    },
    loadMostRecent: function loadMostRecent() {
      return this.get('timeline.loadMostRecent').perform();
    },
    fetchMostRecent: function fetchMostRecent() {
      return this.get('timeline.fetchMostRecent').perform();
    },
    fetchOlder: function fetchOlder() {
      return this.get('timeline.fetchOlder').perform();
    },
    fetchNewer: function fetchNewer() {
      return this.get('timeline.fetchNewer').perform();
    },
    _updateRemoteReadState: function _updateRemoteReadState() {
      if (!this.get('window.visible')) {
        return;
      }

      if (this.get('moreNewerPosts')) {
        return;
      }

      if (!this._isPinnedToBottom()) {
        return;
      }

      var task = this.get('timeline.updateRemoteReadState');
      if (task) {
        task.perform();
      }
    },
    _updateLocalReadState: function _updateLocalReadState() {
      if (!this.get('hasUnseenPosts')) {
        return;
      }

      if (this.get('timeline.readMarker')) {
        return;
      }

      this.get('timeline').storeLocalReadState();
    },
    _didScroll: function _didScroll() {
      this._scrollTimer = run.debounce(this, '_saveScrollData', 250);
      this._handleReachingTimelineBoundary();
      this.set('isPinnedToBottom', this._isPinnedToBottom());
    },
    _handleReachingTimelineBoundary: function _handleReachingTimelineBoundary() {
      var $sp = this._scrollParent();

      if ((0, _scrollTo2.isAtTop)($sp)) {
        run.debounce(this, '_didReachTop', 250, true);
      }

      if ((0, _scrollTo2.isAtBottom)($sp)) {
        run.debounce(this, '_didReachBottom', 250, true);
      }
    },
    _didReachTop: function _didReachTop() {
      if (this.get('timeline.moreOlderPosts')) {
        this._saveScrollData();
        this.fetchOlder();
      }
    },
    _didReachBottom: function _didReachBottom() {
      if (this.get('timeline.moreNewerPosts')) {
        this._saveScrollData();
        this.fetchNewer();
      } else {
        this._updateRemoteReadState();
      }
    },
    _findFirstVisiblePostElement: function _findFirstVisiblePostElement($scrollParent) {
      var $posts = jQuery('.' + _styles3.default.post);
      return $posts.toArray().find(function (el) {
        return jQuery(el).offset().top - $scrollParent.offset().top >= 0;
      });
    },
    _scrollParent: function _scrollParent() {
      return jQuery('.' + _styles.default.timeline);
    },
    _findPostById: function _findPostById(postId) {
      return jQuery('.' + _styles3.default.post + '[data-id="' + postId + '"]');
    },
    _findPostByNoteId: function _findPostByNoteId(noteId) {
      return jQuery('.' + _styles3.default.post + '[data-note-id="' + noteId + '"]');
    },
    _findLastPost: function _findLastPost() {
      return jQuery('.' + _styles2.default.container + ' > :last-child');
    },
    _findLastPostOfTypeMessageOrNote: function _findLastPostOfTypeMessageOrNote() {
      return jQuery('.' + _styles3.default.post + '.message-or-note').last();
    },
    _isSwitchingTimelines: function _isSwitchingTimelines() {
      var _getProperties2 = this.getProperties('timeline', 'previousTimeline'),
          timeline = _getProperties2.timeline,
          previousTimeline = _getProperties2.previousTimeline;

      return timeline !== previousTimeline;
    },
    _isSwitchingQueryParameters: function _isSwitchingQueryParameters() {
      var _getProperties3 = this.getProperties('postId', 'previousPostId', 'noteId', 'previousNoteId'),
          postId = _getProperties3.postId,
          previousPostId = _getProperties3.previousPostId,
          noteId = _getProperties3.noteId,
          previousNoteId = _getProperties3.previousNoteId;

      return postId !== previousPostId || noteId !== previousNoteId;
    },
    _switchQueryParameters: function _switchQueryParameters() {
      var postId = this.get('postId');
      var noteId = this.get('noteId');

      if (postId) {
        this.loadPosition({ postId: postId });
      } else if (noteId) {
        this.loadPosition({ noteId: noteId });
      } else {
        this.loadMostRecent();
      }
    },
    _switchTimelines: function _switchTimelines() {
      var _getProperties4 = this.getProperties('postId', 'noteId', 'timeline', 'previousTimeline'),
          postId = _getProperties4.postId,
          noteId = _getProperties4.noteId,
          timeline = _getProperties4.timeline,
          previousTimeline = _getProperties4.previousTimeline;

      this._unsubscribeFromTimeline(previousTimeline);
      this._subscribeToTimeline(timeline);

      if (postId) {
        this.loadPosition({ postId: postId });
      } else if (noteId) {
        this.loadPosition({ noteId: noteId });
      } else if (timeline.get('posts.length')) {
        this._restoreScrollPosition();
      } else {
        this.fetchMostRecent();
      }
    },


    // fetched newer as a result of pushing the "load more" button
    didFetchNewer: function didFetchNewer() {
      this._updateRemoteReadState();
    },
    didAddSentPosts: function didAddSentPosts() {
      var _this3 = this;

      this.scrollTo({ position: _scrollTo2.BOTTOM, animated: true }).then(function () {
        return _this3._saveScrollData();
      });
    },
    didAddSendingOperation: function didAddSendingOperation() {
      var _this4 = this;

      this.scrollTo({ position: _scrollTo2.BOTTOM, animated: true }).then(function () {
        return _this4._saveScrollData();
      });
    },
    didUpdateSendingOperation: function didUpdateSendingOperation() {
      var _this5 = this;

      this.scrollTo({ position: _scrollTo2.BOTTOM, animated: true }).then(function () {
        return _this5._saveScrollData();
      });
    },


    // fetched newer posts after a reply has been submitted
    didFetchNewerAfterReply: function didFetchNewerAfterReply() {
      var _this6 = this;

      this.scrollTo({ position: _scrollTo2.BOTTOM, animated: true }).then(function () {
        return _this6._saveScrollData();
      }).then(function () {
        return _this6._updateRemoteReadState();
      });
    },


    // fetching newer posts as result of a NEW_POST event from KRE
    // need to check if they are currently pinned, as by the time didFetchNewerFromKRE
    // is triggered, there will be content inserted below
    willFetchNewerFromKRE: function willFetchNewerFromKRE() {
      this._wasPinnedToBottom = this._isPinnedToBottom();
    },


    // fetched newer posts as result of a NEW_POST event from KRE
    didFetchNewerFromKRE: function didFetchNewerFromKRE() {
      this.get('handleDidFetchNewerFromKRE').perform();
      var action = this.get('onDidFetchMostRecent');
      var posts = this.get('posts');

      if (action && posts) {
        action(posts);
      }
    },
    didFetchOlder: function didFetchOlder() {
      this._restoreScrollPosition();
    },
    didFetchPosition: function didFetchPosition(_ref2) {
      var postId = _ref2.postId,
          noteId = _ref2.noteId;

      if (postId) {
        // if loading around the read marker, use the next post instead
        if (this.get('postId') === UNREAD) {
          var posts = this.get('posts');
          var post = posts.findBy('id', postId);
          var index = posts.indexOf(post);
          var nextPost = posts.objectAt(index + 1);
          if (nextPost) {
            postId = nextPost.get('id');
          }
        }
      }

      scheduleOnce('afterRender', this, 'jumpToPost', { postId: postId, noteId: noteId });
    },
    jumpToPost: function jumpToPost(_ref3) {
      var _this7 = this;

      var postId = _ref3.postId,
          noteId = _ref3.noteId;

      this.scrollTo({ postId: postId, noteId: noteId, position: _scrollTo2.MIDDLE }).then(function () {
        return _this7.get('_blipPost').perform(postId);
      }).then(function () {
        return _this7._saveScrollData();
      }).then(function () {
        return _this7._handleReachingTimelineBoundary();
      });
    },
    didFetchMostRecent: function didFetchMostRecent() {
      var _this8 = this;

      this._updateLocalReadState();

      this.scrollTo({ postId: LAST_POST_OF_TYPE_MESSAGE_OR_NOTE, position: _scrollTo2.TOP }).then(function () {
        return _this8._saveScrollData();
      }).then(function () {
        return _this8._updateRemoteReadState();
      });
      var action = this.get('onDidFetchMostRecent');
      var posts = this.get('posts');

      if (action && posts) {
        action(posts);
      }
    },
    _isPinnedToBottom: function _isPinnedToBottom() {
      var $timeline = this._scrollParent();
      var timelineHeight = $timeline.height();
      var scrollDistance = $timeline.prop('scrollTop');
      var contentHeight = $timeline.prop('scrollHeight');
      var buffer = PIN_BUFFER;

      return scrollDistance + timelineHeight >= contentHeight - buffer;
    },
    _saveScrollData: function _saveScrollData() {
      var $scrollParent = this._scrollParent();

      var post = this._findFirstVisiblePostElement($scrollParent);

      if (post) {
        var $post = jQuery(post);
        var offsetFromParent = $post.offset().top - $scrollParent.offset().top;
        var postId = $post.data('id');

        var timeline = this.get('timeline');

        timeline.set('scrollData', {
          offset: offsetFromParent,
          id: postId
        });
      }
    },
    _restoreScrollPosition: function _restoreScrollPosition() {
      var _this9 = this;

      var scrollData = this.get('timeline.scrollData');

      if (scrollData) {
        schedule('afterRender', function () {
          var offset = scrollData.offset,
              id = scrollData.id;

          var $scrollParent = _this9._scrollParent();
          var $post = _this9._findPostById(id);
          var post = $post[0];

          if (!post) {
            return;
          }

          $scrollParent[0].scrollTop = post.offsetTop - offset;
        });
      } else {
        this.scrollTo({ postId: LAST_POST, position: _scrollTo2.BOTTOM });
      }
    },
    scrollTo: function scrollTo() {
      var _this10 = this;

      var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref4$postId = _ref4.postId,
          postId = _ref4$postId === undefined ? null : _ref4$postId,
          _ref4$noteId = _ref4.noteId,
          noteId = _ref4$noteId === undefined ? null : _ref4$noteId,
          _ref4$position = _ref4.position,
          position = _ref4$position === undefined ? _scrollTo2.BOTTOM : _ref4$position,
          _ref4$animated = _ref4.animated,
          animated = _ref4$animated === undefined ? false : _ref4$animated;

      animated = animated && !Ember.testing;

      return new RSVP.Promise(function (resolve, reject) {
        scheduleOnce('afterRender', function () {
          var timeline = _this10._scrollParent();

          if (!timeline.length) {
            resolve();
            return;
          }

          var timelineItem = void 0;
          if (postId === LAST_POST) {
            timelineItem = _this10._findLastPost();
          } else if (postId === LAST_POST_OF_TYPE_MESSAGE_OR_NOTE) {
            timelineItem = _this10._findLastPostOfTypeMessageOrNote();
          } else if (postId) {
            timelineItem = _this10._findPostById(postId);
          } else if (noteId) {
            timelineItem = _this10._findPostByNoteId(noteId);
          }

          (0, _scrollTo2.scrollTo)({
            parent: timeline,
            element: timelineItem,
            position: position,
            animated: animated
          }).then(resolve).catch(reject);
        });
      });
    },
    scrollPostIntoViewIfNeeded: function scrollPostIntoViewIfNeeded(post) {
      var postId = post.get('id');
      if (!(0, _scrollTo2.isVisibleInScrollArea)(this._findPostById(postId), this._scrollParent())) {
        this.scrollTo({ postId: postId, position: _scrollTo2.BOTTOM, animated: true });
      }
    },


    // Actions
    actions: {
      loadOlder: function loadOlder() {
        return this.fetchOlder();
      },
      loadNewer: function loadNewer() {
        return this.fetchNewer();
      },
      scrollPostIntoView: function scrollPostIntoView(post) {
        scheduleOnce('afterRender', this, 'scrollPostIntoViewIfNeeded', post);
      },
      jumpToLatest: function jumpToLatest() {
        if (!this.get('inArchiveMode')) {
          this.scrollTo({ position: _scrollTo2.BOTTOM, animated: true });
        } else {
          var router = this.get('router');
          var kase = this.get('model');
          router.transitionTo('session.agent.cases.case.index', [kase], { postId: UNREAD });
        }
      },
      markAllAsSeen: function markAllAsSeen() {
        this.get('timeline.markAllAsSeen').perform();
      }
    }

  });


  function removeInternalTagsPost(post) {
    if (post.get('original.isActivity') && post.get('original.activity') === 'update_case') {
      var actions = post.get('original.actions') || [];

      var filterInternalTags = function filterInternalTags(_ref5) {
        var _ref6 = _slicedToArray(_ref5, 1),
            tags = _ref6[0];

        return tags ? tags.split(',').map(function (tag) {
          return tag.trim();
        }).filter(function (tag) {
          return !(0, _isInternalTag.default)({ name: tag });
        }).join(', ') : '';
      };

      var filtered = actions.filter(function (action) {
        if (action.get('field') === 'tags' || action.get('field') === 'name') {
          var oldTags = filterInternalTags([action.get('oldValue')]);
          var newTags = filterInternalTags([action.get('newValue')]);
          action.set('oldValue', oldTags);
          action.set('newValue', newTags);
          return (oldTags || newTags) && oldTags !== newTags;
        }
        return true;
      });
      post.set('original.actions', filtered);
    }
    return post;
  }

  function isPostMeaningful(post) {
    if (isPostAnEmptyUpdate(post)) {
      return false;
    }
    return true;
  }

  function isPostAnEmptyUpdate(post) {
    return post.get('original.isActivity') && post.get('original.activity') === 'update_case' && post.get('original.actions.length') === 0;
  }
});