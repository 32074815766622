define('frontend-cp/components/ko-insights/loader/cases/case-response/component', ['exports', 'frontend-cp/lib/computed/humanize-seconds'], function (exports, _humanizeSeconds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectProxy = Ember.ObjectProxy;
  var PromiseProxyMixin = Ember.PromiseProxyMixin;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend({
    tagName: '',
    insights: service(),
    metricsQueryParams: null,
    onUpdate: function onUpdate() {},

    request: computed('metricsQueryParams', function () {
      var _this = this;

      var insights = this.get('insights');
      var adapter = getOwner(this).lookup('adapter:application');
      var endpoint = insights.caseResponseEndpoint();

      return ObjectProxy.extend(PromiseProxyMixin).create({
        promise: adapter.ajax(endpoint, 'GET', this.get('metricsQueryParams')).then(function (data) {
          if (_this.isDestroying || _this.isDestroyed) {
            return null;
          }

          _this.attrs.onUpdate && _this.attrs.onUpdate(data.data);
          _this.set('series', data.data);

          return data;
        })
      });
    }),

    series: null,

    metricName: reads('series.metric.name'),
    metricDelta: reads('series.metric.delta_percent'),
    metricValue: (0, _humanizeSeconds.default)('series.metric.value'),
    metricPrevious: (0, _humanizeSeconds.default)('series.metric.previous')
  });
});