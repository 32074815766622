define('frontend-cp/login-agent/controller', ['exports', 'ember-service/inject', 'frontend-cp/utils/format-validations'], function (exports, _inject, _formatValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberError = Ember.Error;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var on = Ember.on;
  var observer = Ember.observer;
  var run = Ember.run;
  var runNext = Ember.run.next;
  var A = Ember.A;
  var RSVP = Ember.RSVP;
  var jQuery = Ember.$;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    sessionService: service('session'),
    notificationService: service('notification'),
    errorHandler: service('error-handler'),
    windowService: service('window'),
    i18n: service(),
    locale: service(),
    deviceFingerprint: service(),

    newPassword1: '',
    otp: '',
    newPassword2: '',
    forgotPasswordMessage: '',
    fieldErrors: [],
    avatarBackground: null,
    validAvatar: false,
    isContentDown: false,
    prevLoginState: null,
    topFormSet: null,
    bottomFormSet: null,
    authToken: null,
    authProviders: null,
    rememberMe: false,

    queryParams: ['bypassSso', 'error'],
    bypassSso: 'false',
    error: '',

    /**
     * The current state as a dot separated list
     * representing the state hierarchy
     * @type {String}
     */
    _currentState: '',

    init: function init() {
      this.reset();
      this._super.apply(this, arguments);
    },


    /**
     * An object tree representing all possible
     * states and their parent/child relationships
     * @type {Object}
     */
    stateMap: {
      login: {
        password: {
          input: {},
          loading: {},
          confirmed: {},
          error: {}
        },
        otp: {
          input: {},
          loading: {},
          confirmed: {},
          error: {}
        },
        resetPassword: {
          input: {},
          loading: {},
          confirmed: {},
          error: {}
        }
      },
      forgotPassword: {
        input: {},
        loading: {},
        confirmed: {},
        error: {}
      }
    },

    // Observers
    actionButtonText: computed('currentState', function () {
      if (this.get('currentState') === 'login.resetPassword.input') {
        return this.get('i18n').t('login.updatepassword');
      } else {
        return this.get('i18n').t('login.login');
      }
    }),

    flipAvatar: computed('validAvatar', 'avatarBackground', function () {
      return this.get('validAvatar') && this.get('avatarBackground');
    }),

    emailValidDidChange: on('render', observer('emailValid', function () {
      if (this.get('emailValid')) {
        this.requestAvatar(this.get('model.email'));
      }
    })),

    sessionDidClear: on('init', observer('sessionService.session.id', function () {
      if (this.get('sessionService.session.id') === null) {
        this.setState('login.password.input');
      }
    })),

    currentStateDidChange: on('init', observer('currentState', function () {
      var _this = this;

      var currentState = this.get('currentState');

      // Ignore anything outside the login root state
      if (!this.isInState('login', currentState)) {
        this.set('prevLoginState', currentState);
        return;
      }

      var stateMeta = {
        password: {
          order: 0,
          component: 'ko-login/password'
        },
        otp: {
          order: 1,
          component: 'ko-login/otp'
        },
        resetPassword: {
          order: 2,
          component: 'ko-login/reset'
        }
      };

      var prevState = this.get('prevLoginState');
      var currentSubState = this.getStateAtLevel(1, currentState);
      var prevSubState = this.getStateAtLevel(1, prevState);
      var currentStateMeta = stateMeta[currentSubState];
      var prevStateMeta = stateMeta[prevSubState];
      var isContentDown = null;

      // Only items within login level 0 state should animate up / down
      if (this.getStateAtLevel(0, prevState) === 'login') {

        /**
         * Determine the direction of movement depending on 'order' of item
         * Eg. moving from password to otp will move down,
         * otp to resetPassword will move down again
         * resetPassword to password will move up (once, we don't want to go 'past' otp)
         */

        // This should explicitly do nothing if the orders are equal
        if (currentStateMeta.order > prevStateMeta.order) {
          isContentDown = true;
        } else if (currentStateMeta.order < prevStateMeta.order) {
          isContentDown = false;
        }

        // Place content area in pre-animation state
        run(function () {
          // Choose where to place the prev and next components
          _this.setProperties({
            topFormSet: isContentDown ? prevStateMeta.component : currentStateMeta.component,
            bottomFormSet: isContentDown ? currentStateMeta.component : prevStateMeta.component
          });

          if (currentSubState !== prevSubState) {
            // Move content to show the previous component
            _this.set('isContentDown', !isContentDown);
          }
        });

        // In next run loop run the animation
        runNext(function () {
          _this.set('isContentDown', isContentDown);
        });
      }

      // Store prevState for comparison
      this.set('prevLoginState', currentState);
    })),

    // Computed Properties

    /**
     * Active notifications
     * @return {Object[]} Array of notification objects
     */
    notifications: computed('notificationService.notifications.[]', function () {
      var notificationService = this.get('notificationService');
      return notificationService.get('notifications');
    }),

    isLogin: computed('currentState', 'prevLoginState', function () {
      var currentState = this.get('currentState');
      var prevState = this.get('prevLoginState');
      var isInLogin = this.isInState('login', currentState);
      var wasInLogin = this.isInState('login', prevState);
      if (isInLogin !== wasInLogin) {
        this.clearErrors();
      }
      return isInLogin;
    }),

    isLoading: computed('currentState', function () {
      return this.endsWithSubState('loading', this.get('currentState'));
    }),

    isOtp: computed('currentState', function () {
      return this.isInState('login.otp', this.get('currentState'));
    }),

    isPassword: computed('currentState', function () {
      return this.isInState('login.password', this.get('currentState'));
    }),

    isResetPassword: computed('currentState', function () {
      return this.isInState('login.resetPassword', this.get('currentState'));
    }),

    isError: computed('currentState', function () {
      return this.endsWithSubState('error', this.get('currentState'));
    }),

    isForgotPasswordEmailSent: computed('currentState', function () {
      return this.isInState('forgotPassword.confirmed', this.get('currentState'));
    }),

    emailValid: computed('model.email', function () {
      return (0, _formatValidations.validateEmailFormat)(this.get('model.email'));
    }),

    passwordValid: computed('model.password', function () {
      return this.get('model.password').length > 0;
    }),

    canAttemptLogin: computed('emailValid', 'passwordValid', function () {
      return this.get('emailValid') && this.get('passwordValid');
    }),

    googleLoginLink: computed('authProviders', function () {
      var googleProvider = this.get('authProviders').findBy('id', 'GIA');
      if (googleProvider) {
        return googleProvider.get('loginUrl').replace('#action#', this._getLoginRedirectPath());
      }
      return null;
    }),

    loginButtonDisabled: computed('canAttemptLogin', 'isLoading', 'newPasswordValid', 'isResetPassword', function () {
      return !this.get('canAttemptLogin') || this.get('isLoading') || !this.get('newPasswordValid') && this.get('isResetPassword');
    }),

    resetButtonDisabled: computed('isLoading', 'emailValid', function () {
      return this.get('isLoading') || !this.get('emailValid');
    }),

    newPasswordValid: computed('newPassword1', 'newPassword2', function () {
      var password1 = this.get('newPassword1');
      var password2 = this.get('newPassword2');
      return password1 === password2 && !!password1 && password1.length > 7;
    }),

    hasErrorMessages: computed('fieldErrors.[]', function () {
      return this.get('fieldErrors').length > 0;
    }),

    errorMessages: computed('fieldErrors.[]', function () {
      return this.get('fieldErrors').map(this.transformMessages.bind(this));
    }),

    /**
     * Read-only current state property
     * For most use cases use isInState, used mostly as a property
     * in computed properties to observe when state changes
     * @return {String} current state as a dot separated list representing the state hierarchy
     */
    currentState: computed('_currentState', function () {
      return this.get('_currentState');
    }),

    // Methods
    transformMessages: function transformMessages(error) {
      if ((error.code === 'FIELD_REQUIRED' || error.code === 'FIELD_INVALID') && error.parameter === 'email') {
        return this.get('i18n').t('login.error.invalid_email');
      } else if (error.code === 'ASSOCIATE_NOT_FOUND' && error.parameter === 'email') {
        return this.get('i18n').t('login.error.email_not_found');
      } else {
        return error.message;
      }
    },
    setErrors: function setErrors(errors) {
      this.set('fieldErrors', new A(errors));
    },
    clearErrors: function clearErrors() {
      this.set('fieldErrors', []);
    },
    resetRequest: function resetRequest(params) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        jQuery.ajax({
          type: 'PUT',
          url: '/api/v1/base/profile/password',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          dataType: 'json',
          data: jQuery.param(params),
          headers: { 'X-Token': _this2.get('authToken'), 'X-CSRF': true },
          success: function success(data, status, xhr) {
            data.csrf_token = xhr.getResponseHeader('x-csrf-token');
            resolve(data);
          },
          error: function error(xhr) {
            reject(xhr.responseText);
          }
        });
      });
    },
    otpRequest: function otpRequest(otp) {
      var _this3 = this;

      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('release-remember-me')) {
        return this.get('sessionService').requestSession({
          otp: otp,
          authToken: this.get('authToken'),
          rememberMe: this.get('rememberMe')
        });
      } else {
        return new RSVP.Promise(function (resolve, reject) {
          jQuery.ajax({
            type: 'GET',
            url: '/api/v1/session',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            dataType: 'json',
            headers: { 'X-Token': _this3.get('authToken'), 'X-OTP': otp, 'X-CSRF': true },
            success: function success(data, status, xhr) {
              data.csrf_token = xhr.getResponseHeader('x-csrf-token');
              resolve(data);
            },
            error: function error(xhr) {
              reject(xhr.responseText);
            }
          });
        });
      }
    },
    sendPasswordResetRequest: function sendPasswordResetRequest(params) {
      return new RSVP.Promise(function (resolve, reject) {
        jQuery.ajax({
          type: 'POST',
          url: '/api/v1/base/password/reset',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          dataType: 'json',
          data: jQuery.param(params),
          success: function success(data) {
            resolve(data);
          },
          error: function error(xhr) {
            reject(xhr.responseText);
          }
        });
      });
    },
    requestAvatar: function requestAvatar(email) {
      var _this4 = this;

      //This endpoint is won't work for alpha 1 launch
      jQuery.ajax({
        type: 'POST',
        url: '/admin/index.php?/Base/Avatar/JSON/0/200',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        dataType: 'json',
        data: jQuery.param({ email: email }),
        success: function success(response) {
          /**
           * data.is_user dictates whether or not the returned image was gravatars
           * default image or not, if it was, we do not want to flip, treat it as a failed
           * call
           */

          var valid = !!response.data.is_user;

          _this4.set('validAvatar', valid);
          if (valid) {
            _this4.set('avatarBackground', response.data.data);
          }
        },
        error: function error() {
          if (console && console.warn) {
            // eslint-ignore-line
            console.warn('Avatar loading failed'); // eslint-ignore-line
          }
        }
      });
    },
    _onSuccessLogin: function _onSuccessLogin() {
      this.setState('login.password.confirmed');
      this._redirectOrTransitionToSession();
    },
    _getLoginRedirectPath: function _getLoginRedirectPath() {
      var attemptedTransition = this.get('sessionService.attemptedTransition');
      var redirectPath = this.get('sessionService.loginRedirectPath');

      if (attemptedTransition && attemptedTransition.intent && attemptedTransition.intent.url) {
        return attemptedTransition.intent.url;
      } else if (redirectPath) {
        return redirectPath;
      } else {
        return this.get('windowService').currentPath();
      }
    },
    _redirectOrTransitionToSession: function _redirectOrTransitionToSession() {
      var attemptedTransition = this.get('sessionService.attemptedTransition');
      var redirectPath = this.get('sessionService.loginRedirectPath');

      if (attemptedTransition) {
        this.set('sessionService.attemptedTransition', null);
        attemptedTransition.retry();
      } else if (redirectPath) {
        this.set('sessionService.loginRedirectPath', null);
        this.transitionToRoute(redirectPath);
      } else {
        this.transitionToRoute('session.agent');
      }
    },
    _requestSession: function _requestSession() {
      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('release-remember-me')) {
        return this.get('sessionService').requestSession({ email: this.get('model.email'), password: this.get('model.password'), rememberMe: this.get('rememberMe') });
      }
      return this.get('sessionService').requestSession({ email: this.get('model.email'), password: this.get('model.password') });
    },
    login: function login() {
      var _this5 = this;

      var locale = this.get('locale');
      this.setState('login.password.loading');
      this._requestSession().then(function () {
        var userLocaleCode = _this5.get('sessionService.user.locale.locale');

        if (locale.getLocaleCodeFromLocalStorage() !== userLocaleCode) {
          locale.setup().then(function () {
            _this5._onSuccessLogin();
          });
        } else {
          _this5._onSuccessLogin();
        }
      }, function (error) {
        var errors = error.errors || [];
        var errorCodes = errors.map(function (error) {
          return error.code;
        });

        if (errorCodes.indexOf('AUTHENTICATION_FAILED') > -1) {
          _this5.setState('login.password.error');
          _this5.setErrors([{ message: _this5.get('i18n').t('login.error.login_failed') }]);
        } else if (errorCodes.indexOf('CREDENTIAL_EXPIRED') > -1) {
          var authToken = errors[errorCodes.indexOf('CREDENTIAL_EXPIRED')].authToken;
          _this5.set('authToken', authToken);
          _this5.setState('login.resetPassword.input');
        } else if (errorCodes.indexOf('OTP_EXPECTED') > -1) {
          // User needs to enter one time password for two factor authentication
          var _authToken = errors[errorCodes.indexOf('OTP_EXPECTED')].authToken;
          _this5.set('authToken', _authToken);
          _this5.setState('login.otp.input');
        } else if (error instanceof EmberError) {
          // this should never happen in production, but it might happen
          // on development stage when we have problems with models
          // (or similar)
          _this5.setState('login.password.error');
          _this5.setErrors([{ message: 'System error, please contact Customer Support' }]);
        } else {
          _this5.setState('login.password.error');
        }
      });
    },
    resetPassword: function resetPassword() {
      var _this6 = this;

      this.setState('login.resetPassword.loading');
      this.setErrors([]);

      this.resetRequest({
        password: this.get('model.password'),
        new_password: this.get('newPassword1')
      }).then(function (response) {
        _this6.get('notificationService').removeAll();
        if (response.session_id) {
          _this6.setupSessionFromResponse(response);
          _this6._redirectOrTransitionToSession();
        } else {
          _this6.setState('login.resetPassword.error');
          _this6.setErrors({ message: 'Session missing' });
        }
      }, function (response) {
        _this6.setState('login.resetPassword.error');
        var data = JSON.parse(response);
        _this6.setErrors(data.notifications);
        _this6.get('errorHandler').processErrors(data.errors);
        _this6.get('errorHandler').process();
      });
    },
    submitOtp: function submitOtp() {
      var _this7 = this;

      var launchDarkly = this.get('launchDarkly');

      this.setState('login.otp.loading');
      this.setErrors([]);

      if (!launchDarkly.get('release-remember-me')) {
        this.otpRequest(this.get('otp')).then(function (response) {
          _this7.get('notificationService').removeAll();
          if (response.session_id) {
            _this7.setState('login.otp.confirmed');
            _this7.setupSessionFromResponse(response);
            _this7._redirectOrTransitionToSession();
          } else {
            _this7.setState('login.otp.error');
            _this7.setErrors([{ message: 'Session missing' }]);
          }
        }, function (response) {
          _this7.setState('login.otp.error');
          var data = JSON.parse(response);
          _this7.setErrors(data.notifications);
          _this7.get('errorHandler').processErrors(data.errors);
          _this7.get('errorHandler').process();
        });
        return;
      }

      this.otpRequest(this.get('otp')).then(function () {
        _this7.get('notificationService').removeAll();
        if (_this7.get('sessionService.sessionId')) {
          _this7.setState('login.otp.confirmed');
          _this7._redirectOrTransitionToSession();
        } else {
          _this7.setState('login.otp.error');
          _this7.setErrors([{ message: 'Session missing' }]);
        }
      }, function () {
        _this7.setState('login.otp.error');
      });
    },
    setupSessionFromResponse: function setupSessionFromResponse(response) {
      var session = this.get('sessionService');
      session.setProperties({
        sessionId: response.session_id,
        csrfToken: response.csrf_token
      });
    },


    /**
     * Sets the current state as a dot separated
     * list representing the desired state hierarchy
     * eg. 'root.session.foo'
     * Throws an error if the state is not available
     * in the stateMap object tree
     * @param {String} state Dot separated string of state hierarchy
     */
    setState: function setState(state) {
      if (this.get('stateMap.' + state)) {
        this.set('_currentState', state);
      } else {
        throw new Error('Invalid state: ' + state);
      }
    },


    /**
     * Returns true if you are in the current state, you must specify the currentState
     * in order force a call to this.get('currentState') in computed properties,
     * otherwise the computed property will not be called.
     * or any of its parent states
     * @param  {String}  state Dot separated string of state hierarchy
     * @param  {String}  currentState Dot separated string of state hierarchy
     * @return {Boolean}
     */
    isInState: function isInState(state, currentState) {
      if (typeof currentState === 'undefined') {
        throw new Error('currentState is not defined');
      }
      return currentState.indexOf(state) === 0;
    },


    /**
     * Returns true if the last substate in the state string
     * is the leafState/s
     * @param  {String}  subState     Dot separated string of sub-state
     * @param  {String}  currentState currentState Dot separated string of state hierarchy
     * @return {Boolean}
     */
    endsWithSubState: function endsWithSubState(subState, currentState) {
      if (typeof currentState === 'undefined') {
        throw new Error('currentState is not defined');
      }
      return currentState.indexOf(subState) === currentState.length - subState.length;
    },
    getStateAtLevel: function getStateAtLevel(level, currentState) {
      currentState = currentState || this.get('_currentState');
      return currentState.split('.')[level];
    },


    /**
     * Need to SafeString all bound style attributes
     */

    loginFrontImageStyle: computed(function () {
      return htmlSafe('background-image: url(\'/images/login/avatar.png\');');
    }),

    loginBackImageStyle: computed('avatarBackground', function () {
      if (this.get('avatarBackground')) {
        return htmlSafe('background-image: url("' + this.get('avatarBackground') + '");');
      } else {
        return htmlSafe('');
      }
    }),

    actions: {
      login: function login(e) {
        e.preventDefault();
        this.setErrors([]);

        if (!this.isInState('login', this.get('currentState'))) {
          return;
        }

        var funcMap = {
          password: this.login,
          resetPassword: this.resetPassword,
          otp: this.submitOtp
        };

        Reflect.apply(funcMap[this.getStateAtLevel(1)], this, []);
      },
      gotoForgotPassword: function gotoForgotPassword() {
        if (!this.get('isLoading')) {
          this.setState('forgotPassword.input');
        }
      },
      gotoLogin: function gotoLogin() {
        if (!this.get('isLoading')) {
          this.setState('login.password.input');
        }
      },
      redirectToGoogle: function redirectToGoogle(e) {
        e.preventDefault();
        window.location.href = this.get('googleLoginLink');
      },
      sendForgotPasswordEmail: function sendForgotPasswordEmail(e) {
        var _this8 = this;

        e.preventDefault();
        this.setState('forgotPassword.loading');
        this.setErrors([]);
        this.sendPasswordResetRequest({ email: this.get('model.email') }).then(function () {
          _this8.setState('forgotPassword.confirmed');
          _this8.set('forgotPasswordMessage', 'An email with a reset link has been sent to your inbox');
        }, function (response) {
          var data = JSON.parse(response);
          _this8.setState('forgotPassword.error');
          _this8.setErrors(data.errors);
        });
      },
      onNotificationClosed: function onNotificationClosed(notification) {
        var notificationService = this.get('notificationService');
        notificationService.remove(notification);
      }
    },

    reset: function reset() {
      this.set('otp', '');
      this.setState('login.password.input');
      this.set('prevLoginState', this.get('currentState'));
    }
  });
});