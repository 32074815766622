define('frontend-cp/serializers/post', ['exports', 'frontend-cp/serializers/application', 'frontend-cp/serializers/activity'], function (exports, _application, _activity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.resources && payload.resources.activity) {
        Object.keys(payload.resources.activity).forEach(function (activityId) {
          var activity = payload.resources.activity[activityId];
          (0, _activity.addResourcesToActivity)(activity);
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});