define("frontend-cp/components/ko-modal/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-modal__container_9qp2si",
    "content": "ko-modal__content_9qp2si",
    "scaleup--small": "ko-modal__scaleup--small_9qp2si",
    "header": "ko-modal__header_9qp2si",
    "no-header-content": "ko-modal__no-header-content_9qp2si",
    "has-border": "ko-modal__has-border_9qp2si",
    "header-with-icon": "ko-modal__header-with-icon_9qp2si",
    "header-text": "ko-modal__header-text_9qp2si",
    "close": "ko-modal__close_9qp2si",
    "main": "ko-modal__main_9qp2si",
    "main--chrome": "ko-modal__main--chrome_9qp2si",
    "footer": "ko-modal__footer_9qp2si",
    "spaced-footer": "ko-modal__spaced-footer_9qp2si"
  };
});