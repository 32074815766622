define('frontend-cp/models/notification-preference', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    notificationType: (0, _attr.default)('String'),
    channelDesktop: (0, _attr.default)('boolean'),
    channelMobile: (0, _attr.default)('boolean'),
    channelEmail: (0, _attr.default)('boolean')
  });
});