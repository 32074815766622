define('frontend-cp/services/advanced-search', ['exports', 'npm:lodash', 'ember-concurrency'], function (exports, _npmLodash, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var RSVP = Ember.RSVP;
  var getOwner = Ember.getOwner;
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var resultGroups = {
    CASES: {
      fields: 'data(requester(avatar,full_name),last_post_status,last_replier(full_name,role),last_message_preview,subject,priority,state,status,assigned_agent(full_name,avatar),updated_at,last_replied_at,has_attachments),resource',
      include: 'case,case_status,case_priority,user,role'
    },
    USERS: {
      fields: 'data(avatar,full_name,emails,organization(name),last_activity_at),resource',
      include: 'user,identity_email,organization'
    },
    ORGANIZATIONS: {
      fields: '+name,+updated_at,+domains',
      include: 'organization'
    }
  };

  exports.default = Service.extend({
    store: service(),

    search: function search(term, offset, pageLimit) {
      return this.get('_performSearch').perform(term, offset, pageLimit);
    },
    searchByGroup: function searchByGroup(query) {
      var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 30;
      var resources = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var fields = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      var include = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : '*';

      var adapter = getOwner(this).lookup('adapter:application');
      var url = adapter.namespace + '/search';
      var options = {
        data: {
          query: query,
          offset: offset,
          limit: limit,
          fields: fields,
          include: include,
          resources: resources
        }
      };
      return adapter.ajax(url, 'GET', options);
    },


    _performSearch: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(term, offset, pageLimit) {
      var _this = this;

      var promises;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              promises = Object.keys(resultGroups).map(function (group) {
                var _resultGroups$group = resultGroups[group],
                    fields = _resultGroups$group.fields,
                    include = _resultGroups$group.include;

                return _this.searchByGroup(term, offset, pageLimit, group, fields, include);
              });
              _context.next = 3;
              return RSVP.all(promises).then(function (responses) {
                var results = _npmLodash.default.zip(Object.keys(resultGroups), responses.getEach('data'), responses.getEach('total_count'));

                responses.forEach(function (res) {
                  Reflect.deleteProperty(res, 'data');
                  _this.get('store').pushPayload(res);
                });

                // Turn group we want into models
                var searchResults = EmberObject.create({});

                results.forEach(function (res) {
                  searchResults.set(res[0], {
                    results: res[1],
                    total: res[2],
                    totalPages: Math.ceil(res[2] / pageLimit)
                  });
                });

                return searchResults;
              });

            case 3:
              return _context.abrupt('return', _context.sent);

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop()
  });
});