define("frontend-cp/components/ko-info-bar/field/multiline-text/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "multiline-text": "ko-info-bar_field_multiline-text__multiline-text_hba5q",
    "textarea": "ko-info-bar_field_multiline-text__textarea_hba5q",
    "header": "ko-info-bar_field_multiline-text__header_hba5q"
  };
});