define("frontend-cp/components/ko-pagination/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-pagination_item__container_1gps67",
    "item": "ko-pagination_item__item_1gps67",
    "active": "ko-pagination_item__active_1gps67"
  };
});