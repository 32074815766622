define('frontend-cp/session/admin/apps/endpoints/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    store: service(),
    confirmation: service(),
    i18n: service(),

    // CPs
    enabledEndpoints: computed('model.[]', 'model.@each.isEnabled', function () {
      return this.get('model').filter(function (endpoint) {
        return endpoint.get('isEnabled');
      });
    }),

    disabledEndpoints: computed('model.[]', 'model.@each.isEnabled', function () {
      return this.get('model').filter(function (endpoint) {
        return !endpoint.get('isEnabled');
      });
    }),

    // Actions
    actions: {
      transitionToSelectType: function transitionToSelectType() {
        this.transitionToRoute('session.admin.apps.endpoints.select-type');
      },
      editEndpoint: function editEndpoint(endpoint) {
        this.transitionToRoute('session.admin.apps.endpoints.edit', endpoint);
      },
      showErrorDetails: function showErrorDetails(endpoint, e) {
        e.stopPropagation();
        this.transitionToRoute('session.admin.apps.endpoints.index.details', endpoint);
      },
      toggleEnabledStatus: function toggleEnabledStatus(endpoint, e) {
        e.stopPropagation();
        endpoint.toggleProperty('isEnabled');
        endpoint.save();
      },
      deleteEndpoint: function deleteEndpoint(endpoint, e) {
        e.preventDefault();
        e.stopPropagation();
        return this.get('confirmation').confirm({
          intlConfirmationBody: 'generic.confirm.delete'
        }).then(function () {
          return endpoint.destroyRecord();
        });
      }
    }
  });
});