define('frontend-cp/services/error-handler/session-loading-failed-strategy', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    notification: service('notification'),
    i18n: service(),
    session: service('session'),
    router: service('-routing'),
    windowService: service('window'),

    init: function init() {
      this._super.apply(this, arguments);
      this.records = [];
    },
    accept: function accept(record) {
      this.records.push(record);
    },
    process: function process() {
      var recordsCount = this.records.length;

      if (recordsCount) {
        this.get('_transitionToLogin').perform();

        this.records = [];
      }

      return recordsCount;
    },


    _transitionToLogin: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var _getProperties, router, notification, session, windowService;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _getProperties = this.getProperties('router', 'notification', 'session', 'windowService'), router = _getProperties.router, notification = _getProperties.notification, session = _getProperties.session, windowService = _getProperties.windowService;


              session.reportSessionExpiry('Invalid/Missing sessionId. Called from app/services/error-handler/session-loading-failed-strategy.js');
              session.clear();

              session.set('loginRedirectPath', windowService.currentPath());

              _context.next = 6;
              return router.transitionTo('login-agent', [], {});

            case 6:

              notification.add({
                type: 'error',
                title: this.get('i18n').t('generic.user_logged_out'),
                body: this.get('i18n').t('generic.session_expired'),
                autodismiss: true
              });

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop()
  });
});