define('frontend-cp/components/ko-organization-list/component', ['exports', 'ember-concurrency', 'frontend-cp/config/environment', 'ember-diff-attrs', 'npm:lodash'], function (exports, _emberConcurrency, _environment, _emberDiffAttrs, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var get = Ember.get;


  var PAGE_LIMIT = _environment.default.orgListPageSize;

  exports.default = Component.extend({
    tagName: '',

    //Attrs
    definitions: null,
    page: 1,

    //State
    currentPredicateCollections: null,
    currentPropositionOperators: null,
    currentCollectionOperator: null,
    totalRecords: null,
    totalResults: null,
    lastRunQuery: null,

    //Serivces
    store: service(),
    locale: service(),
    router: service('-routing'),

    //Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentPredicateCollections', new Map());
      this.set('currentPropositionOperators', new Map());
      this.set('currentCollectionOperator', 'OR');
      this.set('lastRunQuery', []);

      var currentPropositionOperators = this.get('currentPropositionOperators');
      var definitions = this.get('definitions');
      definitions.forEach(function (definition) {
        currentPropositionOperators.set(definition.get('label').toLowerCase(), 'OR');
      });
    },


    didReceiveAttrs: (0, _emberDiffAttrs.default)('page', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);
      if (!changedAttrs || changedAttrs.page) {
        this.fetchOrganizationsWithCompletePropositions();
      }
    }),

    //CP's
    organizations: reads('fetchOrganizations.lastSuccessful.value'),

    totalPages: computed('totalRecords', function () {
      var totalRecords = this.get('totalRecords');
      return Math.ceil(totalRecords / PAGE_LIMIT);
    }),

    //Actions
    actions: {
      removeAllPropositions: function removeAllPropositions(proposition) {
        var predicateCollections = this.get('currentPredicateCollections');
        var fieldLabel = get(proposition, 'label').toLowerCase();

        predicateCollections.delete(fieldLabel);

        this.notifyPropertyChange('currentPredicateCollections');

        this.fetchOrganizationsWithCompletePropositions();
      },
      removePropositions: function removePropositions(i, proposition) {
        var predicateCollections = this.get('currentPredicateCollections');
        var fieldLabel = proposition.label.toLowerCase();

        if (predicateCollections.has(fieldLabel)) {
          var predicateCollection = predicateCollections.get(fieldLabel);

          if (predicateCollection.length === 1) {
            this.send('removeAllPropositions', proposition);
          } else {
            predicateCollection.splice(i, 1);
          }
        }

        this.notifyPropertyChange('currentPredicateCollections');

        this.fetchOrganizationsWithCompletePropositions();
      },
      changePropositions: function changePropositions(i, proposition) {
        var predicateCollections = this.get('currentPredicateCollections');

        if (predicateCollections.has(proposition.label)) {
          var predicateCollection = predicateCollections.get(proposition.label);
          if (predicateCollection[i]) {
            predicateCollection[i] = proposition;
          } else {
            predicateCollection.push(proposition);
          }
        } else {
          predicateCollections.set(proposition.label, [proposition]);
        }

        this.notifyPropertyChange('currentPredicateCollections');

        this.fetchOrganizationsWithCompletePropositions();
      },
      changePropositionOperator: function changePropositionOperator(definitionName, operator) {
        var currentPropositionOperators = this.get('currentPropositionOperators');

        currentPropositionOperators.set(definitionName, operator);

        this.notifyPropertyChange('currentPropositionOperators');

        this.fetchOrganizationsWithCompletePropositions();
      },
      changeCollectionOperator: function changeCollectionOperator(operator) {
        this.set('currentCollectionOperator', operator);

        this.fetchOrganizationsWithCompletePropositions();
      }
    },

    //Tasks
    fetchOrganizations: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(queryToRun) {
      var router, results, totalResults, locale;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              router = this.get('router');
              _context.next = 3;
              return (0, _emberConcurrency.timeout)(300);

            case 3:
              _context.next = 5;
              return this.get('store').query('organization', queryToRun);

            case 5:
              results = _context.sent;

              if (!(!results.get('length') && this.get('page') !== 1)) {
                _context.next = 9;
                break;
              }

              _context.next = 9;
              return router.transitionTo(router.get('currentRouteName'), [], { page: 1 });

            case 9:
              totalResults = results.get('meta.total');
              locale = this.get('locale.userLocale.locale');


              this.set('totalRecords', totalResults);
              this.set('totalResults', new Intl.NumberFormat(locale).format(totalResults));

              return _context.abrupt('return', results);

            case 14:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    //Methods
    buildNewCollection: function buildNewCollection(predicateCollection) {
      var _this = this;

      var currentCollectionOperator = this.get('currentCollectionOperator');
      var currentPropositionOperators = this.get('currentPropositionOperators');
      var propositionCollection = [];
      var page = this.get('page');

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = predicateCollection.keys()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var key = _step.value;

          propositionCollection.push({
            proposition_operator: currentPropositionOperators.get(key),
            propositions: predicateCollection.get(key).map(function (predicate) {
              return {
                field: predicate.field,
                operator: predicate.operator,
                value: _this.getValueForValueType(predicate.value)
              };
            })
          });
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return {
        offset: PAGE_LIMIT * page - PAGE_LIMIT,
        limit: PAGE_LIMIT,
        predicates: {
          collection_operator: currentCollectionOperator,
          collections: propositionCollection
        }
      };
    },
    getValueForValueType: function getValueForValueType(value) {
      if (Array.isArray(value)) {
        return value.mapBy('value').join(',');
      } else if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && value !== null) {
        return value.value;
      } else {
        return value;
      }
    },
    fetchOrganizationsWithCompletePropositions: function fetchOrganizationsWithCompletePropositions() {
      var predicateCollections = this.get('currentPredicateCollections');
      var completePredicateCollections = new Map();
      var lastRunQuery = this.get('lastRunQuery');

      predicateCollections.forEach(function (value, key) {
        if (value.length) {
          var completedPredicatesInCollection = value.filter(function (predicate) {
            if (!predicate.value && predicate.value !== false) {
              return false;
            } else {
              return true;
            }
          });

          if (completedPredicatesInCollection.length) {
            completePredicateCollections.set(key, completedPredicatesInCollection);
          }
        }
      });

      var query = this.buildNewCollection(completePredicateCollections);

      if (!_npmLodash.default.isEqual(query, lastRunQuery)) {
        this.set('lastRunQuery', query);
        this.get('fetchOrganizations').perform(query);
      }
    }
  });
});