define('frontend-cp/helpers/ko-case-list-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Helper = Ember.Helper;
  var dasherize = Ember.String.dasherize;
  exports.default = Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        kind = _ref2[0],
        column = _ref2[1];

    switch (kind) {
      case 'componentName':
        return columnComponentName(column);
      case 'propertyName':
        return columnPropertyName(column);
    }
  });


  var CUSTOM_TYPE_COLUMNS = ['userAvatar', 'organizationAvatar', 'conversation', 'channel', 'slafirstreplytime', 'slaresolutiontime', 'slanextreplytime', 'slanextbreach', 'casestatusid', 'userComposite', 'orgComposite', 'convComposite'];

  var STRING_COLUMNS = {
    assigneeteamid: 'assignedTeam.title',
    brandid: 'brand.name',
    caseid: 'id',
    casepriorityid: 'priority.label',
    casetypeid: 'caseType.label',
    lastupdatedby: 'lastUpdatedBy.fullName',
    organizationid: 'requester.organization.name',
    rating: 'rating',
    satisfactionstatus: 'ratingStatus',
    slaversionid: 'slaVersion.title',
    fullname: 'fullName',
    email: 'primaryEmail.email',
    organizationName: 'organization.name',
    id: 'id',
    name: 'name'
  };

  var USER_COLUMNS = {
    assigneeagentid: 'assignedAgent',
    requesterid: 'requester'
  };

  var COMPOSITE_COLUMNS = {
    userComposite: 'user',
    orgComposite: 'organization'
  };

  var DATE_COLUMNS = {
    createdat: 'createdAt',
    lastagentrepliedat: 'lastReplyByAgentAt',
    lastcompletedat: 'lastCompletedAt',
    lastrepliedat: 'lastRepliedAt',
    lastrequesterrepliedat: 'lastReplyByRequesterAt',
    latestassigneeupdate: 'latestAssigneeUpdate',
    latestagentupdate: 'lastAgentActivityAt', // last_agent_activity_at || agent_updated_at
    updatedat: 'updatedAt',
    lastseenat: 'lastSeenAt',
    lastActivityAt: 'lastActivityAt'
  };

  function columnComponentName(column) {
    if (DATE_COLUMNS[column]) {
      return 'ko-cases-list/column/generic-date';
    } else if (STRING_COLUMNS[column]) {
      return 'ko-cases-list/column/generic-string';
    } else if (USER_COLUMNS[column]) {
      return 'ko-cases-list/column/user';
    } else if (column.indexOf('case_field') === 0) {
      return 'ko-cases-list/column/custom-field';
    } else if (CUSTOM_TYPE_COLUMNS.indexOf(column) !== -1) {
      return 'ko-cases-list/column/' + dasherize(column);
    }
  }

  function columnPropertyName(column) {
    return STRING_COLUMNS[column] || DATE_COLUMNS[column] || USER_COLUMNS[column] || COMPOSITE_COLUMNS[column];
  }
});