define('frontend-cp/session/agent/insights/reporting/custom-reports/edit/route', ['exports', 'ember-service/inject', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _inject, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend((0, _route.default)('report'), {
    launchDarkly: (0, _inject.default)(),

    store: service(),
    plan: service(),

    model: function model(_ref) {
      var report_id = _ref.report_id;

      return RSVP.hash({
        report: this.get('store').findRecord('report', report_id),
        definitions: this.get('store').query('definition', { type: 'report' })
      });
    },
    beforeModel: function beforeModel(transition) {
      var launchDarkly = this.get('launchDarkly');
      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('release-restrict-insights')) {
        if (!this.get('plan').has('custom_reporting') && !launchDarkly.get('feature-restrict-custom-reporting-insights')) {
          this.transitionTo('session.agent.insights.general.cases');
        }
      }
    },
    setupController: function setupController(controller, properties) {
      controller.setProperties(properties);
      controller.initEdits();
    }
  });
});