define('frontend-cp/components/ko-insights/loader/cases/metrics/component', ['exports', 'npm:lodash', 'frontend-cp/lib/computed/find-by', 'frontend-cp/lib/computed/humanize-seconds', 'frontend-cp/lib/object-difference', 'ember-diff-attrs'], function (exports, _npmLodash, _findBy, _humanizeSeconds, _objectDifference, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectProxy = Ember.ObjectProxy;
  var PromiseProxyMixin = Ember.PromiseProxyMixin;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend({
    tagName: '',
    insights: service(),

    // Params
    metricsQueryParams: null,

    /** @private **/
    updatedQueryParams: null,

    didReceiveAttrs: (0, _emberDiffAttrs.default)('metricsQueryParams', function (changedAttrs) {
      var oldValues = changedAttrs && changedAttrs.metricsQueryParams ? changedAttrs.metricsQueryParams[0].data : {};
      var newValues = this.get('metricsQueryParams.data');

      Reflect.deleteProperty(oldValues, 'include');
      Reflect.deleteProperty(newValues, 'include');

      var differs = (0, _objectDifference.default)(oldValues, newValues);
      var allowedParameters = ['agent_id', 'team_id', 'start_at', 'end_at', 'previous_start_at', 'previous_end_at', 'trial'];

      // if one of the allowed parameters changed, proceed with request
      if (_npmLodash.default.intersection(differs, allowedParameters).length) {
        this.set('updatedQueryParams', { data: newValues });
      }
    }),

    request: computed('updatedQueryParams', function () {
      var _this = this;

      var insights = this.get('insights');
      var adapter = getOwner(this).lookup('adapter:application');
      var endpoint = insights.metricsEndpoint();

      return ObjectProxy.extend(PromiseProxyMixin).create({
        promise: adapter.ajax(endpoint, 'GET', this.get('updatedQueryParams')).then(function (data) {
          if (_this.isDestroying || _this.isDestroyed) {
            return;
          }

          _this.set('metrics', data.data);
        })
      });
    }),

    metrics: null,

    totalAssignedCases: (0, _findBy.default)('metrics.metric', 'name', 'total_assigned'),
    totalCreatedCases: (0, _findBy.default)('metrics.metric', 'name', 'total_created'),
    customersHelped: (0, _findBy.default)('metrics.metric', 'name', 'customers_helped'),
    casesTouched: (0, _findBy.default)('metrics.metric', 'name', 'cases_touched'),
    totalPublicReplies: (0, _findBy.default)('metrics.metric', 'name', 'total_public_replies'),
    averageFirstResponseTime: (0, _findBy.default)('metrics.metric', 'name', 'average_first_response_time'),
    averageRepliesToResolution: (0, _findBy.default)('metrics.metric', 'name', 'average_replies_to_resolution'),
    percentageFirstContactResolved: (0, _findBy.default)('metrics.metric', 'name', 'percentage_first_contact_resolved'),
    averageTeamChanges: (0, _findBy.default)('metrics.metric', 'name', 'average_team_changes'),
    averageAssigneeChanges: (0, _findBy.default)('metrics.metric', 'name', 'average_assignee_changes'),
    averageFirstAssignmentTime: (0, _findBy.default)('metrics.metric', 'name', 'average_first_assignment_time'),

    averageFirstResponseTimeValue: (0, _humanizeSeconds.default)('averageFirstResponseTime.value'),
    averageFirstResponseTimeValuePrevious: (0, _humanizeSeconds.default)('averageFirstResponseTime.previous'),
    averageFirstAssignmentTimeValue: (0, _humanizeSeconds.default)('averageFirstAssignmentTime.value'),
    averageFirstAssignmentTimePrevious: (0, _humanizeSeconds.default)('averageFirstAssignmentTime.value')
  });
});