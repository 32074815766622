define('frontend-cp/components/ko-cases-list/column/slafirstreplytime/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    model: null,

    // CPs
    metric: computed('model.slaMetrics.@each.metricType', function () {
      return this.get('model.slaMetrics').findBy('metricType', 'FIRST_REPLY_TIME');
    })
  });
});