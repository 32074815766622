define('frontend-cp/components/ko-admin/case-forms/edit/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Params
    caseForm: null,
    caseFields: null,
    schema: null,
    editedCaseForm: null,
    title: null,
    onCancel: function onCancel() {},
    onDelete: function onDelete() {},
    onSuccess: function onSuccess() {},

    // Services
    confirmation: service(),
    virtualModel: service(),
    locale: service(),

    canBeDeleted: computed('caseForm.isNew', 'caseForm.isDefault', function () {
      return !(this.get('caseForm.isNew') || this.get('caseForm.isDefault'));
    }),

    brandsWithPlaceholder: computed('brands.[]', function () {
      return [''].concat(this.get('brands').toArray());
    }),

    customerTitleLocale: computed('editedCaseForm.customerTitle', 'editedCaseForm.customerTitles.[]', 'locale.accountDefaultLocaleCode', function () {
      var customerTitles = this.get('editedCaseForm.customerTitles');
      return customerTitles.findBy('locale', this.get('locale.accountDefaultLocaleCode'));
    }),

    descriptionLocale: computed('editedCaseForm.description', 'editedCaseForm.descriptions.[]', 'locale.accountDefaultLocaleCode', function () {
      var descriptions = this.get('editedCaseForm.descriptions');
      return descriptions.findBy('locale', this.get('locale.accountDefaultLocaleCode'));
    }),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var caseForm;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              caseForm = this.get('caseForm');
              _context.next = 3;
              return this.get('virtualModel').save(this.get('caseForm'), this.get('editedCaseForm'), this.get('schema'));

            case 3:

              caseForm.get('customerTitles').filter(function (customerTitle) {
                return customerTitle.get('isNew');
              }).forEach(function (customerTitle) {
                return customerTitle.unloadRecord();
              });
              caseForm.get('descriptions').filter(function (description) {
                return description.get('isNew');
              }).forEach(function (description) {
                return description.unloadRecord();
              });

              this.get('onSuccess')();

            case 6:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    actions: {
      cancel: function cancel() {
        this.get('onCancel')();
      },
      addCaseFieldToForm: function addCaseFieldToForm(caseField) {
        this.get('editedCaseForm.fields').pushObject(caseField);
      },
      removeCaseFieldFromForm: function removeCaseFieldFromForm(caseField) {
        this.get('editedCaseForm.fields').removeObject(caseField);
      },
      updateFields: function updateFields(fields) {
        this.set('editedCaseForm.fields', fields);
      },
      setCustomerTitle: function setCustomerTitle(translation) {
        var customerTitleLocale = this.get('customerTitleLocale');

        if (customerTitleLocale) {
          customerTitleLocale.set('translation', translation);
        }
      },
      setDescription: function setDescription(translation) {
        var descriptionLocale = this.get('descriptionLocale');

        if (descriptionLocale) {
          descriptionLocale.set('translation', translation);
        }
      },
      deleteForm: function deleteForm() {
        var _this = this;

        return this.get('confirmation').confirm({
          intlConfirmLabel: 'generic.confirm.delete_button',
          intlConfirmationBody: 'admin.caseforms.confirm_delete.body',
          intlConfirmationHeader: 'admin.caseforms.confirm_delete.title'
        }).then(function () {
          var formId = _this.get('caseForm.id');
          return _this.get('caseForm').destroyRecord().then(function () {
            _this.get('onDelete')(formId);
          });
        });
      }
    }
  });
});