define("frontend-cp/session/agent/cases/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "index": "session_agent_cases_index__index_1qjm74",
    "sidebar": "session_agent_cases_index__sidebar_1qjm74",
    "content": "session_agent_cases_index__content_1qjm74",
    "pagination": "session_agent_cases_index__pagination_1qjm74"
  };
});