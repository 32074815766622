define("frontend-cp/components/ko-timeline-2/list/item/created-at/email-modal/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "wrapper": "ko-timeline-2_list_item_created-at_email-modal__wrapper_1sq9yl",
    "error-description": "ko-timeline-2_list_item_created-at_email-modal__error-description_1sq9yl",
    "email-view": "ko-timeline-2_list_item_created-at_email-modal__email-view_1sq9yl",
    "email-header": "ko-timeline-2_list_item_created-at_email-modal__email-header_1sq9yl",
    "email-details": "ko-timeline-2_list_item_created-at_email-modal__email-details_1sq9yl",
    "email-content": "ko-timeline-2_list_item_created-at_email-modal__email-content_1sq9yl",
    "fadeIn": "ko-timeline-2_list_item_created-at_email-modal__fadeIn_1sq9yl",
    "header-heading": "ko-timeline-2_list_item_created-at_email-modal__header-heading_1sq9yl",
    "header-header": "ko-timeline-2_list_item_created-at_email-modal__header-header_1sq9yl"
  };
});