define('frontend-cp/components/side-conversations-panel/message-timeline/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    messages: [],

    messagesWithRecipients: computed('messages.@each.message.recipients', function () {
      return this.get('messages').map(function (messageItem) {
        var toRecipients = messageItem.message.recipients.filter(function (recipient) {
          return recipient.type === 'TO';
        });
        var ccRecipients = messageItem.message.recipients.filter(function (recipient) {
          return recipient.type === 'CC';
        });
        var newMessageItem = Object.assign({}, messageItem);
        newMessageItem.toRecipients = toRecipients;
        newMessageItem.ccRecipients = ccRecipients;
        return newMessageItem;
      });
    })
  });
});