define("frontend-cp/trial-data/insights/cases/completed", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "status": 200,
    "data": {
      "start_at": "2016-05-28T00:00:00+00:00",
      "end_at": "2016-06-27T23:59:59+00:00",
      "previous_start_at": "2016-04-16T00:00:00+00:00",
      "previous_end_at": "2016-05-16T23:59:59+00:00",
      "interval": "DAY",
      "interval_count": 31,
      "series": {
        "name": "total_completed",
        "data": [9, 12, 179, 208, 195, 179, 204, 11, 6, 175, 207, 173, 178, 176, 9, 13, 168, 174, 157, 222, 151, 3, 5, 195, 249, 201, 233, 228, 8, 16, 49],
        "previous": [13, 1, 168, 160, 105, 120, 95, 5, 2, 139, 115, 107, 138, 88, 3, 0, 122, 123, 103, 81, 72, 4, 2, 105, 130, 86, 172, 133, 3, 4, 107],
        "resource_type": "report_series_comparison"
      },
      "metric": {
        "name": "total_completed",
        "value": 3993,
        "delta_percent": 59.338,
        "previous": 2506,
        "resource_type": "report_value_comparison"
      },
      "resource_type": "report_cases_completion"
    },
    "resources": [],
    "resource": "report_cases_completion"
  };
});