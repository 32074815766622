define('frontend-cp/session/admin/apps/api/oauth-apps/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    i18n: service(),
    confirmation: service(),

    tabs: computed(function () {
      return [{
        label: this.get('i18n').t('admin.apps.api.tabs.api'),
        routeName: 'session.admin.apps.api.index',
        dynamicSegments: []
      }, {
        label: this.get('i18n').t('admin.apps.api.tabs.oauth_apps'),
        routeName: 'session.admin.apps.api.oauth-apps',
        dynamicSegments: []
      }];
    }),

    actions: {
      transitionToNewOAuthAppRoute: function transitionToNewOAuthAppRoute() {
        this.transitionToRoute('session.admin.apps.api.oauth-apps.new');
      },
      editOAuthApp: function editOAuthApp(app) {
        this.transitionToRoute('session.admin.apps.api.oauth-apps.edit', app.get('id'));
      },
      deleteOAuthApp: function deleteOAuthApp(app, e) {
        e.stopPropagation();

        return this.get('confirmation').confirm({
          intlConfirmLabel: this.get('i18n').t('admin.oauthapps.delete.button'),
          intlConfirmationBody: this.get('i18n').t('admin.oauthapps.delete.message', { name: app.get('name') }),
          isIntl: true
        }).then(function () {
          return app.destroyRecord();
        });
      }
    }
  });
});