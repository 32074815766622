define("frontend-cp/components/ko-cases-list/column/user-composite/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "wrapper": "ko-cases-list_column_user-composite__wrapper_dfquce",
    "avatar": "ko-cases-list_column_user-composite__avatar_dfquce",
    "avatar-loading": "ko-cases-list_column_user-composite__avatar-loading_dfquce",
    "title": "ko-cases-list_column_user-composite__title_dfquce",
    "role": "ko-cases-list_column_user-composite__role_dfquce",
    "separator": "ko-cases-list_column_user-composite__separator_dfquce",
    "loading-separator": "ko-cases-list_column_user-composite__loading-separator_dfquce",
    "company": "ko-cases-list_column_user-composite__company_dfquce",
    "icon": "ko-cases-list_column_user-composite__icon_dfquce",
    "identity": "ko-cases-list_column_user-composite__identity_dfquce",
    "identityMain": "ko-cases-list_column_user-composite__identityMain_dfquce"
  };
});