define("frontend-cp/components/ko-admin/apps/endpoints/form/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "select": "ko-admin_apps_endpoints_form__select_ncqmvx",
    "checkbox": "ko-admin_apps_endpoints_form__checkbox_ncqmvx"
  };
});