define('frontend-cp/components/ko-admin/overview/index/component', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var computed = Ember.computed;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),


    // Attributes
    cards: [],
    subscription: {},
    invoices: [],

    // services
    plan: service(),
    metrics: service(),

    // Computed Properties
    defaultCard: computed('cards', function () {
      return this.get('cards').findBy('isDefault', true);
    }),

    subscribedPlanName: computed('subscription.rateplans', function () {
      return this.get('subscription.rateplans.firstObject.productRateplan.name');
    }),

    accountBalance: readOnly('subscription.account.balance'),
    expiryAt: readOnly('plan.expiryAt'),
    accountCurrency: readOnly('subscription.account.billing.currency'),

    actions: {
      openSupportConversation: function openSupportConversation() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('ops-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'Account overview - Cancel subscription initiate',
            category: 'Account'
          });
        }

        window.open('https://support.kayako.com/conversation/new/3', 'cancel-contact-support', { noopener: true });
      }
    }

  });
});