define("frontend-cp/components/ko-notification-center/preferences/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "description": "ko-notification-center_preferences__description_ff2hk5",
    "heading": "ko-notification-center_preferences__heading_ff2hk5",
    "devices": "ko-notification-center_preferences__devices_ff2hk5",
    "device": "ko-notification-center_preferences__device_ff2hk5",
    "vertical-separator": "ko-notification-center_preferences__vertical-separator_ff2hk5",
    "device-text": "ko-notification-center_preferences__device-text_ff2hk5",
    "device-label": "ko-notification-center_preferences__device-label_ff2hk5",
    "loader": "ko-notification-center_preferences__loader_ff2hk5",
    "list": "ko-notification-center_preferences__list_ff2hk5",
    "table": "ko-notification-center_preferences__table_ff2hk5",
    "list-row": "ko-notification-center_preferences__list-row_ff2hk5",
    "list-head": "ko-notification-center_preferences__list-head_ff2hk5",
    "list-data": "ko-notification-center_preferences__list-data_ff2hk5",
    "list-header": "ko-notification-center_preferences__list-header_ff2hk5",
    "buttons": "ko-notification-center_preferences__buttons_ff2hk5"
  };
});