define("frontend-cp/components/ko-time-tracking/entry/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "list-item": "ko-time-tracking_entry__list-item_1xi5ch",
    "is-editable": "ko-time-tracking_entry__is-editable_1xi5ch",
    "is-editing": "ko-time-tracking_entry__is-editing_1xi5ch",
    "controls": "ko-time-tracking_entry__controls_1xi5ch",
    "time": "ko-time-tracking_entry__time_1xi5ch",
    "is-disabled": "ko-time-tracking_entry__is-disabled_1xi5ch",
    "avatar": "ko-time-tracking_entry__avatar_1xi5ch",
    "delete": "ko-time-tracking_entry__delete_1xi5ch",
    "edit-button": "ko-time-tracking_entry__edit-button_1xi5ch",
    "agent": "ko-time-tracking_entry__agent_1xi5ch",
    "inline-icon": "ko-time-tracking_entry__inline-icon_1xi5ch"
  };
});