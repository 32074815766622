define('frontend-cp/components/ko-admin/fields/select-type/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Services
    customFieldsTypes: service('custom-fields/types')
  });
});