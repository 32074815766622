define('frontend-cp/adapters/app-installation', ['exports', 'frontend-cp/adapters/apps-adapter'], function (exports, _appsAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _appsAdapter.default.extend({
    install: function install(app) {
      var name = app.get('name');

      var _getProperties = this.getProperties('host', 'namespace'),
          host = _getProperties.host,
          namespace = _getProperties.namespace;

      var url = host + '/' + namespace + '/app-installations';
      return this.ajax(url, 'POST', { data: { name: name } });
    },
    uninstall: function uninstall(installation) {
      var name = installation.get('app.name');

      var _getProperties2 = this.getProperties('host', 'namespace'),
          host = _getProperties2.host,
          namespace = _getProperties2.namespace;

      var url = host + '/' + namespace + '/app-installations';
      return this.ajax(url, 'DELETE', { data: { name: name } });
    }
  });
});