define('frontend-cp/session/admin/customizations/privacy/new/controller', ['exports', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/customizations/privacy/schema'], function (exports, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    notification: service(),
    i18n: service(),
    virtualModel: service(),
    schema: _schema.default,
    // Methods
    initEdits: function initEdits() {
      this.set('editedPrivacy', this.get('virtualModel').makeSnapshot(this.get('privacy'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('privacy'), this.get('editedPrivacy'), _schema.default);
    },


    // Actions
    actions: {
      created: function created() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        this.transitionToRoute('session.admin.customizations.privacy.index');
      },
      canceled: function canceled() {
        this.transitionToRoute('session.admin.customizations.privacy.index');
      }
    }
  });
});