define('frontend-cp/components/ko-admin/macros/edit/action-handlers/priority', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    serialize: function serialize(action, macro, store) {
      this.reset(macro);

      if (action.option) {
        if (action.option === 'CHANGE') {
          if (action.value) {
            var priority = store.peekRecord('case-priority', action.value);
            macro.set('priority', priority);
            macro.set('priorityAction', null);
          }
        } else {
          macro.set('priority', null);
          macro.set('priorityAction', action.option + '_ONE_LEVEL');
        }
      }

      return macro;
    },
    deserialize: function deserialize(macro, actions) {
      var _macro$getProperties = macro.getProperties('priority', 'priorityAction'),
          priority = _macro$getProperties.priority,
          priorityAction = _macro$getProperties.priorityAction;

      if (priority) {
        actions.push({
          name: 'priority',
          option: 'CHANGE',
          value: priority.get('id').toString()
        });
      } else if (priorityAction) {
        actions.push({
          name: 'priority',
          option: priorityAction.match(/(.*)_ONE_LEVEL/)[1],
          value: null
        });
      }

      return actions;
    },
    reset: function reset(macro) {
      macro.set('priority', null);
      macro.set('priorityAction', null);
    }
  };
});