define('frontend-cp/models/atlasai', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    category: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    isProtected: (0, _attr.default)('boolean'),
    value: (0, _attr.default)('string')
  });
});