define('frontend-cp/components/ko-select/typeahead-trigger/component', ['exports', 'frontend-cp/lib/keycodes'], function (exports, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var observer = Ember.observer;
  var Component = Ember.Component;
  var run = Ember.run;
  var isBlank = Ember.isBlank;
  exports.default = Component.extend({
    // HTML
    tagName: '',

    textValue: computed('select.selected', 'searchField', {
      get: function get() {
        return this.get('select.selected.' + this.get('searchField'));
      },
      set: function set(_, v) {
        return v;
      }
    }),

    // Lifecycle hooks
    selectObserver: observer('select.{loading,lastSearchedText}', function () {
      var _getProperties = this.getProperties('oldIsOpen', 'oldLoading', 'oldLastSearchedText'),
          oldIsOpen = _getProperties.oldIsOpen,
          oldLoading = _getProperties.oldLoading,
          oldLastSearchedText = _getProperties.oldLastSearchedText;

      var select = this.get('select');
      var isOpen = this.get('select.isOpen');
      var results = this.get('select.results');
      var loading = this.get('select.loading');
      var searchText = this.get('select.searchText');
      var lastSearchedText = this.get('select.lastSearchedText');
      if (oldIsOpen && !isOpen && !loading && searchText) {
        this.set('textValue', this.get('select.selected.' + this.get('searchField')));
      }

      if (lastSearchedText !== oldLastSearchedText) {
        if (isBlank(lastSearchedText)) {
          run.schedule('actions', null, select.actions.close, null, true);
        } else {
          run.schedule('actions', null, select.actions.open);
        }
      } else if (!isBlank(lastSearchedText) && results.length === 0 && loading) {
        run.schedule('actions', null, select.actions.close, null, true);
      } else if (oldLoading && !loading && results.length > 0) {
        /*
         * Another edge case for re-searching for the same thing.
         * If we finished searching and there is something to show, always open the
         * select box
         */
        run.schedule('actions', null, select.actions.open);
      }
      this.setProperties({ oldIsOpen: isOpen, oldLoading: loading, oldLastSearchedText: lastSearchedText });
    }),

    // Actions
    actions: {
      stopPropagation: function stopPropagation(e) {
        e.stopPropagation();
      },
      handleKeydown: function handleKeydown(e) {
        var isLetter = e.keyCode >= _keycodes.zero && e.keyCode <= _keycodes.z || e.keyCode === _keycodes.space; // Keys 0-9, a-z or SPACE
        var isSpecialKeyWhileClosed = !isLetter && !this.get('select.isOpen') && [_keycodes.enter, _keycodes.escape, _keycodes.up, _keycodes.down].indexOf(e.keyCode) > -1;
        if (isLetter || isSpecialKeyWhileClosed) {
          e.stopPropagation();
        }
      },
      handleInput: function handleInput(e) {
        // run the search action and update the value of the text box whenever we type
        this.get('select.actions.search')(e.target.value);
        this.set('textValue', e.target.value);
      }
    }
  });
});