define('frontend-cp/lib/fields-schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var localeField = (0, _virtualModel.model)('locale-field', {
    id: (0, _virtualModel.attr)(),
    locale: (0, _virtualModel.attr)(),
    translation: (0, _virtualModel.attr)({ nonStrictMatching: true })
  });

  exports.default = (0, _virtualModel.model)('case-field', {
    isEnabled: (0, _virtualModel.attr)(),
    title: (0, _virtualModel.attr)(),
    isRequiredForAgents: (0, _virtualModel.attr)(),
    isRequiredOnResolution: (0, _virtualModel.attr)(),
    isVisibleToCustomers: (0, _virtualModel.attr)(),
    isCustomerEditable: (0, _virtualModel.attr)(),
    isRequiredForCustomers: (0, _virtualModel.attr)(),
    regularExpression: (0, _virtualModel.attr)(),
    options: (0, _virtualModel.many)((0, _virtualModel.model)('field-option', {
      id: (0, _virtualModel.attr)(),
      fielduuid: (0, _virtualModel.attr)(),
      values: (0, _virtualModel.many)(localeField),
      sortOrder: (0, _virtualModel.attr)()
    })),
    customerTitles: (0, _virtualModel.many)(localeField),
    descriptions: (0, _virtualModel.many)(localeField)
  });
});