define('frontend-cp/services/realtime-push', ['exports', 'npm:kayako-web-push', 'ember-inject-script', 'npm:platform', 'npm:uuid'], function (exports, _npmKayakoWebPush, _emberInjectScript, _npmPlatform, _npmUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Service = Ember.Service;
  var service = Ember.inject.service;
  var hasSupport = _npmKayakoWebPush.default.hasSupport,
      getPermission = _npmKayakoWebPush.default.getPermission,
      registerServiceWorker = _npmKayakoWebPush.default.registerServiceWorker,
      registerDevice = _npmKayakoWebPush.default.registerDevice;
  exports.default = Service.extend({
    store: service(),
    session: service(),
    localStore: service(),
    errorHandler: service(),
    devices: [],

    /**
     * Returns a boolean indicating if there is a uuid
     * saved in localstorage
     *
     * @method hasPushUuid
     *
     * @return {Boolean}
     */
    hasPushUuid: function hasPushUuid() {
      return !!this.get('localStore').getItem('push', 'uuid', { persist: true });
    },


    /**
     * Returns the existing uuid or creates a new one
     * and saves it to localstorage
     *
     * @method getPushUuid
     *
     * @return {String}
     */
    getPushUuid: function getPushUuid() {
      if (!this.hasPushUuid()) {
        var pushUuid = _npmUuid.default.v4();
        this.get('localStore').setItem('push', 'uuid', pushUuid, { persist: true });
        return pushUuid;
      }
      return this.get('localStore').getItem('push', 'uuid', { persist: true });
    },


    /**
     * Get public key from the server
     *
     * @method getPublicKey
     * @async
     *
     * @return {String}
     */
    getPublicKey: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var credentials;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.get('store').queryRecord('credential', {});

              case 2:
                credentials = _context.sent;
                return _context.abrupt('return', credentials.get('webpushPublicKey'));

              case 4:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function getPublicKey() {
        return _ref.apply(this, arguments);
      }

      return getPublicKey;
    }(),


    /**
     * Loads devices from the API
     *
     * @method loadDevices
     *
     * @return {void}
     */
    loadDevices: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        var devices;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return this.get('store').findAll('device');

              case 2:
                devices = _context2.sent;

                this.set('devices', devices);

              case 4:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function loadDevices() {
        return _ref2.apply(this, arguments);
      }

      return loadDevices;
    }(),


    /**
     * Returns device for given uuid.
     *
     * @method findRegisteredDevice
     *
     * @param  {String}            forUuid
     *
     * @return {Boolean}
     */
    findRegisteredDevice: function findRegisteredDevice(forUuid) {
      return this.get('devices').findBy('fingerprintUuid', forUuid);
    },


    /**
     * Register the user device with the server. This needs to
     * be done only when we create a new subscription with
     * the browser, otherwise user browser will be registered
     * twice and they will get multiple notifications.
     *
     * @method registerDeviceWithServer
     * @async
     *
     * @param  {Object}                 payload
     *
     * @return {Model}
     */
    registerDeviceWithServer: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(payload) {
        var device, deviceProperties;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                device = this.get('store').createRecord('device', {
                  deviceType: 'Browser',
                  fingerprintUuid: this.getPushUuid()
                });
                deviceProperties = this.get('store').createRecord('device-property', {
                  lastIpAddress: this.get('session.ipAddress'),
                  subscriptionInfo: JSON.stringify(payload),
                  osVersion: _npmPlatform.default.os.family + ' ' + _npmPlatform.default.os.version,
                  deviceManufacturer: _npmPlatform.default.manufacturer
                });


                device.set('deviceProperties', deviceProperties);
                return _context3.abrupt('return', this.get('errorHandler').disableWhile(function () {
                  return device.save();
                }));

              case 4:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function registerDeviceWithServer(_x) {
        return _ref3.apply(this, arguments);
      }

      return registerDeviceWithServer;
    }(),


    /**
     * Register the user device with the browser and
     * on the server
     *
     * @method registerUserDevice
     * @async
     *
     * @return {void}
     */
    registerUserDevice: function () {
      var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4() {
        var hasRegisteredDevice, kayakoPushService, registration, publicKey, notificationsPermission, subscription;
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                hasRegisteredDevice = false;

                /**
                 * Browser doesn't support web push
                 */

                if (hasSupport()) {
                  _context4.next = 3;
                  break;
                }

                return _context4.abrupt('return');

              case 3:
                _context4.next = 5;
                return (0, _emberInjectScript.default)('/agent/kayako-push-service.js');

              case 5:

                /**
                 * Register the service to be used for listening for push
                 * notifications.
                 *
                 * @type {Object}
                 */
                kayakoPushService = window.kayakoPushService;
                _context4.next = 8;
                return kayakoPushService.register(registerServiceWorker, '/agent/service-worker.js');

              case 8:
                registration = _context4.sent;

                if (!this.hasPushUuid()) {
                  _context4.next = 13;
                  break;
                }

                _context4.next = 12;
                return this.loadDevices();

              case 12:
                hasRegisteredDevice = !!this.findRegisteredDevice(this.getPushUuid());

              case 13:
                if (!hasRegisteredDevice) {
                  _context4.next = 15;
                  break;
                }

                return _context4.abrupt('return');

              case 15:
                _context4.next = 17;
                return this.getPublicKey();

              case 17:
                publicKey = _context4.sent;

                if (publicKey) {
                  _context4.next = 20;
                  break;
                }

                return _context4.abrupt('return');

              case 20:
                _context4.next = 22;
                return getPermission();

              case 22:
                notificationsPermission = _context4.sent;

                if (!(notificationsPermission !== 'granted')) {
                  _context4.next = 25;
                  break;
                }

                return _context4.abrupt('return');

              case 25:
                _context4.next = 27;
                return registerDevice(publicKey, registration);

              case 27:
                subscription = _context4.sent;
                _context4.next = 30;
                return this.registerDeviceWithServer(subscription);

              case 30:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function registerUserDevice() {
        return _ref4.apply(this, arguments);
      }

      return registerUserDevice;
    }()
  });
});