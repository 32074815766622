define('frontend-cp/components/ko-user-content/field/locale-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Params
    user: null,
    isEdited: false,
    isErrored: false,
    isKREEdited: false,
    onChangeLocale: null,
    locale: null,
    isDisabled: false,

    store: service(),

    tagName: '',

    locales: computed(function () {
      return this.get('store').peekAll('locale').filterBy('isPublic');
    })

  });
});