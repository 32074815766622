define('frontend-cp/serializers/search-result', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'resource_url',

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.resource = 'search_result';
      payload.data.forEach(function (result) {
        result.resource_type = 'search_result';
        result.result_data = result.data;
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});