define("frontend-cp/components/ko-case-content/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "root": "ko-case-content__root_11x6m5",
    "id": "ko-case-content__id_11x6m5",
    "id-hash": "ko-case-content__id-hash_11x6m5",
    "sla": "ko-case-content__sla_11x6m5",
    "sla-bar": "ko-case-content__sla-bar_11x6m5",
    "sla-bar--active": "ko-case-content__sla-bar--active_11x6m5",
    "sla-bar--paused": "ko-case-content__sla-bar--paused_11x6m5",
    "sla-bar--bad": "ko-case-content__sla-bar--bad_11x6m5",
    "sla-bar--completed": "ko-case-content__sla-bar--completed_11x6m5",
    "sla-bar--completedAndBreached": "ko-case-content__sla-bar--completedAndBreached_11x6m5",
    "sla-progress": "ko-case-content__sla-progress_11x6m5",
    "show-shimmer": "ko-case-content__show-shimmer_11x6m5",
    "shimmer": "ko-case-content__shimmer_11x6m5",
    "sla-bar--breached": "ko-case-content__sla-bar--breached_11x6m5",
    "sla-icon": "ko-case-content__sla-icon_11x6m5",
    "sla-icon--active": "ko-case-content__sla-icon--active_11x6m5",
    "sla-icon--paused": "ko-case-content__sla-icon--paused_11x6m5",
    "sla-icon--completed": "ko-case-content__sla-icon--completed_11x6m5",
    "sla-icon--bad": "ko-case-content__sla-icon--bad_11x6m5",
    "sla-icon--completedAndBreached": "ko-case-content__sla-icon--completedAndBreached_11x6m5",
    "sla-text": "ko-case-content__sla-text_11x6m5",
    "minus": "ko-case-content__minus_11x6m5",
    "sla-dropdown": "ko-case-content__sla-dropdown_11x6m5",
    "icon": "ko-case-content__icon_11x6m5",
    "complete": "ko-case-content__complete_11x6m5 ko-case-content__icon_11x6m5",
    "trash": "ko-case-content__trash_11x6m5 ko-case-content__icon_11x6m5",
    "side-conversations": "ko-case-content__side-conversations_11x6m5 ko-case-content__icon_11x6m5",
    "selected": "ko-case-content__selected_11x6m5",
    "brand-menu-text": "ko-case-content__brand-menu-text_11x6m5",
    "editor-footer-left": "ko-case-content__editor-footer-left_11x6m5",
    "editor-footer-right": "ko-case-content__editor-footer-right_11x6m5",
    "replyOptions": "ko-case-content__replyOptions_11x6m5",
    "replyOptionDescription": "ko-case-content__replyOptionDescription_11x6m5",
    "charLimit": "ko-case-content__charLimit_11x6m5",
    "charLimitNegative": "ko-case-content__charLimitNegative_11x6m5",
    "editorWithError": "ko-case-content__editorWithError_11x6m5",
    "itemContainer": "ko-case-content__itemContainer_11x6m5",
    "item-wrap": "ko-case-content__item-wrap_11x6m5",
    "dropdownContent": "ko-case-content__dropdownContent_11x6m5",
    "timeline-header": "ko-case-content__timeline-header_11x6m5",
    "timeline-header-image": "ko-case-content__timeline-header-image_11x6m5",
    "timeline-header-body": "ko-case-content__timeline-header-body_11x6m5",
    "timeline-header-title": "ko-case-content__timeline-header-title_11x6m5",
    "timeline-header-subtitle": "ko-case-content__timeline-header-subtitle_11x6m5",
    "submit-properties": "ko-case-content__submit-properties_11x6m5",
    "submitting": "ko-case-content__submitting_11x6m5",
    "show-submit": "ko-case-content__show-submit_11x6m5",
    "or-text": "ko-case-content__or-text_11x6m5",
    "cancel-property-changes": "ko-case-content__cancel-property-changes_11x6m5",
    "text-editor-loader": "ko-case-content__text-editor-loader_11x6m5",
    "assign-to-me-trigger": "ko-case-content__assign-to-me-trigger_11x6m5",
    "assign-to-me-container": "ko-case-content__assign-to-me-container_11x6m5",
    "assign-to-me-options": "ko-case-content__assign-to-me-options_11x6m5",
    "send-and-set-trigger": "ko-case-content__send-and-set-trigger_11x6m5 ko-power-select-triggers_chevron__trigger_71zyep",
    "note-trigger": "ko-case-content__note-trigger_11x6m5",
    "send-and-set-options": "ko-case-content__send-and-set-options_11x6m5 _dropdown_jvqwfg",
    "typing-area": "ko-case-content__typing-area_11x6m5",
    "in-reply-to": "ko-case-content__in-reply-to_11x6m5",
    "in-reply-to-text": "ko-case-content__in-reply-to-text_11x6m5",
    "in-reply-to-header": "ko-case-content__in-reply-to-header_11x6m5",
    "in-reply-to-message": "ko-case-content__in-reply-to-message_11x6m5",
    "in-reply-to-user": "ko-case-content__in-reply-to-user_11x6m5",
    "in-reply-to-remove": "ko-case-content__in-reply-to-remove_11x6m5",
    "in-reply-to-indicator": "ko-case-content__in-reply-to-indicator_11x6m5",
    "in-reply-to-loader": "ko-case-content__in-reply-to-loader_11x6m5",
    "cc-component": "ko-case-content__cc-component_11x6m5",
    "wrap-ccs": "ko-case-content__wrap-ccs_11x6m5",
    "note-destination-no-send-button": "ko-case-content__note-destination-no-send-button_11x6m5",
    "pill-container": "ko-case-content__pill-container_11x6m5",
    "pill-message": "ko-case-content__pill-message_11x6m5",
    "arrow": "ko-case-content__arrow_11x6m5",
    "arrow-up": "ko-case-content__arrow-up_11x6m5"
  };
});