define("frontend-cp/session/agent/insights/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "index": "session_agent_insights__index_1uzgrs",
    "content": "session_agent_insights__content_1uzgrs"
  };
});