define('frontend-cp/session/errors/not-found/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    session: service(),
    errorHandler: service(),
    locale: service(),

    beforeModel: function beforeModel() {
      var _this = this;

      // we ignore errors before locale strings are loaded
      return this.get('errorHandler').disableWhile(function () {
        return _this.get('locale').setup();
      });
    },
    deactivate: function deactivate() {
      this.controller.set('wakingUp', false);
      this.controller.set('awakened', false);
    },
    model: function model() {
      return this.get('session').getSession().catch(function () {});
    }
  });
});