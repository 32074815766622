define('frontend-cp/components/kre-channel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    socket: service(),

    name: null,
    params: {},
    events: null,

    'on-join': function onJoin() {},
    'on-presence-change': function onPresenceChange() {},

    init: function init() {
      if (!this.get('events')) {
        this.set('events', {});
      }

      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var name = this.get('name');

      if (!name) {
        this._leave();
        return;
      }

      if (this._channel && this._channel.get('name') === name) {
        return;
      }

      this._leave();
      this._join();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._leave();
    },
    _join: function _join() {
      var _this = this;

      this._channel = this.get('socket').channel(this.get('name'), this.get('params')).lock();
      var events = this.get('events');

      Object.keys(events).forEach(function (k) {
        _this._channel.on(k, events[k]);
      });

      this._channel.on('presence-change', this, '_onPresenceChanged');

      this._channel.join().then(function () {
        if (_this.get('isDestroying') || _this.get('isDestroyed')) {
          return;
        }

        _this.get('on-join')();
        _this.get('on-presence-change')(_this._channel.get('presence'));
      });
    },
    _leave: function _leave() {
      var _this2 = this;

      if (!this._channel) {
        return;
      }

      var events = this.get('events');

      Object.keys(events).forEach(function (k) {
        _this2._channel.off(k, events[k]);
      });

      this._channel.off('presence-change', this, '_onPresenceChanged');

      this._channel.unlock();
      this._channel = null;
    },
    _onPresenceChanged: function _onPresenceChanged(presence) {
      this.get('on-presence-change')(presence);
    }
  });
});