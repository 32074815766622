define('frontend-cp/utils/hash-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getImpersonationHash = getImpersonationHash;
  exports.removeImpersonationHash = removeImpersonationHash;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function fromString(windowHash) {
    if (!windowHash || windowHash.length === 0) {
      return {};
    }

    var hashes = windowHash.substring(1).split('&');

    var getKV = function getKV(hash) {
      var arr = hash.split('=');
      var key = arr[0];
      var val = arr.slice(1).join('=');
      return [key, val === '' ? key : val];
    };

    var obj = {};
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = hashes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var hash = _step.value;

        var _getKV = getKV(hash),
            _getKV2 = _slicedToArray(_getKV, 2),
            key = _getKV2[0],
            val = _getKV2[1];

        obj[key] = val;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return obj;
  }

  function toString(structure) {
    var arr = Object.entries(structure).map(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      return key === value ? key : key + '=' + value;
    });
    return arr.length > 0 ? '#' + arr.join('&') : '';
  }

  function removeKey(structure, keyToRemove) {
    var res = Object.assign({}, structure);
    delete res[keyToRemove];
    return res;
  }

  function getValue(structure, keyToSelect) {
    return structure[keyToSelect] || '';
  }

  function getImpersonationHash(windowHash) {
    return getValue(fromString(windowHash), 'impersonationToken');
  }

  function removeImpersonationHash(windowHash) {
    return toString(removeKey(fromString(windowHash), 'impersonationToken'));
  }
});