define('frontend-cp/models/case-reply-options', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    cc: _emberData.default.attr('array'),
    mentions: _emberData.default.attr('array'),
    html: _emberData.default.attr('boolean'),
    type: _emberData.default.attr('string')
  });
});