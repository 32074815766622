define('frontend-cp/models/column', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = _emberData.default.Model.extend({
    name: alias('id'),
    title: _emberData.default.attr('string')
  });
});