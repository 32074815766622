define("frontend-cp/components/ko-tabs/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "tabs": "ko-tabs__tabs_rca1e5",
    "list": "ko-tabs__list_rca1e5",
    "right": "ko-tabs__right_rca1e5"
  };
});