define('frontend-cp/serializers/user', ['exports', 'frontend-cp/serializers/application', 'frontend-cp/lib/custom-field-serialization', 'npm:lodash'], function (exports, _application, _customFieldSerialization, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;


  function getPrimaryEmailAddress(snapshot) {
    return snapshot.hasMany('emails').filter(function (identityEmail) {
      return identityEmail.attr('isPrimary');
    }).map(function (identityEmail) {
      return identityEmail.attr('email');
    })[0] || null;
  }

  exports.default = _application.default.extend({
    store: service(),
    plan: service(),

    attrs: {
      avatar: { serialize: false },
      phones: { serialize: false },
      twitter: { serialize: false },
      facebook: { serialize: false },
      customFields: { serialize: false },
      notes: { serialize: false },
      passwordUpdateAt: { serialize: false },
      avatarUpdateAt: { serialize: false },
      activityAt: { serialize: false },
      visitedAt: { serialize: false },
      createdAt: { serialize: false },
      updatedAt: { serialize: false },
      teams: { serialize: false },
      permissions: { serialize: false }
    },

    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      var locales = this.get('store').peekAll('locale');

      // TODO: this check is to have B/C for locale being object or string
      if (!_npmLodash.default.isObject(resourceHash.locale)) {
        var locale = void 0;
        locales.forEach(function (record) {
          if (record.get('locale') === resourceHash.locale) {
            locale = record;
          }
        });

        if (!locale) {
          locale = locales.get('firstObject');
        }

        resourceHash.locale = {
          id: locale.id,
          type: 'locale'
        };
      }

      return this._super.apply(this, arguments);
    },
    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      resourceHash.links = {
        notes: 'notes',
        tags: 'tags',
        recentCases: 'cases'
      };

      return this._super.apply(this, arguments);
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      if (relationship.key === 'teams') {
        if (json.role_id !== '4') {
          json.team_ids = (snapshot.hasMany('teams') || []).map(function (snapshot) {
            return snapshot.id;
          }).join(',');
        }
      } else if (relationship.key === 'tags') {
        json.tags = (snapshot.hasMany('tags') || []).map(function (snapshot) {
          return snapshot.attr('name');
        }).join(',');
      } else if (relationship.key === 'emails') {
        json.email = getPrimaryEmailAddress(snapshot);
      } else {
        this._super.apply(this, arguments);
      }
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      if (key === 'fieldValues') {
        json.field_values = (0, _customFieldSerialization.serializeCustomFields)(snapshot.attr('customFields'));
        if (Object.keys(json.field_values).length === 0) {
          Reflect.deleteProperty(json, 'field_values');
        }
      } else {
        this._super.apply(this, arguments);
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var normalized = this._super.apply(this, arguments);
      if (requestType === 'findRecord') {
        normalized.data.attributes._isFullyLoaded = true;
      }

      return normalized;
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      var plan = this.get('plan');
      if (!plan.has('shared_organizations')) {
        Reflect.deleteProperty(json, 'organization_case_access');
      }
      if (snapshot.adapterOptions) {
        if (snapshot.adapterOptions.setOrganization) {
          json = { organization_id: json.organization_id };
        }
        if (snapshot.adapterOptions.setSignature) {
          json = { signature: json.signature };
        }
        if (snapshot.adapterOptions.toggleUser) {
          json = { is_enabled: json.is_enabled };
        }
        if (snapshot.adapterOptions.updateName) {
          json = { full_name: json.full_name };
        }
      }

      return json;
    }
  });
});