define('frontend-cp/session/admin/automation/macros/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var EmberObject = Ember.Object;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    store: service(),
    i18n: service(),

    model: function model() {
      var _getProperties = this.getProperties('store', 'i18n'),
          store = _getProperties.store,
          i18n = _getProperties.i18n;

      return RSVP.hash({
        priorities: store.findAll('case-priority'),
        caseTypes: store.findAll('case-type'),
        caseStatuses: store.findAll('case-status'),
        replyTypes: [{
          id: 'REPLY',
          value: i18n.findTranslationByKey('admin.macros.actions.reply_type.reply')
        }, {
          id: 'NOTE',
          value: i18n.findTranslationByKey('admin.macros.actions.reply_type.note')
        }],
        agents: store.query('user', { role: 'agent', limit: 500 }),
        teams: store.findAll('team')
      });
    },
    setupController: function setupController(controller, model) {
      var referenceData = {
        actionDefinitions: this._actionDefinitions(model),
        teams: model.teams
      };

      controller.set('referenceData', referenceData);
    },
    _actionDefinitions: function _actionDefinitions(_ref) {
      var priorities = _ref.priorities,
          caseTypes = _ref.caseTypes,
          caseStatuses = _ref.caseStatuses,
          replyTypes = _ref.replyTypes,
          agents = _ref.agents,
          teams = _ref.teams;

      var i18n = this.get('i18n');

      var peopleWithTeams = [{ id: 'UNASSIGNED', value: i18n.findTranslationByKey('admin.macros.assign.unassigned') }, { id: 'CURRENT_AGENT', value: i18n.findTranslationByKey('admin.macros.assign.current_agent') }];

      teams.forEach(function (team) {
        peopleWithTeams.pushObject({
          id: team.get('id'),
          value: team.get('title')
        });
      });

      agents.forEach(function (agent) {
        agent.get('teams').forEach(function (team) {
          peopleWithTeams.pushObject({
            id: [team.id, agent.id].filter(function (item) {
              return !!item;
            }).join('-'),
            value: team.get('title') + ' \\ ' + agent.get('fullName')
          });
        });
      });

      priorities = priorities.toArray().sortBy('level').map(function (priority) {
        return {
          id: priority.get('id'),
          value: priority.get('label')
        };
      });

      caseTypes = caseTypes.toArray().sortBy('id').map(function (caseType) {
        return {
          id: caseType.get('id'),
          value: caseType.get('label')
        };
      });

      caseStatuses = caseStatuses.toArray().sortBy('sortOrder').filter(function (item) {
        return ['New', 'Closed'].indexOf(item.get('label')) === -1;
      }).map(function (status) {
        return {
          id: status.get('id'),
          value: status.get('label')
        };
      });

      return [EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'PLAIN-TEXT',
        valueType: 'STRING',
        label: i18n.findTranslationByKey('admin.macros.actions.reply_contents.label'),
        name: 'reply-contents',
        options: ['CHANGE'],
        values: replyTypes
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'OPTIONS',
        valueType: 'STRING',
        label: i18n.findTranslationByKey('admin.macros.actions.reply_type.label'),
        name: 'reply-type',
        options: ['CHANGE'],
        values: replyTypes
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'CASCADING_SELECT',
        valueType: 'STRING',
        label: i18n.findTranslationByKey('admin.macros.actions.assignee.label'),
        name: 'assignee',
        options: ['CHANGE'],
        values: peopleWithTeams
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'OPTIONS',
        valueType: 'NUMERIC',
        label: i18n.findTranslationByKey('admin.macros.actions.status.label'),
        name: 'status',
        options: ['CHANGE'],
        values: caseStatuses
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'OPTIONS',
        valueType: 'NUMERIC',
        label: i18n.findTranslationByKey('admin.macros.actions.type.label'),
        name: 'case-type',
        options: ['CHANGE'],
        values: caseTypes
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'TAGS',
        valueType: 'COLLECTION',
        label: i18n.findTranslationByKey('admin.macros.actions.add_tags.label'),
        name: 'add-tags',
        options: ['ADD'],
        values: ''
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'TAGS',
        valueType: 'COLLECTION',
        label: i18n.findTranslationByKey('admin.macros.actions.remove_tags.label'),
        name: 'remove-tags',
        options: ['REMOVE'],
        values: ''
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'OPTIONS',
        valueType: 'NUMERIC',
        label: i18n.findTranslationByKey('admin.macros.actions.priority.label'),
        name: 'priority',
        options: ['CHANGE', 'INCREASE', 'DECREASE'],
        values: priorities
      })];
    }
  });
});