define('frontend-cp/components/ko-timeline-2/list/activity/after/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    action: null,
    statusId: null,

    // Services
    store: service(),

    // Functions
    caseStatusType: function caseStatusType(statusId) {
      return this.get('store').peekRecord('case-status', statusId).get('statusType');
    }
  });
});