define('frontend-cp/metrics-adapters/userpilot', ['exports', 'ember-metrics/metrics-adapters/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _base.default.extend({

    sessionService: service('session'),

    toStringExtension: function toStringExtension() {
      return 'userpilot';
    },
    init: function init() {},
    identify: function identify(data) {
      window.userpilot.identify(data.distinctId, {
        name: data.name,
        email: data.email,
        created_at: data.created_at,
        company: {
          name: data.instance_name,
          id: this.get('sessionService.session.instanceId')
        },
        role_type: data.role_type,
        locale: data.locale
      });
    },
    trackEvent: function trackEvent(data) {
      var event = data.event;
      var attributes = Object.assign({}, data);
      delete attributes.event;
      window.userpilot.track(event, attributes);
    },
    trackPage: function trackPage(data) {
      window.userpilot.track('page_viewed', data);
    },
    alias: function alias() {},
    group: function group() {},
    willDestroy: function willDestroy() {}
  });
});