define('frontend-cp/components/ko-cases-list/column/conversation/component', ['exports', 'npm:he'], function (exports, _npmHe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    isLoading: false,
    model: null,

    _casePresence: null,

    // Services
    i18n: service(),
    session: service(),

    // CPs
    caseChannel: computed.alias('model.realtimeChannel'),

    subject: computed('model.subject', '_requesterIsTyping', function () {
      var requesterIsTyping = this.get('_requesterIsTyping');
      var caseSubject = _npmHe.default.unescape(this.get('model.subject'));

      if (requesterIsTyping) {
        return this.get('i18n').t('cases.realtimeTyping', {
          total: 1,
          sentence: ''
        });
      }

      return caseSubject;
    }),

    lastMessagePreview: computed('model.lastMessagePreview', function () {
      var content = '';
      if (this.get('model.lastMessagePreview')) {
        content = _npmHe.default.unescape(this.get('model.lastMessagePreview'));
      }

      return content;
    }),

    lastRepliedAt: computed('model.lastRepliedAt', 'model.lastReplier.fullName', function () {
      var i18n = this.get('i18n');
      var date = i18n.formatDate(this.get('model.lastRepliedAt'), { format: 'LLL' });
      return i18n.t('cases.last_reply_by_at', {
        by: this.get('model.lastReplier.fullName'),
        at: date
      });
    }),

    hasUnreadMessages: computed('model.readMarker.unreadCount', function () {
      var hasUnreadMessages = this.get('model.readMarker.unreadCount') === undefined || this.get('model.readMarker.unreadCount') > 0;
      return hasUnreadMessages;
    }),

    _requesterIsTyping: computed('_casePresence', 'model.requester', 'session.user', function () {
      var data = this.get('_casePresence');
      if (!data) {
        return false;
      }

      var id = this.get('model.requester.id');
      var loggedInUserId = this.get('session.user.id');

      if (id === loggedInUserId) {
        return false;
      }

      var metas = data[id] && data[id].metas || [];

      return metas.filterBy('is_typing', true).length > 0;
    })
  });
});