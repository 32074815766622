define('frontend-cp/session/admin/messenger/identity-verification/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    model: function model() {
      var store = this.get('store');
      var tokens = store.findAll('device-token');

      return RSVP.hash({
        tokens: tokens
      });
    }
  });
});