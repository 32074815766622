define('frontend-cp/models/facebook-comment', ['exports', 'ember-data', 'frontend-cp/models/postable'], function (exports, _emberData, _postable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _postable.default.extend({
    uuid: _emberData.default.attr('string'),
    postType: 'facebookComment',
    isMessage: true
  });
});