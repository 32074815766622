define('frontend-cp/components/ko-insights/custom-reports/form/component', ['exports', 'ember-concurrency', 'frontend-cp/components/ko-admin/predicate-builder/proposition/styles', 'frontend-cp/components/ko-admin/predicate-builder/styles', 'frontend-cp/lib/keycodes'], function (exports, _emberConcurrency, _styles, _styles2, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var jQuery = Ember.$;
  var get = Ember.get;
  var service = Ember.inject.service;
  var not = Ember.computed.not;
  var computed = Ember.computed;
  var EmberObject = Ember.Object;
  exports.default = Component.extend({
    virtualModel: service(),
    confirmation: service(),
    store: service(),
    reportsService: service('reports'),
    notification: service('notification'),
    i18n: service(),

    schema: null,
    definitions: null,
    editedReport: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.initTeams();
      this.updatePreview();
    },
    initTeams: function initTeams() {
      var _this = this;

      this.set('teams', []);
      this.get('store').findAll('team').then(function (teams) {
        _this.set('teams', teams);
      });
    },


    visibilities: ['PUBLIC', 'PRIVATE', 'TEAM'],
    canBeDeleted: not('report.isNew'),

    availableTeams: computed('teams.@each.id', 'editedReport.visibilityToTeams.@each.id', function () {
      var editedTeamIds = this.get('editedReport.visibilityToTeams').mapBy('id');
      return this.get('teams').filter(function (team) {
        return editedTeamIds.indexOf(team.get('id')) === -1;
      });
    }),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var report;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              report = this.get('report');

              if (!(this.get('editedReport.visibility') === 'TEAM' && this.get('editedReport.visibilityToTeams').length === 0)) {
                _context.next = 4;
                break;
              }

              this.get('notification').add({
                type: 'error',
                title: this.get('i18n').t('generic.validation_errors'),
                autodismiss: true,
                dismissable: true
              });

              return _context.abrupt('return', false);

            case 4:
              _context.next = 6;
              return this.get('virtualModel').save(report, this.get('editedReport'), this.get('schema'));

            case 6:

              report.get('predicateCollections').forEach(function (predicateCollection) {
                return predicateCollection.get('propositions').filter(function (proposition) {
                  return proposition.get('isNew');
                }).forEach(function (proposition) {
                  return proposition.unloadRecord();
                });
              });

              report.get('predicateCollections').filter(function (predicateCollection) {
                return predicateCollection.get('isNew');
              }).forEach(function (predicateCollection) {
                return predicateCollection.unloadRecord();
              });

              this.get('onSuccess')();

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    updatePreview: function updatePreview() {
      var collections = this.get('editedReport.predicateCollections');

      // only preview if we have at least one full predicate
      var canPreview = collections.any(function (collection) {
        return collection.get('propositions').any(function (proposition) {
          var _proposition$getPrope = proposition.getProperties('field', 'operator', 'value'),
              field = _proposition$getPrope.field,
              operator = _proposition$getPrope.operator,
              value = _proposition$getPrope.value;

          return field && operator && value;
        });
      });

      // clone to ensure we pass a new Array through so it triggers re-calculation
      if (canPreview) {
        this.set('previewPredicates', [].concat(_toConsumableArray(collections)));
      } else {
        this.set('previewPredicates', []);
      }
    },


    actions: {
      download: function download() {
        this.get('reportsService').download(this.get('report'));
      },
      cancel: function cancel() {
        this.get('onCancel')();
      },
      delete: function _delete() {
        var _this2 = this;

        return this.get('confirmation').confirm({
          intlConfirmLabel: 'generic.confirm.delete_button',
          intlConfirmationBody: 'insights.custom_reports.confirm_delete.body',
          intlConfirmationHeader: 'insights.custom_reports.confirm_delete.title'
        }).then(function () {
          return _this2.get('report').destroyRecord().then(function () {
            _this2.get('onDelete')();
          });
        });
      },
      addCollection: function addCollection() {
        this.get('editedReport.predicateCollections').pushObject(EmberObject.create({
          propositions: [EmberObject.create()]
        }));
        this.updatePreview();
      },
      removeCollection: function removeCollection(predicateCollection, id) {
        var _this3 = this;

        var element = jQuery('#' + id);
        element.addClass(_styles2.default.containerRemoving);
        element.fadeTo(400, 0, function () {
          _this3.get('editedReport.predicateCollections').removeObject(predicateCollection);
          _this3.updatePreview();
        });
      },
      createPropositionForCollection: function createPropositionForCollection(predicateCollection) {
        get(predicateCollection, 'propositions').pushObject(EmberObject.create());
        this.updatePreview();
      },
      removePropositionFromCollection: function removePropositionFromCollection(predicateCollection, proposition, id) {
        var _this4 = this;

        var element = jQuery('#' + id);
        element.addClass(_styles.default.containerRemoving);
        element.fadeTo(400, 0, function () {
          get(predicateCollection, 'propositions').removeObject(proposition);
          _this4.updatePreview();
        });
      },
      propositionChanged: function propositionChanged() {
        this.updatePreview();
      },
      preventFormSubmission: function preventFormSubmission(_, e) {
        if (e.keyCode === _keycodes.enter) {
          e.preventDefault();
        }
      }
    }

  });
});