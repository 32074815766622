define('frontend-cp/components/ko-admin/rateplans/price/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // attributes
    amount: 0,
    currency: null,
    unitFee: 0,
    flatUpto: 0,
    perUnit: 'account.plans.priceFor.perAgent'
  });
});