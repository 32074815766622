define('frontend-cp/adapters/apps-adapter', ['exports', 'ember-data', 'frontend-cp/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;


  var DEV_APPS_SERVER_HOST = 'https://localhost:5000';

  exports.default = _emberData.default.JSONAPIAdapter.extend({
    session: service(),
    apps: service(),

    host: computed('apps.isDevMode', function () {
      if (this.get('apps.isDevMode')) {
        return DEV_APPS_SERVER_HOST;
      } else {
        return _environment.default.appsApiUrl;
      }
    }),

    defaultSerializer: 'apps',
    namespace: 'api/v1',

    headers: computed('session.sessionId', 'session.csrfToken', function () {
      var _get$getProperties = this.get('session').getProperties('sessionId', 'csrfToken'),
          sessionId = _get$getProperties.sessionId,
          csrfToken = _get$getProperties.csrfToken;

      var headers = {
        'X-CSRF-Token': csrfToken,
        'X-Session-ID': sessionId,
        'X-Instance-Domain': this.get('session.session.instanceName'),
        'X-User-Agent': this.get('session.session.userAgent')
      };

      return headers;
    }).volatile()

  });
});