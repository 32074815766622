define("frontend-cp/components/ko-insights/custom-reports/index/report/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "report": "ko-insights_custom-reports_index_report__report_pruc3k",
    "details": "ko-insights_custom-reports_index_report__details_pruc3k",
    "details-header": "ko-insights_custom-reports_index_report__details-header_pruc3k",
    "label": "ko-insights_custom-reports_index_report__label_pruc3k",
    "details-footer": "ko-insights_custom-reports_index_report__details-footer_pruc3k",
    "details-separator": "ko-insights_custom-reports_index_report__details-separator_pruc3k",
    "last-downloaded": "ko-insights_custom-reports_index_report__last-downloaded_pruc3k",
    "last-downloaded-label": "ko-insights_custom-reports_index_report__last-downloaded-label_pruc3k",
    "last-downloaded-date": "ko-insights_custom-reports_index_report__last-downloaded-date_pruc3k",
    "creator": "ko-insights_custom-reports_index_report__creator_pruc3k",
    "creator-label": "ko-insights_custom-reports_index_report__creator-label_pruc3k",
    "creator-avatar": "ko-insights_custom-reports_index_report__creator-avatar_pruc3k",
    "creator-name": "ko-insights_custom-reports_index_report__creator-name_pruc3k",
    "status-pill": "ko-insights_custom-reports_index_report__status-pill_pruc3k",
    "status-time": "ko-insights_custom-reports_index_report__status-time_pruc3k",
    "status-spinner": "ko-insights_custom-reports_index_report__status-spinner_pruc3k",
    "status-text": "ko-insights_custom-reports_index_report__status-text_pruc3k",
    "status-text-not-started": "ko-insights_custom-reports_index_report__status-text-not-started_pruc3k",
    "status-text-waiting": "ko-insights_custom-reports_index_report__status-text-waiting_pruc3k",
    "status-text-started": "ko-insights_custom-reports_index_report__status-text-started_pruc3k",
    "status-text-completed": "ko-insights_custom-reports_index_report__status-text-completed_pruc3k",
    "status-text-failed": "ko-insights_custom-reports_index_report__status-text-failed_pruc3k",
    "status-text-expired": "ko-insights_custom-reports_index_report__status-text-expired_pruc3k"
  };
});