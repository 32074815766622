define("frontend-cp/components/ko-admin/apps/webhooks/form/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "copy-button-container": "ko-admin_apps_webhooks_form__copy-button-container_10cm8z",
    "copy-button": "ko-admin_apps_webhooks_form__copy-button_10cm8z ko-button__default_ka3fcv ko-button__bordered_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv"
  };
});