define("frontend-cp/components/ko-cases-list/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-cases-list__container_j7ngzl",
    "title": "ko-cases-list__title_j7ngzl",
    "placeholder-shimmer": "ko-cases-list__placeholder-shimmer_j7ngzl",
    "shimmer": "ko-cases-list__shimmer_j7ngzl",
    "bulk-actions": "ko-cases-list__bulk-actions_j7ngzl",
    "merge-button": "ko-cases-list__merge-button_j7ngzl",
    "trash-button": "ko-cases-list__trash-button_j7ngzl",
    "scale-up-button": "ko-cases-list__scale-up-button_j7ngzl",
    "table-th": "ko-cases-list__table-th_j7ngzl",
    "row": "ko-cases-list__row_j7ngzl",
    "row--trashed": "ko-cases-list__row--trashed_j7ngzl",
    "row--focused": "ko-cases-list__row--focused_j7ngzl",
    "not-found": "ko-cases-list__not-found_j7ngzl",
    "not-found-image": "ko-cases-list__not-found-image_j7ngzl",
    "not-found-message": "ko-cases-list__not-found-message_j7ngzl",
    "heading": "ko-cases-list__heading_j7ngzl",
    "ellipsis": "ko-cases-list__ellipsis_j7ngzl"
  };
});