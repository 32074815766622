define('frontend-cp/session/admin/people/teams/new/controller', ['exports', 'ember-service/inject', 'frontend-cp/session/admin/people/teams/schema', 'frontend-cp/services/virtual-model'], function (exports, _inject, _schema, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    // Attributes
    businessHours: null,
    team: null,

    // State
    editedTeam: null,

    // Services
    i18n: service(),
    notification: service(),
    virtualModel: service(),
    metrics: service(),

    schema: _schema.default,

    initEdits: function initEdits() {
      this.set('editedTeam', this.get('virtualModel').makeSnapshot(this.get('team'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('team'), this.get('editedTeam'), _schema.default);
    },


    actions: {
      created: function created() {
        var launchDarkly = this.get('launchDarkly');

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'team_created',
            name: this.get('editedTeam.title')
          });
        }
        this.transitionToRoute('session.admin.people.teams.index');
      },
      redirectToIndex: function redirectToIndex() {
        this.transitionToRoute('session.admin.people.teams.index');
      }
    }
  });
});