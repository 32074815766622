define('frontend-cp/models/macro-wrapper', ['exports', 'frontend-cp/components/ko-admin/macros/edit/action-handlers'], function (exports, _actionHandlers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var observer = Ember.observer;
  exports.default = EmberObject.extend({
    store: null,
    macro: null,
    macroActions: null,

    title: computed.alias('macro.title'),
    visibleToTeam: computed.alias('macro.visibleToTeam'),
    visibilityType: computed.alias('macro.visibilityType'),
    errors: computed.alias('macro.errors'),

    init: function init() {
      this._super.apply(this, arguments);

      var macro = this.get('macro');

      var actions = Object.keys(_actionHandlers.default).reduce(function (actions, actionType) {
        var handler = _actionHandlers.default[actionType];

        if (handler) {
          handler.deserialize(macro, actions);
        }

        return actions;
      }, []);

      if (actions.length === 0) {
        actions.push({});
      }

      this.set('macroActions', actions);
    },


    updateProperties: observer('macroActions.@each.{name,option,value}', function () {
      var _getProperties = this.getProperties('macro', 'macroActions', 'store'),
          macro = _getProperties.macro,
          macroActions = _getProperties.macroActions,
          store = _getProperties.store;

      macroActions.reduce(function (m, action) {
        var handler = _actionHandlers.default[action.name];

        if (handler) {
          return handler.serialize(action, m, store);
        }

        return m;
      }, macro);
    }),

    addAction: function addAction() {
      this.get('macroActions').pushObject({});
    },
    removeAction: function removeAction(action) {
      this._handleActionRemoval(action.name);
      this.get('macroActions').removeObject(action);
    },
    changeAction: function changeAction(definition) {
      this._handleActionRemoval(definition.name);
    },
    _handleActionRemoval: function _handleActionRemoval(name) {
      if (name) {
        var macro = this.get('macro');
        var handler = _actionHandlers.default[name];

        if (handler) {
          handler.reset(macro);
        }
      }
    }
  });
});