define('frontend-cp/components/ko-notification-center/notification/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    notification: null,
    routing: service('-routing'),
    isRead: computed.equal('notification.readState', 'READ'),
    isBreach: computed.equal('notification.activity.verb', 'BREACH'),
    avatarUrl: computed.readOnly('notification.activity.actor.image'),
    url: computed('notification', function () {
      var objectUrl = this.get('notification.activity.object.url') || '';
      var notificationId = this.get('notification.id');

      objectUrl = objectUrl.replace(/case/g, 'conversation');

      return objectUrl + '?notificationId=' + notificationId;
    }),

    actions: {
      closeDropdown: function closeDropdown() {
        this.get('close')();
      }
    }
  });
});