define('frontend-cp/components/ko-insights/articles-list/column/view-count/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    article: null,

    count: computed.readOnly('article.views')
  });
});