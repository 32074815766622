define("frontend-cp/components/ko-pinned-notes/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "sandbox-trigger": "ko-pinned-notes__sandbox-trigger_16s76l",
    "pinned-notes-trigger": "ko-pinned-notes__pinned-notes-trigger_16s76l",
    "chevron": "ko-pinned-notes__chevron_16s76l",
    "sandbox-dropdown": "ko-pinned-notes__sandbox-dropdown_16s76l",
    "conversations-count": "ko-pinned-notes__conversations-count_16s76l",
    "nine-plus": "ko-pinned-notes__nine-plus_16s76l",
    "count-text": "ko-pinned-notes__count-text_16s76l",
    "trigger-text": "ko-pinned-notes__trigger-text_16s76l",
    "closed": "ko-pinned-notes__closed_16s76l",
    "pinned-notes-dropdown": "ko-pinned-notes__pinned-notes-dropdown_16s76l"
  };
});