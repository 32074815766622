define('frontend-cp/adapters/static-model', ['exports', 'ember-data', 'npm:lodash'], function (exports, _emberData, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Adapter.extend({
    findAll: function findAll(store, typeClass) {
      return store.peekAll(typeClass);
    },
    find: function find(store, typeClass, id) {
      var records = this.findAll(store, typeClass);

      var matchingRecords = _npmLodash.default.select(records, function (record) {
        return record.id === id;
      });

      if (matchingRecords.length) {
        return matchingRecords.firstObject;
      }
    }
  });
});