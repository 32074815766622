define('frontend-cp/components/ko-admin/monitors/form/component', ['exports', 'frontend-cp/services/virtual-model', 'ember-diff-attrs'], function (exports, _virtualModel, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var schema = (0, _virtualModel.model)('monitor', {
    id: (0, _virtualModel.attr)(),
    title: (0, _virtualModel.attr)(),
    executionOrder: (0, _virtualModel.attr)(),
    predicateCollections: (0, _virtualModel.many)((0, _virtualModel.model)('predicate-collection', {
      id: (0, _virtualModel.attr)(),
      propositions: (0, _virtualModel.many)((0, _virtualModel.model)('proposition', {
        id: (0, _virtualModel.attr)(),
        field: (0, _virtualModel.attr)(),
        operator: (0, _virtualModel.attr)(),
        value: (0, _virtualModel.attr)()
      }))
    })),
    actions: (0, _virtualModel.many)((0, _virtualModel.model)('automation-action', {
      name: (0, _virtualModel.attr)(),
      option: (0, _virtualModel.attr)(),
      value: (0, _virtualModel.attr)(),
      attributes: (0, _virtualModel.attr)()
    })),
    isEnabled: (0, _virtualModel.attr)(),
    createdAt: (0, _virtualModel.attr)(),
    updatedAt: (0, _virtualModel.attr)()
  });
  exports.default = Component.extend({
    store: service(),
    virtualModel: service(),
    confirmation: service(),

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.get('registerAs')(this);
    },


    didReceiveAttrs: (0, _emberDiffAttrs.default)('monitor', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);
      if (!changedAttrs || changedAttrs.monitor) {
        this.initEdits();
      }
    }),

    canBeDeleted: computed('monitor.isNew', function () {
      return !this.get('monitor.isNew');
    }),

    // Actions
    actions: {
      addCollection: function addCollection() {
        this.get('editedMonitor.predicateCollections').pushObject({
          propositions: [{}]
        });
      },
      removeCollection: function removeCollection(predicateCollection) {
        this.get('editedMonitor.predicateCollections').removeObject(predicateCollection);
      },
      addPropositionToCollection: function addPropositionToCollection(predicateCollection) {
        get(predicateCollection, 'propositions').pushObject({});
      },
      removePropositionFromCollection: function removePropositionFromCollection(predicateCollection, proposition) {
        get(predicateCollection, 'propositions').removeObject(proposition);
      },
      addAction: function addAction() {
        this.get('editedMonitor.actions').pushObject({});
      },
      removeAction: function removeAction(action) {
        this.get('editedMonitor.actions').removeObject(action);
      },
      save: function save() {
        var _this = this;

        var monitor = this.get('monitor');
        var editedMonitor = this.get('editedMonitor');
        return this.get('virtualModel').save(monitor, editedMonitor, schema).then(function () {
          monitor.get('predicateCollections').toArray().forEach(function (predColl) {
            if (predColl.get('isNew')) {
              predColl.get('propositions').invoke('unloadRecord');
              predColl.unloadRecord();
            } else {
              predColl.get('propositions').filterBy('isNew').invoke('unloadRecord');
            }
          });
          monitor.get('actions').filterBy('isNew').invoke('unloadRecord');
          _this.set('editedMonitor', _this.get('virtualModel').makeSnapshot(_this.get('monitor'), schema));
        }).then(this.get('didSave'));
      },
      deleteMonitor: function deleteMonitor() {
        var _this2 = this;

        return this.get('confirmation').confirm({
          intlConfirmationBody: 'admin.monitors.labels.delete_confirmation',
          intlConfirmationHeader: 'admin.monitors.labels.confirm_delete',
          intlConfirmLabel: 'generic.confirm.delete_button'
        }).then(function () {
          return _this2.get('monitor').destroyRecord().then(function () {
            return _this2.attrs.didSave();
          });
        });
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedMonitor', this.get('virtualModel').makeSnapshot(this.get('monitor'), schema));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('monitor'), this.get('editedMonitor'), schema);
    }
  });
});