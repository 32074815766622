define('frontend-cp/components/ko-cases-list/column/custom-field/date/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    caseField: null,

    tagName: '',

    valueAsDate: computed('caseField.value', function () {
      var value = this.get('caseField.value');

      if (!value) {
        return value;
      }

      var convertedDate = (0, _moment.default)(value, 'YYYY-MM-DD');
      var year = convertedDate.get('year');
      var month = convertedDate.get('month');
      var day = convertedDate.get('date');
      var date = new Date(year, month, day);

      return date;
    })
  });
});