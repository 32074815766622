define("frontend-cp/components/ko-identities/create/trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "add": "ko-identities_create_trigger__add_17u151",
    "chevron": "ko-identities_create_trigger__chevron_17u151"
  };
});