define('frontend-cp/components/ko-post-status-icon/component', ['exports', 'frontend-cp/models/post'], function (exports, _post) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    status: null,
    tooltip: true,

    icon: computed('status', function () {
      switch (this.get('status')) {
        case _post.POST_STATUS_CLIENT_FAILED:
          return 'post-status/Failed';

        case _post.POST_STATUS_NOT_SENT:
          return 'post-status/Not Sent';

        case _post.POST_STATUS_SENT:
          return 'post-status/Sent';

        case _post.POST_STATUS_DELIVERED:
          return 'post-status/Delivered';

        case _post.POST_STATUS_SEEN:
          return 'post-status/Seen';

        case _post.POST_STATUS_REJECTED:
          return 'post-status/Rejected';

        default:
          return null;
      }
    })
  });
});