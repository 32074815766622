define('frontend-cp/components/ko-insights/articles-list/column/title/component', ['exports', 'frontend-cp/lib/location-origin'], function (exports, _locationOrigin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    article: null,

    title: computed.readOnly('article.title'),

    originLocation: _locationOrigin.default
  });
});