define('frontend-cp/session/agent/organizations/organization/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route'], function (exports, _tabbedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  var RSVP = Ember.RSVP;
  exports.default = _tabbedRoute.default.extend({
    processManager: service(),

    model: function model(params) {
      return this.store.findRecord('organization', params.organization_id);
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(model, 'organization');
      processManager.setForegroundProcess(process);

      var tabStore = this.get('tabStore');

      var _model$getProperties = model.getProperties('domains', 'tags'),
          domains = _model$getProperties.domains,
          tags = _model$getProperties.tags;

      var tab = this.retrieveTab(transition);

      tabStore.update(tab, { process: process });
      tabStore.set('activeTab', tab);
      this.set('tab', tab);

      return RSVP.hash({ domains: domains, tags: tags }).then(function () {
        var state = process.get('state');

        if (!state) {
          var OrganizationStateManager = getOwner(_this).factoryFor('state-manager:organization');
          state = OrganizationStateManager.create({ organization: model, tab: tab });
          process.set('state', state);
        }

        _this.set('state', state);
      });
    },
    retrieveTab: function retrieveTab(transition) {
      var newOrgController = this.controllerFor('session.agent.organizations.new.index');
      var tabStore = this.get('tabStore');

      if (!newOrgController.get('openInSameTab')) {
        return tabStore.open(transition);
      }

      var state = tabStore.decomposeTransition(transition);
      var transitionTabPath = newOrgController.get('transitionTabPath');
      var tab = tabStore.getTab(transitionTabPath);

      newOrgController.set('transitionTabPath', null);
      newOrgController.set('openInSameTab', false);

      tabStore.update(tab, state);

      return tab;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('state', this.get('state'));
    },


    actions: {
      willTransition: function willTransition() {
        var processManager = this.get('processManager');
        var model = this.modelFor(this.routeName);
        if (model && model.get('isDeleted')) {
          this.get('tabStore').leave(this.tab);
          this.get('tabStore').close(this.tab);
        }

        processManager.setForegroundProcess(null);

        this._super.apply(this, arguments);
      },
      closeTab: function closeTab() {
        this.get('tabStore').close(this.tab);
      }
    }
  });
});