define('frontend-cp/services/server-clock', ['exports', 'moment', 'ember-concurrency'], function (exports, _moment, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;


  var MAX_SKEW = 10 * 60 * 1000;

  exports.default = Service.extend({

    // State
    date: null,
    tickTaskInstance: null,

    // Sometimes the API sends multiple headers, which leads to odd results
    lastKnownServerTime: computed({
      get: function get() {/* */},
      set: function set(_k, value) {
        if (!value) {
          return value;
        }

        // Two headers get joined with comma, so just use the first if that happens
        return value.split(',')[0].trim();
      }
    }),

    // Services
    dateService: service('date'),
    developerTools: service('developerTools'),

    // Lifecycle hooks
    init: function init() {
      this._super();
      this.set('date', this.getServerTime());
      this.set('tickTaskInstance', this.get('tick').perform());
    },

    // CP's
    // Positive skew means local time is ahead of server time
    // Negative skew means local time is behind server time
    skew: computed('lastKnownServerTime', function () {
      var lastKnownServerTime = this.get('lastKnownServerTime');

      if (!lastKnownServerTime) {
        return 0;
      }

      var serverDateTime = (0, _moment.default)(lastKnownServerTime);
      var clientDateTime = (0, _moment.default)(this.get('dateService').getCurrentDate());
      var result = clientDateTime.diff(serverDateTime);

      if (Math.abs(result) > MAX_SKEW) {
        return 0;
      }

      return result;
    }),

    hours: computed('date', function () {
      this.get('date').hours();
    }),

    minutes: computed('date', function () {
      this.get('date').minutes();
    }),

    seconds: computed('date', function () {
      this.get('date').seconds();
    }),

    // Methods
    restartRunningTick: function restartRunningTick() {
      this.get('tickTaskInstance').cancel();
      this.set('tickTaskInstance', this.get('tick').perform());
    },
    getServerTime: function getServerTime() {
      var now = (0, _moment.default)(this.get('dateService').getCurrentDate());
      return now.subtract(this.get('skew'), 'ms');
    },


    // Tasks
    tick: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!true) {
                _context.next = 13;
                break;
              }

              // eslint-disable-line no-constant-condition
              this.set('date', this.getServerTime());

              if (!Ember.testing) {
                _context.next = 4;
                break;
              }

              return _context.abrupt('return');

            case 4:
              if (!this.get('developerTools.slaMetricsInSeconds')) {
                _context.next = 9;
                break;
              }

              _context.next = 7;
              return (0, _emberConcurrency.timeout)(1000);

            case 7:
              _context.next = 11;
              break;

            case 9:
              _context.next = 11;
              return (0, _emberConcurrency.timeout)(60000);

            case 11:
              _context.next = 0;
              break;

            case 13:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable()
  });
});