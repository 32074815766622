define('frontend-cp/lib/sso-url-parsing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function replaceActionUrl(urlString, actionPath) {
    var url = new URL(urlString);

    if (url.searchParams.has('returnto')) {
      var subUrl = new URL(url.searchParams.get('returnto'));

      if (subUrl.searchParams.has('action')) {
        subUrl.searchParams.set('action', actionPath);

        url.searchParams.set('returnto', subUrl.toString());
      }
    }

    return url.toString();
  }

  exports.replaceActionUrl = replaceActionUrl;
});