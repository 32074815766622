define('frontend-cp/session/admin/manage/views/index/route', ['exports', 'frontend-cp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.store.query('view', { show_all: true, limit: _environment.default.APP.views.maxLimit });
    }
  });
});