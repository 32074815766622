define('frontend-cp/models/token', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    label: attr('string', { defaultValue: '' }),
    description: attr('string', { defaultValue: '' }),
    channel: attr('string'),
    token: attr('string'),
    isEnabled: attr('boolean', { defaultValue: true }),
    lastUsedAt: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });
});