define("frontend-cp/components/ko-user-avatar/online-indicator/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "online-indicator": "ko-user-avatar_online-indicator__online-indicator_2q893c",
    "online-indicator--nano": "ko-user-avatar_online-indicator__online-indicator--nano_2q893c",
    "online-indicator--micro": "ko-user-avatar_online-indicator__online-indicator--micro_2q893c",
    "online-indicator--small": "ko-user-avatar_online-indicator__online-indicator--small_2q893c",
    "online-indicator--submedium": "ko-user-avatar_online-indicator__online-indicator--submedium_2q893c",
    "online-indicator--medium": "ko-user-avatar_online-indicator__online-indicator--medium_2q893c",
    "online-indicator--large": "ko-user-avatar_online-indicator__online-indicator--large_2q893c",
    "online-indicator--xLarge": "ko-user-avatar_online-indicator__online-indicator--xLarge_2q893c",
    "tooltip": "ko-user-avatar_online-indicator__tooltip_2q893c"
  };
});