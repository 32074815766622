define('frontend-cp/services/request-history', ['exports', 'moment', 'frontend-cp/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;


  var NUM_REQUESTS_TO_KEEP = 3;

  var PROMISE_PENDING = undefined; // eslint-disable-line no-undefined
  var PROMISE_FULFILLED = 1;
  var PROMISE_REJECTED = 2;

  exports.default = Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this._requests = [];
    },
    push: function push(_ref) {
      var path = _ref.path,
          type = _ref.type,
          data = _ref.data,
          headers = _ref.headers,
          cookies = _ref.cookies,
          request = _ref.request;

      this._requests.unshift({
        path: path,
        type: type,
        data: data,
        headers: headers,
        cookies: cookies,
        request: request,
        at: new Date(),
        csid: sessionIdFromCookie(cookies),
        csrf: csrfFromLocalStorage()
      });
      this._requests = this._requests.slice(0, NUM_REQUESTS_TO_KEEP);
    },
    recentSanitized: function recentSanitized() {
      return this._requests.map(requestInfo);
    }
  });


  function requestInfo(_ref2) {
    var path = _ref2.path,
        data = _ref2.data,
        type = _ref2.type,
        headers = _ref2.headers,
        cookies = _ref2.cookies,
        request = _ref2.request,
        at = _ref2.at,
        csid = _ref2.csid,
        csrf = _ref2.csrf;

    // NOTE - bugsnag needs the result syncronously so we pull data out of Promise#_state & Promise#_result
    return {
      at: (0, _moment.default)(at).toISOString(),
      path: path,
      type: type,
      headers: sanitizeHeaders(headers),
      csid: csid,
      csrf: csrf,
      requestData: sanitizeRequest(data, request._state),
      responseData: sanitizeResponse(request, request._state),
      promise: promiseState(request)
    };
  }

  function csrfFromLocalStorage() {
    if (window.localStorage) {
      var val = window.localStorage.getItem(_environment.default.localStore.prefix + ':' + _environment.default.localStore.defaultNamespace + ':csrf');

      if (val) {
        return JSON.parse(val);
      } else {
        return 'MISSING';
      }
    }

    return 'LOCAL STORAGE NOT AVAILABLE';
  }

  function sessionIdFromCookie(cookies) {
    var match = cookies.match(/novo_sessionid=([^;]+)/);
    return match && match[1] || 'MISSING';
  }

  function sanitizeHeaders(headers) {
    var headersToSend = ['x-session-id', 'x-csrf-token'];
    return Object.keys(headers).reduce(function (selected, key) {
      if (headersToSend.includes(key.toLowerCase())) {
        selected[key] = headers[key];
      }
      return selected;
    }, {});
  }

  function promiseState(promise) {
    switch (promise._state) {
      case PROMISE_PENDING:
        return 'pending';
      case PROMISE_FULFILLED:
        return 'fulfilled';
      case PROMISE_REJECTED:
        return 'rejected';
    }
  }

  function sanitizeRequest(data, promiseState) {
    if (promiseState === PROMISE_REJECTED) {
      return data;
    }
    return 'redacted';
  }

  function sanitizeResponse(response, promiseState) {
    if (promiseState === PROMISE_REJECTED) {
      return response._result;
    }

    return 'redacted';
  }
});