define('frontend-cp/application/route', ['exports', 'frontend-cp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    launchDarkly: service(),
    eventedRouter: service(),
    localClient: service('launchDarklyClientLocal'),

    beforeModel: function beforeModel() {
      var _this = this;

      var user = {
        key: window.location.hostname,
        anonymous: true,
        custom: {
          host: window.location.hostname,
          appVersion: _environment.default.currentRevision
        }
      };

      return this.get('launchDarkly').initialize(user).catch(function () {
        return _this._handleLaunchDarklyFailure(user);
      });
    },
    _handleLaunchDarklyFailure: function _handleLaunchDarklyFailure(user) {
      var service = this.get('launchDarkly');
      var localClient = this.get('localClient');
      service.set('_client', localClient);

      return service.initialize(user);
    },


    actions: {
      didTransition: function didTransition() {
        this.get('eventedRouter').trigger('didTransition');
        return true;
      }
    }
  });
});