define('frontend-cp/adapters/email-original', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord(id, _modelName, _snapshot) {
      var baseUrl = this.buildURL();
      return baseUrl + '/cases/posts/' + id + '/email_original';
    }
  });
});