define('frontend-cp/session/admin/customizations/email-templates/controller', ['exports', 'ember-concurrency', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/customizations/email-templates/schema'], function (exports, _emberConcurrency, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    // Attributes
    brands: null,
    emailCaseNotification: null,
    emailNotification: null,
    emailSatisfaction: null,
    editedEmailCaseNotification: null,
    editedEmailNotification: null,
    editedEmailSatisfaction: null,

    // Services
    i18n: service(),
    notification: service(),
    virtualModel: service(),
    confirmation: service(),

    // CPs
    hasMultipleBrands: computed.gt('brands.length', 1),

    brand: computed(function () {
      return this.get('brands').findBy('isDefault', true);
    }),

    // Methods
    initEdits: function initEdits() {
      this.set('editedEmailCaseNotification', this.get('virtualModel').makeSnapshot(this.get('emailCaseNotification'), _schema.default));
      this.set('editedEmailNotification', this.get('virtualModel').makeSnapshot(this.get('emailNotification'), _schema.default));
      this.set('editedEmailSatisfaction', this.get('virtualModel').makeSnapshot(this.get('emailSatisfaction'), _schema.default));
    },
    isCaseNotificationEdited: function isCaseNotificationEdited() {
      return (0, _virtualModel.isEdited)(this.get('emailCaseNotification'), this.get('editedEmailCaseNotification'), _schema.default);
    },
    isEmailNotificationEdited: function isEmailNotificationEdited() {
      return (0, _virtualModel.isEdited)(this.get('emailNotification'), this.get('editedEmailNotification'), _schema.default);
    },
    isEmailSatisfactionEdited: function isEmailSatisfactionEdited() {
      return (0, _virtualModel.isEdited)(this.get('emailSatisfaction'), this.get('editedEmailSatisfaction'), _schema.default);
    },
    isEdited: function isEdited() {
      return this.isCaseNotificationEdited() || this.isEmailNotificationEdited() || this.isEmailSatisfactionEdited();
    },


    // Tasks
    changeBrand: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(brand) {
      var _this = this;

      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (this.isEdited()) {
                this.get('confirmation').confirm({
                  intlConfirmationHeader: 'generic.confirm.lose_changes_header',
                  intlConfirmationBody: 'generic.confirm.lose_changes',
                  intlConfirmLabel: 'generic.confirm.lose_changes_button'
                }).then(function () {
                  _this.set('brand', brand);
                  _this.get('updateTemplates').perform();
                });
              } else {
                this.set('brand', brand);
                this.get('updateTemplates').perform();
              }

            case 1:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    updateTemplates: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var brand, caseNotificationTemplate, emailNotificationTemplate, satisfactionTemplate;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              brand = this.get('brand');
              _context2.next = 3;
              return this.store.queryRecord('template', { brand: brand, name: 'cases_email_notification' });

            case 3:
              caseNotificationTemplate = _context2.sent;
              _context2.next = 6;
              return this.store.queryRecord('template', { brand: brand, name: 'base_email_notification' });

            case 6:
              emailNotificationTemplate = _context2.sent;
              _context2.next = 9;
              return this.store.queryRecord('template', { brand: brand, name: 'cases_email_satisfaction' });

            case 9:
              satisfactionTemplate = _context2.sent;


              this.set('emailCaseNotification', caseNotificationTemplate);
              this.set('emailNotification', emailNotificationTemplate);
              this.set('emailSatisfaction', satisfactionTemplate);
              this.initEdits();

            case 14:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.prev = 0;

              if (!this.isCaseNotificationEdited()) {
                _context3.next = 5;
                break;
              }

              this.set('emailCaseNotification.contents', this.get('editedEmailCaseNotification.contents'));
              _context3.next = 5;
              return this.get('emailCaseNotification').save();

            case 5:
              if (!this.isEmailNotificationEdited()) {
                _context3.next = 9;
                break;
              }

              this.set('emailNotification.contents', this.get('editedEmailNotification.contents'));
              _context3.next = 9;
              return this.get('emailNotification').save();

            case 9:
              if (!this.isEmailSatisfactionEdited()) {
                _context3.next = 13;
                break;
              }

              this.set('emailSatisfaction.contents', this.get('editedEmailSatisfaction.contents'));
              _context3.next = 13;
              return this.get('emailSatisfaction').save();

            case 13:

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('generic.changes_saved'),
                autodismiss: true
              });

              this.initEdits();
              _context3.next = 19;
              break;

            case 17:
              _context3.prev = 17;
              _context3.t0 = _context3['catch'](0);

            case 19:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this, [[0, 17]]);
    })).drop(),

    // Actions
    actions: {
      brandChanged: function brandChanged(brand) {
        this.get('changeBrand').perform(brand);
      },
      updatedCaseNotification: function updatedCaseNotification(newValue) {
        this.get('editedEmailCaseNotification').set('contents', newValue);
      },
      updatedEmailNotification: function updatedEmailNotification(newValue) {
        this.get('editedEmailNotification').set('contents', newValue);
      },
      updatedEmailSatisfaction: function updatedEmailSatisfaction(newValue) {
        this.get('editedEmailSatisfaction').set('contents', newValue);
      }
    }
  });
});