define('frontend-cp/services/toggle-property', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    store: service(),
    notification: service(),

    toggleProperty: function toggleProperty(model, successMessage) {
      var _this = this;

      var propertyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'isEnabled';

      var newValue = !model.get(propertyName);

      return this._updatePropertyOnServer(model, propertyName, newValue).then(function () {
        if (successMessage) {
          _this.get('notification').success(successMessage);
        }
        model.set(propertyName, newValue);
        model.reload(); // so the model is marked clean. We've already updated the property so we don't need to wait for this call
      });
    },
    _updatePropertyOnServer: function _updatePropertyOnServer(model, propertyName, newValue) {
      var modelName = model.constructor.modelName;
      var adapter = this.get('store').adapterFor(modelName);
      var updateURL = adapter.urlForUpdateRecord(model.get('id'), modelName);
      var snakeCaseParameter = propertyName.replace(/([A-Z])/g, function ($1) {
        return '_' + $1.toLowerCase();
      });

      var data = {};
      data[snakeCaseParameter] = newValue;
      return adapter.ajax(updateURL, 'PUT', { data: data });
    }
  });
});