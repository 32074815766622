define('frontend-cp/components/ko-info-bar/field/multiline-text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var guidFor = Ember.guidFor;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    title: '',
    isErrored: false,
    isEdited: false,
    isKREEdited: false,
    isDisabled: false,
    value: '',
    onValueChange: null,
    emptyLabel: '',

    // State
    isActive: false,
    uniqueId: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.uniqueId = guidFor(this);
    },


    actions: {
      click: function click() {
        $('#' + this.get('uniqueId')).focus();
      },
      focus: function focus() {
        this.set('isActive', true);
      },
      blur: function blur(textarea) {
        this.set('isActive', false);
        textarea.scrollTop = 0;
      }
    }
  });
});