define("frontend-cp/components/ko-agent-content/hierarchical-action-menu/select-item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-agent-content_hierarchical-action-menu_select-item__item_x1uq68",
    "highlighted": "ko-agent-content_hierarchical-action-menu_select-item__highlighted_x1uq68",
    "selected": "ko-agent-content_hierarchical-action-menu_select-item__selected_x1uq68",
    "default": "ko-agent-content_hierarchical-action-menu_select-item__default_x1uq68"
  };
});