define('frontend-cp/session/agent/cases/case/organization/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['timestamp'],
    timestamp: null,

    actions: {
      updateQueryParams: function updateQueryParams(changes) {
        this.setProperties(changes);
        return true;
      }
    }
  });
});