define('frontend-cp/components/ko-user-content/action-menu/component', ['exports', 'frontend-cp/lib/role-types'], function (exports, _roleTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var readOnly = Ember.computed.readOnly;
  var equal = Ember.computed.equal;
  var not = Ember.computed.not;
  var bool = Ember.computed.bool;
  exports.default = Component.extend({
    // Attributes
    user: null,
    filter: null,
    canModifyUserState: false,
    showManageAppAccess: false,
    onCreateNewCase: null,
    onManageAppAccess: null,
    onEditSignature: null,
    onEnableTwoFactor: null,
    onDisableTwoFactor: null,
    onChangeUserPassword: null,
    onSetFilter: function onSetFilter() {},
    onUserToggle: function onUserToggle() {},
    deleteUser: function deleteUser() {},

    // Services
    permissions: service(),
    session: service(),

    // CPs
    currentUser: readOnly('session.user'),
    sessionPermissions: readOnly('session.permissions'),
    isDisabled: not('user.isEnabled'),
    targetUserIsCustomer: equal('user.role.roleType', 'CUSTOMER'),
    currentUserIsAdminOrHigher: bool('currentUser.role.isAdminOrHigher'),

    hasTwoFactorAuth: readOnly('user.isMfaEnabled'),

    targetUserIsCurrentUser: computed('user', function () {
      return this.get('user') === this.get('session.user');
    }),

    canDeleteTargetUser: computed('currentUser.role.roleType', 'currentUser.role.permissions.@each.value', 'user.role.roleType', function () {
      var currentUser = this.get('currentUser');
      var targetUser = this.get('user');

      if (targetUser === currentUser) {
        return false;
      }

      var currentUserRoleType = currentUser.get('role.roleType');
      var targetUserRoleType = targetUser.get('role.roleType');

      switch (currentUserRoleType) {
        case 'OWNER':
        case 'ADMIN':
          var currentUserRoleRank = _roleTypes.default[currentUserRoleType].rank;
          var targetUserRoleRank = _roleTypes.default[targetUserRoleType].rank;

          return currentUserRoleRank >= targetUserRoleRank;
        case 'AGENT':
          if (targetUserRoleType !== 'CUSTOMER') {
            return false;
          }

          var permissions = this.get('currentUser.role.permissions') || [];
          var permission = permissions.findBy('name', 'users.delete');
          var hasPermission = permission && permission.get('value');

          return !!hasPermission;
        default:
          return false;
      }
    }),

    currentUserHasChangeSignaturePermission: computed('sessionPermissions', 'currentUser.role.roleType', function () {
      return this.get('permissions').has('app.user.signature.edit', this.get('user'));
    }),

    currentUserHasChangePasswordEmailPermission: computed('sessionPermissions', 'currentUser.role.roleType', function () {
      return this.get('permissions').has('app.user.password.change', this.get('user'));
    }),

    hasDisableTwoFactorPermission: equal('currentUser.role.roleType', 'OWNER')
  });
});