define('frontend-cp/adapters/session', ['exports', 'ember-service/inject', 'frontend-cp/adapters/application', 'frontend-cp/utils/base64'], function (exports, _inject, _application, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var computed = Ember.computed;
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  exports.default = _application.default.extend({
    launchDarkly: (0, _inject.default)(),

    deviceFingerprint: service(),

    // CPs
    headers: computed('launchDarkly.release-remember-me', 'session.{email,password,sessionId}', function () {
      var launchDarkly = this.get('launchDarkly');

      var sessionId = this.get('session.sessionId');
      var email = this.get('session.email');
      var password = this.get('session.password');
      var authorizationHeader = 'Basic ' + (0, _base.b64EncodeUnicode)(email + ':' + password);
      var withPassword = email && password;
      var fingerprint = this.get('deviceFingerprint').getOrCreate();
      var rememberMeToken = this.get('session.rememberMeToken');
      var impersonationToken = this.get('session.impersonationToken');
      var otp = this.get('session.otp');
      var authToken = this.get('session.authToken');

      var headers = {
        Accept: 'application/json',
        'X-Options': 'flat',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF': true
      };

      if (impersonationToken) {
        headers['X-Impersonation-Token'] = impersonationToken;
      }

      if (!launchDarkly.get('release-remember-me')) {
        if (withPassword) {
          headers.Authorization = authorizationHeader;
        } else {
          headers['X-Session-ID'] = sessionId;
        }
        return headers;
      }

      headers['X-Fingerprint'] = fingerprint;

      var otpMode = otp && authToken;
      if (otpMode) {
        headers['X-Token'] = authToken;
        headers['X-OTP'] = otp;

        return headers;
      }

      if (withPassword) {
        headers.Authorization = authorizationHeader;
      } else if (sessionId) {
        headers['X-Session-ID'] = sessionId;
      }

      if (rememberMeToken) {
        headers['X-RememberMe'] = rememberMeToken;
      }

      return headers;
    }).volatile(),

    // Methods
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._buildURL(modelName, id);
      if (requestType === 'deleteRecord') {
        return url.substr(0, url.indexOf(id));
      }
      return url;
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var launchDarkly = this.get('launchDarkly');

      if (this.isSuccess(status, headers, payload)) {
        // currently the server sends lower-case header but documentation says it's capitalized
        // so handle both forms. JQuery handles this with getResponseHeader but we don't have that here
        payload.data.csrf_token = headers['X-CSRF-Token'] || headers['x-csrf-token'];
        if (launchDarkly.get('release-remember-me')) {
          payload.data.remember_me_token = headers['X-RememberMe'] || headers['x-rememberme'];
        }
      }
      return this._super.apply(this, arguments);
    },
    pathForType: function pathForType() {
      return 'session';
    },
    deleteSession: function deleteSession() {
      var _get$getProperties = this.get('session').getProperties('sessionId', 'csrfToken'),
          sessionId = _get$getProperties.sessionId,
          csrfToken = _get$getProperties.csrfToken;

      var options = {
        type: 'DELETE',
        url: this.namespace + '/session',
        dataType: 'json',
        headers: {
          'X-Session-ID': sessionId,
          'X-CSRF-Token': csrfToken
        }
      };

      return RSVP.resolve().then(function () {
        return $.ajax(options);
      });
    }
  });
});