define('frontend-cp/serializers/identity-autocomplete-email', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      user: { key: 'parent' }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.data) {
        return this._super.apply(this, arguments);
      }

      // set parent model for identity record
      payload.data.forEach(function (record) {
        var type = record.identity.resource_type;
        var id = record.identity.id;

        var item = payload.resources[type][id];

        item.parent = {
          id: record.parent.id,
          resource_type: record.parent.resource_type
        };
        record.id = id;
        record.resource_type = 'identity_autocomplete_email';
      });

      payload.identity_autocomplete_email = payload.data;
      return this._super.apply(this, arguments);
    }
  });
});