define('frontend-cp/adapters/post', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = new Ember.Inflector(Ember.Inflector.defaultRules);

  var REQUIRED_SIDELOADED_MODELS = 'attachment,case_message,channel,post,user,identity_phone,identity_email,identity_twitter,identity_facebook,note,activity,chat_message,facebook_message,twitter_tweet,twitter_message,comment,event,action,trigger,monitor,engagement,sla_version,activity_object,rating,case_status,activity_actor';

  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      if (query.parent) {
        var id = query.parent.id;
        var url = this._super.apply(this, arguments);
        var type = query.parent.get('constructor.modelName');
        Reflect.deleteProperty(query, 'parent');
        return url.replace('/posts', '/' + inflector.pluralize(type) + '/' + id + '/posts?include=' + REQUIRED_SIDELOADED_MODELS);
      }
      return this._super.apply(this, arguments);
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var parent = snapshot.belongsTo('parent');
      var sup = this._super.apply(this, arguments);
      if (parent) {
        return sup.replace('posts', inflector.pluralize(parent.type.modelName) + '/' + parent.id + '/posts');
      } else {
        return sup.replace('posts', 'cases/posts');
      }
    },
    markAsSeen: function markAsSeen(post) {
      var id = post.get('id');
      var namespace = this.namespace;
      var options = { data: { post_status: 'SEEN' } };

      return this.ajax(namespace + '/cases/posts/' + id, 'PUT', options);
    }
  });
});