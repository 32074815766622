define('frontend-cp/models/timetracking-tracked', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    billed: (0, _attr.default)('number'),
    worked: (0, _attr.default)('number'),
    entries: (0, _relationships.hasMany)('timetracking-log')
  });
});