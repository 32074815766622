define("frontend-cp/components/ko-keyboard-help-modal/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "shortcuts": "ko-keyboard-help-modal__shortcuts_15jdkv",
    "column": "ko-keyboard-help-modal__column_15jdkv",
    "group": "ko-keyboard-help-modal__group_15jdkv",
    "heading": "ko-keyboard-help-modal__heading_15jdkv",
    "shortcut": "ko-keyboard-help-modal__shortcut_15jdkv",
    "key": "ko-keyboard-help-modal__key_15jdkv",
    "or": "ko-keyboard-help-modal__or_15jdkv",
    "separator": "ko-keyboard-help-modal__separator_15jdkv",
    "description": "ko-keyboard-help-modal__description_15jdkv"
  };
});