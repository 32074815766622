define('frontend-cp/components/ko-tabs/case/component', ['exports', 'npm:he'], function (exports, _npmHe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    model: null,

    // CPs
    hasOrganization: computed.readOnly('model.requester.organization.name'),
    hasUser: computed.readOnly('model.requester.id'),

    subject: computed('model.subject', function () {
      return _npmHe.default.unescape(this.get('model.subject'));
    }),

    actions: {
      triggerSetOrganization: function triggerSetOrganization(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        this.sendAction('setOrganizationModeOn');
      }
    }
  });
});