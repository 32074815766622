define("frontend-cp/components/ko-select/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "select": "ko-select__select_1u5h9a",
    "select--small": "ko-select__select--small_1u5h9a",
    "select--medium": "ko-select__select--medium_1u5h9a",
    "trigger": "ko-select__trigger_1u5h9a _trigger_jvqwfg _statusIcon_jvqwfg",
    "ko-select-typeahead-trigger": "ko-select__ko-select-typeahead-trigger_1u5h9a",
    "trigger--focused": "ko-select__trigger--focused_1u5h9a _trigger--focused_jvqwfg",
    "dropdown": "ko-select__dropdown_1u5h9a _dropdown_jvqwfg"
  };
});