define('frontend-cp/adapters/notification', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    markAs: function markAs(id, status) {
      var url = this.namespace + '/notifications/' + id;
      return this.ajax(url, 'PUT', {
        data: {
          read_state: status
        }
      });
    },
    markAllAs: function markAllAs(id, status) {
      var url = this.namespace + '/notifications?notification_id=' + id;
      return this.ajax(url, 'PUT', {
        data: {
          read_state: status
        }
      });
    }
  });
});