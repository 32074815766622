define('frontend-cp/lib/left-pad', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (str) {
    var pad = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '00';

    str = String(str);
    return pad.substring(0, pad.length - str.length) + str;
  };
});