define('frontend-cp/components/ko-admin/localization/list-row/component', ['exports', 'ember-service/inject', 'ember-concurrency'], function (exports, _inject, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    tagName: '',

    // Attributes
    language: null,
    list: null,
    canToggle: true,

    //services
    i18n: service(),
    toggleProperty: service(),
    locale: service(),
    metrics: service(),

    onToggle: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var launchDarkly, language, notificationIntlKey, successMessage;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');
              language = this.get('language');
              notificationIntlKey = language.get('isPublic') ? 'admin.localization.disabled.message' : 'admin.localization.enabled.message';
              successMessage = this.get('i18n').t(notificationIntlKey);

              if (launchDarkly.get('release-event-tracking')) {
                this.get('metrics').trackEvent({
                  event: language.get('isPublic') ? 'localization_language_disabled' : 'localization_language_enabled',
                  object: language.get('locale'),
                  language: language.get('name')
                });
              }
              _context.next = 7;
              return this.get('toggleProperty').toggleProperty(language, successMessage, 'isPublic');

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop()
  });
});