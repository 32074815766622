define("frontend-cp/components/ko-user-content/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "root": "ko-user-content__root_1ifjw6",
    "modalBottom": "ko-user-content__modalBottom_1ifjw6",
    "signatureInput": "ko-user-content__signatureInput_1ifjw6",
    "message": "ko-user-content__message_1ifjw6",
    "closeModal": "ko-user-content__closeModal_1ifjw6",
    "buttonLoading": "ko-user-content__buttonLoading_1ifjw6",
    "buttons": "ko-user-content__buttons_1ifjw6",
    "delete-user-buttons": "ko-user-content__delete-user-buttons_1ifjw6",
    "timeline-header": "ko-user-content__timeline-header_1ifjw6",
    "timeline-header-image": "ko-user-content__timeline-header-image_1ifjw6",
    "timeline-header-body": "ko-user-content__timeline-header-body_1ifjw6",
    "timeline-header-title": "ko-user-content__timeline-header-title_1ifjw6",
    "timeline-header-subtitle": "ko-user-content__timeline-header-subtitle_1ifjw6",
    "timeline-preamble": "ko-user-content__timeline-preamble_1ifjw6",
    "timeline-header-subtitle-segment": "ko-user-content__timeline-header-subtitle-segment_1ifjw6",
    "submit-properties": "ko-user-content__submit-properties_1ifjw6",
    "submitting": "ko-user-content__submitting_1ifjw6",
    "show-submit": "ko-user-content__show-submit_1ifjw6",
    "or-text": "ko-user-content__or-text_1ifjw6",
    "cancel-property-changes": "ko-user-content__cancel-property-changes_1ifjw6",
    "new-conversation-button": "ko-user-content__new-conversation-button_1ifjw6",
    "padded-content--small": "ko-user-content__padded-content--small_1ifjw6",
    "disable-otp-title": "ko-user-content__disable-otp-title_1ifjw6",
    "disable-otp-subtitle": "ko-user-content__disable-otp-subtitle_1ifjw6",
    "grant-list-container": "ko-user-content__grant-list-container_1ifjw6",
    "grant-message": "ko-user-content__grant-message_1ifjw6",
    "redirection-loader": "ko-user-content__redirection-loader_1ifjw6",
    "redirection-message": "ko-user-content__redirection-message_1ifjw6",
    "replyOptions": "ko-user-content__replyOptions_1ifjw6",
    "replyOptionDescription": "ko-user-content__replyOptionDescription_1ifjw6",
    "editor-footer-right": "ko-user-content__editor-footer-right_1ifjw6",
    "note-destination-no-send-button": "ko-user-content__note-destination-no-send-button_1ifjw6",
    "delete-modal-header": "ko-user-content__delete-modal-header_1ifjw6",
    "delete-user-header-title": "ko-user-content__delete-user-header-title_1ifjw6",
    "delete-user-header-subtitle": "ko-user-content__delete-user-header-subtitle_1ifjw6",
    "delete-user-warnings": "ko-user-content__delete-user-warnings_1ifjw6",
    "delete-user-warning-title": "ko-user-content__delete-user-warning-title_1ifjw6",
    "delete-user-warning-list": "ko-user-content__delete-user-warning-list_1ifjw6"
  };
});