define('frontend-cp/components/ko-admin/sla/edit/component', ['exports', 'frontend-cp/services/virtual-model', 'frontend-cp/components/ko-admin/predicate-builder/proposition/styles', 'frontend-cp/components/ko-admin/predicate-builder/styles', 'ember-diff-attrs'], function (exports, _virtualModel, _styles, _styles2, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;


  var schema = (0, _virtualModel.model)('sla', {
    id: (0, _virtualModel.attr)(),
    title: (0, _virtualModel.attr)(),
    description: (0, _virtualModel.attr)(),
    executionOrder: (0, _virtualModel.attr)(),
    predicateCollections: (0, _virtualModel.many)((0, _virtualModel.model)('predicate-collection', {
      propositions: (0, _virtualModel.many)((0, _virtualModel.model)('proposition', {
        field: (0, _virtualModel.attr)(),
        operator: (0, _virtualModel.attr)(),
        value: (0, _virtualModel.attr)()
      }))
    })),
    targets: (0, _virtualModel.many)((0, _virtualModel.model)('sla-target', {
      id: (0, _virtualModel.attr)(),
      priority: (0, _virtualModel.attr)(),
      slaTargetType: (0, _virtualModel.attr)(),
      goalInSeconds: (0, _virtualModel.attr)(),
      operationalHours: (0, _virtualModel.attr)()
    })),
    isEnabled: (0, _virtualModel.attr)(),
    isDeleted: (0, _virtualModel.attr)(),
    createdAt: (0, _virtualModel.attr)(),
    updatedAt: (0, _virtualModel.attr)()
  });

  exports.default = Component.extend({
    // Attributes
    sla: null,
    onCancel: null,
    onSuccess: null,
    definitions: [],
    priorities: [],

    // State
    editedSla: null,

    // Services
    store: service(),
    virtualModel: service(),
    confirmation: service(),

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.get('registerAs')(this);
    },


    didReceiveAttrs: (0, _emberDiffAttrs.default)('sla', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);
      if (!changedAttrs || changedAttrs.sla) {
        this.initEdits();
      }
    }),

    // CPs
    canBeDeleted: computed('sla.isNew', function () {
      return !this.get('sla.isNew');
    }),

    // Actions
    actions: {
      save: function save() {
        var _this = this;

        var sla = this.get('sla');
        var editedSla = this.get('editedSla');
        return this.get('virtualModel').save(sla, editedSla, schema).then(function () {
          sla.get('predicateCollections').filter(function (collection) {
            return !collection.get('id');
          }).forEach(function (collection) {
            return collection.unloadRecord();
          });
          sla.get('targets').filter(function (target) {
            return !target.get('id');
          }).forEach(function (target) {
            return target.unloadRecord();
          });
          _this.set('editedSla', _this.get('virtualModel').makeSnapshot(_this.get('sla'), schema));
        });
      },
      deleteSLA: function deleteSLA() {
        var _this2 = this;

        return this.get('confirmation').confirm({
          intlConfirmationBody: 'admin.sla.labels.delete_confirmation',
          intlConfirmationHeader: 'admin.sla.labels.confirm_delete',
          intlConfirmLabel: 'generic.confirm.delete_button'
        }).then(function () {
          return _this2.get('sla').destroyRecord().then(function () {
            return _this2.attrs.onSuccess();
          });
        });
      },
      addCollection: function addCollection() {
        this.get('editedSla.predicateCollections').pushObject(EmberObject.create({
          operator: 'OR',
          propositions: [EmberObject.create()]
        }));
      },
      removeCollection: function removeCollection(collection, id) {
        var _this3 = this;

        var element = $('#' + id);
        element.addClass(_styles2.default.containerRemoving);
        element.fadeTo(400, 0, function () {
          _this3.get('editedSla.predicateCollections').removeObject(collection);
        });
      },
      createPropositionForCollection: function createPropositionForCollection(collection) {
        collection.get('propositions').pushObject(EmberObject.create());
      },
      removePropositionFromCollection: function removePropositionFromCollection(collection, proposition, id) {
        var element = $('#' + id);
        element.addClass(_styles.default.containerRemoving);
        element.fadeTo(400, 0, function () {
          collection.get('propositions').removeObject(proposition);
        });
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedSla', this.get('virtualModel').makeSnapshot(this.get('sla'), schema));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('sla'), this.get('editedSla'), schema);
    }
  });
});