define("frontend-cp/components/ko-admin/messenger-persistent/preview/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "preview-container": "ko-admin_messenger-persistent_preview__preview-container_agkl13",
    "preview": "ko-admin_messenger-persistent_preview__preview_agkl13",
    "preview__content": "ko-admin_messenger-persistent_preview__preview__content_agkl13",
    "preview__updated-background": "ko-admin_messenger-persistent_preview__preview__updated-background_agkl13",
    "is-animating": "ko-admin_messenger-persistent_preview__is-animating_agkl13",
    "preview-light-text": "ko-admin_messenger-persistent_preview__preview-light-text_agkl13",
    "preview__main-text": "ko-admin_messenger-persistent_preview__preview__main-text_agkl13",
    "preview__title": "ko-admin_messenger-persistent_preview__preview__title_agkl13",
    "preview__welcome-message": "ko-admin_messenger-persistent_preview__preview__welcome-message_agkl13",
    "preview__textarea": "ko-admin_messenger-persistent_preview__preview__textarea_agkl13",
    "preview__textarea__content": "ko-admin_messenger-persistent_preview__preview__textarea__content_agkl13",
    "preview__textarea__footer": "ko-admin_messenger-persistent_preview__preview__textarea__footer_agkl13",
    "preview__button": "ko-admin_messenger-persistent_preview__preview__button_agkl13",
    "preview__label": "ko-admin_messenger-persistent_preview__preview__label_agkl13",
    "preview__widgets": "ko-admin_messenger-persistent_preview__preview__widgets_agkl13",
    "preview__widget": "ko-admin_messenger-persistent_preview__preview__widget_agkl13",
    "widget-in": "ko-admin_messenger-persistent_preview__widget-in_agkl13",
    "preview__widget__title": "ko-admin_messenger-persistent_preview__preview__widget__title_agkl13",
    "agents": "ko-admin_messenger-persistent_preview__agents_agkl13",
    "agent-pic": "ko-admin_messenger-persistent_preview__agent-pic_agkl13",
    "agent-names": "ko-admin_messenger-persistent_preview__agent-names_agkl13",
    "bubble": "ko-admin_messenger-persistent_preview__bubble_agkl13",
    "bubble__icon": "ko-admin_messenger-persistent_preview__bubble__icon_agkl13",
    "article": "ko-admin_messenger-persistent_preview__article_agkl13",
    "fadein-articles": "ko-admin_messenger-persistent_preview__fadein-articles_agkl13",
    "no-data-message": "ko-admin_messenger-persistent_preview__no-data-message_agkl13",
    "author-pic": "ko-admin_messenger-persistent_preview__author-pic_agkl13",
    "article__title": "ko-admin_messenger-persistent_preview__article__title_agkl13",
    "content-placeholder": "ko-admin_messenger-persistent_preview__content-placeholder_agkl13",
    "shimmer": "ko-admin_messenger-persistent_preview__shimmer_agkl13",
    "tweet": "ko-admin_messenger-persistent_preview__tweet_agkl13",
    "tweet__text": "ko-admin_messenger-persistent_preview__tweet__text_agkl13",
    "text--small": "ko-admin_messenger-persistent_preview__text--small_agkl13",
    "text--light": "ko-admin_messenger-persistent_preview__text--light_agkl13"
  };
});