define('frontend-cp/components/ko-admin/engagement-actions-builder/preview/component', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Attributes
    tagName: '',
    team: null,
    agent: null,
    message: null,

    // Services

    i18n: service(),

    // CPs
    messageText: computed('message', function () {
      if (this.get('message') === '' || this.get('message') === null || this.get('message.string') === '') {
        return this.get('i18n').t('admin.engagements.preview.message_placeholder');
      }
      return this.get('message');
    }),

    senderName: computed('team', 'agent', function () {
      if (this.get('agent')) {
        return this.get('agent.fullName');
      } else if (this.get('team')) {
        return this.get('team.title');
      } else {
        return this.get('i18n').t('admin.engagements.preview.sender_name_placeholder');
      }
    }),

    multipleAvatars: computed('avatars', function () {
      return this.get('avatars').length > 1;
    }),

    avatars: computed('team', 'agent', function () {
      if (this.get('agent')) {
        return [this.get('agent.avatar')];
      } else if (this.get('team')) {
        var avatars = [];
        var members = _npmLodash.default.slice(this.get('team.members').toArray().filterBy('role.isAgentOrHigher', true).filterBy('isEnabled', true), 0, 3);
        /**
         * Get 3 avatars for team. If less than 3 members are available
         * use default login avatars.
         */

        members.forEach(function (member) {
          avatars.push(get(member, 'avatar'));
        });
        _npmLodash.default.times(3 - avatars.length, function () {
          avatars.push('/images/login/avatar.png');
        });
        return avatars;
      } else {
        return ['/images/login/avatar.png'];
      }
    })
  });
});