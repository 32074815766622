define('frontend-cp/components/ko-timeline-2/list/item/email-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var filterBy = Ember.computed.filterBy;
  var or = Ember.computed.or;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    post: null,

    // CPs
    ccRecipients: filterBy('post.recipients', 'isCC'),
    toRecipients: filterBy('post.recipients', 'isTo'),
    show: or('post.email', 'ccRecipients.length', 'toRecipients.length'),

    onAddCC: function onAddCC() {}
  });
});