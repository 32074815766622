define('frontend-cp/session/agent/insights/reporting/custom-reports/new/controller', ['exports', 'frontend-cp/services/virtual-model', 'frontend-cp/session/agent/insights/reporting/custom-reports/schema'], function (exports, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    schema: _schema.default,

    virtualModel: service(),
    notification: service('notification'),
    i18n: service(),

    initEdits: function initEdits() {
      this.set('editedReport', this.get('virtualModel').makeSnapshot(this.get('report'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('report'), this.get('editedReport'), _schema.default);
    },


    actions: {
      created: function created() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        this.transitionToRoute('session.agent.insights.reporting.custom-reports.index');
      },
      cancelled: function cancelled() {
        this.transitionToRoute('session.agent.insights.reporting.custom-reports.index');
      }
    }
  });
});