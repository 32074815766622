define("frontend-cp/components/ko-form/buttons/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "buttons": "ko-form_buttons__buttons_1dm54l",
    "left-button": "ko-form_buttons__left-button_1dm54l",
    "loader": "ko-form_buttons__loader_1dm54l"
  };
});