define("frontend-cp/components/ko-oauth-client/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "client-logo": "ko-oauth-client__client-logo_ozpkt6",
    "client-row": "ko-oauth-client__client-row_ozpkt6",
    "client-caption": "ko-oauth-client__client-caption_ozpkt6",
    "client-title": "ko-oauth-client__client-title_ozpkt6",
    "client-author": "ko-oauth-client__client-author_ozpkt6",
    "client-body": "ko-oauth-client__client-body_ozpkt6",
    "client-description": "ko-oauth-client__client-description_ozpkt6",
    "client-details": "ko-oauth-client__client-details_ozpkt6",
    "separator": "ko-oauth-client__separator_ozpkt6"
  };
});