define("frontend-cp/components/ko-admin/holidays/edit/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "root": "ko-admin_holidays_edit__root_nsyaaf",
    "title": "ko-admin_holidays_edit__title_nsyaaf",
    "error": "ko-admin_holidays_edit__error_nsyaaf",
    "buttons": "ko-admin_holidays_edit__buttons_nsyaaf"
  };
});