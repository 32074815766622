define('frontend-cp/session/agent/insights/general/cases/controller', ['exports', 'frontend-cp/mixins/insights-control-actions'], function (exports, _insightsControlActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend(_insightsControlActions.default, {
    metricsQueryParams: null
  });
});