define("frontend-cp/components/side-conversations-panel/conversations-list/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "side-panel-header": "side-conversations-panel_conversations-list__side-panel-header_1gtxhv",
    "title": "side-conversations-panel_conversations-list__title_1gtxhv",
    "add-button": "side-conversations-panel_conversations-list__add-button_1gtxhv",
    "conversation-list-container": "side-conversations-panel_conversations-list__conversation-list-container_1gtxhv",
    "conversation-list": "side-conversations-panel_conversations-list__conversation-list_1gtxhv",
    "conversation-item": "side-conversations-panel_conversations-list__conversation-item_1gtxhv",
    "conversation-header": "side-conversations-panel_conversations-list__conversation-header_1gtxhv",
    "conversation-subject": "side-conversations-panel_conversations-list__conversation-subject_1gtxhv",
    "conversation-time": "side-conversations-panel_conversations-list__conversation-time_1gtxhv",
    "conversation-participants": "side-conversations-panel_conversations-list__conversation-participants_1gtxhv",
    "pagination": "side-conversations-panel_conversations-list__pagination_1gtxhv",
    "selected": "side-conversations-panel_conversations-list__selected_1gtxhv",
    "loader": "side-conversations-panel_conversations-list__loader_1gtxhv",
    "conversation-content": "side-conversations-panel_conversations-list__conversation-content_1gtxhv"
  };
});