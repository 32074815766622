define('frontend-cp/components/ko-tab-strip/case-tab/component', ['exports', 'npm:he'], function (exports, _npmHe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    tab: null,
    'on-close': function onClose() {},

    // Services
    socket: service(),
    i18n: service(),
    session: service(),
    processManager: service(),
    window: service(),

    conversation: computed.reads('tab.process.model'),

    // CPs
    showPill: computed('processManager.foregroundProcess', 'tab.process', 'window.visible', function () {
      var foregroundProcess = this.get('processManager.foregroundProcess');
      var currentProcess = this.get('tab.process');
      var windowIsFocussed = this.get('window.visible');

      return !windowIsFocussed || currentProcess !== foregroundProcess;
    }),

    label: computed('tab.process.model.subject', '_requesterIsTyping', function () {
      var requesterIsTyping = this.get('_requesterIsTyping');
      var caseSubject = _npmHe.default.unescape(this.get('tab.process.model.subject'));

      if (requesterIsTyping) {
        return this.get('i18n').t('cases.realtimeTyping', {
          total: 1,
          sentence: ''
        });
      }

      return caseSubject;
    }),
    caseRequester: computed.alias('tab.process.model.requester'),
    caseChannel: computed.alias('tab.process.model.realtimeChannel'),

    _casePresence: null,

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.updatePresenceMeta({ is_viewing: false });
    },
    updatePresenceMeta: function updatePresenceMeta() {
      var meta = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var caseChannelName = this.get('caseChannel');
      if (!caseChannelName) {
        return;
      }

      var socket = this.get('socket');
      if (!socket.hasJoinedChannel(caseChannelName)) {
        return;
      }

      socket.push(caseChannelName, 'update-presence-meta', meta);
    },


    _requesterIsTyping: computed('_casePresence', 'caseRequester', 'session.user', function () {
      var data = this.get('_casePresence');
      if (!data) {
        return false;
      }

      var id = this.get('caseRequester.id');
      var loggedInUserId = this.get('session.user.id');

      if (id === loggedInUserId) {
        return false;
      }

      var metas = data[id] && data[id].metas || [];

      return metas.filterBy('is_typing', true).length > 0;
    })
  });
});