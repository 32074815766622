define('frontend-cp/components/ko-admin/rateplans/list/component', ['exports', 'ember-service/inject', 'npm:lodash', 'ember-concurrency'], function (exports, _inject, _npmLodash, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var bind = Ember.run.bind;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    // Attributes
    rateplans: null,
    ratePlanTerms: null,
    disableActions: false,
    selectedPlan: null, // bidirectional
    selectedTerm: null, // bidirectional
    selectedNumberOfCollaborators: null, // bidirectional
    defaultNumberOfCollaborators: null, // bidirectional
    selectedNumberOfSeats: null, // bidirectional
    selectedSubscriptionDuration: { id: 3, text: '3 Years' }, // bidirectional
    grossTotal: 0,
    nextChargeDate: null,
    subscriptionAmount: 0,
    discountAmount: 0,
    calculatingSummary: false,
    onCtaComparePlans: function onCtaComparePlans() {},
    _stickySummary: false,
    _didScroll: null,

    // Services
    i18n: service(),
    metrics: service(),
    notificationService: service('notification'),
    ratePlansService: service('rateplans'),
    plan: service(),
    store: service(),

    // CP's
    legacyRateplans: computed('rateplans', function () {
      var rateplans = this.get('rateplans');

      if ('legacy' in rateplans) {
        return rateplans.legacy;
      } else {
        return [];
      }
    }),

    latestRateplans: computed('rateplans', function () {
      var rateplans = this.get('rateplans');

      if ('latest' in rateplans) {
        return rateplans.latest;
      } else {
        return rateplans;
      }
    }),

    noRateplansAvailable: computed('latestRateplans', 'legacyRateplans', function () {
      if (this.get('latestRateplans').length || this.get('legacyRateplans').length) {
        return false;
      }
      return true;
    }),

    ratePlanGroups: computed('latestRateplans', function () {
      var matchTillFirstUnderscore = new RegExp('^[^_]+(?=_)');
      var rateplans = this.get('latestRateplans');
      var ratePlanGroups = _npmLodash.default.chain(rateplans).groupBy(function (rateplan) {
        var match = rateplan.key.match(matchTillFirstUnderscore);
        return match && match.toString() || rateplan.key;
      }).values().value();

      return ratePlanGroups;
    }),

    seatsLimit: computed('selectedPlan', 'selectedTerm.value', function () {
      return this.get('ratePlansService').getSeatsLimit(this.get('selectedPlan'), this.get('selectedTerm.value'));
    }),

    savings: computed('selectedPlan', 'selectedNumberOfSeats', function () {
      return {
        ANNUAL: this.get('ratePlansService').getAnnualSavings(this.get('selectedPlan'), this.get('selectedNumberOfSeats'))
      };
    }),

    currency: reads('selectedPlan.currency'),

    // LifeCycle Hooks
    didInsertElement: function didInsertElement() {
      this._didScroll = bind(this, 'didScroll');
      $(window).on('scroll', this._didScroll);
    },
    willDestroyElement: function willDestroyElement() {
      $(window).off('scroll', this._didScroll);
    },
    didScroll: function didScroll() {
      var margin = 230;
      var elementHeight = $(this.get('element')).height();
      if ($(document).scrollTop() >= elementHeight + margin && !this.get('_stickySummary')) {
        this.set('_stickySummary', true);
      } else if ($(document).scrollTop() < elementHeight + margin && this.get('_stickySummary')) {
        this.set('_stickySummary', false);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.get('load').perform();
    },


    load: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!this.get('plan.isTrial')) {
                _context.next = 2;
                break;
              }

              return _context.abrupt('return');

            case 2:
              _context.next = 4;
              return this.get('loadSubscription').perform();

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    loadSubscription: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var store, subscription;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!this.get('subscription')) {
                _context2.next = 2;
                break;
              }

              return _context2.abrupt('return');

            case 2:
              store = this.get('store');
              _context2.next = 5;
              return store.queryRecord('subscription', {});

            case 5:
              subscription = _context2.sent;


              this.set('subscription', subscription);

            case 7:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    subscriptionRatePlan: computed('subscription.rateplans', function () {
      if (this.get('subscription.rateplans') !== undefined) {
        return this.get('ratePlansService').getSubscriptionRatePlan(this.get('subscription.rateplans'));
      }
      return;
    }),

    subscribedPlanCollaborators: computed('subscriptionRatePlan.charges', function () {
      if (this.get('subscriptionRatePlan.charges') !== undefined) {
        return this.get('ratePlansService').getCollaboratorsQuantity(this.get('subscriptionRatePlan.charges')) || 0;
      }
      return;
    }),

    setSubscriptionDuration: function setSubscriptionDuration(duration) {
      if (duration === null) {
        return;
      }

      var subscriptionDurationOptions = [{ label: '1 Year', value: 1 }, { label: '3 Years', value: 3 }, { label: '5 Years', value: 5 }];
      var mixedsubscriptionDurationOptions = _npmLodash.default.map(subscriptionDurationOptions, function (item, key) {
        return { id: item.value, text: item.label };
      });

      if (this.get('selectedSubscriptionDuration') === null) {
        this.set('selectedSubscriptionDuration', mixedsubscriptionDurationOptions[1]);
      }

      if (duration !== null) {
        this.set('selectedSubscriptionDuration', duration);
      }

      this.sendAction('switchSubscriptionDuration', this.get('selectedSubscriptionDuration'));
    },


    actions: {
      termChanged: function termChanged(term) {
        var selectedPlan = this.get('selectedPlan');

        if (selectedPlan && !selectedPlan[term.value]) {
          var formatedMessage = this.get('i18n').t('account.plans.term.cannotSwitch', {
            term: term.value,
            plan: selectedPlan.label
          });
          this.get('notificationService').error(formatedMessage);
          return;
        }
        this.set('selectedTerm', term);
        this.sendAction('switchPlan', selectedPlan);
        this.sendAction('agentsChanged', { target: { value: this.get('selectedNumberOfSeats') } });
      },

      seatsChanged: function seatsChanged(e) {
        /**
         * this makes sure the end-user can press
         * backspace and freely enter values
         */
        if (!e.target.value) {
          return;
        }

        /**
         * if we have some value, then we should validate
         * it and fire actions.
         */
        var newSeatsValue = Math.round(e.target.value);
        if (newSeatsValue < 1) {
          newSeatsValue = 1;
        }
        var seatsLimit = Number(this.get('seatsLimit'));
        if (seatsLimit && newSeatsValue > seatsLimit) {
          newSeatsValue = String(seatsLimit);
          this.get('notificationService').add({
            type: 'error',
            title: this.get('i18n').t('account.plans.seats.cannotIncrease'),
            autodismiss: true,
            unique: true
          });
        }
        e.target.value = String(newSeatsValue);
        this.set('selectedNumberOfSeats', newSeatsValue);
        this.sendAction('seatsSwitched');
      },

      switchPlan: function switchPlan(plan) {
        var launchDarkly = this.get('launchDarkly');

        var currentTerm = this.get('selectedTerm.value');
        if (!plan[currentTerm] || this.get('disableActions') || plan[currentTerm].actualId === this.get('selectedPlan.' + currentTerm + '.actualId')) {
          return;
        }

        /**
         * The backend API defines the default number of collaborators for each plan
         */
        this.set('selectedNumberOfCollaborators', plan[currentTerm].collaborators);
        this.set('defaultNumberOfCollaborators', plan[currentTerm].collaborators);

        if (this.get('subscribedPlanCollaborators') !== undefined) {
          this.set('selectedNumberOfCollaborators', this.get('subscribedPlanCollaborators'));
        }

        var seatsLimit = this.get('ratePlansService').getSeatsLimit(plan, currentTerm);
        if (seatsLimit && seatsLimit < this.get('selectedNumberOfSeats')) {
          this.get('notificationService').error(this.get('i18n').t('account.plans.plan.cannotSwitch'));
          if (launchDarkly.get('release-event-tracking')) {
            this.get('metrics').trackEvent({
              event: 'plan_switched_failed',
              current_seats: seatsLimit,
              selected_seats: this.get('selectedNumberOfSeats')
            });
          }

          return;
        }
        this.set('selectedPlan', plan);
        this.sendAction('planSwitched');
        this.sendAction('agentsChanged', { target: { value: this.get('selectedNumberOfSeats') } });
      },

      switchSubscriptionDuration: function switchSubscriptionDuration(duration) {
        this.setSubscriptionDuration(duration);
      }
    }
  });
});