define('frontend-cp/session/admin/people/staff/add/route', ['exports', 'ember-service/inject', 'ember-concurrency', 'frontend-cp/lib/role-types'], function (exports, _inject, _emberConcurrency, _roleTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var A = Ember.A;
  var EmberObject = Ember.Object;
  var Route = Ember.Route;
  var getOwner = Ember.getOwner;
  var RSVP = Ember.RSVP;
  var hash = Ember.RSVP.hash;
  exports.default = Route.extend({
    launchDarkly: (0, _inject.default)(),

    session: service(),
    store: service(),
    permissions: service(),
    bulkInvitations: service(),
    notification: service(),
    i18n: service(),
    metrics: service(),

    activate: function activate() {
      var launchDarkly = this.get('launchDarkly');

      this._super.apply(this, arguments);

      if (launchDarkly.get('ops-event-tracking')) {
        this.get('metrics').trackEvent({
          event: 'Team directory - Opened invite modal',
          category: 'Admin'
        });
      }
    },
    beforeModel: function beforeModel() {
      var permissions = this.get('permissions');

      if (!permissions.has('users.update')) {
        this.transitionTo('session.admin.people.staff');
      }

      this.get('bulkInvitations').reset();
    },
    model: function model() {
      var store = this.get('store');

      var roles = store.findAll('role').then(this._filterOutCustomerRoles).then(this._filterOutHigherRankedRoles.bind(this));

      var teams = store.findAll('team');
      var invitation = EmberObject.create({
        users: A([])
      });

      return hash({ invitation: invitation, roles: roles, teams: teams });
    },
    setupController: function setupController(controller, model) {
      var invitation = model.invitation;
      var teams = model.teams;
      var roles = model.roles;

      var refData = { roles: roles, teams: teams };

      controller.set('model', invitation);
      controller.set('referenceData', refData);
      controller.set('saveTask', this.get('_save'));
    },
    _filterOutCustomerRoles: function _filterOutCustomerRoles(roles) {
      return roles.filterBy('isCollaboratorOrHigher');
    },
    _filterOutHigherRankedRoles: function _filterOutHigherRankedRoles(roles) {
      var sessionUserRoleType = this.get('session.user.role.roleType');

      var userRoleRank = _roleTypes.default[sessionUserRoleType] && _roleTypes.default[sessionUserRoleType].rank;
      var filteredRoles = roles.filter(function (role) {
        var roleType = role.get('roleType');
        var roleRank = _roleTypes.default[roleType] && _roleTypes.default[roleType].rank;

        return roleRank <= userRoleRank;
      });

      return RSVP.resolve(filteredRoles);
    },


    _save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(invitation) {
      var adapter, data, emails, errors, regex;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              adapter = getOwner(this).lookup('adapter:application');
              data = {
                users: invitation.get('users').map(function (user) {
                  var obj = user.getProperties('fullname', 'email');
                  obj.role_id = user.get('role.id') || null;
                  obj.team_ids = user.get('teams').mapBy('id');

                  return obj;
                })
              };


              invitation.get('users').forEach(function (user) {
                return user.set('errors', null);
              });

              _context.prev = 3;
              _context.next = 6;
              return adapter.ajax(adapter.namespace + '/users/invite', 'POST', { data: data });

            case 6:

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('admin.staff.members_invited.notification'),
                autodismiss: true
              });

              emails = data.users.map(function (user) {
                return user.email;
              });

              this.set('bulkInvitations.emails', emails);
              this.transitionTo('session.admin.people.staff');
              _context.next = 17;
              break;

            case 12:
              _context.prev = 12;
              _context.t0 = _context['catch'](3);
              errors = _context.t0.errors;
              regex = /users\/(\d+)\/(\w+)/;

              errors.filter(function (error) {
                return error.pointer;
              }).forEach(function (error) {
                var results = error.pointer.match(regex);
                var index = results[1];
                var field = results[2];
                var message = error.message;

                switch (field) {
                  case 'role_id':
                    field = 'role';
                    break;
                  case 'team_ids':
                    field = 'teams';
                    break;
                }

                var user = invitation.get('users').objectAt(index);

                if (!user.get('errors')) {
                  user.set('errors', {});
                }

                if (!user.get('errors.' + field)) {
                  user.set('errors.' + field, A([]));
                }

                user.get('errors.' + field).pushObject({ message: message });
              });

            case 17:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[3, 12]]);
    })).drop(),

    actions: {
      cancel: function cancel() {
        this.transitionTo('session.admin.people.staff');
      }
    }
  });
});