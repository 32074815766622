define("frontend-cp/components/ko-admin/add-new-card/form/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "form-body": "ko-admin_add-new-card_form__form-body_o9tcgp",
    "loader": "ko-admin_add-new-card_form__loader_o9tcgp"
  };
});