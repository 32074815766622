define("frontend-cp/components/ko-admin/privacy/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "select": "ko-admin_privacy__select_1tef6y",
    "checkbox": "ko-admin_privacy__checkbox_1tef6y"
  };
});