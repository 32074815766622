define("frontend-cp/components/ko-admin/apps/webhooks/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "row": "ko-admin_apps_webhooks_index__row_1agudv",
    "rowDetail": "ko-admin_apps_webhooks_index__rowDetail_1agudv",
    "label": "ko-admin_apps_webhooks_index__label_1agudv",
    "description": "ko-admin_apps_webhooks_index__description_1agudv",
    "caption": "ko-admin_apps_webhooks_index__caption_1agudv"
  };
});