define("frontend-cp/components/side-conversations-panel/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "side-panel": "side-conversations-panel__side-panel_4k6b2r",
    "open": "side-conversations-panel__open_4k6b2r",
    "side-panel-header": "side-conversations-panel__side-panel-header_4k6b2r",
    "back-icon": "side-conversations-panel__back-icon_4k6b2r",
    "add-button": "side-conversations-panel__add-button_4k6b2r",
    "hidden": "side-conversations-panel__hidden_4k6b2r",
    "title": "side-conversations-panel__title_4k6b2r",
    "subtitle": "side-conversations-panel__subtitle_4k6b2r"
  };
});