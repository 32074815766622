define('frontend-cp/components/ko-impersonation/component', ['exports', 'ember-concurrency', 'frontend-cp/utils/format-validations'], function (exports, _emberConcurrency, _formatValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // Attributes
    domain: '',
    email: '',
    store: service(),

    buttonDisabled: computed('_formDataValid', 'impersonate.isRunning', function () {
      return !this.get('_formDataValid') || this.get('impersonate.isRunning');
    }),

    isLoading: computed('impersonate.isRunning', function () {
      return this.get('impersonate.isRunning');
    }),

    _emailValid: computed('email', function () {
      return (0, _formatValidations.validateEmailFormat)(this.get('email'));
    }),

    _domainValid: computed('domain', function () {
      return this.get('domain').length > 0 && this.get('domain') !== 'support';
    }),

    _formDataValid: computed('_emailValid', '_domainValid', function () {
      return this.get('_emailValid') && this.get('_domainValid');
    }),

    url: computed('domain', 'email', {
      get: function get(key) {
        return '';
      },
      set: function set(key, value) {
        return value;
      }
    }),

    impersonate: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(e) {
      var emailValue, domainValue, apiRes, token;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              emailValue = this.get('email');
              domainValue = this.get('domain');
              _context.next = 4;
              return this.get('store').adapterFor('impersonate').impersonate({ 'email': emailValue, 'domain': domainValue + '.kayako.com' });

            case 4:
              apiRes = _context.sent;
              token = apiRes['data']['token'];


              this.set('url', 'https://' + this.get('domain') + '.kayako.com/agent#impersonationToken=' + token);

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop()
  });
});