define("frontend-cp/components/ko-user-content/info-block/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-user-content_info-block__container_1kngwb",
    "info-card": "ko-user-content_info-block__info-card_1kngwb"
  };
});