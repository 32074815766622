define('frontend-cp/components/ko-cases-list/column/conv-composite/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    model: null,
    isLoading: false,

    // Services
    i18n: service(),

    // CPs
    lastRepliedAt: computed('model.lastRepliedAt', 'model.lastReplier.fullName', function () {
      var i18n = this.get('i18n');
      var date = i18n.formatDate(this.get('model.lastRepliedAt'), { format: 'LLL' });
      return i18n.t('cases.last_reply_by_at', {
        by: this.get('model.lastReplier.fullName'),
        at: date
      });
    })
  });
});