define("frontend-cp/components/ko-case/sla-dropdown/metric/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "table": "ko-case_sla-dropdown_metric__table_e5mto1",
    "label": "ko-case_sla-dropdown_metric__label_e5mto1",
    "data": "ko-case_sla-dropdown_metric__data_e5mto1",
    "time": "ko-case_sla-dropdown_metric__time_e5mto1",
    "is-breached": "ko-case_sla-dropdown_metric__is-breached_e5mto1",
    "metric": "ko-case_sla-dropdown_metric__metric_e5mto1",
    "metric-good": "ko-case_sla-dropdown_metric__metric-good_e5mto1",
    "metric-bad": "ko-case_sla-dropdown_metric__metric-bad_e5mto1",
    "metric-active": "ko-case_sla-dropdown_metric__metric-active_e5mto1",
    "metric-paused": "ko-case_sla-dropdown_metric__metric-paused_e5mto1",
    "progress": "ko-case_sla-dropdown_metric__progress_e5mto1",
    "progress-bar": "ko-case_sla-dropdown_metric__progress-bar_e5mto1",
    "progress-bar--good": "ko-case_sla-dropdown_metric__progress-bar--good_e5mto1",
    "progress-bar--bad": "ko-case_sla-dropdown_metric__progress-bar--bad_e5mto1",
    "progress-bar--active": "ko-case_sla-dropdown_metric__progress-bar--active_e5mto1",
    "grow": "ko-case_sla-dropdown_metric__grow_e5mto1",
    "shimmer": "ko-case_sla-dropdown_metric__shimmer_e5mto1"
  };
});