define('frontend-cp/components/ko-overlay/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var $ = Ember.$;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    qaClass: null,

    didInsertElement: function didInsertElement() {
      $('html').css('overflow', 'hidden');
    },
    didDestroyElement: function didDestroyElement() {
      $('html').css('overflow', '');
    }
  });
});