define('frontend-cp/components/ko-entity-list/sidebar/definition/operator/input/component', ['exports', 'ember-concurrency', 'frontend-cp/lib/keycodes', 'moment'], function (exports, _emberConcurrency, _keycodes, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var dasherize = Ember.String.dasherize;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  exports.default = Component.extend({
    tagName: '',

    //Attrs
    definition: null,
    operatorDefinition: null,
    currentPropositions: null,
    positionInPropositions: null,

    //Serivces
    store: service(),

    //Lifecycle Hooks

    //CP's
    currentProposition: computed('currentPropositions.[]', 'positionInPropositions', function () {
      var currentPropositions = this.get('currentPropositions');
      var positionInPropositions = this.get('positionInPropositions');

      return currentPropositions[positionInPropositions];
    }),

    definitionInputType: computed('currentProposition', function () {
      var currentOperator = this.get('currentProposition').operator;
      var inputType = this.get('definition.operators').find(function (operator) {
        return operator.get('label') === currentOperator;
      }).get('input.inputType');

      if (inputType) {
        return dasherize(inputType.toLowerCase());
      } else {
        return null;
      }
    }),

    currentPropositionAsBooleanString: computed('currentProposition', function () {
      var currentProposition = this.get('currentProposition');

      if (currentProposition.value !== undefined) {
        return currentProposition.value.toString();
      } else {
        return currentProposition.value;
      }
    }),

    currentPropositionValueArray: computed('currentProposition', function () {
      var value = this.get('currentProposition').value;

      if (value) {
        return value;
      } else {
        return [];
      }
    }),

    currentPropositionValueStringAsArray: computed('currentProposition', function () {
      var value = this.get('currentProposition').value;

      if (value) {
        return value.split(',');
      } else {
        return [];
      }
    }),

    selectedOptionsFromValue: computed('currentPropositionValueStringAsArray', 'selectedOperatorDefinitionValueObjects', function () {
      var valueArray = this.get('currentPropositionValueStringAsArray');
      var options = this.get('selectedOperatorDefinitionValueObjects');

      return valueArray.map(function (valueString) {
        return options.findBy('string', valueString);
      });
    }),

    unselectedOptionsFromValue: computed('selectedOptionsFromValue', 'selectedOperatorDefinitionValueObjects', function () {
      var selectedOptionsFromValue = this.get('selectedOptionsFromValue');
      var options = this.get('selectedOperatorDefinitionValueObjects');

      return options.filter(function (option) {
        return !selectedOptionsFromValue.findBy('string', option.get('string'));
      });
    }),

    selectedOptionsFromOption: computed('currentPropositionValueArray', 'selectedOperatorDefinitionValueObjects', function () {
      var valueArray = this.get('currentPropositionValueArray');
      var options = this.get('selectedOperatorDefinitionValueObjects');

      return valueArray.map(function (selectedOption) {
        return options.findBy('string', selectedOption.string);
      });
    }),

    unselectedOptionsFromOption: computed('selectedOptionsFromOption', 'selectedOperatorDefinitionValueObjects', function () {
      var selectedOptionsFromOption = this.get('selectedOptionsFromOption');
      var options = this.get('selectedOperatorDefinitionValueObjects');

      return options.filter(function (option) {
        return !selectedOptionsFromOption.findBy('string', option.get('string'));
      });
    }),

    selectedTagsFromValue: computed('currentPropositionValueStringAsArray', function () {
      var valueArray = this.get('currentPropositionValueStringAsArray');

      return valueArray.map(function (valueString) {
        return { name: valueString };
      });
    }),

    currentPropositionValueAsAutocompleteObject: computed('currentProposition', function () {
      var currentPropositionValueObject = this.get('currentProposition').value;

      if (currentPropositionValueObject) {
        return {
          value: currentPropositionValueObject.value,
          string: currentPropositionValueObject.string
        };
      } else {
        return null;
      }
    }),

    booleanOptions: computed(function () {
      return ['true', 'false'];
    }),

    selectedOperatorDefinitionValueObjects: computed('operatorDefinition', function () {
      var options = this.get('operatorDefinition.input.values');

      return options.map(function (obj) {
        return new EmberObject({
          value: Object.keys(obj)[0],
          string: Object.values(obj)[0]
        });
      });
    }),

    currentlySelectedDefinitionValue: computed('currentProposition', 'selectedOperatorDefinitionValueObjects', function () {
      var currentProposition = this.get('currentProposition');
      var options = this.get('selectedOperatorDefinitionValueObjects');

      if (!options || !currentProposition) {
        return null;
      }

      var option = options.find(function (option) {
        return option.get('value') === currentProposition.value;
      });

      return option ? option : null;
    }),

    //Functions
    createPropositionChangeObj: function createPropositionChangeObj(value) {
      var positionInPropositions = this.get('positionInPropositions');

      return {
        label: this.get('definition.label').toLowerCase(),
        field: this.get('currentPropositions')[positionInPropositions].field,
        operator: this.get('currentPropositions')[positionInPropositions].operator,
        value: value
      };
    },


    //Tasks
    captureTextAndSetAsValue: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(value) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(1000);

            case 2:

              this.send('setValue', value);

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    //Actions
    actions: {
      handleEnterPressInInput: function handleEnterPressInInput(e) {
        if (e.keyCode === _keycodes.enter) {
          this.get('captureTextAndSetAsValue').cancelAll();
          this.send('setValue', e.target.value);
        }
      },
      setValue: function setValue(value) {
        this.sendAction('onPropositionsChange', this.get('positionInPropositions'), this.createPropositionChangeObj(value));
      },
      selectValue: function selectValue(option) {
        this.sendAction('onPropositionsChange', this.get('positionInPropositions'), this.createPropositionChangeObj(option.get('value')));
      },
      setBooleanValue: function setBooleanValue(value) {
        this.sendAction('onPropositionsChange', this.get('positionInPropositions'), this.createPropositionChangeObj(value === 'true'));
      },
      addTagToValue: function addTagToValue(tag) {
        var currentlySelectedTagsAsStrings = this.get('selectedTagsFromValue').mapBy('name');
        var obj = this.createPropositionChangeObj(currentlySelectedTagsAsStrings.concat(tag.name).join(','));

        this.sendAction('onPropositionsChange', this.get('positionInPropositions'), obj);
      },
      removeTagFromValue: function removeTagFromValue(tag) {
        var currentlySelectedTagsAsStrings = this.get('selectedTagsFromValue').mapBy('name');
        var newSelectedTagsAsStrings = currentlySelectedTagsAsStrings.filter(function (tagName) {
          return tagName !== tag.name;
        });
        var obj = this.createPropositionChangeObj(newSelectedTagsAsStrings.join(','));

        this.sendAction('onPropositionsChange', this.get('positionInPropositions'), obj);
      },
      setMultipleValue: function setMultipleValue(options) {
        var value = options.map(function (option) {
          return { value: option.get('value'), string: option.get('string') };
        });

        this.sendAction('onPropositionsChange', this.get('positionInPropositions'), this.createPropositionChangeObj(value));
      },
      setIsoDate: function setIsoDate(utcDate) {
        this.sendAction('onPropositionsChange', this.get('positionInPropositions'), this.createPropositionChangeObj((0, _moment.default)(utcDate).toISOString()));
      },
      selectFromAutocomplete: function selectFromAutocomplete(selectedObject) {
        this.sendAction('onPropositionsChange', this.get('positionInPropositions'), this.createPropositionChangeObj(selectedObject));
      },
      searchAutocomplete: function searchAutocomplete(queryString) {
        return this.get('store').query('organization', { name: queryString }).then(function (results) {
          if (results.length) {
            return results.map(function (result) {
              return {
                value: result.get('id'),
                string: result.get('name')
              };
            });
          } else {
            return [];
          }
        });
      }
    }
  });
});