define('frontend-cp/components/ko-universal-search/result/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    store: service(),
    result: null,
    showAppendIcon: false,
    showRemoveIcon: false,
    isReadOnly: false,
    isHighlighted: false,
    onSelectHighlightedResult: null,
    onHighlight: null,
    onHighlightPreviousResult: null,
    onStopSearch: null,
    onClickRemoveIcon: null,
    href: null,
    appended: false,

    tagName: '',

    resultComponent: computed('result.resource', function () {
      switch (this.get('result.resource')) {
        case 'article':
          return 'ko-universal-search/result/article';
        case 'case':
          return 'ko-universal-search/result/case';
        case 'organization':
          return 'ko-universal-search/result/organization';
        case 'user':
          return 'ko-universal-search/result/user';
      }
    }),

    actions: {
      handleClick: function handleClick(event) {
        if (!this.get('isReadOnly')) {
          if (this.get('showAppendIcon')) {
            this.toggleProperty('appended');
            if (this.get('appended')) {
              this.attrs.onSelectHighlightedResult('ADD', this.get('result'));
            } else {
              this.attrs.onSelectHighlightedResult('REMOVE', this.get('result'));
            }
          } else if (this.get('showRemoveIcon')) {
            this.set('appended', false);
            this.attrs.onSelectHighlightedResult('REMOVE', this.get('result'));
          } else {
            if (this.get('result.resource') === 'article') {
              var article = this.get('store').peekRecord('article', this.get('result.resultData.id'));
              return this.set('href', article.get('helpcenterUrl') || '/article/' + article.get('id'));
            }

            event.preventDefault();

            var hasModifier = event.metaKey || event.ctrlKey || event.shiftKey;
            this.attrs.onSelectHighlightedResult(hasModifier);
            if (!hasModifier && this.attrs.onStopSearch) {
              this.attrs.onStopSearch();
            }
          }
        }
      },
      highlightResult: function highlightResult() {
        if (this.attrs.onHighlight) {
          this.attrs.onHighlight(this.get('result'));
        }
      }
    }
  });
});