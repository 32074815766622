define("frontend-cp/components/ko-insights/custom-reports/index-new/report/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "report": "ko-insights_custom-reports_index-new_report__report_1tg1y4",
    "details": "ko-insights_custom-reports_index-new_report__details_1tg1y4",
    "details-header": "ko-insights_custom-reports_index-new_report__details-header_1tg1y4",
    "label": "ko-insights_custom-reports_index-new_report__label_1tg1y4",
    "details-footer": "ko-insights_custom-reports_index-new_report__details-footer_1tg1y4",
    "italic": "ko-insights_custom-reports_index-new_report__italic_1tg1y4",
    "details-separator": "ko-insights_custom-reports_index-new_report__details-separator_1tg1y4",
    "last-downloaded": "ko-insights_custom-reports_index-new_report__last-downloaded_1tg1y4",
    "last-downloaded-label": "ko-insights_custom-reports_index-new_report__last-downloaded-label_1tg1y4",
    "creator": "ko-insights_custom-reports_index-new_report__creator_1tg1y4",
    "creator-label": "ko-insights_custom-reports_index-new_report__creator-label_1tg1y4",
    "status-pill": "ko-insights_custom-reports_index-new_report__status-pill_1tg1y4",
    "status": "ko-insights_custom-reports_index-new_report__status_1tg1y4",
    "is-waiting": "ko-insights_custom-reports_index-new_report__is-waiting_1tg1y4",
    "is-completed": "ko-insights_custom-reports_index-new_report__is-completed_1tg1y4",
    "is-failed": "ko-insights_custom-reports_index-new_report__is-failed_1tg1y4",
    "spinner": "ko-insights_custom-reports_index-new_report__spinner_1tg1y4",
    "rotate": "ko-insights_custom-reports_index-new_report__rotate_1tg1y4",
    "spinner-path": "ko-insights_custom-reports_index-new_report__spinner-path_1tg1y4",
    "dash": "ko-insights_custom-reports_index-new_report__dash_1tg1y4"
  };
});