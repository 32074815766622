define("frontend-cp/components/ko-entity-list/sidebar/definition/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "heading": "ko-entity-list_sidebar_definition__heading_1iiqsv",
    "icon-padding": "ko-entity-list_sidebar_definition__icon-padding_1iiqsv",
    "proposition": "ko-entity-list_sidebar_definition__proposition_1iiqsv",
    "definition-item": "ko-entity-list_sidebar_definition__definition-item_1iiqsv",
    "definition-label": "ko-entity-list_sidebar_definition__definition-label_1iiqsv",
    "label-text": "ko-entity-list_sidebar_definition__label-text_1iiqsv",
    "remove": "ko-entity-list_sidebar_definition__remove_1iiqsv",
    "add-container": "ko-entity-list_sidebar_definition__add-container_1iiqsv"
  };
});