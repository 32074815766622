define('frontend-cp/session/agent/knowledgebase/create-article/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    store: service(),
    i18n: service(),
    notification: service(),
    virtualModel: service(),
    router: service(),
    locale: service(),
    article: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('article', {
        tags: [],
        attachments: [],
        allowComments: true,
        status: 'PUBLISHED',
        contents: [{ locale: this.get('locale.accountDefaultLocaleCode'), translation: '' }],
        titles: [{ locale: this.get('locale.accountDefaultLocaleCode'), translation: '' }]
      });
    },

    actions: {
      goToIndexRoute: function goToIndexRoute() {
        this.get('router').transitionTo('session.agent.knowledgebase.index');
      }
    }
  });
});