define('frontend-cp/components/ko-case-content/field/requester/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    requester: null,
    isKREEdited: false,
    isErrored: false,
    isEdited: false,
    isDisabled: false,
    onValueChange: null,

    // Services
    store: service(),

    // Tasks
    search: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(name) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              _context.next = 4;
              return this.get('store').query('user', { name: name });

            case 4:
              return _context.abrupt('return', _context.sent);

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }))
  });
});