define('frontend-cp/session/agent/cases/index/suspended-messages/route', ['exports', 'frontend-cp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;


  var limit = _environment.default.casesPageSize;

  exports.default = Route.extend({
    caseListTab: service(),

    queryParams: {
      page: { refreshModel: true }
    },

    beforeModel: function beforeModel() {
      this.get('caseListTab.refreshCases').cancelAll();
      this.get('caseListTab').set('currentCachedView', null);
    },
    model: function model(_ref) {
      var page = _ref.page;

      var offset = (parseInt(page, 10) - 1) * limit;
      return this.store.query('mail', { is_suspended: true, offset: offset, limit: limit });
    },
    setupController: function setupController(controller, mails) {
      controller.set('model', mails);
    },


    // Actions
    actions: {
      showMail: function showMail(mail) {
        this.transitionTo('session.agent.cases.index.suspended-messages.show', mail);
      },
      refreshPage: function refreshPage() {
        var _this = this;

        var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        var params = this.paramsFor(this.routeName);

        if (page) {
          params.page = page;
        }

        var controller = this.controllerFor('session.agent.cases.index.suspended-messages');

        this.model(params).then(function (model) {
          if (model.get('length') === 0 && params.page > 1) {
            controller.set('page', params.page - 1);
            return;
          }

          _this.setupController(controller, model);
        });
      }
    }
  });
});