define("frontend-cp/components/ko-cases-list/sidebar/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-cases-list_sidebar_item__item_1km4cn",
    "disabled": "ko-cases-list_sidebar_item__disabled_1km4cn",
    "icon": "ko-cases-list_sidebar_item__icon_1km4cn",
    "text": "ko-cases-list_sidebar_item__text_1km4cn",
    "counter": "ko-cases-list_sidebar_item__counter_1km4cn",
    "inbox": "ko-cases-list_sidebar_item__inbox_1km4cn"
  };
});