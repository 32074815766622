define('frontend-cp/session/admin/apps/manage/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    appsService: service('apps'),
    store: service(),

    model: function model(_ref) {
      var app_installation_id = _ref.app_installation_id;

      return RSVP.hash({
        installedApp: this.get('appsService.installedApps').findBy('id', app_installation_id),
        prompts: this.get('store').query('app-installation-prompt', { app_installation_id: app_installation_id })
      });
    },
    setupController: function setupController(controller, _ref2) {
      var installedApp = _ref2.installedApp,
          prompts = _ref2.prompts;

      controller.set('installedApp', installedApp);
      controller.set('prompts', prompts);
    }
  });
});