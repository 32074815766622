define('frontend-cp/components/ko-cases-list/column/custom-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes:
    model: null,
    column: null,

    strippedColumnName: computed('column', function () {
      return this.get('column').replace('case_field_', '');
    }),

    field: computed('model', 'strippedColumnName', function () {
      var customFields = this.get('model.customFields');
      var columnName = this.get('strippedColumnName');

      return customFields.find(function (customField) {
        var fieldKey = customField.get('field.key');
        return fieldKey === columnName;
      });
    }),

    componentForColumn: computed('field', function () {
      var fieldType = this.get('field.field.fieldType');

      switch (fieldType) {
        case 'TEXT':
        case 'YESNO':
        case 'NUMERIC':
        case 'DECIMAL':
        case 'REGEX':
          return 'ko-cases-list/column/custom-field/string';
        case 'SELECT':
        case 'RADIO':
        case 'CASCADINGSELECT':
          return 'ko-cases-list/column/custom-field/single-choice';
        case 'CHECKBOX':
          return 'ko-cases-list/column/custom-field/multi-choice';
        case 'DATE':
          return 'ko-cases-list/column/custom-field/date';
      }
    })
  });
});