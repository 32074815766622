define('frontend-cp/session/errors/not-found/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    session: service(),

    name: computed(function () {
      var fullName = this.get('session.user.fullName');
      return fullName ? ' ' + fullName.split(' ')[0] : '';
    }),

    actions: {
      wakeUp: function wakeUp() {
        this.set('wakingUp', true);
      },
      didWakeUp: function didWakeUp() {
        this.set('wakingUp', false);
        this.set('awakened', true);
      }
    }
  });
});