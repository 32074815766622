define('frontend-cp/serializers/case-form', ['exports', 'frontend-cp/serializers/application', 'ember-data', 'npm:lodash'], function (exports, _application, _emberData, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      customerTitles: { embedded: 'always' },
      descriptions: { embedded: 'always' },
      fields: { serialize: 'ids' }
    },

    keyForRelationship: function keyForRelationship(key, relationship, method) {
      if ((!method || method === 'serialize') && relationship === 'hasMany' && key === 'fields') {
        return 'case_field_ids';
      } else {
        return this._super.apply(this, arguments);
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var data = payload.data || {};

      var customerTitles = data.customer_titles;
      var customerTitlesBc = customerTitles && _npmLodash.default.isArray(customerTitles) && !customerTitles.length && data.customer_title;

      var descriptions = data.descriptions;
      var descriptionsBc = descriptions && _npmLodash.default.isArray(descriptions) && !descriptions.length && data.description;

      if (customerTitlesBc) {
        var _id = 'ct-' + new Date().getTime();

        payload.data.customer_titles.push({
          id: _id,
          resource_type: 'locale_field'
        });

        payload.resources.locale_field[_id] = {
          created_at: new Date().toISOString(),
          id: _id,
          locale: 'en-us',
          resource_url: '',
          translation: data.customer_title,
          type: 'locale_field',
          updated_at: new Date().toISOString()
        };
      }

      if (descriptionsBc) {
        var _id2 = 'd-' + new Date().getTime();

        payload.data.descriptions.push({
          id: _id2,
          resource_type: 'locale_field'
        });

        payload.resources.locale_field[_id2] = {
          created_at: new Date().toISOString(),
          id: _id2,
          locale: 'en-us',
          resource_url: '',
          translation: data.description,
          type: 'locale_field',
          updated_at: new Date().toISOString()
        };
      }

      return this._super.apply(this, arguments);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      Reflect.deleteProperty(json, 'customer_title');
      Reflect.deleteProperty(json, 'description');

      return json;
    }
  });
});