define('frontend-cp/models/messenger-setting', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    brand: _emberData.default.belongsTo('brand', { async: false }),
    replyTimeExpectation: _emberData.default.attr('string', { defaultValue: '' }),

    /* enableSuggestions can have three states:
      1. true - enabled
      2. false - disabled
      3. null - disabled because article requirements have not been fulfilled.
    */
    enableSuggestions: _emberData.default.attr(), // passthrough (boolean or null)
    businesshour: _emberData.default.belongsTo('business-hour', { async: false }),
    homeTitles: _emberData.default.hasMany('locale_field', { async: false }),
    homeSubtitles: _emberData.default.hasMany('locale_field', { async: false }),
    metadata: _emberData.default.attr(), // passthrough
    resourceType: 'messenger-setting'
  });
});