define("frontend-cp/components/ko-admin/fields/form/types/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "buttons": "ko-admin_fields_form_types__buttons_1bpurs",
    "add-type": "ko-admin_fields_form_types__add-type_1bpurs",
    "legend": "ko-admin_fields_form_types__legend_1bpurs ko-admin_forms_group__legend_9ysg3g",
    "loader": "ko-admin_fields_form_types__loader_1bpurs",
    "label": "ko-admin_fields_form_types__label_1bpurs",
    "caption": "ko-admin_fields_form_types__caption_1bpurs"
  };
});