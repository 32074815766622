define("frontend-cp/components/ko-pill-input/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "view": "ko-pill-input__view_4eb2x7",
    "container": "ko-pill-input__container_4eb2x7",
    "container--small": "ko-pill-input__container--small_4eb2x7",
    "container--medium": "ko-pill-input__container--medium_4eb2x7",
    "container--full": "ko-pill-input__container--full_4eb2x7",
    "trigger": "ko-pill-input__trigger_4eb2x7 ko-input__input_v1243j",
    "trigger--focused": "ko-pill-input__trigger--focused_4eb2x7"
  };
});