define('frontend-cp/components/ko-agent-content/sidebar/info-list/requester-current-time/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var run = Ember.run;
  exports.default = Component.extend({
    // Attributes:
    user: null,

    timezones: service(),
    i18n: service(),

    // HTML
    tagName: '',

    // CPs
    userTimeZone: computed('user.timeZone', function () {
      return this.get('timezones.timeZones').findBy('id', this.get('user.timeZone'));
    }),

    userMomentTime: computed('user.timeZone', function () {
      var timezone = this.get('user.timeZone');

      if (!timezone) {
        return null;
      }

      return _moment.default.tz((0, _moment.default)(), timezone);
    }),

    timeIcon: computed('userMomentTime', function () {
      var userMomentTime = this.get('userMomentTime');

      if (!userMomentTime) {
        return null;
      }

      var hours = userMomentTime.hours();
      return hours >= 6 && hours < 18 ? 'day' : 'night';
    }),

    content: computed('userMomentTime', function () {
      var now = this.get('userMomentTime');
      var i18n = this.get('i18n');
      var format = 'time';
      var timeZone = this.get('user.timeZone');
      var time = i18n.formatTime(now, { format: format, timeZone: timeZone });

      return i18n.t('cases.timezone.currently', { time: time });
    }),

    // Tick logic
    didInsertElement: function didInsertElement() {
      this.tick();
    },
    tick: function tick() {
      if (!Ember.testing) {
        run.later(this, function () {
          this.notifyPropertyChange('user.timeZone');
          this.tick();
        }, 60000);
      }
    },
    willDestroyElement: function willDestroyElement() {
      run.cancel(this.get('nextTick'));
    }
  });
});