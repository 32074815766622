define('frontend-cp/utils/parse-summary', ['exports', 'frontend-cp/helpers/ko-linkify'], function (exports, _koLinkify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = parseSummary;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function parseSummary(str) {
    var tokens = [];

    var lastPos = 0;
    str.replace(/<[^>]+>/g, function (match, offset) {
      if (lastPos !== offset) {
        tokens.push(parseToken(str.slice(lastPos, offset)));
      }
      tokens.push(parseToken(match));
      lastPos = offset + match.length;
    });
    if (lastPos !== str.length) {
      tokens.push(parseToken(str.slice(lastPos)));
    }

    return tokens.filter(function (token) {
      return token.content;
    });
  }

  function parseToken(token) {
    return parseActor(token) || parseEntity(token) || parseText(token);
  }

  var ACTOR_REGEX = /^<@([^|]*)\|(.*)>$/;
  function parseActor(str) {
    var matches = str.match(ACTOR_REGEX);
    if (!matches) {
      return null;
    }

    var _matches = _slicedToArray(matches, 3),
        url = _matches[1],
        content = _matches[2];

    return {
      type: 'actor',
      url: parseURL(url),
      content: content
    };
  }

  var ENTITY_REGEX = /^<([^@]?.*)\|(.*)>$/;
  function parseEntity(str) {
    var matches = str.match(ENTITY_REGEX);
    if (!matches) {
      return null;
    }

    var _matches2 = _slicedToArray(matches, 3),
        url = _matches2[1],
        content = _matches2[2];

    return {
      type: 'entity',
      url: parseURL(url),
      content: content
    };
  }

  function parseText(str) {
    return {
      type: 'text',
      content: str.trim()
    };
  }

  var USER_URL_REGEX = /Base\/User\/(\d+)/;
  var CASE_URL_REGEX = /Base\/Case\/(\d+)/;
  var INTERNAL_URL_REGEX = /\/agent\//;

  function parseURL(url) {
    var matches = void 0;

    matches = url.match(INTERNAL_URL_REGEX);
    if (matches) {
      return (0, _koLinkify.stripDomain)(url);
    }

    matches = url.match(USER_URL_REGEX);
    if (matches) {
      return '/agent/users/' + matches[1];
    }

    matches = url.match(CASE_URL_REGEX);
    if (matches) {
      return '/agent/conversations/' + matches[1];
    }

    return null;
  }
});