define('frontend-cp/session/admin/apps/manage/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.get('store').findAll('app');
    },
    setupController: function setupController(controller, apps) {
      controller.set('apps', apps);
    }
  });
});