define('frontend-cp/session/agent/cases/index/view/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var controller = Ember.inject.controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    queryParams: ['page', 'orderBy', 'orderByColumn'],
    page: 1,

    // State
    parentController: controller('session.agent.cases.index'),
    selectedCaseIds: computed.readOnly('parentController.selectedCaseIds'),

    // Actions
    actions: {
      sortCases: function sortCases(orderBy, orderByColumn) {
        this.setProperties({
          orderBy: orderBy, orderByColumn: orderByColumn
        });
      },
      setSelectedCaseIds: function setSelectedCaseIds(checkedRows) {
        this.get('parentController').send('setSelectedCaseIds', checkedRows);
      }
    }
  });
});