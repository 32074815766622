define('frontend-cp/components/ko-sla/remaining/component', ['exports', 'moment', 'ember-concurrency'], function (exports, _moment, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  var run = Ember.run;
  exports.default = Component.extend({
    tagName: '',

    metric: null,
    seconds: null,
    shimmer: false,

    // Services
    serverClock: service(),
    date: service(),

    // CP's
    startedAt: reads('metric.startedAt'),
    dueAt: reads('metric.dueAt'),
    isCompleted: reads('metric.isCompleted'),
    lastPausedAt: reads('metric.lastPausedAt'),
    isPaused: reads('metric.isPaused'),
    completedAt: reads('metric.completedAt'),
    serverTime: reads('serverClock.date'),

    // Lifecycle Hook
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.notifyPropertyChange('isBreached');
    },
    triggerBreached: function triggerBreached(isBreached) {
      var _this = this;

      if (this._isBreached !== isBreached && this.get('onBreachChange')) {
        run.next(function () {
          _this.get('onBreachChange')(isBreached);
        });
      }

      this._isBreached = isBreached;
    },


    remainingSeconds: computed('startedAt', 'dueAt', 'isCompleted', 'lastPausedAt', 'isPaused', 'completedAt', 'isBreached', 'serverTime', function () {
      var to = 0;
      var from = 0;

      if (this.get('isCompleted') && this.get('startedAt') && !this.get('isBreached')) {
        to = this.get('startedAt').valueOf();
      } else if (this.get('dueAt')) {
        to = this.get('dueAt').valueOf();
      }

      if (this.get('isCompleted') && this.get('completedAt')) {
        from = this.get('completedAt').valueOf();
      } else if (this.get('isPaused') && this.get('lastPausedAt')) {
        from = this.get('lastPausedAt').valueOf();
      } else if (this.get('serverTime')) {
        from = this.get('serverTime').valueOf();
      }

      this.get('_secondsCounter').cancelAll();
      this.get('_showShimmer').perform();

      return Math.floor(Math.abs(to - from) / 1000);
    }),

    numberOfWholeDaysRemaining: computed('remainingSeconds', function () {
      return Math.floor(Math.abs(_moment.default.duration(this.get('remainingSeconds'), 'seconds').asDays()));
    }),

    numberOfWholeHoursRemaining: computed('remainingSeconds', function () {
      return Math.abs(_moment.default.duration(this.get('remainingSeconds'), 'seconds').hours());
    }),

    numberOfWholeMinutesRemaining: computed('remainingSeconds', function () {
      return Math.abs(_moment.default.duration(this.get('remainingSeconds'), 'seconds').minutes());
    }),

    timeRemaining: computed('remainingSeconds', 'seconds', function () {
      var days = Math.floor(Math.abs(_moment.default.duration(this.get('remainingSeconds'), 'seconds').asDays()));
      var hours = Math.abs(_moment.default.duration(this.get('remainingSeconds'), 'seconds').hours());
      var minutes = Math.abs(_moment.default.duration(this.get('remainingSeconds'), 'seconds').minutes());
      var seconds = this.get('seconds');

      if (days && hours) {
        minutes > 45 ? hours++ : hours--;
        minutes = null;
      }

      var formattedTime = '';

      if (days) {
        formattedTime = days + 'd';
      }
      if (hours) {
        formattedTime += ' ' + hours + 'h';
      }
      if (minutes) {
        formattedTime += ' ' + minutes + 'm';
      }
      if (!days && !hours && !minutes) {
        this.get('_secondsCounter').perform();
        formattedTime += ' ' + seconds + 's';
      }

      return formattedTime;
    }),

    timePassed: computed('remainingSeconds', function () {
      if (this.get('isCompleted') || this.get('isBreached') || !this.get('startedAt')) {
        return null;
      }

      var totalSeconds = (0, _moment.default)(this.get('dueAt')).diff((0, _moment.default)(this.get('startedAt'))) / 1000;
      var remainingFraction = this.get('remainingSeconds') / totalSeconds;
      var percentage = 100 - remainingFraction * 100;

      return percentage <= 100 && percentage > 0 ? 'width: ' + percentage + '%' : null;
    }),

    isNotCompletedButPastDue: computed('metric.{dueAt,lastPausedAt,completedAt,stage}', 'serverTime', function () {
      if (this.get('isPaused')) {
        return this.get('completedAt') === null && (0, _moment.default)(this.get('lastPausedAt')).isAfter(this.get('dueAt'));
      } else {
        return this.get('completedAt') === null && this.get('serverTime').isAfter(this.get('dueAt'));
      }
    }),

    isCompletedButPastDue: computed('metric.{dueAt,lastPausedAt,completedAt,isPaused}', 'serverTime', function () {
      if (!this.get('completedAt')) {
        return false;
      }

      if (this.get('isPaused')) {
        return (0, _moment.default)(this.get('lastPausedAt')).isAfter(this.get('dueAt'));
      } else {
        return (0, _moment.default)(this.get('completedAt')).isAfter(this.get('dueAt'));
      }
    }),

    isBreached: computed('isCompletedButPastDue', 'isNotCompletedButPastDue', function () {
      var isBreached = this.get('isCompletedButPastDue') || this.get('isNotCompletedButPastDue');

      this.triggerBreached(isBreached);

      return isBreached;
    }),

    isBreachedByAMinuteOrMore: computed('isBreached', 'metric', function () {
      return this.get('isBreached') && this.get('remainingSeconds') < 60;
    }),

    _secondsCounter: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var seconds;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              seconds = Math.abs(_moment.default.duration(this.get('remainingSeconds'), 'seconds').seconds());

              this.set('seconds', seconds);

              // Show static value of seconds if case is paused/completed

              if (!(this.get('isPaused') || this.get('isCompleted'))) {
                _context.next = 4;
                break;
              }

              return _context.abrupt('return');

            case 4:
              if (!Ember.testing) {
                _context.next = 6;
                break;
              }

              return _context.abrupt('return');

            case 6:
              if (!this.get('isBreached')) {
                _context.next = 16;
                break;
              }

            case 7:
              if (!(this.get('seconds') < 60)) {
                _context.next = 14;
                break;
              }

              _context.next = 10;
              return (0, _emberConcurrency.timeout)(1000);

            case 10:
              this.incrementProperty('seconds');
              if (this.get('seconds') >= 59) {
                this.set('seconds', null);
                this.get('serverClock').restartRunningTick();
              }
              _context.next = 7;
              break;

            case 14:
              _context.next = 23;
              break;

            case 16:
              if (!(this.get('seconds') >= 0)) {
                _context.next = 23;
                break;
              }

              _context.next = 19;
              return (0, _emberConcurrency.timeout)(1000);

            case 19:
              this.decrementProperty('seconds');
              if (this.get('seconds') < 1) {
                this.set('seconds', null);
                this.get('serverClock').restartRunningTick();
              }
              _context.next = 16;
              break;

            case 23:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    _showShimmer: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              this.set('shimmer', true);
              _context2.next = 3;
              return (0, _emberConcurrency.timeout)(800);

            case 3:
              this.set('shimmer', false);

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    }))
  });
});