define('frontend-cp/session/agent/search/controller', ['exports', 'ember-service/inject', 'ember-concurrency', 'frontend-cp/lib/keycodes', 'frontend-cp/config/environment', 'frontend-cp/utils/compute-selected'], function (exports, _inject, _emberConcurrency, _keycodes, _environment, _computeSelected) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var notEmpty = Ember.computed.notEmpty;
  var EmberObject = Ember.Object;
  var guidFor = Ember.guidFor;
  var run = Ember.run;
  var jQuery = Ember.$;
  var observer = Ember.observer;


  var columns = {
    CASES: ['convComposite', 'casestatusid', 'casepriorityid', 'updatedat'],

    USERS: ['userComposite', 'lastActivityAt'],

    ORGANIZATIONS: ['orgComposite', 'updatedat']
  };

  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    // Services
    store: service(),
    metrics: service(),
    advancedSearch: service(),
    i18n: service(),
    notification: service(),
    bulkService: service('case-bulk-update'),
    permissions: service(),
    confirmation: service(),
    mergeConversation: service(),

    queryParams: ['page', {
      group: {
        scope: 'controller'
      }
    }],

    // Attributes
    group: 'CASES',
    page: 1,

    // State
    searchTerm: '',
    openInANewTab: false,
    preventFocus: false,
    isDirty: false,
    isSomeChecked: notEmpty('selectedCaseIds'),
    isUpdatingCases: false,
    selectedCaseIds: computed(function () {
      return [];
    }),
    showResults: true,
    transition: null,
    tabIndex: null,

    // CPs
    hasPermissionToTrashCases: computed(function () {
      return this.get('permissions').has('cases.trash');
    }),

    inputID: computed(function () {
      return 'ko-' + guidFor(this);
    }),

    results: computed.readOnly('search.lastSuccessful.value'),

    teams: computed(function () {
      return this.get('store').peekAll('team');
    }),

    selectedCases: computed.alias('selectedCaseIds'),

    isEverythingChecked: computed('searchResults.length', 'selectedCaseIds.length', function () {
      var searchResults = this.get('searchResults.length');
      var selectedCaseIds = this.get('selectedCaseIds.length');

      return searchResults === selectedCaseIds;
    }),

    totalCounts: computed('results', 'showResults', function () {
      var results = this.get('results');
      var showResults = this.get('showResults');

      if (results) {
        var totalCounts = EmberObject.create({});

        Object.keys(results).forEach(function (res) {
          if (showResults) {
            totalCounts.set(res, results.get(res).total);
          } else {
            totalCounts.set(res, 0);
          }
        });
        return totalCounts;
      }
    }),

    totalPages: computed('results', 'group', function () {
      var group = this.get('group');
      if (this.get('results')) {
        return this.get('results').get(group).totalPages;
      }
    }),

    searchResults: computed('results', 'group', function () {
      var store = this.get('store');
      var group = this.get('group');
      if (this.get('results')) {
        var results = this.get('results').get(group).results;
        return results.map(function (res) {
          return store.peekRecord(res.resource, res.id);
        });
      }
    }),

    columnList: computed('group', function () {
      return columns[this.get('group')];
    }),

    maxWidthForColumn: function maxWidthForColumn(column) {
      if (column === 'convComposite' || column === 'userComposite' || column === 'orgComposite' || column === 'fullname' || column === 'name') {
        return null;
      } else if (column === 'userAvatar' || column === 'organizationAvatar') {
        return 65;
      } else if (column === 'id') {
        return 60;
      } else {
        return 150;
      }
    },
    minWidthForColumn: function minWidthForColumn(column) {
      if (column === 'convComposite' || column === 'userComposite' || column === 'orgComposite') {
        return 300;
      } else if (column === 'fullname' || column === 'name') {
        return 200;
      } else if (column === 'userAvatar' || column === 'organizationAvatar') {
        return 65;
      } else {
        return 60;
      }
    },
    focusInput: function focusInput() {
      if (this.get('preventFocus')) {
        this.set('preventFocus', false);
      } else {
        run.scheduleOnce('afterRender', this, function () {
          jQuery('#' + this.get('inputID')).focus();
        });
      }
    },


    isSearchRunning: observer('search.isRunning', function () {
      this.set('showResults', !this.get('search.isRunning'));
    }),

    // Tasks
    search: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var searchTerm, currentPage, pageLimit, offset;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              searchTerm = this.get('searchTerm');
              currentPage = parseInt(this.get('page'), 10) || 1;
              pageLimit = _environment.default.casesPageSize;
              offset = pageLimit * currentPage - pageLimit;
              _context.next = 6;
              return this.get('advancedSearch').search(searchTerm, offset, pageLimit);

            case 6:
              return _context.abrupt('return', _context.sent);

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    mergeCases: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var store, mergeConversation, ids, cases, skipSelection;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              store = this.get('store');
              mergeConversation = this.get('mergeConversation');
              ids = this.get('selectedCaseIds');
              cases = ids.map(function (id) {
                return store.peekRecord('case', id);
              });
              skipSelection = true;
              _context2.prev = 5;

              this.set('isUpdatingCases', true);

              _context2.next = 9;
              return mergeConversation.confirm({ cases: cases, skipSelection: skipSelection });

            case 9:
              _context2.next = 11;
              return this.get('search').perform();

            case 11:

              this.set('selectedCaseIds', []);

            case 12:
              _context2.prev = 12;

              this.set('isUpdatingCases', false);
              return _context2.finish(12);

            case 15:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this, [[5,, 12, 15]]);
    })).drop(),

    actions: {
      selectAll: function selectAll() {
        this.set('selectedCaseIds', this.get('searchResults').mapBy('id'));
      },
      deselectAll: function deselectAll() {
        this.set('selectedCaseIds', []);
      },
      showResultGroup: function showResultGroup(group) {
        this.set('preventFocus', true);
        this.transitionToRoute('session.agent.search', this.get('searchTerm'), {
          queryParams: {
            page: 1,
            group: group
          }
        });
      },
      openResult: function openResult(resultGroup, result, hasModifier) {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('ops-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'Search - Open a result',
            category: 'Agent'
          });
        }

        var routes = {
          USERS: 'session.agent.users.user',
          CASES: 'session.agent.cases.case',
          ORGANIZATIONS: 'session.agent.organizations.organization'
        };

        var route = routes[resultGroup];

        this.send('openSearchResult', route, result, hasModifier);
      },
      inputKeyDown: function inputKeyDown(e) {
        switch (e.keyCode) {
          case _keycodes.enter:
            {
              this.set('isDirty', true);
              this.transitionToRoute('session.agent.search', this.get('searchTerm'), {
                queryParams: {
                  page: 1,
                  group: 'CASES'
                }
              });
              e.preventDefault();
              break;
            }
        }
      },
      toggleCheck: function toggleCheck(rowCase, checked, shiftKey) {
        var selectedCaseIds = this.get('selectedCaseIds');

        if (shiftKey && selectedCaseIds.length) {
          var allCaseIds = this.get('searchResults').mapBy('id');
          var selectedRows = (0, _computeSelected.default)(rowCase.id, checked, selectedCaseIds, allCaseIds);
          this.set('selectedCaseIds', selectedRows);
          return;
        }

        if (checked) {
          selectedCaseIds.pushObject(rowCase.id);
        } else {
          selectedCaseIds.removeObject(rowCase.id);
        }
      },
      bulkUpdateComplete: function bulkUpdateComplete() {
        this.get('search').perform();
        this.send('clearSelectedCaseIds');
      },
      clearSelectedCaseIds: function clearSelectedCaseIds() {
        this.set('selectedCaseIds', []);
      },
      trashCases: function trashCases() {
        var _this = this;

        return this.get('confirmation').confirm({
          intlConfirmationBody: 'generic.confirm.trashcases'
        }).then(function () {
          _this.set('isUpdatingCases', true);
          return _this.get('bulkService').trashCases(_this.get('selectedCaseIds')).then(function (notificationResponse) {
            if (!(notificationResponse.notifications && notificationResponse.notifications[0].type === 'ERROR')) {
              _this.get('notification').success(_this.get('i18n').t('generic.casestrashed'));
              _this.get('search').perform();
            }
          }).catch(function () {
            _this.get('notification').error(_this.get('i18n').t('generic.case_trashing_failed'));
          });
        }).finally(function () {
          return _this.set('isUpdatingCases', false);
        });
      }
    }
  });
});