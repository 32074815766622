define('frontend-cp/components/ko-notification-center/component', ['exports', 'ember-service/inject', 'ember-concurrency', 'frontend-cp/services/sound-alerts'], function (exports, _inject, _emberConcurrency, _soundAlerts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var Component = Ember.Component;
  var run = Ember.run;
  var isEmpty = Ember.isEmpty;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    tagName: '',

    store: service(),
    notificationCenter: service(),
    session: service(),
    browserTab: service(),
    soundAlerts: service(),
    notificationPreferences: service(),
    profilePreferences: service(),

    hasNewNotification: false,
    showPreferencesModal: false,

    hasMore: computed.readOnly('notificationCenter.hasMore'),

    notifications: computed.filterBy('notificationCenter.notifications', 'activity.isActivity'),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('getNotifications').perform();
    },


    notificationChannel: computed(function () {
      return this.get('session.user.notificationChannel');
    }),

    /**
     * Task to paginate over notifications. Not droppable
     * since there is no way to trigger this task
     * multiple times.
     */
    getNotifications: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(reload) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('notificationCenter').paginate(reload);

            case 2:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    markAllAsRead: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              this._removeUnreadMark();
              _context2.next = 3;
              return this.get('notificationCenter').markAllAsRead();

            case 3:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    markAllAsSeen: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              this._removeUnreadMark();
              _context3.next = 3;
              return this.get('notificationCenter').markAllAsSeen();

            case 3:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).drop(),

    _showUnreadMark: function _showUnreadMark() {
      this.set('hasNewNotification', true);
    },
    _removeUnreadMark: function _removeUnreadMark() {
      this.set('hasNewNotification', false);
    },
    _refreshNotifications: function _refreshNotifications(reload) {
      var _this = this;

      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('release-live-chat-notification-improvements')) {
        var before = this.get('notifications');
        this.get('getNotifications').perform(reload).then(function () {
          var after = _this.get('notifications');
          var newNotifications = after.filter(function (notification) {
            return !before.includes(notification);
          }).filter(function (notification) {
            return _this._isDesktopNotification(notification);
          }).filter(function (notification) {
            return _this._isSoundPreferenceEnabled(notification);
          });

          if (!isEmpty(newNotifications)) {
            _this.get('soundAlerts').play(_soundAlerts.DONK);
          }
        });
      } else {
        this.get('getNotifications').perform(reload);
      }
    },
    _isDesktopNotification: function _isDesktopNotification(notification) {
      var notificationType = notification.get('notificationType');
      return this.get('notificationPreferences').isDesktopNotification(notificationType);
    },
    _isSoundPreferenceEnabled: function _isSoundPreferenceEnabled(notification) {
      var profilePreferences = this.get('profilePreferences');

      if (!profilePreferences.get('preferences.desktopSoundAlerts')) {
        return false;
      }

      return true;
    },


    actions: {
      closeDropdown: function closeDropdown(dropdown) {
        // Using 'run.later' to keep dropdown from closing before the route
        // transitioning happens
        run.later(function () {
          dropdown.actions.close();
        });
      },
      showPreferences: function showPreferences(e) {
        e.preventDefault();
        this.set('showPreferencesModal', true);
      },
      closePreferecesModal: function closePreferecesModal() {
        this.set('showPreferencesModal', false);
      },
      refreshNotifications: function refreshNotifications() {
        var launchDarkly = this.get('launchDarkly');

        var reload = true;

        this._refreshNotifications(reload);
        this._showUnreadMark();

        if (launchDarkly.get('release-live-chat-notification-improvements')) {
          this.get('browserTab').registerAppUpdate();
        }
      },
      removePaginatedNotifications: function removePaginatedNotifications() {
        this.get('notificationCenter').removePaginatedNotifications();
      }
    }
  });
});