define('frontend-cp/components/ko-admin/rateplans/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // attributes
    rateplan: null,
    selectedPlan: null,
    isDisabled: false,
    selectedTerm: null,
    selectedNumberOfSeats: '0', // make sure it is string
    seatsLimit: '0', // make sure it is string
    grossTotal: 0,
    calculatingSummary: false,
    nextChargeDate: null,
    subscriptionAmount: 0,
    discountAmount: 0,
    stickySummary: false,

    numberOfCollaborators: computed('rateplan', 'selectedTerm', function () {
      var selectedTerm = this.get('selectedTerm');
      // can sometimes be undefined
      return this.get('rateplan.' + selectedTerm + '.collaborators') || 0;
    }),

    isSelected: computed('selectedPlan.{key,productId}', 'rateplan.{key,productId}', function () {
      var selectedKey = this.get('selectedPlan.key');
      var selectedProductId = this.get('selectedPlan.productId');
      var myKey = this.get('rateplan.key');
      var myProductId = this.get('rateplan.productId');

      return selectedKey === myKey && selectedProductId === myProductId;
    })
  });
});