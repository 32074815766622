define("frontend-cp/components/ko-toggle/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "toggle-border-radius": "31px",
    "toggle": "ko-toggle__toggle_1lf7sj",
    "disabled": "ko-toggle__disabled_1lf7sj",
    "container": "ko-toggle__container_1lf7sj",
    "container--activated": "ko-toggle__container--activated_1lf7sj",
    "container--micro": "ko-toggle__container--micro_1lf7sj",
    "slider": "ko-toggle__slider_1lf7sj",
    "slider--activated": "ko-toggle__slider--activated_1lf7sj",
    "slider--micro": "ko-toggle__slider--micro_1lf7sj",
    "label": "ko-toggle__label_1lf7sj",
    "label--micro": "ko-toggle__label--micro_1lf7sj"
  };
});