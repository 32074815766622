define("frontend-cp/components/app-version-notifier/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "app-version-notifier": "app-version-notifier__app-version-notifier_j4nb27",
    "button": "app-version-notifier__button_j4nb27"
  };
});