define('frontend-cp/components/ko-advanced-search/component', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  var service = Ember.inject.service;


  var columns = {
    CASES: ['id', 'subject', 'priority.label', 'status.label', 'assignedAgent.fullName', 'updatedAt'],
    USERS: ['id', 'fullName', 'primaryEmail.email', 'organization.name', 'lastActivityAt'],
    ORGANIZATIONS: ['id', 'name', 'updatedAt']
  };

  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    tagName: '',
    resultGroup: null,
    results: null,
    searchTerm: null,
    metrics: service(),

    columnList: computed('resultGroup', function () {
      return columns[this.get('resultGroup')];
    }),

    isDate: function isDate(column) {
      return column === 'updatedAt' || column === 'lastActivityAt';
    },
    maxWidthForColumn: function maxWidthForColumn(column) {
      if (column === 'subject' || column === 'fullName' || column === 'name') {
        return null;
      } else {
        return 150;
      }
    },
    minWidthForColumn: function minWidthForColumn(column) {
      if (column === 'subject' || column === 'fullName' || column === 'name') {
        return 200;
      } else {
        return 60;
      }
    },


    actions: {
      openSearchResult: function openSearchResult(resultGroup, result, hasModifier) {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('ops-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'Search - Open a result',
            category: 'Agent'
          });
        }

        switch (resultGroup) {
          case 'USERS':
            this.attrs.onLoadSearchRoute('session.agent.users.user', result.get('fullName'), result.id, hasModifier);
            break;
          case 'CASES':
            this.attrs.onLoadSearchRoute('session.agent.cases.case', result.get('subject'), result.id, hasModifier);
            break;
          case 'ORGANIZATIONS':
            this.attrs.onLoadSearchRoute('session.agent.organizations.organization', result.get('name'), result.id, hasModifier);
            break;
        }
      }
    }
  });
});