define("frontend-cp/components/ko-journey-timeline/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-journey-timeline__container_1qz16u",
    "loader": "ko-journey-timeline__loader_1qz16u",
    "loader--idle": "ko-journey-timeline__loader--idle_1qz16u ko-journey-timeline__loader_1qz16u",
    "loader--running": "ko-journey-timeline__loader--running_1qz16u ko-journey-timeline__loader_1qz16u",
    "item": "ko-journey-timeline__item_1qz16u",
    "event-url-container": "ko-journey-timeline__event-url-container_1qz16u",
    "located": "ko-journey-timeline__located_1qz16u",
    "standard": "ko-journey-timeline__standard_1qz16u",
    "note": "ko-journey-timeline__note_1qz16u",
    "activity-container": "ko-journey-timeline__activity-container_1qz16u",
    "event-url": "ko-journey-timeline__event-url_1qz16u"
  };
});