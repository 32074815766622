define("frontend-cp/components/ko-insights/custom-reports/form/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "team-sharing": "ko-insights_custom-reports_form__team-sharing_19xi7j",
    "team-selector": "ko-insights_custom-reports_form__team-selector_19xi7j",
    "download-button": "ko-insights_custom-reports_form__download-button_19xi7j"
  };
});