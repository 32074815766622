define('frontend-cp/components/ko-insights/section/sla-overview/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // attrs
    startAt: null,
    endAt: null,
    interval: null,
    slaId: null,
    metricsQueryParams: null,
    slaQueryTerm: '',

    onActorChange: function onActorChange() {},
    onDateRangeChange: function onDateRangeChange() {},
    onIntervalChange: function onIntervalChange() {}
  });
});