define('frontend-cp/components/ko-insights/actor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    titleField: 'fullName',

    isLoading: false,
    showAvatarImage: true,
    actorAvatar: null,
    selected: null,
    searchField: null,
    options: null,
    onChange: null,
    search: null,
    description: null
  });
});