define('frontend-cp/session/admin/messenger/configure/route', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    launchDarkly: (0, _inject.default)(),

    confirmation: service(),
    model: function model() {
      var launchDarkly = this.get('launchDarkly');

      var store = this.get('store');
      var brand = store.findAll('brand');

      var messengerSetting = undefined;
      var businessHour = undefined;

      if (launchDarkly.get('release-messenger-persistent-settings')) {
        messengerSetting = store.findAll('messenger-setting');
        businessHour = store.findAll('business-hour');
      }

      var twitterAccounts = store.findAll('twitter-account');
      var metrics = store.queryRecord('conversation-starter', {});

      return RSVP.hash({
        brand: brand,
        twitterAccounts: twitterAccounts,
        metrics: metrics,
        messengerSetting: messengerSetting,
        businessHour: businessHour
      });
    },


    discardChanges: false,
    // Actions
    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        var launchDarkly = this.get('launchDarkly');

        if (!launchDarkly.get('release-messenger-persistent-settings')) {
          return;
        }
        var isEdited = this.controller.get('isEdited');

        if (typeof isEdited === 'function') {
          isEdited = Reflect.apply(isEdited, this.controller, []);
        }

        if (!this.discardChanges && isEdited) {
          transition.abort();
          this.get('confirmation').confirm({
            intlConfirmationHeader: 'generic.confirm.lose_changes_header',
            intlConfirmationBody: 'generic.confirm.lose_changes',
            intlConfirmLabel: 'generic.confirm.lose_changes_button'
          }).then(function () {
            _this.discardChanges = true;
            transition.retry();
          });
        }
        return true;
      },
      didTransition: function didTransition() {
        this.discardChanges = false;
      }
    }
  });
});