define('frontend-cp/components/ko-admin/apps/manage/edit/prompt/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;


  // hack for now - in future it'll just be type "app" and
  // the path will be a separate field
  var APP_TYPE_REGEX = /^app:(.*)$/;

  exports.default = Component.extend({
    tagName: '',

    prompt: null,
    app: null,

    inputType: computed('prompt.inputType', function () {
      var type = this.get('prompt.inputType');

      var appMatch = type.match(APP_TYPE_REGEX);
      if (appMatch) {
        return 'app';
      } else {
        return type;
      }
    }),

    appURL: computed('prompt.inputType', 'app.slots.firstObject', function () {
      var type = this.get('prompt.inputType');
      var appMatch = type.match(APP_TYPE_REGEX);
      if (!appMatch) {
        return;
      }

      var path = appMatch[1];
      var url = this.get('app.slots.firstObject.url');

      // we know that the URL will be of the form https://foo.cdn.net/${app-name}/${version}/${path/to/index.html}
      var prefixMatch = url.match(/^(https:\/\/[^/]+\/[^/]+\/[^/]+\/)/);
      if (!prefixMatch) {
        return;
      }

      var prefix = prefixMatch[1];
      return '' + prefix + path;
    })

  });
});