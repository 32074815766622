define("frontend-cp/components/ko-info-bar/field/drill-down/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-info-bar_field_drill-down__trigger_1ipjjv",
    "dropdown": "ko-info-bar_field_drill-down__dropdown_1ipjjv _dropdown_1ilz5g _dropdown_jvqwfg"
  };
});