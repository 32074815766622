define('frontend-cp/session/agent/insights/general/agents/controller', ['exports', 'ember-service/inject', 'frontend-cp/mixins/insights-control-actions'], function (exports, _inject, _insightsControlActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var observer = Ember.observer;
  var run = Ember.run;
  var service = Ember.inject.service;
  exports.default = Controller.extend(_insightsControlActions.default, {
    launchDarkly: (0, _inject.default)(),

    metrics: service(),

    queryParams: ['agent'],

    metricsQueryParams: null,

    agent: null,
    sla: null,

    metricsObserver: observer('agent', 'sla', function () {
      run.debounce(this, '_trackEvent', 150);
    }),

    _trackEvent: function _trackEvent() {
      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('ops-event-tracking')) {
        this.get('metrics').trackEvent({
          event: 'Insights - Toggle filter',
          category: 'Agent'
        });
      }
    }
  });
});