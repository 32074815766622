define("frontend-cp/components/ko-admin/engagement-predicate-builder/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-admin_engagement-predicate-builder__container_rtfpf0",
    "fadeIn": "ko-admin_engagement-predicate-builder__fadeIn_rtfpf0",
    "containerRemoving": "ko-admin_engagement-predicate-builder__containerRemoving_rtfpf0",
    "label": "ko-admin_engagement-predicate-builder__label_rtfpf0",
    "remove": "ko-admin_engagement-predicate-builder__remove_rtfpf0",
    "add": "ko-admin_engagement-predicate-builder__add_rtfpf0",
    "new": "ko-admin_engagement-predicate-builder__new_rtfpf0"
  };
});