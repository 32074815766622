define('frontend-cp/services/analytics', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    launchDarkly: (0, _inject.default)(),

    metrics: service(),
    session: service(),
    plan: service(),

    setup: function setup() {
      var launchDarkly = this.get('launchDarkly');

      var user = void 0;
      if (launchDarkly.get('release-remember-me')) {
        user = this.get('session.user');
      } else {
        user = this.get('session.session.user');
      }

      var instanceId = this.get('session.session.instanceId');
      var planName = this.get('plan.plan.name');
      var instanceName = this.get('session.session.instanceName');

      this.set('metrics.context.groupId', instanceId);

      this.get('metrics').identify({
        distinctId: user.get('uuid'),
        name: user.get('fullName'),
        email: user.get('primaryEmail.email'),
        role_type: user.get('role.roleType'),
        created_at: user.get('createdAt'),
        instance_name: instanceName,
        locale: user.get('locale.locale')
      });

      this.get('metrics').invoke('group', {
        groupId: instanceId,
        name: instanceName,
        plan: planName
      });
    }
  });
});