define('frontend-cp/session/agent/insights/reporting/custom-reports/index/route', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;


  var PER_PAGE = 10;

  exports.default = Route.extend({
    launchDarkly: (0, _inject.default)(),

    queryParams: {
      page: { refreshModel: true }
    },

    store: service(),
    metrics: service(),
    plan: service(),

    model: function model(_ref) {
      var page = _ref.page;

      page = parseInt(page) || 1;
      var offset = (page - 1) * PER_PAGE;
      return this.get('store').query('report', { offset: offset, limit: PER_PAGE });
    },
    beforeModel: function beforeModel(transition) {
      var launchDarkly = this.get('launchDarkly');
      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('release-restrict-insights')) {
        if (!this.get('plan').has('custom_reporting') && !launchDarkly.get('feature-restrict-custom-reporting-insights')) {
          this.transitionTo('session.agent.insights.general.cases');
        }
      }
    },
    setupController: function setupController(controller, reports) {
      controller.set('reports', reports);
      controller.set('pages', Math.ceil(reports.get('meta.total') / PER_PAGE));
    },


    actions: {
      didTransition: function didTransition() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'custom_reports_viewed'
          });
        }
      }
    }
  });
});