define('frontend-cp/lib/computed/find-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (collectionName, key, value) {
    return computed(collectionName + '.@each.' + key, function () {
      return (this.get(collectionName) || []).findBy(key, value);
    });
  };

  var computed = Ember.computed;
});