define('frontend-cp/models/identity', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    isPrimary: _emberData.default.attr('boolean'),
    isValidated: _emberData.default.attr('boolean'),

    // CPs
    canBeRemoved: not('isPrimary'),
    canBeValidated: not('isValidated'),
    canBePrimarized: computed('isPrimary', 'canBeValidated', function () {
      return !this.get('isPrimary') && !this.get('canBeValidated');
    })
  });
});