define('frontend-cp/components/ko-admin/apps/manage/edit/prompt/app/component', ['exports', 'frontend-cp/components/ko-app-slot/app/component', 'frontend-cp/components/ko-app-slot/app/template', 'frontend-cp/components/ko-app-slot/app/styles'], function (exports, _component, _template, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    styles: _styles.default,

    handleUpdatePromptValue: function handleUpdatePromptValue(msg) {
      this.get('on-change')(msg.data.payload.value);
      this.replyTo(msg, { status: 'ok' });
    }
  });
});