define('frontend-cp/session/admin/people/staff/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    bulkInvitations: service(),

    model: function model() {
      return this.store.query('user', {
        role: 'COLLABORATOR',
        limit: 9999,
        fields: 'resource_type,full_name,avatar,is_enabled,is_mfa_enabled,role(resource_type,title),teams(resource_type,title)',
        include: 'role,team'
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        var infos = transition.router.currentHandlerInfos;

        if (infos[infos.length - 1].name === 'session.admin.people.staff.add') {
          if (this.get('bulkInvitations.emails.length')) {
            this.store.query('user', { role: 'COLLABORATOR', limit: 9999 }).then(function (users) {
              _this.set('controller.model', users);
              _this.get('bulkInvitations').reset();
            });
          }
        }
      },
      addTeamMembers: function addTeamMembers() {
        this.transitionTo('session.admin.people.staff.add');
      }
    }
  });
});