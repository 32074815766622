define('frontend-cp/components/ko-admin/rateplans/units/component', ['exports', 'frontend-cp/lib/keycodes'], function (exports, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'span',

    // attributes
    seatsLimit: '0',
    collaboratorsLimit: '0',
    seatsLabel: 'account.trial.agents.input.label',
    isDisabled: false,
    selectedNumberOfSeats: '0',
    keyDown: function keyDown(e) {
      if (e.keyCode === _keycodes.equalSign || e.keyCode === _keycodes.dash || e.keyCode === _keycodes.period) {
        e.preventDefault();
      }
    }
  });
});