define("frontend-cp/components/ko-radio/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "size": "15px",
    "radio": "ko-radio__radio_7jk1h4",
    "radio--disabled": "ko-radio__radio--disabled_7jk1h4",
    "container": "ko-radio__container_7jk1h4",
    "check": "ko-radio__check_7jk1h4",
    "check--on": "ko-radio__check--on_7jk1h4",
    "radio-label": "ko-radio__radio-label_7jk1h4"
  };
});