define('frontend-cp/components/ko-admin/fields/index/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    //Params:
    model: null,

    customFields: service('custom-fields'),
    notification: service(),
    i18n: service(),
    session: service(),
    confirmation: service(),

    // CPs
    systemfields: computed('model.@each.isSystem', function () {
      return this.get('model').filter(function (field) {
        return field.get('isSystem');
      });
    }),

    customfields: computed('model.@each.isEnabled', 'model.@each.sortOrder', function () {
      return this.get('model').filter(function (field) {
        return field.get('isEnabled') && !field.get('isSystem');
      }).sortBy('sortOrder');
    }),

    disabledfields: computed('model.@each.isEnabled', function () {
      return this.get('model').filter(function (field) {
        return !field.get('isEnabled');
      });
    }),

    // Actions
    actions: {
      toggleEnabledStatus: function toggleEnabledStatus(field, e) {
        var _this = this;

        e.stopPropagation();
        this.get('customFields').toggleEnabled(field).then(function () {
          var notificationMessage = void 0;
          if (field.get('isEnabled')) {
            notificationMessage = _this.get('i18n').t('admin.casefields.enabled.success_message');
          } else {
            notificationMessage = _this.get('i18n').t('admin.casefields.disabled.success_message');
          }

          _this.get('notification').success(notificationMessage);
        });
      },
      deleteField: function deleteField(field, e) {
        var _this2 = this;

        e.preventDefault();
        e.stopPropagation();
        return this.get('confirmation').confirm({
          intlConfirmationBody: 'generic.confirm.delete'
        }).then(function () {
          return field.destroyRecord();
        }).then(function () {
          var msg = _this2.get('i18n').t('admin.casefields.delete.success_message');
          _this2.get('notification').success(msg);
        });
      },
      editField: function editField(field) {
        getOwner(this).lookup('router:main').transitionTo('session.admin.customizations.case-fields.edit', field.id);
      },
      reorderCustomFields: function reorderCustomFields(orderedCustomFields) {
        this.get('customFields').reorder(this.get('model'), orderedCustomFields, this.get('session.sessionId'));
      }
    }
  });
});