define('frontend-cp/session/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    sessionService: service('session'),

    beforeModel: function beforeModel(transition) {
      this.transitionTo('session.agent');
    }
  });
});