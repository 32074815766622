define('frontend-cp/components/ko-timeline-2/list/days/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    items: null,

    helpers: {
      isThisWeek: function isThisWeek(date) {
        return (0, _moment.default)().startOf('week').isBefore(date);
      }
    }
  });
});