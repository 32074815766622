define('frontend-cp/components/ko-tabs/user/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    model: null,

    // CPs
    hasOrganization: computed.readOnly('model.organization.name'),

    actions: {
      triggerSetOrganization: function triggerSetOrganization(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        this.sendAction('setOrganizationModeOn');
      }
    }
  });
});