define('frontend-cp/helpers/contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Helper = Ember.Helper;
  exports.default = Helper.extend({
    compute: function compute(params, hash) {
      var item = params[0];
      var list = params[1];
      return list && list.length && params[1].includes(item);
    }
  });
});