define('frontend-cp/components/ko-timeline-2/list/item/attachment/component', ['exports', 'frontend-cp/components/ko-timeline-2/list/item/attachment/styles'], function (exports, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var $ = Ember.$;
  exports.default = Component.extend({
    tagName: '',

    attachment: null,
    thumbnailSize: 200,

    thumbnail: computed('attachment.thumbnails.[]', function () {
      var size = this.get('thumbnailSize');
      var filtered = this.get('attachment.thumbnails').filter(function (thumbnail) {
        var width = typeof thumbnail.get === 'function' ? thumbnail.get('width') : get(thumbnail, 'width');
        return width <= size;
      });
      return filtered.get('lastObject');
    }),

    icon: computed('attachment.name', 'attachment.attachmentType', function () {
      var name = this.get('attachment.name') || '';
      var ext = name.slice(name.lastIndexOf('.') + 1, name.length).toUpperCase();

      var mimeType = this.get('attachment.attachmentType') || '';
      var type = mimeType.slice(0, mimeType.indexOf('/')).toUpperCase();
      var subtype = mimeType.slice(mimeType.lastIndexOf('/') + 1, mimeType.length).toUpperCase();

      var icon = 'attachment/icon--file_';

      var static_icon_ext = ['AI', 'CSS', 'DOC', 'HTML', 'PDF', 'PPT', 'PSD', 'SK', 'TXT', 'XD', 'XLS', 'ZIP'];
      if (static_icon_ext.includes(ext)) {
        return icon + ext;
      }

      switch (type) {
        case 'AUDIO':
          return icon + 'Audio';
        case 'IMAGE':
          return icon + 'Image';
        case 'VIDEO':
          return icon + 'Video';
        case 'TEXT':
          return icon + 'TXT';
      }

      if (subtype) {
        if (subtype.includes('spreadsheet')) {
          return icon + 'XLS';
        }
        if (subtype.includes('presentation')) {
          return icon + 'PPT';
        }
        if (subtype.includes('image')) {
          return icon + 'Image';
        }
        if (subtype.includes('graphics')) {
          return icon + 'Image';
        }
        if (subtype.includes('document')) {
          return icon + 'DOC';
        }
        if (subtype.includes('compressed')) {
          return icon + 'ZIP';
        }
      }

      return icon + 'Generic';
    }),

    actions: {
      triggerPreview: function triggerPreview(event) {
        event.preventDefault();
        event.stopPropagation();
        $(event.currentTarget).parents('.' + _styles.default.attachment).find('.' + _styles.default.thumbnail + ' > div').click();
      }
    }
  });
});