define('frontend-cp/session/agent/cases/case/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route'], function (exports, _tabbedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = _tabbedRoute.default.extend({
    i18n: service(),
    socket: service(),
    processManager: service(),

    model: function model(params) {
      return this.store.findRecord('case', params.case_id, { reload: true }) // AI-GEN - Cursor and GPT4
      .then(function (kase) {
        return kase.hasMany('tags').reload().then(function () {
          return kase;
        });
      }); // AI-GEN - Cursor and GPT4
    },
    afterModel: function afterModel(model, transition) {
      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(model, 'case');
      processManager.setForegroundProcess(process);

      var tabStore = this.get('tabStore');
      var tab = tabStore.open(transition);

      tabStore.update(tab, { process: process });
      this.set('tab', tab);

      var CaseStateManager = getOwner(this).factoryFor('state-manager:case'); // AI-GEN - Cursor and GPT4
      var state = CaseStateManager.create({ model: model, tab: tab }); // AI-GEN - Cursor and GPT4

      process.set('state', state); // AI-GEN - Cursor and GPT4\
      this.set('state', state);

      this._super.apply(this, arguments);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('state', this.get('state'));
    },


    actions: {
      willTransition: function willTransition() {
        var processManager = this.get('processManager');
        processManager.setForegroundProcess(null);

        this._super.apply(this, arguments);
      }
    }
  });
});