define('frontend-cp/components/power-select/component', ['exports', 'ember-power-select/components/power-select', 'ember-component-inbound-actions/inbound-actions'], function (exports, _powerSelect, _inboundActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _powerSelect.default.extend(_inboundActions.default, {
    actions: {
      open: function open() {
        this.publicAPI.actions.open();
      }
    }
  });
});