define('frontend-cp/lib/scroll-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.scrollTo = scrollTo;
  exports.isVisibleInScrollArea = isVisibleInScrollArea;
  exports.isAtTop = isAtTop;
  exports.isAtBottom = isAtBottom;
  var RSVP = Ember.RSVP;
  var run = Ember.run;
  var jQuery = Ember.$;
  var TOP = exports.TOP = 'TOP';
  var MIDDLE = exports.MIDDLE = 'MIDDLE';
  var BOTTOM = exports.BOTTOM = 'BOTTOM';

  function scrollTo() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$parent = _ref.parent,
        parent = _ref$parent === undefined ? null : _ref$parent,
        _ref$element = _ref.element,
        element = _ref$element === undefined ? null : _ref$element,
        _ref$position = _ref.position,
        position = _ref$position === undefined ? BOTTOM : _ref$position,
        _ref$animated = _ref.animated,
        animated = _ref$animated === undefined ? false : _ref$animated;

    return new RSVP.Promise(function (resolve) {
      var scrollTop = void 0;
      if (element && element.length) {
        var itemTop = element.prop('offsetTop');
        var itemHeight = element.outerHeight(true);
        var parentHeight = parent.height();

        // if the item is too big to fit on the screen, position it at the top
        // so we cut off the bottom and not the top
        if (itemHeight >= parentHeight) {
          position = TOP;
        }

        switch (position) {
          case TOP:
            scrollTop = itemTop;
            break;

          case MIDDLE:
            scrollTop = itemTop - parentHeight / 2 + itemHeight / 2;
            break;

          default:
            // BOTTOM
            scrollTop = itemTop - parentHeight + itemHeight;
            break;
        }
      } else {
        // NOTE - don't support scrolling to MIDDLE of parent
        //        as that makes no sense...
        switch (position) {
          case TOP:
            scrollTop = 0;
            break;

          default:
            // BOTTOM
            scrollTop = parent.prop('scrollHeight');
            break;
        }
      }

      if (animated) {
        parent.animate({ scrollTop: scrollTop }, { duration: 'slow' }).promise().done(function () {
          return run(resolve);
        });
      } else {
        parent.scrollTop(scrollTop);
        resolve();
      }
    });
  }

  function isVisibleInScrollArea(el, parent) {
    if (el instanceof jQuery) {
      el = el[0];
    }

    if (parent instanceof jQuery) {
      parent = parent[0];
    }

    var elPos = el.getBoundingClientRect();
    var parentPos = parent.getBoundingClientRect();

    return elPos.top >= parentPos.top && elPos.top <= parentPos.bottom && elPos.bottom >= parentPos.top && elPos.bottom <= parentPos.bottom;
  }

  function isAtTop(parent) {
    var min = 0;
    var scrollTop = parent.scrollTop();
    return scrollTop <= min;
  }

  function isAtBottom(parent) {
    var max = parent.prop('scrollHeight') - parent.height();
    var scrollTop = parent.scrollTop();
    return scrollTop >= max;
  }
});