define('frontend-cp/lib/edited-custom-fields', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  exports.default = EmberObject.extend({
    // Attributes
    originalCustomFields: [],
    editedCustomFields: [],

    // CPs
    idToEditedValueHash: computed('editedCustomFields.@each.value', function () {
      var map = {};
      this.get('editedCustomFields').forEach(function (field) {
        var id = field.get('field.id');
        if (id !== null && id !== undefined) {
          map[field.get('field.id')] = field.get('value');
        }
      });
      return map;
    }),

    editedIds: computed('originalCustomFields.@each.value', 'editedCustomFields.@each.value', function () {
      var _this = this;

      return this.get('editedCustomFields').filter(function (fieldObject) {
        var originalFieldObject = _this.get('originalCustomFields').findBy('field.id', fieldObject.get('field.id'));
        if (!originalFieldObject) {
          // we do not consider field to be edited if it was undefined and became an empty string
          return Boolean(fieldObject.get('value'));
        }
        if (fieldObject.get('field.fieldType') === 'CHECKBOX') {
          // Special treatment of checkbox values since 10,12 is the same as 12,10
          var sort = function sort(value) {
            return (value || '').split(',').sort().join(',');
          };

          var stringifiedFieldObjectIsNotTheSame = sort(originalFieldObject.get('value')) !== sort(fieldObject.get('value'));
          var fieldObjectHasChangedType = _typeof(originalFieldObject.get('value')) !== _typeof(fieldObject.get('value'));

          return stringifiedFieldObjectIsNotTheSame || fieldObjectHasChangedType;
        } else {
          return originalFieldObject.get('value') !== fieldObject.get('value');
        }
      }).map(function (fieldObject) {
        return fieldObject.get('field.id');
      });
    }),

    idToIsEditedHash: computed('editedIds.[]', function () {
      var map = {};
      this.get('editedIds').forEach(function (field) {
        map[field] = true;
      });
      return map;
    }),

    isEdited: computed('editedIds.[]', function () {
      return this.get('editedIds.length') > 0;
    }),

    // Methods
    setValue: function setValue(field, value) {
      var valueObject = this.get('editedCustomFields').findBy('field.id', field.get('id'));

      if (!valueObject) {
        valueObject = EmberObject.create({ field: field, value: value });
        this.get('editedCustomFields').pushObject(valueObject);
      }

      var isCheckboxBeingUnset = field.get('fieldType') === 'CHECKBOX' && value === null;
      if (isCheckboxBeingUnset) {
        valueObject.set('value', null);
      } else {
        valueObject.set('value', value || '');
      }
    }
  });
});