define('frontend-cp/components/ko-identities/form/component', ['exports', 'frontend-cp/utils/format-validations'], function (exports, _formatValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var EmberPromise = Ember.RSVP.Promise;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var isBlank = Ember.isBlank;


  var titles = {
    'identity-email': 'generic.identities.add.email',
    'identity-twitter': 'generic.identities.add.twitter',
    'identity-phone': 'generic.identities.add.phone'
  };
  var placeholders = {
    'identity-email': 'generic.identities.placeholders.email',
    'identity-twitter': 'generic.identities.placeholders.twitter',
    'identity-phone': 'generic.identities.placeholders.phone'
  };
  var inputTypes = {
    'identity-email': 'email',
    'identity-twitter': 'text',
    'identity-phone': 'tel'
  };

  exports.default = Component.extend({
    localClassNames: ['form'],
    i18n: service(),
    // CPs
    title: computed('identity', function () {
      return titles[this.get('identity.constructor.modelName')];
    }),

    placeholder: computed('identity', function () {
      return placeholders[this.get('identity.constructor.modelName')];
    }),

    inputType: computed('identity', function () {
      return inputTypes[this.get('identity.constructor.modelName')];
    }),

    didInsertElement: function didInsertElement() {
      this.$('input').focus();
    },


    // Actions
    actions: {
      save: function save() {
        var identity = this.get('identity');
        identity.get('errors').clear();
        if (identity.constructor.modelName === 'identity-email') {
          return this.saveEmail(this.get('mainField').trim());
        } else if (identity.constructor.modelName === 'identity-twitter') {
          return this.saveTwitter(this.get('mainField').trim());
        } else if (identity.constructor.modelName === 'identity-phone') {
          return this.savePhone(this.get('mainField').trim());
        }
      },
      cancel: function cancel(e) {
        e.preventDefault();
        this.attrs.cancel();
      }
    },

    // Methods
    saveEmail: function saveEmail(email) {
      var identity = this.get('identity');
      if ((0, _formatValidations.validateEmailFormat)(email)) {
        identity.set('email', email);
        return this.attrs.save(identity);
      } else {
        var message = this.get('i18n').t('generic.identities.errors.invalid_email_format');
        identity.get('errors').add('email', message);
        return EmberPromise.reject(false);
      }
    },
    saveTwitter: function saveTwitter(screenName) {
      var identity = this.get('identity');
      if (screenName.indexOf('@') !== 0) {
        screenName = '@' + screenName; // Add @sign for validation
      }
      if ((0, _formatValidations.validateTwitterHandleFormat)(screenName)) {
        identity.set('screenName', screenName.slice(1)); // Remove @ before save
        return this.attrs.save(identity);
      } else {
        var message = this.get('i18n').t('generic.identities.errors.invalid_twitter_handle_format');
        identity.get('errors').add('screenName', message);
        return EmberPromise.reject(false);
      }
    },
    savePhone: function savePhone(number) {
      var sanitizedNumber = number.replace(/[^0-9+]/g, '');
      if (isBlank(sanitizedNumber)) {
        return EmberPromise.reject(false);
      }
      var identity = this.get('identity');
      identity.set('number', sanitizedNumber);
      return this.attrs.save(identity);
    }
  });
});