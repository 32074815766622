define('frontend-cp/components/ko-modal/component', ['exports', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend(_component.default, {
    tagName: '',

    // Attributes
    width: '720px',
    showHeader: true,
    chromelessContent: false,
    qaClass: null,
    'on-close': null,
    'on-confirm': null,
    noHeaderContent: false,
    noFooter: false,
    borderTop: true,
    borderBottom: true,

    keyboardShortcuts: {
      esc: function esc() {
        var closeAction = this.get('on-close');
        closeAction && closeAction();
      },

      enter: function enter() {
        var confirmAction = this.get('on-confirm');
        confirmAction && confirmAction();
      }
    },

    // CPs
    modalStyle: computed('width', function () {
      return htmlSafe('width: ' + this.get('width'));
    })
  });
});