define('frontend-cp/services/notification-center', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Service.extend({
    launchDarkly: (0, _inject.default)(),

    store: service(),
    errorHandler: service(),
    totalItems: null,
    page: 0,
    perPage: 20,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('notifications', []);
    },
    reset: function reset() {
      this.set('notifications', []);
      this.set('page', 0);
    },


    hasMore: computed('notifications.[]', function () {
      var notificationsCount = this.get('notifications').length;
      return this.get('totalItems') === null || this.get('totalItems') > notificationsCount;
    }),

    /**
     * Paginate over list of notifications. This method will
     * take care of finding out whether to make the network
     * request or not. Use `this.hasMore` property to know
     * if more results are to be paginated.
     *
     * @method paginate
     *
     * @return {Promise}
     */
    paginate: function paginate(reload) {
      var _this = this;

      if (!this.get('hasMore')) {
        return;
      }

      if (reload) {
        this.set('page', 0);
      }

      /**
       * Increment the page
       */
      this.incrementProperty('page', 1);
      return this.get('store').query('notification', this.getParams()).then(function (response) {
        _this.set('totalItems', response.get('meta.total'));
        if (reload) {
          _this.get('notifications').clear();
        }
        _this.get('notifications').addObjects(response);
      });
    },


    /**
     * Return the offset, limit and include params for fetching notifications.
     *
     * @method getParams
     *
     * @return {Object}
     */
    getParams: function getParams() {
      var launchDarkly = this.get('launchDarkly');

      var result = this.getOffsetAndLimit();

      if (launchDarkly.get('release-optimize-notification-center-include')) {
        result.include = 'activity,case,user,action';
      }

      return result;
    },


    /**
     * Returns the offset and limit to be used for fetching
     * notifications
     *
     * @method getOffsetAndLimit
     *
     * @return {Object}
     */
    getOffsetAndLimit: function getOffsetAndLimit() {
      var perPage = this.get('perPage');
      var page = this.get('page');

      return {
        offset: page === 1 ? 0 : perPage * (page - 1),
        limit: perPage
      };
    },


    /**
     * Marks all notifications as read
     *
     * @method markAllAsRead
     *
     * @return {Promise}
     */
    markAllAsRead: function markAllAsRead() {
      /**
       * An array of unread notifications
       */
      var unreads = this.get('notifications').filter(function (notif) {
        return notif.get('readState') !== 'READ';
      });
      if (!unreads.length) {
        return;
      }

      /**
       * Getting the id of first notification to mark it as READ
       * since everything below it will be marked as READ too
       */
      var maxId = this.get('notifications.firstObject.id');

      /**
       * Locally updating the state of notifications
       */
      unreads.forEach(function (notif) {
        return notif.set('readState', 'READ');
      });

      return this.get('store').adapterFor('notification').markAllAs(maxId, 'READ').catch(function () {
        unreads.forEach(function (notification) {
          return notification.set('readState', 'SEEN');
        });
      });
    },


    /**
     * Returns notification for a given id
     *
     * @method getNotificationById
     *
     * @param  {Number}             id
     *
     * @return {Promise}
     */
    getNotificationById: function getNotificationById(id) {
      return this.get('store').peekRecord('notification', id);
    },


    /**
     * Marks all notifications as seen
     *
     * @method markAllAsSeen
     *
     * @return {Promise}
     */
    markAllAsSeen: function markAllAsSeen() {
      var unseens = this.get('notifications').filter(function (notif) {
        return notif.get('readState') === 'UNSEEN';
      });
      if (!unseens.length) {
        return;
      }

      /**
       * Getting the id of first notification to mark it as SEEN
       * since everything below it will be marked as SEEN too
       */
      var maxId = this.get('notifications.firstObject.id');

      return this.get('store').adapterFor('notification').markAllAs(maxId, 'SEEN').catch(function () {
        unseens.forEach(function (notification) {
          return notification.set('readState', 'UNSEEN');
        });
      });
    },


    /**
     * Mark a single notification as read
     *
     * @method markAsRead
     *
     * @param  {Number}   id
     *
     * @return {Promise}
     */
    markAsRead: function markAsRead(id) {
      var _this2 = this;

      var notification = this.getNotificationById(id);
      if (notification) {
        notification.set('readState', 'READ');
      }

      return this.get('errorHandler').disableWhile(function () {
        return _this2.get('store').adapterFor('notification').markAs(id, 'READ').catch(function () {
          if (notification) {
            notification.set('readState', 'SEEN');
          }
        });
      });
    },


    /**
     * Mark a single notification as seen
     *
     * @method markAsSeen
     *
     * @param  {Object}   notification
     *
     * @return {Promise}
     */
    markAsSeen: function markAsSeen(notification) {
      notification.set('readState', 'SEEN');
      return this.get('store').adapterFor('notification').markAs(notification.get('id'), 'SEEN').catch(function () {
        notification.set('readState', 'UNSEEN');
      });
    },


    /**
     * When notification dropdown is closed, we are removing all notifications
     * after first page (first 20 items) since if user has loaded 100+ notifications,
     * the dropdown would lag while opening next time
     *
     * @method removePaginatedNotifications
     */
    removePaginatedNotifications: function removePaginatedNotifications() {
      var notifications = this.get('notifications');
      var perPage = this.get('perPage');
      var totalCount = notifications.get('length');

      if (totalCount > perPage) {
        notifications.removeAt(perPage, totalCount);
        this.set('page', 1);
      }
    }
  });
});