define('frontend-cp/session/admin/channels/twitter/reauthorize/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    notification: service(),
    i18n: service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var params = transition.queryParams;

      var adapter = getOwner(this).lookup('adapter:application');
      var url = adapter.namespace + '/twitter/account/reauthorize';

      if (params.oauth_token && params.oauth_verifier) {
        var options = {
          data: {
            oauthToken: params.oauth_token,
            oauthVerifier: params.oauth_verifier
          }
        };

        adapter.ajax(url, 'PUT', options).then(function () {
          _this.get('notification').add({
            type: 'success',
            title: _this.get('i18n').t('generic.changes_saved'),
            autodismiss: true
          });
        }).finally(function () {
          _this.transitionTo('session.admin.channels.twitter.index');
        });
      } else if (params.denied) {
        this.transitionTo('session.admin.channels.twitter.index');
      }
    }
  });
});