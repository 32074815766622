define('frontend-cp/serializers/device-token', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var data = payload.data || [];
      data = data.map(function (item) {
        return {
          id: item.type,
          attributes: {
            'device-type': item.type,
            value: item.value
          },
          type: 'device_token'
        };
      });

      return this._super(store, primaryModelClass, { data: data }, id, requestType);
    }
  });
});