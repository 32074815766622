define('frontend-cp/components/ko-cases-list/column/slanextbreach/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var computed = Ember.computed;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    model: null,

    // Services
    serverClock: service(),

    // CPs
    metric: or('oldestIncompleteMetric', 'nextMetricDue'),

    oldestIncompleteMetric: computed('model.slaMetrics.@each.{isCompleted,dueAt}', function () {
      return this.get('model.slaMetrics').rejectBy('isCompleted').sortBy('dueAt').get('firstObject');
    }),

    nextMetricDue: computed('serverClock.date', 'model.slaMetrics.@each.dueAt', function () {
      var now = this.get('serverClock.date');

      return this.get('model.slaMetrics').sortBy('dueAt').find(function (metric) {
        return metric.get('dueAt') >= now;
      });
    })
  });
});