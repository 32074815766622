define('frontend-cp/models/case-reply', ['exports', 'ember-data', 'npm:uuid/v4', 'ember-data-model-fragments'], function (exports, _emberData, _v, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    clientId: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return (0, _v.default)();
      }
    }),
    contents: _emberData.default.attr('string'),
    channelType: _emberData.default.attr('string'),
    channel: _emberData.default.belongsTo('account', { async: false }),
    inReplyToUuid: _emberData.default.attr('string'),
    channelOptions: _emberDataModelFragments.default.fragment('case-reply-options'),
    status: _emberData.default.belongsTo('case-status', { async: false }),
    priority: _emberData.default.belongsTo('case-priority', { async: false }),
    caseType: _emberData.default.belongsTo('case-type', { async: false }),
    assignedTeam: _emberData.default.belongsTo('team', { async: false }),
    assignedAgent: _emberData.default.belongsTo('user', { async: false }),
    tags: _emberData.default.attr('string'),
    fieldValues: _emberDataModelFragments.default.fragmentArray('case-field-value'),
    requester: _emberData.default.belongsTo('user', { async: false, inverse: null }),
    subject: _emberData.default.attr('string', { defaultValue: '' }),
    // _filename: DS.belongsTo('?'),

    form: _emberData.default.belongsTo('case-form', { async: false }),

    attachmentFileIds: _emberData.default.attr('string'),

    case: _emberData.default.belongsTo('case', { async: true }),
    posts: _emberData.default.hasMany('post', { async: true })
  });
});