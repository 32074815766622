define('frontend-cp/components/ko-admin/messenger/component', ['exports', 'ember-concurrency', 'frontend-cp/config/environment'], function (exports, _emberConcurrency, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var isEmpty = Ember.isEmpty;
  exports.default = Component.extend({
    i18n: service(),
    notification: service(),
    metricsService: service('metrics'),
    store: service(),

    defaultLocale: '',
    brands: [],
    metrics: {},
    twitterAccounts: [],
    selectedTwitterAccount: {},
    selectedSectionId: null,
    brand: {},
    categoriesTree: [],
    activeTab: 'customize',
    primaryColor: '#F1703F',
    activeSolidColor: '#FF3B30',
    activeGradient: '-192deg, #40364D 37%, #9B4779 100%',
    activePattern: '1',
    backgroundMode: 'gradient',
    replyTimeLocale: '',

    // States
    isOnlinePresenceEnabled: true,
    isArticlesEnabled: false,
    isTwitterEnabled: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.setInitialSelectedBrand();
      this.get('fetchCategories').perform();
      var settings = this.get('store').peekAll('setting');
      this.set('defaultLocale', settings.findBy('key', 'account.default_language').get('value'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.preSelectTwitterAccount();
    },


    // CPs
    hasMultipleBrands: computed.gt('brands.length', 1),

    isTwitterConnected: computed.readOnly('twitterAccounts.length'),

    fetchCategories: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var limit, store, brandId, categories, categoryIds, sections, tree;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              limit = 999;
              store = this.get('store');
              brandId = this.get('brand.id');
              _context.next = 5;
              return store.query('category', { brandId: brandId, limit: limit });

            case 5:
              categories = _context.sent;

              if (!isEmpty(categories)) {
                _context.next = 10;
                break;
              }

              this.set('categoriesTree', []);
              this.preSelectSection();
              return _context.abrupt('return');

            case 10:
              categoryIds = categories.mapBy('id').join(',');
              _context.next = 13;
              return store.query('section', { categoryIds: categoryIds, limit: limit });

            case 13:
              sections = _context.sent;
              tree = [];


              sections.forEach(function (section) {
                var category = section.get('category');
                var sectionNode = {
                  id: section.get('id'),
                  value: section.get('title')
                };
                var categoryNode = tree.findBy('value', category.get('title'));

                if (!categoryNode) {
                  categoryNode = {
                    value: category.get('title'),
                    children: []
                  };

                  tree.push(categoryNode);
                }

                categoryNode.children.push(sectionNode);
              });

              this.set('categoriesTree', tree);
              this.preSelectSection();

            case 18:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    tweets: computed('selectedTwitterAccount', function () {
      var twitterId = this.get('selectedTwitterAccount.id');
      if (twitterId) {
        this.get('fetchTweets').perform(twitterId);
      }
      return [];
    }),

    fetchTweets: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(id) {
      var tweets;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('store').query('twitter-tweet', {
                twitterIds: id
              });

            case 2:
              tweets = _context2.sent;

              this.set('tweets', tweets);

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    hasNoSections: computed.not('categoriesTree.length'),

    articles: computed('selectedSectionId', function () {
      var store = this.get('store');
      var section_id = this.get('selectedSectionId');
      var limit = 3;

      if (section_id) {
        return store.query('article', { section_id: section_id, limit: limit });
      } else {
        return [];
      }
    }),

    isDarkSolidColor: computed('activeSolidColor', function () {
      var solidColor = this.get('activeSolidColor');
      if (this.getColorDarkness(solidColor) < 172) {
        return true;
      }
    }),

    isDarkGradient: computed('activeGradient', function () {
      var gradientValue = this.get('activeGradient');
      if (gradientValue) {
        var colors = gradientValue.match(/#\w{6}/g);
        var lightness1 = this.getColorDarkness(colors[0]);
        var lightness2 = this.getColorDarkness(colors[1]);

        if (lightness1 < 135 && lightness2 < 190) {
          return true;
        }
      }
    }),

    isLightText: computed('isDarkSolidColor', 'isDarkGradient', 'backgroundMode', function () {
      var mode = this.get('backgroundMode');
      var darkGradient = this.get('isDarkGradient');
      var darkSolidColor = this.get('isDarkSolidColor');

      if (mode === 'gradient' && darkGradient || mode === 'color' && darkSolidColor) {
        return true;
      }
    }),

    title: computed(function () {
      return this.get('i18n').t('admin.messenger.tabs.customize.title_default_value');
    }),

    welcomeMessage: computed(function () {
      return this.get('i18n').t('admin.messenger.tabs.customize.welcome_default_value', { brand: this.get('brand.name') });
    }),

    publicLocales: computed(function () {
      return this.get('store').peekAll('locale').filterBy('isPublic');
    }),

    titleLocaleFields: computed('publicLocales', function () {
      var result = this.get('publicLocales').map(function (localeModel) {
        var locale = localeModel.get('locale');
        var localeField = EmberObject.create({ locale: locale, translation: '' });
        return localeField;
      });
      return result;
    }),

    welcomeMessageLocaleFields: computed('publicLocales', function () {
      var result = this.get('publicLocales').map(function (localeModel) {
        var locale = localeModel.get('locale');
        var localeField = EmberObject.create({ locale: locale, translation: '' });
        return localeField;
      });
      return result;
    }),

    patternUrl: computed('activePattern', 'activeSolidColor', 'activeGradient', 'backgroundMode', function () {
      var mode = this.get('backgroundMode');
      var suffix = '';
      var url = '';

      if (this.get('activePattern')) {
        if (mode === 'gradient' && this.get('isDarkGradient')) {
          suffix = '--dark';
        } else if (mode === 'color') {
          if (this.getColorDarkness(this.get('activeSolidColor')) > 190) {
            suffix = '--dark';
          }
        }
        url = '' + _environment.default.messengerAssetsUrl + this.get('activePattern') + suffix + '.svg';
      }

      return url;
    }),

    generatedCode: computed('activeGradient', 'activeSolidColor', 'backgroundMode', 'brand.{domain,name,subDomain}', 'isArticlesEnabled', 'isLightText', 'isOnlinePresenceEnabled', 'isTwitterEnabled', 'patternUrl', 'primaryColor', 'selectedSectionId', 'selectedTwitterAccount.id', 'title', 'titleLocaleFields.@each.translation', 'welcomeMessage', 'welcomeMessageLocaleFields.@each.translation', 'replyTimeLocale', function () {
      var _this = this;

      var apiUrl = 'https://' + this.get('brand.subDomain') + '.' + this.get('brand.domain') + '/api/v1';
      // Escaping the quotes from brand name since they can mess up the embed code.
      var brandName = this.get('brand.name').replace(/"/g, '\\"');
      var primaryColor = this.get('primaryColor');
      var textColor = this.get('isLightText') ? '#FFFFFF' : '#2D3138';
      var presenceEnabled = this.get('isOnlinePresenceEnabled');
      var articlesEnabled = this.get('isArticlesEnabled');
      var twitterEnabled = this.get('isTwitterEnabled');
      var twitterAccountId = this.get('selectedTwitterAccount.id') || null;
      var sectionId = this.get('selectedSectionId');
      var pattern = this.get('patternUrl');
      var replyTimeLocale = this.get('replyTimeLocale');
      var background = this.get('backgroundMode') === 'color' ? this.get('activeSolidColor') : this.get('activeGradient');
      var defaultLocale = this.get('defaultLocale');
      var homeTitles = this.get('titleLocaleFields').map(function (locale) {
        return {
          locale: locale.get('locale'),
          translation: defaultLocale === locale.get('locale') ? _this.get('title') : locale.get('translation')
        };
      });
      var homeWelcomeMessages = this.get('welcomeMessageLocaleFields').map(function (locale) {
        return {
          locale: locale.get('locale'),
          translation: defaultLocale === locale.get('locale') ? _this.get('welcomeMessage') : locale.get('translation')
        };
      });

      return '<script>(function(d,a){function c(){var b=d.createElement("script");b.async=!0;b.type="text/javascript";b.src=a._settings.messengerUrl;b.crossOrigin="anonymous";var c=d.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}window.kayako=a;a.readyQueue=[];a.newEmbedCode=!0;a.ready=function(b){a.readyQueue.push(b)};a._settings={apiUrl:"' + apiUrl + '",teamName:"' + brandName + '",homeTitles:' + JSON.stringify(homeTitles) + ',homeSubtitles:' + JSON.stringify(homeWelcomeMessages) + ',replyTimeLocale:"' + replyTimeLocale + '",messengerUrl:"https://' + this.get('brand.subDomain') + '.kayakocdn.com/messenger",realtimeUrl:"' + _environment.default.kreSocket + '",widgets:{presence:{enabled:' + presenceEnabled + '},twitter:{enabled:' + twitterEnabled + ',twitterHandle:"' + twitterAccountId + '"},articles:{enabled:' + articlesEnabled + ',sectionId:' + sectionId + '}},styles:{primaryColor:"' + primaryColor + '",homeBackground:"' + background + '",homePattern:"' + pattern + '",homeTextColor:"' + textColor + '"}};window.attachEvent?window.attachEvent("onload",c):window.addEventListener("load",c,!1)})(document,window.kayako||{});</script>';
    }),

    // Pre-selects first section of first category in the dropdown
    preSelectSection: function preSelectSection() {
      var firstSectionId = this.get('categoriesTree.firstObject.children.firstObject.id');
      this.set('selectedSectionId', firstSectionId);
    },


    // Pre-selects first available twitter account
    preSelectTwitterAccount: function preSelectTwitterAccount() {
      if (this.get('twitterAccounts.length')) {
        this.set('selectedTwitterAccount', this.get('twitterAccounts.firstObject'));
      }
    },
    getColorDarkness: function getColorDarkness(hexCode) {
      var hex = hexCode.replace('#', '');
      var r = parseInt(hex.substring(0, 2), 16);
      var g = parseInt(hex.substring(2, 4), 16);
      var b = parseInt(hex.substring(4, 6), 16);
      return Math.round((parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000);
    },
    setInitialSelectedBrand: function setInitialSelectedBrand() {
      this.set('brand', this.get('brands').findBy('isDefault', true));
    },


    publish: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var brandId, name, contents;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              brandId = this.get('brand.id');
              name = 'helpcenter_kayako_messenger';
              contents = this.get('generatedCode');
              _context3.next = 5;
              return this.get('store').adapterFor('template').updateTempalte(brandId, name, contents);

            case 5:

              this.get('notification').add({
                type: 'success',
                title: this.get('brand.name') + ' ' + this.get('i18n').t('admin.messenger.tabs.embed.publish_success'),
                autodismiss: true
              });

            case 6:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    actions: {
      switchTab: function switchTab(activeTab) {
        this.set('activeTab', activeTab);
      },
      brandChanged: function brandChanged(brand) {
        this.set('brand', brand);
        this.get('fetchCategories').perform();
      },
      setSection: function setSection(sectionNode) {
        this.set('selectedSectionId', get(sectionNode, 'id'));
      },
      twitterAccountChanged: function twitterAccountChanged(account) {
        this.set('selectedTwitterAccount', account);
      },
      copiedToClipboard: function copiedToClipboard() {
        this.get('metricsService').trackEvent({
          event: 'Messenger - Copy to clipboard',
          category: 'Admin'
        });

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.copied_to_clipboard'),
          autodismiss: true
        });
      }
    }
  });
});