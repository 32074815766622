define('frontend-cp/components/ko-insights/highcharts/tooltip-formatters/stacked', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (i18n) {
    return function () {
      var tooltip = ['<b>' + i18n.formatDate(this.x, { format: 'weekdayDate' }) + '</b>'];

      this.points.forEach(function (point) {
        tooltip.push('<span style="color:' + point.series.color + '">\u25CF</span> ' + point.series.name + ': ' + i18n.formatNumber(point.y));
      });

      return tooltip.join('<br/>');
    };
  };
});