define('frontend-cp/serializers/automation-action', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    extractAttributes: function extractAttributes(modelClass, json) {
      json.attributes = json.attributes.reduce(function (obj, attr) {
        obj[attr.name] = attr.value;
        return obj;
      }, {});
      return this._super.apply(this, arguments);
    }
  });
});