define('frontend-cp/models/definition', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  exports.default = _emberData.default.Model.extend({
    i18n: service(),

    fieldName: reads('id'),
    label: _emberData.default.attr('string'),
    group: _emberData.default.attr('string'),
    definitionType: _emberData.default.attr('string'),
    inputType: _emberData.default.attr('string'),
    subType: _emberData.default.attr('string'),
    operators: _emberData.default.attr('array'),

    groupLabel: computed('label', 'group', function () {
      var group = this.get('group');
      var label = this.get('label');

      if (group) {
        return this.get('i18n').t('admin.predicate_builder.group.' + group) + ': ' + label;
      }

      return label;
    }),

    // Can be one of the following things:
    // * an empty string
    // * an object with keys as value and properties as text
    // * a unicorn
    values: _emberDataModelFragments.default.fragmentArray('definition-value-fragment')
  });
});