define('frontend-cp/processes/organization-new', ['exports', 'frontend-cp/processes/process'], function (exports, _process) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var get = Ember.get;


  var OrganizationNewProcess = _process.default.extend({
    type: 'organization-new',

    restoreModel: function restoreModel(json) {
      return EmberObject.create({
        creationTimestamp: json.modelId
      });
    },
    serialize: function serialize() {
      return {
        pid: this.get('pid'),
        type: this.get('type'),
        modelId: this.get('model.creationTimestamp')
      };
    }
  });

  OrganizationNewProcess.reopenClass({
    generatePid: function generatePid(model) {
      var id = get(model, 'creationTimestamp');
      return 'organization-new:' + id;
    }
  });

  exports.default = OrganizationNewProcess;
});