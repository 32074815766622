define('frontend-cp/serializers/team', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      members: { serialize: false },
      memberCount: { serialize: false }
    },

    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      resourceHash.links = {
        members: '/api/v1/teams/' + resourceHash.id + '/members'
      };

      return this._super.apply(this, arguments);
    }
  });
});