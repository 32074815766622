define('frontend-cp/models/activity-location', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    city: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    regionCode: _emberData.default.attr('string'),
    areaCode: _emberData.default.attr('string'),
    timeZone: _emberData.default.attr('string'),
    organization: _emberData.default.attr('string'),
    netSpeed: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    countryCode: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('string'),
    longitude: _emberData.default.attr('string'),
    metroCode: _emberData.default.attr('string'),
    isp: _emberData.default.attr('string')
  });
});