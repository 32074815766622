define('frontend-cp/components/appcues-beacon/component', ['exports', 'ember-diff-attrs'], function (exports, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    key: null,
    shouldTrack: false,

    // appcues: service(),

    didReceiveAttrs: (0, _emberDiffAttrs.default)('shouldTrack', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);
      if (!changedAttrs || this._changedFromFalseToTrue(changedAttrs.shouldTrack)) {
        this._trackEvent();
      }
    }),

    _trackEvent: function _trackEvent() {
      if (this.get('shouldTrack')) {
        // Commenting code to remove appcues dependency
        // this.get('appcues').track(this.get('key'));
      }
    },
    _changedFromFalseToTrue: function _changedFromFalseToTrue(shouldTrack) {
      if (!shouldTrack) {
        return false;
      }

      var _shouldTrack = _slicedToArray(shouldTrack, 2),
          oldValue = _shouldTrack[0],
          newValue = _shouldTrack[1];

      return oldValue === false && newValue === true;
    }
  });
});