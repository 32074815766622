define('frontend-cp/services/timeline', ['exports', 'ember-service/inject', 'npm:lodash', 'frontend-cp/timelines/post', 'frontend-cp/timelines/activity'], function (exports, _inject, _npmLodash, _post, _activity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Service = Ember.Service;
  var service = Ember.inject.service;
  var EmberPromise = Ember.RSVP.Promise;
  var getOwner = Ember.getOwner;


  var Promise = EmberPromise;

  var includeActivities = function includeActivities(filter) {
    return filter === 'all';
  };

  /**
   * Perform merge by timestamp.
   * When same-date conflicts occur, the first timeline objects will be
   * considered to be newer.
   *
   * @param {Array<DS.Model>} activities 1st timeline
   * @param {Array<DS.Model>} posts 2nd timeline
   * @param {String} direction older or newer
   * @return {Array<DS.Model>} merged timeline
   */
  var mergeTwoTimelines = function mergeTwoTimelines(activities, posts, direction) {
    var postsWithActivities = [];
    while (posts.length !== 0 || activities.length !== 0) {
      var topPost = posts[0];
      var topActivity = activities[0];

      if (!topPost) {
        postsWithActivities.push(topActivity);
        activities.splice(0, 1);
        continue;
      }

      if (!topActivity) {
        postsWithActivities.push(topPost);
        posts.splice(0, 1);
        continue;
      }

      var postDate = topPost.get('createdAt');
      var activityDate = topActivity.get('createdAt');

      if (direction === 'newer') {
        if (activityDate.getTime() < postDate.getTime()) {
          postsWithActivities.push(topActivity);
          activities.splice(0, 1);
        } else {
          postsWithActivities.push(topPost);
          posts.splice(0, 1);
        }
      } else if (direction === 'older') {
        if (postDate.getTime() > activityDate.getTime()) {
          postsWithActivities.push(topPost);
          posts.splice(0, 1);
        } else {
          postsWithActivities.push(topActivity);
          activities.splice(0, 1);
        }
      }
    }
    return postsWithActivities;
  };

  exports.default = Service.extend({
    launchDarkly: (0, _inject.default)(),

    store: service(),
    processManager: service(),

    timelineForCase: function timelineForCase(model, filter) {
      var launchDarkly = this.get('launchDarkly');

      var processManager = this.get('processManager');
      var type = model.get('isNew') ? 'case-new' : 'case';
      var process = processManager.getOrCreateProcess(model, type);
      var attr = 'timeline';

      var timeline = process && process.get(attr);

      if (!timeline) {
        if (launchDarkly.get('ops-create-timeline-objects-from-container')) {
          var Timeline = this._getTimelineFactory('post');

          timeline = Timeline.create({
            filter: filter || 'all',
            parent: model,
            limit: 30
          });
        } else {
          timeline = _post.default.create({
            filter: filter || 'all',
            parent: model,
            limit: 30,
            store: this.get('store')
          });
        }

        if (model.get('readMarker.unreadCount') > 0) {
          timeline.storeLocalReadState();
        }

        if (process) {
          process.on('willForeground', function () {
            timeline.get('restore').perform();
          });

          process.on('willBackground', function () {
            timeline.clearLocalReadState();
          });

          process.on('willDestroy', function () {
            timeline.destroy();
          });

          process.set(attr, timeline);
        }
      } else if (filter) {
        timeline.setFilter(filter);
      }

      return timeline;
    },
    timelineForCaseUser: function timelineForCaseUser(model, user) {
      var launchDarkly = this.get('launchDarkly');

      var processManager = this.get('processManager');
      var type = model.get('isNew') ? 'case-new' : 'case';
      var process = processManager.getOrCreateProcess(model, type);
      var attr = 'userTimeline';

      var timeline = process && process.get(attr);

      if (!timeline) {
        if (launchDarkly.get('ops-create-timeline-objects-from-container')) {
          var Timeline = this._getTimelineFactory('activity');

          timeline = Timeline.create({
            parent: user,
            limit: 30
          });
        } else {
          timeline = _activity.default.create({
            parent: user,
            limit: 30,
            store: this.get('store')
          });
        }

        if (process) {
          process.on('willForeground', function () {
            timeline.get('restore').perform();
          });

          process.on('willDestroy', function () {
            timeline.destroy();
          });

          process.set(attr, timeline);
        }
      }

      return timeline;
    },
    timelineForCaseOrganization: function timelineForCaseOrganization(model, organization) {
      var launchDarkly = this.get('launchDarkly');

      var processManager = this.get('processManager');
      var type = model.get('isNew') ? 'case-new' : 'case';
      var process = processManager.getOrCreateProcess(model, type);
      var attr = 'organizationTimeline';

      var timeline = process && process.get(attr);

      if (!timeline) {
        if (launchDarkly.get('ops-create-timeline-objects-from-container')) {
          var Timeline = this._getTimelineFactory('activity');

          timeline = Timeline.create({
            parent: organization,
            limit: 30
          });
        } else {
          timeline = _activity.default.create({
            parent: organization,
            limit: 30,
            store: this.get('store')
          });
        }

        if (process) {
          process.on('willForeground', function () {
            timeline.get('restore').perform();
          });

          process.on('willDestroy', function () {
            timeline.destroy();
          });

          process.set(attr, timeline);
        }
      }

      return timeline;
    },
    timelineForUser: function timelineForUser(user) {
      var launchDarkly = this.get('launchDarkly');

      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(user, 'user');
      var attr = 'timeline';

      var timeline = process && process.get(attr);

      if (!timeline) {
        if (launchDarkly.get('ops-create-timeline-objects-from-container')) {
          var Timeline = this._getTimelineFactory('activity');

          timeline = Timeline.create({
            parent: user,
            limit: 30
          });
        } else {
          timeline = _activity.default.create({
            parent: user,
            limit: 30,
            store: this.get('store')
          });
        }

        if (process) {
          process.on('willForeground', function () {
            timeline.get('restore').perform();
          });

          process.on('willDestroy', function () {
            timeline.destroy();
          });

          process.set(attr, timeline);
        }
      }

      return timeline;
    },
    timelineForUserOrganization: function timelineForUserOrganization(user, organization) {
      var launchDarkly = this.get('launchDarkly');

      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(user, 'user');
      var attr = 'organizationTimeline';

      var timeline = process && process.get(attr);

      if (!timeline) {
        if (launchDarkly.get('ops-create-timeline-objects-from-container')) {
          var Timeline = this._getTimelineFactory('activity');

          timeline = Timeline.create({
            parent: organization,
            limit: 30
          });
        } else {
          timeline = _activity.default.create({
            parent: organization,
            limit: 30,
            store: this.get('store')
          });
        }

        if (process) {
          process.on('willForeground', function () {
            timeline.get('restore').perform();
          });

          process.on('willDestroy', function () {
            timeline.destroy();
          });

          process.set(attr, timeline);
        }
      }

      return timeline;
    },
    timelineForOrganization: function timelineForOrganization(organization) {
      var launchDarkly = this.get('launchDarkly');

      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(organization, 'organization');
      var attr = 'timeline';

      var timeline = process && process.get(attr);

      if (!timeline) {
        if (launchDarkly.get('ops-create-timeline-objects-from-container')) {
          var Timeline = this._getTimelineFactory('activity');

          timeline = Timeline.create({
            parent: organization,
            limit: 30
          });
        } else {
          timeline = _activity.default.create({
            parent: organization,
            limit: 30,
            store: this.get('store')
          });
        }

        if (process) {
          process.on('willForeground', function () {
            timeline.get('restore').perform();
          });

          process.on('willDestroy', function () {
            timeline.destroy();
          });

          process.set(attr, timeline);
        }
      }

      return timeline;
    },


    /**
     * Get a single post.
     *
     * @private
     * @param {DS.Model} model model
     * @param {Number} postId post id
     * @return {Promise<DS.Model>} post
     */
    _getSinglePost: function _getSinglePost(model, postId) {
      var post = this.get('store').peekRecord('post', postId);
      if (post) {
        if (post.get('isReloading')) {
          return post.reload();
        } else {
          return Promise.resolve(post);
        }
      } else {
        post = this.get('store').createRecord('post', { id: postId });
        post.set('parent', model);
        return post.reload();
      }
    },


    /**
     * Return posts for a given parent model.
     *
     * @param {DS.Model} model model
     * @param {Number} postId reference post id
     * @param {[String]} options.direction whether to request 'older' or 'newer' posts
     * @param {[Number]} options.count post count
     * @param {[Number]} options.including whether to include the post with specified id
     * @return {Promise} posts
     */
    getPosts: function getPosts(model, postId) {
      var _this = this;

      var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
          _ref$direction = _ref.direction,
          direction = _ref$direction === undefined ? 'older' : _ref$direction,
          _ref$count = _ref.count,
          count = _ref$count === undefined ? 30 : _ref$count,
          _ref$includeActivitie = _ref.includeActivities,
          includeActivities = _ref$includeActivitie === undefined ? true : _ref$includeActivitie,
          _ref$including = _ref.including,
          including = _ref$including === undefined ? false : _ref$including;

      var posts = void 0,
          startingPost = void 0,
          morePostsAvailable = void 0;

      var startFromTop = !postId;
      var queryParamName = direction === 'older' ? 'afterId' : 'beforeId';

      if (postId) {
        posts = this._getSinglePost(model, postId).then(function (post) {
          var _this$_fetchPosts;

          startingPost = post;
          return _this._fetchPosts(model, (_this$_fetchPosts = {}, _defineProperty(_this$_fetchPosts, queryParamName, postId), _defineProperty(_this$_fetchPosts, 'limit', including ? count - 1 : count), _this$_fetchPosts)).then(function (posts) {
            return [post].concat(posts.toArray());
          });
        });
      } else {
        posts = direction === 'older' ? this._fetchPosts(model, { limit: count }) : this._fetchPosts(model, { beforeId: 0, limit: count });
      }

      return posts.then(function (posts) {
        morePostsAvailable = !(posts.get('length') < (including ? count : count + 1));

        var activities = includeActivities ? _this._getActivitiesForPosts(model, posts, direction, startFromTop, morePostsAvailable) : [];
        return Promise.resolve(activities).then(function (activities) {

          // Merge activities and posts
          var all = mergeTwoTimelines(activities, posts, direction);

          // Finally, remove initial post if it's not required
          all = including ? all : all.filter(function (p) {
            return p !== startingPost;
          });
          return { posts: all, morePostsAvailable: morePostsAvailable };
        });
      });
    },
    loadPostsAbove: function loadPostsAbove(state, _ref2) {
      var model = _ref2.model,
          filter = _ref2.filter,
          postId = _ref2.postId,
          including = _ref2.including,
          _ref2$count = _ref2.count,
          count = _ref2$count === undefined ? 30 : _ref2$count;

      if (state.get('loadingTop') || model.get('isNew')) {
        return Promise.resolve();
      }

      state.set('loadingTop', true);

      return this.getPosts(model, postId, {
        direction: 'older',
        count: count,
        including: including,
        includeActivities: includeActivities(filter)
      }).then(function (_ref3) {
        var posts = _ref3.posts,
            morePostsAvailable = _ref3.morePostsAvailable;

        var postsState = state.get('posts');

        if (!postsState) {
          state.set('posts', posts);
        } else {
          postsState.unshiftObjects(posts.reverse());
        }

        state.set('topPostsAvailable', morePostsAvailable);
      }).finally(function () {
        state.set('loadingTop', false);
      });
    },
    loadPostsBelow: function loadPostsBelow(state, _ref4) {
      var model = _ref4.model,
          filter = _ref4.filter,
          postId = _ref4.postId,
          _ref4$count = _ref4.count,
          count = _ref4$count === undefined ? 30 : _ref4$count;

      if (state.get('loadingBottom') || model.get('isNew')) {
        return Promise.resolve();
      }

      state.set('loadingBottom', true);
      return this.getPosts(model, postId, {
        direction: 'newer',
        count: count,
        includeActivities: includeActivities(filter)
      }).then(function (_ref5) {
        var posts = _ref5.posts,
            morePostsAvailable = _ref5.morePostsAvailable;

        var postsState = state.get('posts');

        if (!postsState) {
          state.set('posts', posts);
        } else {
          postsState.addObjects(posts);
        }

        state.set('loadingBottom', false);
        state.set('bottomPostsAvailable', morePostsAvailable);
      }).finally(function () {
        state.set('loadingBottom', false);
      });
    },
    loadActivitiesAbove: function loadActivitiesAbove(state, _ref6) {
      var model = _ref6.model,
          _ref6$sortOrder = _ref6.sortOrder,
          sortOrder = _ref6$sortOrder === undefined ? 'newest' : _ref6$sortOrder,
          activity = _ref6.activity,
          _ref6$count = _ref6.count,
          count = _ref6$count === undefined ? 30 : _ref6$count;

      if (state.get('loadingTop') || model.get('isNew')) {
        return Promise.resolve();
      }

      state.set('loadingTop', true);
      var since = void 0,
          until = void 0;

      function unix(date) {
        return Math.floor(date.getTime() / 1000);
      }

      if (activity) {
        var createdAt = activity.get('createdAt');
        if (sortOrder === 'oldest') {
          since = createdAt && unix(createdAt);
        } else {
          until = createdAt && unix(createdAt);
        }
      }

      return this.get('store').query('activity', {
        parent: model,
        until: until,
        since: since,
        sort_order: sortOrder === 'newest' ? 'DESC' : 'ASC',
        limit: count
      }).then(function (posts) {
        var postsAsArray = posts.toArray();
        var topPostsAvailable = postsAsArray.length >= count;
        state.get('posts').unshiftObjects(postsAsArray.reverse());
        state.set('topPostsAvailable', topPostsAvailable);
      }).finally(function () {
        state.set('loadingTop', false);
      });
    },


    /**
     * Get all activites for a range of posts.
     *
     * @private
     * @param {DS.Model} model model
     * @param {Array<DS.Model>} posts posts
     * @param {String} direction older or newer
     * @param {Boolean} startFromTop if true, activities will be fetched from the beginning of timeline
     * @param {Boolean} morePostsAvailable if true, activities to be fetched will be bounded by the timestamp of the last post
     * @return {Promise<Array<DS.Model>>} activities
     */
    _getActivitiesForPosts: function _getActivitiesForPosts(model, posts, direction, startFromTop, morePostsAvailable) {
      var _this2 = this;

      var parseNextUrl = function parseNextUrl(nextUrl) {
        return _npmLodash.default.fromPairs(nextUrl.split('&').map(function (segment) {
          return segment.split('=');
        }));
      };

      // Fetch all activities between two points in time (inclusive).
      var fetchActivities = function fetchActivities(_ref7, end, direction) {
        var since = _ref7.since,
            until = _ref7.until,
            offset = _ref7.offset;
        return _this2.get('store').query('activity', {
          parent: model,
          // since and until are exclusive
          since: since, until: until,
          offset: offset,
          sort_order: direction === 'newer' ? 'ASC' : 'DESC',
          limit: 10
        }).then(function (result) {
          var isActivityNotCreatedBeforeLastRecord = function isActivityNotCreatedBeforeLastRecord(activity) {
            return activity.get('createdAt').getTime() >= end.getTime();
          };
          var isActivityNotCreatedAfterLastRecord = function isActivityNotCreatedAfterLastRecord(activity) {
            return activity.get('createdAt').getTime() <= end.getTime();
          };
          var filteringFunction = void 0;
          if (!end) {
            filteringFunction = function filteringFunction() {
              return true;
            };
          } else if (direction === 'older') {
            filteringFunction = isActivityNotCreatedBeforeLastRecord;
          } else {
            filteringFunction = isActivityNotCreatedAfterLastRecord;
          }

          var activities = _npmLodash.default.filter(result.toArray(), filteringFunction);
          var nextUrl = result.get('meta').next;
          if (activities.length < 10 || !nextUrl) {
            return activities;
          } else {
            return fetchActivities(parseNextUrl(nextUrl), end, direction).then(function (moreActivities) {
              return activities.concat(moreActivities);
            });
          }
        });
      };

      var from = posts[0];
      var to = morePostsAvailable ? posts[posts.length - 1] : null;
      var newer = direction === 'newer';
      var start = from ? new Date(from.get('createdAt').getTime() - (newer ? 0 : 1000)).getTime() : null;
      var end = morePostsAvailable ? new Date(to.get('createdAt').getTime() - (newer ? 1000 : 0)) : null;
      var timestamps = {
        since: direction === 'newer' && !startFromTop ? Math.floor((start - 1000) / 1000) : null,
        until: direction === 'older' && !startFromTop ? Math.floor((start + 1000) / 1000) : null
      };

      return fetchActivities(timestamps, end, direction);
    },


    /**
     * Retrieves posts from the server. Returns a promise which resolves when
     * fetch is successful
     *
     * @private
     * @param {DS.Model} model model
     * @param {[Number]} options.beforeId id of the post
     * @param {[Number]} options.afterId id of the post
     * @return {Promise} promise
     */
    _fetchPosts: function _fetchPosts(model) {
      var _ref8 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref8$beforeId = _ref8.beforeId,
          beforeId = _ref8$beforeId === undefined ? null : _ref8$beforeId,
          _ref8$afterId = _ref8.afterId,
          afterId = _ref8$afterId === undefined ? null : _ref8$afterId,
          _ref8$limit = _ref8.limit,
          limit = _ref8$limit === undefined ? 10 : _ref8$limit;

      var params = {
        parent: model,
        limit: limit,
        include: 'post,user,role,identity,note,channel,case-message,mailbox,location,message-recipient,identity-email,attachment,case-reply,user-note,identity-twitter,identity-facebook,case-note,chat-message,user-minimal,facebook-comment,facebook-message,facebook-post,twitter-message,media,twitter-tweet'
      };
      if (beforeId !== null) {
        params.before_id = beforeId;
      }
      if (afterId !== null) {
        params.after_id = afterId;
      }

      return this.get('store').query('post', params).then(function (posts) {
        if (beforeId !== null) {
          return posts.toArray().reverse();
        } else {
          return posts.toArray();
        }
      });
    },
    _getTimelineFactory: function _getTimelineFactory(type) {
      return getOwner(this).factoryFor('timeline:' + type);
    }
  });
});