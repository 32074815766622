define('frontend-cp/session/admin/customizations/organization-fields/edit/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend((0, _route.default)(), {
    customFields: service(),

    model: function model(params) {
      return this.store.findRecord('organization-field', params.organization_field_id);
    },
    afterModel: function afterModel(field) {
      this.get('customFields').ensureLocaleFieldsAndOptions(field);
    },
    setupController: function setupController(controller, field) {
      controller.setProperties({ field: field });
      controller.initEdits();
    }
  });
});