define('frontend-cp/components/ko-info-bar/field/checkbox/component', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var valueToArray = function valueToArray(value) {
    return (value || '').split(',').filter(function (v) {
      return v !== '';
    });
  };

  exports.default = Component.extend({
    tagName: '',

    // Params
    title: '',
    options: null,
    isErrored: false,
    isEdited: false,
    isKREEdited: false,
    isDisabled: false,
    value: null,
    onValueChange: null,
    hasUnsetOption: false,
    dropdownIsOpen: false,

    i18n: service(),

    isChecked: function isChecked(value, id) {
      return valueToArray(value).includes(id.toString());
    },


    displayValue: computed('value', 'dropdownIsOpen', function () {
      var value = this.get('value');
      var options = this.get('options');

      if (value === null) {
        return this.get('i18n').t('generic.no_changes');
      }
      if (value === '') {
        return this.get('i18n').t('cases.options_will_be_deselected', { count: options.length });
      }
      if (value && typeof value === 'string') {
        if (this.get('dropdownIsOpen')) {
          var count = value.split(',').length;
          if (count === options.length) {
            return this.get('i18n').t('cases.all_options_will_be_selected');
          }
          return this.get('i18n').t('cases.options_will_be_selected', { count: value.split(',').length });
        }
        return value.split(',').map(function (id) {
          return options.findBy('id', id);
        }).compact().mapBy('value').join(', ');
      }
    }),

    reset: function reset(close) {
      this.sendAction('onValueChange', null);
      close();
    },


    actions: {
      valueChanged: function valueChanged(id, checked) {
        var valueAsArray = valueToArray(_npmLodash.default.clone(this.get('value')));

        if (valueAsArray.includes(id)) {
          valueAsArray.removeObject(id);
        } else {
          valueAsArray.addObject(id);
        }

        var newValue = valueAsArray.join(',');

        this.sendAction('onValueChange', newValue);
      },
      toggleDropdown: function toggleDropdown(isOpen) {
        if (isOpen && this.get('value') === null) {
          this.sendAction('onValueChange', '');
        }
        this.set('dropdownIsOpen', isOpen);
      }
    }
  });
});