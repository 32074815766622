define('frontend-cp/services/virtual-model', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEdited = exports.fragment = exports.model = exports.many = exports.attr = exports.list = undefined;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var EmberObject = Ember.Object;
  var set = Ember.set;
  var get = Ember.get;
  var map = _npmLodash.default.map,
      every = _npmLodash.default.every;
  var list = exports.list = function list() {
    return { type: 'array' };
  };
  var attr = exports.attr = function attr(options) {
    return { type: 'attribute', options: options };
  };
  var many = exports.many = function many(schema) {
    return { type: 'many', schema: schema };
  };
  // export const one = schema => ({ type: 'one', schema }); // TODO
  var model = exports.model = function model(typeName, schema) {
    return { type: 'model', typeName: typeName, schema: schema };
  };
  var fragment = exports.fragment = function fragment(typeName, schema) {
    return { type: 'fragment', typeName: typeName, schema: schema };
  };

  var isPromise = function isPromise(model) {
    return model && (model.isFulfilled === false || model.isFulfilled === true);
  };
  var getPromiseContents = function getPromiseContents(model) {
    return isPromise(model) ? model.get('content') : model;
  };

  var deepEquals = function deepEquals(_ref, left, right) {
    var type = _ref.type,
        schema = _ref.schema,
        options = _ref.options;

    if (type === 'model' || type === 'fragment') {
      return every(map(schema, function (childSchema, attribute) {
        return deepEquals(childSchema, get(left, attribute), get(right, attribute));
      }));
    } else if (type === 'attribute') {
      if (options && options.nonStrictMatching) {
        return left === right || !left && !right;
      } else {
        return getPromiseContents(left) === getPromiseContents(right);
      }
    } else if (type === 'many') {
      return get(left, 'length') === get(right, 'length') && every(left.map(function (el, index) {
        return deepEquals(schema, el, right.objectAt(index));
      }));
    } else if (type === 'array') {
      return get(left, 'length') === get(right, 'length') && every(left.map(function (el, index) {
        return el === right.objectAt(index);
      }));
    }
  };

  var isEdited = exports.isEdited = function isEdited(original, changed, schema) {
    return !deepEquals(schema, original, changed);
  };

  exports.default = Service.extend({
    store: service(),

    makeSnapshot: function makeSnapshot(model) {
      var _this = this;

      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          type = _ref2.type,
          schema = _ref2.schema;

      if (type === 'model' || type === 'fragment') {
        var object = EmberObject.create();
        _npmLodash.default.forEach(schema, function (childSchema, key) {
          object.set(key, _this.makeSnapshot(get(model, key), childSchema));
        });
        return object;
      } else if (type === 'attribute') {
        return getPromiseContents(model);
      } else if (type === 'many') {
        return model.map(function (item) {
          return _this.makeSnapshot(item, schema);
        });
      } else if (type === 'array') {
        return model.slice(0);
      }
    },
    patch: function patch(model, changedData, _ref3) {
      var _this2 = this;

      var type = _ref3.type,
          schema = _ref3.schema;

      var store = get(this, 'store');
      if (type === 'model' || type === 'fragment') {
        _npmLodash.default.forEach(schema, function (childSchema, key) {
          var newAttribute = _this2.patch(get(model, key), get(changedData, key), childSchema);
          // workaround against not being able to do model.set('children', model.get('children'))`
          if (childSchema.type !== 'many') {
            set(model, key, newAttribute);
          }
        });
        return model;
      } else if (type === 'attribute') {
        return changedData;
      } else if (type === 'many' && schema.type === 'model') {
        model.toArray().forEach(function (el) {
          var updated = schema.schema.id ? changedData.find(function (el2) {
            return el2.id && el2.id === get(el, 'id');
          }) : null;
          if (updated) {
            _this2.patch(el, updated, schema);
          } else {
            el.deleteRecord();
            // model.removeObject(el);
          }
        });
        var added = schema.schema.id ? changedData.reject(function (el) {
          return model.find(function (el2) {
            return el2.id && get(el2, 'id') === el.id;
          });
        }) : changedData;
        added.forEach(function (newRecord) {
          var record = store.createRecord(schema.typeName);
          _this2.patch(record, newRecord, schema);
          model.pushObject(record);
        });
        return model;
      } else if (type === 'many' && schema.type === 'fragment') {
        model.toArray().forEach(function (fragment) {
          return model.removeObject(fragment);
        });
        changedData.forEach(function (record) {
          var fragment = store.createFragment(schema.typeName);
          _this2.patch(fragment, record, schema);
          model.pushObject(fragment);
        });
        return model;
      } else if (type === 'many' && schema.type === 'attribute') {
        model.clear();
        changedData.forEach(function (item) {
          return model.pushObject(item);
        });
      } else if (type === 'array') {
        return changedData.slice(0);
      }
    },
    save: function save(model, editedModel, schema, adapterOptions) {
      var _this3 = this;

      var original = this.makeSnapshot(model, schema);

      this.patch(model, editedModel, schema);

      return model.save(adapterOptions).then(function () {
        return _this3.makeSnapshot(model, schema);
      }).catch(function (error) {
        _this3.copyErrors(model, editedModel);
        _this3.patch(model, original, schema);
        throw error;
      });
    },
    copyErrors: function copyErrors(model, editedModel) {
      if (!model.get('errors.errorsByAttributeName')) {
        // can happen if model isn't an EmberData model
        return;
      }
      var attributeErrors = model.get('errors.errorsByAttributeName').copy();
      var copiedErrors = EmberObject.create();
      attributeErrors.forEach(function (attrErrors, attribute) {
        copiedErrors.set(attribute, attrErrors.map(function (message) {
          return message;
        }));
      });
      editedModel.set('errors', copiedErrors);
    }
  });
});