define('frontend-cp/components/ko-insights/sidebar/component', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    tagName: '',

    plan: service(),
    permissions: service(),
    insights: service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('insights').requestSLAs().then(function (slas) {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }

        if (!isEmpty(slas)) {
          _this.set('slas', slas);
        }
      });
    },


    hasSLAs: computed('launchDarkly.feature-restrict-sla-insights', 'slas.[]', function () {
      var launchDarkly = this.get('launchDarkly');

      var slas = this.get('slas');
      var permissions = this.get('permissions');
      return !isEmpty(slas) && (this.get('plan').has('insights_sla') && permissions.has('slas.manage') || launchDarkly.get('feature-restrict-sla-insights'));
    }),

    hasHelpCenterInsights: computed('launchDarkly.feature-restrict-helpcenter-insights', function () {
      var launchDarkly = this.get('launchDarkly');

      return this.get('plan').has('helpcenter_insights') || launchDarkly.get('feature-restrict-helpcenter-insights');
    }),

    hasCustomReportingInsights: computed('launchDarkly.feature-restrict-custom-reporting-insights', function () {
      var launchDarkly = this.get('launchDarkly');

      return this.get('plan').has('custom_reporting') || launchDarkly.get('feature-restrict-custom-reporting-insights');
    }),

    hasAgentTeamInsights: computed('launchDarkly.feature-restrict-agent-team-insights', function () {
      var launchDarkly = this.get('launchDarkly');

      return this.get('plan').has('agent_team_insights') || launchDarkly.get('feature-restrict-agent-team-insights');
    })

  });
});