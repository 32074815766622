define('frontend-cp/services/cache/case-priority', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    storeCache: service(),

    getAll: function getAll() {
      var storeCache = this.get('storeCache');
      return storeCache.query('case-priority', {}, { ttl: 60000 }); // 1 minute
    },
    invalidateCache: function invalidateCache() {
      this.get('storeCache').invalidateCache('case-priority', {});
    }
  });
});