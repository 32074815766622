define('frontend-cp/session/agent/knowledgebase/article-view/controller', ['exports', 'frontend-cp/utils/is-internal-tag'], function (exports, _isInternalTag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var copy = Ember.copy;
  var set = Ember.set;
  var next = Ember.run.next;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    isEditPaneOpen: false,
    isEditPaneVisible: false,
    articleToEdit: null,
    isArticleLoading: false,

    visibleTags: computed('model.article.tags.@each.name', function () {
      return this.get('model.article.tags').filter(function (tag) {
        return !(0, _isInternalTag.default)(tag);
      });
    }),

    openEditArticlePane: function openEditArticlePane() {
      var _this = this;

      set(this, 'isEditPaneOpen', true);
      next(function () {
        set(_this, 'isEditPaneVisible', true);
      });
    },
    closeEditArticlePane: function closeEditArticlePane() {
      set(this, 'isEditPaneOpen', false);
      set(this, 'isEditPaneVisible', false);
    },
    stopPropagation: function stopPropagation(event) {
      event.stopPropagation();
    },


    actions: {
      openEditArticlePane: function openEditArticlePane() {
        this.set('articleToEdit', copy(this.get('model.article'), true));
        this.openEditArticlePane();
      },
      closeEditArticlePane: function closeEditArticlePane() {
        this.closeEditArticlePane();
      },
      onArticleSave: function onArticleSave() {
        this.set('isArticleLoading', true);
        this.send('refreshRoute');
        this.closeEditArticlePane();
      },
      stopPropagation: function stopPropagation(event) {
        this.stopPropagation(event);
      }
    }
  });
});