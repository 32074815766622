define("frontend-cp/components/ko-admin/automation-actions-builder/cascading-select/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-admin_automation-actions-builder_cascading-select__trigger_jiv8n _trigger_jvqwfg _statusIcon_jvqwfg",
    "dropdown": "ko-admin_automation-actions-builder_cascading-select__dropdown_jiv8n _dropdown_jvqwfg"
  };
});