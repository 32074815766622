define("frontend-cp/components/ko-status-pill/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "status": "ko-status-pill__status_9bnne1",
    "label": "ko-status-pill__label_9bnne1",
    "status-draft": "ko-status-pill__status-draft_9bnne1",
    "status-new": "ko-status-pill__status-new_9bnne1",
    "status-open": "ko-status-pill__status-open_9bnne1",
    "status-pendingapproval": "ko-status-pill__status-pendingapproval_9bnne1",
    "status-pending": "ko-status-pill__status-pending_9bnne1",
    "status-custom": "ko-status-pill__status-custom_9bnne1",
    "status-published": "ko-status-pill__status-published_9bnne1",
    "status-completed": "ko-status-pill__status-completed_9bnne1",
    "status-closed": "ko-status-pill__status-closed_9bnne1",
    "status-primary": "ko-status-pill__status-primary_9bnne1"
  };
});