define('frontend-cp/utils/dispatch-event', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = dispatchEvent;
  var assign = Ember.assign;
  function dispatchEvent(element, eventName) {
    var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var oEvent = document.createEvent('Events');
    oEvent.initEvent(eventName, true /* bubbles */, true /* cancelable */);

    if (opts.keyCode && !opts.charCode) {
      opts.charCode = opts.keyCode;
    }

    var defaults = {
      view: window,
      ctrlKey: false,
      altKey: false,
      shiftKey: false,
      metaKey: false
    };

    assign(oEvent, defaults);
    assign(oEvent, opts);

    element.dispatchEvent(oEvent);
  }
});