define('frontend-cp/lib/custom-field-serialization', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.serializeCustomFields = serializeCustomFields;
  exports.serializeChannelOptions = serializeChannelOptions;
  function serializeCustomFields(customFields, form) {
    var fieldValues = {};
    var formFields = form ? form.hasMany('fields').map(function (field) {
      return field.attr('key');
    }) : [];

    if (!customFields) {
      return fieldValues;
    }

    customFields.forEach(function (customField) {
      var key = customField.record.get('field.key');
      if (!form || formFields.indexOf(key) > -1) {
        // For some reason its possible for the API to get to a state where
        // the resources of the /api/v1/users contains user_fields that aren't
        // present in /api/v1/users/fields, in that case field.key is undefined
        if (key) {
          fieldValues[key] = customField.attr('value');
        } else {
          /*eslint-disable no-console */
          if (console && console.warn) {
            console.warn('Missing custom field key');
          }
          /*eslint-enable no-console */
        }
      }
    });

    return fieldValues;
  }

  function serializeChannelOptions(json, channelOptions) {
    switch (json.channel) {
      case 'MAILBOX':
      case 'MAIL':
        Reflect.deleteProperty(json.channel_options, 'type');

        json.channel_options.html = true;

        var cc = channelOptions.attr('cc');
        if (cc.length) {
          json.channel_options.cc = cc.toString();
        }
        break;
      case 'TWITTER':
        ['cc', 'html'].forEach(function (key) {
          Reflect.deleteProperty(json.channel_options, key);
        });
        break;
      case 'FACEBOOK':
        Reflect.deleteProperty(json, 'channel_options');
        break;
      case 'NOTE':
        json.channel_options = { html: true };

        var mentions = channelOptions.attr('mentions');
        if (mentions && mentions.length) {
          json.channel_options.mentions = mentions;
        }
        break;
    }

    return json;
  }
});