define('frontend-cp/serializers/definition', ['exports', 'frontend-cp/serializers/application', 'npm:lodash'], function (exports, _application, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'field',

    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      resourceHash.values = _npmLodash.default.map(resourceHash.values, function (val, id) {
        return {
          value: id,
          string: val
        };
      });
      return this._super.apply(this, arguments);
    }
  });
});