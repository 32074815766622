define('frontend-cp/session/admin/customizations/localization/settings/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var Route = Ember.Route;
  exports.default = Route.extend((0, _route.default)(), {
    model: function model() {
      return this.store.findAll('setting', { reload: true });
    },
    setupController: function setupController(controller, model) {
      controller.set('originalSettings', EmberObject.create({
        default_language: model.findBy('key', 'account.default_language').get('value'),
        timezone: model.findBy('key', 'account.timezone').get('value'),
        time_format: model.findBy('key', 'account.time_format').get('value')
      }));
      controller.set('currentSettings', EmberObject.create({
        default_language: model.findBy('key', 'account.default_language').get('value'),
        timezone: model.findBy('key', 'account.timezone').get('value'),
        time_format: model.findBy('key', 'account.time_format').get('value')
      }));
    }
  });
});