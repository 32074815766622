define("frontend-cp/components/ko-app-slot/app/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "app": "ko-app-slot_app__app_en8tsf",
    "frame": "ko-app-slot_app__frame_en8tsf"
  };
});