define('frontend-cp/components/ko-admin/macros/edit/action-handlers/status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    serialize: function serialize(action, macro, store) {
      this.reset(macro);

      if (action.value) {
        var caseStatus = store.peekRecord('case-status', action.value);
        macro.set('status', caseStatus);
      }

      return macro;
    },
    deserialize: function deserialize(macro, actions) {
      var status = macro.get('status');

      if (status) {
        actions.push({
          name: 'status',
          option: 'CHANGE',
          value: status.get('id').toString()
        });
      }

      return actions;
    },
    reset: function reset(macro) {
      macro.set('status', null);
    }
  };
});