define('frontend-cp/components/ko-info-bar/field/yesno/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    title: null,
    options: null,
    value: null,
    isErrored: false,
    isEdited: false,
    isKREEdited: false,
    isDisabled: false,
    onValueChange: null,
    hasEmptyOption: false,
    emptyLabel: null,

    // HTML
    tagName: '',

    optionsList: [EmberObject.create({ id: 'yes', value: 'Yes' }), EmberObject.create({ id: 'no', value: 'No' })]
  });
});