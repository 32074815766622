define("frontend-cp/components/ko-admin/macros/edit/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "team-sharing": "ko-admin_macros_edit__team-sharing_687nx7",
    "team-selector": "ko-admin_macros_edit__team-selector_687nx7"
  };
});