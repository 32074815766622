define('frontend-cp/models/account', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    balance: _emberData.default.attr('always-string', { defaultValue: '' }),
    billTo: _emberData.default.belongsTo('contact', { async: false }),
    billing: _emberDataModelFragments.default.fragment('billing'),
    vatId: _emberData.default.attr('always-string', { defaultValue: '' })
  });
});