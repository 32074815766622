define("frontend-cp/components/ko-table/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-table__container_143omk",
    "table": "ko-table__table_143omk"
  };
});