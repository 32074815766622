define("frontend-cp/components/ko-notification-center/group/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "list-group-title": "ko-notification-center_group__list-group-title_mma7ki",
    "list-group-items": "ko-notification-center_group__list-group-items_mma7ki"
  };
});