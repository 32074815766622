define('frontend-cp/models/contact', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    personalEmail: _emberData.default.attr('string'),
    homePhone: _emberData.default.attr('string')
  });
});