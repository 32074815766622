define("frontend-cp/components/key-value-collection-builder/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "pair": "key-value-collection-builder__pair_1dawsc",
    "remove-button": "key-value-collection-builder__remove-button_1dawsc",
    "key": "key-value-collection-builder__key_1dawsc",
    "value": "key-value-collection-builder__value_1dawsc",
    "add-btn": "key-value-collection-builder__add-btn_1dawsc"
  };
});