define('frontend-cp/services/sorter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Service = Ember.Service;
  var isEmpty = Ember.isEmpty;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({
    sort: function sort(models) {
      var _this = this;

      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref$startingIndex = _ref.startingIndex,
          startingIndex = _ref$startingIndex === undefined ? 0 : _ref$startingIndex;

      if (isEmpty(models)) {
        return;
      }
      var adapter = getOwner(this).lookup('adapter:application');
      var modelName = models.get('firstObject').constructor.modelName;
      var adapterNamespace = adapter.get('namespace');
      var modelNamespace = this._modelNamespaceFor(modelName);

      models.forEach(function (model, index) {
        model.set(_this._sortingFieldFor(modelName), index + startingIndex);
      });

      adapter.ajax(adapterNamespace + '/' + modelNamespace + '/reorder', 'PUT', {
        data: _defineProperty({}, this._idsKeyFor(modelName), models.mapBy('id').join(','))
      });
    },
    _sortingFieldFor: function _sortingFieldFor(name) {
      switch (name) {
        case 'case-priority':
          return 'level';
        case 'sla':
          return 'executionOrder';
        case 'trigger':
          return 'executionOrder';
        default:
          return 'sortOrder';
      }
    },
    _modelNamespaceFor: function _modelNamespaceFor(name) {
      switch (name) {
        case 'case-priority':
          return 'cases/priorities';
        case 'case-status':
          return 'cases/statuses';
        case 'case-field':
          return 'cases/fields';
        case 'user-field':
          return 'users/fields';
        case 'organization-field':
          return 'organizations/fields';
        case 'sla':
          return 'slas';
        case 'trigger':
          return 'triggers';
      }
    },
    _idsKeyFor: function _idsKeyFor(name) {
      switch (name) {
        case 'case-priority':
          return 'priority_ids';
        case 'case-status':
          return 'status_ids';
        case 'case-field':
          return 'field_ids';
        case 'user-field':
          return 'field_ids';
        case 'organization-field':
          return 'field_ids';
        case 'sla':
          return 'sla_ids';
        case 'trigger':
          return 'trigger_ids';
      }
    }
  });
});