define('frontend-cp/components/ko-universal-search/result/case/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    result: null,

    requesterAvatar: computed.readOnly('result.resultData.requester.avatar'),
    agentAvatar: computed.readOnly('result.resultData.assignedAgent.avatar'),
    requesterFullName: computed.readOnly('result.resultData.requester.fullName'),

    status: computed.readOnly('result.resultData.status'),
    updatedAt: computed.readOnly('result.resultData.updatedAt'),

    actions: {
      onClickRemoveIcon: function onClickRemoveIcon() {
        this.get('onClickRemoveIcon')('REMOVE', this.get('result'));
      }
    }
  });
});