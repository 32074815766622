define("frontend-cp/components/ko-timeline-2/list/activity/before-after/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "property-old-value": "ko-timeline-2_list_activity_before-after__property-old-value_1xbi5s",
    "property-avatar": "ko-timeline-2_list_activity_before-after__property-avatar_1xbi5s",
    "arrow": "ko-timeline-2_list_activity_before-after__arrow_1xbi5s"
  };
});