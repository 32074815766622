define('frontend-cp/session/admin/manage/views/edit/controller', ['exports', 'frontend-cp/services/virtual-model', 'frontend-cp/session/admin/manage/views/schema'], function (exports, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    notification: service(),
    i18n: service(),
    virtualModel: service(),
    caseListTab: service('case-list-tab'),

    schema: _schema.default,

    initEdits: function initEdits() {
      this.set('editedCaseView', this.get('virtualModel').makeSnapshot(this.get('caseView'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('caseView'), this.get('editedCaseView'), _schema.default);
    },


    actions: {
      updated: function updated() {
        this.get('notification').success(this.get('i18n').t('generic.changes_saved'));

        this.get('caseListTab').set('forceNextLoad', true);
        this.initEdits();
        this.transitionToRoute('session.admin.manage.views.index');
      },
      deleted: function deleted() {
        this.get('caseListTab').set('forceNextLoad', true);
        this.initEdits();
        this.transitionToRoute('session.admin.manage.views.index');
      },
      redirectToIndex: function redirectToIndex() {
        this.transitionToRoute('session.admin.manage.views.index');
      }
    }
  });
});