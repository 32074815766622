define("frontend-cp/components/ko-user-cases/dropdown/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "root": "ko-user-cases_dropdown__root_eej71i",
    "subject": "ko-user-cases_dropdown__subject_eej71i",
    "case-item": "ko-user-cases_dropdown__case-item_eej71i",
    "case-item-inner": "ko-user-cases_dropdown__case-item-inner_eej71i",
    "title": "ko-user-cases_dropdown__title_eej71i",
    "involved-users": "ko-user-cases_dropdown__involved-users_eej71i",
    "case-meta": "ko-user-cases_dropdown__case-meta_eej71i",
    "avatar": "ko-user-cases_dropdown__avatar_eej71i",
    "requester": "ko-user-cases_dropdown__requester_eej71i",
    "agent": "ko-user-cases_dropdown__agent_eej71i",
    "name": "ko-user-cases_dropdown__name_eej71i",
    "unassigned": "ko-user-cases_dropdown__unassigned_eej71i",
    "last-updated": "ko-user-cases_dropdown__last-updated_eej71i",
    "status": "ko-user-cases_dropdown__status_eej71i",
    "case-status": "ko-user-cases_dropdown__case-status_eej71i",
    "case-status-NEW": "ko-user-cases_dropdown__case-status-NEW_eej71i",
    "case-status-OPEN": "ko-user-cases_dropdown__case-status-OPEN_eej71i",
    "case-status-PENDING": "ko-user-cases_dropdown__case-status-PENDING_eej71i",
    "case-status-COMPLETED": "ko-user-cases_dropdown__case-status-COMPLETED_eej71i",
    "case-status-CLOSED": "ko-user-cases_dropdown__case-status-CLOSED_eej71i",
    "case-status-CUSTOM": "ko-user-cases_dropdown__case-status-CUSTOM_eej71i",
    "middot": "ko-user-cases_dropdown__middot_eej71i",
    "view-all-cases": "ko-user-cases_dropdown__view-all-cases_eej71i",
    "view-all-cases-content": "ko-user-cases_dropdown__view-all-cases-content_eej71i"
  };
});