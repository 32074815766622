define("frontend-cp/components/ko-input/search/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "search": "ko-input_search__search_njcqam",
    "input": "ko-input_search__input_njcqam ko-input__input_v1243j"
  };
});