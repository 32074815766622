define("frontend-cp/components/ko-select/typeahead-trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-select_typeahead-trigger__trigger_n46dey",
    "input": "ko-select_typeahead-trigger__input_n46dey",
    "loading": "ko-select_typeahead-trigger__loading_n46dey _loading_jvqwfg"
  };
});