define('frontend-cp/lib/is-ie', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (version, comparison) {
    var cc = 'IE';
    var b = document.createElement('B');
    var isIE = void 0;

    var docElem = document.documentElement;

    if (version) {
      cc += ' ' + version;
      if (comparison) {
        cc = comparison + ' ' + cc;
      }
    }

    b.innerHTML = '<!--[if ' + cc + ']><b id="iecctest"></b><![endif]-->';
    docElem.appendChild(b);
    isIE = !!document.getElementById('iecctest');
    docElem.removeChild(b);

    return isIE;
  };
});