define('frontend-cp/session/admin/automation/monitors/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.store.findAll('monitor', { reload: true });
    },


    // Actions
    actions: {
      edit: function edit(monitor) {
        return this.transitionTo('session.admin.automation.monitors.edit', monitor);
      }
    }
  });
});