define("frontend-cp/components/ko-change-password/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "main": "ko-change-password__main_1sh4w8",
    "policies": "ko-change-password__policies_1sh4w8",
    "policy": "ko-change-password__policy_1sh4w8",
    "tick": "ko-change-password__tick_1sh4w8",
    "is-green": "ko-change-password__is-green_1sh4w8",
    "fields": "ko-change-password__fields_1sh4w8",
    "button": "ko-change-password__button_1sh4w8",
    "label": "ko-change-password__label_1sh4w8"
  };
});