define('frontend-cp/models/business-hour', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string', { defaultValue: '' }),
    zones: _emberData.default.attr({ defaultValue: function defaultValue() {
        return [];
      } }),
    holidays: _emberData.default.hasMany('businesshour-holiday', { async: false }),
    isDefault: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});