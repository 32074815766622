define('frontend-cp/session/admin/customizations/email-templates/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend((0, _route.default)('brands'), {
    model: function model() {
      var _this = this;

      return this.store.findAll('brand').then(function (brands) {
        var brand = brands.findBy('isDefault', true);

        return RSVP.hash({
          brands: brands,
          emailCaseNotification: _this.store.queryRecord('template', { brand: brand, name: 'cases_email_notification' }),
          emailNotification: _this.store.queryRecord('template', { brand: brand, name: 'base_email_notification' }),
          emailSatisfaction: _this.store.queryRecord('template', { brand: brand, name: 'cases_email_satisfaction' })
        });
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('brands', model.brands.filterBy('isEnabled', true).sortBy('name'));
      controller.set('emailCaseNotification', model.emailCaseNotification);
      controller.set('emailNotification', model.emailNotification);
      controller.set('emailSatisfaction', model.emailSatisfaction);
      controller.initEdits();
    }
  });
});