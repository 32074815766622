define('frontend-cp/session/agent/insights/reporting/custom-reports/edit/controller', ['exports', 'ember-service/inject', 'frontend-cp/services/virtual-model', 'frontend-cp/session/agent/insights/reporting/custom-reports/schema'], function (exports, _inject, _virtualModel, _schema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    schema: _schema.default,

    virtualModel: service(),
    notification: service('notification'),
    i18n: service(),
    metrics: service(),

    initEdits: function initEdits() {
      this.set('editedReport', this.get('virtualModel').makeSnapshot(this.get('report'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('report'), this.get('editedReport'), _schema.default);
    },


    actions: {
      created: function created() {
        var launchDarkly = this.get('launchDarkly');

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'custom_reports_updated',
            object: this.get('editedReport.id')
          });
        }
        this.transitionToRoute('session.agent.insights.reporting.custom-reports.index');
      },
      cancelled: function cancelled() {
        this.transitionToRoute('session.agent.insights.reporting.custom-reports.index');
      },
      deleted: function deleted() {
        this.initEdits();
        this.transitionToRoute('session.agent.insights.reporting.custom-reports.index');
      }
    }
  });
});