define("frontend-cp/components/ko-timeline-2/jump-bar/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "background-primary": "#E5F3F7",
    "background-hover": "#D0EAF1",
    "text-color": "#4EAFCB",
    "jump-bar": "ko-timeline-2_jump-bar__jump-bar_1oo42v",
    "jump": "ko-timeline-2_jump-bar__jump_1oo42v",
    "jump-arrow": "ko-timeline-2_jump-bar__jump-arrow_1oo42v",
    "jump-text": "ko-timeline-2_jump-bar__jump-text_1oo42v",
    "jump-message": "ko-timeline-2_jump-bar__jump-message_1oo42v",
    "mark-as-seen": "ko-timeline-2_jump-bar__mark-as-seen_1oo42v",
    "mark-as-seen-action": "ko-timeline-2_jump-bar__mark-as-seen-action_1oo42v"
  };
});