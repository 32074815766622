define('frontend-cp/components/ko-admin/engagements/index/poly-graph/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;


  var defaultPlot = [0, 0, 0, 0, 0, 0, 0, 0];

  exports.default = Component.extend({

    // Attributes
    engagement: null,
    background: '#FFFFFF',
    showVisible: false,
    showClicked: false,
    showUsed: false,
    width: 160,
    height: 70,
    conversion: 0,

    points: computed('showVisible', 'showClicked', 'showUsed', function () {
      var showVisible = this.get('showVisible');
      var showClicked = this.get('showClicked');
      var showUsed = this.get('showUsed');
      if (showVisible === true) {
        return this.get('visiblePlot');
      } else if (showClicked === true) {
        return this.get('clickedPlot');
      } else if (showUsed === true) {
        return this.get('usedPlot');
      } else {
        return defaultPlot.join(' ');
      }
    }),

    count: computed('showVisible', 'showClicked', 'showUsed', function () {
      var visibleCount = this.get('engagement.metric.visible_count');
      var clickedCount = this.get('engagement.metric.clicked_count');
      var usedCount = this.get('engagement.metric.used_count');
      var showVisible = this.get('showVisible');
      var showClicked = this.get('showClicked');
      var showUsed = this.get('showUsed');
      if (visibleCount === 0) {
        return '-';
      } else if (showVisible === true) {
        return visibleCount;
      } else if (showClicked === true) {
        if (clickedCount > visibleCount) {
          return visibleCount;
        }
        return clickedCount;
      } else if (showUsed === true) {
        if (usedCount > clickedCount) {
          return clickedCount;
        }
        return usedCount;
      }
      return 0;
    }),

    visiblePlot: computed('engagement', function () {
      var visibleCount = this.get('engagement.metric.visible_count');
      var clickedCount = this.get('engagement.metric.clicked_count');
      var visiblePlot = [0, 0, 0, 0, 0, 0, 0, 0];
      if (visibleCount > 0) {
        if (clickedCount > visibleCount) {
          clickedCount = visibleCount;
        }
        visiblePlot = [0, 0, 0, this.height, this.width, this.height, this.width, 0];
        var clickedToVisible = this.height - Math.round(clickedCount / visibleCount * this.height);
        visiblePlot[7] = clickedToVisible;
      }
      return visiblePlot.join(' ');
    }),

    clickedPlot: computed('engagement', function () {
      var visibleCount = this.get('engagement.metric.visible_count');
      var clickedCount = this.get('engagement.metric.clicked_count');
      var usedCount = this.get('engagement.metric.used_count');
      var clickedPlot = [0, 0, 0, 0, 0, 0, 0, 0];
      if (visibleCount === 0) {
        return clickedPlot.join(' ');
      }
      clickedCount = clickedCount > visibleCount ? visibleCount : clickedCount;
      usedCount = usedCount > clickedCount ? clickedCount : usedCount;
      clickedPlot = [0, 0, 0, this.height, this.width, this.height, this.width, 0];
      var clickToVisible = this.height - Math.round(clickedCount / visibleCount * this.height);
      clickedPlot[1] = clickToVisible;

      var usedToVisible = this.height - Math.round(usedCount / visibleCount * this.height);
      clickedPlot[7] = usedToVisible;

      return clickedPlot.join(' ');
    }),

    usedPlot: computed('engagement', function () {
      var visibleCount = this.get('engagement.metric.visible_count');
      var clickedCount = this.get('engagement.metric.clicked_count');
      var usedCount = this.get('engagement.metric.used_count');
      var usedPlot = [0, 0, 0, 0, 0, 0, 0, 0];
      if (visibleCount === 0 || clickedCount === 0) {
        return usedPlot.join(' ');
      }
      clickedCount = clickedCount > visibleCount ? visibleCount : clickedCount;
      usedCount = usedCount > clickedCount ? clickedCount : usedCount;
      usedPlot = [0, 0, 0, this.height, this.width, this.height, this.width, 0];
      var usedToVisible = this.height - Math.round(usedCount / visibleCount * this.height);
      usedPlot[1] = usedToVisible;
      usedPlot[7] = usedToVisible;
      this.set('conversion', parseFloat(usedCount / visibleCount * 100).toFixed(2));
      return usedPlot.join(' ');
    })
  });
});