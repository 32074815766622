define('frontend-cp/lib/facade/channel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  exports.default = EmberObject.extend({
    id: computed.readOnly('channel.id'),
    groupName: computed.readOnly('channel.groupName'),
    disabled: computed.readOnly('channel.disabled'),
    handle: computed.readOnly('channel.handle'),
    channelType: computed.readOnly('channel.channelType'),
    isOnline: false,

    shouldDeliverViaMessenger: computed('isOnline', 'normalizedType', function () {
      var isOnline = this.get('isOnline');
      var type = this.get('normalizedType');

      return type === 'email' && isOnline;
    }),

    normalizedType: computed('channelType', function () {
      var channelType = this.get('channelType');

      switch (channelType) {
        case 'MAILBOX':
        case 'MAIL':
          {
            return 'email';
          }
        case 'FACEBOOK':
          {
            return 'facebook';
          }
        case 'TWITTER':
          {
            return 'twitter';
          }
      }
    })
  });
});