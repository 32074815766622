define("frontend-cp/components/ko-admin/apps/manage/index/app/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "app": "ko-admin_apps_manage_index_app__app_mnt6ui",
    "image": "ko-admin_apps_manage_index_app__image_mnt6ui",
    "details": "ko-admin_apps_manage_index_app__details_mnt6ui",
    "name": "ko-admin_apps_manage_index_app__name_mnt6ui",
    "version": "ko-admin_apps_manage_index_app__version_mnt6ui",
    "top-line": "ko-admin_apps_manage_index_app__top-line_mnt6ui",
    "bottom-line": "ko-admin_apps_manage_index_app__bottom-line_mnt6ui",
    "description": "ko-admin_apps_manage_index_app__description_mnt6ui"
  };
});