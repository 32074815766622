define('frontend-cp/session/agent/cases/index/suspended-messages/controller', ['exports', 'frontend-cp/config/environment', 'frontend-cp/utils/compute-selected'], function (exports, _environment, _computeSelected) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var filterBy = Ember.computed.filterBy;
  var computed = Ember.computed;
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  var $ = Ember.$;


  var CASE_PAGE_LIMIT = _environment.default.casesPageSize;

  exports.default = Controller.extend({
    queryParams: ['page', 'view'],
    page: 1,
    columns: ['from', 'subject', 'createdAt', 'suspensionCode'],
    selectedMailIds: [],
    i18n: service(),
    confirmation: service(),

    // CPs
    suspendedMails: filterBy('model', 'isSuspended', true),

    totalPages: computed('model.meta.total', function () {
      var totalCases = this.get('model.meta.total');
      return Math.ceil(totalCases / CASE_PAGE_LIMIT);
    }),

    isEverythingChecked: computed('selectedMailIds.length', 'suspendedMails.length', function () {
      var selected = this.get('selectedMailIds.length');
      var total = this.get('suspendedMails.length');

      return selected === total;
    }),

    actions: {
      selectAll: function selectAll() {
        this.set('selectedMailIds', this.get('suspendedMails').mapBy('id'));
      },
      toggleCheck: function toggleCheck(rowMail, checked, shiftKey) {
        var selectedMailIds = [].concat(_toConsumableArray(this.get('selectedMailIds')));

        if (shiftKey && selectedMailIds.length) {
          var allMailIds = this.get('suspendedMails').mapBy('id');
          var selectedRows = (0, _computeSelected.default)(rowMail.id, checked, selectedMailIds, allMailIds);
          this.set('selectedMailIds', selectedRows);
          return;
        }

        if (checked) {
          selectedMailIds.push(rowMail.id);
        } else {
          selectedMailIds.removeObject(rowMail.id);
        }

        this.set('selectedMailIds', selectedMailIds);
      },
      deselectAll: function deselectAll() {
        this.set('selectedMailIds', []);
      },
      showMail: function showMail(mail) {
        this.target.send('showMail', mail);
      },
      permanentlyDeleteSelectedMails: function permanentlyDeleteSelectedMails() {
        var _this = this;

        var selectedMailIds = this.get('selectedMailIds');
        var selectedMails = this.get('suspendedMails').filter(function (mail) {
          return selectedMailIds.includes(mail.id);
        });

        return this.get('confirmation').confirm({
          intlConfirmationBody: 'cases.suspendedMessages.confirmDeleteAll'
        }).then(function () {
          var ids = selectedMailIds;
          var adapter = getOwner(_this).lookup('adapter:application');
          var url = adapter.namespace + '/mails?' + $.param({ ids: ids });
          return adapter.ajax(url, 'DELETE');
        }).then(function () {
          _this.send('refreshPage');
          _this.set('selectedMailIds', []);
          selectedMails.forEach(function (m) {
            return m.unloadRecord();
          });
        });
      },
      tableSorted: function tableSorted(column, order) {
        this.setProperties({ orderBy: order, orderByColumn: column });
      }
    }
  });
});