define('frontend-cp/components/ko-agent-content/sidebar/info-list/item/componet', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes:
    iconName: null,
    content: null,
    tooltip: null,

    tagName: ''
  });
});