define('frontend-cp/session/agent/cases/case/index/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route-child'], function (exports, _tabbedRouteChild) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = _tabbedRouteChild.default.extend({
    tabStore: service(),
    agentCache: service('cache/agent-cache'),
    casePriorityCache: service('cache/case-priority'),
    timelineService: service('timeline'),

    queryParams: {
      postId: { replace: true },
      filter: { replace: true, refreshModel: true },
      noteId: { replace: true, refreshModel: true }
    },

    model: function model(_ref) {
      var filter = _ref.filter,
          noteId = _ref.noteId;

      var caseModel = this.modelFor('session.agent.cases.case');
      var store = this.get('store');
      var agentCache = this.get('agentCache');
      var casePriorityCache = this.get('casePriorityCache');

      var timelineService = this.get('timelineService');
      var timeline = timelineService.timelineForCase(caseModel, filter);

      return RSVP.hash({
        case: caseModel,
        priorities: casePriorityCache.getAll(),
        statuses: store.findAll('case-status'),
        types: store.findAll('case-type'),
        caseFields: store.findAll('case-field'),
        caseForms: store.findAll('case-form'),
        agents: agentCache.getAgents(),
        teams: store.findAll('team'),
        timeline: timeline,
        noteId: noteId
      });
    },
    setupController: function setupController(controller, models) {
      var parentController = this.controllerFor('session.agent.cases.case');
      controller.set('state', parentController.get('state'));
      controller.setProperties(models);
    },


    // Actions
    actions: {
      updateQueryParams: function updateQueryParams(changes) {
        var tabs = this.get('tabStore');
        var activeTab = tabs.get('activeTab');
        var defaultQPs = { postId: null, filter: 'all', noteId: null };
        var newQueryParams = Object.assign({}, defaultQPs, activeTab.queryParams || {}, changes);
        tabs.update(activeTab, { queryParams: newQueryParams });
      }
    }
  });
});