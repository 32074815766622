define('frontend-cp/adapters/case-ai-summary', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'ai-summary';
    },
    generateSummary: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(caseId) {
        var summary;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.ajax('/api/v1/cases/' + caseId + '/ai-summary', 'GET');

              case 2:
                summary = _context.sent;
                return _context.abrupt('return', summary.data.ai_summary);

              case 4:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function generateSummary(_x) {
        return _ref.apply(this, arguments);
      }

      return generateSummary;
    }(),
    addSummaryToCase: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(caseId, summary) {
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                return _context2.abrupt('return', this.ajax('/api/v1/cases/' + caseId + '/ai-summary', 'POST', {
                  data: {
                    'ai_summary': summary
                  }
                }));

              case 1:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function addSummaryToCase(_x2, _x3) {
        return _ref2.apply(this, arguments);
      }

      return addSummaryToCase;
    }()
  });
});