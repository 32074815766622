define('frontend-cp/mixins/dirty-aware/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;

  exports.default = function () {
    var modelFieldName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    return Mixin.create({
      i18n: service(),
      confirmation: service(),

      // Actions
      actions: {
        willTransition: function willTransition(transition) {
          var _this = this;

          var isEdited = this.controller.get('isEdited');

          if (typeof isEdited === 'function') {
            isEdited = Reflect.apply(isEdited, this.controller, []);
          }

          if (isEdited) {
            transition.abort();

            this.get('confirmation').confirm({
              intlConfirmationHeader: 'generic.confirm.lose_changes_header',
              intlConfirmationBody: 'generic.confirm.lose_changes',
              intlConfirmLabel: 'generic.confirm.lose_changes_button'
            }).then(function () {
              _this.controller.initEdits();
              transition.retry();
            });
          } else if (modelFieldName) {
            var model = this.controller.get(modelFieldName);
            if (model.get('isNew')) {
              model.rollbackAttributes();
            }
          }
        }
      }
    });
  };
});