define('frontend-cp/session/admin/automation/monitors/new/route', ['exports', 'ember-service/inject', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _inject, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend((0, _route.default)('model'), {
    launchDarkly: (0, _inject.default)(),

    i18n: service(),
    notification: service(),
    metrics: service(),

    model: function model() {
      var store = this.get('store');

      return store.createRecord('monitor', {
        actions: [store.createRecord('automation-action')],
        isEnabled: true
      });
    },
    setupController: function setupController(controller, model) {
      model.get('predicateCollections').createRecord({
        propositions: [this.get('store').createRecord('proposition')]
      });
      this._super.apply(this, arguments);
      controller.setProperties(this.modelFor('session.admin.automation.monitors'));
    },


    // Actions
    actions: {
      didSave: function didSave() {
        var launchDarkly = this.get('launchDarkly');

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });
        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'monitor_created'
          });
        }
        this.transitionTo('session.admin.automation.monitors');
      },
      cancel: function cancel() {
        this.transitionTo('session.admin.automation.monitors');
      }
    }
  });
});