define('frontend-cp/components/ko-admin/brands/list-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    brand: null,
    list: null,
    onDelete: function onDelete() {},
    onEdit: function onEdit() {},
    onMakeDefault: function onMakeDefault() {},
    onToggle: function onToggle() {}
  });
});