define('frontend-cp/components/ko-info-bar/field/date/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var guidFor = Ember.guidFor;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    title: '',
    isErrored: false,
    isEdited: false,
    isDisabled: false,
    value: null,
    onValueChange: null,
    emptyLabel: '-',

    // State
    isFocused: false,
    isCalendarShown: false,

    // CPs
    valueAsDate: computed('value', function () {
      var value = this.get('value');

      if (!value) {
        return value;
      }

      var convertedDate = _moment.default.utc(value, 'YYYY-MM-DD');
      var year = convertedDate.get('year');
      var month = convertedDate.get('month');
      var day = convertedDate.get('date');
      var date = new Date(year, month, day);

      return date;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.uniqueId = guidFor(this);
    },


    actions: {
      mouseDown: function mouseDown(e) {
        if (!this.get('isDisabled') && !this.get('isCalendarShown')) {
          e.preventDefault();
          $('#' + this.get('uniqueId')).focus();
        }
      },
      focus: function focus() {
        this.set('isCalendarShown', true);
      },
      blur: function blur() {
        this.set('isCalendarShown', false);
      },
      hide: function hide() {
        this.set('isCalendarShown', false);
      },
      preserveFocus: function preserveFocus(e) {
        e.preventDefault();
        e.stopPropagation();
      },
      dateChange: function dateChange(value) {
        var date = dateToTimestamp(value);

        this.attrs.onValueChange(date);
        this.set('isCalendarShown', false);
      }
    }
  });


  function dateToTimestamp(date) {
    var m = (0, _moment.default)(date);

    if (!m.isValid()) {
      return '';
    }

    var year = m.get('year');
    var month = m.get('month');
    var day = m.get('date');
    var result = (0, _moment.default)([year, month, day]);

    return result.format('YYYY-MM-DDTHH:mm:ss+0000');
  }
});