define('frontend-cp/session/agent/knowledgebase/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = Ember.inject.service;
  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  exports.default = Route.extend({
    articleEvents: service(),
    notification: service('notification'),
    i18n: service(),
    session: service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('articleEvents').setArticleCreatedCallback(function () {
        _this.refresh();
      });
      this.get('articleEvents').setArticleUpdatedCallback(function () {
        _this.refresh();
      });
      this.get('articleEvents').setArticleDeletedCallback(function () {
        _this.refresh();
      });
    },
    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(params) {
        var adapter, articleCounts;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                adapter = this.store.adapterFor('article');
                _context.next = 3;
                return adapter.fetchArticleCounts();

              case 3:
                articleCounts = _context.sent;
                return _context.abrupt('return', hash({
                  articleCounts: articleCounts
                }));

              case 5:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function model(_x) {
        return _ref.apply(this, arguments);
      }

      return model;
    }()
  });
});