define("frontend-cp/components/ko-admin/rateplans/item-group/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "cell": "ko-admin_rateplans_item-group__cell_1gb8oh",
    "selected": "ko-admin_rateplans_item-group__selected_1gb8oh",
    "disabled": "ko-admin_rateplans_item-group__disabled_1gb8oh",
    "plan-item": "ko-admin_rateplans_item-group__plan-item_1gb8oh",
    "select-item": "ko-admin_rateplans_item-group__select-item_1gb8oh",
    "plan-item-detail": "ko-admin_rateplans_item-group__plan-item-detail_1gb8oh",
    "plan-item-padding-top-5": "ko-admin_rateplans_item-group__plan-item-padding-top-5_1gb8oh",
    "support-plan-duration-label": "ko-admin_rateplans_item-group__support-plan-duration-label_1gb8oh",
    "plan-duration": "ko-admin_rateplans_item-group__plan-duration_1gb8oh",
    "plan-name": "ko-admin_rateplans_item-group__plan-name_1gb8oh",
    "plan-description": "ko-admin_rateplans_item-group__plan-description_1gb8oh",
    "plan-item-description": "ko-admin_rateplans_item-group__plan-item-description_1gb8oh",
    "plan-minimum-amount-notification": "ko-admin_rateplans_item-group__plan-minimum-amount-notification_1gb8oh",
    "plan-maths": "ko-admin_rateplans_item-group__plan-maths_1gb8oh",
    "plan-description-label-helper": "ko-admin_rateplans_item-group__plan-description-label-helper_1gb8oh",
    "plan-description-label-collaborators": "ko-admin_rateplans_item-group__plan-description-label-collaborators_1gb8oh",
    "flex": "ko-admin_rateplans_item-group__flex_1gb8oh",
    "adjust-me": "ko-admin_rateplans_item-group__adjust-me_1gb8oh",
    "background-white": "ko-admin_rateplans_item-group__background-white_1gb8oh",
    "label-helper": "ko-admin_rateplans_item-group__label-helper_1gb8oh"
  };
});