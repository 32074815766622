define('frontend-cp/session/agent/cases/new/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route'], function (exports, _tabbedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = _tabbedRoute.default.extend({
    store: service(),
    i18n: service(),
    processManager: service(),

    model: function model(_ref) {
      var _this = this;

      var requester_id = _ref.requester_id,
          timestamp = _ref.timestamp;

      var store = this.get('store');

      var newCase = this.store.peekAll('case').findBy('creationTimestamp', timestamp);
      if (newCase) {
        return newCase;
      }

      if (!requester_id) {
        return RSVP.hash({
          statuses: store.findAll('case-status'),
          caseForms: store.findAll('case-form')
        }).then(function (_ref2) {
          var statuses = _ref2.statuses,
              caseForms = _ref2.caseForms;

          var form = caseForms.find(function (caseForm) {
            return caseForm.get('isDefault') === true;
          });

          return _this.store.createRecord('case', {
            form: form,
            status: statuses.find(function (model) {
              return model.get('statusType') === 'NEW';
            }),
            creationTimestamp: timestamp
          });
        });
      } else {
        return RSVP.hash({
          requester: store.findRecord('user', requester_id),
          replyChannels: store.query('channel', { user_id: requester_id }),
          statuses: store.findAll('case-status'),
          caseForms: store.findAll('case-form')
        }).then(function (_ref3) {
          var requester = _ref3.requester,
              replyChannels = _ref3.replyChannels,
              statuses = _ref3.statuses,
              caseForms = _ref3.caseForms;

          var sourceChannel = replyChannels.objectAt(0);
          var form = caseForms.find(function (caseForm) {
            return caseForm.get('isDefault') === true;
          });

          return _this.store.createRecord('case', {
            requester: requester, replyChannels: replyChannels, sourceChannel: sourceChannel, form: form,
            status: statuses.find(function (model) {
              return model.get('statusType') === 'NEW';
            }),
            creationTimestamp: timestamp
          });
        });
      }
    },
    afterModel: function afterModel(model, transition) {
      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(model, 'case-new');
      processManager.setForegroundProcess(process);

      var tabStore = this.get('tabStore');
      var tab = tabStore.open(transition);

      tabStore.update(tab, { process: process });
      this.set('tab', tab);

      var state = process.get('state');

      if (!state) {
        var CaseStateManager = getOwner(this).factoryFor('state-manager:case');
        state = CaseStateManager.create({ model: model, tab: tab });

        process.set('state', state);
      }

      this.set('state', state);

      this._super.apply(this, arguments);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('state', this.get('state'));
    },


    actions: {
      transitionToNewlyCreatedCase: function transitionToNewlyCreatedCase(newCase) {
        var tabStore = this.get('tabStore');
        tabStore.leave(this.tab);
        tabStore.close(this.tab);
        this.transitionTo('session.agent.cases.case.index', newCase.get('id'));
      }
    }
  });
});