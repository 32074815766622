define("frontend-cp/components/ko-entity-list/sidebar/collection-logic-operator/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-entity-list_sidebar_collection-logic-operator__container_15oo3s",
    "trigger": "ko-entity-list_sidebar_collection-logic-operator__trigger_15oo3s _trigger_jvqwfg _statusIcon_jvqwfg",
    "trigger--focused": "ko-entity-list_sidebar_collection-logic-operator__trigger--focused_15oo3s _trigger--focused_jvqwfg",
    "dropdown": "ko-entity-list_sidebar_collection-logic-operator__dropdown_15oo3s _dropdown_jvqwfg"
  };
});