define('frontend-cp/components/ko-notification-center/preferences-new/component', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var isEmpty = Ember.isEmpty;
  var RSVP = Ember.RSVP;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    session: service(),
    store: service(),
    notification: service(),
    notificationPreferences: service(),
    profilePreferences: service(),
    i18n: service(),

    preferences: null,
    soundPreferences: null,
    onClose: function onClose() {},

    init: function init() {
      this._super.apply(this, arguments);

      var preferences = this.get('notificationPreferences.preferences').map(function (pref) {
        return Object.assign({}, pref);
      });
      var soundPreferences = Object.assign({}, {
        desktopSoundAlerts: this.get('profilePreferences.preferences.desktopSoundAlerts')
      });
      this.set('preferences', preferences);
      this.set('soundPreferences', soundPreferences);
    },


    isSaving: computed.or('notificationPreferences.isSaving', 'profilePreferences.isSaving'),

    isEdited: computed('launchDarkly.release-live-chat-notification-improvements', '_isEditedOld', '_isEditedNew', function () {
      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('release-live-chat-notification-improvements')) {
        return this.get('_isEditedNew');
      }
      return this.get('_isEditedOld');
    }),

    _isEditedNew: computed.or('isNotificationPreferencesEdited', 'isSoundPreferencesEdited'),
    _isEditedOld: computed.reads('isNotificationPreferencesEdited'),

    isNotificationPreferencesEdited: computed('preferences.@each.{channelDesktop,channelMobile,channelEmail}', 'notificationPreferences.preferences.[]', function () {
      var _this = this;

      var preferences = this.get('preferences');
      var originalPreferences = this.get('notificationPreferences.preferences');
      var editedPreferences = preferences.filter(function (pref) {
        var original = originalPreferences.findBy('notificationType', pref.notificationType);
        return _this._hasDirtyAttributes(pref, original);
      });
      return !isEmpty(editedPreferences);
    }),

    isSoundPreferencesEdited: computed('soundPreferences.desktopSoundAlerts', 'profilePreferences.preferences', function () {
      var preferences = this.get('soundPreferences');
      var originalPreferences = this.get('profilePreferences.preferences');
      return preferences.desktopSoundAlerts !== originalPreferences.desktopSoundAlerts;
    }),

    _hasDirtyAttributes: function _hasDirtyAttributes(preference, original) {
      return preference.channelDesktop !== original.channelDesktop || preference.channelMobile !== original.channelMobile || preference.channelEmail !== original.channelEmail;
    },


    actions: {
      save: function save() {
        var _this2 = this;

        var launchDarkly = this.get('launchDarkly');

        var preferences = this.get('preferences');
        var soundPreferences = this.get('soundPreferences');

        var onSuccess = function onSuccess() {
          _this2.get('notification').add({
            type: 'success',
            title: _this2.get('i18n').t('notifications.preferences.success'),
            autodismiss: true
          });

          _this2.get('onClose')();
        };

        var onError = function onError() {
          _this2.get('notification').add({
            type: 'error',
            title: _this2.get('i18n').t('generic.generic_error'),
            autodismiss: true
          });
        };

        if (launchDarkly.get('release-live-chat-notification-improvements')) {
          RSVP.all([this.get('notificationPreferences').savePreferences(preferences), this.get('profilePreferences').savePreferences(soundPreferences)]).then(onSuccess, onError);
        } else {
          this.get('notificationPreferences').savePreferences(preferences).then(onSuccess, onError);
        }
      }
    }
  });
});