define('frontend-cp/adapters/case-form', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // We are including `case-field` as part of HOTFIX FT-1897
  // If and when PDM-9524 lands, we can revert.
  var REQUIRED_SIDELOADED_MODELS = 'case-field,locale-field';

  exports.default = _application.default.extend({

    autoIncludeAll: false,

    pathForType: function pathForType() {
      return 'cases/forms';
    },
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS;
    },
    urlForQuery: function urlForQuery() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS;
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS;
    },
    urlForCreateRecord: function urlForCreateRecord() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS;
    },
    urlForFindRecord: function urlForFindRecord() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS;
    }
  });
});