define("frontend-cp/components/ko-info-bar/field/select-multiple/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-info-bar_field_select-multiple__trigger_1jw80j",
    "dropdown": "ko-info-bar_field_select-multiple__dropdown_1jw80j _dropdown_1ilz5g _dropdown_jvqwfg"
  };
});