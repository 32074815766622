define('frontend-cp/instance-initializers/expose-visit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    window.kayakoVisit = function (url) {
      return appInstance.visit(url);
    };
  }

  exports.default = {
    name: 'expose-visit',
    initialize: initialize
  };
});