define('frontend-cp/components/ko-organization-list/results/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  exports.default = Component.extend({
    tagName: '',

    //Attrs
    organizations: null,
    isLoading: null,

    //State
    columnList: null,

    //Serivces
    router: service('-routing'),
    tabStore: service(),

    //Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);

      this.set('columnList', ['orgComposite', 'updatedat', 'createdat']);
    },


    //CP's

    //Methods
    maxWidthForColumn: function maxWidthForColumn(column) {
      if (column === 'orgComposite') {
        return 1500;
      } else {
        return 300;
      }
    },
    minWidthForColumn: function minWidthForColumn(column) {
      if (column === 'orgComposite') {
        return 500;
      } else {
        return 100;
      }
    },


    //Tasks
    viewOrganization: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(organization) {
      var router, id;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              router = this.get('router');
              id = get(organization, 'id');


              router.transitionTo('session.agent.organizations.organization', [id], { returnTo: router.get('currentRouteName') });

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    //Actions
    actions: {
      viewOrganization: function viewOrganization(organization, hasModifier) {
        if (hasModifier) {
          this.get('tabStore').createTabNextToActiveTab('session.agent.organizations.organization', organization);
        } else {
          this.get('viewOrganization').perform(organization);
        }
      }
    }
  });
});