define('frontend-cp/models/facebook-page', ['exports', 'ember-data', 'frontend-cp/models/account'], function (exports, _emberData, _account) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _account.default.extend({
    title: _emberData.default.attr('string'),
    account: _emberData.default.belongsTo('facebook-account'),
    brand: _emberData.default.belongsTo('brand', { async: false }),
    routePosts: _emberData.default.attr('boolean'),
    routeMessages: _emberData.default.attr('boolean'),
    isEnabled: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),

    import: false
  });
});