define("frontend-cp/components/ko-universal-search/result/organization/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "icon": "ko-universal-search_result_organization__icon_1mwbss",
    "title": "ko-universal-search_result_organization__title_1mwbss",
    "avatar": "ko-universal-search_result_organization__avatar_1mwbss",
    "updated": "ko-universal-search_result_organization__updated_1mwbss",
    "domains": "ko-universal-search_result_organization__domains_1mwbss",
    "domain": "ko-universal-search_result_organization__domain_1mwbss",
    "domainFirst": "ko-universal-search_result_organization__domainFirst_1mwbss",
    "separator": "ko-universal-search_result_organization__separator_1mwbss"
  };
});