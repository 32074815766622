define('frontend-cp/session/agent/users/new/index/controller', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['postId', 'filter'],
    filter: 'notes',
    postId: null,
    openInSameTab: false,
    transitionTabPath: null,

    actions: {
      createNewCase: function createNewCase(userId) {
        this.transitionToRoute('session.agent.cases.new', (0, _moment.default)().format('YYYY-MM-DD-hh-mm-ss'), { queryParams: { requester_id: userId } });
      },
      updateQueryParams: function updateQueryParams(changes) {
        this.setProperties(changes);
        return true;
      },
      openInSameTab: function openInSameTab() {
        this.set('openInSameTab', true);
      }
    }
  });
});