define("frontend-cp/components/ko-button/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "shared": "ko-button__shared_ka3fcv",
    "size--medium": "ko-button__size--medium_ka3fcv",
    "size--wide": "ko-button__size--wide_ka3fcv",
    "align--center": "ko-button__align--center_ka3fcv",
    "has-margin": "ko-button__has-margin_ka3fcv",
    "fixed-width": "ko-button__fixed-width_ka3fcv",
    "span": "ko-button__span_ka3fcv",
    "has-animation": "ko-button__has-animation_ka3fcv",
    "is-loading": "ko-button__is-loading_ka3fcv",
    "disabled": "ko-button__disabled_ka3fcv",
    "full": "ko-button__full_ka3fcv",
    "naked": "ko-button__naked_ka3fcv ko-button__shared_ka3fcv",
    "chrome": "ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "primary-with-options": "ko-button__primary-with-options_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "note": "ko-button__note_ka3fcv",
    "primary": "ko-button__primary_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "bordered": "ko-button__bordered_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "default": "ko-button__default_ka3fcv ko-button__bordered_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "highlight": "ko-button__highlight_ka3fcv ko-button__bordered_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "alert": "ko-button__alert_ka3fcv ko-button__bordered_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "loading": "ko-button__loading_ka3fcv",
    "loading__dot": "ko-button__loading__dot_ka3fcv",
    "loading-dots": "ko-button__loading-dots_ka3fcv"
  };
});