define('frontend-cp/components/ko-admin/automation-actions-builder/cascading-select/trigger/component', ['exports', 'frontend-cp/lib/keycodes'], function (exports, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var get = Ember.get;
  var observer = Ember.observer;
  var computed = Ember.computed;
  var run = Ember.run;
  exports.default = Component.extend({
    // Attributes
    extra: null,
    select: null,

    // HTML
    tagName: '',

    // Observers
    isOpenObserver: observer('select.isOpen', function () {
      var select = this.get('select');
      var oldIsOpen = this.get('oldIsOpen');

      if (!oldIsOpen && select.isOpen) {
        run.schedule('afterRender', this, function () {
          $('#ko-' + this.get('select.uniqueId') + ' input').focus();
        });
      } else if (oldIsOpen && !select.isOpen) {
        run.schedule('actions', null, select.actions.search, '');
      }

      this.set('oldIsOpen', select.isOpen);
    }),

    // CPs
    value: computed('extra.labelPath', 'select.isOpen', 'select.selected', 'extra.formattedValue', function () {
      var isOpen = this.get('select.isOpen');
      if (isOpen) {
        return '';
      }
      var formattedValue = this.get('extra.formattedValue');
      if (formattedValue) {
        return formattedValue;
      }
      var selected = this.get('select.selected');
      if (!selected) {
        return '-';
      }
      var labelPath = this.get('extra.labelPath');
      if (labelPath) {
        return get(selected, labelPath);
      } else {
        return selected;
      }
    }),

    actions: {
      handleMouseDown: function handleMouseDown(e) {
        if (this.get('select.isOpen')) {
          e.stopPropagation();
        }
      },
      handleKeyDown: function handleKeyDown(e) {
        var select = this.get('select');
        if (e.keyCode === _keycodes.enter && select.isOpen) {
          select.actions.choose(select.highlighted, e);
          e.stopPropagation();
        }
        // Don't use space to select currently highlighed option
        if (e.keyCode === _keycodes.space && select.isOpen) {
          e.stopPropagation();
        }
        if (!this.get('select.isOpen') && e.keyCode !== _keycodes.tab && e.keyCode !== _keycodes.enter) {
          e.preventDefault();
        }
      }
    }
  });
});