define('frontend-cp/adapters/report-case', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var REQUIRED_SIDELOADED_MODELS = ['case', 'user', 'user_minimal', 'status', 'case_status', 'article', 'section', 'category', 'locale_field', 'channel'].join(',');

  var REQUIRED_FIELDS = ['resource', 'resource_url', 'subject', 'requester(avatar,full_name)', 'assigned_agent(avatar,full_name)', 'status', 'updated_at', 'last_message_preview'].join(',');

  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'reports/preview';
    },
    urlForQuery: function urlForQuery() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS + '&fields=' + REQUIRED_FIELDS;
    },


    // TODO - use methodForRequest when the 'ds-improved-ajax' flag is enabled
    ajax: function ajax(url, type, data) {
      return this._super(url, 'POST', data);
    }
  });
});