define('frontend-cp/components/ko-notification-center/notification-mentioned-in-conversation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    notification: null,
    close: function close() {},

    user: computed.reads('notification.activity.actorUser'),
    subject: computed('notification.activity.case.title', function () {
      var title = this.get('notification.activity.object.title');

      if (title.length > 50) {
        return title.slice(0, 50) + '...';
      }

      return title;
    })
  });
});