define('frontend-cp/adapters/case', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var REQUIRED_SIDELOADED_MODELS = 'channel,last_public_channel,mailbox,facebook_page,facebook_account,twitter_account,user,organization,sla_metric,sla_version_target,sla_version,identity_email,identity_domain,identity_facebook,identity_twitter,identity_phone,case_field';

  exports.default = _application.default.extend({
    autoIncludeAll: false,

    urlForQuery: function urlForQuery(query, modelName) {
      if (query.parent) {
        var id = query.parent.id;
        var url = this._super.apply(this, arguments);
        Reflect.deleteProperty(query, 'parent');
        var newUrl = url.replace('/cases', '/views/' + id + '/cases');
        return newUrl + '?include=read_marker';
      }
      return this._super.apply(this, arguments);
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      var url = this._super.apply(this, arguments);
      return url + '?include=sla_metric,sla_version_target,sla_version,last_public_channel,read_marker';
    },
    urlForCreateRecord: function urlForCreateRecord() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS + ',read_marker';
    },
    urlForFindRecord: function urlForFindRecord() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS + ',read_marker';
    },


    // We assume that all endpoints, apart from /cases/:id, might return cases with
    // some fields potentially missing. _isFullyLoaded is set automatically during
    // queryRecord.
    shouldReloadRecord: function shouldReloadRecord(store, snapshot) {
      return !snapshot.record.get('_isFullyLoaded');
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    emptyTrash: function emptyTrash() {
      var url = this.namespace + '/cases/trash';
      return this.ajax(url, 'DELETE');
    }
  });
});