define("frontend-cp/components/ko-select/multiple/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-select_multiple__trigger_gi7hdq",
    "trigger--focused": "ko-select_multiple__trigger--focused_gi7hdq",
    "dropdown": "ko-select_multiple__dropdown_gi7hdq _dropdown_jvqwfg"
  };
});