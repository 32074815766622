define('frontend-cp/components/ko-admin/messenger-persistent/preferences/component', ['exports', 'ember-concurrency', 'ember-diff-attrs', 'npm:lodash', 'frontend-cp/components/ko-admin/messenger-persistent/preferences/phraseList'], function (exports, _emberConcurrency, _emberDiffAttrs, _npmLodash, _phraseList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    i18n: service(),

    businessHours: [],

    didReceiveAttrs: (0, _emberDiffAttrs.default)('brand', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);
      if (!changedAttrs || changedAttrs && changedAttrs.brand) {
        this.get('fetchAverageReplyTime').perform();
      }
    }),

    businessHoursWithPlaceholder: computed('businessHours.[]', function () {
      return [''].concat(this.get('businessHours').toArray());
    }),

    fetchAverageReplyTime: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(id) {
      var brand, response;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              brand = this.get('brand');

              if (brand) {
                _context.next = 3;
                break;
              }

              return _context.abrupt('return');

            case 3:
              _context.next = 5;
              return this.get('store').adapterFor('conversation-starter').fetchForBrand(brand.get('id'));

            case 5:
              response = _context.sent;

              this.set('averageReplyTime', response.data.average_reply_time);
              return _context.abrupt('return', response);

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    currentResponseTimeExpectation: computed('averageReplyTime', function () {
      return this.getPhrase(this.get('averageReplyTime'));
    }),

    currentExpectation: computed('currentResponseTimeExpectation', function () {
      var itIsCurrently = this.get('i18n').t('admin.messenger.tabs.options.response_time.options.it_is_currently');
      return itIsCurrently + ' "' + this.get('currentResponseTimeExpectation') + '"';
    }),

    isSuggestionsFeatureDisabled: computed.equal('enableSuggestions', null),

    getPhrase: function getPhrase(replyTime) {
      var phrasePrefix = 'admin.messenger.tabs.options.response_time.options';
      if (replyTime === null) {
        return this.get('i18n').t(phrasePrefix + '.reply_asap');
      }
      var matchingPhrase = _npmLodash.default.find(_phraseList.default, function (ph) {
        return ph.times[0] <= replyTime && ph.times[1] >= replyTime;
      });
      if (matchingPhrase) {
        var _matchingPhrase$sente = matchingPhrase.sentence(),
            locale = _matchingPhrase$sente.locale,
            data = _matchingPhrase$sente.data;

        return this.get('i18n').t(phrasePrefix + '.' + locale, data);
      }
    },


    actions: {
      onBusinessHourChange: function onBusinessHourChange(businessHour) {
        if (businessHour === '') {
          this.set('linkedBusinessHour', null);
          return;
        }
        this.set('linkedBusinessHour', businessHour);
      },
      onSuggestionsToggle: function onSuggestionsToggle() {
        if (this.get('isSuggestionsFeatureDisabled')) return;
        this.set('enableSuggestions', !this.get('enableSuggestions'));
      }
    }
  });
});