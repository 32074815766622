define('frontend-cp/components/ko-admin/brands/form/domain-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    value: '',
    domain: '',
    disabled: false,
    qaClass: null,
    onInput: function onInput() {},

    // State
    focused: false,

    actions: {
      focus: function focus() {
        this.set('focused', true);
      },
      blur: function blur() {
        this.set('focused', false);
      }
    }
  });
});