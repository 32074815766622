define('frontend-cp/components/ko-bulk-sidebar/component', ['exports', 'ember-service/inject', 'ember-concurrency', 'npm:lodash', 'frontend-cp/lib/edited-custom-fields'], function (exports, _inject, _emberConcurrency, _npmLodash, _editedCustomFields) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var or = Ember.computed.or;
  var A = Ember.A;
  var Component = Ember.Component;
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var computed = Ember.computed;
  var service = Ember.inject.service;


  var convertErrorsToMap = function convertErrorsToMap(errors) {
    return (errors || []).filter(function (error) {
      return error.parameter;
    }).reduce(function (errorMap, error) {
      errorMap.set(error.parameter, true);
      return errorMap;
    }, Ember.Object.create({}));
  };

  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    tagName: '',

    //Attributes
    selectedCaseIds: null,

    //State
    assignedTeam: null,
    assignedAgent: null,
    caseStatus: null,
    casePriority: null,
    caseType: null,
    tags: [],
    statuses: [],
    types: [],
    priorities: [],
    assigneeField: { title: 'Assignee' },
    isAssigneeEdited: false,
    isCaseStatusEdited: false,
    isCaseTypeEdited: false,
    isCasePriorityEdited: false,
    isTagsFieldEdited: false,
    isSaving: false,
    customFields: null,
    customFieldsState: null,

    //Serivces
    store: service(),
    i18n: service(),
    bulkService: service('case-bulk-update'),
    notification: service(),
    agentCache: service('cache/agent-cache'),
    customFieldsList: service('custom-fields/list'),

    //Initializers
    init: function init() {
      this._super.apply(this, arguments);
      this.resetState();
      this.fetchReferenceData();
      this.resetSelections();
      this.get('fetchAgents').perform();
      this.get('fetchCustomFields').perform();
    },
    resetState: function resetState() {
      this.set('tags', []);
    },
    fetchReferenceData: function fetchReferenceData() {
      var store = this.get('store');

      this.set('statuses', store.findAll('case-status'));
      this.set('types', store.findAll('case-type'));
      this.set('priorities', store.findAll('case-priority'));
    },
    resetSelections: function resetSelections() {
      this.setProperties({
        assignedTeam: null,
        assignedAgent: null,
        isAssigneeEdited: null,
        isCaseStatusEdited: false,
        isCaseTypeEdited: false,
        isCasePriorityEdited: false,
        caseStatus: this.getNoChangesItem(),
        caseType: this.getNoChangesItem(),
        casePriority: this.getNoChangesItem(),
        isTagsFieldEdited: false,
        tags: A()
      });
    },
    getNoChangesItem: function getNoChangesItem() {
      return EmberObject.create({
        id: -1,
        label: this.get('i18n').t('generic.no_changes')
      });
    },
    createCustomFieldItems: function createCustomFieldItems(customFields) {
      return customFields.map(function (field) {
        if (field.get('fieldType') === 'CHECKBOX') {
          return EmberObject.create({ field: field, value: null });
        }
        return EmberObject.create({ field: field, value: '' });
      });
    },


    //CP's
    bulkCaseStatuses: computed('statuses.[]', function () {
      var apiCaseStatuses = this.get('statuses').toArray();

      return [this.getNoChangesItem()].concat(_toConsumableArray(apiCaseStatuses.filter(function (caseStatus) {
        return ['NEW', 'CLOSED'].indexOf(caseStatus.get('statusType')) === -1;
      })));
    }),

    bulkCaseTypes: computed('types.[]', function () {
      return [this.getNoChangesItem(), EmberObject.create({ label: '-' })].concat(_toConsumableArray(this.get('types').toArray()));
    }),

    bulkCasePriorities: computed('priorities.[]', function () {
      return [this.getNoChangesItem(), EmberObject.create({ label: '-' })].concat(_toConsumableArray(this.get('priorities').toArray()));
    }),

    isEdited: or('isAssigneeEdited', 'isCaseStatusEdited', 'isCaseTypeEdited', 'isCasePriorityEdited', 'isTagsFieldEdited', 'customFieldsState.isEdited'),

    // Methods
    suggestTags: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(searchTerm) {
      var addNewMessage, data, exactMatch;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              addNewMessage = this.get('i18n').t('generic.addtagname', { tag: searchTerm });
              _context.next = 5;
              return this.get('store').query('tag', { name: searchTerm });

            case 5:
              data = _context.sent;
              exactMatch = !!data.toArray().findBy('name', searchTerm) || !!this.get('tags').findBy('name', searchTerm);
              return _context.abrupt('return', _npmLodash.default.difference(data.mapBy('name'), this.get('tags').mapBy('name')).map(function (name) {
                return { name: name };
              }).concat(exactMatch ? [] : [{ name: addNewMessage, actualName: searchTerm }]));

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    fetchAgents: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var agentCache, agents;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!this.get('agents')) {
                _context2.next = 2;
                break;
              }

              return _context2.abrupt('return');

            case 2:
              agentCache = this.get('agentCache');
              _context2.next = 5;
              return agentCache.getAgents();

            case 5:
              agents = _context2.sent;


              this.set('agents', agents);

            case 7:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).restartable(),

    fetchCustomFields: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var customFields, originalCustomFields, editedCustomFields, customFieldsState;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return this.get('store').findAll('case-field');

            case 2:
              customFields = _context3.sent;


              customFields = customFields.filterBy('isSystem', false);
              this.set('customFields', customFields);

              originalCustomFields = this.createCustomFieldItems(customFields);
              editedCustomFields = this.createCustomFieldItems(customFields);
              customFieldsState = _editedCustomFields.default.create({ originalCustomFields: originalCustomFields, editedCustomFields: editedCustomFields });

              this.set('customFieldsState', customFieldsState);

            case 9:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).restartable(),

    //Actions
    actions: {
      setAssignee: function setAssignee(team, agent) {
        if (team === null && agent === null) {
          this.set('assignedTeam', null);
          this.set('assignedAgent', null);
          this.set('isAssigneeEdited', false);
        } else {
          this.set('assignedTeam', team);
          this.set('assignedAgent', agent);
          this.set('isAssigneeEdited', true);
        }
      },
      setCaseStatus: function setCaseStatus(caseStatus) {
        if (caseStatus && caseStatus.get('id') === -1) {
          this.set('caseStatus', this.getNoChangesItem());
          this.set('isCaseStatusEdited', false);
        } else {
          this.set('caseStatus', caseStatus);
          this.set('isCaseStatusEdited', true);
        }
      },
      setCaseType: function setCaseType(caseType) {
        if (caseType && caseType.get('id') === -1) {
          this.set('caseType', this.getNoChangesItem());
          this.set('isCaseTypeEdited', false);
        } else {
          this.set('caseType', caseType);
          this.set('isCaseTypeEdited', true);
        }
      },
      setCasePriority: function setCasePriority(casePriority) {
        if (casePriority && casePriority.get('id') === -1) {
          this.set('casePriority', this.getNoChangesItem());
          this.set('isCasePriorityEdited', false);
        } else {
          this.set('casePriority', casePriority);
          this.set('isCasePriorityEdited', true);
        }
      },
      addTag: function addTag(newTag) {
        var name = get(newTag, 'actualName') || get(newTag, 'name');
        var editedTags = this.get('tags');
        if (editedTags.find(function (tag) {
          return get(tag, 'name') === name;
        })) {
          return;
        }
        editedTags.pushObject({ name: name });
        this.set('isTagsFieldEdited', true);
      },
      removeTag: function removeTag(tag) {
        var tags = this.get('tags');
        tags.removeObject(tag);
        if (!tags.length) {
          this.set('isTagsFieldEdited', false);
        }
      },
      submit: function submit() {
        var _this = this;

        var launchDarkly = this.get('launchDarkly');
        var launchDarkly = this.get('launchDarkly');

        var bulkService = this.get('bulkService');
        var options = {};
        if (this.get('assignedTeam')) {
          options.assignedTeam = this.get('assignedTeam');
        }
        if (this.get('assignedAgent')) {
          options.assignedAgent = this.get('assignedAgent');
        }
        if (this.get('caseStatus.id') !== -1) {
          options.caseStatus = this.get('caseStatus');
        }
        if (this.get('casePriority.id') !== -1) {
          options.casePriority = this.get('casePriority');
        }
        if (this.get('caseType.id') !== -1) {
          options.caseType = this.get('caseType');
        }
        if (this.get('tags').length) {
          options.tags = this.get('tags');
        }

        if (launchDarkly.get('release-sidebar-custom-fields')) {
          var fields = this.get('customFieldsState.editedCustomFields');
          var usedFields = fields.filterBy('value');

          if (usedFields.length) {
            options.fieldValues = usedFields.reduce(function (result, field) {
              result[field.get('field.key')] = field.get('value');
              return result;
            }, {});
          }
        }

        this.set('isSaving', true);

        bulkService.updateCases(this.get('selectedCaseIds'), options).then(function () {
          _this.get('notification').add({
            type: 'success',
            title: _this.get('i18n').t('cases.cases.updated'),
            autodismiss: true
          });

          _this.set('isSaving', false);
          _this.sendAction('onBulkUpdateCases');
          _this.resetSelections();
        }).catch(function (e) {
          _this.set('isSaving', false);
          if (launchDarkly.get('release-sidebar-custom-fields')) {
            _this.set('errorMap', convertErrorsToMap(e.errors));
          }
        });
      },
      cancel: function cancel() {
        this.sendAction('onCancel');
      },
      setCustomField: function setCustomField(field, value) {
        if (value) {
          value = get(value, 'id') || value;
        }
        this.get('customFieldsState').setValue(field, value);
      }
    }
  });
});