define("frontend-cp/components/ko-organization-list/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-organization-list__container_uifhrx",
    "title-loader": "ko-organization-list__title-loader_uifhrx",
    "title": "ko-organization-list__title_uifhrx",
    "heading": "ko-organization-list__heading_uifhrx",
    "sidebar": "ko-organization-list__sidebar_uifhrx",
    "results": "ko-organization-list__results_uifhrx",
    "not-found": "ko-organization-list__not-found_uifhrx",
    "not-found-image": "ko-organization-list__not-found-image_uifhrx",
    "not-found-message": "ko-organization-list__not-found-message_uifhrx",
    "sidebar-detail": "ko-organization-list__sidebar-detail_uifhrx"
  };
});