define('frontend-cp/components/agent-lookup/component', ['exports', 'ember-concurrency', 'frontend-cp/lib/keycodes'], function (exports, _emberConcurrency, _keycodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  var Component = Ember.Component;


  function normalizeUser(u) {
    return { id: get(u, 'id'), text: get(u, 'text') || get(u, 'fullName') || get(u, 'full_name') };
  }

  exports.default = Component.extend({
    store: service(),

    options: [],

    disabled: false,

    selected: computed('options', {
      set: function set(_, selection) {
        if (!selection) {
          return null;
        }
        var options = this.get('options');
        if (!options.length) {
          this.set('options', [normalizeUser(selection)]);
        }
        return normalizeUser(selection);
      }
    }),

    // Tasks
    search: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(name) {
      var users, lowercaseTerm, specialResults, searchResults, combinedResults, uniqueResults;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              _context.next = 4;
              return this.get('store').query('user', { in: 'ADMINS,AGENTS', name: name });

            case 4:
              users = _context.sent;
              lowercaseTerm = name.toLowerCase();
              specialResults = this.get('options').filter(function (e) {
                return e.text.toLowerCase().indexOf(lowercaseTerm) > -1;
              });
              searchResults = users.map(normalizeUser);
              combinedResults = specialResults.concat(searchResults);
              uniqueResults = combinedResults.reduce(function (acc, current) {
                if (!acc.find(function (item) {
                  return String(item.id) === String(current.id);
                })) {
                  acc.push(current);
                }
                return acc;
              }, []);
              return _context.abrupt('return', uniqueResults);

            case 11:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    // Actions
    actions: {
      preventSubmissionOnEnter: function preventSubmissionOnEnter(_, e) {
        if (e.keyCode === _keycodes.enter) {
          e.preventDefault();
        }
      }
    }
  });
});