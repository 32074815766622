define("frontend-cp/components/ko-tab-strip/tab/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "tab": "ko-tab-strip_tab__tab_10w30r",
    "inactive-tab": "ko-tab-strip_tab__inactive-tab_10w30r ko-tab-strip_tab__tab_10w30r",
    "active-tab": "ko-tab-strip_tab__active-tab_10w30r ko-tab-strip_tab__tab_10w30r",
    "link": "ko-tab-strip_tab__link_10w30r",
    "tooltip": "ko-tab-strip_tab__tooltip_10w30r",
    "close": "ko-tab-strip_tab__close_10w30r"
  };
});