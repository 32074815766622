define('frontend-cp/services/rateplans', ['exports', 'moment', 'npm:lodash'], function (exports, _moment, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    i18n: service(),
    store: service(),

    transformRatePlans: function transformRatePlans(ratePlans) {
      var _this = this;

      if (ratePlans.toArray) {
        ratePlans = ratePlans.toArray();
      }

      return (0, _npmLodash.default)(ratePlans).groupBy(function (ratePlan) {
        return ratePlan.get('key');
      }).map(function (ratePlan) {
        return _this._getRatePlanNode(ratePlan);
      }).sortBy('ANNUAL.price').value();
    },
    transformRateplansWithLegacy: function transformRateplansWithLegacy(rateplans) {
      if (!rateplans) {
        return null;
      }

      var latest = rateplans.filter(function (rateplan) {
        return rateplan.get('product.isLatest');
      });
      var legacy = rateplans.filter(function (rateplan) {
        return !rateplan.get('product.isLatest');
      });

      latest = this.transformRatePlans(latest);
      legacy = this.transformRatePlans(legacy);

      return { latest: latest, legacy: legacy };
    },
    getTermDiscount: function getTermDiscount(ratePlans, term) {
      var _this2 = this;

      if (!ratePlans) {
        return { fixed: 0, percentage: 0 };
      }
      return (0, _npmLodash.default)(ratePlans.toArray()).filter(function (rateplan) {
        return _npmLodash.default.find(rateplan.get('charges').toArray(), function (charge) {
          return charge.get('billingPeriod') === term && _this2.isDiscount(charge);
        });
      }).map(function (rateplan) {
        return rateplan.get('charges').toArray();
      }).flatten().transform(function (result, charge) {
        if (_this2.isFixedDiscount(charge)) {
          result.fixed = parseInt(charge.get('defaultQuantity'));
        } else if (charge && _this2.isPercentageDiscount(charge)) {
          result.percentage = parseInt(charge.get('defaultQuantity'));
        }
        return result;
      }, { fixed: 0, percentage: 0 }).value();
    },
    calculatePercentage: function calculatePercentage(base, percentage) {
      return base * percentage / 100;
    },
    getDiscount: function getDiscount(total, fixedDiscount, discountPercentage) {
      var totalAfterFixedDiscount = total - fixedDiscount;
      return this.calculatePercentage(totalAfterFixedDiscount, discountPercentage) + fixedDiscount;
    },
    isFixedDiscount: function isFixedDiscount(charge) {
      return charge.get('model') === 'DISCOUNTFIXEDAMOUNT';
    },
    isPercentageDiscount: function isPercentageDiscount(charge) {
      return charge.get('model') === 'DISCOUNTPERCENTAGE';
    },
    isDiscount: function isDiscount(charge) {
      return this.isFixedDiscount(charge) || this.isPercentageDiscount(charge);
    },
    _getRatePlanNode: function _getRatePlanNode(ratePlan) {
      var _this3 = this;

      return _npmLodash.default.transform(ratePlan, function (result, ratePlan) {
        return _this3._setCharges(result, ratePlan);
      }, {});
    },
    _setCharges: function _setCharges(result, ratePlan) {
      var charge = this._getDisplayCharge(ratePlan);
      var chargeNode = charge.billingPeriod;
      result.label = ratePlan.get('label');
      result.key = ratePlan.get('key');
      result.description = ratePlan.get('description');
      result.minimum_amount_notification = ratePlan.get('minimum_amount_notification');
      result.minimum_purchase_amount = ratePlan.get('minimum_purchase_amount');
      result.currency = charge.currency;
      result.productId = ratePlan.get('product.id');
      result[chargeNode] = charge;
    },
    _getDisplayCharge: function _getDisplayCharge(rateplan) {
      var _this4 = this;

      var rateplanId = rateplan.get('id');
      var chargesArray = rateplan.get('charges').toArray();

      return _npmLodash.default.reduce(chargesArray, function (result, charge) {
        if (charge.get('unitOfMeasure') === 'AGENTS') {
          result = _npmLodash.default.assign(result, _this4._getAgentCharge(charge, rateplanId));
        } else if (charge.get('unitOfMeasure') === 'COLLABORATORS') {
          result = _npmLodash.default.assign(result, _this4._getCollaboratorCharge(charge, rateplanId));
        } else {
          // TODO: FIX IT LATER, NEED TO HAVE A FALLBACK
          // result = _.assign(result, this._getAgentCharge(charge, rateplanId));
        }
        return result;
      }, {});
    },
    _getAgentCharge: function _getAgentCharge(charge, parentId) {
      var priceTier = this._getDisplayTier(charge);
      return _npmLodash.default.assign({
        actualId: parentId,
        price: this._getRatePlanUnitFee(priceTier.unitFee, charge.get('billingPeriod')) || priceTier.flatFee,
        actualPrice: priceTier.unitFee || priceTier.flatFee,
        billingPeriod: charge.get('billingPeriod')
      }, priceTier);
    },
    _getCollaboratorCharge: function _getCollaboratorCharge(charge, parentId) {
      return {
        actualId: parentId,
        collaborators: charge.get('defaultQuantity'),
        billingPeriod: charge.get('billingPeriod')
      };
    },


    /*
     * returns unit fee for a rateplan based upon the
     * billingPeriod. Annual one get's a division
     * by 12 (no. of months).
     */
    _getRatePlanUnitFee: function _getRatePlanUnitFee(fee, billingPeriod) {
      return billingPeriod === 'ANNUAL' ? Math.ceil(fee / 12) : fee;
    },
    _getDisplayTier: function _getDisplayTier(charge) {
      var _this5 = this;

      var tiersArray = charge.get('tiers').toArray();
      return _npmLodash.default.reduce(tiersArray, function (result, tier) {
        if (tier.get('priceFormat') === 'PERUNIT') {
          result = _npmLodash.default.assign(result, _this5._getPerUnitTier(tier, tier.get('currency')));
        } else if (tier.get('priceFormat') === 'FLATFEE') {
          result = _npmLodash.default.assign(result, _this5._getFlatFeeTier(tier, tier.get('currency')));
        }
        return result;
      }, {});
    },
    _getPerUnitTier: function _getPerUnitTier(tier, currency) {
      return {
        currency: currency,
        unitFee: tier.get('price'),
        maxUnits: tier.get('endingUnit'),
        flatFee: 0,
        flatUpto: 0
      };
    },
    _getFlatFeeTier: function _getFlatFeeTier(tier, currency) {
      return {
        currency: currency,
        flatFee: tier.get('price'),
        flatUpto: tier.get('endingUnit'),
        unitFee: 0,
        maxUnits: 0
      };
    },


    /*
     * returns annual savings for a given plan and the
     * current number of seats.
     */
    getAnnualSavings: function getAnnualSavings(plan, agentsCount) {
      if (!plan || !plan.MONTH || !plan.ANNUAL) {
        return 0;
      }
      return (plan.MONTH.price - plan.ANNUAL.price) * 12 * agentsCount;
    },


    /*
     * returns total for a given plan, term and number of
     * required agents
     */
    getSubscriptionTotal: function getSubscriptionTotal(plan, term, agentsCount) {
      return plan[term].actualPrice * agentsCount;
    },


    /*
     * return the quantity from the last node of charges
     * where unitOfMeasure is AGENTS.
     *
     * @param  {Array} charges
     *
     * @return {Number}
     */
    getAgentsQuantity: function getAgentsQuantity(charges) {
      var agentCharge = _npmLodash.default.last(_npmLodash.default.filter(charges.toArray(), function (charge) {
        return charge.get('unitOfMeasure') === 'AGENTS' && charge.get('isLastSegment');
      }));
      return agentCharge ? agentCharge.get('quantity') : 0;
    },


    /*
     * return the quantity from the last node of charges
     * where unitOfMeasure is COLLABORATORS.
     *
     * @param  {Array} charges
     *
     * @return {Number}
     */
    getCollaboratorsQuantity: function getCollaboratorsQuantity(charges) {
      var collaboratorCharge = _npmLodash.default.last(_npmLodash.default.filter(charges.toArray(), function (charge) {
        return charge.get('unitOfMeasure') === 'COLLABORATORS' && charge.get('isLastSegment');
      }));
      return collaboratorCharge ? collaboratorCharge.get('quantity') : 0;
    },


    /*
     * returns renewal date for a given term
     */
    getRenewalDate: function getRenewalDate(term) {
      var termMonths = term === 'MONTH' ? 1 : 12;
      return (0, _moment.default)().add(termMonths, 'months');
    },


    /*
     * returns gross total for a given country and vatId
     */
    getGrossTotal: function getGrossTotal(total, country, vatId) {
      if (this._countryAlwaysChargeVat(country)) {
        return total + this._calculateVat(total);
      } else if (this.countryHasVat(country) && !vatId) {
        return total + this._calculateVat(total);
      } else {
        return total;
      }
    },


    /*
     * returns whether country charges vat, no matter what
     */
    _countryAlwaysChargeVat: function _countryAlwaysChargeVat(country) {
      return ['UK', 'GB'].indexOf(country) > -1;
    },


    /*
     * returns whether country charges vat or not
     */
    countryHasVat: function countryHasVat(country) {
      return ['UK', 'AT', 'BE', 'GB', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'EL', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'BG', 'GR'].indexOf(country) > -1;
    },


    /*
     * calculates vat on a given total
     */
    _calculateVat: function _calculateVat(amount) {
      var percentageCharged = 20;
      return amount * percentageCharged / 100;
    },
    getUpgradePayload: function getUpgradePayload(rateplan, newAgentsCount, selectedCollaborators) {
      if (!rateplan) {
        return [];
      }
      return [{
        product_rateplan_id: rateplan.get('id'),
        charges: this.getUpgradeCharges(rateplan, newAgentsCount, selectedCollaborators)
      }];
    },
    getUpgradeCharges: function getUpgradeCharges(rateplan, newAgentsCount, selectedCollaborators) {
      return _npmLodash.default.reduce(rateplan.get('charges').toArray(), function (result, charge) {
        if (charge.get('unitOfMeasure') === 'AGENTS') {
          result.push({
            product_rateplan_charge_id: charge.get('id'),
            quantity: newAgentsCount
          });
        }

        if (charge.get('unitOfMeasure') === 'COLLABORATORS') {
          result.push({
            product_rateplan_charge_id: charge.get('id'),
            quantity: selectedCollaborators
          });
        }

        return result;
      }, []);
    },
    getTerms: function getTerms(rateplans) {
      return _npmLodash.default.uniq(_npmLodash.default.reduce(rateplans.toArray(), function (terms, rateplan) {
        var rateplansTerms = _npmLodash.default.map(rateplan.get('charges').toArray(), function (charge) {
          return charge.get('billingPeriod');
        });
        terms = terms.concat(rateplansTerms);
        return terms;
      }, []));
    },
    getTermItem: function getTermItem(key) {
      return {
        value: key,
        label: this.get('i18n').t('account.plans.terms.' + key)
      };
    },
    getSeatsLimit: function getSeatsLimit(plan, term) {
      var defaultLimit = 999;
      if (!plan || !plan[term]) {
        return defaultLimit;
      }
      return plan[term].unitFee ? defaultLimit : plan[term].flatUpto;
    },
    getSubscriptionRatePlan: function getSubscriptionRatePlan(rateplans) {
      if (rateplans !== undefined) {
        return _npmLodash.default.find(rateplans.toArray(), function (rateplan) {
          return rateplan.get('productRateplan.productRateplanType') === 'PRIMARY';
        }) || null;
      }

      return null;
    }
  });
});