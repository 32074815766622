define("frontend-cp/components/ko-universal-search/search-hints/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "is-active": "ko-universal-search_search-hints__is-active_1a3ihs",
    "block": "ko-universal-search_search-hints__block_1a3ihs",
    "container": "ko-universal-search_search-hints__container_1a3ihs",
    "suggestion-description": "ko-universal-search_search-hints__suggestion-description_1a3ihs"
  };
});