define("frontend-cp/components/ko-admin/rateplans/price/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "plan-price-amount": "ko-admin_rateplans_price__plan-price-amount_1ye002",
    "plan-price": "ko-admin_rateplans_price__plan-price_1ye002",
    "label-helper": "ko-admin_rateplans_price__label-helper_1ye002"
  };
});