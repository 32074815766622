define('frontend-cp/components/ko-case/sla-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    //Params
    sla: null,
    slaMetrics: null,

    // HTML
    tagName: ''
  });
});