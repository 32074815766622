define("frontend-cp/components/ko-editable-text/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-editable-text__container_149mbn",
    "hoverable": "ko-editable-text__hoverable_149mbn",
    "pencil": "ko-editable-text__pencil_149mbn",
    "input": "ko-editable-text__input_149mbn",
    "input--large": "ko-editable-text__input--large_149mbn",
    "text": "ko-editable-text__text_149mbn",
    "emphasizedText": "ko-editable-text__emphasizedText_149mbn",
    "emphasizedText--large": "ko-editable-text__emphasizedText--large_149mbn",
    "text--kre-edited": "ko-editable-text__text--kre-edited_149mbn",
    "text--edited": "ko-editable-text__text--edited_149mbn",
    "text--disabled": "ko-editable-text__text--disabled_149mbn",
    "container--empty": "ko-editable-text__container--empty_149mbn",
    "container--error": "ko-editable-text__container--error_149mbn"
  };
});