define('frontend-cp/models/sla', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('always-string', { defaultValue: '' }),
    description: _emberData.default.attr('always-string', { defaultValue: '' }),
    executionOrder: _emberData.default.attr('number'),
    predicateCollections: _emberData.default.hasMany('predicate-collection', { async: false }),
    targets: _emberData.default.hasMany('sla-target', { async: false }),
    isEnabled: _emberData.default.attr('boolean', { defaultValue: true }),
    isDeleted: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});