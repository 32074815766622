define('frontend-cp/components/ko-admin/roles/form/component', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var readOnly = Ember.computed.readOnly;
  var filterBy = Ember.computed.filterBy;
  var on = Ember.on;
  var Component = Ember.Component;
  var underscore = Ember.String.underscore;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;


  var Permission = EmberObject.extend({
    context: null,
    name: null,
    values: null,
    hasHelpText: false,
    role: readOnly('context.role'),

    translationKey: computed('name', function () {
      return underscore(this.get('name'));
    }),

    isEnabled: computed('values.[]', 'role.permissions.@each.value', function () {
      var _this = this;

      return this.get('values').every(function (name) {
        var permission = _this.get('role.permissions').findBy('name', name);
        return !!permission && permission.get('value');
      });
    })
  });

  var PermissionGroup = EmberObject.extend({
    context: null,
    name: null,
    isAvailable: true,
    permissions: null,
    role: readOnly('context.role'),

    translationKey: computed('name', function () {
      return underscore(this.get('name'));
    })
  });

  var AdminPermissionGroup = PermissionGroup.extend({
    isAvailable: computed('role.roleType', function () {
      return ['ADMIN', 'OWNER'].includes(this.get('role.roleType'));
    })
  });

  var AgentAndUpPermissionGroup = PermissionGroup.extend({
    isAvailable: computed('role.roleType', function () {
      return ['ADMIN', 'OWNER', 'AGENT'].includes(this.get('role.roleType'));
    })
  });

  exports.default = Component.extend({
    notification: service(),
    i18n: service(),
    roles: service(),
    role: null,
    availablePermissionGroups: filterBy('permissionGroups', 'isAvailable'),

    initializePermissions: on('init', function () {
      if (!this.get('role.isNew')) {
        return;
      }

      _npmLodash.default.flatMap(this.get('permissionGroups'), function (group) {
        return group.get('permissions');
      }).forEach(function (permission) {
        permission.set('isEnabled', true);
      });
    }),

    canBeDeleted: computed('role.isNew', 'role.isSystem', function () {
      return !(this.get('role.isNew') || this.get('role.isSystem'));
    }),

    roleType: computed('role.roleType', 'roles.allTypes', function () {
      return this.get('roles.allTypes').findBy('id', this.get('role.roleType'));
    }),

    agentCaseAccessType: computed('role.agentCaseAccess', 'roles.availableAgentCaseAccessTypes', function () {
      return this.get('roles.availableAgentCaseAccessTypes').findBy('id', this.get('role.agentCaseAccess'));
    }),

    permissionGroups: computed(function () {
      var _this2 = this;

      var permissionGroup = function permissionGroup(name) {
        for (var _len = arguments.length, params = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          params[_key - 1] = arguments[_key];
        }

        return PermissionGroup.create.apply(PermissionGroup, [{ context: _this2, name: name }].concat(params));
      };

      var permission = function permission(name) {
        for (var _len2 = arguments.length, params = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          params[_key2 - 1] = arguments[_key2];
        }

        return Permission.create.apply(Permission, [{ context: _this2, name: name }].concat(params));
      };

      var adminPermissionGroup = function adminPermissionGroup(name) {
        for (var _len3 = arguments.length, params = Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
          params[_key3 - 1] = arguments[_key3];
        }

        return AdminPermissionGroup.create.apply(AdminPermissionGroup, [{ context: _this2, name: name }].concat(params));
      };

      var agentAndUpPermissionGroup = function agentAndUpPermissionGroup(name) {
        for (var _len4 = arguments.length, params = Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
          params[_key4 - 1] = arguments[_key4];
        }

        return AgentAndUpPermissionGroup.create.apply(AgentAndUpPermissionGroup, [{ context: _this2, name: name }].concat(params));
      };

      return [permissionGroup('cases', {
        permissions: [permission('cases-create-reply', {
          values: ['cases.public_reply'],
          qaClass: 'qa-ko-admin_roles-create-public-reply-checkbox'
        }), permission('cases-split-and-merge', {
          hasHelpText: true,
          values: ['cases.merge', 'cases.split']
        }), permission('cases-trash', {
          hasHelpText: true,
          values: ['cases.trash']
        }), permission('cases-view-trash', {
          hasHelpText: true,
          values: ['cases.view_trash']
        }), permission('cases-view-suspended', {
          hasHelpText: true,
          values: ['cases.view_suspended']
        })]
      }), permissionGroup('users-and-orgs', {
        permissions: [permission('users-and-orgs-create-update', {
          hasHelpText: true,
          values: ['users.update', 'organizations.update'],
          qaClass: 'qa-ko-admin_roles-create-new-users-checkbox'
        }), permission('users-and-orgs-delete', {
          hasHelpText: true,
          values: ['users.delete', 'organizations.delete']
        })]
      }), permissionGroup('help-center', {
        permissions: [permission('help-center-manage', {
          hasHelpText: true,
          values: ['help_center.manage'],
          qaClass: 'qa-ko-admin_roles-manage-helpcenter-checkbox'
        }), permission('help-center-manage-articles', {
          hasHelpText: true,
          values: ['help_center_articles.manage']
        }), permission('help-center-publish-articles', {
          hasHelpText: true,
          values: ['help_center_articles.publish']
        })]
      }), adminPermissionGroup('user-administration', {
        permissions: [permission('teams-manage', {
          hasHelpText: true,
          values: ['teams.manage'],
          qaClass: 'qa-ko-admin_roles-manage-teams-checkbox'
        }), permission('roles-and-permissions-manage', {
          hasHelpText: true,
          values: ['roles.manage']
        })]
      }), adminPermissionGroup('system-administration', {
        permissions: [permission('apps-manage', { values: ['apps.manage'] }), permission('endpoints-manage', { values: ['endpoints.manage'] }), permission('channels-manage', {
          hasHelpText: true,
          values: ['channels.manage']
        }), permission('brands-manage', {
          values: ['brands.manage']
        }), permission('case-views-manage', {
          values: ['case_views.manage']
        }), permission('case-macros-manage', {
          values: ['macros.manage']
        }), permission('localization-manage', {
          values: ['localization.manage']
        }), permission('automations-manage', {
          hasHelpText: true,
          values: ['automations.manage']
        }), permission('slas-and-business-hours-manage', {
          hasHelpText: true,
          values: ['slas.manage', 'business_hours.manage']
        }), permission('case-fields-manage', {
          hasHelpText: true,
          values: ['case_fields.manage']
        }), permission('users-and-organization-fields-manage', {
          hasHelpText: true,
          values: ['organizations_fields.manage', 'user_fields.manage']
        }), permission('settings-manage', {
          hasHelpText: true,
          values: ['settings.manage']
        })]
      }), agentAndUpPermissionGroup('insights', {
        permissions: [permission('insights-access', {
          hasHelpText: true,
          values: ['insights.access']
        })]
      })];
    }),

    // Actions
    actions: {
      save: function save() {
        var _this3 = this;

        return this.get('role').save().then(function (role) {
          var adapter = getOwner(_this3).lookup('adapter:application');

          var permissions = _this3.get('availablePermissionGroups').reduce(function (memo, permissionGroup) {
            permissionGroup.get('permissions').forEach(function (permission) {
              permission.get('values').forEach(function (name) {
                memo[name] = permission.get('isEnabled') ? 1 : 0;
              });
            });

            return memo;
          }, {});

          return adapter.ajax(adapter.namespace + '/roles/' + role.get('id') + '/permissions', 'PUT', { data: { permission: permissions } }).then(function () {
            role.get('permissions').reload();
          });
        });
      },
      deleteRole: function deleteRole() {
        var _this4 = this;

        return this.get('role').destroyRecord().then(function () {
          _this4.send('success');
        });
      },
      success: function success() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        if (this.attrs.onSave) {
          this.attrs.onSave();
        }
      },
      selectRoleType: function selectRoleType(roleType) {
        this.set('role.roleType', roleType.get('id'));
      },
      selectAgentCaseAccessType: function selectAgentCaseAccessType(agentCaseAccessType) {
        this.set('role.agentCaseAccess', agentCaseAccessType.get('id'));
      }
    }
  });
});