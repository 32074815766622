define('frontend-cp/session/admin/account/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    plan: service(),

    beforeModel: function beforeModel() {
      return this.get('plan').fetchPlan();
    }
  });
});