define('frontend-cp/components/ko-case-content/field/status/swatch-with-label/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attrs
    prefix: null
  });
});