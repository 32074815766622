define('frontend-cp/session/agent/cases/case/index/controller', ['exports', 'npm:he'], function (exports, _npmHe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    queryParams: ['postId', 'filter', 'noteId'],
    filter: 'all',
    postId: null,
    noteId: null,

    caseSubject: computed('case.subject', function () {
      return _npmHe.default.unescape(this.get('case.subject'));
    }),

    // Actions
    actions: {
      updateQueryParams: function updateQueryParams(changes) {
        this.setProperties(changes);
        return true;
      }
    }
  });
});