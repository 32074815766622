define('frontend-cp/components/ko-checkbox/component', ['exports', 'frontend-cp/lib/keycodes', 'frontend-cp/mixins/with-unique-id'], function (exports, _keycodes, _withUniqueId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_withUniqueId.default, {
    isVerticallyCentered: false,
    isTopAligned: false,
    disabled: false,
    /**
     * size
     * @type Boolean
     * @values small | large \ null (default)
     *
     * @small 12px with 1px border
     * @null  14px with 1px border
     * @large 18px with 1px border
     */
    size: null,
    checked: false,
    tabindex: 0,
    label: '',
    tagName: '',
    qaClass: '',

    // Actions
    actions: {
      toggleCheckbox: function toggleCheckbox(event) {
        if (!this.disabled) {
          if (this.get('onCheck')) {
            this.sendAction('onCheck', !this.get('checked'), event);
          } else {
            this.toggleProperty('checked');
          }
        }
      },
      keyDown: function keyDown() {
        var e = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        if (e.keyCode === _keycodes.space) {
          return false;
        }
      },
      keyUp: function keyUp() {
        var e = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        if (e.keyCode === _keycodes.space) {
          this.send('toggleCheckbox');
        }
        return false;
      },
      click: function click() {
        var e = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        e.stopPropagation && e.stopPropagation();

        return false;
      }
    }
  });
});