define('frontend-cp/session/admin/customizations/privacy/schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _virtualModel.model)('privacy', {
    url: (0, _virtualModel.attr)(),
    privacyType: (0, _virtualModel.attr)(),
    locale: (0, _virtualModel.attr)(),
    default: (0, _virtualModel.attr)()
  });
});