define("frontend-cp/components/ko-universal-search/result/layout/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-universal-search_result_layout__container_uaui50",
    "detailContainer": "ko-universal-search_result_layout__detailContainer_uaui50",
    "content": "ko-universal-search_result_layout__content_uaui50",
    "top": "ko-universal-search_result_layout__top_uaui50",
    "bottom": "ko-universal-search_result_layout__bottom_uaui50",
    "right": "ko-universal-search_result_layout__right_uaui50"
  };
});