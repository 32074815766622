define("frontend-cp/components/article-form/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "froalaTextArea": "article-form__froalaTextArea_t3sclb",
    "title-field": "article-form__title-field_t3sclb",
    "form-field": "article-form__form-field_t3sclb",
    "create-article-form": "article-form__create-article-form_t3sclb",
    "create-article-form-left": "article-form__create-article-form-left_t3sclb",
    "create-article-form-right": "article-form__create-article-form-right_t3sclb",
    "trigger": "article-form__trigger_t3sclb _trigger_jvqwfg _statusIcon_jvqwfg",
    "dropdown": "article-form__dropdown_t3sclb _dropdown_jvqwfg",
    "help-text": "article-form__help-text_t3sclb"
  };
});