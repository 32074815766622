define('frontend-cp/components/ko-select/multiple/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    allowCreate: false,
    disabled: false,
    onChange: null,
    onValueAddition: null,
    onValueRemoval: null,
    onSuggestion: null,
    options: null,
    renderInPlace: false,
    matchTriggerWidth: true,
    searchField: 'name',
    placeholder: '',
    selected: [],
    allowAddDuringSearch: false,

    // State
    focused: false,

    // CPs
    searchOnly: not('options.length'),

    focusIn: function focusIn() {
      this.set('focused', true);
    },
    focusOut: function focusOut() {
      this.set('focused', false);
    }
  });
});