define("frontend-cp/components/ko-agent-content/action-menu/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "item": "ko-agent-content_action-menu_item__item_478uld",
    "disabled": "ko-agent-content_action-menu_item__disabled_478uld"
  };
});