define("frontend-cp/components/ko-on-boarding-old/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "smoothTransition": "cubic-bezier(0.19, 1, 0.22, 1)",
    "onboard": "ko-on-boarding-old__onboard_1kzaln",
    "wrapper": "ko-on-boarding-old__wrapper_1kzaln",
    "header": "ko-on-boarding-old__header_1kzaln",
    "scaleup--small": "ko-on-boarding-old__scaleup--small_1kzaln",
    "title": "ko-on-boarding-old__title_1kzaln",
    "subtitle": "ko-on-boarding-old__subtitle_1kzaln",
    "cards": "ko-on-boarding-old__cards_1kzaln",
    "card": "ko-on-boarding-old__card_1kzaln",
    "is-disabled": "ko-on-boarding-old__is-disabled_1kzaln",
    "card__title": "ko-on-boarding-old__card__title_1kzaln",
    "card__description": "ko-on-boarding-old__card__description_1kzaln",
    "card__button": "ko-on-boarding-old__card__button_1kzaln",
    "is-active": "ko-on-boarding-old__is-active_1kzaln",
    "is-done": "ko-on-boarding-old__is-done_1kzaln",
    "card__count": "ko-on-boarding-old__card__count_1kzaln",
    "card__count__number": "ko-on-boarding-old__card__count__number_1kzaln",
    "card__count__tick": "ko-on-boarding-old__card__count__tick_1kzaln",
    "modal-section": "ko-on-boarding-old__modal-section_1kzaln",
    "is-aligned": "ko-on-boarding-old__is-aligned_1kzaln",
    "transition-delay-1": "ko-on-boarding-old__transition-delay-1_1kzaln",
    "transition-delay-2": "ko-on-boarding-old__transition-delay-2_1kzaln",
    "no-transition": "ko-on-boarding-old__no-transition_1kzaln",
    "modal-section--spaced": "ko-on-boarding-old__modal-section--spaced_1kzaln",
    "modal-section--narrow": "ko-on-boarding-old__modal-section--narrow_1kzaln",
    "modal-section--wide": "ko-on-boarding-old__modal-section--wide_1kzaln",
    "modal-section--low-scale": "ko-on-boarding-old__modal-section--low-scale_1kzaln",
    "modal-section--allset": "ko-on-boarding-old__modal-section--allset_1kzaln",
    "add-agents-fields": "ko-on-boarding-old__add-agents-fields_1kzaln",
    "narrow-success-message": "ko-on-boarding-old__narrow-success-message_1kzaln",
    "modal-section__wrapper": "ko-on-boarding-old__modal-section__wrapper_1kzaln",
    "modal-section__content": "ko-on-boarding-old__modal-section__content_1kzaln",
    "modal-section__social": "ko-on-boarding-old__modal-section__social_1kzaln",
    "heading": "ko-on-boarding-old__heading_1kzaln",
    "label": "ko-on-boarding-old__label_1kzaln",
    "paired-buttons": "ko-on-boarding-old__paired-buttons_1kzaln",
    "messenger-preview": "ko-on-boarding-old__messenger-preview_1kzaln",
    "messenger-right-text": "ko-on-boarding-old__messenger-right-text_1kzaln",
    "messenger-right-lede-text": "ko-on-boarding-old__messenger-right-lede-text_1kzaln",
    "messenger-text-transition": "ko-on-boarding-old__messenger-text-transition_1kzaln",
    "messenger-text-in": "ko-on-boarding-old__messenger-text-in_1kzaln",
    "list": "ko-on-boarding-old__list_1kzaln",
    "list-item": "ko-on-boarding-old__list-item_1kzaln",
    "messenger-button-pair": "ko-on-boarding-old__messenger-button-pair_1kzaln",
    "allset-tick": "ko-on-boarding-old__allset-tick_1kzaln",
    "light-text": "ko-on-boarding-old__light-text_1kzaln",
    "gray-box": "ko-on-boarding-old__gray-box_1kzaln",
    "gray-box__text": "ko-on-boarding-old__gray-box__text_1kzaln",
    "button-text": "ko-on-boarding-old__button-text_1kzaln",
    "next-step": "ko-on-boarding-old__next-step_1kzaln",
    "download-container": "ko-on-boarding-old__download-container_1kzaln",
    "note": "ko-on-boarding-old__note_1kzaln",
    "space-between": "ko-on-boarding-old__space-between_1kzaln",
    "copy-button": "ko-on-boarding-old__copy-button_1kzaln",
    "input-addon": "ko-on-boarding-old__input-addon_1kzaln",
    "facebook-pages": "ko-on-boarding-old__facebook-pages_1kzaln",
    "facebook-pages__item": "ko-on-boarding-old__facebook-pages__item_1kzaln",
    "facebook-pages-loader": "ko-on-boarding-old__facebook-pages-loader_1kzaln",
    "no-pages": "ko-on-boarding-old__no-pages_1kzaln",
    "confetti": "ko-on-boarding-old__confetti_1kzaln",
    "fade-in": "ko-on-boarding-old__fade-in_1kzaln"
  };
});