define('frontend-cp/components/ko-universal-search/search-hints/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    recentSearches: [],
    highlightedSuggestion: null,
    onHighlight: function onHighlight() {},
    onSelectSuggestion: function onSelectSuggestion() {}
  });
});