define("frontend-cp/trial-data/insights/slas", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "status": 200,
    "data": [{
      "id": 3,
      "title": "Support SLA",
      "description": "Support SLA",
      "execution_order": 1,
      "predicate_collections": [{
        "id": 3,
        "resource_type": "predicate_collection"
      }],
      "targets": [{
        "id": 31,
        "resource_type": "sla_target"
      }, {
        "id": 32,
        "resource_type": "sla_target"
      }, {
        "id": 33,
        "resource_type": "sla_target"
      }, {
        "id": 34,
        "resource_type": "sla_target"
      }, {
        "id": 38,
        "resource_type": "sla_target"
      }, {
        "id": 42,
        "resource_type": "sla_target"
      }, {
        "id": 35,
        "resource_type": "sla_target"
      }, {
        "id": 39,
        "resource_type": "sla_target"
      }, {
        "id": 43,
        "resource_type": "sla_target"
      }, {
        "id": 36,
        "resource_type": "sla_target"
      }, {
        "id": 40,
        "resource_type": "sla_target"
      }, {
        "id": 44,
        "resource_type": "sla_target"
      }, {
        "id": 37,
        "resource_type": "sla_target"
      }, {
        "id": 41,
        "resource_type": "sla_target"
      }, {
        "id": 45,
        "resource_type": "sla_target"
      }],
      "is_enabled": true,
      "created_at": "2016-06-28T09:27:50+00:00",
      "updated_at": "2016-06-28T09:27:50+00:00",
      "resource_type": "sla",
      "resource_url": "https://brewfictus.kayako.com/api/v1/slas/3"
    }],
    "resources": {
      "case_priority": {
        "1": {
          "id": 1,
          "label": "Low",
          "level": 1,
          "created_at": "2016-06-28T08:43:37+00:00",
          "updated_at": "2016-06-28T08:43:37+00:00",
          "resource_type": "case_priority",
          "resource_url": "https://brewfictus.kayako.com/api/v1/cases/priorities/1"
        },
        "2": {
          "id": 2,
          "label": "Normal",
          "level": 2,
          "created_at": "2016-06-28T08:43:37+00:00",
          "updated_at": "2016-06-28T08:43:37+00:00",
          "resource_type": "case_priority",
          "resource_url": "https://brewfictus.kayako.com/api/v1/cases/priorities/2"
        },
        "3": {
          "id": 3,
          "label": "High",
          "level": 3,
          "created_at": "2016-06-28T08:43:37+00:00",
          "updated_at": "2016-06-28T08:43:37+00:00",
          "resource_type": "case_priority",
          "resource_url": "https://brewfictus.kayako.com/api/v1/cases/priorities/3"
        },
        "4": {
          "id": 4,
          "label": "Urgent",
          "level": 4,
          "created_at": "2016-06-28T08:43:37+00:00",
          "updated_at": "2016-06-28T08:43:37+00:00",
          "resource_type": "case_priority",
          "resource_url": "https://brewfictus.kayako.com/api/v1/cases/priorities/4"
        }
      },
      "predicate_collection": {
        "3": {
          "id": 3,
          "propositions": [{
            "id": 3,
            "resource_type": "proposition"
          }],
          "resource_type": "predicate_collection"
        }
      },
      "proposition": {
        "3": {
          "id": 3,
          "field": "cases.casetypeid",
          "operator": "comparison_equalto",
          "value": "3",
          "resource_type": "proposition"
        }
      },
      "sla_target": {
        "31": {
          "id": 31,
          "priority": null,
          "type": "FIRST_REPLY_TIME",
          "goal_in_seconds": 600,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "32": {
          "id": 32,
          "priority": null,
          "type": "NEXT_REPLY_TIME",
          "goal_in_seconds": 600,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "33": {
          "id": 33,
          "priority": null,
          "type": "RESOLUTION_TIME",
          "goal_in_seconds": 600,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "34": {
          "id": 34,
          "priority": {
            "id": 1,
            "resource_type": "case_priority"
          },
          "type": "FIRST_REPLY_TIME",
          "goal_in_seconds": 3600,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "35": {
          "id": 35,
          "priority": {
            "id": 2,
            "resource_type": "case_priority"
          },
          "type": "FIRST_REPLY_TIME",
          "goal_in_seconds": 1800,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "36": {
          "id": 36,
          "priority": {
            "id": 3,
            "resource_type": "case_priority"
          },
          "type": "FIRST_REPLY_TIME",
          "goal_in_seconds": 1200,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "37": {
          "id": 37,
          "priority": {
            "id": 4,
            "resource_type": "case_priority"
          },
          "type": "FIRST_REPLY_TIME",
          "goal_in_seconds": 600,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "38": {
          "id": 38,
          "priority": {
            "id": 1,
            "resource_type": "case_priority"
          },
          "type": "NEXT_REPLY_TIME",
          "goal_in_seconds": 3600,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "39": {
          "id": 39,
          "priority": {
            "id": 2,
            "resource_type": "case_priority"
          },
          "type": "NEXT_REPLY_TIME",
          "goal_in_seconds": 1800,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "40": {
          "id": 40,
          "priority": {
            "id": 3,
            "resource_type": "case_priority"
          },
          "type": "NEXT_REPLY_TIME",
          "goal_in_seconds": 1200,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "41": {
          "id": 41,
          "priority": {
            "id": 4,
            "resource_type": "case_priority"
          },
          "type": "NEXT_REPLY_TIME",
          "goal_in_seconds": 600,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "42": {
          "id": 42,
          "priority": {
            "id": 1,
            "resource_type": "case_priority"
          },
          "type": "RESOLUTION_TIME",
          "goal_in_seconds": 3600,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "43": {
          "id": 43,
          "priority": {
            "id": 2,
            "resource_type": "case_priority"
          },
          "type": "RESOLUTION_TIME",
          "goal_in_seconds": 1800,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "44": {
          "id": 44,
          "priority": {
            "id": 3,
            "resource_type": "case_priority"
          },
          "type": "RESOLUTION_TIME",
          "goal_in_seconds": 1200,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        },
        "45": {
          "id": 45,
          "priority": {
            "id": 4,
            "resource_type": "case_priority"
          },
          "type": "RESOLUTION_TIME",
          "goal_in_seconds": 600,
          "operational_hours": "BUSINESS_HOURS",
          "resource_type": "sla_target"
        }
      }
    },
    "resource": "sla",
    "offset": 0,
    "limit": 10,
    "total_count": 1,
    "logs": [{
      "level": "WARNING",
      "message": "Entire resources enforced for flat mode"
    }]
  };
});