define('frontend-cp/components/ko-tab-strip/component', ['exports', 'ember-keyboard-shortcuts/mixins/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend(_component.default, {
    tagName: '',

    tabStore: service(),

    tabs: computed.alias('tabStore.tabs'),

    keyboardShortcuts: {
      ']': {
        action: 'selectNextTab',
        global: false
      },
      '[': {
        action: 'selectPreviousTab',
        global: false
      },
      x: {
        action: 'closeCurrentTab',
        global: false
      }
    },

    actions: {
      closeTab: function closeTab(tab, event) {
        var tabStore = this.get('tabStore');

        if (event) {
          event.stopPropagation();
          event.preventDefault();
        }

        tabStore.close(tab);
      },
      selectNextTab: function selectNextTab() {
        var tabStore = this.get('tabStore');
        tabStore.selectNextTab();
      },
      selectPreviousTab: function selectPreviousTab() {
        var tabStore = this.get('tabStore');
        tabStore.selectPreviousTab();
      },
      closeCurrentTab: function closeCurrentTab() {
        var tabStore = this.get('tabStore');
        tabStore.closeActiveTab();
      }
    }
  });
});