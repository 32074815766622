define("frontend-cp/components/ko-agent-dropdown/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "iconSquare": "ko-agent-dropdown__iconSquare_15uh4p",
    "icon": "ko-agent-dropdown__icon_15uh4p",
    "trigger": "ko-agent-dropdown__trigger_15uh4p",
    "drop": "ko-agent-dropdown__drop_15uh4p",
    "drop--1": "ko-agent-dropdown__drop--1_15uh4p",
    "drop--2": "ko-agent-dropdown__drop--2_15uh4p",
    "drop--3": "ko-agent-dropdown__drop--3_15uh4p",
    "navItems": "ko-agent-dropdown__navItems_15uh4p",
    "navItem": "ko-agent-dropdown__navItem_15uh4p",
    "is-active": "ko-agent-dropdown__is-active_15uh4p",
    "focused": "ko-agent-dropdown__focused_15uh4p",
    "unfocused": "ko-agent-dropdown__unfocused_15uh4p",
    "new-conv": "ko-agent-dropdown__new-conv_15uh4p",
    "new-user": "ko-agent-dropdown__new-user_15uh4p",
    "new-org": "ko-agent-dropdown__new-org_15uh4p",
    "new-search": "ko-agent-dropdown__new-search_15uh4p",
    "navItemImg": "ko-agent-dropdown__navItemImg_15uh4p",
    "navItemText": "ko-agent-dropdown__navItemText_15uh4p",
    "dropdown-trigger-label": "ko-agent-dropdown__dropdown-trigger-label_15uh4p"
  };
});