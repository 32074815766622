define("frontend-cp/components/ko-status-swatch/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "outer": "ko-status-swatch__outer_134cns",
    "has-border": "ko-status-swatch__has-border_134cns",
    "inner": "ko-status-swatch__inner_134cns",
    "status-draft": "ko-status-swatch__status-draft_134cns",
    "status-new": "ko-status-swatch__status-new_134cns",
    "status-open": "ko-status-swatch__status-open_134cns",
    "status-pendingapproval": "ko-status-swatch__status-pendingapproval_134cns",
    "status-pending": "ko-status-swatch__status-pending_134cns",
    "status-custom": "ko-status-swatch__status-custom_134cns",
    "status-published": "ko-status-swatch__status-published_134cns",
    "status-completed": "ko-status-swatch__status-completed_134cns",
    "status-closed": "ko-status-swatch__status-closed_134cns"
  };
});