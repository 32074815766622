define('frontend-cp/adapters/mailbox', ['exports', 'frontend-cp/adapters/application', 'frontend-cp/adapters/unpanginate-mixin'], function (exports, _application, _unpanginateMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_unpanginateMixin.default, {
    makeDefault: function makeDefault(mailbox) {
      var url = this.namespace + '/mailboxes/default';
      var options = { data: { mailbox_id: mailbox.get('id') } };

      return this.ajax(url, 'PUT', options);
    }
  });
});