define('frontend-cp/components/ko-notification-center/group/component', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    notificationsList: [],
    groupedNotifications: computed('notificationsList.[]', function () {
      return (0, _npmLodash.default)(this.get('notificationsList')).groupBy(function (notification) {
        return notification.get('notificationDay');
      }).value();
    })
  });
});