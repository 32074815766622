define('frontend-cp/models/user', ['exports', 'ember-data', 'ember-data-model-fragments', 'frontend-cp/models/has-posts'], function (exports, _emberData, _emberDataModelFragments, _hasPosts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _hasPosts.default.extend({
    uuid: _emberData.default.attr('string'),
    fullName: _emberData.default.attr('string'),
    designation: _emberData.default.attr('string'),
    alias: _emberData.default.attr('string'),
    isEnabled: _emberData.default.attr('boolean'),
    isMfaEnabled: _emberData.default.attr('boolean'),
    role: _emberData.default.belongsTo('role', { async: false }),
    avatar: _emberData.default.attr('string'),
    organization: _emberData.default.belongsTo('organization', { async: true }),
    teams: _emberData.default.hasMany('team', { async: false }),
    customFields: _emberDataModelFragments.default.fragmentArray('user-field-value', { defaultValue: [] }),
    fieldValues: _emberDataModelFragments.default.fragmentArray('user-field-value', { defaultValue: [] }), // write only
    locale: _emberData.default.belongsTo('locale', { async: true }),
    timeZone: _emberData.default.attr('string'),
    timeZoneOffset: _emberData.default.attr('number'),
    greeting: _emberData.default.attr('string'),
    signature: _emberData.default.attr('string'),
    statusMessage: _emberData.default.attr('string'),
    passwordUpdateAt: _emberData.default.attr('date'),
    avatarUpdateAt: _emberData.default.attr('date'),
    activityAt: _emberData.default.attr('date'),
    visitedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    lastActiveAt: _emberData.default.attr('date'),
    lastSeenAt: _emberData.default.attr('date'),
    lastActivityAt: _emberData.default.attr('date'),
    lastLoggedInAt: _emberData.default.attr('date'),
    pinnedNotesCount: _emberData.default.attr('number'),
    viewNotes: computed(function () {
      return [];
    }),

    presenceChannel: _emberData.default.attr('string'),
    notificationChannel: _emberData.default.attr('string'),

    agentCaseAccess: _emberData.default.attr('string', { defaultValue: 'ALL' }),
    organizationCaseAccess: _emberData.default.attr('string', { defaultValue: 'REQUESTED' }),

    // returned if we hit the /users/me endpoint
    permissions: _emberData.default.hasMany('permission'),

    // Shadow children fields
    accesslogs: _emberData.default.hasMany('access-log', { async: true }),
    recentCases: _emberData.default.hasMany('case', { async: true, inverse: 'requester' }),
    activeCases: computed('recentCases.@each.statusType', function () {
      var closedTypes = ['COMPLETED', 'CLOSED'];
      return this.get('recentCases').filter(function (_case) {
        return !closedTypes.includes(_case.get('status.statusType'));
      });
    }),
    tags: _emberData.default.hasMany('tag', { async: true }),
    notes: _emberData.default.hasMany('user-note', { async: true }),

    emails: _emberData.default.hasMany('identity-email', { async: false }),
    phones: _emberData.default.hasMany('identity-phone', { async: false }),
    twitter: _emberData.default.hasMany('identity-twitter', { async: false }),
    facebook: _emberData.default.hasMany('identity-facebook', { async: false }),

    resourceType: 'user',

    save: function save() {
      var _this = this;

      this.get('customFields').forEach(function (customField) {
        _this.get('fieldValues').createFragment({
          fieldId: customField.get('field.id'),
          value: customField.get('value') === '' ? null : customField.get('value')
        });
      });

      return this._super.apply(this, arguments);
    },


    primaryEmail: computed('emails.@each.isPrimary', function () {
      var emails = this.get('emails');
      var primaryEmail = emails.filter(function (email) {
        return email.get('isPrimary');
      }).get('firstObject');

      return primaryEmail ? primaryEmail : emails.get('firstObject');
    }),

    primaryEmailAddress: computed('primaryEmail', function () {
      return this.get('primaryEmail.email');
    }),

    primaryPhoneIdentity: computed('phones.@each.isPrimary', function () {
      return this.get('phones').filter(function (phone) {
        return phone.get('isPrimary');
      }).get('firstObject');
    }),
    primaryPhoneNumber: computed.readOnly('primaryPhoneIdentity.number'),

    primaryTwitterIdentity: computed('twitter.@each.isPrimary', function () {
      return this.get('twitter').filter(function (twitter) {
        return twitter.get('isPrimary');
      }).get('firstObject');
    }),
    primaryTwitterHandle: computed.readOnly('primaryTwitterIdentity.screenName'),

    primaryFacebookIdentity: computed('facebook.@each.isPrimary', function () {
      return this.get('facebook').filter(function (facebook) {
        return facebook.get('isPrimary');
      }).get('firstObject');
    }),
    primaryFacebookUsername: computed.readOnly('primaryFacebookIdentity.screenName'),

    // Indicates whether all fields of a case have been loaded, also see adapter/serializer.
    _isFullyLoaded: _emberData.default.attr('boolean', { defaultValue: false }),

    // used in the creation steps
    creationTimestamp: null,

    hasPinnedNotes: computed.bool('pinnedNotesCount')
  });
});