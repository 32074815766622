define('frontend-cp/components/ko-feedback/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    tabStore: service('tab-store'),

    actions: {
      openCase: function openCase(feedbackCase, e) {
        e.preventDefault();
        e.stopPropagation();
        this.get('tabStore').transitionAndInsertTabNextToActiveTab('session.agent.cases.case', [feedbackCase]);
      }
    }
  });
});