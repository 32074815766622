define('frontend-cp/components/ko-agent-content/hierarchical-action-menu/component', ['exports', 'npm:lodash', 'ember-diff-attrs'], function (exports, _npmLodash, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  exports.default = Component.extend({
    items: null,
    currentItem: null,
    navigableItems: [],

    didReceiveAttrs: (0, _emberDiffAttrs.default)('items', 'currentItem', function (changedAttrs) {
      var currentMenuItemHasNoChildren = !this.get('currentItem.children.length');
      var navigableItemsHaveUpdated = !_npmLodash.default.isEqual(this.get('navigableItems'), this.get('items'));
      if (currentMenuItemHasNoChildren && navigableItemsHaveUpdated) {
        this.set('navigableItems', this.get('items'));
      }
    }),

    actions: {
      itemSelected: function itemSelected(_ref) {
        var _ref$object = _ref.object,
            action = _ref$object.action,
            _ref$object$args = _ref$object.args,
            args = _ref$object$args === undefined ? [] : _ref$object$args;

        if (action) {
          if (typeof action === 'function') {
            action.apply(undefined, _toConsumableArray(args));
          } else if ((typeof action === 'undefined' ? 'undefined' : _typeof(action)) === 'object' && typeof action.send === 'function') {
            action.send.apply(action, _toConsumableArray(args));
          }
        }
      },
      traversingItems: function traversingItems(currentItem) {
        this.set('currentItem', currentItem);
      }
    }
  });
});