define("frontend-cp/components/ko-search-sidebar/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "sidebar": "ko-search-sidebar__sidebar_1v3ubc",
    "no-padding": "ko-search-sidebar__no-padding_1v3ubc"
  };
});