define('frontend-cp/helpers/route-params', ['exports', 'ember-router-helpers/helpers/route-params'], function (exports, _routeParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _routeParams.default;
    }
  });
  Object.defineProperty(exports, 'routeParams', {
    enumerable: true,
    get: function () {
      return _routeParams.routeParams;
    }
  });
});