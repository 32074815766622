define("frontend-cp/components/ko-agent-content/sidebar/info-list/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "list-item": "ko-agent-content_sidebar_info-list_item__list-item_13b4rn",
    "content": "ko-agent-content_sidebar_info-list_item__content_13b4rn"
  };
});