define("frontend-cp/components/ko-checkbox/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "checkboxWrap": "ko-checkbox__checkboxWrap_11qxzi",
    "centerHorizontally": "ko-checkbox__centerHorizontally_11qxzi",
    "topAligned": "ko-checkbox__topAligned_11qxzi",
    "checkbox": "ko-checkbox__checkbox_11qxzi",
    "tick": "ko-checkbox__tick_11qxzi",
    "fade-in": "ko-checkbox__fade-in_11qxzi",
    "size-small": "ko-checkbox__size-small_11qxzi",
    "size-large": "ko-checkbox__size-large_11qxzi",
    "disabled": "ko-checkbox__disabled_11qxzi",
    "tickLarge": "ko-checkbox__tickLarge_11qxzi",
    "label": "ko-checkbox__label_11qxzi",
    "labelDisabled": "ko-checkbox__labelDisabled_11qxzi"
  };
});