define('frontend-cp/models/role', ['exports', 'ember-data', 'frontend-cp/lib/role-types'], function (exports, _emberData, _roleTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    roleType: _emberData.default.attr('string', { defaultValue: 'AGENT' }),
    agentCaseAccess: _emberData.default.attr('string', { defaultValue: 'ALL' }),
    isSystem: _emberData.default.attr('boolean'),
    permissions: _emberData.default.hasMany('permission', { async: true }),

    isAdminOrHigher: computed('roleType', function () {
      var roleType = this.get('roleType');

      return _roleTypes.default[roleType].rank >= _roleTypes.default.ADMIN.rank;
    }),

    isAgentOrHigher: computed('roleType', function () {
      var roleType = this.get('roleType');

      return _roleTypes.default[roleType].rank >= _roleTypes.default.AGENT.rank;
    }),

    isCollaboratorOrHigher: computed('roleType', function () {
      var roleType = this.get('roleType');

      return _roleTypes.default[roleType].rank >= _roleTypes.default.COLLABORATOR.rank;
    })
  });
});