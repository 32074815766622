define("frontend-cp/session/admin/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "page-title": "session_admin_index__page-title_jf9dek",
    "home-icon": "session_admin_index__home-icon_jf9dek",
    "category": "session_admin_index__category_jf9dek",
    "category-title": "session_admin_index__category-title_jf9dek",
    "category-description": "session_admin_index__category-description_jf9dek",
    "items": "session_admin_index__items_jf9dek",
    "item": "session_admin_index__item_jf9dek",
    "item-icon--hover": "session_admin_index__item-icon--hover_jf9dek",
    "item-icon": "session_admin_index__item-icon_jf9dek",
    "item-icon-container": "session_admin_index__item-icon-container_jf9dek",
    "item-title": "session_admin_index__item-title_jf9dek"
  };
});