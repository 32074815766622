define("frontend-cp/components/ko-entity-list/sidebar/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "sidebar": "ko-entity-list_sidebar__sidebar_jxbkwo",
    "heading-container": "ko-entity-list_sidebar__heading-container_jxbkwo",
    "heading": "ko-entity-list_sidebar__heading_jxbkwo",
    "definitions": "ko-entity-list_sidebar__definitions_jxbkwo"
  };
});