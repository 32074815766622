define('frontend-cp/session/admin/customizations/case-fields/new/controller', ['exports', 'ember-service/inject', 'frontend-cp/services/virtual-model', 'frontend-cp/lib/fields-schema'], function (exports, _inject, _virtualModel, _fieldsSchema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    launchDarkly: (0, _inject.default)(),

    notification: service(),
    i18n: service(),
    customFields: service(),
    virtualModel: service(),
    metrics: service(),
    schema: _fieldsSchema.default,

    title: computed('field.title', function () {
      return this.get('customFields').getTitleBreadcrumbs(this.get('field'));
    }),

    actions: {
      created: function created() {
        var launchDarkly = this.get('launchDarkly');

        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.initEdits();
        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'conversation_field_created',
            object: this.get('editedField.id'),
            type: this.get('editedField.fieldType'),
            visible_to_customers: this.get('editedField.isVisibleToCustomers'),
            is_required_on_resolution: this.get('editedField.isRequiredOnResolution'),
            is_required_for_customers: this.get('editedField.isRequiredForCustomers'),
            is_required_for_agents: this.get('editedField.isVisibleToAgents')
          });
        }
        this.transitionToRoute('session.admin.customizations.case-fields.index');
      },
      canceled: function canceled() {
        this.transitionToRoute('session.admin.customizations.case-fields.index');
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedField', this.get('virtualModel').makeSnapshot(this.get('field'), _fieldsSchema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('field'), this.get('editedField'), _fieldsSchema.default);
    }
  });
});