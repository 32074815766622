define("frontend-cp/components/ko-notification-center-new/group/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "list-group-title": "ko-notification-center-new_group__list-group-title_1jq7gk",
    "list-group-items": "ko-notification-center-new_group__list-group-items_1jq7gk"
  };
});