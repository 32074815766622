define('frontend-cp/adapters/application', ['exports', 'ember-data', 'npm:lodash'], function (exports, _emberData, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _emberData.default.RESTAdapter.extend({
    namespace: '/api/v1',
    session: service(),
    errorHandler: service(),
    requestHistory: service(),
    notificationHandler: service('error-handler/notification-strategy'),
    serverClock: service(),
    adapterRequestLogger: service(),

    /**
      Automatically add `include=*` query param to all requests.
       Override in subclasses of the adapter to disable this mode for certain endpoints.
       To override this setting at find-time, use `{ include: [] }` to include
      nothing and `{ include: 'foo,bar' }` to specify exactly.
       @property autoIncludeAll
      @type boolean
      @default true
    */
    autoIncludeAll: true,

    /*
     * Each time we findAll on a model, we check to see if we've
     * loaded it - we don't reload the model once this has happened.
     * (all new models will be pushed to the store via KRE)
     */
    foundAllHash: null,

    initFoundAllHash: on('init', function () {
      this.set('foundAllHash', {});
    }),

    // CPs
    headers: computed('session.sessionId', 'session.csrfToken', function () {
      var _get$getProperties = this.get('session').getProperties('sessionId', 'csrfToken'),
          sessionId = _get$getProperties.sessionId,
          csrfToken = _get$getProperties.csrfToken;

      var headers = {
        Accept: 'application/json',
        'X-Options': 'flat',
        'X-Requested-With': 'XMLHttpRequest',
        'X-API-Token': '440be73f-7a9a-492f-a88b-745e5affb049'
      };

      if (csrfToken) {
        headers['X-CSRF-Token'] = csrfToken;
      }

      if (sessionId) {
        headers['X-Session-ID'] = sessionId;
      }

      return headers;
    }).volatile(),

    handleResponse: function handleResponse(status, headers, payload) {
      if (headers['date-iso']) {
        this.get('serverClock').set('lastKnownServerTime', headers['date-iso']);
      }
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      } else {
        var errors = this.normalizeErrorResponse(status, headers, payload);
        if (this.isInvalid(status, headers, payload)) {
          return new _emberData.default.InvalidError(errors);
        } else {
          return new _emberData.default.AdapterError(errors);
        }
      }
    },

    normalizeErrorResponse: function normalizeErrorResponse(status, headers) {
      var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var errors = payload.errors || [];

      // Sneaks the auth token into auth-related errors
      if (payload.auth_token) {
        errors.filter(function (e) {
          return ['CREDENTIAL_EXPIRED', 'OTP_EXPECTED'].includes(e.code);
        }).forEach(function (error) {
          return error.authToken = payload.auth_token;
        });
      }

      // Since we can only return array, we have to merge notifications
      // into errors and assign NOTIFICATION code to tell them apart
      var notifications = payload.notifications || [];
      notifications.forEach(function (n) {
        n.code = 'NOTIFICATION';
      });

      return errors.concat(notifications);
    },
    handleErrors: function handleErrors(promise) {
      var _this = this;

      return promise.then(function (data) {
        _this.get('notificationHandler').processAll(data && data.notifications);
        return data;
      }, function (e) {
        return _this.get('errorHandler').process(e);
      });
    },
    isInvalid: function isInvalid(status, header, payload) {
      var isValidationError = function isValidationError(responseError) {
        return ['FIELD_REQUIRED', 'FIELD_DUPLICATE', 'FIELD_EMPTY', 'FIELD_INVALID'].includes(responseError.code);
      };

      var hasValidationErrors = function hasValidationErrors(responseErrors) {
        return _npmLodash.default.some(responseErrors, isValidationError);
      };

      return status === 422 || hasValidationErrors(payload.errors);
    },
    ajax: function ajax(path, type) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var request = this._super.apply(this, arguments);

      this.get('requestHistory').push({
        path: path, type: type, data: options.data, request: request, headers: this.get('headers'), cookies: document.cookie
      });

      return this.handleErrors(request);
    },
    ajaxOptions: function ajaxOptions(url, type) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var includeSpecified = options.data && 'include' in options.data;
      var shouldAutoIncludeAll = !includeSpecified && this.autoIncludeAll;

      if (shouldAutoIncludeAll) {
        if (type === 'GET') {
          options.data = options.data || {};
          if (!options.data.include) {
            options.data.include = '*';
          }
        } else {
          if (url.indexOf('include=') === -1) {
            if (url.indexOf('?') > -1) {
              url = url + '&include=*';
            } else {
              url = url + '?include=*';
            }
          }
        }
      }

      return this._super(url, type, options);
    },
    shouldReloadAll: function shouldReloadAll(store, snapshotArray) {
      var model = snapshotArray.type;
      var modelName = model.modelName;
      var hasBeenRequested = this.get('adapterRequestLogger').hasRequestedAllRecords(modelName);

      this.get('adapterRequestLogger').setRequestedAllRecords(modelName);

      return !hasBeenRequested;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    }
  });
});