define('frontend-cp/components/kre-connection-ribbon/component', ['exports', 'frontend-cp/services/socket'], function (exports, _socket) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    socket: service(),

    failedToConnect: computed('socket.state', 'socket.hasAttemptedConnection', function () {
      return this.get('socket.state') === _socket.STATE_CLOSED && this.get('socket.hasAttemptedConnection');
    }),

    isOffline: computed('socket.state', 'socket.hasConnectedOnce', function () {
      return this.get('socket.state') === _socket.STATE_CLOSED && this.get('socket.hasConnectedOnce');
    }),

    actions: {
      reloadToRetry: function reloadToRetry(e) {
        e.preventDefault();
        window.location.reload(true);
      }
    }
  });
});