define("frontend-cp/components/ko-timeline-2/list/activity/large/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "large": "ko-timeline-2_list_activity_large__large_16asl1",
    "large-activity-icon": "ko-timeline-2_list_activity_large__large-activity-icon_16asl1",
    "large-activity-text": "ko-timeline-2_list_activity_large__large-activity-text_16asl1",
    "large-activity-detail-text": "ko-timeline-2_list_activity_large__large-activity-detail-text_16asl1",
    "inline-property-avatar": "ko-timeline-2_list_activity_large__inline-property-avatar_16asl1",
    "breach": "ko-timeline-2_list_activity_large__breach_16asl1 ko-timeline-2_list_activity_large__large_16asl1",
    "trash": "ko-timeline-2_list_activity_large__trash_16asl1 ko-timeline-2_list_activity_large__large_16asl1",
    "restored": "ko-timeline-2_list_activity_large__restored_16asl1 ko-timeline-2_list_activity_large__large_16asl1",
    "completed": "ko-timeline-2_list_activity_large__completed_16asl1 ko-timeline-2_list_activity_large__large_16asl1",
    "rating": "ko-timeline-2_list_activity_large__rating_16asl1",
    "rating-summary": "ko-timeline-2_list_activity_large__rating-summary_16asl1",
    "comment-container": "ko-timeline-2_list_activity_large__comment-container_16asl1",
    "rating-comment-text": "ko-timeline-2_list_activity_large__rating-comment-text_16asl1",
    "rating-comment-text-open-quote": "ko-timeline-2_list_activity_large__rating-comment-text-open-quote_16asl1",
    "activity-time": "ko-timeline-2_list_activity_large__activity-time_16asl1",
    "confetti": "ko-timeline-2_list_activity_large__confetti_16asl1",
    "fade-in": "ko-timeline-2_list_activity_large__fade-in_16asl1"
  };
});