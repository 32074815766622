define("frontend-cp/session/agent/welcome/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "title": "session_agent_welcome__title_25zlqm",
    "subtitle": "session_agent_welcome__subtitle_25zlqm",
    "container": "session_agent_welcome__container_25zlqm",
    "content": "session_agent_welcome__content_25zlqm",
    "row": "session_agent_welcome__row_25zlqm",
    "text": "session_agent_welcome__text_25zlqm",
    "header": "session_agent_welcome__header_25zlqm",
    "description": "session_agent_welcome__description_25zlqm",
    "image-container": "session_agent_welcome__image-container_25zlqm",
    "bar": "session_agent_welcome__bar_25zlqm",
    "image": "session_agent_welcome__image_25zlqm"
  };
});