define("frontend-cp/components/ko-agent-content/sidebar/info-card/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-agent-content_sidebar_info-card__container_1k5uxz",
    "image": "ko-agent-content_sidebar_info-card__image_1k5uxz",
    "body": "ko-agent-content_sidebar_info-card__body_1k5uxz",
    "header": "ko-agent-content_sidebar_info-card__header_1k5uxz",
    "subheader": "ko-agent-content_sidebar_info-card__subheader_1k5uxz"
  };
});