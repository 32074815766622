define("frontend-cp/components/ko-reorderable-table/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-reorderable-table__container_1g7mg6",
    "list": "ko-reorderable-table__list_1g7mg6",
    "item": "ko-reorderable-table__item_1g7mg6",
    "handle": "ko-reorderable-table__handle_1g7mg6",
    "handleOutside": "ko-reorderable-table__handleOutside_1g7mg6"
  };
});