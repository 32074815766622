define('frontend-cp/services/error-handler/generic-strategy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    notification: service('notification'),
    i18n: service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.records = [];
    },
    accept: function accept(record) {
      this.records.push(record);
    },
    process: function process() {
      var recordsCount = this.records.length;

      if (recordsCount) {
        this.get('notification').add({
          type: 'error',
          title: this.get('i18n').t('generic.generic_error'),
          autodismiss: true,
          dismissable: true
        });

        this.records = [];
      }

      return recordsCount;
    }
  });
});