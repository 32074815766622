define('frontend-cp/session/admin/manage/case-forms/new/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend((0, _route.default)('caseForm'), {
    store: service(),

    model: function model() {
      var store = this.get('store');

      return RSVP.hash({
        caseForm: store.createRecord('case-form'),
        caseFields: store.query('case-field', { include: [] }),
        brands: store.findAll('brand')
      }).then(function (model) {
        var caseFields = model.caseFields;
        var caseForm = model.caseForm;

        caseFields.forEach(function (field) {
          if (field.get('isSystem')) {
            caseForm.get('fields').pushObject(field);
          }
        });

        return model;
      });
    },
    afterModel: function afterModel(model) {
      var store = this.get('store');
      var locales = store.peekAll('locale').filterBy('isPublic', true);
      var customerTitles = model.caseForm.get('customerTitles');
      var descriptions = model.caseForm.get('descriptions');

      locales.forEach(function (element) {
        var locale = element.get('locale');

        if (!customerTitles.findBy('locale', locale)) {
          customerTitles.pushObject(store.createRecord('locale-field', { locale: locale }));
        }

        if (!descriptions.findBy('locale', locale)) {
          descriptions.pushObject(store.createRecord('locale-field', { locale: locale }));
        }
      });
    },
    setupController: function setupController(controller, _ref) {
      var brands = _ref.brands,
          caseForm = _ref.caseForm,
          caseFields = _ref.caseFields;

      controller.setProperties({ brands: brands, caseForm: caseForm, caseFields: caseFields });
      controller.initEdits();
    }
  });
});