define('frontend-cp/session/admin/channels/facebook/edit/controller', ['exports', 'frontend-cp/session/admin/channels/facebook/schema', 'frontend-cp/services/virtual-model'], function (exports, _schema, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    notification: service(),
    i18n: service(),
    virtualModel: service(),
    editedPage: null,
    page: null,

    // Actions
    actions: {
      cancel: function cancel() {
        this.transitionToRoute('session.admin.channels.facebook.index');
      },
      save: function save() {
        var _this = this;

        var virtualModel = this.get('virtualModel');
        var page = this.get('page');
        var editedPage = this.get('editedPage');

        return virtualModel.save(page, editedPage, _schema.default).then(function (snapshot) {
          return _this.set('editedPage', snapshot);
        });
      },
      onSuccess: function onSuccess() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.changes_saved'),
          autodismiss: true
        });

        this.transitionToRoute('session.admin.channels.facebook.index');
      }
    },

    // Methods
    initEdits: function initEdits() {
      this.set('editedPage', this.get('virtualModel').makeSnapshot(this.get('page'), _schema.default));
    },
    isEdited: function isEdited() {
      return (0, _virtualModel.isEdited)(this.get('page'), this.get('editedPage'), _schema.default);
    }
  });
});