define('frontend-cp/adapters/report', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var REQUIRED_SIDELOADED_MODELS = 'team,user,predicate-collection,proposition';

  exports.default = _application.default.extend({
    autoIncludeAll: false,

    urlForQuery: function urlForQuery() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS;
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS;
    },
    urlForCreateRecord: function urlForCreateRecord() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS;
    },
    urlForFindRecord: function urlForFindRecord() {
      var url = this._super.apply(this, arguments);
      return url + '?include=' + REQUIRED_SIDELOADED_MODELS;
    }
  });
});