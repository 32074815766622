define("frontend-cp/components/ko-simple-list/cell/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "cell": "ko-simple-list_cell__cell_1b5hzk",
    "disabled": "ko-simple-list_cell__disabled_1b5hzk"
  };
});