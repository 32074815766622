define('frontend-cp/adapters/brand', ['exports', 'frontend-cp/adapters/application', 'frontend-cp/adapters/unpanginate-mixin'], function (exports, _application, _unpanginateMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_unpanginateMixin.default, {
    getSslCertificates: function getSslCertificates(brand) {
      var brandId = brand.get('id');
      var url = this.namespace + '/brands/' + brandId + '/certificate';

      return this.ajax(url, 'GET');
    },
    buildURL: function buildURL() {
      var result = this._super.apply(this, arguments);

      result += '?fields=-ssl_certificate';

      return result;
    }
  });
});