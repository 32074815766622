define("frontend-cp/components/ko-case/macro-selector/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-case_macro-selector__container_ltxhiw",
    "trigger": "ko-case_macro-selector__trigger_ltxhiw",
    "dropdown": "ko-case_macro-selector__dropdown_ltxhiw _dropdown_jvqwfg"
  };
});