define('frontend-cp/components/ko-input/component', ['exports', 'frontend-cp/mixins/with-unique-id'], function (exports, _withUniqueId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var next = Ember.run.next;
  exports.default = Component.extend(_withUniqueId.default, {
    // Attributes
    name: null,
    value: '',
    onInput: null,
    onChange: null,
    onBlur: null,
    disabled: false,
    placeholder: null,
    autofocus: false,
    qaClass: null,
    type: 'text',
    size: 'medium', // possible values: small, medium, full
    multiline: false,
    lines: 4,
    monospace: false,
    customElementId: false,

    // HTML
    tagName: '',

    // Lifecycle hooks
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      next(function () {
        return _this.performAutofocus();
      });
    },


    id: computed(function () {
      if (this.get('customElementId')) {
        return this.get('elementId');
      } else {
        return this.get('uniqueId');
      }
    }),

    performAutofocus: function performAutofocus() {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }

      if (this.get('autofocus')) {
        $('#' + this.get('uniqueId')).focus();
      }
    }
  });
});