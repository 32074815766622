define("frontend-cp/session/admin/customizations/organization-fields/index/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "icon": "session_admin_customizations_organization-fields_index__icon_skglo5",
    "title": "session_admin_customizations_organization-fields_index__title_skglo5"
  };
});