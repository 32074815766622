define('frontend-cp/models/facebook-account', ['exports', 'ember-data', 'frontend-cp/models/account'], function (exports, _emberData, _account) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _account.default.extend({
    accountId: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    status: _emberData.default.attr('string')
  });
});