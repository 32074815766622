define('frontend-cp/components/ko-insights/custom-reports/index-new/report/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var equal = Ember.computed.equal;
  exports.default = Component.extend({
    tagName: '',

    report: null,

    isGenerating: computed('report.status', function () {
      return ['TRIGGERED', 'WAITING', 'STARTED'].contains(this.get('report.status'));
    }),

    isCompleted: equal('report.status', 'COMPLETED'),

    actions: {
      changed: function changed(data) {
        /**
         * KAYAKO-14666 In the response from KRE the status code
         * for COMPLETED is 4 (as in the database)
         *
         * @author Diego Nobre <diego.nobre@crossover.com>
         */
        if (data.changed_properties.status === 4) {
          this.set('report.status', 'COMPLETED');
        }
        this.get('report').reload();
      }
    }

  });
});