define("frontend-cp/components/ko-insights/section/agents-teams/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "header": "ko-insights_section_agents-teams__header_guh7r0",
    "entity": "ko-insights_section_agents-teams__entity_guh7r0",
    "filters": "ko-insights_section_agents-teams__filters_guh7r0",
    "filtersControls": "ko-insights_section_agents-teams__filtersControls_guh7r0",
    "metricsContainer": "ko-insights_section_agents-teams__metricsContainer_guh7r0",
    "metrics": "ko-insights_section_agents-teams__metrics_guh7r0",
    "chart": "ko-insights_section_agents-teams__chart_guh7r0",
    "questionBlock": "ko-insights_section_agents-teams__questionBlock_guh7r0",
    "question": "ko-insights_section_agents-teams__question_guh7r0",
    "slaQuestion": "ko-insights_section_agents-teams__slaQuestion_guh7r0",
    "slaFilter": "ko-insights_section_agents-teams__slaFilter_guh7r0",
    "questionTitle": "ko-insights_section_agents-teams__questionTitle_guh7r0",
    "answer": "ko-insights_section_agents-teams__answer_guh7r0",
    "insights": "ko-insights_section_agents-teams__insights_guh7r0"
  };
});