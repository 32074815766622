define('frontend-cp/components/ko-admin/automation-actions-builder/boolean-false/component', ['exports', 'frontend-cp/components/ko-admin/automation-actions-builder/base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    // Lifecycle hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('automationAction.value', 'false');
    }
  });
});