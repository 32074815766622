define('frontend-cp/components/ko-admin/identity-verification/index/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var service = Ember.inject.service;
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Component.extend({
    // Attributes

    tokens: [],
    javascriptLabel: 'Javascript',
    phpLabel: 'PHP',
    stepOneJavascript: htmlSafe('<div> &lt;script&gt;</div>\n    <div>   kayako.ready(function() {</div>\n    <div>      kayako.identify(name, email, signature, timestamp); </div>\n    <div>   });</div>\n    <div> &lt;/script&gt;</div>'),

    stepOnePhp: htmlSafe('<div>&lt;&quest;php</div>\n    <div>   $timestamp = time();</div>\n    <div>   $signature = hash_hmac(\'sha256\', ($name . $email . </div>\n    <div>   $token . $timestamp), $token);</div>\n    <div>&quest;&gt;</div>\n    '),

    // Services

    store: service(),
    i18n: service(),
    notification: service(),

    // CPs

    stepTwoJavascript: computed(function () {
      return htmlSafe('<div> &lt;script&gt;</div>\n    <div>   kayako.forget(function() { </div>\n    <div>      // ' + this.get('i18n').t('admin.messenger.identity_verification.web.step_2.comment') + '</div>\n    <div>   });</div>\n    <div> &lt;/script&gt;</div>');
    }),

    articleLinkText: computed(function () {
      return htmlSafe(this.get('i18n').t('admin.messenger.identity_verification.web.external_article.link_text'));
    }),

    tabs: computed(function () {
      return [{
        id: 'web',
        label: this.get('i18n').t('admin.messenger.identity_verification.tabs.web'),
        routeName: 'session.admin.messenger.identity-verification.index',
        dynamicSegments: [],
        queryParams: null
      }];
    }),

    webTokenModel: computed.filterBy('tokens', 'deviceType', 'WEB'),
    webToken: computed('webTokenModel', function () {
      if (this.get('webTokenModel').toArray) {
        return get(this.get('webTokenModel').toArray()[0], 'value');
      }
      return '';
    }),

    // Tasks

    updateWebToken: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(deviceType) {
      var that, updateTokenResponse, tokenRecord;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              that = this;
              _context.prev = 1;
              _context.next = 4;
              return this.get('store').adapterFor('device-token').updateToken(deviceType);

            case 4:
              updateTokenResponse = _context.sent;
              _context.next = 7;
              return this.get('store').findRecord('device-token', deviceType);

            case 7:
              tokenRecord = _context.sent;

              if (updateTokenResponse.data.value) {
                _context.next = 10;
                break;
              }

              throw new TypeError();

            case 10:
              tokenRecord.set('value', updateTokenResponse.data.value);

              if (deviceType === 'WEB') {
                this.set('webToken', updateTokenResponse.data.value);
              }
              this.get('notification').add({
                type: 'success',
                title: that.get('i18n').t('admin.messenger.identity_verification.notifications.token_update'),
                autodismiss: true
              });
              _context.next = 18;
              break;

            case 15:
              _context.prev = 15;
              _context.t0 = _context['catch'](1);

              this.get('notification').add({
                type: 'error',
                title: that.get('i18n').t('admin.messenger.identity_verification.notifications.error'),
                autodismiss: true
              });

            case 18:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[1, 15]]);
    })).drop(),

    // Actions

    actions: {
      onCopiedToClipboard: function onCopiedToClipboard() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.copied_to_clipboard'),
          autodismiss: true
        });
      },
      onRefresh: function onRefresh(deviceType) {
        this.get('updateWebToken').perform(deviceType);
      }
    }
  });
});