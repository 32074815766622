define('frontend-cp/components/ko-admin/holidays/row/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    holiday: null,
    tagName: '',

    formattedDate: computed('holiday.date', function () {
      return (0, _moment.default)(this.get('holiday.date'), 'DD/MM/YYYY');
    }),

    actions: {
      editHoliday: function editHoliday() {
        this.set('isEditing', true);
      },
      stopEditing: function stopEditing() {
        this.set('errors', []);
        this.set('isEditing', false);
      }
    }
  });
});