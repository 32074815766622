define("frontend-cp/components/ko-admin/forms/description/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "description": "ko-admin_forms_description__description_krmqn9",
    "small": "ko-admin_forms_description__small_krmqn9"
  };
});