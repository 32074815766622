define('frontend-cp/components/ko-universal-search/result-see-more/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    onClick: function onClick() {},

    actions: {
      clickHandler: function clickHandler(event) {
        event.preventDefault();

        var hasModifier = event.metaKey || event.ctrlKey || event.shiftKey;
        this.attrs.onClick(hasModifier);
      }
    }
  });
});