define('frontend-cp/components/ko-time-tracking/entry/component', ['exports', 'ember-concurrency', 'frontend-cp/components/ko-time-tracking/entry/styles'], function (exports, _emberConcurrency, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var jQuery = Ember.$;
  exports.default = Component.extend({
    store: service(),
    notification: service(),
    session: service(),
    i18n: service(),

    entry: null,
    isEditing: true,

    didUpdateAttrs: function didUpdateAttrs() {
      if (!this.get('isEditing')) {
        this.removeActive();
      }
    },


    hasEditPermission: computed(function () {
      var roleType = this.get('session.user.role.roleType');
      return roleType === 'OWNER' || roleType === 'ADMIN';
    }),

    deleteEntry: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(entry, e) {
      var id, log;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              e.stopPropagation();

              id = entry.get('id');
              _context.next = 4;
              return this.get('store').findRecord('timetracking-log', id);

            case 4:
              log = _context.sent;

              log.deleteRecord();
              _context.prev = 6;
              _context.next = 9;
              return log.save();

            case 9:
              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('cases.timetracking.messages.delete'),
                autodismiss: true
              });
              this.get('afterDeleteEntry')(entry);
              _context.next = 16;
              break;

            case 13:
              _context.prev = 13;
              _context.t0 = _context['catch'](6);

              log.rollbackAttributes();

            case 16:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[6, 13]]);
    })),

    removeActive: function removeActive() {
      jQuery('.' + _styles.default['list-item']).removeClass(_styles.default['is-editing']);
    },


    actions: {
      edit: function edit(entry, e) {
        this.get('onEditEntry')(entry);

        this.removeActive();
        jQuery(e.target).closest('.' + _styles.default['list-item']).addClass(_styles.default['is-editing']);
      }
    }
  });
});