define('frontend-cp/components/ko-admin/roles/list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    notification: service(),
    i18n: service(),
    roles: service(),
    confirmation: service(),
    role: null,

    // CPs
    canDelete: not('role.isSystem'),

    canEdit: computed('role.roleType', 'role.isSystem', 'roles.editableSystemTypes', function () {
      return !this.get('role.isSystem') || this.get('roles.editableSystemTypes').findBy('id', this.get('role.roleType'));
    }),

    roleType: computed('role.roleType', function () {
      return this.get('roles.allTypes').findBy('id', this.get('role.roleType'));
    }),

    // Actions
    actions: {
      edit: function edit(role, event) {
        event.stopPropagation();

        if (this.attrs.onEdit) {
          this.attrs.onEdit(role);
        }
      },
      delete: function _delete(role, event) {
        var _this = this;

        event.stopPropagation();
        this.get('confirmation').confirm({ intlConfirmationBody: 'generic.confirm.delete' }).then(function () {
          return role.destroyRecord();
        }).then(function () {
          return _this.get('notification').success(_this.get('i18n').t('admin.roles.index.deletion_successful_notification'));
        });
      }
    }
  });
});