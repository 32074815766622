define('frontend-cp/services/device-fingerprint', ['exports', 'npm:uuid'], function (exports, _npmUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;


  var NAMESPACE = 'device';
  var KEY = 'fingerprint';

  exports.default = Service.extend({
    localStore: service(),

    create: function create() {
      var fingerprint = _npmUuid.default.v4();
      this.get('localStore').setItem(NAMESPACE, KEY, fingerprint, { persist: true });
      return fingerprint;
    },
    fetch: function fetch() {
      return this.get('localStore').getItem(NAMESPACE, KEY, { persist: true });
    },
    exists: function exists() {
      return Boolean(this.fetch());
    },
    getOrCreate: function getOrCreate() {
      return this.fetch() || this.create();
    }
  });
});