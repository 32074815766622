define('frontend-cp/timelines/activity', ['exports', 'frontend-cp/timelines/timeline', 'ember-concurrency'], function (exports, _timeline, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assign = Ember.assign;
  var reads = Ember.computed.reads;
  exports.default = _timeline.default.extend({

    firstPost: reads('posts.firstObject'),
    lastPost: reads('posts.lastObject'),

    _defaultParams: function _defaultParams() {
      return {
        parent: this.get('parent'),
        include: '*',
        limit: this.get('limit')
      };
    },
    _paramsForRestore: function _paramsForRestore() {
      return assign(this._defaultParams(), {
        include: 'nothing',
        limit: 1
      });
    },
    _paramsForOlder: function _paramsForOlder() {
      var time = this.get('firstPost.createdAt');
      if (!time) {
        return assign(this._defaultParams(), {});
      }
      return assign(this._defaultParams(), {
        until: time.getTime() / 1000
      });
    },
    _paramsForNewer: function _paramsForNewer() {
      var time = this.get('lastPost.createdAt');
      if (!time) {
        return assign(this._defaultParams(), {});
      }
      return assign(this._defaultParams(), {
        since: time.getTime() / 1000
      });
    },
    _paramsForMostRecent: function _paramsForMostRecent() {
      return assign(this._defaultParams(), {});
    },
    _paramsForAfterTimestamp: function _paramsForAfterTimestamp(timestamp, limit) {
      return assign(this._defaultParams(), {
        since: timestamp,
        filters: this.get('filter') || 'all',
        limit: limit
      });
    },
    _paramsForBeforeTimestamp: function _paramsForBeforeTimestamp(timestamp, limit) {
      return assign(this._defaultParams(), {
        until: timestamp,
        filters: this.get('filter') || 'all',
        limit: limit
      });
    },


    _fetch: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(params) {
      var store;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              store = this.get('store');
              _context.next = 3;
              return store.query('activity', params);

            case 3:
              return _context.abrupt('return', _context.sent);

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    sortPosts: function sortPosts(posts) {
      var dir = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'ASC';

      return posts.toArray().sort(function (a, b) {
        if (dir === 'ASC') {
          return a.get('createdAt').getTime() - b.get('createdAt').getTime();
        } else {
          return b.get('createdAt').getTime() - a.get('createdAt').getTime();
        }
      });
    }
  });
});