define('frontend-cp/components/ko-entity-list/sidebar/proposition-logic-operator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    //Attrs
    operators: null,
    selectedOperator: null,

    //State

    //CP's
    pillColor: computed('selectedOperator', 'operators', function () {
      var selectedOperator = this.get('selectedOperator');
      var operators = this.get('operators');

      return operators.indexOf(selectedOperator) % 2;
    }),

    //Functions

    //Actions
    actions: {
      changeOperator: function changeOperator() {
        var selectedOperator = this.get('selectedOperator');
        var operators = this.get('operators');
        var newOperator = operators[operators.indexOf(selectedOperator) + 1] || operators[0];

        this.sendAction('onOperatorChange', newOperator);
      }
    }
  });
});