define('frontend-cp/components/ko-admin/automation-actions-builder/autocomplete-team-or-agent/component', ['exports', 'frontend-cp/components/ko-admin/automation-actions-builder/base/component', 'ember-basic-dropdown/utils/computed-fallback-if-undefined'], function (exports, _component, _computedFallbackIfUndefined) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var set = Ember.set;
  exports.default = _component.default.extend({
    agents: (0, _computedFallbackIfUndefined.default)([]),
    teams: (0, _computedFallbackIfUndefined.default)([]),
    team: null,
    agent: null,
    assign: false,
    automationAction: null,
    onTeamChange: function onTeamChange() {},
    onAgentChange: function onAgentChange() {},

    init: function init() {
      this._super.apply(this, arguments);
      var action = this.get('automationAction');
      var attributes = this.get('definition.attributes').reduce(function (obj, attr) {
        if (!obj.hasOwnProperty(attr)) {
          obj[attr] = null;
        }
        if (attr === 'assign') {
          obj[attr] = obj[attr] === 'true';
        }
        return obj;
      }, get(action, 'attributes') || {});
      set(action, 'attributes', attributes);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var action = this.get('automationAction');
      if (action) {
        var attributes = get(action, 'attributes');
        var teamId = get(attributes, 'assign_team_id');
        var agentId = get(attributes, 'assign_agent_id');
        if (teamId) {
          var currentTeam = this.get('teams').toArray().findBy('id', teamId);
          if (currentTeam) {
            this.set('team', currentTeam);
          }
        }
        if (agentId) {
          var currentAgent = this.get('agents').toArray().findBy('id', agentId);
          if (currentAgent) {
            this.set('agent', currentAgent);
          }
        }
      }
    },


    actions: {
      changeAction: function changeAction(team, agent) {
        this.set('team', team);
        this.set('agent', agent);
        if (this.get('automationAction')) {
          this.set('automationAction.attributes.assign_team_id', this.get('team.id'));
          this.set('automationAction.attributes.assign_agent_id', null);
          var teamId = get(team, 'id');
          this.set('automationAction.attributes.assign_team_id', teamId);
          this.get('onTeamChange')(this.get('teams').toArray().findBy('id', teamId));
          if (agent) {
            this.set('automationAction.attributes.assign_agent_id', this.get('agent.id'));
            var agentId = get(agent, 'id');
            this.set('automationAction.attributes.assign_agent_id', agentId);
            this.get('onAgentChange')(this.get('agents').toArray().findBy('id', agentId));
            return;
          }
          this.set('automationAction.attributes.assign_agent_id', null);
          this.get('onAgentChange')(null);
        }
      }
    }
  });
});