define('frontend-cp/session/admin/channels/email/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    // Services
    i18n: service(),
    notification: service(),
    confirmation: service(),

    // CPs
    enabledChannels: computed('model.@each.isDeleted', 'model.@each.isEnabled', function () {
      return this.get('model').filter(function (mail) {
        return mail.get('isEnabled') && !mail.get('isDeleted');
      });
    }),
    disabledChannels: computed('model.@each.isDeleted', 'model.@each.isEnabled', 'model.@each.isVerified', function () {
      return this.get('model').filter(function (mail) {
        return !mail.get('isEnabled') && !mail.get('isDeleted') && mail.get('isVerified');
      });
    }),
    unverifiedChannels: computed('model.@each.isDeleted', 'model.@each.isVerified', function () {
      return this.get('model').filter(function (mail) {
        return !mail.get('isVerified') && !mail.get('isDeleted');
      });
    }),
    tabs: computed(function () {
      return [{
        id: 'organization',
        label: this.get('i18n').t('admin.email.tabs.mailboxes'),
        routeName: 'session.admin.channels.email',
        dynamicSegments: []
      }, {
        id: 'user',
        label: this.get('i18n').t('admin.email.tabs.settings'),
        routeName: 'session.admin.channels.email.settings',
        dynamicSegments: []
      }];
    }),

    actions: {
      editMailbox: function editMailbox(mailbox) {
        this.transitionToRoute('session.admin.channels.email.edit', mailbox.id);
      },
      toggleEnabledProperty: function toggleEnabledProperty(channel, e) {
        var _this = this;

        e.stopPropagation();
        channel.toggleProperty('isEnabled');
        channel.save().then(function () {
          var notificationMessage = _this.get('i18n').t(channel.get('isEnabled') ? 'admin.email.enabled.message' : 'admin.email.disabled.message');
          _this.get('notification').success(notificationMessage);
        });
      },
      makeDefault: function makeDefault(mailbox, e) {
        var _this2 = this;

        e.stopPropagation();
        var adapter = getOwner(this).lookup('adapter:application');
        var url = adapter.namespace + '/mailboxes/default';

        //TODO: this model is left dirty - it is not an issue,
        //but ideally we would mark this as clean.
        this.get('model').forEach(function (mailbox) {
          mailbox.set('isDefault', false);
        });
        mailbox.set('isDefault', true);

        var options = {
          data: { mailbox_id: mailbox.get('id') }
        };

        adapter.ajax(url, 'PUT', options).then(function () {
          var notificationMessage = _this2.get('i18n').t('admin.email.default.message');
          _this2.get('notification').success(notificationMessage);
        });
      },
      delete: function _delete(mailbox, e) {
        var _this3 = this;

        e.preventDefault();
        e.stopPropagation();
        return this.get('confirmation').confirm({
          intlConfirmationBody: 'generic.confirm.delete'
        }).then(function () {
          return mailbox.destroyRecord();
        }).then(function () {
          var msg = _this3.get('i18n').t('admin.email.deleted.message');
          _this3.get('notification').success(msg);
        });
      }
    }
  });
});