define('frontend-cp/session/admin/people/businesshours/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    store: service(),
    i18n: service(),
    plan: service(),
    confirmation: service(),

    // CPs
    reachedLimit: computed('model', function () {
      return this.get('plan').limitFor('business_hours') <= this.get('model.meta.total');
    }),

    // Actions
    actions: {
      transitionToAddNewBusinessHour: function transitionToAddNewBusinessHour() {
        this.transitionToRoute('session.admin.people.businesshours.new');
      },
      editBusinessHour: function editBusinessHour(businessHour) {
        this.transitionToRoute('session.admin.people.businesshours.edit', businessHour);
      },
      makeDefault: function makeDefault(businessHour, event) {
        event.stopPropagation();
        var prevDefault = this.get('store').peekAll('business-hour').findBy('isDefault', true);
        prevDefault.set('isDefault', false);

        businessHour.set('isDefault', true);
        businessHour.save().then(function () {
          return prevDefault.reload();
        }, function () {
          return businessHour.rollbackAttributes();
        });
      },
      deleteBusinessHour: function deleteBusinessHour(businessHour, e) {
        e.preventDefault();
        e.stopPropagation();
        return this.get('confirmation').confirm({ intlConfirmationBody: 'generic.confirm.delete' }).then(function () {
          return businessHour.destroyRecord();
        });
      }
    }
  });
});