define('frontend-cp/serializers/identity-email', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      if ('isValidated' in snapshot.changedAttributes()) {
        Reflect.deleteProperty(json, 'email');
        Reflect.deleteProperty(json, 'user_id');
      }
      return json;
    }
  });
});