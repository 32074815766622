define('frontend-cp/components/ko-text-editor/draggable-dropzone/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var bool = Ember.computed.bool;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    onDrop: null,
    isUploadEnabled: bool('onDrop'),
    onUploadImages: null,
    dropzoneError: null,

    // State
    active: false,
    dragCounter: 0,

    handleDrop: function handleDrop(event) {
      event.preventDefault();
      var files = event.dataTransfer.files;
      if (this.get('isUploadEnabled') && files.length) {
        var fileType = files[0].type;

        if (fileType.startsWith('image/') && this.get('isRichFormattingAvailable')) {
          this.get('onUploadImages')(files);
        } else {
          this.get('onDrop')(Array.slice((event.dataTransfer || event.originalEvent.dataTransfer).files));
        }
      }
      this.set('dragCounter', 0);
      this.set('active', false);
    },


    actions: {
      dragEnter: function dragEnter(event) {
        event.preventDefault();
        this.incrementProperty('dragCounter');
        this.set('active', true);
      },
      dragOver: function dragOver(event) {
        event.preventDefault();
      },
      dragLeave: function dragLeave(event) {
        event.preventDefault();
        this.decrementProperty('dragCounter');
        if (this.get('dragCounter') === 0) {
          this.set('active', false);
        }
      },


      // This event is prevented by Froala, thats why we have to rethrow it
      // and handle Froala events, instead of native
      dropOnEditor: function dropOnEditor(froalaEvent, editor, event) {
        this.handleDrop(event);
      },
      drop: function drop(event) {
        this.handleDrop(event);
      },
      swallowEditorEvent: function swallowEditorEvent(froalaEvent, editor, event) {
        event.preventDefault();
      }
    }
  });
});