define("frontend-cp/components/ko-entity-list/sidebar/collection-logic-operator-trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-entity-list_sidebar_collection-logic-operator-trigger__container_1d202k",
    "container-open": "ko-entity-list_sidebar_collection-logic-operator-trigger__container-open_1d202k",
    "chevron": "ko-entity-list_sidebar_collection-logic-operator-trigger__chevron_1d202k",
    "chevron-up": "ko-entity-list_sidebar_collection-logic-operator-trigger__chevron-up_1d202k"
  };
});