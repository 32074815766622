define("frontend-cp/components/ko-notification-center/preferences-new/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "description": "ko-notification-center_preferences-new__description_1w49jx",
    "heading": "ko-notification-center_preferences-new__heading_1w49jx",
    "devices": "ko-notification-center_preferences-new__devices_1w49jx",
    "device": "ko-notification-center_preferences-new__device_1w49jx",
    "vertical-separator": "ko-notification-center_preferences-new__vertical-separator_1w49jx",
    "device-text": "ko-notification-center_preferences-new__device-text_1w49jx",
    "device-label": "ko-notification-center_preferences-new__device-label_1w49jx",
    "loader": "ko-notification-center_preferences-new__loader_1w49jx",
    "list": "ko-notification-center_preferences-new__list_1w49jx",
    "table": "ko-notification-center_preferences-new__table_1w49jx",
    "list-row": "ko-notification-center_preferences-new__list-row_1w49jx",
    "list-row-old": "ko-notification-center_preferences-new__list-row-old_1w49jx",
    "list-head": "ko-notification-center_preferences-new__list-head_1w49jx",
    "list-data": "ko-notification-center_preferences-new__list-data_1w49jx",
    "list-header": "ko-notification-center_preferences-new__list-header_1w49jx",
    "buttons": "ko-notification-center_preferences-new__buttons_1w49jx",
    "sound-preferences-container": "ko-notification-center_preferences-new__sound-preferences-container_1w49jx",
    "sound-preferences": "ko-notification-center_preferences-new__sound-preferences_1w49jx",
    "sound-preference": "ko-notification-center_preferences-new__sound-preference_1w49jx",
    "sound-icon": "ko-notification-center_preferences-new__sound-icon_1w49jx"
  };
});