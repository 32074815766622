define('frontend-cp/session/agent/users/new/organization/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['postId', 'filter'],
    filter: 'notes',
    postId: null,

    actions: {
      updateQueryParams: function updateQueryParams(changes) {
        this.setProperties(changes);
        return true;
      }
    }
  });
});