define('frontend-cp/components/ko-member-list/component', ['exports', 'ember-keyboard-shortcuts/mixins/component', 'frontend-cp/components/ko-member-list/styles'], function (exports, _component, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var scheduleOnce = Ember.run.scheduleOnce;
  var A = Ember.A;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var readOnly = Ember.computed.readOnly;


  var CUSTOMER_ROLE_ID = 4;

  exports.default = Component.extend(_component.default, {
    store: service(),
    session: service(),
    i18n: service(),
    notification: service(),
    router: service('-routing'),

    keyboardShortcuts: {
      esc: {
        action: 'hideDropdown',
        global: true,
        preventDefault: true
      }
    },

    showMemberDropdown: false,
    showMemberExpanded: false,
    addMemberMode: false,
    /**
     * @property searchState
     * @type {'empty'|'creating'}
     */
    searchState: 'empty',
    /**
     * @property userSet
     * @type {'adding member'|'missing'|'members'}
     */
    userSet: 'adding member',
    searchText: '',
    isDisabled: false,
    searchRequest: null,
    userResults: A(),
    addingUserInProgress: false,

    maximumMembersOnTwoRows: 8,

    // Computed Properties
    memberCountText: computed('memberCount', function () {
      var count = this.get('memberCount');
      var max = this.get('maximumMembersOnTwoRows');

      if (count <= max) {
        return this.get('i18n').formatHtmlMessage('organization.member_count', {
          memberCount: count
        });
      } else {
        return this.get('i18n').formatHtmlMessage('organization.member_count_extra', {
          currentlyShown: max,
          memberCount: count
        });
      }
    }),
    searchPlaceholder: computed(function () {
      return this.get('i18n').formatHtmlMessage('organization.add_member');
    }),
    memberCreate: computed('searchText', function () {
      var email = this.get('searchText').trim();
      email = email.length ? ' ' + email : '';
      return this.get('i18n').formatHtmlMessage('organization.member_create', {
        email: email
      });
    }),
    memberMissing: computed('searchText', function () {
      if (this.get('searchText').trim().length) {
        return this.get('i18n').formatHtmlMessage('organization.member_missing', {
          term: this.get('searchText')
        });
      } else {
        return this.get('i18n').formatHtmlMessage('organization.member_missing_empty', {
          term: this.get('searchText')
        });
      }
    }),
    memberCount: readOnly('members.length'),

    // Methods

    /**
     * @method searchMembers
     * @param {string} term - Term to search for
     * @param {User[]} members - Members to search through
     * @returns {User[]} Members whose name or email match the given term
     */
    searchMembers: function searchMembers(term, members) {
      var regex = new RegExp(term, 'ig');

      return members.filter(function (member) {
        return regex.test(member.full_name) || regex.test(member.email); // can use to find users by email too
      });
    },


    /**
     * @method isEmail
     * @param {string} term - A string that *might* be an email address
     * @returns {boolean}
     */
    isEmail: function isEmail(term) {
      return (/\S+@\S+\.\S+/.test(term)
      );
    },


    /**
     * Adds a user to the set of members to save to the organization.
     *
     * @method handleNewMember
     * @param {User} member - Member to add
     */
    handleNewMember: function handleNewMember(member) {
      this.set('addingUserInProgress', false);
      this.set('userSet', 'members');
      this.set('searchState', 'empty');
      this.set('addMemberMode', false);
      this.set('showMemberDropdown', false);
      this.get('members').addObject(member);
    },
    focusOnAddMemberField: function focusOnAddMemberField() {
      $('.' + _styles.default['add-member-email']).focus();
    },


    actions: {
      goToUser: function goToUser(member) {
        this.get('router').transitionTo('session.agent.users.user', [member.id]);
      },
      toggleMemberDropdown: function toggleMemberDropdown(userSet, isTriggered) {
        var isDown = this.get('addMemberMode');

        if (this.get('userSet') === 'adding member') {
          this.set('userSet', 'missing');
        }

        this.set('addMemberMode', !isDown);

        scheduleOnce('afterRender', this, 'focusOnAddMemberField');
      },
      toggleMemberExpanded: function toggleMemberExpanded(isToggled) {
        var isDown = this.get('showMemberExpanded');
        this.set('showMemberExpanded', !isDown);
      },
      handleInput: function handleInput(e) {
        var value = e.trim();

        if (value.length && this.get('userSet') === 'missing') {
          var isEmail = this.isEmail(value);
          if (isEmail) {
            this.set('searchState', 'creating');
          }
        } else if (this.get('addMemberMode')) {
          return;
        } else {
          this.set('searchState', 'empty');
        }
      },
      toggleAddMemberForm: function toggleAddMemberForm(doShow) {
        var email = this.get('searchText');
        var members = this.searchMembers(email, this.get('members'));

        members = members.filterBy('email', email);

        if (members.length) {
          var member = members[0];
          var msg = this.get('i18n').t('organization.member_already_present', {
            fullName: member.full_name,
            email: member.email,
            orgName: this.get('organization.name')
          });
          this.get('notification').error(msg);
        } else {
          this.set('userSet', 'adding member');
        }
      },
      addUser: function addUser(email, name) {
        var _this = this;

        var organization = this.get('organization');
        var store = this.get('store');

        return store.findRecord('role', CUSTOMER_ROLE_ID).then(function (role) {
          var emailIdentity = store.createRecord('identity-email', {
            isPrimary: true,
            email: email
          });
          var user = store.createRecord('user', {
            role: role,
            fullName: name,
            emails: [emailIdentity],
            organization: organization
          });

          _this.set('addingUserInProgress', true);

          return user.save().then(function (mem) {
            return _this.handleNewMember(mem);
          }).finally(function () {
            return _this.set('addingUserInProgress', false);
          });
        });
      },
      cancelAddingUser: function cancelAddingUser(e) {
        this.set('addMemberMode', false);
        this.set('searchState', 'empty');
        this.set('userSet', 'members');
      },
      hideDropdown: function hideDropdown(e) {
        this.set('showMemberDropdown', false);
        this.send('cancelAddingUser', e);
      }
    }
  });
});