define('frontend-cp/adapters/product-rateplan', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      if (query.productId) {
        var id = query.productId;
        Reflect.deleteProperty(query, 'productId');
        return this.urlPrefix() + ('/account/products/' + id + '/rateplans');
      } else {
        return this._super.apply(this, arguments);
      }
    },
    pathForType: function pathForType() {
      return 'account/rateplans';
    }
  });
});