define('frontend-cp/adapters/device-token', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'devices/tokens';
    },
    updateToken: function updateToken(type) {
      var url = this.namespace + '/' + this.pathForType();
      return this.ajax(url, 'PUT', {
        data: {
          type: type
        }
      });
    }
  });
});