define('frontend-cp/components/key-value-collection-builder/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // Attributes
    collection: null,
    name: 'key-value-builder',
    keyPlaceholder: null,
    valuePlaceholder: null,
    add: function add() {},
    remove: function remove() {},
    updatePair: function updatePair() {}
  });
});