define('frontend-cp/components/ko-timeline-2/list/item/created-at/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var not = Ember.computed.not;
  var service = Ember.inject.service;
  var get = Ember.get;


  function twitterHref(model) {
    if (model) {
      var screenName = get(model, 'original.screenName');
      var tweetId = get(model, 'original.id');

      if (screenName && tweetId) {
        return 'https://twitter.com/' + screenName + '/status/' + tweetId;
      }
    }

    return null;
  }

  exports.default = Component.extend({
    tagName: '',

    model: null,
    showModal: false,

    windowService: service('window'),

    modelChannelType: reads('model.sourceChannel.channelType'),
    creatorIsCustomer: not('model.creator.role.isAgentOrHigher'),

    postIsEmailFromCustomer: computed('creatorIsCustomer', 'modelChannelType', function () {
      var creatorIsCustomer = this.get('creatorIsCustomer');
      var postType = this.get('modelChannelType');

      return creatorIsCustomer && (postType === 'MAIL' || postType === 'MAILBOX');
    }),

    postIsTwitter: computed('modelChannelType', 'href', function () {
      var postType = this.get('modelChannelType');
      var href = this.get('href');

      return postType === 'TWITTER' && href;
    }),

    href: computed('model.id', function () {
      var model = this.get('model');
      return twitterHref(model);
    }),

    actions: {
      openExternal: function openExternal(href, e) {
        e.preventDefault();
        e.stopPropagation();

        this.get('windowService').open(href, '', { width: '800', height: '500' });
      }
    }
  });
});