define('frontend-cp/session/admin/messenger/engagements/new/route', ['exports', 'ember-service/inject', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _inject, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend((0, _route.default)('theVisitorEngagement'), {
    launchDarkly: (0, _inject.default)(),


    agentCache: service('cache/agent-cache'),
    metrics: service(),

    model: function model() {
      var store = this.get('store');
      var agentCache = this.get('agentCache');

      return RSVP.hash({
        definitions: store.query('definition', { type: 'engagement' }),
        automationActionDefinitions: store.query('automation-action-definition', { type: 'engagement' }),
        theVisitorEngagement: store.createRecord('engagement', {
          actions: [store.createRecord('automation-action')],
          isEnabled: true
        }),
        teams: store.findAll('team'),
        agents: agentCache.getAgents()
      });
    },
    setupController: function setupController(controller, _ref) {
      var theVisitorEngagement = _ref.theVisitorEngagement,
          teams = _ref.teams,
          agents = _ref.agents,
          definitions = _ref.definitions,
          automationActionDefinitions = _ref.automationActionDefinitions;

      theVisitorEngagement.get('predicateCollections').createRecord({
        propositions: [this.get('store').createRecord('proposition')],
        showAddNewCondition: true,
        definitions: this.get('store').peekAll('definition').filter(function (definition) {
          return definition.get('group') === 'ENGAGEMENT';
        })
      });
      controller.setProperties({ theVisitorEngagement: theVisitorEngagement, teams: teams, agents: agents, definitions: definitions, automationActionDefinitions: automationActionDefinitions });
      controller.setProperties(this.modelFor('session.admin.messenger.engagements'));
    },


    // Actions
    actions: {
      didSave: function didSave() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'engagement_rule_created',
            name: this.get('controller').get('theVisitorEngagement.title')
          });
        }
        this.transitionTo('session.admin.messenger.engagements');
      },
      cancel: function cancel() {
        this.transitionTo('session.admin.messenger.engagements');
      }
    }
  });
});