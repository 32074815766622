define("frontend-cp/components/ko-insights/section/cases/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "header": "ko-insights_section_cases__header_109xq7",
    "entity": "ko-insights_section_cases__entity_109xq7",
    "filters": "ko-insights_section_cases__filters_109xq7",
    "filtersControls": "ko-insights_section_cases__filtersControls_109xq7",
    "metricsContainer": "ko-insights_section_cases__metricsContainer_109xq7",
    "metrics": "ko-insights_section_cases__metrics_109xq7",
    "chart": "ko-insights_section_cases__chart_109xq7",
    "questionBlock": "ko-insights_section_cases__questionBlock_109xq7",
    "question": "ko-insights_section_cases__question_109xq7",
    "questionTitle": "ko-insights_section_cases__questionTitle_109xq7",
    "questionSpacing": "ko-insights_section_cases__questionSpacing_109xq7",
    "answer": "ko-insights_section_cases__answer_109xq7",
    "insights": "ko-insights_section_cases__insights_109xq7",
    "insightsChannelChart": "ko-insights_section_cases__insightsChannelChart_109xq7"
  };
});