define('frontend-cp/services/notification-preferences', ['exports', 'ember-service/inject', 'ember-concurrency'], function (exports, _inject, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;


  var PATH = '/notification_preferences';

  exports.default = Service.extend({
    launchDarkly: (0, _inject.default)(),

    store: service(),

    _preferences: null,

    preferences: computed.reads('_preferences'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('_preferences', []);
    },
    loadPreferences: function loadPreferences() {
      return this.get('_loadPreferences').perform();
    },
    savePreferences: function savePreferences(preferences) {
      return this.get('_savePreferences').perform(preferences);
    },


    isSaving: computed.reads('_savePreferences.isRunning'),

    isDesktopNotification: function isDesktopNotification(notificationType) {
      var preference = this._preference(notificationType);

      return preference.channelDesktop;
    },
    _preference: function _preference(notificationType) {
      return this.get('preferences').findBy('notificationType', notificationType);
    },


    _loadPreferences: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var _this = this;

      var launchDarkly;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');

              if (launchDarkly.get('ops-refactor-notification-preferences-data-retrieval')) {
                _context.next = 3;
                break;
              }

              return _context.abrupt('return');

            case 3:
              return _context.abrupt('return', this._load().then(function (result) {
                var preferences = result.data.map(_this._deserializePreference);
                _this.get('_preferences').clear();
                _this.get('_preferences').addObjects(preferences);

                return result;
              }));

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    _savePreferences: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(preferences) {
      var data;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              data = preferences.map(this._serializePreference);
              _context2.next = 3;
              return this._save(data);

            case 3:
              return _context2.abrupt('return', this.loadPreferences());

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    _deserializePreference: function _deserializePreference(pref) {
      return {
        notificationType: pref.notification_type,
        channelDesktop: pref.channel_desktop,
        channelMobile: pref.channel_mobile,
        channelEmail: pref.channel_email
      };
    },
    _serializePreference: function _serializePreference(pref) {
      return {
        notification_type: pref.notificationType,
        channel_desktop: pref.channelDesktop,
        channel_mobile: pref.channelMobile,
        channel_email: pref.channelEmail
      };
    },
    _adapter: function _adapter() {
      return this.get('store').adapterFor('application');
    },
    _url: function _url() {
      var adapter = this._adapter();
      return '' + adapter.namespace + PATH;
    },
    _load: function _load() {
      var adapter = this._adapter();
      var url = this._url();

      return adapter.ajax(url, 'GET');
    },
    _save: function _save(data) {
      var adapter = this._adapter();
      var url = this._url();

      return adapter.ajax(url, 'PUT', { data: { values: data } });
    }
  });
});