define("frontend-cp/components/ko-admin/engagements/index/poly-graph/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "count-wrapper": "ko-admin_engagements_index_poly-graph__count-wrapper_161est",
    "metric-count": "ko-admin_engagements_index_poly-graph__metric-count_161est",
    "conversion": "ko-admin_engagements_index_poly-graph__conversion_161est"
  };
});