define('frontend-cp/components/ko-merge-conversation-modal/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var next = Ember.run.next;
  var EmberObject = Ember.Object;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var get = Ember.get;
  var observer = Ember.observer;
  exports.default = Component.extend({
    // Services
    mergeConversation: service(),
    apiAdapter: service(),
    notification: service(),
    i18n: service(),
    routing: service('-routing'),

    // State
    casesForUser: null,
    highlightedResult: null,
    isSearching: false,
    showConfirmation: false,
    selectedConversations: null,
    hasSuggestions: false,

    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedConversations', []);
    },


    activeMergeDidChange: observer('activeMerge', function () {
      var passed = this.get('activeMerge.selectedCases');
      var skipSelection = this.get('activeMerge.skipSelection');

      if (passed) {
        var normalized = passed.map(normalizeToMinimalCaseFormat);
        this.set('selectedConversations', normalized);
      }

      if (skipSelection) {
        this.set('showConfirmation', true);
      }
    }),

    // CP's
    activeMerge: computed.oneWay('mergeConversation.activeMerge'),

    selectedLabel: computed('activeMerge.selectedLabel', function () {
      return this.get('activeMerge.selectedLabel').toUpperCase();
    }),

    convosToBeAffected: computed('selectedConversations.[]', 'activeMerge.currentCase', function () {
      var currentCase = this._normalizeToMinimalCaseFormat(this.get('activeMerge.currentCase'));
      return [].concat(_toConsumableArray(this.get('selectedConversations')), [currentCase]);
    }),

    oldestCase: computed('convosToBeAffected.[]', function () {
      var items = this.get('convosToBeAffected');
      var sorted = items.sortBy('resultData.createdAt');
      return sorted[0];
    }),

    convosToBeMerged: computed('convosToBeAffected.[]', 'oldestCase', function () {
      var convosToBeAffected = this.get('convosToBeAffected');
      var oldestCaseId = this.get('oldestCase.id');

      return convosToBeAffected.filter(function (convo) {
        return convo.get('id') !== oldestCaseId;
      });
    }),

    searchWithinQuery: computed('activeMerge.requesterName', function () {
      return 'in:cases -status:closed';
    }),

    suggestionExclusions: computed('selectedConversations.[]', 'activeMerge.currentCase.id', function () {
      var exclusions = this.get('selectedConversations').map(function (convo) {
        var idUrl = convo.get('id');

        return idUrl.substr(idUrl.lastIndexOf('/') + 1);
      });

      return [].concat(_toConsumableArray(exclusions), [this.get('activeMerge.currentCase.id')]);
    }),

    searchExclusions: computed('selectedConversations.[]', 'activeMerge.currentCase.id', function () {
      var exclusions = this.get('selectedConversations').map(function (convo) {
        return {
          id: convo.get('id'),
          type: 'case'
        };
      });

      exclusions.pushObject({
        id: this.get('activeMerge.currentCase.id'),
        type: 'case'
      });

      return exclusions;
    }),

    numSelectedConversations: computed.readOnly('convosToBeMerged.length'),

    requester: computed.readOnly('activeMerge.currentCase.requester'),

    // Methods

    // Search results are a different format from suggestions
    // normalizing them before they are stored makes it easier to keep the excuded results list in sync
    _normalizeToMinimalCaseFormat: function _normalizeToMinimalCaseFormat(convo) {
      return normalizeToMinimalCaseFormat(convo);
    },


    // Tasks
    performMerge: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var convosToBeMergedIds, oldestCase, oldestCaseId;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              convosToBeMergedIds = this.get('convosToBeMerged').map(function (convo) {
                return convo.get('id');
              });
              oldestCase = this.get('oldestCase');
              oldestCaseId = this.get('oldestCase.id');
              _context.prev = 3;
              _context.next = 6;
              return this.get('apiAdapter').mergeCases(oldestCaseId, convosToBeMergedIds);

            case 6:
              this.get('mergeConversation').acceptMerge(oldestCase);
              this.get('notification').success(this.get('i18n').t('cases.merge_conversation.success_message'));
              this.set('selectedConversations', []);
              this.set('showConfirmation', false);
              if (this.get('activeMerge.currentCase.id') !== oldestCaseId) {
                this.get('routing').transitionTo('session.agent.cases.case', [oldestCaseId]);
              }
              _context.next = 18;
              break;

            case 13:
              _context.prev = 13;
              _context.t0 = _context['catch'](3);

              this.get('mergeConversation').cancelMerge(false);
              this.set('selectedConversations', []);
              this.set('showConfirmation', false);

            case 18:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[3, 13]]);
    })).drop(),

    actions: {
      updateSearchState: function updateSearchState(state) {
        this.set('isSearching', state);
      },
      highlightResult: function highlightResult(highlightedResult) {
        this.set('highlightedResult', highlightedResult);
      },
      updateMergeList: function updateMergeList(operation, result) {
        if (operation === 'ADD') {
          this.get('selectedConversations').pushObject(this._normalizeToMinimalCaseFormat(result));
        } else {
          this.get('selectedConversations').removeObject(result);
        }
      },
      onCancel: function onCancel() {
        this.set('selectedConversations', []);
        this.get('mergeConversation').cancelMerge(false);
        this.set('showConfirmation', false);
      },
      onNext: function onNext() {
        this.set('showConfirmation', true);
      },
      onBackFromConfirmation: function onBackFromConfirmation() {
        this.set('showConfirmation', false);
      },
      onConfirm: function onConfirm() {
        this.get('performMerge').perform();
      },
      onFetchSuggestions: function onFetchSuggestions(suggestions) {
        var _this = this;

        // The merge conversation option shows up on cases (open) that can show up in suggestions.
        // So if there's only one suggestion, that is the case we're on, and it's excluded from the suggestions list.
        var hasSuggestions = false;
        if (suggestions && get(suggestions, 'length') > 1) {
          hasSuggestions = true;
        } else if (suggestions === null) {
          // If `suggestions` is null, that means the suggestions haven't been fetched yet.
          // `suggestions` will be an array with length = 1 when suggestions have been fetched, but no suggestions exist.
          hasSuggestions = true;
        }
        next(function () {
          _this.set('hasSuggestions', hasSuggestions);
        });
      }
    }
  });


  function normalizeToMinimalCaseFormat(convo) {
    if (convo.get('subject')) {
      //is a full blown case resource
      return new EmberObject({
        id: convo.get('id'),
        title: convo.get('subject'),
        resource: 'case',
        resultData: {
          status: convo.get('status'),
          requester: convo.get('requester'),
          assignedAgent: convo.get('assignedAgent'),
          updatedAt: convo.get('updatedAt'),
          createdAt: convo.get('createdAt')
        }
      });
    } else {
      //assume it is a search result or suggestion
      return new EmberObject({
        id: convo.get('id').substr(convo.get('id').lastIndexOf('/') + 1),
        title: convo.get('title'),
        resource: 'case',
        resultData: {
          status: convo.get('resultData.status'),
          requester: convo.get('resultData.requester'),
          assignedAgent: convo.get('resultData.assignedAgent'),
          updatedAt: convo.get('resultData.updatedAt'),
          createdAt: convo.get('resultData.createdAt')
        }
      });
    }
  }
});