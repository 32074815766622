define('frontend-cp/components/ko-search-sidebar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    resultGroup: null,
    totalCounts: null,
    selectedCaseIds: null,
    onSelect: function onSelect() {},
    bulkUpdateComplete: function bulkUpdateComplete() {},
    clearSelectedCaseIds: function clearSelectedCaseIds() {},

    //CPs
    _selectedCaseIds: computed.alias('selectedCaseIds')
  });
});