define('frontend-cp/adapters/user-case', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    autoIncludeAll: false,

    urlForQuery: function urlForQuery(query, modelName) {
      var id = query.userId;
      var statuses = query.statuses;
      this._super.apply(this, arguments);
      Reflect.deleteProperty(query, 'userId');
      Reflect.deleteProperty(query, 'statuses');
      return '/api/v1/users/' + id + '/cases?status=' + statuses + '&include=case_status,user,read_marker';
    }
  });
});