define('frontend-cp/components/ko-agent-cases-index-view/component', ['exports', 'frontend-cp/config/environment', 'npm:lodash', 'ember-keyboard-shortcuts/mixins/component', 'ember-concurrency'], function (exports, _environment, _npmLodash, _component, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var run = Ember.run;
  var computed = Ember.computed;
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;


  var CASE_PAGE_LIMIT = _environment.default.casesPageSize;

  var openSelectedCaseInBackground = {
    action: 'openSelectedCaseInBackground',
    global: false
  };

  exports.default = Component.extend(_component.default, {
    tagName: '',

    keyboardShortcuts: {
      up: {
        action: 'focusCaseAbove',
        global: false
      },
      down: {
        action: 'focusCaseBelow',
        global: false
      },
      enter: {
        action: 'openSelectedCase',
        global: false
      },
      'ctrl+enter': openSelectedCaseInBackground,
      'shift+enter': openSelectedCaseInBackground,
      'mod+enter': openSelectedCaseInBackground
    },

    // Attrs
    page: 1,
    orderByColumn: null,
    orderBy: null,
    selectedCaseIds: null,
    currentFocusedID: null,

    // Actions
    selectedCaseIdsUpdated: function selectedCaseIdsUpdated() {},
    sortList: function sortList() {},

    // State
    casesLoading: true,
    oldQueryParams: {},
    oldView: {},

    // Services
    tabStore: service(),
    caseListTab: service('case-list-tab'),
    routing: service('-routing'),

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedCaseIds', []);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      var queryParams = {
        page: this.get('page'),
        orderByColumn: this.get('orderByColumn'),
        orderBy: this.get('orderBy')
      };

      var view = this.get('view');
      var caseListTab = this.get('caseListTab');

      if (this.oldView !== view || !_npmLodash.default.isEqual(this.oldQueryParams, queryParams)) {
        this.set('casesLoading', true);
        this.set('currentFocusedID', null);
        caseListTab.getCasesForView(view, queryParams).then(function () {
          caseListTab.transitionToPreviousPageIfEmpty();
        }).catch(function (error) {
          if (!(0, _emberConcurrency.didCancel)(error)) {
            throw error;
          }
        }).finally(function () {
          _this.set('casesLoading', false);
        });
      }

      this.oldQueryParams = queryParams;
      this.oldView = view;
    },


    // Tasks
    handleRealtimeChange: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('caseListTab.fetchCases').perform();

            case 2:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    // CP's
    cases: readOnly('caseListTab.latestCases'),

    activeView: readOnly('caseListTab.currentView'),

    isTrash: computed('activeView.viewType', function () {
      return this.get('activeView.viewType') === 'TRASH';
    }),

    showPagination: computed.empty('selectedCaseIds'),

    totalPages: computed('cases.meta.total', function () {
      var totalCases = this.get('cases.meta.total');
      return Math.ceil(totalCases / CASE_PAGE_LIMIT);
    }),

    // Methods
    selectCase: function selectCase(direction) {
      var cases = this.get('cases');
      var count = cases.get('length');
      var id = this.get('currentFocusedID');
      var currentIndex = cases.getEach('id').indexOf(id);

      if (id === null) {
        id = cases.get('firstObject.id');
      } else if (direction === 'down') {
        id = cases.objectAt(++currentIndex % count).id;
      } else if (direction === 'up') {
        currentIndex = currentIndex <= 0 ? count : currentIndex;
        id = cases.objectAt(--currentIndex % count).id;
      }
      this.set('currentFocusedID', id);
    },
    afterMerge: function afterMerge(primaryCase) {
      var caseListTab = this.get('caseListTab');

      this.sendAction('selectedCaseIdsUpdated', []);

      run.next(function () {
        return caseListTab.refresh();
      });
    },


    // Actions
    actions: {
      clearSelectedCaseIds: function clearSelectedCaseIds() {
        this.sendAction('selectedCaseIdsUpdated', []);
      },
      transitionToCase: function transitionToCase(caseContext, hasModifier) {
        var route = 'session.agent.cases.case';

        if (hasModifier) {
          this.get('tabStore').createTab(route, caseContext);
        } else {
          this.get('routing').transitionTo(route, [caseContext.get('id')], {});
        }
      },
      sortCaseList: function sortCaseList(column, orderBy) {
        this.sendAction('sortList', orderBy, column.get('name'));
      },
      setSelectedCaseIds: function setSelectedCaseIds(checkedRows) {
        this.sendAction('selectedCaseIdsUpdated', checkedRows);
      },
      trashCases: function trashCases() {
        var _this2 = this;

        this.sendAction('selectedCaseIdsUpdated', []);
        // wrapped in next() to allow spinner to load, to so it animates
        run.next(function () {
          _this2.get('caseListTab').refresh();
        });
      },
      trashAllCases: function trashAllCases() {
        var _this3 = this;

        // wrapped in next() to allow spinner to load, to so it animates
        run.next(function () {
          _this3.get('caseListTab').refresh();
        });
      },
      focusCaseAbove: function focusCaseAbove() {
        this.selectCase('up');
      },
      focusCaseBelow: function focusCaseBelow() {
        this.selectCase('down');
      },
      openSelectedCase: function openSelectedCase() {
        if (!this.get('currentFocusedID')) {
          return;
        }
        this.get('routing').transitionTo('session.agent.cases.case', [this.get('currentFocusedID')]);
      },
      openSelectedCaseInBackground: function openSelectedCaseInBackground() {
        var kase = this.get('cases').findBy('id', this.get('currentFocusedID'));
        if (!kase) {
          return;
        }
        this.get('tabStore').createTab('session.agent.cases.case', kase);
      }
    }
  });
});