define('frontend-cp/adapters/app-installation-prompt', ['exports', 'frontend-cp/adapters/apps-adapter'], function (exports, _appsAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _appsAdapter.default.extend({
    urlForQuery: function urlForQuery(_ref) {
      var app_installation_id = _ref.app_installation_id;

      return this._urlForInstallationPrompts(app_installation_id);
    },
    updatePrompts: function updatePrompts(installation, values) {
      var url = this._urlForInstallationPrompts(installation.get('id'));
      var prompts = Object.keys(values).map(function (key) {
        return { key: key, value: values[key] };
      });
      return this.ajax(url, 'PUT', { data: { prompts: prompts } });
    },
    _urlForInstallationPrompts: function _urlForInstallationPrompts(app_installation_id) {
      var _getProperties = this.getProperties('host', 'namespace'),
          host = _getProperties.host,
          namespace = _getProperties.namespace;

      return host + '/' + namespace + '/app-installations/' + app_installation_id + '/prompts';
    }
  });
});