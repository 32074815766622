define('frontend-cp/session/admin/channels/twitter/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.store.findAll('twitter-account', { reload: true });
    },


    actions: {
      editAccount: function editAccount(account) {
        this.transitionTo('session.admin.channels.twitter.edit', account.get('id'));
      }
    }
  });
});