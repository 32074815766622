define('frontend-cp/helpers/relative-term-for-date', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.relativeTermForDate = relativeTermForDate;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Helper = Ember.Helper;
  var service = Ember.inject.service;
  var observer = Ember.observer;
  var readOnly = Ember.computed.readOnly;
  var assign = Ember.assign;
  function relativeTermForDate(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        then = _ref3[0];

    var now = _ref2.now,
        timezone = _ref2.timezone;

    if (!then) {
      return null;
    }

    now = now || new Date();

    if (isToday(then, now, timezone)) {
      return 'today';
    }

    if (isYesterday(then, now, timezone)) {
      return 'yesterday';
    }

    return null;
  }

  function isToday(then, now, timezone) {
    then = _moment.default.tz(then, timezone);
    now = _moment.default.tz(now, timezone);

    var min = (0, _moment.default)(now).startOf('day');
    var max = (0, _moment.default)(min).endOf('day');

    return then.isBetween(min, max, null, '[]');
  }

  function isYesterday(then, now, timezone) {
    return isToday(then, (0, _moment.default)(now).subtract(1, 'day'), timezone);
  }

  exports.default = Helper.extend({
    moment: service(),

    timezone: readOnly('moment.timeZone'),

    timezoneDidChange: observer('timezone', function () {
      this.recompute();
    }),

    compute: function compute(args) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var timezone = this.get('timezone');

      return relativeTermForDate(args, assign({}, options, { timezone: timezone }));
    }
  });
});