define("frontend-cp/components/ko-case-content/cc/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "root": "ko-case-content_cc__root_shs3yu",
    "hide": "ko-case-content_cc__hide_shs3yu",
    "cc": "ko-case-content_cc__cc_shs3yu",
    "cc--on": "ko-case-content_cc__cc--on_shs3yu",
    "cc--collapsed": "ko-case-content_cc__cc--collapsed_shs3yu",
    "cc-expand-item": "ko-case-content_cc__cc-expand-item_shs3yu",
    "cc-list-and-input": "ko-case-content_cc__cc-list-and-input_shs3yu",
    "trigger": "ko-case-content_cc__trigger_shs3yu",
    "dropdown": "ko-case-content_cc__dropdown_shs3yu _dropdown_jvqwfg",
    "option": "ko-case-content_cc__option_shs3yu",
    "text": "ko-case-content_cc__text_shs3yu",
    "name": "ko-case-content_cc__name_shs3yu",
    "email": "ko-case-content_cc__email_shs3yu",
    "add-icon": "ko-case-content_cc__add-icon_shs3yu",
    "option-item": "ko-case-content_cc__option-item_shs3yu",
    "option-item-close": "ko-case-content_cc__option-item-close_shs3yu",
    "option-item-title": "ko-case-content_cc__option-item-title_shs3yu",
    "option-item-membrane": "ko-case-content_cc__option-item-membrane_shs3yu"
  };
});