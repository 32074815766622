define('frontend-cp/components/ko-insights/loader/help-center/articles/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectProxy = Ember.ObjectProxy;
  var PromiseProxyMixin = Ember.PromiseProxyMixin;
  var computed = Ember.computed;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend({
    tagName: '',
    insights: service(),
    store: service(),

    metricsQueryParams: null,
    articles: null,

    request: computed('metricsQueryParams', function () {
      var _this = this;

      // trigger for computed property to work for trial: false/true param
      this.get('metricsQueryParams');

      var insights = this.get('insights');
      var store = this.get('store');
      var endpoint = insights.articlesEndpoint();
      var adapter = getOwner(this).lookup('adapter:application');

      return ObjectProxy.extend(PromiseProxyMixin).create({
        promise: adapter.ajax(endpoint, 'GET', { data: { limit: 20 } }).then(function (payload) {
          if (_this.isDestroying || _this.isDestroyed) {
            return null;
          }

          var defaultLocale = store.peekAll('setting').findBy('key', 'account.default_language').get('value');
          if (insights.isTrialMode()) {
            defaultLocale = 'en-us';
          }

          var articles = payload.data.map(function (article) {
            var brand = brandFor({ article: article, payload: payload, store: store });
            var locale = brand && brand.get('locale.locale') || defaultLocale;
            var titles = article.titles.map(function (title) {
              return payload.resources.locale_field[title.id];
            });
            var title = titles.filter(function (title) {
              return title.locale === locale;
            }).map(function (title) {
              return title.translation;
            })[0];
            var defaultTitle = titles[0] && titles[0].translation || '';
            var url = urlFor({ article: article, brand: brand, locale: locale, defaultLocale: defaultLocale });

            return {
              id: article.id,
              title: title || defaultTitle,
              total_comments: article.total_comments,
              upvote_count: article.upvote_count,
              views: article.views,
              url: url
            };
          });

          _this.set('articles', articles);

          return payload;
        })
      });
    })
  });


  function urlFor(_ref) {
    var article = _ref.article,
        brand = _ref.brand,
        locale = _ref.locale,
        defaultLocale = _ref.defaultLocale;

    var scheme = 'https://';
    var hostname = brand.get('subDomain') + '.' + brand.get('domain');
    var slug = article.slugs.findBy('locale', locale) || article.slugs.findBy('locale', defaultLocale) || article.slugs[0];
    var path = '/' + slug.locale + '/article/' + slug.translation;
    var result = scheme + hostname + path;

    return result;
  }

  function brandFor(_ref2) {
    var article = _ref2.article,
        payload = _ref2.payload,
        store = _ref2.store;

    var section = payload.resources.section[article.section.id];
    var category = payload.resources.category[section.category.id];
    var result = store.peekRecord('brand', category.brand.id);

    return result;
  }
});