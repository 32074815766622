define('frontend-cp/serializers/auth-provider', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var convertedData = [];
      var payloadData = payload.data && payload.data.agent || [];
      payloadData.forEach(function (item) {
        convertedData.push({
          id: item.provider_code,
          attributes: {
            scheme: item.scheme,
            'login-url': item.login_url,
            'logout-url': item.logout_url
          },
          type: 'auth_provider'
        });
      });

      return this._super(store, primaryModelClass, { data: convertedData }, id, requestType);
    }
  });
});