define('frontend-cp/models/case-field-value', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  var observer = Ember.observer;
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    fieldId: _emberData.default.attr('string'),

    observeFieldID: on('init', observer('fieldId', function () {
      this.set('field', this.store.peekRecord('case-field', this.get('fieldId')));
    })),

    value: _emberData.default.attr('string')
  });
});