define('frontend-cp/session/admin/automation/monitors/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    // Methods
    initEdits: function initEdits() {
      this.get('editComponent').initEdits();
    },
    isEdited: function isEdited() {
      return this.get('editComponent').isEdited();
    }
  });
});