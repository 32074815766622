define('frontend-cp/session/admin/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    plan: service(),
    session: service(),
    permissions: service(),

    shouldShowMessengerSection: computed('session.permissions.[]', function () {
      var permissions = this.get('permissions');

      return permissions.has('channels.manage') || permissions.has('automations.manage');
    }),

    shouldShowCustomizationSection: computed('session.permissions.[]', function () {
      var permissions = this.get('permissions');

      return permissions.has('case_fields.manage') || permissions.has('user_fields.manage') || permissions.has('organizations_fields.manage');
    }),

    shouldShowPeopleSection: computed('session.permissions.[]', function () {
      var permissions = this.get('permissions');

      return permissions.has('teams.manage') || permissions.has('roles.manage') || permissions.has('business_hours.manage');
    }),

    shouldShowAutomationsSection: computed('session.permissions.[]', function () {
      var permissions = this.get('permissions');

      return permissions.has('macros.manage') || permissions.has('slas.manage') || permissions.has('automations.manage');
    }),

    hostname: computed(function () {
      return location.hostname;
    }),

    shouldShowAccountSection: computed('hostname', function () {
      return this.get('hostname') !== 'support.kayako.com';
    })
  });
});