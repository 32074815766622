define('frontend-cp/components/ko-pinned-notes/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var scheduleOnce = Ember.run.scheduleOnce;
  exports.default = Component.extend({
    // Attributes:
    tagName: '',
    model: null,
    fetchNotes: function fetchNotes() {}, // Defined in components where ko-pinned-notes is called in.

    init: function init() {
      this._super.apply(this, arguments);
      scheduleOnce('afterRender', this, 'runFetchNotes');
    },
    runFetchNotes: function runFetchNotes() {
      this.get('fetchNotes').perform();
    },


    // Services
    i18n: service(),
    store: service(),

    // CPs
    pinnedNotesCount: computed('model.viewNotes.@each.isPinned', function () {
      if (this.get('model.viewNotes')) {
        return this.get('model.viewNotes').filterBy('isPinned').length;
      }
      return 0;
    }),

    pinnedNotesText: computed('pinnedNotesCount', function () {
      var count = this.get('pinnedNotesCount');
      count = count > 9 ? '9+' : count;

      return this.get('i18n').t('generic.pinned_notes', { count: count });
    })
  });
});