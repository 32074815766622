define("frontend-cp/components/ko-note-destination/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "note-destination-selector": "ko-note-destination__note-destination-selector_asvq3s",
    "option-container": "ko-note-destination__option-container_asvq3s"
  };
});