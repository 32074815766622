define("frontend-cp/components/ko-reorderable-list/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-reorderable-list__container_mopbzp",
    "list": "ko-reorderable-list__list_mopbzp",
    "item": "ko-reorderable-list__item_mopbzp",
    "handle": "ko-reorderable-list__handle_mopbzp",
    "handleOutside": "ko-reorderable-list__handleOutside_mopbzp"
  };
});