define('frontend-cp/components/ko-admin/automation-actions-builder/endpoint-http-post/component', ['exports', 'frontend-cp/components/ko-admin/automation-actions-builder/base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var isBlank = Ember.isBlank;
  exports.default = _component.default.extend({
    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.originalValue = this.get('automationAction.value');
      this.payloadFormat = isBlank(this.originalValue) ? 'simple' : 'custom';
    },


    endpointContentTypeDescriptionIntlString: computed('definition.inputType', function () {
      switch (this.get('definition.inputType')) {
        case 'ENDPOINT_HTTP_XML':
          return 'admin.automation_actions_builder.endpoint_description.xml';
        case 'ENDPOINT_HTTP_JSON':
          return 'admin.automation_actions_builder.endpoint_description.json';
      }
    }),

    // Actions
    actions: {
      changePayloadFormat: function changePayloadFormat(v) {
        this.set('payloadFormat', v);
        if (v === 'custom') {
          this.set('automationAction.value', this.originalValue);
        } else if (v === 'simple') {
          this.originalValue = this.get('automationAction.value');
          this.set('automationAction.value', '');
        }
      }
    }
  });
});