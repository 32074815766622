define('frontend-cp/components/ko-agent-dropdown/component', ['exports', 'moment', 'frontend-cp/components/ko-agent-dropdown/styles'], function (exports, _moment, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var run = Ember.run;
  exports.default = Component.extend({
    tagName: '',
    // State
    selectedNavItem: null,

    // Services
    store: service(),
    notification: service(),
    i18n: service(),
    routing: service('-routing'),
    permissions: service(),
    transitionToRouteAction: 'transitionToRoute',

    // CPs
    canCreateUsers: computed(function () {
      return this.get('permissions').has('users.update');
    }),

    canCreateOrganizations: computed(function () {
      return this.get('permissions').has('organizations.update');
    }),

    dropdownWidthClass: computed('selectedNavItem', 'canCreateUsers', 'canCreateOrganizations', function () {
      if (this.get('selectedNavItem')) {
        // always full width if we're inside a form
        return null;
      } else {
        var length = 2; // Setting to 2, because there will be at 2-4 items in two rows. So 2 columns works.

        return _styles.default['drop--' + length];
      }
    }),

    // Actions
    actions: {
      reset: function reset() {
        if (Ember.testing) {
          this.set('selectedNavItem', null);
        } else {
          run.next(this, this.set, 'selectedNavItem', null);
        }
      },
      onTabCreateComplete: function onTabCreateComplete(route, model) {
        this._createSuccessNotification(route);

        this.get('routing').transitionTo(route, [model]);
      },
      transitionToRoute: function transitionToRoute(dropdown) {
        for (var _len = arguments.length, rest = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          rest[_key - 1] = arguments[_key];
        }

        this.sendAction.apply(this, ['transitionToRouteAction'].concat(_toConsumableArray(rest)));

        dropdown.actions.close();
      },
      openSearchTab: function openSearchTab(dropdown) {
        var searchHash = new Date().getTime().toString(16);
        this.get('routing').transitionTo('session.agent.search-new', [searchHash]);
        dropdown.actions.close();
      },
      openNewConversationTab: function openNewConversationTab(dropdown) {
        var dateStamp = (0, _moment.default)().format('YYYY-MM-DD-hh-mm-ss');
        this.get('routing').transitionTo('session.agent.cases.new.index', [dateStamp]);

        dropdown.actions.close();
      },
      openNewUserTab: function openNewUserTab(dropdown) {
        var dateStamp = (0, _moment.default)().format('YYYY-MM-DD-hh-mm-ss');
        this.get('routing').transitionTo('session.agent.users.new.index', [dateStamp]);

        dropdown.actions.close();
      },
      openNewOrganizationTab: function openNewOrganizationTab(dropdown) {
        var dateStamp = (0, _moment.default)().format('YYYY-MM-DD-hh-mm-ss');
        this.get('routing').transitionTo('session.agent.organizations.new.index', [dateStamp]);

        dropdown.actions.close();
      }
    },

    // Methods

    _createSuccessNotification: function _createSuccessNotification(route) {
      var notificationMessage = null;

      switch (route) {
        case 'session.agent.users.user':
          notificationMessage = this.get('i18n').t('users.user.created');
          break;
        case 'session.agent.cases.case':
          // intentionally left blank, because at this step we do not create a Case
          break;
        case 'session.agent.organizations.organization':
          notificationMessage = this.get('i18n').t('organization.created');
          break;
      }

      if (notificationMessage) {
        this.get('notification').add({
          type: 'success',
          title: notificationMessage,
          autodismiss: true
        });
      }
    }
  });
});