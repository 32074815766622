define("frontend-cp/components/ko-info-bar/field/select-multiple/trigger/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-info-bar_field_select-multiple_trigger__trigger_1xeoxb",
    "input": "ko-info-bar_field_select-multiple_trigger__input_1xeoxb",
    "title": "ko-info-bar_field_select-multiple_trigger__title_1xeoxb",
    "loading": "ko-info-bar_field_select-multiple_trigger__loading_1xeoxb _loading-icon_jvqwfg"
  };
});