define("frontend-cp/components/ko-two-factor-auth/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "modal-container": "ko-two-factor-auth__modal-container_1p46fk",
    "modal-content": "ko-two-factor-auth__modal-content_1p46fk",
    "is-visible": "ko-two-factor-auth__is-visible_1p46fk",
    "out": "ko-two-factor-auth__out_1p46fk",
    "out--quick": "ko-two-factor-auth__out--quick_1p46fk",
    "modal-content__inner": "ko-two-factor-auth__modal-content__inner_1p46fk",
    "modal-action-button": "ko-two-factor-auth__modal-action-button_1p46fk",
    "padded-content": "ko-two-factor-auth__padded-content_1p46fk",
    "qr-image-container": "ko-two-factor-auth__qr-image-container_1p46fk",
    "all-set-icon": "ko-two-factor-auth__all-set-icon_1p46fk",
    "all-set-heading": "ko-two-factor-auth__all-set-heading_1p46fk",
    "highlighted": "ko-two-factor-auth__highlighted_1p46fk",
    "recovery-codes": "ko-two-factor-auth__recovery-codes_1p46fk",
    "recovery-codes__item": "ko-two-factor-auth__recovery-codes__item_1p46fk",
    "copy-button-container": "ko-two-factor-auth__copy-button-container_1p46fk",
    "copy-button": "ko-two-factor-auth__copy-button_1p46fk ko-button__highlight_ka3fcv ko-button__bordered_ka3fcv ko-button__chrome_ka3fcv ko-button__shared_ka3fcv",
    "padded-content--small": "ko-two-factor-auth__padded-content--small_1p46fk",
    "disable-otp-title": "ko-two-factor-auth__disable-otp-title_1p46fk",
    "disable-otp-subtitle": "ko-two-factor-auth__disable-otp-subtitle_1p46fk"
  };
});