define('frontend-cp/session/admin/messenger/engagements/edit/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend((0, _route.default)(), {
    agentCache: service('cache/agent-cache'),

    model: function model(params) {
      var store = this.get('store');
      var agentCache = this.get('agentCache');
      return RSVP.hash({
        definitions: store.query('definition', { type: 'engagement' }),
        automationActionDefinitions: store.query('automation-action-definition', { type: 'engagement' }),
        theVisitorEngagement: this.store.findRecord('engagement', params.engagement_id),
        teams: store.findAll('team'),
        agents: agentCache.getAgents()
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      controller.setProperties(this.modelFor('session.admin.messenger.engagements'));
    },


    // Actions
    actions: {
      didSave: function didSave() {
        this.transitionTo('session.admin.messenger.engagements');
      },
      cancel: function cancel() {
        this.transitionTo('session.admin.messenger.engagements');
      }
    }
  });
});