define("frontend-cp/components/ko-you-label/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "label": "ko-you-label__label_64mz5t",
    "label-text": "ko-you-label__label-text_64mz5t"
  };
});