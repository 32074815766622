define('frontend-cp/components/ko-status-swatch/component', ['exports', 'frontend-cp/components/ko-status-swatch/styles'], function (exports, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    statusClass: computed('type', function () {
      if (!this.get('type')) {
        return null;
      }

      return _styles.default['status-' + this.get('type').toLowerCase()];
    })

  });
});