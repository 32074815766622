define('frontend-cp/session/agent/knowledgebase/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    selectedStatus: 'ALL',
    router: service(),

    queryParams: {
      currentPage: 'page'
    },
    currentPage: 1,

    isIndexRoute: computed('router.currentRouteName', function () {
      return this.get('router.currentRouteName') === 'session.agent.knowledgebase.index';
    }),

    totalCount: computed('model.articleCounts', 'selectedStatus', function () {
      var articleCounts = this.get('model.articleCounts');
      var selectedStatus = this.get('selectedStatus');

      if (selectedStatus === 'ALL') {
        return articleCounts.allCount;
      } else if (selectedStatus === 'PUBLISHED') {
        return articleCounts.myPublishedCount;
      } else if (selectedStatus === 'DRAFT') {
        return articleCounts.draftCount;
      } else {
        return 0;
      }
    }),

    actions: {
      filterByStatus: function filterByStatus(status) {
        this.set('selectedStatus', status);
        this.transitionToRoute('session.agent.knowledgebase.index');
      }
    }
  });
});