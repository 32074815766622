define("frontend-cp/components/ko-admin/fields/select-type/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "field-type": "ko-admin_fields_select-type__field-type_1ygtgy",
    "icon": "ko-admin_fields_select-type__icon_1ygtgy",
    "title": "ko-admin_fields_select-type__title_1ygtgy",
    "action": "ko-admin_fields_select-type__action_1ygtgy",
    "caption": "ko-admin_fields_select-type__caption_1ygtgy"
  };
});