define('frontend-cp/session/agent/cases/index/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel() {
      // Redirect to the first view.
      var views = this.modelFor('session.agent.cases.index').views;
      var inbox = views.findBy('isDefault');
      this.transitionTo('session.agent.cases.index.view', inbox.id);
    }
  });
});