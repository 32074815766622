define('frontend-cp/components/ko-sla/tooltip/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var htmlSafe = Ember.String.htmlSafe;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    metric: null,
    isBreached: false,

    // Services
    i18n: service(),

    // CPs
    tooltipTitle: computed('metric.metricType', 'metric.isCompleted', 'metric.dueAt', 'metric.completedAt', 'metric.target.operationalHours', function () {
      var i18n = this.get('i18n');
      var madeDueSlug = '';
      if (this.get('metric.isCompleted')) {
        madeDueSlug = 'made';
      } else if (this.get('isBreached')) {
        madeDueSlug = 'was_due';
      } else {
        madeDueSlug = 'due';
      }
      var time = this.get('metric.isCompleted') ? this.get('metric.completedAt') : this.get('metric.dueAt');

      var metric = i18n.t('cases.sla.tooltip.metric.' + this.get('metric.metricType'));
      var made_due = i18n.t('cases.sla.tooltip.' + madeDueSlug);
      var due_time = i18n.formatDate(time, { format: 'fullWithTime' });
      var operational_hours = i18n.t('cases.sla.tooltip.operational_hours.' + this.get('metric.target.operationalHours'));

      return htmlSafe(i18n.t('cases.sla.tooltip.sentence', {
        metric: metric,
        made_due: made_due,
        due_time: due_time,
        operational_hours: operational_hours
      }));
    })
  });
});