define("frontend-cp/locales/en-us/users", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "new_user_label": "User",
    "users": "Users",
    "title": "User",
    "total_users": "total users",
    "addnewuser": "Add New User",
    "new_user": "New User",
    "create_new": "Create new user",
    "delete_user": "Delete user",
    "delete_user_with_name": "Delete {name}'s profile",
    "delete_user_warning": "Warning: You can’t undo this action.",
    "delete_user_warning_title": "Deleting this user's profile means:",
    "delete_user_warnings": {
      "one": "All conversations linked to this user will be deleted",
      "two": "All notes, user activity, and any other user data will be deleted",
      "three": "The user will no longer be searchable within your Kayako"
    },
    "redirection_message": "Taking you to {name}'s journey...",
    "disabled": "(Disabled)",
    "disable": "Disable User",
    "enable": "Enable User",
    "infobar": {
      "organization": "Organization",
      "role": "Role",
      "timezone": "Timezone",
      "locale": "Language",
      "accesslevel": "Conversation access",
      "agentaccess": {
        "self": "Conversations assigned to agent",
        "teams": "Conversations in agent’s teams",
        "inherit": "(Inherit from role)",
        "all": "All conversations"
      },
      "organizationaccess": {
        "organization": "All organization’s conversations",
        "requested": "Only requested conversations"
      }
    },
    "addteam": "Add a team...",
    "teams": "Teams",
    "team_member_search": {
      "no_results": "No suitable agents found",
      "too_short": "Please enter at least {count} letters",
      "already_included": "This person is already in the team"
    },
    "user_list": {
      "no_results": "No matching users found"
    },
    "addtag": "Add a tag...",
    "tags": "Tags",
    "manage_app_access": "Manage app access",
    "editsignature": "Edit signature",
    "start_conversation": "Start conversation",
    "send_password_reset_email": "Send password reset email",
    "deleteuser": "Delete user",
    "confirmdelete": "Are you sure you want to delete this user?",
    "user": {
      "created": "User Created",
      "updated": "User Updated"
    },
    "metadata": {
      "created": "Created",
      "updated": "Last updated",
      "lastseen": "Last seen"
    },
    "notes": "Notes",
    "2fa_enabled": "2FA Enabled",
    "recent_feedback": "Recent feedback",
    "recent_cases": "Recent conversations",
    "no_feedback_available": "No feedback is available from this user",
    "no_recent_cases": "No recent conversations from this user",
    "signature_placeholder": "Add a signature",
    "update_signature": "Save signature",
    "signaturemessage": " ",
    "change_role": {
      "from_customer": "You are about to upgrade this user’s role. The user may receive greater access to conversations, private conversation notes, user information and some configuration areas, depending on how your permissions are configured.",
      "from_customer_header": "Upgrade role",
      "from_customer_confirm_button": "Yes, upgrade",
      "to_customer": "You are about to downgrade this user’s role. The user may lose access to conversations, private conversation notes, user information and some configuration areas. The user may permanently lose any agent-specific data (such as personal macros, or personal views).",
      "to_customer_header": "Downgrade role",
      "to_customer_confirm_button": "Yes, downgrade"
    },
    "userid": "User ID",
    "last_seen": "Last seen {time}",
    "never_seen": "Not seen before",
    "online_now": "Online now",
    "timeline": {
      "preamble": "<p>Hello! This is the beginning of your {name} journey. Here you can see a log of every action they will take within Kayako. You can also display their activity from other programs, by pushing notifications to this timeline using Zapier or the Kayako API.</p><p>Oh, and you can add private notes to this journey, which your team can see, but the customer cannot. 👇</p>"
    },
    "new_conversation": "New Conversation",
    "grant": {
      "you_have_not_authorized_apps": "You have not authorized any apps to use your Kayako account.",
      "last_used_at": "Last used on {date}",
      "never_used": "Never used",
      "revoke": "Revoke",
      "confirmdelete": "Are you sure you want to revoke this app's access? The app will no longer be able to connect to your Kayako account."
    },
    "change_avatar": "Change or remove your avatar",
    "upload_photo": "Upload your avatar",
    "remove_photo": "Remove your avatar",
    "avatar_removal_success": "Your avatar was removed successfully.",
    "avatar_removal_failure": "Failed to remove your avatar. Please try again later or contact support.",
    "confirm": {
      "delete_avatar": {
        "header": "Are you sure you want to remove your avatar?",
        "body": "We'll replace it with a default Kayako avatar.",
        "confirm": "Yes, remove my avatar."
      }
    },
    "incorrect_file_type": "<strong>{filename}</strong> isn't allowed, please try again with a JPG, GIF, or PNG file.",
    "two_factor": {
      "menu": {
        "enable": "Setup two-factor auth",
        "disable": "Disable two-factor auth"
      },
      "enable": {
        "title": "Two-factor authentication",
        "get_started": "Add another layer of security to your Kayako account by requiring access to your phone whenever you log in. Once activated, you'll need to enter both your password and an authentication code from your mobile phone to log in to your Kayako account.",
        "learn_more": "Learn more about two-factor authentication in Kayako",
        "get_started_button": "Get started",
        "get_app": "You'll need an authenticator app on your mobile phone to generate your authentication codes.",
        "recommended_apps": "We recommend <a href=\"https://support.google.com/accounts/answer/1066447?hl=en\" target=\"_blank\" rel=\"noopener noreferrer\">Google Authenticator</a>, a free app available for most phones. Kayako also supports apps like <a href=\"https://duo.com/product/trusted-users/two-factor-authentication/duo-mobile\" target=\"_blank\" rel=\"noopener noreferrer\">Duo Mobile</a> and <a href=\"https://www.authy.com/\" target=\"_blank\" rel=\"noopener noreferrer\">Authy</a>.",
        "ready_button": "Ready to set up",
        "add_kayako": "To add Kayako to your authenticator app, open the app and add a new token. Then, scan the image below, using your phone's camera.",
        "enter_otp": "After scanning the image, enter the 6-digit authentication code generated by the app in the box below.",
        "verify_button": "Verify and activate two-factor auth",
        "error_message": "Authentication failed. Please try again.",
        "success_title": "Two-factor authentication enabled",
        "success_description": "All set! Doors securely locked and bolted. From now on, when you sign in to your Kayako account you'll need to enter an authentication code from your phone using your authenticator app as well as your regular password.",
        "backup_codes": "We've generated some backup authentication codes for you to keep in a safe place, just in case you lose access to your mobile phone."
      },
      "disable": {
        "headline": "Are you sure you want to disable two-factor authentication?",
        "subheading": "Removing two-factor authentication will remove the additional layer of security on this account.",
        "button": "Disable two-factor auth",
        "notification": "Two-factor authentication disabled"
      }
    },
    "change_password": {
      "title": "Change password",
      "label": {
        "current": "Current password",
        "new": "New password",
        "confirm": "Confirm new password"
      },
      "message": {
        "success": "Password updated",
        "dont_match": "Passwords do not match. Please try again."
      },
      "policies": {
        "title": "Your password must contain:",
        "max_consecutive": "at most {value} consecutive {value, plural, =1 {character} other {characters}}",
        "min_characters": "at least {value} {value, plural, =1 {character} other {characters}}",
        "min_numbers": "at least {value} {value, plural, =1 {number} other {numbers}}",
        "min_symbols": "at least {value} special {value, plural, =1 {character} other {characters}}",
        "require_mixed_case": "mixed case characters"
      }
    },
    "sign_update_success": "Your signature was successfully updated",
    "sign_update_failure": "Your signature couldn't be updated.",
    "name_update_success": "Name was successfully updated.",
    "name_update_failure": "An error was encountered while trying to update the name."
  };
});