define('frontend-cp/services/store-cache', ['exports', 'npm:lodash', 'moment'], function (exports, _npmLodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberPromise = Ember.RSVP.Promise;
  var on = Ember.on;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    store: service(),
    date: service(),

    _storeCache: null,

    initCache: on('init', function () {
      this._storeCache = {};
    }),

    query: function query(type, _query) {
      var _this = this;

      var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
          _ref$reload = _ref.reload,
          reload = _ref$reload === undefined ? false : _ref$reload,
          _ref$ttl = _ref.ttl,
          ttl = _ref$ttl === undefined ? 0 : _ref$ttl;

      var serializedQuery = type + ':' + _npmLodash.default.map(_query, function (value, key) {
        return key + ':' + value;
      }).sort().join(':');

      if (!this._requiresCacheUpdate(serializedQuery, reload, ttl)) {
        return new EmberPromise(function (resolve) {
          resolve(_this._storeCache[serializedQuery].result);
        });
      } else {
        return this.get('store').query(type, _query).then(function (data) {
          _this._storeCache[serializedQuery] = {
            cachedAt: (0, _moment.default)(_this.get('date').getCurrentDate()),
            result: data
          };
          return data;
        });
      }
    },
    invalidateCache: function invalidateCache(type, query) {
      var serializedQuery = type + ':' + _npmLodash.default.map(query, function (value, key) {
        return key + ':' + value;
      }).sort().join(':');
      Reflect.deleteProperty(this._storeCache, serializedQuery);
    },
    _requiresCacheUpdate: function _requiresCacheUpdate(query, shouldReload, ttl) {
      if (shouldReload || !this._storeCache[query]) {
        return true;
      }

      var earliestLegalCachedAt = ttl ? (0, _moment.default)().subtract(ttl, 'ms') : null;
      var cachedAt = this._storeCache[query].cachedAt;

      return earliestLegalCachedAt && earliestLegalCachedAt.isAfter(cachedAt);
    }
  });
});