define('frontend-cp/session/admin/automation/sla/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      transitionToSla: function transitionToSla(sla) {
        this.transitionToRoute('session.admin.automation.sla.edit', sla.id);
      },
      transitionToNewSla: function transitionToNewSla() {
        this.transitionToRoute('session.admin.automation.sla.new');
      }
    }
  });
});