define("frontend-cp/components/ko-timeline-2/list/item/email-info/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "cell": "ko-timeline-2_list_item_email-info__cell_rrehmi",
    "left": "ko-timeline-2_list_item_email-info__left_rrehmi"
  };
});