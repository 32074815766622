define('frontend-cp/session/admin/manage/case-forms/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Controller = Ember.Controller;
  var getOwner = Ember.getOwner;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    session: service(),
    i18n: service(),
    confirmation: service(),

    enabledForms: computed('model.@each.isEnabled', 'model.@each.sortOrder', function () {
      return this.get('model').filter(function (form) {
        return form.get('isEnabled') && !form.get('isDeleted');
      }).sortBy('sortOrder');
    }),

    disabledForms: computed('model.@each.isEnabled', function () {
      return this.get('model').filter(function (form) {
        return !form.get('isEnabled');
      });
    }),

    actions: {
      makeDefault: function makeDefault(caseform, e) {
        e.stopPropagation();
        var adapter = getOwner(this).lookup('adapter:application');
        var url = adapter.namespace + '/cases/forms/default';

        this.store.peekAll('case-form').forEach(function (caseform) {
          caseform.set('isDefault', false);
        });
        caseform.set('isDefault', true);
        //TODO: this model is left dirty - it is not an issue,
        //but ideally we would mark this as clean.

        var options = {
          data: { form_id: caseform.get('id') }
        };

        adapter.ajax(url, 'PUT', options);
      },
      reorderForms: function reorderForms(orderedForms) {
        var adapter = getOwner(this).lookup('adapter:application');
        var url = adapter.namespace + '/cases/forms/reorder';

        var startingSortOrderNumber = 1;
        var orderedIds = orderedForms.map(function (form) {
          return form.id;
        });

        var sortOrder = startingSortOrderNumber;
        orderedForms.forEach(function (form) {
          form.set('sortOrder', sortOrder);
          sortOrder++;
        });

        var options = {
          data: { form_ids: orderedIds.toString() }
        };

        adapter.ajax(url, 'PUT', options);
      },
      toggleEnabledStatus: function toggleEnabledStatus(caseform, e) {
        e.stopPropagation();
        caseform.toggleProperty('isEnabled');
        caseform.save();
      },
      transitionToNewCaseFormRoute: function transitionToNewCaseFormRoute() {
        this.transitionToRoute('session.admin.manage.case-forms.new');
      },
      editForm: function editForm(form) {
        this.transitionToRoute('session.admin.manage.case-forms.edit', form.get('id'));
      },
      showDeleteConfirmation: function showDeleteConfirmation(form, e) {
        var _this = this;

        e.stopPropagation();

        this.get('confirmation').confirm({
          intlConfirmLabel: 'generic.confirm.delete_button',
          intlConfirmationBody: 'admin.caseforms.confirm_delete.body',
          intlConfirmationHeader: 'admin.caseforms.confirm_delete.title'
        }).then(function () {
          _this.send('deleteField', form);
        });
      },
      deleteField: function deleteField(form) {
        form.destroyRecord();
      }
    }
  });
});