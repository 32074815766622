define("frontend-cp/components/ko-info-bar/viewing-users/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-info-bar_viewing-users__container_1860ls",
    "avatars": "ko-info-bar_viewing-users__avatars_1860ls",
    "header": "ko-info-bar_viewing-users__header_1860ls",
    "avatar": "ko-info-bar_viewing-users__avatar_1860ls",
    "avatar-image": "ko-info-bar_viewing-users__avatar-image_1860ls",
    "inactive": "ko-info-bar_viewing-users__inactive_1860ls",
    "updating": "ko-info-bar_viewing-users__updating_1860ls",
    "spinner": "ko-info-bar_viewing-users__spinner_1860ls",
    "spinner-bounce": "ko-info-bar_viewing-users__spinner-bounce_1860ls",
    "sk-bouncedelay": "ko-info-bar_viewing-users__sk-bouncedelay_1860ls",
    "spinner-bounce1": "ko-info-bar_viewing-users__spinner-bounce1_1860ls",
    "spinner-bounce2": "ko-info-bar_viewing-users__spinner-bounce2_1860ls"
  };
});