define('frontend-cp/application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var observer = Ember.observer;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    urlService: service('url'),
    notificationService: service('notification'),
    plan: service(),
    config: service(),
    launchDarkly: service(),
    session: service(),

    appVersion: computed(function () {
      return this.get('config.currentRevision');
    }),

    activeVersion: computed('launchDarkly.app-version', function () {
      var version = this.get('launchDarkly.app-version');

      if (!version || version === 'undefined') {
        return null;
      }

      return version;
    }),

    notificationFrequency: computed('launchDarkly.app-version-notification-frequency', function () {
      var frequency = this.get('launchDarkly.app-version-notification-frequency');

      return this._parseFrequency(frequency);
    }),

    currentPathDidChange: observer('currentPath', function () {
      this.get('urlService').set('currentPath', this.get('currentPath'));
    }),

    /**
     * Active notifications
     * @return {Object[]} Array of notification objects
     */
    notifications: computed('notificationService.notifications.[]', function () {
      var notificationService = this.get('notificationService');
      return notificationService.get('notifications');
    }),

    grammarlyDetected: Ember.computed(function () {
      return document.body.dataset && document.body.dataset.grCSLoaded === 'true';
    }),

    actions: {
      onNotificationClosed: function onNotificationClosed(notification) {
        var notificationService = this.get('notificationService');
        notificationService.remove(notification);
      }
    },

    _parseFrequency: function _parseFrequency(val) {
      var defaultValue = 0;

      if (!val || val === 'undefined') {
        return defaultValue;
      }
      try {
        val = parseInt(val);

        if (isNaN(val)) {
          return defaultValue;
        }

        return val;
      } catch (e) {
        return defaultValue;
      }
    }
  });
});