define('frontend-cp/serializers/predicate-collection', ['exports', 'ember-data', 'frontend-cp/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      propositions: { serialize: 'records' }
    }
  });
});