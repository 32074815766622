define('frontend-cp/components/ko-admin/automation-actions-builder/options/component', ['exports', 'frontend-cp/components/ko-admin/automation-actions-builder/base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = _component.default.extend({
    i18n: service(),

    valuePlaceholder: computed('definition.name', function () {
      var name = this.get('definition.name');
      var i18n = this.get('i18n');
      var translationKey = 'admin.automation_actions_builder.placeholders.' + name;
      if (i18n.exists(translationKey)) {
        return i18n.t(translationKey);
      } else {
        return i18n.t('admin.automation_actions_builder.placeholders.default');
      }
    })
  });
});