define('frontend-cp/session/admin/people/teams/index/controller', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var on = Ember.on;
  exports.default = Controller.extend({
    storeCache: service(),
    confirmation: service(),

    // prime agents cache - makes loading team pages faster
    primeAgentCache: on('init', function () {
      this.get('storeCache').query('user', { role: 'collaborator' });
    }),

    filter: '',
    filteredResults: computed('model.@each.{title,isDeleted}', 'filter', function () {
      var teams = this.get('model');
      var filter = this.get('filter');
      var regEx = new RegExp(filter, 'i');

      teams = teams.filter(function (team) {
        return !team.get('isDeleted');
      });

      if (filter) {
        teams = teams.filter(function (team) {
          return regEx.test(team.get('title'));
        });
      }

      return teams;
    }),

    // Tasks

    deleteTeam: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(team) {
      var confirmation, confirmed;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              confirmation = this.get('confirmation');
              _context.next = 3;
              return confirmation.confirm({
                intlConfirmationHeader: 'admin.teams.confirm_delete.title',
                intlConfirmationBody: 'admin.teams.confirm_delete.body'
              });

            case 3:
              confirmed = _context.sent;

              if (!confirmed) {
                _context.next = 8;
                break;
              }

              _context.next = 7;
              return team.destroyRecord();

            case 7:
              return _context.abrupt('return', _context.sent);

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    // Actions

    actions: {
      transitionToAddNewTeam: function transitionToAddNewTeam() {
        this.transitionToRoute('session.admin.people.teams.new');
      },
      editTeam: function editTeam(team) {
        this.transitionToRoute('session.admin.people.teams.edit', team.get('id'));
      }
    }
  });
});