define("frontend-cp/components/ko-admin/billing/card/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "card-group": "ko-admin_billing_card__card-group_1mci5f",
    "group-title": "ko-admin_billing_card__group-title_1mci5f",
    "group-label": "ko-admin_billing_card__group-label_1mci5f",
    "group-body": "ko-admin_billing_card__group-body_1mci5f",
    "card": "ko-admin_billing_card__card_1mci5f",
    "about-card": "ko-admin_billing_card__about-card_1mci5f",
    "delete-handle": "ko-admin_billing_card__delete-handle_1mci5f",
    "card-type": "ko-admin_billing_card__card-type_1mci5f",
    "card-type-label": "ko-admin_billing_card__card-type-label_1mci5f",
    "isDefault": "ko-admin_billing_card__isDefault_1mci5f"
  };
});