define('frontend-cp/session/admin/apps/endpoints/schema', ['exports', 'frontend-cp/services/virtual-model'], function (exports, _virtualModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _virtualModel.model)('endpoint', {
    title: (0, _virtualModel.attr)(),
    requestMethod: (0, _virtualModel.attr)(),
    requestContentType: (0, _virtualModel.attr)(),

    webhookUsername: (0, _virtualModel.attr)(),
    webhookPassword: (0, _virtualModel.attr)(),
    webhookUseHttpAuth: (0, _virtualModel.attr)(),
    webhookUrl: (0, _virtualModel.attr)(),
    emailSubject: (0, _virtualModel.attr)(),
    emailDestination: (0, _virtualModel.attr)(),
    slackUrl: (0, _virtualModel.attr)()
  });
});