define("frontend-cp/components/ko-timeline-2/list/item/email-info-inline/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "cell": "ko-timeline-2_list_item_email-info-inline__cell_2p3z32",
    "left": "ko-timeline-2_list_item_email-info-inline__left_2p3z32",
    "grey": "ko-timeline-2_list_item_email-info-inline__grey_2p3z32",
    "mail-text": "ko-timeline-2_list_item_email-info-inline__mail-text_2p3z32",
    "to-text": "ko-timeline-2_list_item_email-info-inline__to-text_2p3z32",
    "email-item-cc": "ko-timeline-2_list_item_email-info-inline__email-item-cc_2p3z32",
    "email-item": "ko-timeline-2_list_item_email-info-inline__email-item_2p3z32",
    "no-comma": "ko-timeline-2_list_item_email-info-inline__no-comma_2p3z32",
    "email-item-content": "ko-timeline-2_list_item_email-info-inline__email-item-content_2p3z32",
    "shrink": "ko-timeline-2_list_item_email-info-inline__shrink_2p3z32",
    "email-item-solo": "ko-timeline-2_list_item_email-info-inline__email-item-solo_2p3z32",
    "others-text": "ko-timeline-2_list_item_email-info-inline__others-text_2p3z32",
    "details-chevron": "ko-timeline-2_list_item_email-info-inline__details-chevron_2p3z32",
    "active": "ko-timeline-2_list_item_email-info-inline__active_2p3z32",
    "chevron-info-box": "ko-timeline-2_list_item_email-info-inline__chevron-info-box_2p3z32",
    "top": "ko-timeline-2_list_item_email-info-inline__top_2p3z32",
    "cc-item": "ko-timeline-2_list_item_email-info-inline__cc-item_2p3z32",
    "sandbox-trigger": "ko-timeline-2_list_item_email-info-inline__sandbox-trigger_2p3z32",
    "hide": "ko-timeline-2_list_item_email-info-inline__hide_2p3z32"
  };
});