define('frontend-cp/services/api-adapter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    // Services
    store: service('store'),

    trashCase: function trashCase(caseId) {
      return this._updateCaseState(caseId, '/cases/' + caseId + '/trash');
    },
    restoreCase: function restoreCase(caseId) {
      return this._updateCaseState(caseId, '/cases/' + caseId + '/restore');
    },
    mergeCases: function mergeCases(target, mergeCandidates) {
      var adapter = getOwner(this).lookup('adapter:case');
      var requestUrl = adapter.namespace + ('/cases/' + target + '/merge');
      var candidates = mergeCandidates.toString();
      return adapter.ajax(requestUrl, 'POST', { data: { case_ids: candidates } });
    },
    _updateCaseState: function _updateCaseState(caseId, path) {
      var _this = this;

      var adapter = getOwner(this).lookup('adapter:case');
      var url = adapter.namespace + path;
      return adapter.ajax(url, 'put').then(function (response) {
        _this.get('store').pushPayload('case', {
          id: caseId,
          type: 'case',
          attributes: { state: response.data.state }
        });
      });
    }
  });
});