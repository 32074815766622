define('frontend-cp/models/twitter-tweet', ['exports', 'ember-data', 'frontend-cp/models/postable'], function (exports, _emberData, _postable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _postable.default.extend({
    uuid: _emberData.default.attr('string'),
    tweetId: _emberData.default.attr('string'),
    attachments: _emberData.default.hasMany('attachment', { async: false }),
    contents: _emberData.default.attr('string'),
    screenName: _emberData.default.attr('string'),
    downloadAll: _emberData.default.attr('string'),
    favoriteCount: _emberData.default.attr('number'),
    retweetCount: _emberData.default.attr('number'),
    fullName: _emberData.default.attr('string'),

    identity: _emberData.default.belongsTo('identity', { async: false }),
    inReplyToIdentity: _emberData.default.belongsTo('identity', { async: false }),
    inReplyToTweet: _emberData.default.belongsTo('twitter-tweet', { async: false }),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    postType: 'twitterTweet',
    isMessage: true
  });
});