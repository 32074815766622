define('frontend-cp/routes/abstract/tabbed-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    tabStore: service(),

    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.controller.set('tab', this.tab);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
    },


    // Actions
    actions: {
      subrouteDidRender: function subrouteDidRender(routeName) {
        if (this.tab) {
          this.get('tabStore').update(this.tab, { routeName: routeName });
        }
      }
    }
  });
});