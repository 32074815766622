define('frontend-cp/models/my-oauth-client', ['exports', 'ember-data-model-fragments', 'ember-data/attr'], function (exports, _emberDataModelFragments, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    name: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    author: (0, _attr.default)('string'),
    author_url: (0, _attr.default)('string'),
    logo: _emberDataModelFragments.default.fragment('my-oauth-client-logo', { async: false })
  });
});