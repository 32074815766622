define('frontend-cp/session/admin/apps/endpoints/index/details/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      onClose: function onClose() {
        this.transitionToRoute('session.admin.apps.endpoints');
      }
    }
  });
});