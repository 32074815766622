define("frontend-cp/components/ko-insights/actor/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "actorContainer": "ko-insights_actor__actorContainer_k1yzrs",
    "actorDropdownContainer": "ko-insights_actor__actorDropdownContainer_k1yzrs",
    "avatar": "ko-insights_actor__avatar_k1yzrs",
    "actorDropdown": "ko-insights_actor__actorDropdown_k1yzrs",
    "actorDescription": "ko-insights_actor__actorDescription_k1yzrs"
  };
});