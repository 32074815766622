define('frontend-cp/components/ko-notification-center/notification-case-updated/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    notification: null,
    close: function close() {},

    isBreach: computed.equal('notification.activity.verb', 'BREACH'),
    avatarUrl: computed.readOnly('notification.activity.actor.image'),
    summary: computed.reads('notification.activity.summary')
  });
});