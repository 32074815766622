define('frontend-cp/utils/lighten-darken-color', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.LightenDarkenColor = LightenDarkenColor;
  function LightenDarkenColor(col, amt) {

    var usePound = false;

    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    if (col.length === 3) {
      var newCol = '';
      for (var i = 0; i < col.length; i++) {
        newCol += col[i].repeat(2);
      }

      col = newCol;
    } else if (col.length !== 6) {
      return '#000000';
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;else if (r < 0) r = 0;

    var b = (num >> 8 & 0x00FF) + amt;

    if (b > 255) b = 255;else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | b << 8 | r << 16).toString(16);
  }
});