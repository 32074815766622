define("frontend-cp/components/ko-simple-list/row/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "row": "ko-simple-list_row__row_8ci8kt",
    "row--actionable": "ko-simple-list_row__row--actionable_8ci8kt"
  };
});