define('frontend-cp/components/ko-date-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    value: null,
    placeholder: null,
    onChange: null,

    actions: {
      setDate: function setDate(close, date) {
        close();
        if (this.get('onChange')) {
          this.get('onChange')(date);
        }
      }
    }
  });
});