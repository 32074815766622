define('frontend-cp/services/error-handler/resource-not-found-strategy', ['exports', 'frontend-cp/utils/base-path'], function (exports, _basePath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    notification: service(),
    i18n: service(),
    router: service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.records = [];
    },
    accept: function accept(record) {
      this.records.push(record);
    },
    transitionTo: function transitionTo(path) {
      this.get('router').transitionTo(path);
    },
    process: function process() {
      var recordsCount = this.records.length;

      if (recordsCount) {
        this.get('notification').add({
          type: 'error',
          title: this.get('i18n').t('generic.resource_not_found'),
          autodismiss: true,
          dismissable: true
        });
        var path = (0, _basePath.getBasePath)();
        var pathname = location.pathname;
        if (pathname !== path) {
          this.transitionTo(path);
        }

        this.records = [];
      }

      return recordsCount;
    }
  });
});