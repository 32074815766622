define("frontend-cp/components/ko-notification-center-new/notification-case-updated/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "avatar-container": "ko-notification-center-new_notification-case-updated__avatar-container_1jo3xp",
    "for-breach": "ko-notification-center-new_notification-case-updated__for-breach_1jo3xp",
    "avatar": "ko-notification-center-new_notification-case-updated__avatar_1jo3xp"
  };
});