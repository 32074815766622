define('frontend-cp/components/ko-toast/component', ['exports', 'frontend-cp/config/environment', 'frontend-cp/components/ko-toast/styles'], function (exports, _environment, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var later = Ember.run.later;
  var cancel = Ember.run.cancel;
  var RSVP = Ember.RSVP;


  var ALERT_TYPE_INFO = 'info';
  var ALERT_TYPE_WARNING = 'warning';
  var ALERT_TYPE_ERROR = 'error';
  var ALERT_TYPE_SUCCESS = 'success';

  var AUTODISMISS_TIMEOUT = 3000;

  exports.default = Component.extend({
    //Attributes
    type: null,
    dismissable: false,
    autodismiss: false,
    isClosing: false,
    href: null,
    hrefText: null,
    hrefTarget: '_blank',
    linkToRoute: null,
    linkToText: null,

    // HTML
    localClassNames: 'toast',

    // Services
    i18n: service(),

    // Private properties
    _animateOutToken: null,

    // CPs
    linkText: computed('hrefText', function () {
      var customText = this.get('hrefText');
      return customText || this.get('i18n').t('generic.find_out_more');
    }),

    iconClass: computed('type', function () {
      switch (this.get('type')) {
        case ALERT_TYPE_INFO:
          return 'i-info';
        case ALERT_TYPE_WARNING:
          return 'i-caution-solid';
        case ALERT_TYPE_ERROR:
          return 'i-danger-solid';
        case ALERT_TYPE_SUCCESS:
          return 'i-tick';
        default:
          return null;
      }
    }),

    typeClass: computed('type', function () {
      return _styles.default[this.get('type')];
    }),

    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this.animateIn();
      this.initAutoDismiss();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this._animateOutToken) {
        cancel(this._animateOutToken);
      }
    },
    initAutoDismiss: function initAutoDismiss() {
      // always leave toasts in when testing so we can check they appear
      // otherwise we get intermittent failures if we check for a toast
      // but it has been removed in the meantime
      if (_environment.default.environment === 'test') {
        return;
      }

      if (!this.get('autodismiss')) {
        return;
      }

      this._animateOutToken = later(this, 'animateOut', AUTODISMISS_TIMEOUT);
    },
    click: function click() {
      if (this.get('autodismiss')) {
        this.animateOut();
      }
    },
    animateIn: function animateIn() {
      animate(this.$(), _styles.default.addModifier, _styles.default.addModifierActive);
    },
    animateOut: function animateOut() {
      var _this = this;

      if (this.get('isClosing')) {
        return;
      }

      this.set('isClosing', true);

      var $componentElement = this.$();
      var componentHeight = $componentElement.outerHeight();
      animate($componentElement, _styles.default.removeModifier, _styles.default.removeModifierActive, {
        clamp: true
      }).then(function () {
        $componentElement.hide();
        _this.sendAction('close');
      });
      $componentElement.css('margin-bottom', -componentHeight + 'px');
    },


    actions: {
      onCloseClicked: function onCloseClicked() {
        this.animateOut();
      }
    }
  });


  function animate($element, className, classNameActive) {
    var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
        _ref$clamp = _ref.clamp,
        clamp = _ref$clamp === undefined ? false : _ref$clamp;

    return new RSVP.Promise(function (resolve) {
      $element.addClass(className);
      forceRender($element);
      $element.one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function () {
        if (!clamp) {
          $element.removeClass(className + ' ' + classNameActive);
        }
        resolve();
      });
      $element.addClass(classNameActive);
    });

    function forceRender($element) {
      $element.outerHeight();
      return $element;
    }
  }
});