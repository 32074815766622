define('frontend-cp/session/agent/users/new/index/route', ['exports', 'frontend-cp/routes/abstract/tabbed-route-child'], function (exports, _tabbedRouteChild) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tabbedRouteChild.default.extend({
    queryParams: {
      postId: { replace: true },
      filter: { replace: true }
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var parentController = this.controllerFor('session.agent.users.new');
      controller.set('state', parentController.get('state'));
    }
  });
});