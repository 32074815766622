define('frontend-cp/session/admin/customizations/organization-fields/select-type/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      setUserFieldType: function setUserFieldType(fieldType) {
        this.transitionToRoute('session.admin.customizations.organization-fields.new', fieldType);
      },
      transitionToIndexRoute: function transitionToIndexRoute() {
        this.transitionToRoute('session.admin.customizations.organization-fields.index');
      }
    }
  });
});