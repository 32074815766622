define("frontend-cp/components/ko-impersonation/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "inputFieldHeight": "50px",
    "fieldHeight": "80px",
    "smoothTransition": "cubic-bezier(0.19, 1, 0.22, 1)",
    "mutedText": "#797e82",
    "impersonate": "ko-impersonation__impersonate_1ogjei",
    "fade-in": "ko-impersonation__fade-in_1ogjei",
    "section": "ko-impersonation__section_1ogjei",
    "box": "ko-impersonation__box_1ogjei",
    "container": "ko-impersonation__container_1ogjei",
    "front": "ko-impersonation__front_1ogjei",
    "back": "ko-impersonation__back_1ogjei",
    "flipped": "ko-impersonation__flipped_1ogjei",
    "image": "ko-impersonation__image_1ogjei",
    "header": "ko-impersonation__header_1ogjei",
    "sub-header": "ko-impersonation__sub-header_1ogjei",
    "form-content": "ko-impersonation__form-content_1ogjei",
    "form-wrapper": "ko-impersonation__form-wrapper_1ogjei",
    "addon": "ko-impersonation__addon_1ogjei",
    "button": "ko-impersonation__button_1ogjei",
    "url": "ko-impersonation__url_1ogjei"
  };
});