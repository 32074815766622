define('frontend-cp/lib/object-difference', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var oldValues = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var newValues = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (Object.keys(newValues).length > Object.keys(oldValues).length) {
      var temp = oldValues;
      oldValues = newValues;
      newValues = temp;
    }

    return _npmLodash.default.reduce(oldValues, function (result, value, key) {
      return _npmLodash.default.isEqual(value, newValues[key]) ? result : result.concat(key);
    }, []);
  };
});