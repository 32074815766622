define('frontend-cp/lib/animation', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.raf = raf;
  var EmberObject = Ember.Object;
  var RSVP = Ember.RSVP;
  function raf() {
    return new RSVP.Promise(function (resolve, reject) {
      window.requestAnimationFrame(resolve);
    });
  }

  exports.default = EmberObject.extend({
    animate: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(cb) {
      var startTime, nextTime;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return raf();

            case 2:
              startTime = _context.sent;
              nextTime = startTime;

            case 4:
              if (!true) {
                _context.next = 12;
                break;
              }

              if (!(cb(nextTime - startTime) === false)) {
                _context.next = 7;
                break;
              }

              return _context.abrupt('return');

            case 7:
              _context.next = 9;
              return raf();

            case 9:
              nextTime = _context.sent;
              _context.next = 4;
              break;

            case 12:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }))
  }).create();
});