define('frontend-cp/models/category', ['exports', 'ember-data', 'frontend-cp/utils/locale'], function (exports, _emberData, _locale) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    brand: belongsTo('brand', { async: false }),
    description: attr('string'),
    displayOrder: attr('number'),
    titles: hasMany('locale-field', { async: false }),
    slug: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),

    locale: service(),
    session: service(),
    titleLocale: computed('titles.[]', 'locale.accountDefaultLocaleCode', 'session.user.locale.locale', function () {
      return (0, _locale.findLocaleFieldBySettings)(this.get('titles'), this.get('locale'), this.get('session'));
    }),
    title: computed.readOnly('titleLocale.translation')
  });
});