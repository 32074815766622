define("frontend-cp/components/ko-tabs/user/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "disabled": "ko-tabs_user__disabled_jvnahf",
    "set-organization": "ko-tabs_user__set-organization_jvnahf",
    "setting-organization": "ko-tabs_user__setting-organization_jvnahf",
    "hidden": "ko-tabs_user__hidden_jvnahf",
    "tab-loader": "ko-tabs_user__tab-loader_jvnahf"
  };
});