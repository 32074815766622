define("frontend-cp/components/ko-bulk-invitation/recipient/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "layout": "ko-bulk-invitation_recipient__layout_10cjk2",
    "item": "ko-bulk-invitation_recipient__item_10cjk2"
  };
});