define("frontend-cp/components/apps-dev-mode-ribbon/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "ribbon": "apps-dev-mode-ribbon__ribbon_1mgy7e",
    "button": "apps-dev-mode-ribbon__button_1mgy7e"
  };
});