define("frontend-cp/session/admin/apps/salesforce/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "header": "session_admin_apps_salesforce__header_z04gzu",
    "content": "session_admin_apps_salesforce__content_z04gzu",
    "title": "session_admin_apps_salesforce__title_z04gzu",
    "list": "session_admin_apps_salesforce__list_z04gzu",
    "list__item": "session_admin_apps_salesforce__list__item_z04gzu",
    "list__item__title": "session_admin_apps_salesforce__list__item__title_z04gzu",
    "list__item__description": "session_admin_apps_salesforce__list__item__description_z04gzu",
    "buttons": "session_admin_apps_salesforce__buttons_z04gzu"
  };
});