define('frontend-cp/session/admin/automation/macros/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var MACROS_LIMIT = exports.MACROS_LIMIT = 50;

  exports.default = Route.extend({
    queryParams: {
      page: { refreshModel: true }
    },

    model: function model(_ref) {
      var page = _ref.page;

      var offset = (parseInt(page) - 1) * MACROS_LIMIT;
      return this.get('store').query('macro', { show_all: true, offset: offset, limit: MACROS_LIMIT });
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (transition.queryParamsOnly) {
          this.controller.set('isLoadingMacros', true);
        }
      },
      didTransition: function didTransition() {
        this.controller.set('isLoadingMacros', false);
      }
    }
  });
});