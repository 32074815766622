define('frontend-cp/session/admin/customizations/brands/edit/index/route', ['exports', 'frontend-cp/mixins/dirty-aware/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend((0, _route.default)(), {
    model: function model(params) {
      return RSVP.hash({
        brand: this.modelFor('session.admin.customizations.brands.edit'),
        locales: this.store.findAll('locale')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
      controller.initEdits();
    }
  });
});