define("frontend-cp/components/ko-agent-content/action-menu/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "trigger": "ko-agent-content_action-menu__trigger_23b3cr ko-power-select-triggers_chevron__trigger_71zyep",
    "dropdown-body": "ko-agent-content_action-menu__dropdown-body_23b3cr"
  };
});