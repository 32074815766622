define('frontend-cp/services/search-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;


  var SEARCH_NAMESPACE = 'search';
  var RECENT_SEARCH_KEY = 'recent_searches';

  exports.default = Service.extend({
    _recentSearches: null,

    localStore: service(),

    init: function init() {
      var oldSessionSearches = this.get('localStore').getItem(SEARCH_NAMESPACE, RECENT_SEARCH_KEY);
      this.set('_recentSearches', oldSessionSearches || []);
    },
    logSearch: function logSearch(searchTerm) {
      var recentSearches = this.get('_recentSearches');

      recentSearches.removeObject(searchTerm);
      recentSearches.pushObject(searchTerm);

      if (recentSearches.get('length') > 5) {
        recentSearches.removeAt(0);
      }

      this.get('localStore').setItem(SEARCH_NAMESPACE, RECENT_SEARCH_KEY, recentSearches);
    },


    recentSearches: computed('_recentSearches.[]', function () {
      return this.get('_recentSearches').slice(0).reverse();
    })
  });
});