define('frontend-cp/serializers/note', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      createdAt: { serialize: false },
      updatedAt: { serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      json.is_html = true;
      return json;
    }
  });
});