define('frontend-cp/adapters/definition-improved-contract', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      var type = query.type;
      Reflect.deleteProperty(query, 'type');

      switch (type) {
        case 'view':
          return this.namespace + '/views/definitions';
        case 'sla':
          return this.namespace + '/slas/definitions';
        case 'monitor':
          return this.namespace + '/monitors/definitions';
        case 'trigger':
          return this.namespace + '/triggers/definitions';
        case 'engagement':
          return this.namespace + '/engagements/definitions';
        case 'report':
          return this.namespace + '/reports/definitions';
        case 'user':
          return this.namespace + '/users/definitions';
        case 'organization':
          return this.namespace + '/organizations/definitions';
      }
    }
  });
});