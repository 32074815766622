define("frontend-cp/components/ko-admin/messenger-persistent/customize/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "label": "ko-admin_messenger-persistent_customize__label_4yzt5k",
    "localized-field": "ko-admin_messenger-persistent_customize__localized-field_4yzt5k",
    "locale-input": "ko-admin_messenger-persistent_customize__locale-input_4yzt5k",
    "background-mode": "ko-admin_messenger-persistent_customize__background-mode_4yzt5k",
    "is-active": "ko-admin_messenger-persistent_customize__is-active_4yzt5k",
    "color-palletes": "ko-admin_messenger-persistent_customize__color-palletes_4yzt5k",
    "show-colors": "ko-admin_messenger-persistent_customize__show-colors_4yzt5k",
    "color-palletes__wrap": "ko-admin_messenger-persistent_customize__color-palletes__wrap_4yzt5k",
    "reply-time-locale": "ko-admin_messenger-persistent_customize__reply-time-locale_4yzt5k",
    "radio-label-detail": "ko-admin_messenger-persistent_customize__radio-label-detail_4yzt5k",
    "loader": "ko-admin_messenger-persistent_customize__loader_4yzt5k",
    "pallete": "ko-admin_messenger-persistent_customize__pallete_4yzt5k",
    "pallete-press": "ko-admin_messenger-persistent_customize__pallete-press_4yzt5k",
    "scale-up": "ko-admin_messenger-persistent_customize__scale-up_4yzt5k",
    "solid-color": "ko-admin_messenger-persistent_customize__solid-color_4yzt5k",
    "gradient": "ko-admin_messenger-persistent_customize__gradient_4yzt5k",
    "patterns": "ko-admin_messenger-persistent_customize__patterns_4yzt5k",
    "pattern": "ko-admin_messenger-persistent_customize__pattern_4yzt5k",
    "is-active-pattern": "ko-admin_messenger-persistent_customize__is-active-pattern_4yzt5k",
    "color-input-container": "ko-admin_messenger-persistent_customize__color-input-container_4yzt5k",
    "color-input": "ko-admin_messenger-persistent_customize__color-input_4yzt5k",
    "text--small": "ko-admin_messenger-persistent_customize__text--small_4yzt5k",
    "color-selector--background": "ko-admin_messenger-persistent_customize__color-selector--background_4yzt5k",
    "color-selector--primary": "ko-admin_messenger-persistent_customize__color-selector--primary_4yzt5k",
    "mtop--tiny": "ko-admin_messenger-persistent_customize__mtop--tiny_4yzt5k",
    "nudge-upward": "ko-admin_messenger-persistent_customize__nudge-upward_4yzt5k"
  };
});