define('frontend-cp/session/agent/insights/general/teams/route', ['exports', 'ember-service/inject', 'frontend-cp/lib/get-date-range-at'], function (exports, _inject, _getDateRangeAt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    launchDarkly: (0, _inject.default)(),

    session: service('session'),
    insights: service(),
    metrics: service(),
    plan: service(),

    queryParams: {
      team: { refreshModel: true },
      sla: { refreshModel: true }
    },

    model: function model(params) {
      var user = this.get('session.user');
      var insights = this.get('insights');

      var teamId = params.team ? params.team : user.get('teams.firstObject.id');

      var insightsParams = this.paramsFor('session.agent.insights');
      var startAt = insightsParams.startAt ? insightsParams.startAt : (0, _getDateRangeAt.monthFormat)(new Date(new Date().getTime() - 86400000 * 30));
      var endAt = insightsParams.endAt ? insightsParams.endAt : (0, _getDateRangeAt.monthFormat)(new Date());
      var interval = insightsParams.interval ? insightsParams.interval : 'DAY';
      var sla = params.sla ? params.sla : null;

      var _insights$getPrevious = insights.getPreviousDates(startAt, endAt),
          previousStart = _insights$getPrevious.previousStart,
          previousEnd = _insights$getPrevious.previousEnd;

      var metricsQueryParams = {
        data: {
          team_id: teamId,
          start_at: startAt + 'T00:00:00.000Z',
          end_at: endAt + 'T23:59:59.999Z',
          previous_start_at: previousStart + 'T00:00:00.000Z',
          previous_end_at: previousEnd + 'T23:59:59.999Z',
          interval: interval,
          trial: insights.isTrialMode() && insightsParams.trial
        }
      };

      return RSVP.hash({
        teamId: teamId,
        startAt: startAt,
        endAt: endAt,
        interval: interval,
        slaId: sla,
        metricsQueryParams: metricsQueryParams
      });
    },
    setupController: function setupController(controller, data) {
      var _this = this;

      var insights = this.get('insights');

      this.get('controller').setProperties({
        team: data.teamId,
        sla: data.slaId,
        startAt: data.startAt,
        endAt: data.endAt,
        interval: data.interval,
        metricsQueryParams: data.metricsQueryParams
      });

      if (insights.isTrialMode()) {
        insights.pushTrialNotification(function () {
          _this.transitionTo({ queryParams: { trial: false } });
        });
      }
    },
    beforeModel: function beforeModel(transition) {
      var launchDarkly = this.get('launchDarkly');
      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('release-restrict-insights')) {
        if (!this.get('plan').has('agent_team_insights') && !launchDarkly.get('feature-restrict-agent-team-insights')) {
          this.transitionTo('session.agent.insights.general.cases');
        }
      }
    },


    actions: {
      didTransition: function didTransition() {
        var launchDarkly = this.get('launchDarkly');

        if (launchDarkly.get('release-event-tracking')) {
          this.get('metrics').trackEvent({
            event: 'teams_insights_viewed'
          });
        }
      }
    }
  });
});