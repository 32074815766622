define('frontend-cp/session/admin/apps/endpoints/select-type/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    i18n: service(),
    endpoints: service(),

    _t: function _t(key) {
      return this.get('i18n').t(key);
    },


    title: computed(function () {
      var breadcrumbs = [this._t('admin.apps.endpoints.title'), this._t('admin.apps.endpoints.headings.new')];

      return breadcrumbs.join(' / ');
    }),

    actions: {
      setType: function setType(type) {
        this.transitionToRoute('session.admin.apps.endpoints.new', type);
      },
      canceled: function canceled() {
        this.transitionToRoute('session.admin.apps.endpoints.index');
      }
    }
  });
});