define('frontend-cp/components/ko-notification-center/-base-notification/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',
    notificationCenter: service(),

    notification: null,
    close: function close() {},

    isRead: computed.equal('notification.readState', 'READ'),
    url: computed('notification', function () {
      var objectUrl = this.get('notification.activity.object.url') || '';
      var notificationId = this.get('notification.id');

      objectUrl = objectUrl.replace(/case/g, 'conversation');

      return objectUrl + '?notificationId=' + notificationId;
    }),

    markAsRead: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var notificationId;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              notificationId = this.get('notification.id');
              _context.next = 3;
              return this.get('notificationCenter').markAsRead(notificationId);

            case 3:
              return _context.abrupt('return', _context.sent);

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    actions: {
      markAsRead: function markAsRead(event) {
        event.preventDefault();
        event.stopPropagation();
        this.get('markAsRead').perform();
      }
    }
  });
});