define('frontend-cp/locales/en-us', ['exports', 'frontend-cp/locales/en-us/account', 'frontend-cp/locales/en-us/admin', 'frontend-cp/locales/en-us/billing', 'frontend-cp/locales/en-us/cases', 'frontend-cp/locales/en-us/generic', 'frontend-cp/locales/en-us/insights', 'frontend-cp/locales/en-us/login', 'frontend-cp/locales/en-us/organization', 'frontend-cp/locales/en-us/search', 'frontend-cp/locales/en-us/timeline', 'frontend-cp/locales/en-us/users', 'frontend-cp/locales/en-us/welcome', 'frontend-cp/locales/en-us/notifications', 'frontend-cp/locales/en-us/onboarding'], function (exports, _account, _admin, _billing, _cases, _generic, _insights, _login, _organization, _search, _timeline, _users, _welcome, _notifications, _onboarding) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    account: _account.default,
    admin: _admin.default,
    billing: _billing.default,
    cases: _cases.default,
    generic: _generic.default,
    insights: _insights.default,
    login: _login.default,
    organization: _organization.default,
    search: _search.default,
    timeline: _timeline.default,
    users: _users.default,
    welcome: _welcome.default,
    notifications: _notifications.default,
    onboarding: _onboarding.default
  };
});