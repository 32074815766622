define('frontend-cp/components/ko-agent-dropdown/create-user/component', ['exports', 'frontend-cp/utils/format-validations'], function (exports, _formatValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    // params
    onCreate: function onCreate() {},
    onCancel: function onCancel() {},

    fields: null,
    shouldShowValidationErrorUser: false,
    shouldShowValidationErrorsEmail: false,
    fullNameValue: '',
    emailValue: '',

    i18n: service(),
    notificationService: service('notification'),
    store: service(),

    fullNameError: computed('fullNameValue', function () {
      var fullName = this.get('fullNameValue');

      if (fullName) {
        return null;
      }

      return this.get('i18n').t('generic.create_user_panel.name_required');
    }),

    emailError: computed('emailValue', function () {
      var email = this.get('emailValue');

      if (!email || (0, _formatValidations.validateEmailFormat)(email)) {
        return null;
      }

      return this.get('i18n').t('generic.create_user_panel.email_invalid');
    }),

    actions: {
      onError: function onError(error) {
        if (error.errors.findBy('code', 'FIELD_DUPLICATE')) {
          var i18n = this.get('i18n');
          this.get('notificationService').removeAll();
          this.get('notificationService').add({
            type: 'error',
            title: i18n.t('generic.create_user_panel.user_exists_toast'),
            autodismiss: true,
            dismissable: false
          });
          this.set('fields.email.errors', [{
            message: i18n.t('generic.create_user_panel.user_exists')
          }]);
        }
      },
      handleFocusOut: function handleFocusOut(flag, event) {
        if ($(event.relatedTarget).is('[data-purpose="cancel"]')) {
          return;
        }

        this.set(flag, true);
      },
      submit: function submit() {
        var _this = this;

        var store = this.get('store');
        var onSubmit = this.get('onSubmit');
        if (onSubmit) {
          onSubmit();
        }
        var savePromise = store.findRecord('role', 4).then(function (roleModel) {
          var email = store.createRecord('identity-email', {
            isPrimary: true,
            email: _this.get('emailValue')
          });
          return store.createRecord('user', {
            role: roleModel,
            fullName: _this.get('fullNameValue'),
            emails: [email]
          }).save();
        });

        savePromise.then(function () {
          return _this.get('dropdown.actions').close();
        });

        return savePromise;
      }
    }
  });
});