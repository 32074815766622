define("frontend-cp/components/ko-universal-search/title/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "titleBlock": "ko-universal-search_title__titleBlock_80itig",
    "title": "ko-universal-search_title__title_80itig",
    "titleLine": "ko-universal-search_title__titleLine_80itig"
  };
});