define('frontend-cp/components/ko-layout/popup-with-arrow/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var htmlSafe = Ember.String.htmlSafe;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',
    width: null,

    additionalStyles: computed('width', function () {
      if (this.get('width')) {
        return htmlSafe('min-width: ' + this.get('width') + 'px;');
      }
    })
  });
});