define('frontend-cp/mixins/with-unique-id', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var guidFor = Ember.guidFor;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.uniqueId = guidFor(this);
    }
  });
});