define('frontend-cp/services/tab-store', ['exports', 'frontend-cp/utils/bugsnag', 'frontend-cp/config/environment'], function (exports, _bugsnag, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var debounce = Ember.run.debounce;
  var get = Ember.get;
  var getProperties = Ember.getProperties;
  var set = Ember.set;
  var setProperties = Ember.setProperties;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({
    routing: service('-routing'),
    localStore: service('localStore'),
    confirmation: service(),
    processManager: service(),

    casesViewId: null,
    caseState: null,
    insertTabAtIndex: null,
    activeTab: null,

    //CPs
    tabs: computed(function () {
      var _this = this;

      var tabs = this.get('localStore').getItem(_environment.default.localStore.defaultNamespace, 'tabs') || [];
      return tabs.map(function (tab) {
        return _this.deserializeTab(tab);
      }).compact();
    }),

    //Public API
    setCasesViewId: function setCasesViewId(viewId) {
      this.set('casesViewId', viewId);
    },
    transitionAndInsertTabNextToActiveTab: function transitionAndInsertTabNextToActiveTab() {
      var _this2 = this;

      var router = this.get('routing');

      this.set('insertTabAtIndex', this.get('tabs').indexOf(this.get('activeTab')) + 1);
      router.transitionTo.apply(router, arguments).finally(function () {
        return _this2.set('insertTabAtIndex', null);
      });
    },
    selectPreviousTab: function selectPreviousTab() {
      var previousTab = this.previousTab();
      if (previousTab) {
        this.selectTab(previousTab);
      }
    },
    selectNextTab: function selectNextTab() {
      var nextTab = this.nextTab();
      if (nextTab) {
        this.selectTab(nextTab);
      }
    },
    open: function open(transition) {
      var _decomposeTransition = this.decomposeTransition(transition),
          basePath = _decomposeTransition.basePath,
          routeName = _decomposeTransition.routeName,
          dynamicSegments = _decomposeTransition.dynamicSegments,
          queryParams = _decomposeTransition.queryParams;

      var tabs = this.get('tabs');

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = tabs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _tab = _step.value;

          if (this._compareTabAndTargetTabBasePath(_tab.basePath, basePath)) {
            this.set('activeTab', _tab);
            return _tab;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var tab = this._buildTab({ basePath: basePath, routeName: routeName, dynamicSegments: dynamicSegments, queryParams: queryParams });

      tab.linkParams = [routeName].concat(_toConsumableArray(dynamicSegments));
      if (queryParams) {
        tab.linkParams.push(this._buildQueryParams(queryParams));
      }
      if (this.get('insertTabAtIndex') !== null) {
        this.get('tabs').insertAt(this.get('insertTabAtIndex'), tab);
      } else {
        this.get('tabs').addObject(tab);
      }

      this.set('activeTab', tab);
      this.schedulePersistTabs();
      return tab;
    },
    createTab: function createTab(routeName, model, queryParams) {
      if (model.get('_isFullyLoaded') === false) {
        model.reload();
      }
      var processManager = this.get('processManager');
      var process = processManager.getOrCreateProcess(model, model.constructor.modelName);

      var dynamicSegments = [get(model, 'id')];
      var basePath = this.get('routing').generateURL(routeName, dynamicSegments);
      var tabs = this.get('tabs');

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = tabs[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _tab2 = _step2.value;

          if (this._compareTabAndTargetTabBasePath(_tab2.basePath, basePath)) {
            return _tab2;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      var tab = this._buildTab({
        process: process,
        basePath: basePath,
        routeName: routeName,
        dynamicSegments: dynamicSegments,
        queryParams: queryParams,
        linkParams: [routeName].concat(dynamicSegments)
      });
      if (queryParams) {
        tab.linkParams.push(this._buildQueryParams(queryParams));
      }

      this.get('tabs').addObject(tab);
      this.schedulePersistTabs();
      return tab;
    },
    createTabNextToActiveTab: function createTabNextToActiveTab() {
      this.set('insertTabAtIndex', this.get('tabs').indexOf(this.get('activeTab')) + 1);
      this.createTab.apply(this, arguments);
      this.set('insertTabAtIndex', null);
    },
    getTab: function getTab(basePath) {
      return this.get('tabs').findBy('basePath', basePath);
    },
    close: function close(tab) {
      var _this3 = this;

      var discardChanges = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var isEdited = get(tab, 'state.case._isEdited') || get(tab, 'state.user._isEdited') || get(tab, 'state.organization._isEdited');

      if (isEdited && !discardChanges) {
        this.get('confirmation').confirm({
          intlConfirmationBody: 'generic.unsaved_tab_changes',
          intlConfirmLabel: 'generic.continue_button'
        }).then(function () {
          _this3._closeTab(tab);
        });
      } else {
        this._closeTab(tab);
      }
    },
    closeActiveTab: function closeActiveTab() {
      var discardChanges = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var active = this.get('activeTab');
      if (!active) {
        return null;
      }
      return this.close(active, discardChanges);
    },
    leave: function leave(tab) {
      if (this.get('activeTab') === tab) {
        this.set('activeTab', null);
      }
    },
    update: function update(tab, changes) {
      setProperties(tab, changes);

      var _getProperties = getProperties(tab, 'routeName', 'dynamicSegments'),
          routeName = _getProperties.routeName,
          dynamicSegments = _getProperties.dynamicSegments;

      set(tab, 'linkParams', [routeName].concat(_toConsumableArray(dynamicSegments)));

      this.schedulePersistTabs();
    },
    updateState: function updateState(tab, newState) {
      tab.state = Object.assign(tab.state || {}, newState);
      this.schedulePersistTabs();
    },
    clearAll: function clearAll() {
      this.get('tabs').clear();
      this.persistTabs();
    },


    //Private API

    previousTab: function previousTab(tab) {
      var wrap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var tabs = this.get('tabs');
      if (tabs.length <= 1) {
        return null;
      }

      var activeTab = tab || this.get('activeTab');
      var activeIndex = tabs.indexOf(activeTab);

      var prevIndex = activeIndex - 1;
      if (prevIndex < 0) {
        if (!wrap) {
          return null;
        }
        prevIndex = tabs.length - 1;
      }

      return tabs[prevIndex];
    },
    nextTab: function nextTab(tab) {
      var wrap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var tabs = this.get('tabs');
      if (tabs.length <= 1) {
        return null;
      }

      var activeTab = tab || this.get('activeTab');
      var activeIndex = tabs.indexOf(activeTab);

      var nextIndex = activeIndex + 1;
      if (nextIndex >= tabs.length) {
        if (!wrap) {
          return null;
        }
        nextIndex = 0;
      }

      return tabs[nextIndex];
    },
    selectTab: function selectTab(tab) {
      var routing = this.get('routing');
      routing.transitionTo(tab.routeName, tab.dynamicSegments, tab.queryParams || {});
    },
    _closeTab: function _closeTab(tab) {
      this._destroyProcess(tab);

      var routing = this.get('routing');
      var tabs = this.get('tabs');
      if (this.get('activeTab') !== tab) {
        tab.onClose();
        tabs.removeObject(tab);
      } else {
        var nextTab = this.nextTab(tab, false) || this.previousTab(tab, false);

        tab.onClose();
        tabs.removeObject(tab);

        if (tab.queryParams && tab.queryParams.returnTo) {
          routing.transitionTo(tab.queryParams.returnTo);
        } else if (nextTab) {
          this.selectTab(nextTab);
        } else {
          if (this.get('casesViewId')) {
            routing.transitionTo('session.agent.cases.index.view', [this.get('casesViewId')], {});
          } else {
            routing.transitionTo('session.agent.cases.index');
          }
        }
      }
      this.schedulePersistTabs();
    },
    schedulePersistTabs: function schedulePersistTabs() {
      debounce(this, 'persistTabs', 100);
    },
    persistTabs: function persistTabs() {
      var tabs = this.get('tabs') || [];

      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      this.get('localStore').setItem(_environment.default.localStore.defaultNamespace, 'tabs', tabs.map(this.serializeTab).compact());
    },
    decomposeTransition: function decomposeTransition(transition) {
      var routeName = transition.handlerInfos[transition.resolveIndex].name;
      var queryParams = Object.keys(transition.queryParams).length > 0 ? transition.queryParams : null;
      // The dynamic segments are wrong. It should include all the dynamic segments
      // of all parent routes, not only the ones of this one.
      var dynamicSegments = transition.intent.contexts;
      if (!dynamicSegments || !dynamicSegments.length) {
        dynamicSegments = Object.keys(transition.params).reduce(function (ary, k) {
          var params = transition.params[k];
          if (Object.keys(params).length > 0) {
            return ary.concat(Object.keys(params).map(function (k) {
              return params[k];
            }));
          } else {
            return ary;
          }
        }, []).compact();
      }
      var url = this.get('routing').generateURL(routeName, dynamicSegments, queryParams || {});

      var _url$split = url.split('?'),
          _url$split2 = _slicedToArray(_url$split, 1),
          basePath = _url$split2[0];

      return { basePath: basePath, routeName: routeName, dynamicSegments: dynamicSegments, queryParams: queryParams };
    },
    serializeTab: function serializeTab(tab) {
      if (!tab.process) {
        // tabs are often constructed in two phases, the tab opened & the process set later
        // don't store if we're half-way through, we'll get called again in a moment…
        return null;
      }

      var json = {
        basePath: tab.basePath,
        routeName: tab.routeName,
        dynamicSegments: tab.dynamicSegments.map(function (ds) {
          return ds && (typeof ds === 'undefined' ? 'undefined' : _typeof(ds)) === 'object' ? ds.id : ds;
        }),
        queryParams: tab.queryParams,
        state: tab.state,
        processId: get(tab.process, 'pid')
      };

      return json;
    },
    deserializeTab: function deserializeTab(tab) {
      tab.basePath = tab.basePath.replace(/case/g, 'conversation');

      tab.linkParams = [tab.routeName].concat(_toConsumableArray(tab.dynamicSegments));
      if (tab.queryParams) {
        tab.linkParams.push(this._buildQueryParams(tab.queryParams));
      }

      if (tab.processId) {
        var processManager = this.get('processManager');
        tab.process = processManager.getProcessByPid(tab.processId);
      }

      if (!tab.process) {
        if (!Ember.testing && window.Bugsnag) {
          var context = (0, _bugsnag.getMetaData)(null, getOwner(this));
          context.tab = {
            processId: tab.processId,
            basePath: tab.basePath,
            routeName: tab.routeName
          };
          window.Bugsnag.notify('DeserializeTabError', 'No process found when deserializing tab', context, 'info');
        }
        return null;
      }

      return this._buildTab(tab);
    },
    _buildTab: function _buildTab() {
      var attrs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return Object.assign({
        process: null,
        basePath: null,
        routeName: null,
        dynamicSegments: [],
        queryParams: [],
        state: {},
        onClose: function onClose() {}
      }, attrs);
    },


    // This is pretty private API. I been told that it's unlikely to change, but still, caution.
    _buildQueryParams: function _buildQueryParams(hash) {
      return {
        isQueryParams: true,
        values: hash
      };
    },
    _destroyProcess: function _destroyProcess(tab) {
      var processManager = this.get('processManager');

      var process = tab.process;

      if (process) {
        processManager.destroyProcess(process);
      }
    },
    _compareTabAndTargetTabBasePath: function _compareTabAndTargetTabBasePath(tabBasePath, targetBasePath) {
      var _tabBasePath$split = tabBasePath.split('?');

      var _tabBasePath$split2 = _slicedToArray(_tabBasePath$split, 1);

      tabBasePath = _tabBasePath$split2[0];

      var _targetBasePath$split = targetBasePath.split('?');

      var _targetBasePath$split2 = _slicedToArray(_targetBasePath$split, 1);

      targetBasePath = _targetBasePath$split2[0];

      return tabBasePath === targetBasePath;
    }
  });
});