define('frontend-cp/components/articles-view-sidebar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    selectedStatus: 'ALL',

    articleCounts: {
      allCount: 0,
      draftCount: 0,
      myPublishedCount: 0
    },

    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      filterByStatus: function filterByStatus(status) {
        this.get('filterByStatus')(status); // Call the action passed from the parent
      }
    }
  });
});