define("frontend-cp/session/admin/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "index": "session_admin__index_7n67n1",
    "content-container": "session_admin__content-container_7n67n1",
    "content": "session_admin__content_7n67n1"
  };
});