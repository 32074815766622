define("frontend-cp/components/ko-case-content/field/requester/trigger-value/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-case-content_field_requester_trigger-value__container_lyqa98",
    "avatar": "ko-case-content_field_requester_trigger-value__avatar_lyqa98"
  };
});