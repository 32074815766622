define('frontend-cp/components/ko-tab-strip/search-results-tab/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    tab: null,
    'on-close': function onClose() {},

    label: computed.readOnly('tab.process.model.id')
  });
});