define("frontend-cp/components/ko-entity-list/sidebar/definition/operator/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "operator-list": "ko-entity-list_sidebar_definition_operator__operator-list_10dsqz",
    "operator": "ko-entity-list_sidebar_definition_operator__operator_10dsqz",
    "input": "ko-entity-list_sidebar_definition_operator__input_10dsqz"
  };
});