define('frontend-cp/components/ko-file-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'multiple', 'disabled', 'title', 'accept'],
    type: 'file',
    multiple: true,
    change: function change() {
      var files = this.element.files;
      this.sendAction('on-change', Array.slice(files));
    }
  });
});