define('frontend-cp/components/ko-admin/automation-actions-builder/autocomplete-team-or-agent/assignee/trigger-value/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    tagName: '',

    session: service(),

    isMe: computed('session.user.id', 'agent.id', function () {
      return this.get('session.user.id') === this.get('agent.id');
    })
  });
});